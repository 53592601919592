/* eslint-disable-next-line lodash/import-scope */
import _ from 'lodash';
import {domain, UnlockableContentClaimRequest, UnlockableContentRequest} from './712sigs';

export const strict = false;

export const state = () => ({
  hasUnlockableContent: {}
});

export const mutations = {
  storeUnlockableContentFound(state, {unlockable, editionId}) {
    state.hasUnlockableContent = {
      ...state.hasUnlockableContent,
      [editionId]: unlockable
    };
  }
};

export const getters = {
  hasUnlockableContent: state => (editionId) => {
    return state.hasUnlockableContent[editionId];
  }
};

export const actions = {

  async setUnlockableContent({state, rootState, dispatch}, data) {
    try {
      const {web3Store} = rootState;
      const {chainId, web3} = web3Store;

      await dispatch('web3Store/createJwtSession', {}, {root: true});
      const authToken = rootState.web3Store.authToken;

      console.log('setting unlockable content', data);

      /* eslint-disable-next-line no-async-promise-executor */
      const signature = await new Promise(async (resolve, reject) => {
        const signer = await web3.getSigner();
        return signer._signTypedData(domain(chainId), UnlockableContentRequest, data)
          .then(resolve)
          .catch(reject);
      });
      console.log('Generated signature', signature);

      const postToApi = async () => this.$api({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${authToken}`
        },
        data: {
          data,
          signature
        },
        url: `/network/${chainId}/unlockable/edition/${data.artwork_id}/unlockable`
      });

      const results = await postToApi();
      return results.data;
    } catch (e) {
      console.error('Unable to send/sign unlockable content', e);
      return null;
    }
  },

  async claimUnlockableContent({state, rootState, commit, getters, dispatch}, data) {
    try {
      const {web3Store} = rootState;
      const {chainId, web3} = web3Store;

      await dispatch('web3Store/createJwtSession', {}, {root: true});
      const authToken = rootState.web3Store.authToken;

      /* eslint-disable-next-line no-async-promise-executor */
      const signature = await new Promise(async (resolve, reject) => {
        const signer = await web3.getSigner();
        return signer._signTypedData(domain(chainId), UnlockableContentClaimRequest, data)
          .then(resolve)
          .catch(reject);
      });
      console.log('Generated signature', signature);

      const postToApi = async () => this.$api({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${authToken}`
        },
        data: {
          data,
          signature
        },
        url: `/network/${chainId}/unlockable/edition/${data.artwork_id}/unlockable/unlock`
      });
      const results = await postToApi();
      return results.data;
    } catch (e) {
      console.error('Unable to claim edition unlockable content', e);
      return null;
    }
  },

  async hasUnlockableContent({rootState, commit, getters}, {editionId, refresh}) {
    try {
      const {web3Store} = rootState;
      const {chainId} = web3Store;

      if (!refresh) {
        const hasContent = getters.hasUnlockableContent(editionId);
        if (hasContent !== null && hasContent !== undefined) {
          return hasContent;
        }
      }

      const results = await this.$api.get(`/network/${chainId}/unlockable/edition/${editionId}/unlockable`);

      const unlockable = _.get(results.data, 'unlockable', false);

      commit('storeUnlockableContentFound', {
        unlockable,
        editionId
      });

      return unlockable;
    } catch (e) {
      console.warn('Unable to determine if edition has unlockable content', e);
      return null;
    }
  }

};
