import {GET_CONTRACTS_BY_ARTIST, GET_EDITION_IDS_FOR_CONTRACT} from '../queries/creatorContracts'

export const actions = {
  async fetchContractDetails({rootState, dispatch}, {address}) {
    try {
      console.log(`[CreatorContract] Fetching contract details for ${address}`)

      const {web3Store} = rootState;
      const {chainId} = web3Store;

      const apiGet = async () => await this.$creatorContractApi({
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'bearer null'
        },
        url: `/network/${chainId}/contracts/${address.toLowerCase()}`
      })

      const resp = await apiGet()

      if (resp.status === 200 && resp.data) {
        console.log(`[CreatorContract] Successfully fetched contract details for ${address}`)
        return resp.data
      } else {
        return null
      }
    } catch (err) {
      console.error('Error fetching contract details - ', err)
      return null
    }
  },

  async fetchContractImage({rootState, dispatch}, {address, imageType}) {
    const {web3Store} = rootState;
    const {chainId} = web3Store;

    console.log(`[CreatorContract] Fetching contract ${imageType} image for ${address}`)

    const apiGet = async () => await this.$creatorContractApi({
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: 'bearer null'
      },
      url: `/network/${chainId}/images/${address.toLowerCase()}?imageType=${imageType}`
    })

    const resp = await apiGet()

    if (resp.status === 200 && resp.data) {
      console.log(`[CreatorContract] Successfully fetched ${imageType} image for ${address}`)

      return resp.data.storageUrl
    } else {
      return null
    }
  },
  async fetchAllContractImages({rootState, dispatch}, {address}) {
    const {web3Store} = rootState;
    const {chainId} = web3Store;

    const apiGet = async () => await this.$creatorContractApi({
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: 'bearer null'
      },
      url: `/network/${chainId}/images/all/${address.toLowerCase()}`
    })

    const resp = await apiGet()

    if (resp.status === 200 && resp.data) {
      return resp.data
    } else {
      return null
    }
  },
  async fetchContractIds({rootState}, address) {
    console.log(`[CreatorContract] Fetching contract ids for ${address}`)
    const res = await this.app.apolloProvider.clients[rootState.web3Store.gqlClient].query({
      query: GET_EDITION_IDS_FOR_CONTRACT,
      variables: {
        id: address
      }
    })

    if (res.data && res.data.editionIds) {
      console.log(`[CreatorContract] Successfully fetched contract ${res.data.editionIds.length} IDs for ${address}`)

      return res.data.editionIds.editions
    } else {
      return []
    }
  },
  async fetchAllContractsByArtist({rootState, dispatch}, address) {
    const res = await this.app.apolloProvider.clients[rootState.web3Store.gqlClient].query({
      query: GET_CONTRACTS_BY_ARTIST,
      variables: {
        id: address
      }
    })

    if (res.data && res.data.contracts) {
      return res.data.contracts
    } else {
      return []
    }
  }
}
