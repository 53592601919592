<template>
  <div class="artistSkeleton">
    <div class="artistSkeleton__profile">
      <b-skeleton :animated="true" circle width="64px" height="64px" />
    </div>
    <div class="artistSkeleton__content">
      <b-skeleton :animated="true" height="32px" width="160px" />
      <b-skeleton :animated="true" height="24px" width="80px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistSkeleton'
}
</script>

<style lang="scss">
.artistSkeleton {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 14px;

  &__profile .b-skeleton {
    width: auto;
  }
}
</style>
