import {isCreatorContract, splitCreatorEditionId} from './CreatorContractUtils';
import {editionSlug} from './slugs';

export function handleGalleryRouting(edition, clickHandler = false) {
  if (edition.edition && checkIsToken(edition)) {
    if (edition.edition.metadata === undefined) {
      edition.edition.metadata = edition.metadata;
    }
    edition = edition.edition;
  }
  if (clickHandler) {
    return clickHandler
  } else if (isCreatorContract(edition)) {
    const ccDetails = splitCreatorEditionId(edition.id)

    return {
      name: 'contract-contract-id',
      params: {id: ccDetails.editionId, contract: ccDetails.contract}
    }
  } else {
    return {
      name: 'gallery-id',
      params: {id: editionSlug(edition.metadata.name, edition.id)}
    }
  }
}

export function handleTokenCardRouting(token, override = false) {
  if (override) {
    return handleGalleryRouting(token, false);
  } else if (token.version === '4') {
    if (token.editionNumber && token.edition.creatorContract) {
      return {
        name: 'contract-contract-id',
        params: {id: token.editionNumber, contract: token.edition.creatorContract.id}
      }
    }
    // derive data from token id
    else if (token.edition) {
      const ccDetails = splitCreatorEditionId(token.edition.id);
      return {
        name: 'contract-contract-id',
        params: {id: ccDetails.editionId, contract: ccDetails.contract}
      }
    }
  } else {
    return {name: 'tokens-id', params: {id: token.id}}
  }
}

function checkIsToken(val) {
  // TODO Change implementation to take into account individual tokens being returned from a query as well as activityEvents and editions
  if (!val.tokenIds) {
    return true;
    // is token
  } else {
    return false;
    // is not token
  }
}
