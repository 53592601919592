<template>
  <div>
    <DonationBadge v-if="donations && donations.length > 0"></DonationBadge>
  </div>
</template>

<script>
import DonationBadge from '../badges/DonationBadge';

export default {
  components: {DonationBadge},
  props: ['profile', 'stats'],
  data: () => ({
    donations: []
  }),
  async mounted() {
    this.loadDonations();
  },
  methods: {
    loadDonations() {
      try {
        this.$cacheApi.$get(`/network/1/accounts/${this.profile.address}/donations`)
          .then((donations) => {
            this.donations = donations;
          });
      } catch (e) {
        console.error('unable to load donations', e);
      }
    }
  }
};
</script>
