<template>
  <figure v-lazy-container="{ selector: 'img' }" v-if="address">
    <img :data-src="`${$userMediaApi.defaults.baseURL}/media/user/profile/${updatedAddress || address}`"
         :data-error="createAccountIcon">
  </figure>
</template>
<script>
import {toDataUrl} from 'myetherwallet-blockies';

export default {
  props: ['address'],
  data() {
    return {
      updatedAddress: null
    }
  },
  computed: {
    createAccountIcon() {
      return toDataUrl(this.address);
    }
  },
  methods: {
    forceRefresh() {
      this.updatedAddress = `${this.address}?random=${Date.now()}`
    }
  }
};
</script>

<style lang="scss" scoped>
@import "node_modules/bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/mixins";

img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  width: 128px;
  height: 128px;

  @include mobile {
    height: 96px;
    width: 96px;
  }
}
</style>
