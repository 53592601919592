var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trending-image" },
    [
      _vm.edition && _vm.edition.id
        ? _c("ApolloQuery", {
            attrs: {
              query: require("../../queries/editionQueries")
                .EDITION_BY_ID_WITH_TOKENS_QUERY,
              variables: { id: _vm.edition.id },
              "client-id": _vm.gqlClient,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ result: { loading, data } }) {
                    return [
                      !loading && data && data.editionById[0]
                        ? _c(
                            "div",
                            { staticClass: "trending-image-card" },
                            [
                              _c("image-for-asset-card", {
                                attrs: {
                                  edition: data.editionById[0],
                                  cover: "true",
                                  resolution: "card",
                                  "disable-icons": true,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "trending-image-title has-text-weight-bold txt-overflow is-family-tertiary",
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.editionById[0].metadata.name)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("img", {
                                class: { "card-image-cover": true },
                                attrs: { src: "/branding/bigloader-image.svg" },
                              }),
                              _vm._v(" "),
                              _c("b-skeleton", {
                                attrs: {
                                  active: "",
                                  width: "200px",
                                  height: "12px",
                                },
                              }),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ],
              null,
              false,
              114955127
            ),
          })
        : _c(
            "div",
            [
              _c("img", {
                class: { "card-image-cover": true },
                attrs: { src: "/branding/bigloader-image.svg" },
              }),
              _vm._v(" "),
              _c("b-skeleton", {
                attrs: { active: "", width: "200px", height: "12px" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }