<template>
  <!-- test site - https://modelviewer.dev/examples/tester.html -->
  <!-- glb editor - https://modelviewer.dev/editor/ -->
  <!-- reveal="auto"    - If set the model will be revealed as soon as it is done loading and rendering -->
  <!-- poster           - like a video this is the image behind the loaded 3d object -->
  <!-- camera-controls  - Enables controls via mouse/touch when in flat view. -->
  <!-- auto-rotate      - Enables the auto-rotation of the model -->
  <!-- ar               - Enable the ability to launch AR experiences on supported devices. -->
  <!-- environment-image- Defines the specific lighting mode, by default we use a simple generic one. -->
  <!-- interaction-policy-If set to "always-allow", the user can control it even if the viewer is not the focused element on the page-->

  <img v-if="cover && cardAsset"
       data-loading="/branding/bigloader-image.svg"
       data-error="/branding/holding_image.jpg"
       v-lazy="{src: cardAsset, error:'/branding/holding_image.jpg'}">

  <model-viewer v-else
                :src="assetUrl"
                :poster="cardAsset"
                autoplay
                auto-rotate
                ar
                magic-leap
                :loading="cover ? 'lazy' : 'eager'"
                :reveal="cover ? 'manual' : 'auto'"
                quick-look-browsers="safari chrome"
                camera-controls
                class="objectViewer"
                ref="objectViewer"
                style="background: linear-gradient(#ffffff, #fafafa); text-align: center; width: 100%; margin: 0 auto;"
                interaction-policy="always-allow"
                environment-image="/3d/environments/lightroom_14b.hdr"
                @progress="onProgress"
                @error="onError"
                @ar-status="onArStatus">

    <div id="progress" slot="progress-bar">
      <div class="bar"></div>
      <div>Loading Artwork</div>
    </div>

  </model-viewer>
</template>

<script>

import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';

export default {
  name: 'InteractivePlayer',
  props: ['editionNumber', 'cover', 'cardAsset', 'fullAsset', 'metadata'],
  data() {
    return {
      assetUrl: ''
    };
  },
  async mounted() {
    try {
      // dont redirect to the item but load the location via JSON
      const {data} = await this.$axios.get(`${this.fullAsset}?noDownload=true`);
      this.assetUrl = data.location || this.metadata.animation_url;
    } catch (e) {
      console.log('Unable to download full asset', e);
      this.assetUrl = ipfsUtils.buildPinataLookupUri(this.metadata.animation_url);
    }
  },
  methods: {
    onError(error) {
      console.error('onError', error);
    },
    onArStatus(event) {
      console.info('onArStatus', event);
    },
    onProgress(event) {
      const progress = document.querySelector('#progress');
      const bar = progress.querySelector('.bar');

      const {totalProgress} = event.detail;
      progress.classList.toggle('show', totalProgress < 1);
      bar.style.transform = `scaleX(${totalProgress})`;
    }
  }
};
</script>
<style scoped>

model-viewer#reveal {
  --poster-color: transparent;
}

model-viewer#interaction {
  --poster-color: transparent;
}

.objectViewer {
  height: 700px;
}

#progress {
  position: absolute;
  width: calc(100% / 3);
  height: 10px;
  color: rgba(2, 2, 2, 0.75);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.25);
  border: 2px solid currentColor;
  border-radius: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 100% / 6);
  opacity: 0;
  transition: opacity 0.3s 0.3s;
}

#progress.show {
  transition-delay: 0s;
  opacity: 1;
}

#progress > .bar {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  transform-origin: top left;
  transform: scaleX(0);
}

</style>
