var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "section",
              {
                staticClass:
                  "has-margin-7 has-padding-top-7 has-padding-bottom-7",
              },
              [
                _c("h2", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v("\n            Claim Royalties\n          "),
                ]),
                _vm._v(" "),
                _c("h5", { staticClass: "mb-5" }, [
                  _vm._v(
                    "\n            Have you received any royalties from OpenSea sales?\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm.royalties &&
                _vm.royalties.merkleProofAndIndex &&
                parseFloat(_vm.royalties.amountInEth) > 0.0 &&
                !_vm.royalties.fundsClaimed
                  ? _c(
                      "section",
                      { staticClass: "mt-4 mb-4" },
                      [
                        _c(
                          "b-message",
                          {
                            attrs: {
                              type: "is-info",
                              closable: false,
                              duration: 0,
                            },
                          },
                          [
                            _vm._v("\n              You have\n              "),
                            _c("eth-with-fiat-price", {
                              staticClass: "has-text-weight-bold",
                              attrs: {
                                "price-in-eth": _vm.royalties.amountInEth,
                                "show-fiat": true,
                                "override-dp": 4,
                              },
                            }),
                            _vm._v(
                              "\n              of unclaimed royalties.\n            "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.royalties &&
                    _vm.royalties.merkleProofAndIndex &&
                    parseFloat(_vm.royalties.amountInEth) > 0.0 &&
                    _vm.royalties.fundsClaimed
                  ? _c(
                      "section",
                      { staticClass: "mt-4 mb-4" },
                      [
                        _c(
                          "b-message",
                          {
                            attrs: {
                              type: "is-light",
                              closable: false,
                              duration: 0,
                            },
                          },
                          [
                            _vm._v(
                              "\n              You have already claimed\n              "
                            ),
                            _c("eth-with-fiat-price", {
                              staticClass: "has-text-weight-bold",
                              attrs: {
                                "price-in-eth": _vm.royalties.amountInEth,
                                "show-fiat": true,
                                "override-dp": 4,
                              },
                            }),
                            _vm._v(
                              "\n              of royalties.\n            "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.royalties
                  ? _c(
                      "section",
                      { staticClass: "mt-4 mb-4" },
                      [
                        _c(
                          "b-message",
                          {
                            attrs: {
                              type: "is-info",
                              closable: false,
                              duration: 0,
                            },
                          },
                          [
                            _vm._v(
                              "\n              You have no unclaimed royalties.\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "section",
                      { staticClass: "mt-4 mb-4" },
                      [
                        _c(
                          "b-message",
                          {
                            attrs: {
                              type: "is-info",
                              closable: false,
                              duration: 0,
                            },
                          },
                          [
                            _vm._v(
                              "\n              Loading royalties...\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
            _vm._v(" "),
            _vm.transactionHash
              ? _c("section", { staticClass: "level is-mobile pt-2" }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "check-circle-outline",
                            size: "is-small",
                          },
                        }),
                        _vm._v("\n               \n              "),
                        _c("span", { staticClass: "is-uppercase is-size-7" }, [
                          _vm._v(
                            "\n                  Claiming royalties\n              "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level-right" }, [
                    _c("div", { staticClass: "level-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "is-muted-7 is-family-tertiary",
                          attrs: {
                            href: _vm.etherscanTxLink(_vm.transactionHash),
                            target: "_blank",
                          },
                        },
                        [_vm._v("\n                [view tx]\n              ")]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n            Nevermind\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-primary",
                    attrs: {
                      expanded: "",
                      disabled:
                        !_vm.royalties ||
                        !_vm.royalties.merkleProofAndIndex ||
                        parseFloat(_vm.royalties.amountInEth) === 0.0 ||
                        _vm.royalties.fundsClaimed ||
                        _vm.transactionHash,
                    },
                    on: { click: _vm.claim },
                  },
                  [_vm._v("\n            Claim\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "mt-4 is-size-7" }, [
      _c("p", [
        _vm._v(
          "\n              You can claim existing funds from the Vault, but as of 12th of May 2023 we no longer capture any new royalties.\n              "
        ),
        _c(
          "a",
          {
            staticClass: "is-linkable",
            attrs: {
              href: "https://knownorigin.io/journal/cms/stories/vault-update-and-our-policy-on-royalties",
              target: "_blank",
            },
          },
          [_vm._v("\n                Learn more.\n              ")]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              Note: You will need to perform an on-chain transaction to claim OpenSea royalties.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }