<template>
  <modal-template :edition="edition">

    <template slot="content-title">
      Make an offer
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="secondary-content">
      <b-dropdown aria-role="list"
                  :append-to-body="true"
                  :scrollable="true"
                  :max-height="350"
                  v-model="selectedToken">

        <button class="button" slot="trigger" slot-scope="{ active }">
          <span v-if="!selectedToken">
            <span>Select an edition no.</span>
          </span>
          <span v-else>
            <span class="has-text-weight-bold">
              #{{ selectedToken.id }}
            </span>
            <span v-if="selectedToken.isListed">
              | Listed for <eth-with-fiat-price :price-in-eth="selectedToken.listPrice"></eth-with-fiat-price>
            </span>
            <span v-if="selectedToken.openOffer" class="has-text-weight-bold">
              | Current offer <eth-with-fiat-price size="is-small" :price-in-eth="selectedToken.openOffer.ethValue"></eth-with-fiat-price>
            </span>
            <span v-else class="has-text-weight-semibold">
              | No open offers
            </span>
<!--            <span>-->
<!--              | Previous <eth-with-fiat-price  :price-in-eth="selectedToken.lastSalePriceInEth"></eth-with-fiat-price>-->
<!--            </span>-->
          </span>
          <b-icon v-if="!selectedToken" :icon="active ? 'sort-up' : 'sort-down'"></b-icon>
        </button>

        <b-dropdown-item aria-role="listitem" v-for="(token, idx) in tokens" :key="idx" :value="token">
          <span class="has-text-weight-bold">
            #{{ idx + 1 }}
          </span>
          <span v-if="token.isListed">
            | Listed for <eth-with-fiat-price :price-in-eth="token.listPrice"></eth-with-fiat-price>
          </span>
          <span v-else>
            | Previous <eth-with-fiat-price :price-in-eth="token.lastSalePriceInEth"></eth-with-fiat-price>
          </span>
          <span v-if="token.openOffer" class="has-text-weight-bold">
            | Current offer <eth-with-fiat-price :price-in-eth="token.openOffer.ethValue"></eth-with-fiat-price>
          </span>
          <span v-else class="has-text-weight-semibold">
            | No open offers
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <template slot="content-balance">&nbsp;</template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="!selectedToken"
                @click="launchMakeOfferModel(selectedToken)">
        Make offer
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import {mapState} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import {TOKEN_OWNERS_FOR_EDITION} from '../../../queries/tokenQueries';
import PlaceTokenOffer from '../token/PlaceTokenOffer';
import {GET_TOKEN_ACTIVE_OFFER} from '../../../queries/offersQueries';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, ArtworkNameAndArtist, ModalTemplate},
  props: ['edition'],
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  data() {
    return {
      tokens: [],
      selectedToken: null
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('tokens', {
      client: this.gqlClient,
      query: TOKEN_OWNERS_FOR_EDITION,
      variables() {
        return {
          editionNumber: this.edition.id
        };
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async launchMakeOfferModel(token) {
      const {data} = await this.$apollo.getClient().query({
        query: GET_TOKEN_ACTIVE_OFFER,
        variables: {
          token: `token-${token.id}`
        }
      });
      this.close();
      this.$buefy.modal.open({
        parent: this,
        component: PlaceTokenOffer,
        width: 600,
        props: {
          tokenId: token.id,
          edition: this.edition,
          existingOffer: data.tokenOffers[0]
        }
      });
    }
  }

}
</script>
