<template>
  <client-only>
    <div class="card" v-if="workingMetadata && token" :class="{ccToken: isCc}">
      <div class="card-image">
        <span v-if="isVideoImageType">
          <b-icon icon="video" type="is-white"
                  style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
          </b-icon>
        </span>

        <span v-if="is3DImageType">
          <b-icon icon="video-3d-variant" type="is-white"
                  style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
          </b-icon>
        </span>

        <span v-if="is360Image">
          <b-icon icon="panorama" type="is-white"
                  style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
          </b-icon>
        </span>

        <div class="asset-card-wrapped-link hand-pointer">
          <n-link :to="handleRouting(token, canOverrideToEdition)">
            <image-asset :edition-number="token.edition.id"
                         :metadata="workingMetadata"
                         :resolution="resolution"
                         :cover="cover">
            </image-asset>
          </n-link>
        </div>

        <unlockable-edition-icon v-if="token" :edition-number="token.editionNumber">
        </unlockable-edition-icon>

        <enhanced-icon v-if="token" :token-id="getTokenId">
        </enhanced-icon>
        <creator-contract-icon :is-creator-contract="isCc">
        </creator-contract-icon>

        <b-tag v-if="parseFloat(token.listPrice) > 0" type="is-light" class="is-uppercase is-family-primary"
               style="position: absolute; top: 1rem; left: 1rem; z-index: 10;">
          On Sale
          <eth-with-fiat-price :price-in-eth="token.listPrice"></eth-with-fiat-price>
        </b-tag>
      </div>
      <div class="card-content is-paddingless">
        <div class="content">
          <div class="columns is-mobile is-multiline">
            <div class="column is-four-fifths has-padding-left-2 has-padding-top-4" data-testid="artworkName">
              <n-link :to="handleRouting(token, canOverrideToEdition)" class="asset-card-wrapped-link">
                <slot name="asset-card-title-prefix"></slot>
                <slot name="asset-card-content-top-left">
                  <h2 v-if="size === 'is-large'" class="has-text-left is-family-tertiary has-text-weight-bold"
                      :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
                    {{ workingMetadata.name | truncate(30) }}
                  </h2>
                  <h6 v-else class="has-text-left is-family-tertiary has-text-weight-bold"
                      :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">{{
                      workingMetadata.name | truncate(30)
                    }}</h6>
                </slot>
              </n-link>
            </div>
            <div
              class="column has-text-right is-one-fifth is-size-7 has-text-weight-bold has-padding-right-2 has-padding-top-4" data-testid="artworkName">
              <n-link :to="handleRouting(token, canOverrideToEdition)" class="asset-card-wrapped-link">
                <slot name="asset-card-content-top-right">
                  <token-id-of-x :id="getTokenId"
                                 :edition-number="token.editionNumber"
                                 :version="token.version"
                                 :edition-total-available="token.edition.totalAvailable"
                                 :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
                  </token-id-of-x>
                </slot>
              </n-link>
            </div>
            <div class="column is-four-fifths has-padding-left-2 has-padding-bottom-2">
              <n-link :to="{name:'profile-id', params: { id: token.artistAccount.toLowerCase()}}"
                      :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
                <slot name="asset-card-content-bottom-left">
                  <artist-name-and-image-lookup
                    :artist-account="token.artistAccount"
                    :name="workingMetadata.artist"
                    :collaborators="token.edition.collective && token.edition.collective.recipients ? token.edition.collective.recipients : token.edition.collaborators"
                    :size="size"
                    :is-cc="isCc"
                  >
                  </artist-name-and-image-lookup>
                </slot>
              </n-link>
            </div>
            <div class="column is-one-fifth has-text-right has-padding-right-2">
              <slot name="asset-card-content-bottom-right">
                <b-dropdown aria-role="list" class="is-clickable has-text-left is-family-tertiary" position="is-top-left">
                  <b-icon icon="dots-horizontal" slot="trigger"></b-icon>
                  <b-dropdown-item aria-role="listitem" custom>
                    <div class="columns is-mobile">
                      <div class="column">
                        <span class="">Token ID</span>
                      </div>
                      <div class="column has-text-right">
                        <token-id :id="getTokenId"></token-id>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <hr class="dropdown-divider">
                  <b-dropdown-item aria-role="list" @click="listToken(token)" v-if="canChangeBuyNowPrice && !isCc">
                    {{ !token.isListed ? 'Sell as buy now' : 'Change price' }}
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="list" @click="listReserveAuction(token)"
                                   v-if="canChangeReservePrice && !isCc">
                    {{
                      isReserveAuctionSalesType(token.salesType) ? 'Change reserve price' : 'Start reserve auction'
                    }}
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="delist token" @click="delistToken(token)" v-if="canCancelSale && !isCc">
                    Cancel sale
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="set minimum bid" ref="setminimumbid"
                                   @click="setMinimumBid(token)" v-if="canSetMinimumBid && !isCc">
                    Set minimum bid
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="set minimum bid" ref="setminimumbid"
                                   @click="claimUnlockableContent(token)"
                                   v-if="isLoggedInAccountViewingPage && hasUnlockableContent(token.editionNumber) && !isCc">
                    View unlockable
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="transfer" @click="transferToken(token)" v-if="canTransferToken">
                    Transfer
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="download"
                                   @click="downloadAsset(token)" v-if="isLoggedInAccountViewingPage">
                    Download
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="delist token" @click="shareTokenModal(token)">
                    Share
                  </b-dropdown-item>
                </b-dropdown>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer" :class="{ccToken: isCc}">
        <div class="column is-half has-padding-left-3 has-padding-top-0 is-flex is-align-items-center" data-testid="saleprice"
             :class="{'countdown-started': reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete}">
          <n-link :to="handleRouting(token, canOverrideToEdition)" class="asset-card-wrapped-link"
                  :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
            <div class="has-text-weight-bold has-text-left is-family-tertiary">
              <slot name="asset-card-footer-left-title">
                <span v-if="token.isListed && token.listing && reserveAuctionCountdownInProgress">
                  Current bid
                </span>
                <span v-else-if="token.isListed && token.listing && isReserveAuctionSalesType(token.salesType)">
                  Reserve price
                </span>
                <span v-else-if="token.isListed">
                  Buy now
                </span>
                <span v-else-if="getTokenMinimumOffer(token) && getTokenMinimumOffer(token).eth_reserve_in_wei">
                  Place a bid
                 <span :class="{'has-text-grey': !isCc}">
                   (minimum)
                 </span>
                </span>
                <span v-else-if="token.lastSalePriceInEth && parseFloat(token.lastSalePriceInEth) > 0">
                  Sold for
                </span>
                <span v-else-if="token.lastSalePriceInEth && parseFloat(token.lastSalePriceInEth) === 0"
                      :class="{'has-text-grey': !isCc}">
                 &nbsp;
                </span>
              </slot>
            </div>
            <div class="has-text-weight-bold has-text-left">
              <slot name="asset-card-footer-left">
                <span
                  v-if="(reserveAuctionCountdownInProgress || reserveAuctionCountdownComplete) && token.isListed && token.listing">
                  <eth-with-fiat-price :price-in-wei="token.listing.reserveAuctionBid">
                  </eth-with-fiat-price>
                </span>
                <span v-else-if="isReserveAuctionSalesType(token.salesType) && token.isListed && token.listing">
                  <eth-with-fiat-price :price-in-wei="token.listing.reservePrice">
                  </eth-with-fiat-price>
                </span>
                <span v-else-if="token.isListed">
                  <eth-with-fiat-price :price-in-eth="token.listPrice" :show-fiat="false">
                  </eth-with-fiat-price>
                </span>
                <span v-else-if="getTokenMinimumOffer(token) && getTokenMinimumOffer(token).eth_reserve_in_wei">
                  <eth-with-fiat-price :price-in-wei="getTokenMinimumOffer(token).eth_reserve_in_wei || 0">
                  </eth-with-fiat-price>
                </span>
                <span v-else-if="token.lastSalePriceInEth && parseFloat(token.lastSalePriceInEth) > 0">
                  <eth-with-fiat-price :price-in-eth="token.lastSalePriceInEth"
                                       :show-fiat="false">
                  </eth-with-fiat-price>
                </span>
                <span v-else-if="token.lastSalePriceInEth && parseFloat(token.lastSalePriceInEth) === 0" class="">
                 Gifted
                </span>
              </slot>
            </div>
          </n-link>
        </div>
        <div class="column has-text-left is-half has-padding-right-3"
             :class="{'countdown-started has-padding-bottom-4': reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete}">
          <n-link :to="{name: 'profile-id', params: {id: token.currentOwner.address}}"
                  class="has-text-dark">
            <slot name="asset-card-footer-right-title">
              <div>
                <span v-if="reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete"
                      class="has-text-weight-semibold">
                  Auction ends in
                </span>
                <span class="is-size-7 mb-1 has-text-weight-bold is-family-tertiary" v-else :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
                  Owned by
                </span>
              </div>
            </slot>
            <slot name="asset-card-footer-right">
              <span v-if="reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete"
                    class="has-text-weight-semibold">
                <auction-count-down-simple :end-date="token.listing.reserveAuctionEndTimestamp"
                                           :white-text="false"
                                           class="has-text-weight-semibold">
                </auction-count-down-simple>
              </span>
              <owner-name-and-image-lookup v-else :account="token.currentOwner.address" class="is-inline-block"
                                           :is-cc="isCc"
              >
              </owner-name-and-image-lookup>
            </slot>
          </n-link>
        </div>
      </footer>
    </div>
  </client-only>
</template>

<script>
import _first from 'lodash/first';
import {mapGetters, mapState} from 'vuex';
import {mimeTypeUtils, ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import {
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isSteppedSalesType,
  isBuyNowAndOffersSalesType,
  isBuyableSalesType,
  isReserveAuctionSalesType
} from '../services/SaleTypes';
import {EDITION_BY_ID_QUERY} from '../queries/editionQueries';
import ReserveAuctionUtils from '../services/ReserveAuctionUtils';
import {isCreatorContract, splitCreatorEditionId} from '../services/CreatorContractUtils';
import {handleTokenCardRouting} from '../services/routing';
import {doesMetadataExist} from '../services/editionUtils';
import TokenIdOfX from './TokenIdOfX';
import OwnerNameAndImageLookup from './OwnerNameAndImageLookup';
import TokenId from './TokenId';
import ListTokenModal from './modal/token/ListTokenModal';
import ShareTokenModal from './modal/ShareTokenModal';
import DeListTokenModal from './modal/token/DeListTokenModal';
import SetOffchainMinBid from './modal/SetOffchainMinBid';
import TransferTokenModal from './modal/token/TransferTokenModal';
import UnlockableEditionIcon from './badges/UnlockableEditionIcon';
import ClaimEditionUnlockableContent from './modal/edition/unlockable/ClaimEditionUnlockableContent';
import ListReserveAuctionModal from './modal/token/ListReserveAuctionModal';
import ImageAsset from './asset-renders/ImageAsset';
import ArtistNameAndImageLookup from './avatar/ArtistNameAndImageLookup';
import AuctionCountDownSimple from './clocks/AuctionCountDownSimple';
import EnhancedIcon from './badges/EnhancedIcon';
import CreatorContractIcon from './badges/CreatorContractIcon';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    CreatorContractIcon,
    EnhancedIcon,
    AuctionCountDownSimple,
    TokenId,
    UnlockableEditionIcon,
    OwnerNameAndImageLookup,
    TokenIdOfX,
    EthWithFiatPrice,
    ImageAsset,
    ArtistNameAndImageLookup
  },
  props: [
    'token',
    'routeEdition',
    'cover',
    'resolution',
    'size',
    'address'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      like: false
    };
  },
  computed: {
    ...mapGetters('offerStore', [
      'getTokenMinimumOffer'
    ]),
    ...mapGetters('unlockableContentStore', [
      'hasUnlockableContent'
    ]),
    ...mapState('web3Store', [
      'chainId',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount'
    ]),
    reserveAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.token.listing);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.token.listing);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.token.listing);
    },
    workingMetadata() {
      if (doesMetadataExist(this.token)) {
        return this.token.metadata;
      }
      return this.metadataFromKoCache;
    },
    isVideoImageType() {
      return this.token.metadata && this.token.metadata.image_type && mimeTypeUtils.isVideoType(this.token.metadata.image_type);
    },
    is3DImageType() {
      return this.token.metadata && this.token.metadata.image_type && mimeTypeUtils.is3D(this.token.metadata.image_type);
    },
    is360Image() {
      return this.token.metadata && this.token.metadata.image_sphere === true;
    },
    isLoggedInAccountViewingPage() {
      return this.isLoggedInAccount(this.address);
    },
    canCancelSale() {
      if (!this.isLoggedInAccountViewingPage) {
        return false;
      }
      return this.token.isListed && (!this.reserveAuctionCountdownInProgress && !this.reserveAuctionCountdownComplete);
    },
    canSetMinimumBid() {
      if (!this.isLoggedInAccountViewingPage) {
        return false;
      }
      return !this.token.isListed && isOffersOnlySalesType(this.token.salesType);
    },
    canTransferToken() {
      if (!this.isLoggedInAccountViewingPage) {
        return false;
      }
      if (isReserveAuctionSalesType(this.token.salesType)) {
        return this.token.listing && !this.reserveAuctionCountdownStarted;
      }
      return true;
    },
    canChangeReservePrice() {
      if (!this.isLoggedInAccountViewingPage || parseInt(this.token.version) !== 3) {
        return false;
      }
      return !isBuyNowSalesType(this.token.salesType) && !this.reserveAuctionCountdownStarted;
    },
    canChangeBuyNowPrice() {
      if (!this.isLoggedInAccountViewingPage) {
        return false;
      }
      return !isReserveAuctionSalesType(this.token.salesType);
    },
    isCc() {
      return isCreatorContract(this.token);
    },
    getTokenId() {
      if (isCreatorContract(this.token)) {
        const {editionId} = splitCreatorEditionId(this.token.id)
        return editionId
      } else {
        return this.token.id
      }
    },
    canOverrideToEdition() {
      return !!this.routeEdition;
    }
  },
  watch: {},
  async beforeMount() {
    if (!doesMetadataExist(this.token)) {
      const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.token.id}`);
      if (metadata) {
        this.metadataFromKoCache = metadata;
      }
    }
  },
  async mounted() {
    // load latest min bid
    await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
      current_owner: this.token.currentOwner.id,
      artwork_id: this.token.id,
      entity_type: 'token'
    });
  },
  methods: {
    openLink(newWindow = false) {
      if (newWindow === true) {
        window.open(this.$router.resolve({name: 'tokens-id', params: {id: this.tokenId}}).href, '_blank');
      } else {
        this.$router.push({name: 'tokens-id', params: {id: this.tokenId}});
      }
    },
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isSteppedSalesType,
    isBuyNowAndOffersSalesType,
    isBuyableSalesType,
    isReserveAuctionSalesType,
    async getEditionForToken(token) {
      return this.$apollo.query({
        client: this.gqlClient,
        query: EDITION_BY_ID_QUERY,
        variables: {
          id: token.editionNumber
        }
      }).then(({data}) => _first(data.editionById));
    },
    async loadLoggedInUserLikePref() {
      if (this.token) {
        const {like} = await this.$store.dispatch('userStore/getUserEditionLike', {
          account: this.account,
          editionNumber: this.token.editionNumber
        });
        this.like = like;
      }
    },
    listToken(token) {
      this.$buefy.modal.open({
        parent: this,
        component: ListTokenModal,
        width: 600,
        props: {token},
        events: {
          close: async (success) => {
            if (success) {
              const dismissedPrompt = await this.$store.dispatch('userStore/hasUserDismissedShareTokenPromptModal');
              if (!dismissedPrompt) {
                // launch share model after setting list price
                this.$buefy.modal.open({
                  parent: this,
                  component: ShareTokenModal,
                  width: 600,
                  props: {
                    token,
                    profile: this.profile,
                    title: 'Share this sale',
                    listTokenFlow: true,
                    subtitle: `${token.metadata.name} by ${token.metadata.artist}`
                  }
                });
              }
            }
            this.$emit('tokens-by-owner-refresh');
          }
        }
      });
    },
    listReserveAuction(token) {
      this.$buefy.modal.open({
        parent: this,
        component: ListReserveAuctionModal,
        width: 600,
        props: {token},
        events: {
          close: async (success) => {
            // TODO share modal
            this.$emit('tokens-by-owner-refresh');
          }
        }
      });
    },
    delistToken(token) {
      this.$buefy.modal.open({
        parent: this,
        component: DeListTokenModal,
        width: 600,
        props: {token},
        events: {
          close: () => {
            this.$emit('tokens-by-owner-refresh');
          }
        }
      });
    },
    async setMinimumBid(token) {
      const edition = await this.getEditionForToken(token);
      this.$buefy.modal.open({
        parent: this,
        component: SetOffchainMinBid,
        width: 600,
        props: {
          token,
          edition,
          type: 'token'
        }
      });
    },
    async claimUnlockableContent(token) {
      const edition = await this.getEditionForToken(token);
      this.$buefy.modal.open({
        parent: this,
        component: ClaimEditionUnlockableContent,
        width: 600,
        props: {
          token,
          edition
        }
      });
    },
    downloadAsset(token) {
      if (token.metadata) {
        const downloadUrl = token.metadata.animation_url || token.metadata.image;
        window.open(ipfsUtils.buildPinataLookupUri(downloadUrl), '_blank');
      }
    },
    shareTokenModal(token) {
      this.$buefy.modal.open({
        parent: this,
        component: ShareTokenModal,
        width: 600,
        props: {
          token,
          profile: this.profile,
          title: 'Share',
          subtitle: `${token.metadata.name} by ${token.metadata.artist}`
        }
      });
    },
    transferToken(token) {
      this.$buefy.modal.open({
        parent: this,
        component: TransferTokenModal,
        width: 600,
        props: {token},
        events: {
          close: () => {
            this.$emit('tokens-by-owner-refresh');
          }
        }
      });
    },
    handleRouting(token, routeToEdition) {
      return handleTokenCardRouting(token, routeToEdition);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.countdown-started {
  background-color: $black;
  color: $white !important;

  div > * {
    background-color: $black;
    color: $white !important;
  }

  span > * {
    background-color: $black;
    color: $white !important;
  }
}

.ccToken {
  background-color: $dark;
  color: white;
  border-top-color: #3A3A3A;
}

</style>
