export const strict = false

export const state = () => ({
  usdPrice: null
});

export const mutations = {
  storeUsdPrice(state, usdPrice) {
    state.usdPrice = usdPrice;
  }
};

export const actions = {
  async getUsdPrice({state, commit}) {
    const get = async () => {
      const {usdPrice} = await this.$cacheApi.$get('/info/usdprice');
      commit('storeUsdPrice', usdPrice);
      return usdPrice;
    };

    // Hack to try 3 times getting the usd price
    get()
      .catch(() => get())
      .catch(() => get())
  }
};
