<template>
  <div>
    <div
      v-if="item"
      class="is-full columns is-flex-mobile p-0 my-0 search-item-style"
      :class="{ 'search-item-style-top': item.__position === 1 }"
    >
      <div class="is-flex is-align-items-center search-rounded-image-container">
        <img
          class="rounded-image"
          v-lazy="{
            src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${userAddress}`,
            error: createAccountIcon(),
          }"
        />
      </div>

      <section class="column is-centered px-2 py-2" v-if="profile">
        <div class="has-text-left is-size-6 ml-1 is-flex is-align-items-center">
          <span class="is-family-secondary">{{
            nameOrShortEth.length > textWrapCap
              ? nameOrShortEth.substring(0, textWrapCap) + "..."
              : nameOrShortEth
          }}</span>
          <b-icon
            icon="check-decagram"
            size="is-small"
            class="ml-2 is-size-5 is-size-6-mobile is-verified"
            v-if="
              profile &&
              isTwitterVerified(userAddress.toLowerCase(), profile.twitter)
            "
          >
          </b-icon>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { toDataUrl } from 'myetherwallet-blockies';
import { mapGetters } from 'vuex';
import { cleanUpUrl } from '../../services/utils';

export default {
  props: ['address', 'item', 'isMobile'],
  data() {
    return {
      profile: undefined,
      coverImage: '/branding/cover-preview.jpg',
      textWrapCap: this.isMobile ? 20 : 34
    };
  },
  computed: {
    ...mapGetters('userStore', [
      'isInstagramVerified',
      'isTwitterVerified',
      'isEitherProfileVerified'
    ]),
    userAddress() {
      return this.profile ? this.profile.address : this.address;
    },
    nameOrShortEth() {
      if (this.profile && this.profile.username) {
        return this.profile.username;
      }
      return this.$options.filters.shortEth(this.address);
    }
  },
  async mounted() {
    try {
      this.profile = await this.$store.dispatch(
        'userStore/getUser',
        this.address
      );
    } catch (e) {
      console.log('Problem with cover image');
    }
  },
  methods: {
    cleanUpUrl,
    createAccountIcon() {
      return toDataUrl(this.userAddress);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.box-fat {
  margin-top: 2rem;
  height: 28rem;
  border: 1px $grey-light solid;
  border-radius: 0.5rem;
}

.avatar {
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  width: 128px;
  height: 128px;
}
</style>
