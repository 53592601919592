<template>
  <div class="card-content is-paddingless">
    <div class="content">
      <div class="columns is-mobile is-multiline">
        <div class="column is-7 has-padding-left-2 has-padding-top-4">
          <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link" data-testid="artworkName" :class="{ccArtworkInfoText: isCc}"
                  style="overflow-wrap: break-word">
            <h2 :class="{'has-text-dark': !isCc, 'has-text-white': isCc}" v-if="size === 'is-large'"
                class="has-text-left is-family-tertiary has-text-weight-bold">{{ workingMetadata.name | truncate(30) }}</h2>
            <h6 :class="{'has-text-dark': !isCc, 'has-text-white': isCc}" v-else
                class="has-text-left is-family-tertiary has-text-weight-bold">{{ workingMetadata.name | truncate(30) }}</h6>
          </n-link>
        </div>
        <div class="column has-text-right is-5 is-size-7 has-padding-top-5 has-padding-right-2">
          <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link" data-testid="editionSize"
                  :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
            <availability :available="edition.totalAvailable" :supply="edition.totalSupply" :edition="edition"></availability>
          </n-link>
        </div>
        <div class="column is-four-fifths has-padding-left-2 has-padding-bottom-2">
          <n-link :to="{name:'profile-id', params: { id: edition.artistAccount.toLowerCase()}}"
                  :class="{'has-text-dark': !isCc, 'has-text-white': isCc}"
                  data-testid="artistName">
            <artist-name-and-image-lookup
              :artist-account="edition.artistAccount"
              :name="workingMetadata.artist"
              :collaborators="edition.collective ? edition.collective.recipients: edition.collaborators"
              :size="size"
              :is-cc="isCc"
            >
            </artist-name-and-image-lookup>
          </n-link>
        </div>
        <div v-if="!clickHandler" class="column is-one-fifth has-text-right has-padding-right-2">
          <b-dropdown aria-role="list" v-if="isLoggedInAccountViewingPage" class="is-clickable has-text-left is-family-tertiary"
                      position="is-top-left">
            <b-icon icon="dots-horizontal" slot="trigger"
                    :class="{'has-text-dark': !isCc, 'has-text-white': isCc}"></b-icon>
            <b-dropdown-item aria-role="listitem" custom>
              <div class="columns is-mobile">
                <div class="column">
                  <span>Edition #</span>
                </div>
                <div class="column has-text-right" v-if="isCc">
                  <token-id :id="(splitCreatorEditionId(edition.id).editionId)"></token-id>
                </div>
                <div class="column has-text-right" v-else>
                  <token-id :id="edition.id"></token-id>
                </div>
              </div>
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item aria-role="change price" ref="changeprice"
                             v-if="canChangeGatedPublicPrice"
                             @click="changePrice(edition)">
              Change public price
            </b-dropdown-item>
            <b-dropdown-item aria-role="change price" ref="changeprice"
                             v-else-if="canChangeBuyNowPrice" @click="changePrice(edition)">
              Change price
            </b-dropdown-item>
            <b-dropdown-item aria-role="change reserve price" ref="changereserveprice"
                             v-else-if="canChangeReservePrice" @click="changePrice(edition)">
              Change reserve price
            </b-dropdown-item>
            <b-dropdown-item aria-role="result auction" ref="resultauction"
                             v-else-if="canResultReserveAuction" @click="resultReserveAuction(edition)">
              Complete auction
            </b-dropdown-item>
            <b-dropdown-item aria-role="change type" ref="changelisting"
                             v-if="canChangeSalesListing" @click="changeSaleType(edition)">
              Change listing
            </b-dropdown-item>
            <b-dropdown-item aria-role="set minimum bid" ref="setminimumbid"
                             v-if="canSetOffchainMinimumBid" @click="setMinimumBid(edition)">
              Set minimum bid
            </b-dropdown-item>
            <b-dropdown-item aria-role="transfer to" ref="transfer"
                             v-if="canTransferToken" @click="transferEditionToken(edition)">
              Transfer
            </b-dropdown-item>
            <b-dropdown-item aria-role="set unlockable content" ref="setunlockablecontent"
                             v-if="canSetUnlockableContent" @click="setUnlockableContent(edition)">
              Set unlockable content
            </b-dropdown-item>
            <b-dropdown-item aria-role="delete or burn" ref="burn" class="has-text-danger"
                             v-if="canBurnEdition" @click="burn(edition)">
              {{edition.isOpenEdition ? 'End open edition' : 'Delete / Burn'}}
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" has-link ref="view" class="">
              <n-link :to="handleMarketPlaceRouting">
                View
              </n-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _head from 'lodash/head';
import {mapState, mapGetters} from 'vuex';
import ArtistNameAndImageLookup from '../avatar/ArtistNameAndImageLookup';
import {
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isSteppedSalesType,
  isBuyNowAndOffersSalesType,
  isBuyableSalesType,
  isReserveAuctionSalesType,
  isOffersSalesType,
  getNextStep,
  AVAILABLE_SALES_TYPES
} from '../../services/SaleTypes';
import editionUtils from '../../services/editionUtils'
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import Availability from '../Availability';
import TokenId from '../TokenId';
import ChangeEditionPrice from '../modal/edition/ChangeEditionPrice';
import ChangeSteppedEditionPrice from '../modal/edition/ChangeSteppedEditionPrice';
import SetOffchainMinBid from '../modal/SetOffchainMinBid';
import BurnEdition from '../modal/edition/BurnEdition';
import SetEditionUnlockableContent from '../modal/edition/unlockable/SetEditionUnlockableContent';
import {GET_EDITION_ACTIVE_OFFER} from '../../queries/offersQueries';
import ChangeEditionSaleType from '../modal/edition/ChangeEditionSaleType';
import TransferEditionTokenModal from '../modal/edition/TransferEditionTokenModal';
import ResultEditionReserveAuction from '../modal/edition/ResultEditionReserveAuction';
import {LISTED_TOKENS_FOR_EDITION} from '../../queries/tokenQueries';
import {isCreatorContract, splitCreatorEditionId} from '../../services/CreatorContractUtils'
import {handleGalleryRouting} from '../../services/routing';
import EndOpenEdition from '../modal/edition/EndOpenEdition';

export default {
  components: {
    TokenId,
    Availability,
    ArtistNameAndImageLookup
  },
  props: [
    'edition',
    'size',
    'phase',
    'clickHandler'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      like: false,
      editionOffers: null,
      tokens: null
    };
  },
  computed: {
    ...mapGetters('offerStore', [
      'getEditionMinimumOffer'
    ]),
    ...mapState('web3Store', [
      'chainId',
      'gqlClient',
      'account'
    ]),
    ...mapGetters('unlockableContentStore', [
      'hasUnlockableContent'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount'
    ]),
    workingMetadata() {
      if (this.edition.metadata) {
        return this.edition.metadata;
      }
      return this.metadataFromKoCache;
    },
    isSoldOutPrimary() {
      return editionUtils.isSoldOutPrimary(this.edition);
    },
    isLoggedInAccountViewingPage() {
      return this.isLoggedInAccount(this.account);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    },
    canSetUnlockableContent() {
      return this.edition && this.isLoggedInAccount(this.edition.artistAccount) && !isCreatorContract(this.edition)
    },
    canChangeGatedPublicPrice() {
      return this.phase && this.phase.state !== 'public' && this.canChangeBuyNowPrice
    },
    canChangeBuyNowPrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        (isBuyNowAndOffersSalesType(this.edition.salesType) || isBuyNowSalesType(this.edition.salesType)) &&
        !this.edition.offersOnly;
    },
    canChangeSteppedToBuyNowPrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isSteppedSalesType(this.edition.salesType) &&
        this.edition.remainingSupply === this.edition.totalAvailable &&
        !this.edition.offersOnly;
    },
    canChangeReservePrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isReserveAuctionSalesType(this.edition.salesType) &&
        (!this.reserveAuctionCountdownInProgress && !this.reserveAuctionCountdownComplete);
    },
    canSetOffchainMinimumBid() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isOffersOnlySalesType(this.edition.salesType)
    },
    canResultReserveAuction() {
      return isReserveAuctionSalesType(this.edition.salesType) &&
        this.isNotSoldOutPrimary(this.edition) &&
        this.reserveAuctionCountdownComplete &&
        ReserveAuctionUtils.canResultEditionReserveAuction(this.edition, this.account);
    },
    canTransferToken() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        return this.canChangeReservePrice;
      }
      if (this.isLoggedInAccount(this.edition.artistAccount) && this.edition.isOpenEdition && this.isContractPage) {
        return false;
      }
      return this.isLoggedInAccount(this.edition.artistAccount) && this.isNotSoldOutPrimary(this.edition);
    },
    canChangeSalesListing() {
      if (!isCreatorContract(this.edition)) {
        if (isReserveAuctionSalesType(this.edition.salesType)) {
          return this.canChangeReservePrice;
        }

        if (this.phase && this.phase.state !== 'public') {
          return false
        }

        return this.isLoggedInAccount(this.edition.artistAccount) &&
          this.isNotSoldOutPrimary(this.edition) &&
          AVAILABLE_SALES_TYPES[this.edition.version][this.edition.salesType].length > 0
      } else {
        return false
      }
    },
    isContractPage() {
      const regex = /\//;
      if (this.$nuxt.$route.path.substring(0, 10) === '/contract/' && !(regex.test(this.$nuxt.$route.path.substring(10, this.$nuxt.$route.path.length)))) {
        return true;
      }
      return false;
    },
    canBurnEdition() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        return this.canChangeReservePrice;
      }
      if (editionUtils.hasOpenEditionEndedWithNoSales(this.edition)) {
        return (this.isLoggedInAccount(this.edition.artistAccount))
      }
      return (this.isLoggedInAccount(this.edition.artistAccount) && this.isNotSoldOutPrimary(this.edition));
    },
    lowestListedToken() {
      if (!this.tokens || this.tokens.length === 0) {
        return null;
      }

      return this.tokens.length > 0 ? _head(this.tokens) : null;
    },
    handleMarketPlaceRouting() {
      return handleGalleryRouting(this.edition, this.clickHandler)
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  },
  async beforeMount() {
    if (!this.edition.metadata) {
      const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.edition.id}`);
      if (metadata) {
        this.metadataFromKoCache = metadata;
      }
    }
  },
  async mounted() {
    if (!isCreatorContract(this.edition)) {
      this.$apollo.addSmartQuery('editionOffers', {
        deep: false,
        query: GET_EDITION_ACTIVE_OFFER,
        client: this.gqlClient,
        variables() {
          return {
            edition: `edition-${this.edition.id ? this.edition.id : '0'}`
          };
        },
        error(error) {
          console.log('error', error);
        }
      });

      this.$apollo.addSmartQuery('tokens', {
        client: this.gqlClient,
        query: LISTED_TOKENS_FOR_EDITION,
        variables() {
          return {
            editionNumber: this.edition.id
          };
        },
        error(error) {
          console.log('error', error);
        }
      });
    }
  },
  methods: {
    splitCreatorEditionId,
    isCreatorContract,
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isSteppedSalesType,
    isBuyNowAndOffersSalesType,
    isBuyableSalesType,
    isReserveAuctionSalesType,
    isOffersSalesType,
    getNextStep,
    availability(edition) {
      return editionUtils.availability(edition);
    },
    isNotSoldOutPrimary(edition) {
      return editionUtils.isNotSoldOutPrimary(edition);
    },
    changePrice(edition) {
      if (!isSteppedSalesType(edition.salesType)) {
        this.$buefy.modal.open({
          parent: this,
          component: ChangeEditionPrice,
          width: 600,
          props: {edition},
          events: {
            close: () => {
              this.$emit('editions-refresh');
            }
          }
        });
      }
    },
    changeSteppedPrice(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: ChangeSteppedEditionPrice,
        width: 600,
        props: {edition},
        events: {
          close: () => {
            this.$emit('editions-refresh');
          }
        }
      });
    },
    setMinimumBid(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: SetOffchainMinBid,
        width: 600,
        props: {
          edition,
          type: 'edition'
        }
      })
    },
    changeSaleType(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: ChangeEditionSaleType,
        width: 600,
        props: {
          edition,
          type: 'edition'
        },
        events: {
          close: () => {
            this.$emit('editions-refresh');
          }
        }
      })
    },
    setUnlockableContent(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: SetEditionUnlockableContent,
        width: 600,
        props: {
          edition
        }
      })
    },
    burn(edition) {
      if (edition.isOpenEdition) {
        this.$buefy.modal.open({
          parent: this,
          component: EndOpenEdition,
          width: 600,
          props: {
            editionNumber: edition.id,
            metadata: edition.metadata
          },
          canCancel: true,
          events: {
            close: () => {
              this.$emit('editions-refresh');
            }
          }
        })
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: BurnEdition,
          width: 600,
          props: {edition},
          events: {
            close: () => {
              this.$emit('editions-refresh');
            }
          }
        })
      }
    },
    transferEditionToken(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: TransferEditionTokenModal,
        width: 600,
        props: {edition},
        events: {
          close: () => {
            this.$emit('editions-refresh');
          }
        }
      });
    },
    resultReserveAuction(edition) {
      this.$buefy.modal.open({
        parent: this,
        component: ResultEditionReserveAuction,
        width: 600,
        props: {
          edition
        },
        events: {
          close: () => {
            this.$apollo.queries.editionOffers.refetch();
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.ccArtworkInfoText {
  color: white;
}
</style>
