var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cover && _vm.cardAsset
    ? _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: { src: _vm.cardAsset, error: "/branding/holding_image.jpg" },
            expression: "{src: cardAsset, error:'/branding/holding_image.jpg'}",
          },
        ],
        attrs: {
          "data-loading": "/branding/bigloader-image.svg",
          "data-error": "/branding/holding_image.jpg",
        },
      })
    : _c(
        "model-viewer",
        {
          ref: "objectViewer",
          staticClass: "objectViewer",
          staticStyle: {
            background: "linear-gradient(#ffffff, #fafafa)",
            "text-align": "center",
            width: "100%",
            margin: "0 auto",
          },
          attrs: {
            src: _vm.assetUrl,
            poster: _vm.cardAsset,
            autoplay: "",
            "auto-rotate": "",
            ar: "",
            "magic-leap": "",
            loading: _vm.cover ? "lazy" : "eager",
            reveal: _vm.cover ? "manual" : "auto",
            "quick-look-browsers": "safari chrome",
            "camera-controls": "",
            "interaction-policy": "always-allow",
            "environment-image": "/3d/environments/lightroom_14b.hdr",
          },
          on: {
            progress: _vm.onProgress,
            error: _vm.onError,
            "ar-status": _vm.onArStatus,
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "progress-bar", id: "progress" },
              slot: "progress-bar",
            },
            [
              _c("div", { staticClass: "bar" }),
              _vm._v(" "),
              _c("div", [_vm._v("Loading Artwork")]),
            ]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }