var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-message",
    {
      staticClass: "is-size-7 my-5",
      attrs: { type: "is-warning", closable: false, duration: 0 },
    },
    [
      _c("p", [
        _vm._v(
          "\n    The creator of this contract has applied a marketplace filter. This ensures they receive royalties on secondary sales. You won’t be able to sell this edition on certain marketplaces.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "is-linkable",
          attrs: {
            href: "https://docs.knownorigin.io/en/articles/6884825-what-is-a-marketplace-blocker",
            target: "_blank",
          },
        },
        [_vm._v("Learn more here")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }