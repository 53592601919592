<template>
  <!-- small icons and names-->
  <div class="level is-mobile is-vcentered">
    <div class="level-left">
      <div class="level-item" v-if="account">
        <account-image :address="account"></account-image>
      </div>
      <div class="level-item content">
        <h4 v-if="nameOrShortEth && size === 'is-large'" :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">{{ nameOrShortEth }}</h4>
        <h6 v-else-if="nameOrShortEth" class="has-text-weight-medium drop-mb" :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">{{ nameOrShortEth | truncate(15) }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountImage from './common/AccountImage';

  export default {
    components: {AccountImage},
    props: ['account', 'size', 'isCc'],
    data: () => ({
      profile: null
    }),
    computed: {
      nameOrShortEth() {
        if (this.profile && this.profile.username) {
          return this.profile.username;
        }
        return this.$options.filters.shortEth(this.account)
      }
    },
    async mounted() {
      if (this.account) {
        this.profile = await this.$store.dispatch('userStore/getUser', this.account);
      }
    }
  };
</script>

<style scoped>
.drop-mb {
  margin-bottom: -0.15rem;
}
</style>
