<template>
    <section>
        <section>
            <b-field :label="requiredFieldsList.includes('streetAddress1') ? 'Address Line 1 *' : 'Address Line 1'"
                :type="{ 'is-danger': errors.streetAddress1 && isDirty.streetAddress1, 'is-success': streetAddress1 && streetAddress1.length > 0 && !errors.streetAddress1 }"
                :message="invalidStreetAddress1">
                <b-input type="text" v-model="streetAddress1" @input="updateFields" data-cy="address1_input" max="100">
                </b-input>
            </b-field>

            <b-field label="Address Line 2" class="mt-6"
                :type="{ 'is-danger': errors.streetAddress2 && isDirty.streetAddress2, 'is-success': streetAddress2 && streetAddress2.length > 0 && !errors.streetAddress2 }">
                <b-input type="text" v-model="streetAddress2" @input="updateFields" data-cy="address2_input" max="100">
                </b-input>
            </b-field>

            <b-field :label="requiredFieldsList.includes('city') ? 'City *' : 'City'" class="mt-6"
                :type="{ 'is-danger': errors.city && isDirty.city, 'is-success': city && city.length > 0 && !errors.city }"
                :message="invalidCity">
                <b-input type="text" v-model="city" @input="updateFields" data-cy="city_input" max="50">
                </b-input>
            </b-field>

            <b-field :label="requiredFieldsList.includes('state') ? 'State *' : 'State'" class="mt-6"
                v-if="country === 'US' || country === 'CA'"
                :type="{ 'is-danger': errors.state && isDirty.state, 'is-success': state && state.length > 0 && !errors.state }"
                :message="invalidState">
                <region-select :country="country" v-model="state" class="input" data-cy="state_input">
                </region-select>
            </b-field>

            <b-field :label="requiredFieldsList.includes('postal') ? `${postcodeName} *` : postcodeName" class="mt-6"
                :type="{ 'is-danger': errors.postal && isDirty.postal, 'is-success': postal && postal.length > 0 && !errors.postal }"
                :message="invalidPostal">
                <b-input type="text" v-model="postal" @input="updateFields" data-cy="postal_input" min="2" max="20">
                </b-input>
            </b-field>
        </section>
    </section>
</template>

<script>
import _size from 'lodash/size';
import _trim from 'lodash/trim';
export default {
    props: {
        addressStreet1: String,
        addressStreet2: String,
        addressCity: String,
        addressState: String,
        addressPostal: String,
        country: String,
        requiredFieldsList: Array
    },
    data() {
        return {
            isAddressValid: false,
            errors: {},
            streetAddress1: this.addressStreet1 || '',
            streetAddress2: this.addressStreet2 || '',
            city: this.addressCity || '',
            state: this.addressState || '',
            postal: this.addressPostal || '',
            isDirty: {
                streetAddress1: null,
                city: null,
                state: null,
                postal: null
            }
        }
    },
    computed: {
        postcodeName() {
            if (this.country === 'US') {
                return 'Zipcode'
            } else if (this.country === 'IE') {
                return 'Eircode'
            } else {
                return 'Postcode'
            }
        },
        invalidStreetAddress1() {
            if (this.isDirty.streetAddress1 && this.streetAddress1.length === 0 && this.requiredFieldsList.includes('streetAddress1')) {
                return 'Required field'
            }
            return null;
        },
        invalidCity() {
            if (this.isDirty.city && this.city.length === 0 && this.requiredFieldsList.includes('city')) {
                return 'Required field'
            }
            return null;
        },
        invalidState() {
            if (this.isDirty.state && this.state.length === 0 && this.requiredFieldsList.includes('state')) {
                return 'Required field'
            }
            return null;
        },
        invalidPostal() {
            if (this.isDirty.postal && this.postal.length === 0 && this.requiredFieldsList.includes('postal')) {
                return 'Required field'
            } else if (this.isDirty.postal && this.postal.length < 2) {
                return 'Minimum 2 characters'
            }
            return null;
        }
    },
    methods: {
        updateFields() {
            this.updateAddress();
            this.formIsValid();
        },
        updateAddress() {
            this.$emit(
                'updatedAddress',
                this.streetAddress1,
                this.streetAddress2,
                this.city,
                this.state,
                this.postal
            )
        },
        formIsValid() {
            this.isAddress1Valid();
            this.isAddress2Valid();
            this.isCityValid();
            this.isStateValid();
            this.isPostalCodeValid();
            this.$emit('isAddressValid', this.errors.length === 0)
        },
        isAddress1Valid() {
            if (this.streetAddress1 && this.streetAddress1.length > 0) {
                this.isDirty.streetAddress1 = true;
            }
            this.errors.streetAddress1 = (_size(_trim(this.streetAddress1)) === 0 || _size(_trim(this.streetAddress1)) > 100) && this.requiredFieldsList.includes('streetAddress1');
        },
        isAddress2Valid() {
            if (this.streetAddress2 && this.streetAddress2.length > 0) {
                this.isDirty.streetAddress2 = true;
            }
            this.errors.streetAddress2 = (_size(_trim(this.streetAddress2)) === 0 || _size(_trim(this.streetAddress2)) > 100);
        },
        isCityValid() {
            if (this.city && this.city.length > 0) {
                this.isDirty.city = true;
            }
            this.errors.city = (_size(_trim(this.city)) === 0 || _size(_trim(this.city)) > 50) && this.requiredFieldsList.includes('city');
        },
        isStateValid() {
            if (this.state && this.state.length > 0) {
                this.isDirty.state = true;
            }
            this.errors.state = (this.country === 'US' || this.country === 'CA') && !this.state && this.requiredFieldsList.includes('state');
        },
        isPostalCodeValid() {
            if (this.postal && this.postal.length > 0) {
                this.isDirty.postal = true;
            }
            this.errors.postal = (_size(_trim(this.postal)) < 2 || _size(_trim(this.postal)) > 20) && this.requiredFieldsList.includes('postal');
        }
    }
}
</script>
<styles>
</styles>
