var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "column is-half has-padding-left-3 has-padding-top-0 is-flex is-align-items-center",
      class: {
        "countdown-started":
          _vm.reserveAuctionCountdownStarted &&
          !_vm.reserveAuctionCountdownComplete &&
          !_vm.isCc,
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "has-text-weight-bold has-text-left" },
          [
            _vm._t(
              "asset-card-footer-left-title",
              function () {
                return [
                  _c(
                    "n-link",
                    {
                      staticClass: "asset-card-wrapped-link is-family-tertiary",
                      class: { ccAssetText: _vm.isCc },
                      attrs: { to: _vm.handleMarketPlaceRouting },
                    },
                    [
                      _vm.phase &&
                      _vm.phase.state !== "public" &&
                      _vm.isNotSoldOutPrimary
                        ? _c("div", { attrs: { "data-testid": "saleType" } }, [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  class: {
                                    "is-muted":
                                      _vm.phase.state === "pre-phase" &&
                                      !_vm.isCc,
                                    "has-text-white": _vm.isCc,
                                  },
                                },
                                [_vm._v("Early access")]
                              ),
                            ]),
                          ])
                        : !_vm.isSoldOutPrimary
                        ? _c(
                            "span",
                            {
                              class: {
                                "has-text-dark": !_vm.isCc,
                                "has-text-white": _vm.isCc,
                              },
                            },
                            [
                              _vm.isOffersOnlySalesType(
                                _vm.edition.salesType
                              ) &&
                              _vm.editionOffers &&
                              _vm.editionOffers.length > 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n              Current bid\n            "
                                    ),
                                  ])
                                : _vm.isOffersOnlySalesType(
                                    _vm.edition.salesType
                                  )
                                ? _c(
                                    "span",
                                    {
                                      class: {
                                        "has-text-grey":
                                          !_vm.startDatePassed && !_vm.isCc,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Place a bid\n            "
                                      ),
                                      _vm.getEditionMinimumOffer(_vm.edition)
                                        ? _c(
                                            "span",
                                            {
                                              class: {
                                                "has-text-grey": !_vm.isCc,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              (minimum)\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.isBuyNowAndOpenSalesType(
                                    _vm.edition.salesType
                                  )
                                ? _c(
                                    "span",
                                    {
                                      class: {
                                        "has-text-grey":
                                          !_vm.startDatePassed && !_vm.isCc,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Open edition\n            "
                                      ),
                                    ]
                                  )
                                : _vm.isBuyableSalesType(_vm.edition.salesType)
                                ? _c(
                                    "span",
                                    {
                                      class: {
                                        "has-text-grey":
                                          !_vm.startDatePassed && !_vm.isCc,
                                      },
                                      attrs: { "data-testid": "saleType" },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Buy now\n            "
                                      ),
                                    ]
                                  )
                                : _vm.reserveAuctionCountdownInProgress
                                ? _c(
                                    "span",
                                    { class: { ccAssetText: _vm.isCc } },
                                    [
                                      _vm._v(
                                        "\n              Current bid\n            "
                                      ),
                                    ]
                                  )
                                : _vm.reserveAuctionCountdownComplete
                                ? _c(
                                    "span",
                                    { class: { ccAssetText: _vm.isCc } },
                                    [
                                      _vm._v(
                                        "\n              Sold for\n            "
                                      ),
                                    ]
                                  )
                                : _vm.isReserveAuctionSalesType(
                                    _vm.edition.salesType
                                  )
                                ? _c(
                                    "span",
                                    {
                                      class: {
                                        "has-text-grey":
                                          !_vm.startDatePassed && !_vm.isCc,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Reserve price\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _c("span", [
                            _vm.lowestListedToken &&
                            _vm.isBuyNowSalesType(
                              _vm.lowestListedToken.salesType
                            )
                              ? _c(
                                  "span",
                                  { attrs: { "data-testid": "saleType" } },
                                  [
                                    _vm._v(
                                      "\n              Buy now\n            "
                                    ),
                                  ]
                                )
                              : _vm.lowestListedToken &&
                                _vm.isReserveAuctionSalesType(
                                  _vm.lowestListedToken.salesType
                                )
                              ? _c("span", [
                                  _vm._v(
                                    "\n              Reserve price\n            "
                                  ),
                                ])
                              : parseInt(_vm.edition.totalAvailable) === 1 &&
                                parseFloat(_vm.edition.totalEthSpentOnEdition) >
                                  0
                              ? _c("span", [
                                  _vm._v(
                                    "\n              Sold for\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isBuyNowAndOpenSalesType(
                                        _vm.edition.salesType
                                      )
                                        ? _vm.edition.totalSold
                                        : _vm.edition.totalAvailable
                                    ) + " Editions"
                                  ),
                                ]),
                          ]),
                    ]
                  ),
                ]
              },
              { dataTestid: "cardFooter" }
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "has-text-weight-semibold has-text-left" },
          [
            _c(
              "n-link",
              {
                staticClass: "asset-card-wrapped-link is-family-tertiary",
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
                attrs: { to: _vm.handleMarketPlaceRouting },
              },
              [
                _vm._t("asset-card-footer-left", function () {
                  return [
                    _vm.phase && _vm.phase.state !== "public"
                      ? _c(
                          "div",
                          { class: { "has-text-white": _vm.isCc } },
                          [
                            _vm.phase.priceInWei === "0"
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "has-text-weight-semibold",
                                    class: {
                                      "is-muted":
                                        _vm.phase.state === "pre-phase" &&
                                        !_vm.isCc,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Free claim\n            "
                                    ),
                                  ]
                                )
                              : _c("eth-with-fiat-price", {
                                  staticClass: "has-text-weight-semibold",
                                  class: {
                                    "is-muted":
                                      _vm.phase.state === "pre-phase" &&
                                      _vm.isCc,
                                  },
                                  attrs: {
                                    "price-in-wei": _vm.phase.priceInWei,
                                  },
                                }),
                          ],
                          1
                        )
                      : !_vm.isSoldOutPrimary
                      ? _c("span", [
                          !_vm.isOffersSalesType(_vm.edition.salesType)
                            ? _c(
                                "span",
                                [
                                  _c("edition-price", {
                                    class: {
                                      "has-text-grey":
                                        !_vm.startDatePassed && !_vm.isCc,
                                      "has-text-white": _vm.isCc,
                                    },
                                    attrs: { edition: _vm.edition },
                                  }),
                                ],
                                1
                              )
                            : _vm.isOffersOnlySalesType(
                                _vm.edition.salesType
                              ) &&
                              _vm.editionOffers &&
                              _vm.editionOffers.length > 0
                            ? _c(
                                "span",
                                [
                                  _c("eth-with-fiat-price", {
                                    class: { "has-text-white": _vm.isCc },
                                    attrs: {
                                      "price-in-wei":
                                        _vm.editionOffers[0].weiValue,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.getEditionMinimumOffer(_vm.edition) &&
                              _vm.getEditionMinimumOffer(_vm.edition)
                                .eth_reserve_in_wei &&
                              _vm.getEditionMinimumOffer(_vm.edition)
                                .eth_reserve_in_wei > 0
                            ? _c(
                                "span",
                                {
                                  class: {
                                    "has-text-grey":
                                      !_vm.startDatePassed && !_vm.isCc,
                                  },
                                },
                                [
                                  _c("eth-with-fiat-price", {
                                    class: { "has-text-white": _vm.isCc },
                                    attrs: {
                                      "price-in-wei":
                                        _vm.getEditionMinimumOffer(_vm.edition)
                                          .eth_reserve_in_wei,
                                    },
                                  }),
                                ],
                                1
                              )
                            : (_vm.reserveAuctionCountdownInProgress ||
                                _vm.reserveAuctionCountdownComplete) &&
                              _vm.edition.activeBid
                            ? _c(
                                "span",
                                [
                                  _c("eth-with-fiat-price", {
                                    class: { "has-text-white": _vm.isCc },
                                    attrs: {
                                      "price-in-eth":
                                        _vm.edition.activeBid.ethValue,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.isReserveAuctionSalesType(
                                _vm.edition.salesType
                              )
                            ? _c(
                                "span",
                                {
                                  class: {
                                    "has-text-grey":
                                      !_vm.startDatePassed && !_vm.isCc,
                                  },
                                },
                                [
                                  _c("eth-with-fiat-price", {
                                    class: { "has-text-white": _vm.isCc },
                                    attrs: {
                                      "price-in-wei": _vm.edition.reservePrice,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "is-family-tertiary",
                                  class: {
                                    "has-text-grey":
                                      !_vm.startDatePassed && !_vm.isCc,
                                    "has-text-white": _vm.isCc,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                No reserve\n              "
                                  ),
                                ]
                              ),
                        ])
                      : _c(
                          "span",
                          { staticClass: "has-text-weight-semibold" },
                          [
                            _vm.lowestListedToken
                              ? _c(
                                  "span",
                                  [
                                    _vm.isBuyNowSalesType(
                                      _vm.lowestListedToken.salesType
                                    )
                                      ? _c("eth-with-fiat-price", {
                                          class: { "has-text-white": _vm.isCc },
                                          attrs: {
                                            "price-in-eth":
                                              _vm.lowestListedToken.listPrice,
                                          },
                                        })
                                      : _vm.isReserveAuctionSalesType(
                                          _vm.lowestListedToken.salesType
                                        )
                                      ? _c("eth-with-fiat-price", {
                                          class: { "has-text-white": _vm.isCc },
                                          attrs: {
                                            "price-in-wei":
                                              _vm.lowestListedToken.listing
                                                .reservePrice,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : parseInt(_vm.edition.totalAvailable) === 1 &&
                                parseFloat(_vm.edition.totalEthSpentOnEdition) >
                                  0
                              ? _c(
                                  "span",
                                  [
                                    _c("eth-with-fiat-price", {
                                      class: { "has-text-white": _vm.isCc },
                                      attrs: {
                                        "price-in-eth":
                                          _vm.edition.totalEthSpentOnEdition ||
                                          0,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.edition.totalSold > 0
                              ? _c("span", { staticClass: "has-text-danger" }, [
                                  _vm._v("Sold out"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }