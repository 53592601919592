<template>
  <div class="level is-mobile">
    <div class="level-left">
      <div>
        <n-link :to="{name:'profile-id', params: { id: address}}">
        <h3 class="is-family-secondary">{{ nameOrShortEth }}</h3>
        </n-link>
        <small v-if="message">{{ message }}</small>
      </div>

    </div>
    <div class="level-right mb-2">
      <n-link :to="{name:'profile-id', params: { id: address}}">
        <account-image :address="address"></account-image>
      </n-link>
    </div>
  </div>
</template>

<script>
import AccountImage from '../common/AccountImage';
export default {
  components: {AccountImage},
  props: ['address', 'message'],
  data() {
    return {
      name: ''
    }
  },
  computed: {
    nameOrShortEth() {
      if (this.name && this.name.startsWith('0x')) {
        return this.$options.filters.shortEth(this.name);
      }

      // Catch for MoCDA
      if (this.name === 'MoCDA Museum of Contemporary Digital Art') { return 'MoCDA' }

      return this.name;
    }
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      let name = this.name;
      if (!this.name) {
        try {
          const res = await this.$store.dispatch('userStore/getUser', this.address);
          if (res.username) {
            name = res.username;
          } else {
            name = this.artistAccount;
          }
        } catch (err) {
          name = this.artistAccount;
        }
      }
      this.name = name;
    }
  }
}
</script>
