var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    { attrs: { edition: _vm.edition, "transaction-hash": null } },
    [
      _c("template", { slot: "content-title" }, [
        _c("p", [_vm._v("\n      Set minimum bid\n    ")]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _c(
          "section",
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: "Enter minimum bid price",
                  message: `Min. sale price is ${_vm.minAmount}`,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    placeholder: "1.23",
                    expanded: "",
                    autofocus: "",
                    type: "number",
                    step: "any",
                  },
                  model: {
                    value: _vm.form.priceInWei,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "priceInWei", $$v)
                    },
                    expression: "form.priceInWei",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.minBid && _vm.minBid.eth_reserve_in_wei
              ? _c(
                  "p",
                  [
                    _vm._v("\n        Current minimum bid:\n        "),
                    _c("eth-with-fiat-price", {
                      attrs: {
                        "price-in-wei": _vm.minBid.eth_reserve_in_wei,
                        "show-fiat": true,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n        Setting an minimum bid "),
              _c("span", { staticClass: "has-text-weight-semibold" }, [
                _vm._v("for free"),
              ]),
              _vm._v(
                " is a way to signal to buyers that there is a minimum price which you will consider\n        before accepting.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "has-text-weight-semibold" }, [
              _vm._v(
                "\n        You are still responsible for resulting any auction.\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.response && _vm.response.success
              ? _c(
                  "p",
                  [
                    _vm._v("\n        Minimum bid set\n        "),
                    _c("b-icon", {
                      attrs: { icon: "check-circle-outline", size: "is-small" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("p", { staticClass: "has-text-danger" }, [
                  _vm._v("\n        Minimum bid not set!\n      "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                expanded: "",
                disabled: _vm.disableButton,
                loading: _vm.loading,
              },
              on: { click: _vm.changePrice },
            },
            [_vm._v("\n      Set minimum bid\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }