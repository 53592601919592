var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { "overflow-wrap": "anywhere" },
      attrs: { "data-cy": "artist_bio" },
    },
    [
      _vm._l(_vm.formatDescription, function (para, index) {
        return _c(
          "span",
          { key: index, staticClass: "is-block pb-4 is-family-primary" },
          _vm._l(para, function (line, index) {
            return _c(
              "span",
              { key: index, staticClass: "m-0 p-0 is-block is-family-primary" },
              [_c("span", { domProps: { innerHTML: _vm._s(line) } })]
            )
          }),
          0
        )
      }),
      _vm._v(" "),
      (_vm.descriptionLength === 300 && _vm.linkifyDescription.length > 300) ||
      (_vm.descriptionLength === 150 && _vm.linkifyDescription.length > 150)
        ? _c(
            "span",
            {
              staticClass:
                "heading is-clickable has-text-weight-bold is-family-tertiary",
              on: { click: _vm.moreDescription },
            },
            [
              _vm._v("\n    Expand "),
              _c("b-icon", {
                attrs: { icon: "arrow-expand-down", size: "is-small" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }