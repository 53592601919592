<template>
  <b-message type="is-warning" :closable="false" :duration="0" class="is-size-7 my-5">
    <p>
      The creator of this contract has applied a marketplace filter. This ensures they receive royalties on secondary sales. You won’t be able to sell this edition on certain marketplaces.
    </p>
    <a href="https://docs.knownorigin.io/en/articles/6884825-what-is-a-marketplace-blocker"
       target="_blank"
       class="is-linkable">Learn more here</a>
  </b-message>
</template>

<script>
export default {
  name: 'MarketplaceBlockerMessage'
};
</script>

<style scoped>

</style>
