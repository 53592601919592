var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section has-background-black pt-6 pb-5 mt-6" },
    [
      _c("div", { staticClass: "container pt-4 pb-3" }, [
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-one-third has-text-left mb-5" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "h1",
                  { staticClass: "title has-text-white is-size-3 mb-2" },
                  [_vm._v("Discover and collect")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "has-text-white mb-5" }, [
                  _vm._v(
                    "\n              KnownOrigin gives artists a place to create and collect unique, authentic, digital collectibles in the form of NFTs using blockchain technology.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "n-link",
                  {
                    staticClass:
                      "button is-white is-outlined is-family-tertiary",
                    attrs: { to: "/journal" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleItemClickInsights(
                          "button",
                          "",
                          "learn more",
                          "view journal"
                        )
                      },
                    },
                  },
                  [_vm._v("\n              Learn more\n            ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column is-one-third has-text-left mb-5" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "h1",
                  { staticClass: "title has-text-white is-size-3 mb-2" },
                  [_vm._v("Become an artist")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "has-text-white mb-5" }, [
                  _vm._v(
                    "\n              Join our community of creatives, showcase and sell your digital artworks. Digitally sign your work by creating a tokenized certificate.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "n-link",
                  {
                    staticClass:
                      "button is-white is-outlined is-family-tertiary",
                    attrs: { to: "/apply" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleItemClickInsights(
                          "button",
                          "",
                          "apply to join",
                          "apply page"
                        )
                      },
                    },
                  },
                  [_vm._v("\n              Apply to join\n            ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column is-one-third has-text-left mb-5" }, [
            _c("div", { staticClass: "content" }, [
              _c("h1", { staticClass: "title has-text-white is-size-3 mb-2" }, [
                _vm._v("Join our community"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "has-text-white mb-5" }, [
                _vm._v(
                  "\n              Meet the KnownOrigin team, artists, collectors, and get platform updates, announcements and more in our Discord.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button is-white is-outlined is-family-tertiary",
                  attrs: {
                    href: "https://discord.gg/knownorigin",
                    target: "_blank",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleItemClickInsights(
                        "button",
                        "",
                        "join the discord",
                        "discord join link"
                      )
                    },
                  },
                },
                [_vm._v("\n              Launch Discord\n            ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }