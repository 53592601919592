<template>
    <div class="content box-fat">

      <figure class="avatar"
              :style="{ 'background-image': 'url(' + coverImage + ')'}"
              style="background-size: cover; border-radius: 0.5rem 0.5rem 0 0">
        <img class="is-rounded"
             v-lazy="{
                src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${userAddress}`,
                error: createAccountIcon()
            }">
      </figure>

      <section class="content is-centered px-3" v-if="profile">
        <div class="has-text-centered is-size-4">
          <span class="is-family-secondary">{{ nameOrShortEth }}</span>
          <b-icon icon="check-decagram" size="is-small"
                  class="ml-1 is-size-5 is-size-6-mobile is-verified"
                  v-if="profile && isTwitterVerified(userAddress.toLowerCase(), profile.twitter)">
          </b-icon>
        </div>
        <div class="has-text-centered mt-3">
          <b-tag class="is-family-primary is-uppercase">
            {{ profile.isArtist ? 'ARTIST' : 'COLLECTOR' }}
          </b-tag>
        </div>
      </section>

      <section class="content pl-4 pr-4" v-if="profile" style="height: 55px; overflow: hidden;">
        <p class="has-text-centered">
          {{ (profile.bio || '') }}
        </p>
      </section>

      <section class="content px-5 mb-2" v-if="profile">
        <div class="level is-mobile">
          <div class="level-left" v-if="profile.twitter">
            <b-icon icon="twitter" size="is-small"></b-icon>
            <a :href="`https://twitter.com/${cleanUpUrl(profile.twitter)}`" target="_blank">
              <span class="ml-1">@{{ cleanUpUrl(profile.twitter) }}</span>
            </a>
          </div>
          <div class="level-left" v-if="profile.instagram">
            <b-icon icon="instagram" size="is-small"></b-icon>
            <a :href="`https://www.instagram.com/${cleanUpUrl(profile.instagram)}`" target="_blank">
              <span class="ml-1">@{{ cleanUpUrl(profile.instagram) }}</span>
            </a>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
import {toDataUrl} from 'myetherwallet-blockies';
import {mapGetters} from 'vuex';
import {cleanUpUrl} from '../../services/utils';

export default {
  components: {},
  props: ['address', 'skinny'],
  data() {
    return {
      profile: null,
      coverImage: '/branding/cover-preview.jpg'
    };
  },
  computed: {
    ...mapGetters('userStore', [
      'isInstagramVerified',
      'isTwitterVerified',
      'isEitherProfileVerified'
    ]),
    userAddress() {
      return this.profile ? this.profile.address : this.address;
    },
    nameOrShortEth() {
      if (this.profile && this.profile.username) {
        return this.profile.username;
      }
      return this.$options.filters.shortEth(this.address);
    }
  },
  watch: {
    address (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(async () => {
          this.profile = await this.$store.dispatch('userStore/getUser', this.address);
          this.$forceUpdate();
        });
      }
    }
  },
  async mounted() {
    try {
      this.profile = await this.$store.dispatch('userStore/getUser', this.address);
      this.setCoverImage();
    } catch (e) {
      console.log('Problem with cover image');
    }
  },
  methods: {
    cleanUpUrl,
    createAccountIcon() {
      return toDataUrl(this.userAddress);
    },
    setCoverImage() {
      if (this.profile && this.profile.coverImageUrl) {
        const highResImage = new Image();
        highResImage.onload = () => {
          this.coverImage = this.profile.coverImageUrl;
        };
        highResImage.src = this.profile.coverImageUrl;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.box-fat {
  margin-top: 2rem;
  height: 28rem;
  border: 1px $grey-light solid;
  border-radius: 0.5rem;
}

.avatar {
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  width: 128px;
  height: 128px;
}
</style>
