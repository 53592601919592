<template>
    <article>
        <section style="min-height: 600px;">
        <nuxt />
        </section>
    </article>
</template>

<script>

import {mapState, mapActions} from 'vuex';

if (process.client) {
    // Polly fills ... unsure of direct impact of these yet ...
    require('resize-observer-polyfill');
    require('intersection-observer');
    require('focus-visible');
    require('@webcomponents/webcomponentsjs');

    // register model viewer web component
    require('@google/model-viewer');
}

export default {
    computed: {
    ...mapState('web3Store', ['account'])
    },
    async mounted() {
    window.onNuxtReady(() => {
        console.log('App finished loading - onNuxtReady()');
    });

    this.$store.dispatch('getUsdPrice');

    await this.bootMessenger();

    // if (this.account) {
    //   this.checkForPromoNfts();
    // }
    },
    methods: {
    //  set up messenger actions here.
    ...mapActions('analytics/intercomStore', ['bootMessenger'])
    },

    errorCaptured(err, vm, info) {
    console.log('Global error found, logging', err, info);
    }
};
</script>

<style lang="scss" scoped>
.is-fixed-white-background {
    left: 0;
    position: fixed;
    right: 0;
    top: -20px;
    z-index: 20;
}
</style>
