<template>
  <div class="" v-if="curationDataBlobs && curationDataBlobs.length > 0">
    <div v-for="(curation, i) in curationDataBlobs" :key="i" class="mt-4">
      <section class="mb-2">
        <div class="box">
          <artist-badge-header :address="curation.ethAddress" :message="getMessage(curation)">
          </artist-badge-header>
          <div class="media-content">
            <div class="content">
              <p>
                <b-icon icon="format-quote-open" size="is-medium"></b-icon>
                {{ quotes[i] }}
              </p>
              <div v-if="showMoreToggles[i]">
                <span
                  v-if="showMoreToggles[i] === 1"
                  class="heading is-clickable pt-2"
                  @click="toggleMoreDescription(curation.quote, i)"
                >Show more <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
              </span>
                <span
                  v-if="showMoreToggles[i] === 2"
                  class="heading is-clickable pt-2"
                  @click="toggleMoreDescription(curation.quote, i)"
                >Show less <b-icon icon="arrow-expand-up" size="is-small"></b-icon>
              </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import ArtistBadgeHeader from '../avatar/ArtistBadgeHeader';

export default {
  components: {
    ArtistBadgeHeader
  },
  props: ['editionId'],
  data() {
    return {
      staticDescriptionLength: 150,
      curationDataBlobs: [],
      quotes: [],
      showMoreToggles: []
    };
  },
  computed: {
    displayName() {
      if (this.displayName && this.displayName.startsWith('0x')) {
        return this.$options.filters.shortEth(this.displayName);
      }
      return this.displayName;
    }
  },
  mounted() {
    this.loadCurationData();
  },
  methods: {
    async loadUser() {
      let name = this.name;
      if (!this.name) {
        try {
          const res = await this.$store.dispatch('userStore/getUser', this.artistAccount);
          if (res.username) {
            name = res.username;
          } else {
            name = this.artistAccount;
          }
        } catch (err) {
          name = this.artistAccount;
        }
      }
      this.displayName = name;
    },
    getMessage(curation) {
      if (curation.curationType === 'mocda') { return 'Curated by MoCDA'; }
      if (curation.curationType === 'ambassador') { return `Selected by ${curation.ambassadorType} Ambassador`; }
      if (curation.curationType === 'drop') { return 'Featured in Drops'; }
    },
    // Loads curation data from API
    async loadCurationData() {
      const curationDataBlob = await this.$store.dispatch('cmsStore/getEditionCurationData', this.editionId);
      if (curationDataBlob) {
        this.curationDataBlobs = curationDataBlob.curationData;

        this.curationDataBlobs.forEach((item, i) => {
          if (item.quote.length > this.staticDescriptionLength) {
            this.quotes[i] = `${item.quote.slice(0, this.staticDescriptionLength)}...`;
            this.showMoreToggles[i] = 1;
          } else {
            this.quotes[i] = item.quote;
          }
        });
      }
    },
    toggleMoreDescription(quote, i) {
      if (this.quotes[i].length === quote.length) {
        this.$set(this.quotes, i, `${quote.slice(0, this.staticDescriptionLength)}...`);
        this.$set(this.showMoreToggles, i, 1);
      } else {
        this.$set(this.quotes, i, quote);
        this.$set(this.showMoreToggles, i, 2);
      }
    }
  }
};

</script>
