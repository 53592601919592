var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item && !_vm.contractBlocked && _vm.transferState !== 1
    ? _c(
        "div",
        {
          staticClass:
            "is-full columns is-flex-mobile my-0 search-item-style__invert rounded-box",
          class: { "search-item-style-top": _vm.index === 0 },
          on: {
            click: function ($event) {
              return _vm.handleContractRouting(_vm.item)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "is-flex is-align-items-center search-rounded-image-container",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: {
                      src: _vm.imageUrl,
                      error: _vm.createContractImage(),
                    },
                    expression:
                      "{\n        src: imageUrl,\n        error: createContractImage(),\n      }",
                  },
                ],
                staticClass: "rounded-image",
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "column is-centered px-3 pr-0 is-inline-flex is-justify-content-space-between",
            },
            [
              _c("div", { staticClass: "is-flex is-align-items-center" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "has-text-left is-size-6 ml-1 has-text-weight-light is-family-secondary",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.item.name.length > _vm.textWrapCap
                            ? _vm.item.name.substring(0, _vm.textWrapCap) +
                                "..."
                            : _vm.item.name
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.owner && _vm.owner.username
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "is-size-7 ml-3 has-text-weight-light is-family-tertiary contract-owner-text",
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.owner.username) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.contractStats
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "has-text-weight-medium is-family-tertiary",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.contractStats.totalNumOfEditions) +
                            " Edition" +
                            _vm._s(
                              parseInt(_vm.contractStats.totalNumOfEditions) >
                                1 ||
                                parseInt(
                                  _vm.contractStats.totalNumOfEditions
                                ) === 0
                                ? "s"
                                : ""
                            )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }