<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      <p>
        {{ isReserveAuctionSalesType(edition.salesType) ? 'Change reserve price' : 'Change price' }}
      </p>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <template slot="secondary-content">
      <section>
        <b-field :label="isReserveAuctionSalesType(edition.salesType) ? 'Enter new reserve price' : 'Enter new price'"
                 :message="`Min. sale price is ${minAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   v-model="form.priceInEth"
                   type="number"
                   :min="0"
                   :max="100000"
                   step="any">
          </b-input>
        </b-field>
        <p v-if="!edition.offersOnly && isBuyNowSalesType(edition.salesType)">
          Current price
          <eth-with-fiat-price :price-in-wei="edition.priceInEth" />
        </p>
        <p v-else-if="isReserveAuctionSalesType(edition.salesType)">
          Current reserve price
          <eth-with-fiat-price :price-in-wei="edition.reservePrice" />
        </p>
        <div v-if="isReserveAuctionSalesType(edition.salesType)" v-cloak>
          <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
            Lowering the reserve price below an existing bid will trigger the 24hr countdown and the auction wll
            begin.
          </b-message>
        </div>
      </section>
    </template>

    <template slot="action-feedback-label">
      {{ isReserveAuctionSalesType(edition.salesType) ? 'Changing reserve price' : 'Changing price' }}
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                :disabled="disableButton"
                @click="changePrice">
        Change price
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth} from '../../../services/utils';
import {
  isReserveAuctionSalesType,
  isBuyNowSalesType
} from '../../../services/SaleTypes';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    EthWithFiatPrice,
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition'],
  data() {
    return {
      minAmount: listOrOfferAmountFloorInEth,
      transactionHash: null,
      form: {
        priceInEth: null
      }
    };
  },
  computed: {
    disableButton() {
      if (this.transactionHash) {
        return true;
      }
      return (Number(this.form.priceInEth || 0) < this.minAmount);
    }
  },
  methods: {
    isReserveAuctionSalesType,
    isBuyNowSalesType,
    close() {
      this.$emit('close');
    },
    async changePrice() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/changePrice', {
          edition: this.edition.id,
          version: this.edition.version,
          salesType: this.edition.salesType,
          priceInWei: ethers.utils.parseEther(this.form.priceInEth).toString(),
          creatorContract: this.edition.creatorContract
        });

        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
