<template>
    <span v-if="profile && profile.username" class="is-family-secondary">
      {{ profile.username || (profile.address | shortEth)}}
    </span>
    <span v-else-if="profile && profile.address" class="is-family-secondary">{{ profile.address | shortEth}}</span>
    <span v-else class="is-family-secondary">{{ ethAccount | shortEth}}</span>
</template>

<script>
  export default {
    props: ['ethAccount'],
    data: () => ({
      profile: null
    }),
    async mounted() {
      if (this.ethAccount) {
        this.profile = await this.$store.dispatch('userStore/getUser', this.ethAccount);
      }
    }
  };
</script>
