var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.parentOfChildNft || _vm.childToken
    ? _c("article", { staticClass: "tile is-child pt-5" }, [
        _vm.parentOfChildNft
          ? _c(
              "div",
              [
                _c("p", { staticClass: "title" }, [_vm._v("Contained within")]),
                _vm._v(" "),
                _c("single-embedded-nft", {
                  attrs: {
                    token: _vm.parentOfChildNft,
                    darkmode: _vm.darkmode,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.childToken
          ? _c(
              "div",
              { class: { "pt-5": _vm.parentOfChildNft } },
              [
                _c("p", { staticClass: "title" }, [_vm._v("Contains")]),
                _vm._v(" "),
                _c(
                  "single-embedded-nft",
                  { attrs: { token: _vm.childToken, darkmode: _vm.darkmode } },
                  [
                    _c(
                      "template",
                      { slot: "right-side-element" },
                      [_vm._t("right-side-element")],
                      2
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }