<template>
  <div>
    <div class="level is-mobile"
         :class="{'box has-background-dark has-text-light': darkmode}"
         v-if="tokenDetails && collectionDetails"
         v-cloak>
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <div>
            <img class="thumbnail-image-cover"
                 :src="tokenDetails.image_url"
                 :alt="tokenDetails.token_id"
                 v-if="tokenDetails.image_url">
          </div>
        </div>
        <slot name="token-information">
          <div class="column">
            <div class="has-text-weight-medium is-size-6 is-family-primary">
              {{ truncateName(tokenDetails.name, 35) }}
            </div>
            <div class="is-muted is-size-6 is-family-primary mt-1 mb-2">
              {{ truncateName(tokenDetails.description, 28) }}
            </div>
            <slot name="external-link">
              <div class="level is-size-7">
                <div class="level-left">
                  <div v-if="isKOCollection(collectionDetails.address)">
                    <img src="/favicon.ico" class="image is-16x16 mr-1" :alt="tokenDetails.name">
                    <a target="_blank"
                       :class="{'has-text-light': darkmode}"
                       :href="knownOriginTokenLink(tokenDetails.token_id, tokenDetails.linkType)">
                      <b-icon icon="open-in-new" size="is-small"></b-icon>
                      View on KnownOrigin
                    </a>
                  </div>
                  <div v-else>
                    <img :src="collectionDetails.image_url ? collectionDetails.image_url : '/opensea.png'"
                         class="image is-16x16 mr-1"
                         :alt="tokenDetails.name">
                    <a target="_blank" :class="{'has-text-light': darkmode}"
                       :href="openSeaTokenLink(collectionDetails.address, tokenDetails.token_id)">
                      <b-icon icon="open-in-new" size="is-small"></b-icon>
                      View on OpenSea
                    </a>
                  </div>
                </div>
              </div>
            </slot>
          </div>
        </slot>
      </div>
      <slot name="right-side-element" class="is-pulled-right">
      </slot>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import _get from 'lodash/get';
import {TOKEN_BY_ID_QUERY} from '../../queries/tokenQueries';

export default {
  props: [
    'darkmode',
    'token'
  ],
  data() {
    return {
      tokenDetails: null,
      collectionDetails: null
    }
  },
  computed: {
    ...mapGetters('web3Store', [
      'isKOCollection',
      'knownOriginTokenLink',
      'openSeaTokenLink'
    ]),
    ...mapState('web3Store', [
      'gqlClient',
      'chainId'
    ])
  },
  async mounted() {
    const {token, collection} = await this.$store.dispatch('enhancedTokensStore/getNftTokenMetadata', {
      contractAddress: this.token.address,
      tokenId: this.token.value
    });
    this.tokenDetails = token;
    this.collectionDetails = collection;

    if (this.token.linkType) {
      this.tokenDetails.linkType = this.token.linkType;
    }

    // Populate missing image data if not found and a KO asset
    const isMissingImageUrl = _get(this.tokenDetails, 'image_url.length', 0) === 0;
    if (isMissingImageUrl && this.isKOCollection(this.token.address)) {
      const result = await this.$apollo
        .query({
          client: this.gqlClient,
          query: TOKEN_BY_ID_QUERY,
          variables: {id: this.token.value},
          fetchPolicy: 'no-cache'
        })
        .then(({data}) => data && data.tokenById);
      this.tokenDetails.image_url = this.thumbnailAsset(result.editionNumber);
    }
  },
  methods: {
    truncateName(name, max) {
      return name.length > max ? name.substring(0, max - 3) + '...' : name;
    },
    thumbnailAsset(editionNumber) {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${editionNumber}/thumbnail`;
    }
  }
};
</script>
