<template>
  <div ref="autocompleteContainer"></div>
</template>

<script>
// KEEP This as vue2, default is vue3 and it breaks everything
import { createWidgetMixin } from 'vue-instantsearch/vue2/es';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

// DONT CHANGE THIS IMPORT, neads to be a direct import of CSS file or webpack packs up...
import '@algolia/autocomplete-theme-classic/dist/theme.css'
import algoliasearch from 'algoliasearch/lite';

const INSTANT_SEARCH_INDEX_NAME = 'user-profile-index';
const INSTANT_SEARCH_QUERY_SUGGESTIONS = 'omni-search_query_suggestions'

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  data() {
    return {
      searchClient: algoliasearch(
        `${process.env.ALGOLIA_APP_ID}`,
        `${process.env.ALGOLIA_API_KEY}`
      )
    }
  },
  mounted() {
    const { instantSearchInstance } = this;

    // Set the InstantSearch index UI state from external events
    function setInstantSearchUiState({ query }) {
      instantSearchInstance.setUiState(uiState => ({
        ...uiState,
        [INSTANT_SEARCH_INDEX_NAME]: {
          ...uiState[INSTANT_SEARCH_INDEX_NAME],
          page: 1,
          query
        }
      }));
    }

    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.label });
          }
        };
      }
    });

    const searchClient = algoliasearch(
        `${process.env.ALGOLIA_APP_ID}`,
        '6b346083c2c567a5674676d9b253710b'
      );

    createQuerySuggestionsPlugin({
        searchClient: algoliasearch(
          `${process.env.ALGOLIA_APP_ID}`,
          // TODO change back to env, getting blocked on api key
          '9128e58a463370c2a310cf8fc79c9327'
        ),
      indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
      getSearchParams() {
        return recentSearchesPlugin.data.getAlgoliaSearchParams({
          hitsPerPage: 6
        });
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.query });
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }

            return source.getItems(params);
          }
        };
      }
    });

    const initialState =
      instantSearchInstance.mainIndex.getHelper()?.state || {};

    this.autocompleteInstance = autocomplete({
      container: this.$refs.autocompleteContainer,
      placeholder: 'Search for products',
      detachedMediaQuery: 'none',
      openOnFocus: true,
      // plugins: [recentSearchesPlugin, querySuggestionsPlugin],
      plugins: [],
      initialState: { query: initialState.query || '' },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query });
      },
      onReset() {
        setInstantSearchUiState({ query: '' });
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setInstantSearchUiState({ query: state.query });
        }
      },
      getSources({ query }) {
        return [
          {
            sourceId: 'testData',
            getItems() {
              return getAlgoliaResults({
                indexName: INSTANT_SEARCH_INDEX_NAME,
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX_NAME,
                    query,
                    params: {
                      hitsPerPage: 5,
                      attributesToSnippet: ['*:10'],
                      snippetEllipsisText: '…'
                    }
                  }
                ]

              })
            },
            templates: {
              item({ item, components }) {
                return (
                  <div className="aa-ItemWrapper">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemContentBody">
                          <div className="aa-ItemContentTitle">
                          <components.Snippet hit={item} attribute="id" />
                          </div>
                        </div>
                      </div>
                  </div>
                )
              }
            }
          }
        ]
      }

    });
  },
  beforeDestroy() {
    if (this.autocompleteInstance) {
      this.autocompleteInstance.destroy();
    }
  }
};
</script>
