<template>
  <div class="columns has-padding-left-3">
    <div class="column">
      <user-profile-lookup
        :artist-account="artistAccount"
        :metadata-artist="artistAccount">
      </user-profile-lookup>
    </div>
    <div class="is-divider-vertical" data-content="X" v-if="filteredCollabs && filteredCollabs.length >= 1">
    </div>
    <div class="column is-vcentered" v-if="filteredCollabs && filteredCollabs.length > 1 && filteredCollabs.length <= 4">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item is-clickable" style="margin-left: -1.5rem; " v-for="(collab, index) in filteredCollabs" :key="index" @click="openCollectiveModal">
            <account-image :address="collab" size="is-64x64"></account-image>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-vcentered" v-else-if="filteredCollabs && filteredCollabs.length === 1">
      <div class="level">
        <div class="level-left">
          <user-profile-lookup
            :artist-account="filteredCollabs[0]"
            :metadata-artist="filteredCollabs[0]">
          </user-profile-lookup>
        </div>
      </div>
    </div>
    <div class="column is-vcentered" v-if="filteredCollabs && filteredCollabs.length > 4">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item is-clickable" style="margin-left: -1.5rem; " v-for="(collab, index) in splicedCollabs" :key="index" @click="openCollectiveModal">
            <account-image :address="collab" size="is-64x64"></account-image>
          </div>
          <div class="level-item">
            <h2 class="is-size-4-desktop is-clickable" @click="openCollectiveModal">+{{ filteredCollabs.length - 4 }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEditionButton" class="is-flex is-align-items-center is-justify-content-end">
      <b-button type="is-primary" outlined
        @click="routeToEdition">
        Go to edition
      </b-button>
    </div>
  </div>
</template>
<script>
import _uniq from 'lodash/uniq';
import {mapState} from 'vuex';
import UserProfileLookup from '../UserProfileLookup';
import {notInExclusions} from '../../services/utils';
import AccountImage from '../common/AccountImage';
import MultiCollabBreakdownModal from '../modal/MultiCollabBreakdownModal';

export default {
  components: {AccountImage, UserProfileLookup},
  props: ['editionId', 'artistAccount', 'collaborators', 'showEditionButton'],
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    filteredCollabs() {
      return _uniq(this.collaborators)
        .filter(notInExclusions) // remove hard coded excludes
        .filter((address) => {
          // remove the primary artist
          return address.toLowerCase() !== this.artistAccount.toLowerCase();
        });
    },
    splicedCollabs() {
      return _uniq(this.collaborators)
        .filter(notInExclusions) // remove hard coded excludes
        .filter((address) => {
          // remove the primary artist
          return address.toLowerCase() !== this.artistAccount.toLowerCase();
        })
        .splice(0, 4);
    }
  },
  methods: {
    async openCollectiveModal() {
      this.$buefy.modal.open({
        parent: this,
        component: MultiCollabBreakdownModal,
        width: 600,
        props: {
          editionId: this.editionId,
          artistAccount: this.artistAccount
        }
      });
    },
    async routeToEdition() {
      const editionResult = await this.$api.get(`/network/${this.chainId}/token/${this.editionId}`)
      if (editionResult.data.edition) {
        this.$router.push({ name: 'gallery-id', params: {id: editionResult.data.edition }})
      } else {
        this.$buefy.toast.open({message: `<span class="is-family-tertiary">Could not route to edition</span>`});
      }
    }
  }
};
</script>
