var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "container section" }, [
    _c("div", { staticClass: "tile is-child" }, [
      _c(
        "div",
        {
          staticClass:
            "content is-flex is-justify-content-space-between is-align-items-center is-vcentered",
        },
        [
          _c(
            "h2",
            { staticClass: "column title is-size-3-desktop is-size-5-mobile" },
            [_vm._v("\n        " + _vm._s(_vm.cmsComponent.title) + "\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.handleViewMoreClick(_vm.cmsComponent.viewMoreLink)
                },
              },
            },
            [
              _c(
                "n-link",
                {
                  staticClass:
                    "is-outlined is-size-6-mobile component-view-more-link other-trending-artists-cta",
                  attrs: { to: _vm.cmsComponent.viewMoreLink },
                },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "is-secondary is-fullwidth" } },
                    [_vm._v(_vm._s(_vm.cmsComponent.viewMoreLabel))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "artist1" }, [
      _c("div", { staticClass: "columns is-multiline has-margin-top-7" }, [
        _c(
          "div",
          { staticClass: "top-trending-artists keen-slider" },
          _vm._l(_vm.topTrendingArtists, function (artist, i) {
            return _c(
              "div",
              {
                key: "trending-artist" + i,
                staticClass:
                  "top-trending-artists-slide is-flex keen-slider__slide px-2",
              },
              [
                _c("trending-artist-single", {
                  staticClass: "top-trending-artists-slide-item is-2",
                  attrs: { artist: artist },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }