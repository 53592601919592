var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("client-only", [
        _vm.editionData && _vm.editionId
          ? _c(
              "header",
              {
                staticClass:
                  "header-image has-text-white has-background-grey-darker",
                style: { backgroundImage: `url(${_vm.image})` },
              },
              [
                _c("section", { staticClass: "hero" }, [
                  _c("div", { staticClass: "hero-body" }, [
                    _c(
                      "div",
                      { staticClass: "container content" },
                      [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "has-text-white has-margin-top-1 is-size-2-mobile",
                          },
                          [
                            _c(
                              "n-link",
                              {
                                staticClass: "has-text-white has-padding-top-6",
                                attrs: {
                                  to: {
                                    name: "gallery-id",
                                    params: { id: _vm.editionId },
                                  },
                                },
                              },
                              [
                                _vm.isVideo
                                  ? _c(
                                      "b-icon",
                                      {
                                        staticClass: "is-pulled-right",
                                        attrs: {
                                          icon: "video",
                                          size: "is-small",
                                          "custom-class": "is-size-3",
                                        },
                                      },
                                      [_vm._v(">")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                  Genesis collection by " +
                                    _vm._s(_vm.editionData.metadata.artist) +
                                    "\n                  "
                                ),
                                _vm.editionData.optionalCommissionAccount
                                  ? _c(
                                      "span",
                                      [
                                        _vm._v(" X "),
                                        _c("profile-name", {
                                          attrs: {
                                            "eth-account":
                                              _vm.editionData
                                                .optionalCommissionAccount,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.editionData && !_vm.startDatePassed
                          ? _c("client-only", [
                              _c(
                                "div",
                                { staticClass: "columns has-text-white" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "column is-one-third-desktop",
                                    },
                                    [
                                      _c("edition-sale-count-down", {
                                        attrs: {
                                          edition: _vm.editionData,
                                          "white-text": true,
                                          "hide-label": true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.learnMore
                          ? _c("div", { staticClass: "content" }, [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c(
                                  "p",
                                  { staticClass: "control" },
                                  [
                                    _c(
                                      "n-link",
                                      {
                                        staticClass:
                                          "button is-white is-outlined",
                                        attrs: {
                                          to: {
                                            name: "specials-id",
                                            params: { id: _vm.slug },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Learn more\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.editionData && _vm.editionId
                          ? _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "n-link",
                                  {
                                    staticClass:
                                      "is-size-10 has-text-white has-padding-top-6",
                                    attrs: {
                                      to: {
                                        name: "gallery-id",
                                        params: { id: _vm.editionId },
                                      },
                                    },
                                  },
                                  [
                                    _c("artwork-name-and-artist", {
                                      staticStyle: {
                                        "overflow-wrap": "anywhere",
                                      },
                                      attrs: {
                                        metadata: _vm.editionData.metadata,
                                        "edition-number": _vm.editionId,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }