var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-flex is-align-items-center" }, [
    _vm.isValidGatedSale
      ? _c("div", { staticClass: "column has-text-left has-padding-right-3" }, [
          _vm.hasSalePrice && _vm.activityEvent.buyer
            ? _c(
                "div",
                [
                  _c(
                    "n-link",
                    {
                      class: {
                        "has-text-dark": !_vm.isCc,
                        "has-text-white": _vm.isCc,
                      },
                      attrs: {
                        to: {
                          name: "profile-id",
                          params: { id: _vm.activityEvent.buyer },
                        },
                      },
                    },
                    [
                      _vm.activityEvent.buyer
                        ? _c("div", { staticClass: "is-size-7 mb-1" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.mapActivityEventPersonaLabel(
                                    _vm.activityEvent.eventType
                                  )
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("owner-name-and-image-lookup", {
                        staticClass: "is-inline-block",
                        attrs: {
                          account: _vm.activityEvent.buyer,
                          "is-cc": "isCc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.edition.gatedSale.phases[0].state === "pre-phase"
            ? _c(
                "div",
                {
                  staticClass: "has-text-weight-semibold",
                  class: {
                    "has-text-dark": !_vm.isCc,
                    "has-text-white": _vm.isCc,
                  },
                },
                [
                  _c("p", [_vm._v("Starts in")]),
                  _vm._v(" "),
                  _c("clock-count-down-simple", {
                    attrs: {
                      "start-date": _vm.edition.gatedSale.phases[0].startTime,
                      "white-text": false,
                    },
                  }),
                ],
                1
              )
            : _vm.edition.gatedSale.phases[0].state === "active"
            ? _c(
                "div",
                {
                  class: { "is-muted": !_vm.isCc, "has-text-white": _vm.isCc },
                },
                [
                  _c("p", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v("Public price"),
                  ]),
                  _vm._v(" "),
                  _c("eth-with-fiat-price", {
                    staticClass: "has-text-weight-semibold",
                    attrs: { "price-in-wei": _vm.edition.priceInWei },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : !_vm.startDatePassed && !_vm.isValidGatedSale
      ? _c(
          "div",
          {
            staticClass:
              "column has-text-left has-padding-right-3 is-family-tertiary",
          },
          [
            _c(
              "div",
              {
                staticClass: "has-text-weight-semibold",
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
              },
              [
                _vm._v("\n      Starts in\n      "),
                _c("clock-count-down-simple", {
                  staticClass: "has-text-weight-semibold",
                  class: {
                    "has-text-dark": !_vm.isCc,
                    "has-text-white": _vm.isCc,
                  },
                  attrs: {
                    "start-date": _vm.edition.startDate,
                    "white-text": false,
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm.activityEvent.buyer !== "0x0000000000000000000000000000000000000000"
      ? _c(
          "div",
          { staticClass: "column has-text-left has-padding-right-3" },
          [
            _vm.activityEvent.buyer === null && _vm.isCc
              ? _c("n-link", { attrs: { to: _vm.handleMarketPlaceRouting } })
              : _c(
                  "n-link",
                  {
                    attrs: {
                      to: {
                        name: "profile-id",
                        params: { id: _vm.activityEvent.buyer },
                      },
                    },
                  },
                  [
                    _vm.activityEvent.buyer
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "is-size-7 mb-1 is-family-tertiary has-text-weight-bold",
                            class: {
                              "has-text-dark": !_vm.isCc,
                              "has-text-white": _vm.isCc,
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.mapActivityEventPersonaLabel(
                                    _vm.activityEvent.eventType
                                  )
                                ) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("owner-name-and-image-lookup", {
                      staticClass: "is-inline-block",
                      attrs: {
                        account: _vm.activityEvent.buyer,
                        "is-cc": _vm.isCc,
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "columns is-flex is-vcentered" },
          [
            _c("footer-right-asset-card", {
              staticClass: "column",
              class: { "has-text-dark": !_vm.isCc, "has-text-white": _vm.isCc },
              attrs: { edition: _vm.edition, "from-activity": true },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }