var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "columns has-margin-top-1 is-multiline" },
    [
      _vm._l(_vm.allItemsSpliced, function (blogPost) {
        return _c(
          "aside",
          { key: blogPost.path, staticClass: "column is-one-third" },
          [
            _c("n-link", { attrs: { to: blogPost.path } }, [
              _c(
                "div",
                { staticClass: "card is-shadowless has-margin-bottom-1" },
                [
                  _c("div", { staticClass: "card-image" }, [
                    _c("figure", { staticClass: "image is-16by9" }, [
                      _c("img", {
                        staticClass: "journal-image-cover",
                        attrs: {
                          src: _vm.buildImgixURL(blogPost.coverimage),
                          alt: blogPost.artist,
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content is-paddingless" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "is-family-tertiary has-margin-top-6 has-text-weight-bold",
                        },
                        [_vm._v(_vm._s(blogPost.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-family-primary has-text-weight-normal",
                        },
                        [
                          blogPost.journalType
                            ? _c(
                                "b-tag",
                                {
                                  staticClass:
                                    "is-uppercase is-family-tertiary has-text-weight-medium",
                                  attrs: { type: "is-secondary" },
                                },
                                [_vm._v(_vm._s(blogPost.journalType))]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(blogPost.description) +
                              "\n              "
                          ),
                          blogPost.youtube
                            ? _c(
                                "span",
                                [_c("b-icon", { attrs: { icon: "youtube" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "time",
                        { staticClass: "is-muted is-size-7 level is-mobile" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "level-left is-family-tertiary has-text-weight-bold",
                            },
                            [
                              _c("b-icon", {
                                staticClass: "level-item",
                                attrs: { icon: "calendar", size: "is-small" },
                              }),
                              _vm._v(" "),
                              blogPost.publishedDate || blogPost.date
                                ? _c("span", { staticClass: "level-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.getJournalDateFrom(blogPost))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c(
            "infinite-loading",
            {
              attrs: { identifier: _vm.infiniteId },
              on: { infinite: _vm.moreItems },
            },
            [
              _c("template", { slot: "spinner" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("template", { slot: "no-results" }, [_vm._v(" ")]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }