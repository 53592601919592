var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ids && _vm.ids.length > 0
    ? _c(
        "div",
        { staticClass: "columns is-multiline" },
        _vm._l(_vm.ids, function (id) {
          return _c(
            "div",
            {
              key: id,
              staticClass:
                "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card",
            },
            [
              _c("edition-wrapper", {
                attrs: {
                  id: id,
                  cover: true,
                  resolution: "card",
                  size: "is-small",
                  address: _vm.profile ? _vm.profile.address : null,
                },
              }),
            ],
            1
          )
        }),
        0
      )
    : _c("div", [_c("empty-state", { attrs: { message: "No collection" } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }