<template>
  <a href="https://docs.knownorigin.io/en/articles/6554600-composing-other-tokens" target="_blank">
    <b-tag class="is-dark is-uppercase hand-pointer">
      <b-icon icon="set-center" type="is-light" size="is-small"></b-icon>
      EMBEDDED ITEMS
    </b-tag>
  </a>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: ['editionId', 'metadata', 'enhancedTokensErc20', 'enhancedTokensErc721'],
  computed: {
    ...mapState('web3Store', ['chainId'])
  }
};
</script>
