var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editionCardSkeleton" }, [
    _c("img", {
      staticClass: "editionCardSkeleton__card card-image-cover",
      attrs: { src: "/branding/bigloader-image.svg" },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editionCardSkeleton__content" },
      [
        _c("b-skeleton", {
          attrs: { animated: true, height: "20px", width: "100px" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "editionCardSkeleton__contentArtist" },
          [
            _c("b-skeleton", {
              attrs: {
                animated: true,
                circle: "",
                width: "32px",
                height: "32px",
              },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              attrs: { animated: true, height: "20px", width: "100px" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editionCardSkeleton__footer" },
      [
        _c("b-skeleton", {
          attrs: { animated: true, height: "24px", width: "100px" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }