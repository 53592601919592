<template>
  <div class="animCols is-flex is-flex-wrap-wrap">
    <div v-for="(item, i) in items" :key="item + i" class="animCols__item my-5 p-2">
      <div v-if="item.image" class="animCols__imgHolder is-flex is-align-items-center is-justify-content-center p-5">
        <AnimBuildYourBrand v-if="item.image === 'build-your-brand'" />
        <AnimSellAcrossMarketplaces v-if="item.image === 'sell-across-marketplaces'"
          :class="'sellAcrossMarketplaces--slide' + slide" />
        <AnimCollabAnim v-if="item.image === 'collaborate'" :class="'collabAnim--slide' + slide" />
        <AnimCustomDrop v-if="item.image === 'customised-drops'" :class="'customDrops--slide' + slide" />
      </div>

      <h4 v-if="item.title" class="animCols__title mt-4 is-size-5 has-text-weight-bold is-family-tertiary" v-html="item.title" />
      <p v-if="item.text" class="animCols__text mt-1" v-html="item.text" />
    </div>

  </div>
</template>

<script>
import AnimBuildYourBrand from '~/static/anim/columns/build-your-brand.svg?inline'
import AnimSellAcrossMarketplaces from '~/static/anim/columns/sell-across-marketplaces.svg?inline'
import AnimCollabAnim from '~/static/anim/columns/collaborate.svg?inline'
import AnimCustomDrop from '~/static/anim/columns/customised-drops.svg?inline'

export default {
  components: {
    AnimBuildYourBrand,
    AnimSellAcrossMarketplaces,
    AnimCollabAnim,
    AnimCustomDrop
  },
  props: ['items'],
  data() {
    return {
      slide: 1
    }
  },
  mounted() {
    this.countdown = setInterval(() => {
      if (this.slide === 4) {
        this.slide = 1
        return
      }

      this.slide++
    }, 1500)
  },
  destroyed() {
    clearInterval(this.countdown)
  }
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/mixins";

.animCols {
  color: #363636;

  &__item {
    flex: 1;
    position: relative;
    max-width: 50%;
    min-width: 25%;
  }

  &__imgHolder {
    position: relative;
    border: 1px solid #D7D7D7;
    height: auto;
    aspect-ratio: 1/0.7;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(0deg, hsla(0, 0%, 0%, 1) 20%, hsla(0, 0%, 100%, 1) 100%);
      opacity: 0.05;
      pointer-events: none;
      z-index: 1;
    }

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 2;
    }
  }
}

/*----------------------------------------*/

// < 1215px
@include until-widescreen {
  .animCols {
    &__item {
      padding: 0.75rem !important;
    }
  }
}

/*----------------------------------------*/

// < 1023px
@include touch {
  .animCols {
    &__item {
      min-width: 50%;
    }
  }
}

/*----------------------------------------*/

// < 768px
@include mobile {
  .animCols {
    flex-direction: column;

    &__item {
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
    }

    &__imgHolder {
      border: none;
      padding: 0 !important;
      aspect-ratio: auto;
    }

    &__imgHolder::before {
      display: none;
    }
  }
}

/*----------------------------------------*/
/*----------------------------------------*/
/*----------------------------------------*/

@keyframes byb1 {

  0%,
  90%,
  100% {
    opacity: 0;
  }

  20%,
  70% {
    opacity: 1;
  }
}

@keyframes byb2 {

  0%,
  80%,
  100% {
    opacity: 0;
  }

  20%,
  60% {
    opacity: 1;
  }
}

@keyframes byb3 {

  0%,
  70%,
  100% {
    opacity: 0;
  }

  20%,
  50% {
    opacity: 1;
  }
}

@keyframes byb4 {

  0%,
  60%,
  100% {
    opacity: 0;
  }

  20%,
  40% {
    opacity: 1;
  }
}

.buildYourBrand {
  &__wave {
    opacity: 0;
    animation: byb1 4s infinite;

    &--1 {
      animation-delay: .2s;
    }

    &--2 {
      animation-delay: .4s;
    }

    &--3 {
      animation-delay: .6s;
    }

    &--4 {
      animation-delay: .8s;
    }

    &--5 {
      animation-delay: 1s;
    }

    &--6 {
      animation-delay: 1.2s;
    }

    &--7 {
      animation-delay: 1.4s;
    }
  }

  &__user {
    opacity: 0;

    &--4,
    &--5,
    &--9 {
      animation: byb2 4s 1s infinite;
    }

    &--2,
    &--6,
    &--8 {
      animation: byb3 4s 1.5s infinite;
    }

    &--1,
    &--3,
    &--7,
    &--10 {
      animation: byb4 4s 2s infinite;
    }
  }
}

/*----------------------------------------*/
/*----------------------------------------*/
/*----------------------------------------*/

.sellAcrossMarketplaces {
  &__market {
    opacity: 0.2;
    transition: opacity .6s ease;
  }

  &__outline {
    transition: fill .6s ease;
  }

  //
  &--slide1 {

    .sellAcrossMarketplaces__market--1,
    .sellAcrossMarketplaces__market--4,
    .sellAcrossMarketplaces__market--5 {
      opacity: 1;

      .sellAcrossMarketplaces__outline {
        fill: #7CD736;
      }
    }
  }

  &--slide2 {

    .sellAcrossMarketplaces__market--2,
    .sellAcrossMarketplaces__market--3,
    .sellAcrossMarketplaces__market--5 {
      opacity: 1;

      .sellAcrossMarketplaces__outline {
        fill: #7CD736;
      }
    }
  }

  &--slide3 {

    .sellAcrossMarketplaces__market--2,
    .sellAcrossMarketplaces__market--3,
    .sellAcrossMarketplaces__market--4,
    .sellAcrossMarketplaces__market--6 {
      opacity: 1;

      .sellAcrossMarketplaces__outline {
        fill: #7CD736;
      }
    }
  }

  &--slide4 {

    .sellAcrossMarketplaces__market {
      opacity: 1;

      .sellAcrossMarketplaces__outline {
        fill: #7CD736;
      }
    }
  }
}

/*----------------------------------------*/
/*----------------------------------------*/
/*----------------------------------------*/

.collabAnim {
  &__cursor {

    transition: transform .6s ease;
  }

  &__shape {
    opacity: 0;
    transition: opacity .6s .3s ease;
  }

  &--slide2 {

    .collabAnim__cursor--3 {
      transform: translate(170px, -60px);
    }

    .collabAnim__shape--1 {
      opacity: 1;
    }
  }

  &--slide3 {

    .collabAnim__cursor--2 {
      transform: translate(120px, -10px);
    }

    .collabAnim__shape--1,
    .collabAnim__shape--2 {
      opacity: 1;
    }
  }

  &--slide4 {

    .collabAnim__cursor--1 {
      transform: translate(210px, 70px);
    }

    .collabAnim__shape--1,
    .collabAnim__shape--2,
    .collabAnim__shape--3 {
      opacity: 1;
    }
  }
}

/*----------------------------------------*/
/*----------------------------------------*/
/*----------------------------------------*/

.customDrops {
  &__outline {
    transition: fill .6s ease;
  }

  &__tick,
  &__scheduled {
    opacity: 0;
    transition: opacity .6s ease;
  }

  &__card {
    opacity: 0;
    transition: opacity .6s ease, transform .6s ease;

    &--1 {
      opacity: 1;
    }
  }

  &--slide1 {
    .customDrops__btn--1 .customDrops__outline {
      fill: #7CD736;
    }

    .customDrops__btn--1 .customDrops__tick {
      opacity: 1;
    }
  }

  &--slide2,
  &--slide3,
  &--slide4 {
    .customDrops__btn--2 .customDrops__outline {
      fill: #7CD736;
    }

    .customDrops__btn--2 .customDrops__tick {
      opacity: 1;
    }

    .customDrops__card--2,
    .customDrops__card--4 {
      opacity: 1;
      transform: translateX(-66px)
    }

    .customDrops__card--3,
    .customDrops__card--5 {
      opacity: 1;
      transform: translateX(66px)
    }
  }

  &--slide3,
  &--slide4 {
    .customDrops__btn--3 .customDrops__outline {
      fill: #7CD736;
    }

    .customDrops__btn--3 .customDrops__tick {
      opacity: 1;
    }

    .customDrops__card--4 {
      opacity: 1;
      transform: translateX(-132px)
    }

    .customDrops__card--5 {
      opacity: 1;
      transform: translateX(132px)
    }
  }

  &--slide4 {
    .customDrops__btn--4 .customDrops__outline {
      fill: #7CD736;
    }

    .customDrops__btn--4 .customDrops__tick {
      opacity: 1;
    }

    .customDrops__scheduled {
      opacity: 1;
    }
  }
}
</style>
