var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
    ? _c(
        "span",
        {
          staticClass: "has-text-right is-family-tertiary",
          class: {
            "has-text-weight-semibold": !_vm.small,
            "is-size-8 pl-1": _vm.small,
          },
        },
        [
          !_vm.isOneOfOne && _vm.availability === 1
            ? _c("span", { staticClass: "has-text-danger" }, [
                _vm._v("last one"),
              ])
            : _c("span", [_vm._v("1/" + _vm._s(_vm.available))]),
        ]
      )
    : _c(
        "span",
        {
          staticClass: "has-text-right is-family-tertiary",
          class: {
            "has-text-weight-medium": !_vm.small,
            "is-size-8 pl-1": _vm.small,
          },
        },
        [
          _vm.isSoldOutPrimary(_vm.edition)
            ? _c("span", [_vm._v(_vm._s(_vm.openEditionSoldOutSalesCounts))])
            : !_vm.startDatePassed(_vm.edition)
            ? _c("span", [_vm._v("0 MINTED")])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.edition.totalSupply) +
                    _vm._s(_vm.openEditionSalesCount) +
                    " MINTED"
                ),
              ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }