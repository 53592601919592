<template>
  <div v-if="metadata">
    <div class="tile is-ancestor">

      <div class="tile is-parent is-vertical is-12">
        <div class="tile is-child" style="margin-bottom: -0.5rem !important;">
          <edition-creators :edition-id="editionNumber"
                            :artist-account="artistAccount || metadata.artist"
                            :collaborators="collaborators"
                            :show-edition-button="showEditionButton">
          </edition-creators>
        </div>

        <div class="tile">
          <div class="tile is-parent is-vertical is-8">
            <div class="tile is-child">
              <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                  <slot name="single-asset-image">
                    <image-asset :metadata="metadata"
                                 :edition-number="editionNumber"
                                 :cover="false"
                                 :enable-magnify="true"
                                 :is-cc="editionCreatorContract"
                                 resolution="default">
                    </image-asset>
                  </slot>

                  <slot name="single-asset-sales-table"></slot>

                  <slot name="single-asset-image-below"></slot>

                </div>
              </div>
            </div>
          </div>

          <div class="tile is-parent is-vertical">
            <div class="tile is-child">
              <div class="cc">
                <div class="columns">
                  <div class="column is-three-quarters ml-3">
                <slot name="single-asset-creator-contract" v-if="editionCreatorContract">
                  <article>
                    <creator-contract-showcase class="mb-5 ml-3"
                                               :address="editionCreatorContract"></creator-contract-showcase>
                  </article>
                  <b-tag class="is-dark is-uppercase hand-pointer is-family-tertiary" v-if="marketplacesBlocked" @click.native="openBlockerModal">
                    <span class="is-underlined-hover">Protected marketplaces</span></b-tag>
                </slot>
                  </div>
                </div>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child content">
                  <slot name="single-asset-name">
                    <h1 class="is-size-3-mobile artwork-title is-uppercase" style="overflow-wrap: anywhere; max-width: 400px; overflow: hidden;">
                      {{ metadata.name }}</h1>
                  </slot>
                </article>

                <slot name="single-asset-primary-info">
                </slot>

                <slot name="single-asset-primary-cta">
                </slot>

                <slot name="purchasing"></slot>

                <slot name="single-asset-owner" v-if="owner && !childOfEnhancedNFT">
                  <article class="tile is-child">
                    <div class="has-text-weight-bold title is-family-tertiary">
                      Owned by
                    </div>
                    <user-profile-lookup
                      :artist-account="owner.address"
                      :metadata-artist="owner.address">
                    </user-profile-lookup>
                  </article>
                </slot>

                <article class="tile is-child content">
                  <slot name="single-asset-description">
                    <p class="title is-family-tertiary has-text-weight-bold">Description</p>
                    <asset-description :description="metadata.description"></asset-description>

                    <slot name="single-asset-badges">
                      <div class="columns">
                        <div class="column" v-if="hasUnlockableContent(editionNumber)">
                          <unlockable-edition :edition-number="editionNumber">
                          </unlockable-edition>
                        </div>
                        <div class="column" v-if="collaborators && collaborators.length > 1">
                          <collaboration-badge :edition-id="editionNumber"
                                               :artist-account="artistAccount"
                                               :collaborators="collaborators">
                          </collaboration-badge>
                        </div>
                        <slot name="single-asset-enhanced-badge"></slot>
                        <div class="column">
                          <content-auth-tag :edition-id="editionNumber"
                                            :image-url="metadata.image"
                                            :minter="artistAccount">
                          </content-auth-tag>
                        </div>
                      </div>
                    </slot>
                  </slot>

                </article>

                <article class="tile is-child is-family-tertiary">
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                        <slot name="single-asset-share">
                          <b-dropdown aria-role="list">
                            <b-icon icon="share-variant" slot="trigger">Share</b-icon>
                            <b-dropdown-item aria-role="listitem" @click="copyURLWithToast" ref="urlLink">Copy share
                              link
                            </b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="copyInstaWithToast" ref="instaLink">Copy for
                              Instagram share
                            </b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="copyTwitterWithToast" ref="instaLink">Copy
                              for
                              Twitter share
                            </b-dropdown-item>
                          </b-dropdown>
                        </slot>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <slot name="single-asset-like">
                        </slot>
                      </div>
                    </div>
                  </div>
                </article>

                <div class="tile is-child">

                  <slot name="single-asset-stats-edition">
                  </slot>

                  <slot name="single-asset-curation-badge">
                  </slot>

                  <slot name="single-asset-stats-enhanced-nft">
                  </slot>

                  <slot name="single-asset-stats-enhanced-token">
                  </slot>

                </div>

                <article class="tile is-child">
                  <slot name="single-asset-tags">
                    <b-taglist class="pt-4">
                      <b-tag v-if="tags" v-for="(tag, $index) in tags" :key="$index" type="is-secondary"
                             class="is-lowercase short-tag">
                        <n-link :to="{name: 'marketplace', query:{[`tag_${tag.replace(/ /g, '_')}`]:true}}"
                                class="has-tag-link">
                          #{{ tag.replace(/\s+/g, '') | truncate(50) }}
                        </n-link>
                      </b-tag>
                      <b-tag v-if="format" type="is-secondary"
                             class="is-lowercase short-tag">
                        <n-link
                          :to="{name: 'marketplace', query:{[`format_${format.replace(/ /g, '_').toLowerCase()}`]:format}}"
                          class="has-tag-link">
                          #{{ metadata.format }}
                        </n-link>
                      </b-tag>
                      <!--                      TODO dropping theme for now as not reflected in new marketplace-->
                      <!--                      <b-tag v-if="theme" type="is-secondary"-->
                      <!--                             class="is-lowercase short-tag">-->
                      <!--                        <n-link :to="{name: 'gallery', query:{search:theme}}" class="has-tag-link">-->
                      <!--                          #{{ metadata.theme }}-->
                      <!--                        </n-link>-->
                      <!--                      </b-tag>-->
                    </b-taglist>
                    <span class="heading is-clickable is-uppercase" @click="moreTags"
                          v-if="tagsLength === 25 && metadata && metadata.tags && metadata.tags.length > 25">
                      MORE TAGS <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
                    </span>
                  </slot>
                </article>

                <article class="tile is-child">
                  <client-only>
                    <b-tabs v-model="activeTab" :animated="false">
                      <slot name="single-asset-tab-items"></slot>
                    </b-tabs>
                  </client-only>
                </article>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {copyText} from '../services/fileUtils';
import {notInExclusions} from '../services/utils';
import {getInsta, getTweet} from '../services/socials';
import ImageAsset from './asset-renders/ImageAsset';
import UserProfileLookup from './UserProfileLookup';
import AssetDescription from './AssetDescription';
import UnlockableEdition from './badges/UnlockableEdition';
import EditionCreators from './avatar/EditionCreators';
import ContentAuthTag from './common/cai/ContentAuthTag';
import CollaborationBadge from './badges/CollaborationBadge';
import CreatorContractShowcase from './CreatorContractShowcase';
import MarketplaceBlockerModal from './modal/MarketplaceBlockerModal';

export default {
  components: {
    CollaborationBadge,
    ContentAuthTag,
    EditionCreators,
    UnlockableEdition,
    AssetDescription,
    ImageAsset,
    UserProfileLookup,
    CreatorContractShowcase
  },
  props: [
    'id',
    'metadata',
    'tokenURI',
    'editionNumber',
    'artistAccount',
    'collaborators',
    'owner',
    'editionShare',
    'totalSold',
    'soldOutPrimary',
    'childOfEnhancedNFT',
    'editionCreatorContract',
    'showEditionButton'
  ],
  data: () => ({
    activeTab: 0,
    tagsLength: 25,
    profile: null,
    marketplaceBlockerModalInstance: null,
    marketplacesBlocked: false,
    endOpenEditionModalInstance: null,
    contractDetails: null
  }),
  computed: {
    ...mapState('web3Store', ['chainId']),
    ...mapGetters('unlockableContentStore', [
      'hasUnlockableContent'
    ]),
    tags() {
      if (this.metadata && this.metadata.tags && this.metadata.tags.length > 0) {
        const processedTags = [];
        this.metadata.tags.slice(0, this.tagsLength).forEach((tag) => {
          tag.trim().split('#').forEach((tag) => {
            if (tag !== '#' && tag !== '') {
              processedTags.push(tag);
            }
          });
        });
        return processedTags;
      }
      return null;
    },
    format() {
      if (this.metadata && this.metadata.format) {
        return this.metadata.format;
      }
      return null;
    },
    theme() {
      if (this.metadata && this.metadata.theme) {
        return this.metadata.theme;
      }
      return null;
    }
  },
  async mounted() {
    this.profile = await this.$store.dispatch('userStore/getUser', this.artistAccount);
    if (!this.editionCreatorContract) {
      await this.loadUnlockableContent()
    } else {
      this.contractDetails = await this.$store.dispatch('creatorContractStore/fetchContractDetails', {
        address: this.editionCreatorContract
      })
      if (this.contractDetails.filterRegistry?.supported) { // check filter registry for blocked marketplaces
        this.marketplacesBlocked = true;
      }
    }
  },
  methods: {
    notInExclusions,
    openBlockerModal() {
      this.marketplaceBlockerModalInstance = this.$buefy.modal.open({
        parent: this,
        component: MarketplaceBlockerModal,
        canCancel: true
      })
    },
    copyURLWithToast() {
      copyText(window.location.href);
      this.$buefy.toast.open({message: `<span class="is-family-tertiary">Share link copied</span>`});
    },
    copyInstaWithToast() {
      copyText(getInsta('GALLERY_ID', this.profile, window.location.href, this.editionShare));
      this.$buefy.toast.open('Instagram edition info copied');
    },
    copyTwitterWithToast() {
      copyText(getTweet('GALLERY_ID', this.profile, window.location.href, this.editionShare));
      this.$buefy.toast.open('Twitter edition info copied');
    },
    moreTags() {
      this.tagsLength = 1001;
    },
    async loadUnlockableContent() {
      // load any unlockable content
      await this.$store.dispatch('unlockableContentStore/hasUnlockableContent', {
        editionId: this.editionNumber
      });
    }
  }
};
</script>

<style scoped>
.cc {
  position: relative;
  top: -1.4rem !important;
}
.is-underlined-hover:hover {
  text-decoration: underline;
}
</style>
