var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      {
        staticClass: "column p-0 search-background",
        class: { "search-shadow": _vm.computeFocus },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnter.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "client-only",
          [
            _c(
              "ais-instant-search",
              {
                attrs: {
                  "search-client": _vm.searchClient,
                  "index-name": "user-profile-index",
                  "stalled-search-delay": 200,
                  routing: _vm.routingUser,
                },
              },
              [
                _c("div", { staticClass: "columns is-multiline" }, [
                  _c("div", { staticClass: "column is-full" }, [
                    _c("div", { staticClass: "columns is-mobile" }, [
                      _c(
                        "div",
                        { staticClass: "column is-full" },
                        [
                          _c("ais-search-box", {
                            ref: "search",
                            attrs: { placeholder: "Search..." },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ isSearchStalled, refine }) {
                                  return [
                                    _c(
                                      "b-field",
                                      [
                                        _c("b-input", {
                                          staticClass: "nav-input",
                                          attrs: {
                                            autofocus: true,
                                            placeholder:
                                              _vm.computedPlaceholder,
                                            type: "search",
                                            icon: "magnify",
                                            loading: isSearchStalled,
                                            "icon-right": "close-circle",
                                            "icon-right-clickable": "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.debounceSearch(refine)
                                            },
                                            "icon-right-click": function (
                                              $event
                                            ) {
                                              return _vm.clearSearch(refine)
                                            },
                                            click: function ($event) {
                                              return _vm.enableFocus()
                                            },
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleAmplitudeClick(
                                                "omniClicked"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.searchInput,
                                            callback: function ($$v) {
                                              _vm.searchInput = $$v
                                            },
                                            expression: "searchInput",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _vm.searchInput != null &&
                          typeof _vm.searchInput === "string" &&
                          _vm.searchInput.length > 0 &&
                          _vm.omniToggle
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pl-4 pr-4 pb-2 scrollable-content-omni",
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-7 has-text-weight-medium is-flex is-align-items-center input-cta is-family-tertiary",
                                    },
                                    [
                                      _c("span", [
                                        _c("img", {
                                          staticClass: "image is-16x16 mr-2",
                                          attrs: {
                                            src: "/interface/enter-icon.png",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(
                                        "Search marketplace\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.noArtists
                                    ? _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "mt-3 mb-15 has-text-weight-bold is-family-tertiary",
                                          class: {
                                            "artist-hover":
                                              _vm.highlightArtists,
                                          },
                                          on: {
                                            click: () => _vm.route("Artists"),
                                            mouseover: function ($event) {
                                              _vm.highlightArtists = true
                                            },
                                            mouseleave: function ($event) {
                                              _vm.highlightArtists = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Artists\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "ais-index",
                                    {
                                      attrs: {
                                        "index-name": "user-profile-index",
                                        routing: _vm.routingUser,
                                        "index-id":
                                          "artworks-user-profile-index",
                                      },
                                    },
                                    [
                                      _c("ais-configure", {
                                        attrs: { hitsPerPage: 3 },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "ais-state-results",
                                        [
                                          _c("ais-hits", {
                                            attrs: {
                                              "transform-items":
                                                _vm.transformArtists,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ items }) {
                                                    return _vm._l(
                                                      items,
                                                      function (item) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: item.address,
                                                            staticClass:
                                                              "custom-user-item search-list-item p-0",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickProfile(
                                                                  item,
                                                                  "Artists"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "search-result-row",
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                attrs: {
                                                                  address:
                                                                    item.address,
                                                                  type: "user",
                                                                  item: item,
                                                                  "is-mobile":
                                                                    _vm.isMobile,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              966238753
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ais-index",
                                    {
                                      attrs: {
                                        "index-name":
                                          _vm.getIndexName.creatorContracts,
                                        routing: _vm.routing,
                                        middlewares: _vm.middlewares,
                                      },
                                    },
                                    [
                                      _c("ais-configure", {
                                        attrs: {
                                          hitsPerPage: 4,
                                          filters: "enabled:true",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.noContracts
                                        ? _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "mt-2 mb-15 has-text-weight-bold is-family-tertiary",
                                              class: {
                                                "artist-hover":
                                                  _vm.highlightContracts,
                                              },
                                              on: {
                                                click: () =>
                                                  _vm.route("Contracts"),
                                                mouseover: function ($event) {
                                                  _vm.highlightContracts = true
                                                },
                                                mouseleave: function ($event) {
                                                  _vm.highlightContracts = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Contracts\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "ais-state-results",
                                        [
                                          _c("ais-hits", {
                                            attrs: {
                                              "class-names": {
                                                "ais-Hits": "",
                                                "ais-Hits-item":
                                                  "custom-user-item search-list-item p-0",
                                                "ais-Hits-list": "",
                                              },
                                              "transform-items":
                                                _vm.transformContracts,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "contract-search-result-row",
                                                        {
                                                          attrs: {
                                                            item: item,
                                                            index: index,
                                                            "text-wrap-cap":
                                                              _vm.textWrapCapArtworks,
                                                            "handle-contract-routing":
                                                              _vm.handleContractRouting,
                                                            "sort-by":
                                                              _vm.sortBy,
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              675657814
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ais-index",
                                    {
                                      attrs: {
                                        "index-name":
                                          _vm.getIndexName.marketplace,
                                        routing: _vm.routing,
                                        middlewares: _vm.middlewares,
                                      },
                                    },
                                    [
                                      _c("ais-configure", {
                                        attrs: {
                                          hitsPerPage: 3,
                                          filters: "filters.active:true",
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.noArtworks
                                        ? _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "mt-2 mb-15 has-text-weight-bold is-family-tertiary",
                                              class: {
                                                "artist-hover":
                                                  _vm.highlightArtworks,
                                              },
                                              on: {
                                                click: () =>
                                                  _vm.route("Artworks"),
                                                mouseover: function ($event) {
                                                  _vm.highlightArtworks = true
                                                },
                                                mouseleave: function ($event) {
                                                  _vm.highlightArtworks = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Artworks\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "ais-state-results",
                                        [
                                          _c("ais-hits", {
                                            attrs: {
                                              "class-names": {
                                                "ais-Hits": "",
                                                "ais-Hits-item":
                                                  "custom-user-item search-list-item p-0",
                                                "ais-Hits-list": "",
                                              },
                                              "transform-items":
                                                _vm.transformArtworks,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "artwork-search-result-row",
                                                        {
                                                          class:
                                                            item.filters &&
                                                            item.filters
                                                              .isCreatorContract
                                                              ? "search-item-style__invert"
                                                              : "search-item-style",
                                                          attrs: {
                                                            item: item,
                                                            index: index,
                                                            "text-wrap-cap":
                                                              _vm.textWrapCapArtworks,
                                                            "handle-amplitude-click":
                                                              _vm.handleAmplitudeClick,
                                                            "sort-by":
                                                              _vm.sortBy,
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              737653303
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ais-index",
                                    {
                                      attrs: {
                                        "index-name": "user-profile-index",
                                        routing: _vm.routingUser,
                                        "index-id":
                                          "accounts-user-profile-index",
                                      },
                                    },
                                    [
                                      !_vm.noAccounts
                                        ? _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "mt-2 mb-15 has-text-weight-bold is-family-tertiary",
                                              class: {
                                                "artist-hover":
                                                  _vm.highlightAccounts,
                                              },
                                              on: {
                                                click: () =>
                                                  _vm.route("Accounts"),
                                                mouseover: function ($event) {
                                                  _vm.highlightAccounts = true
                                                },
                                                mouseleave: function ($event) {
                                                  _vm.highlightAccounts = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Accounts\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("ais-toggle-refinement", {
                                        attrs: {
                                          attribute: "isArtist",
                                          label: "Artists",
                                          off: false,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                value,
                                                refine,
                                                createURL,
                                              }) {
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: false,
                                                          expression: "false",
                                                        },
                                                      ],
                                                      attrs: {
                                                        href: createURL(value),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return refine(value)
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("b-switch", {
                                                        staticClass:
                                                          "is-primary is-light is-outlined pr-4",
                                                        attrs: {
                                                          value:
                                                            value.isRefined,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          130381078
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("ais-configure", {
                                        attrs: { hitsPerPage: 3 },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "ais-state-results",
                                        [
                                          _c("ais-hits", {
                                            attrs: {
                                              "class-names": {
                                                "ais-Hits": "",
                                                "ais-Hits-item": "",
                                              },
                                              "transform-items":
                                                _vm.transformAccounts,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ items }) {
                                                    return _vm._l(
                                                      items,
                                                      function (item) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: item.address,
                                                            staticClass:
                                                              "custom-user-item search-list-item p-0",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickProfile(
                                                                  item,
                                                                  "Accounts"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "search-result-row",
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                attrs: {
                                                                  address:
                                                                    item.address,
                                                                  type: "user",
                                                                  item: item,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4071602834
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.noArtists &&
                                  _vm.noArtworks &&
                                  _vm.noAccounts &&
                                  _vm.noContracts
                                    ? _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "mt-3 mb-1 has-text-weight-bold is-family-tertiary",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    No search results found\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }