var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    !_vm.isLargeTimeDifference
      ? _c(
          "section",
          [
            !_vm.hidden
              ? _c("vue-countdown", {
                  attrs: {
                    time: _vm.diff,
                    transform: _vm.transform,
                    "emit-events": true,
                  },
                  on: { progress: _vm.handleCountdownProgress },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "section",
                              {
                                class: {
                                  "has-text-white": _vm.whiteText === true,
                                },
                              },
                              [
                                props.days > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "\n           " +
                                          _vm._s(props.days) +
                                          "d  " +
                                          _vm._s(props.hours) +
                                          "h  " +
                                          _vm._s(props.minutes) +
                                          "m\n        "
                                      ),
                                    ])
                                  : props.hours > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "\n           " +
                                          _vm._s(props.hours) +
                                          "h  " +
                                          _vm._s(props.minutes) +
                                          "m  " +
                                          _vm._s(props.seconds) +
                                          "s\n        "
                                      ),
                                    ])
                                  : props.minutes > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "\n           " +
                                          _vm._s(props.hours) +
                                          "h  " +
                                          _vm._s(props.minutes) +
                                          "m  " +
                                          _vm._s(props.seconds) +
                                          "s\n        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    957727071
                  ),
                })
              : _vm._e(),
          ],
          1
        )
      : _c("section", [
          _c(
            "section",
            { class: { "has-text-white": _vm.whiteText === true } },
            [_c("span", [_vm._v("\n           More than a year\n        ")])]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }