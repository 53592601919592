var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "section",
      [
        _c("h3"),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              type: { "is-danger": _vm.dobError, "is-success": _vm.dobSuccess },
              message: _vm.errorMessage,
              label: _vm.requiredFieldsList.includes("dob")
                ? "Date of birth *"
                : "Date of birth",
            },
          },
          [
            _c("b-input", {
              attrs: { value: _vm.formattedDOB, placeholder: "DD/MM/YYYY" },
              on: { input: _vm.inputChanged },
            }),
            _vm._v(" "),
            _c("b-datepicker", {
              staticClass: "manage-profile__picker",
              attrs: {
                "mobile-native": false,
                "years-range": [-100, 30],
                "max-date": _vm.maxDate,
              },
              on: { input: _vm.datePickerChanged },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c("b-button", {
                        attrs: {
                          "icon-left": "calendar-today",
                          type: "is-primary",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.dobData,
                callback: function ($$v) {
                  _vm.dobData = $$v
                },
                expression: "dobData",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }