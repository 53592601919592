export default function ({$axios, redirect}, inject) {
  /// //////
  // API //
  /// //////

  const api = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  api.setBaseURL(process.env.BASE_API_URL || 'https://us-central1-known-origin-io.cloudfunctions.net/main/api');
  console.log(`Setting API axios base to [${process.env.BASE_API_URL || 'https://us-central1-known-origin-io.cloudfunctions.net/main/api'}]`);

  // Inject to context as $api
  inject('api', api);

  const cacheApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  cacheApi.setBaseURL(process.env.BASE_CACHE_API_URL || 'https://api.v1.knownorigin.io/api');
  console.log(`Setting API axios base to [${process.env.BASE_CACHE_API_URL || 'https://api.v1.knownorigin.io/api'}]`);

  // Inject to context as $cacheApi
  inject('cacheApi', cacheApi);

  /// //////
  // CDN //
  /// //////

  const cdnApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  cdnApi.setBaseURL(process.env.BASE_CDN_API_URL || 'https://cdn.knownorigin.io');
  console.log(`Setting CDN axios base to [${process.env.BASE_CDN_API_URL || 'https://cdn.knownorigin.io'}]`);

  // Inject to context as $cdnApi
  inject('cdnApi', cdnApi);

  /// ///////////
  // Metadata //
  /// ///////////

  const metadataApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  metadataApi.setBaseURL(process.env.BASE_METADATA_API_URL || 'https://koda-metadata-service-beta-gp2s5beobq-uc.a.run.app');
  console.log(`Setting Metadata axios base to [${process.env.BASE_METADATA_API_URL || 'https://koda-metadata-service-beta-gp2s5beobq-uc.a.run.app'}]`);

  // Inject to context as $metaDataApi
  inject('metadataApi', metadataApi);

  /// ///////////////
  // Verification //
  /// ///////////////

  const verificationApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  verificationApi.setBaseURL(process.env.BASE_VERIFICATION_API_URL || 'https://known-origin-profile-verification-gp2s5beobq-uc.a.run.app');
  console.log(`Setting verification axios base to [${process.env.BASE_VERIFICATION_API_URL || 'https://known-origin-profile-verification-gp2s5beobq-uc.a.run.app'}]`);

  // Inject to context as $verificationApi
  inject('verificationApi', verificationApi);

  /// /////////////////////
  // User profile media //
  /// /////////////////////

  const userMediaApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  userMediaApi.setBaseURL(process.env.BASE_USER_MEDIA_SERVICE_URL || 'https://user-profile-media-service-gp2s5beobq-uc.a.run.app');
  console.log(`Setting verification axios base to [${process.env.BASE_USER_MEDIA_SERVICE_URL || 'https://user-profile-media-service-gp2s5beobq-uc.a.run.app'}]`);

  // Inject to context as $verificationApi
  inject('userMediaApi', userMediaApi);

  /// /////////////////////////
  // Creator Contract Service //
  /// //////////////////////////

  const creatorContractApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  creatorContractApi.setBaseURL(process.env.BASE_CREATOR_CONTRACT_SERVICE_URL || 'https://creator-contract.knownorigin.io');
  console.log(`Setting creator contract axios base to [${process.env.BASE_CREATOR_CONTRACT_SERVICE_URL || 'https://creator-contract.knownorigin.io'}]`);

  // Inject to context as $creatorContractApi
  inject('creatorContractApi', creatorContractApi);

  /// /////////////////////////
  // Secondary Market Service //
  /// //////////////////////////

  const secondaryMarketApi = $axios.create({
    headers: {
      common: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-store,no-cache,must-revalidate'
      }
    }
  });

  // Set baseURL to something different
  secondaryMarketApi.setBaseURL(process.env.BASE_SECONDARY_MARKET_SERVICE_URL || 'http://0.0.0.0:8080');
  console.log(`Setting secondary market service axios base to [${process.env.BASE_SECONDARY_MARKET_SERVICE_URL || 'http://0.0.0.0:8080'}]`);

  // Inject to context as $secondaryMarketApi
  inject('secondaryMarketApi', secondaryMarketApi);
}
