var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-flex is-flex-direction-column" },
    [
      _vm.artist && _vm.artist.id
        ? _c(
            "user-profile-lookup",
            {
              staticClass: "mb-4",
              attrs: {
                "artist-account": _vm.artist.id,
                "metadata-artist": _vm.artist.id,
              },
            },
            [
              _c("template", { slot: "artist-profile-header" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "artist-header-text mb-1 is-size-7 is-uppercase",
                  },
                  [
                    _c("span", { staticClass: "is-capitalized" }, [
                      _vm._v("Trending creator"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "artist-profile-subtitle" },
                [
                  _c("trending-score", {
                    attrs: {
                      score: _vm.artist.score,
                      address: _vm.artist.id,
                      "no-padding": true,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _c("div", { staticClass: "media is-align-items-center mb-5" }, [
            _c(
              "div",
              { staticClass: "media-left" },
              [
                _c("b-skeleton", {
                  attrs: {
                    circle: "",
                    active: "",
                    width: "64px",
                    height: "64px",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "media-content" }, [
              _c(
                "div",
                { staticClass: "content is-flex is-flex-wrap-wrap" },
                [
                  _c("b-skeleton", {
                    attrs: { active: "", width: "100px", height: "12px" },
                  }),
                  _vm._v(" "),
                  _c("b-skeleton", {
                    attrs: { active: "", width: "232px", height: "24px" },
                  }),
                  _vm._v(" "),
                  _c("b-skeleton", {
                    attrs: { active: "", width: "75px", height: "24px" },
                  }),
                ],
                1
              ),
            ]),
          ]),
      _vm._v(" "),
      _vm.artist && _vm.artist.editions[0]
        ? _c("trending-image", { attrs: { edition: _vm.artist.editions[0] } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }