var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "modal-template",
        {
          attrs: {
            edition: _vm.token,
            token: _vm.token,
            "transaction-hash": _vm.transactionHash,
          },
        },
        [
          _c("template", { slot: "content-title" }, [
            _vm.isApproved
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.token.isListed
                          ? "Change reserve price"
                          : "Start reserve auction"
                      ) +
                      "\n    "
                  ),
                ])
              : _c("span", [_vm._v("\n      Enable marketplace\n    ")]),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "content-sub-title" },
            [
              _vm.isApproved
                ? _c("artwork-name-and-artist", {
                    attrs: {
                      metadata: _vm.token.metadata,
                      "edition-number": _vm.token.editionNumber,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
            [
              _vm.isApproved
                ? _c(
                    "section",
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Reserve price",
                            message: `Min. sale price is ${_vm.minBidAmount}`,
                          },
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "1.23",
                              expanded: "",
                              autofocus: "",
                              disabled: _vm.transactionHash,
                              type: "number",
                              step: "any",
                            },
                            model: {
                              value: _vm.form.listAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "listAmount", $$v)
                              },
                              expression: "form.listAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "b-message",
                            {
                              staticClass: "is-size-7",
                              attrs: {
                                type: "is-info",
                                closable: false,
                                duration: 0,
                              },
                            },
                            [
                              _c(
                                "p",
                                [
                                  _vm._v(
                                    "\n            You will receive\n            "
                                  ),
                                  _vm.listAmountToSeller
                                    ? _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-eth":
                                            _vm.listAmountToSeller,
                                          "override-dp": 3,
                                        },
                                      })
                                    : _c("eth-with-fiat-price", {
                                        attrs: {
                                          "override-dp": 3,
                                          "price-in-eth": "0",
                                        },
                                      }),
                                  _vm._v(
                                    "\n            after artist royalties + fees.\n            "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "is-linkable",
                                      attrs: {
                                        href: "https://knownorigin.io/journal/platformupdate/making-a-secondary-marketplace",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("See fee breakdown")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.token.isListed
                                ? _c("p", [
                                    _vm._v(
                                      "\n            Lowering the reserve price below an existing bid will trigger the 24hr countdown and the auction wll\n            begin.\n            "
                                    ),
                                    _c("a", { staticClass: "is-linkable" }, [
                                      _vm._v("Learn how 24 hour auctions work"),
                                    ]),
                                  ])
                                : _c("p", [
                                    _c("a", { staticClass: "is-linkable" }, [
                                      _vm._v("Learn how 24 hour auctions work"),
                                    ]),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "section",
                    [
                      _c(
                        "b-message",
                        {
                          staticClass: "is-size-7",
                          attrs: {
                            type: "is-info",
                            closable: false,
                            duration: 0,
                          },
                        },
                        [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(
                              "\n          In order to set a sell price you need to enable our new marketplace\n          "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "is-linkable",
                                attrs: {
                                  href: _vm.etherscanAddressLink(
                                    _vm.marketplaceAddress
                                  ),
                                  target: "_blank",
                                },
                              },
                              [_vm._v("smart contract")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("section", [
                            _vm._v(
                              "\n          You only need to do this once. The\n          "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "is-linkable",
                                attrs: {
                                  href: _vm.etherscanAddressLink(
                                    _vm.marketplaceAddress
                                  ),
                                  target: "_blank",
                                },
                              },
                              [_vm._v("smart contract")]
                            ),
                            _vm._v(
                              "\n          allows you to resell your artworks, handle payments &\n          royalties and to move tokens on your behalf\n        "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ]
          ),
          _vm._v(" "),
          _c("template", { slot: "action-feedback-label" }, [
            _vm.isApproved
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.token.isListed
                          ? "Changing reserve price"
                          : "Starting reserve auction"
                      ) +
                      "\n    "
                  ),
                ])
              : _c("span", [_vm._v("\n      Approving marketplace\n    ")]),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "action-button" },
            [
              _vm.isApproved
                ? _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { expanded: "", disabled: _vm.disableButton },
                      on: { click: _vm.listToken },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.token.isListed
                              ? "Change reserve price"
                              : "Start reserve auction"
                          ) +
                          "\n    "
                      ),
                    ]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { expanded: "", disabled: _vm.transactionHash },
                      on: { click: _vm.approveMarketplace },
                    },
                    [_vm._v("\n      Enable marketplace\n    ")]
                  ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }