import Vue from 'vue';
import {ethers} from 'ethers';

Vue.filter('toEth', val => val && ethers.utils.formatEther(ethers.BigNumber.from(val)));

Vue.filter('toDp', (val, decimals = 2) => val && parseFloat(val).toFixed(decimals));

Vue.filter('to3Dp', val => val && parseFloat(val).toFixed(3));

Vue.filter('to2Dp', val => val && parseFloat(val).toFixed(2));

Vue.filter('shortEth', function (value) {
  if (!value) {
    return value;
  }
  return `
  ${value.substr(0, 4)}
  ...
  ${value.substr(value.length - 4, value.length)}
  `;
});

Vue.filter('dotDotDot', function (value) {
  if (!value) { return ''; }
  if (value) {
    return value.substr(0, 6) + '...' + value.substr(value.length - 6, value.length);
  }
  return '';
});

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value;
});
