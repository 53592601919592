<template>
  <span v-if="version !== '4'" class="is-family-tertiary">
      #{{ (parseInt(id) - parseInt(editionNumber) + parseInt(version) - 2) }}/{{ editionTotalAvailable }}
  </span>
  <span v-else class="is-family-tertiary">#{{ Math.abs(parseInt(id) - parseInt(editionNumber) - editionTotalAvailable) }}/{{
      editionTotalAvailable
    }}</span>
</template>

<script>
export default {
  props: ['id', 'editionNumber', 'version', 'editionTotalAvailable']
};
</script>
