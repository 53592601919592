<template>
  <aside class="scrollable-content content is-family-tertiary" v-if="activityEvents">
    <ul>
      <li v-for="activityEvent in activityEvents" :key="activityEvent.id">
        <div class="columns is-mobile is-multiline" v-if="activityEvent.buyer!=='0x0000000000000000000000000000000000000000'">
          <div class="column is-half">
            <span class="heading has-text-weight-semibold">{{ mapEvent(activityEvent.eventType) }}</span>
            <span class="heading" v-if="activityEvent.buyer">
                <profile-name-link :eth-account="activityEvent.buyer"></profile-name-link>
            </span>
            <a class="is-muted-7" :href="etherscanTxLink(activityEvent.transactionHash)" target="_blank">[view tx]</a>
          </div>
          <div class="column is-half">
            <span class="heading">
              <date-from :timestamp-in-secs="activityEvent.timestamp" />
            </span>
            <span class="heading">
              <eth-with-fiat-price v-if="activityEvent.eventValueInWei"
                size="is-small"
                show-historical-fiat="true"
                :price-in-wei="activityEvent.eventValueInWei"
                :transaction-hash="activityEvent.transactionHash">
              </eth-with-fiat-price>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import {EDITION_HISTORY} from '../../queries/activityQueries';
  import DateFrom from '../common/DateFrom';
  import {mapEvent} from '../../services/utils';
  import ProfileNameLink from '../avatar/ProfileNameLink';
  import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

  export default {
    components: {ProfileNameLink, EthWithFiatPrice, DateFrom},
    props: ['editionId'],
    data() {
      return {
        activityEvents: []
      };
    },
    computed: {
      ...mapState('web3Store', ['gqlClient']),
      ...mapGetters('web3Store', [
        'etherscanTxLink'
      ])
    },
    watch: {
      activityEvents (newVal, oldVal) {
        if (newVal !== oldVal) {
          (newVal || []).forEach((event) => {
            const {transactionHash} = event;
            this.$store.dispatch('transactionLookupStore/lookupTransactionDetail', transactionHash);
          });
        }
      }
    },
    mounted() {
      this.$apollo.addSmartQuery('activityEvents', {
        client: this.gqlClient,
        query: EDITION_HISTORY,
        fetchPolicy: 'cache-and-network',
        variables() {
          return {
            edition: this.editionId
          };
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
    },
    methods: {
      mapEvent,
      showMore() {
        this.sliceLen = this.activityEvents.length;
      }
    }
  };
</script>
