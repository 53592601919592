<template>
  <section>

    <nav class="columns mb-4 mt-4">
      <aside class="column is-half-desktop is-full-mobile">
        <b-button type="is-primary" @click="createTokenCollection" outlined
                  v-if="isLoggedInAccountViewingPage && tokensByOwner && tokensByOwner.length > 0">
          Create a collection
        </b-button>
      </aside>
    </nav>

    <nav class="columns has-margin-bottom-6 has-margin-top-6">
      <aside class="column is-full-mobile">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item is-family-tertiary has-text-weight-bold">
              <b-field>
                <b-switch
                  v-model="selectedView"
                  :false-value="viewCollections"
                  :true-value="viewOnSale">
                  {{ viewOnSale }}
                  <b-tooltip position="is-top" label="Items with a list or reserve price" class="ml-1" type="is-dark">
                    <b-icon icon="information-outline" size="is-small"></b-icon>
                  </b-tooltip>
                </b-switch>

              </b-field>
            </div>
          </div>
          <div
            class="level-right"
            v-if="selectedView !== viewCollections"
          >
            <div class="level-item">
              <b-select placeholder="Sort by" v-model="selectedSortBy" size="is-small" class="has-margin-left-7">
                <option
                  v-for="option in sortByOptions"
                  :value="option"
                  :key="option.key">
                  {{ option.label }}
                </option>
              </b-select>
            </div>
          </div>
        </div>
      </aside>
    </nav>

    <section class="content has-margin-bottom-6 has-margin-top-6"
             v-if="selectedView === viewCollections && tokensByOwner && tokensByOwner.length > 0">
      <div
        v-if="formatOwnedTokens && profileCollections && profileCollections.length > 0"
        v-for="profileCollection in profileCollections" :key="profileCollection.name"
      >
        <token-profile-collection
          :profile-collection="profileCollection"
          :address="address"
          :is-logged-in-account-viewing-page="isLoggedInAccountViewingPage"
          @refresh-collection="loadUserCollections"
          @edit-token-collection="editTokenCollection"
        >
        </token-profile-collection>
      </div>
    </section>

    <div class="columns is-multiline" v-if="tokensByOwner && tokensByOwner.length > 0">
      <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card" v-for="token in formatOwnedTokens" :key="token.id">
        <token-card
          :token="token"
          :cover="true"
          :address="address"
          resolution="card"
          class="has-margin-bottom-4"
          @tokens-by-owner-refresh="refreshTokensByOwner"
        >
        </token-card>
      </div>
    </div>
    <div v-else-if="profileCollections && profileCollections.length === 0">
      <empty-state message="No items collected"></empty-state>
    </div>

    <client-only>
      <infinite-loading @infinite="showMore" :identifier="infiniteId">
        <template slot="error">&nbsp;</template>
        <template slot="spinner">&nbsp;</template>
        <template slot="no-results">&nbsp;</template>
        <template slot="no-more">
          <empty-state message="No more tokens"></empty-state>
        </template>
      </infinite-loading>
    </client-only>

  </section>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {TOKENS_BY_OWNER_LISTED, TOKENS_BY_OWNER_QUERY_WITH_EXCLUDE} from '../../queries/tokenQueries';
import EmptyState from '../common/EmptyState';
import TokenCard from '../TokenCard';
import {isOffersOnlySalesType} from '../../services/SaleTypes';
import TokenProfileCollection from './TokenProfileCollection';

const viewCollections = 'Collections'
const viewListed = 'Listed for sale'

const sortByOptions = [
  {label: 'Newest', key: 'newest', orderBy: 'birthTimestamp', orderDirection: 'desc'},
  {label: 'Oldest', key: 'oldest', orderBy: 'birthTimestamp', orderDirection: 'asc'},
  {label: 'Highest price', key: 'highest', orderBy: 'listPrice', orderDirection: 'desc'},
  {label: 'Lowest price', key: 'lowest', orderBy: 'listPrice', orderDirection: 'asc'}
];

const PAGE_SIZE = 6;

export default {
  components: {
    TokenProfileCollection,
    TokenCard,
    EmptyState
  },
  props: ['address'],
  data: () => ({
    skip: 0,
    infiniteId: +new Date(),

    selectedSortBy: sortByOptions[0],
    sortByOptions,

    selectedView: viewCollections,
    viewCollections,
    viewOnSale: viewListed,

    tokensByOwner: null,
    profileCollections: [],
    allCollectionsItems: []
  }),
  computed: {
    ...mapState('web3Store', [
      'gqlClient',
      'authToken'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'hasAuthToken'
    ]),
    isLoggedInAccountViewingPage() {
      return this.isLoggedInAccount(this.address);
    },
    formatOwnedTokens() {
      return this.tokensByOwner.filter(item => (item.edition.creatorContract ? !item.edition.creatorContract.isHidden : true) || !this.tokensByOwner.includes(item.id))
    }
  },
  watch: {
    selectedSortBy(newVal) {
      this.skip = 0
      this.fetchListedView(newVal)
    },
    async selectedView(newVal) {
      this.skip = 0
      if (newVal === viewCollections) {
        await this.fetchTokensByOwners()
      }

      if (newVal === viewListed) {
        await this.fetchListedView()
      }
    },
    // When auth token changed load collection to get private ones
    authToken(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadUserCollections();
      }
    }
  },
  async mounted() {
    await this.fetchTokensByOwners()

    await this.loadUserCollections();
  },
  methods: {
    showMore($state = null) {
      if (this.$apollo.queries.tokensByOwner) {
        this.infiniteId += 1;

        if (!this.$apollo.queries.tokensByOwner.loading) {
          this.skip = this.skip + PAGE_SIZE;

          this.$apollo.queries.tokensByOwner.fetchMore({
            variables: {
              first: PAGE_SIZE,
              skip: this.skip,
              ...this.selectedSortBy
            },
            updateQuery: (previousResult, {fetchMoreResult}) => {
              if (fetchMoreResult.tokensByOwner && fetchMoreResult.tokensByOwner.length > 0) {
                if ($state && $state.loaded) { $state.loaded(); }
              } else if ($state && $state.complete) { $state.complete(); }

              return {
                tokensByOwner: previousResult.tokensByOwner.concat(fetchMoreResult.tokensByOwner)
              };
            }
          });
        }
      }
    },
    async createTokenCollection() {
      await this.signInAndGetAuthToken()
      if (this.hasAuthToken) {
        this.$emit('create-token-collection');
      }
    },
    async editTokenCollection(id) {
      await this.signInAndGetAuthToken()
      if (this.hasAuthToken) {
        this.$emit('edit-token-collection', id);
      }
    },
    isOffersOnlySalesType,
    refreshTokensByOwner() {
      this.$apollo.queries.tokensByOwner.refetch(this.tokensQueryParams({first: this.skip + PAGE_SIZE}));
      this.$apollo.queries.tokensByOwner.startPolling(10000);
    },
    async fetchTokensByOwners() {
      await this.$apollo.addSmartQuery('tokensByOwner', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return TOKENS_BY_OWNER_QUERY_WITH_EXCLUDE([])
        },
        variables() {
          return this.tokensQueryParams();
        },
        error(error) {
          console.log('error', error);
        }
      })
    },
    tokensQueryParams(overrides = {}) {
      return {
        owner: this.address.toLowerCase(),
        first: PAGE_SIZE,
        skip: 0,
        orderBy: 'birthTimestamp',
        orderDirection: 'desc',
        ...overrides
      }
    },
    async loadUserCollections() {
      const {collections, allItems} = await this.$store.dispatch('profileCollectionsStore/getProfileCollections', {
        owner: this.address,
        includePrivate: true,
        collectionType: 'token'
      });
      this.profileCollections = collections;
      this.allCollectionsItems = allItems;
    },
    async fetchListedView(overrides) {
      await this.$apollo.addSmartQuery('tokensByOwner', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return TOKENS_BY_OWNER_LISTED()
        },
        variables() {
          return this.tokensQueryParams(overrides);
        },
        error(error) {
          console.log('error', error);
        }
      })
    },
    async signInAndGetAuthToken() {
      if (!this.hasAuthToken) {
        await this.$store.dispatch('web3Store/createJwtSession');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  display: inline-block !important;
}
</style>
