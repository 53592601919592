var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _vm.workingMetadata && _vm.edition
      ? _c(
          "div",
          { staticClass: "card", class: { ccArtwork: _vm.isCc } },
          [
            _c("image-for-asset-card", {
              attrs: {
                "algolia-info": _vm.algoliaInfo,
                edition: _vm.edition,
                cover: _vm.cover,
                resolution: _vm.resolution,
                metadata: _vm.workingMetadata,
                "click-handler": _vm.clickHandler,
                "is-cc": _vm.isCc,
              },
            }),
            _vm._v(" "),
            _vm._t("artwork-info-asset-card", function () {
              return [
                _c("artwork-info", {
                  attrs: {
                    edition: _vm.edition,
                    size: _vm.size,
                    phase: _vm.phase,
                    "click-handler": _vm.clickHandler,
                  },
                }),
              ]
            }),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "card-footer", class: { borderGrey: _vm.isCc } },
              [
                _vm._t("search-footer-left-asset-card", function () {
                  return [
                    _c("search-footer-left-asset-card", {
                      attrs: {
                        edition: _vm.edition,
                        "working-token": _vm.workingToken,
                        phase: _vm.phase,
                        "token-offer": _vm.tokenOffer,
                        "min-bid": _vm.minBid,
                        "click-handler": _vm.clickHandler,
                      },
                    }),
                  ]
                }),
                _vm._v(" "),
                _vm._t("search-footer-right-asset-card", function () {
                  return [
                    _c("search-footer-right-asset-card", {
                      attrs: {
                        edition: _vm.edition,
                        "working-token": _vm.workingToken,
                        "click-handler": _vm.clickHandler,
                        phase: _vm.phase,
                      },
                    }),
                  ]
                }),
              ],
              2
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }