var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "10px", "margin-right": "10px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "section",
              {
                staticClass:
                  "has-margin-7 has-padding-top-7 has-padding-bottom-7",
              },
              [
                _c(
                  "h2",
                  { staticClass: "mb-0" },
                  [
                    _c("artwork-name-and-artist", {
                      attrs: { "edition-number": _vm.editionId },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h5", { staticClass: "mb-5" }, [
                  _vm._v(
                    "\n            Edition #" +
                      _vm._s(_vm.editionId) +
                      " ownership network.\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n            An experiment in modelling edition ownership.\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "section",
                  [
                    _c("network", {
                      ref: "network",
                      staticClass: "wrapper",
                      attrs: {
                        nodes: _vm.nodes,
                        edges: _vm.edges,
                        options: _vm.options,
                      },
                      on: { "double-click": _vm.onDoubleClick },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n            Close\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }