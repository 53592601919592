var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.insights && _vm.insights.length > 0
        ? _c(
            "b-table",
            {
              attrs: {
                data: _vm.insights,
                paginated: "",
                "pagination-simple": "",
                "per-page": 10,
                striped: "",
                "default-sort": "createdTimestamp",
                "default-sort-direction": "desc",
              },
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "artwork",
                  label: " ",
                  width: "20%",
                  centered: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c(
                                "n-link",
                                { attrs: { to: _vm.buildLink(props.row.id) } },
                                [
                                  props.row
                                    ? _c("image-asset", {
                                        staticClass: "is-hidden-mobile",
                                        attrs: {
                                          "edition-number": props.row.id,
                                          metadata: props.row.metadata,
                                          cover: false,
                                          resolution: "thumbnail",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.row
                                    ? _c(
                                        "span",
                                        { staticClass: "is-hidden-desktop" },
                                        [
                                          _c("artwork-name-and-artist", {
                                            attrs: {
                                              metadata: props.row.metadata,
                                              "edition-number": props.row.id,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  569301681
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "totalAvailable",
                  label: "Size",
                  centered: "",
                  sortable: "",
                  numeric: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c("span", [
                                _vm._v(
                                  "1 of " + _vm._s(props.row.totalAvailable)
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3979791967
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "action", label: "Status", centered: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c(
                                "span",
                                [
                                  props.row.hasSoldOut
                                    ? _c(
                                        "b-tag",
                                        {
                                          staticClass: "is-uppercase",
                                          attrs: { type: "is-dark" },
                                        },
                                        [_vm._v("Sold out")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "is-uppercase is-size-7",
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                props.row.totalAvailable -
                                                  props.row.totalSupply
                                              ) +
                                              " Available\n        "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  438699554
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "totalSold",
                  label: "Sold",
                  centered: "",
                  sortable: "",
                  numeric: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c("span", [_vm._v(_vm._s(props.row.totalSold))])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1120458689
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "action", label: "Gift", centered: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    props.row.tokenIds.length -
                                      props.row.totalSold
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1488183135
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "action", label: "Avg.", centered: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c(
                                "span",
                                [
                                  _c("eth-with-fiat-price", {
                                    attrs: {
                                      "price-in-eth":
                                        props.row.totalEthSpentOnEdition /
                                        props.row.totalSold,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (props) {
                                            return undefined
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  89740424
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "totalEthSpentOnEdition",
                  label: "Total",
                  centered: "",
                  sortable: "",
                  numeric: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c(
                                "span",
                                [
                                  _c("eth-with-fiat-price", {
                                    attrs: {
                                      "price-in-eth":
                                        props.row.totalEthSpentOnEdition,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3345782014
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "allOwners",
                  label: "Owners",
                  centered: "",
                  sortable: "",
                  numeric: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c("span", [
                                _vm._v(_vm._s(props.row.allOwners.length)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3050772086
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "action", label: "Sell out", centered: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.totalAvailable - props.row.totalSupply === 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.difference(props.row))),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2865513770
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "createdTimestamp",
                  label: "Created",
                  centered: "",
                  sortable: "",
                  numeric: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props
                            ? _c(
                                "span",
                                [
                                  _c("date-format", {
                                    attrs: {
                                      "timestamp-in-secs":
                                        props.row.createdTimestamp,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3405832773
                ),
              }),
            ],
            1
          )
        : _c(
            "div",
            [_c("empty-state", { attrs: { message: "No insights" } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }