<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Complete auction
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <section class="has-margin-7">
        <winning-offer :wei-value="edition.reserveAuctionBid"
                       :bidder-address="edition.activeBid.bidder">
        </winning-offer>
      </section>

      <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
        <p>
          Resulting an auction completes the trade between the seller and buyer.
        </p>
        <p>
          All parties can result the auction once it has successfully ended.
        </p>
        <p>
          <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank" lass="is-linkable">Learn how 24 hour auctions work</a>
        </p>
      </b-message>
    </div>

    <template slot="action-feedback-label">
      Auction complete
    </template>

    <template slot="content-balance">&nbsp;</template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="transactionHash"
                @click="resultAuction">
        Complete auction
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import WinningOffer from '../../offers/WinningOffer';

export default {
  components: {WinningOffer, ArtworkNameAndArtist, ModalTemplate},
  props: ['edition'],
  data() {
    return {
      transactionHash: null
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async resultAuction() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/resultEditionReserveAuction', {
          editionId: this.edition.id,
          version: this.edition.version
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
