
export const actions = {

  async getErc20TokenDetails({rootState}, {address}) {
    try {
      const {web3Store} = rootState;
      const {chainId} = web3Store;
      return await this.$cacheApi.$get(`/network/${chainId}/erc20/${address}/details`);
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  async getErc20TokenDetailsAndPrice({rootState, dispatch}, {item}) {
    try {
      const {web3Store} = rootState;
      const {chainId} = web3Store;
      return dispatch('getErc20TokenDetails', {address: item.address})
        .then(async ({details}) => {
          const {USD} = await this.$cacheApi.$get(`/network/${chainId}/erc20/price/${item.address}`);
          details.count = item.value.slice(0, item.value.length - parseInt(details.decimals));
          details.usdPrice = USD ? USD * details.count : 0;
          return {...item, ...details};
        });
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  async getNftTokenMetadata({rootState}, {contractAddress, tokenId}) {
    try {
      const {web3Store} = rootState;
      const {chainId} = web3Store;
      console.log('getNftTokenMetadata', {contractAddress, tokenId});
      return await this.$cacheApi.$get(`/network/${chainId}/erc721/tokens/${contractAddress}/${tokenId}/metadata`);
    } catch (err) {
      console.error(err);
      return null;
    }
  }
};
