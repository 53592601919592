import {ethers} from 'ethers';
import moment from 'moment';

// Default
export const NOT_FOR_SALE = 0;

// V2 & V3
export const BUY_NOW = 1;

// V2 only (legacy)
export const BUY_NOW_AND_OFFERS = 2;

// V2 & V3
export const OFFERS_ONLY = 3;

// V3 only
export const STEPPED_SALE = 4;

// V3 only
export const RESERVE_COUNTDOWN_AUCTION = 5;

// V4 only
export const OPEN_EDITION = 6;

export function equal(a, b) {
  return a.toString() === b.toString();
}

export function isLockableEdition(edition) {
  return parseInt(edition.version) === 3 && isOffersOnlySalesType(edition.salesType);
}

export const mapSalesType = (salesType) => {
  switch (parseInt(salesType)) {
    case NOT_FOR_SALE:
      return 'Not for sale';
    case BUY_NOW:
      return 'Buy now';
    case BUY_NOW_AND_OFFERS:
      return 'Buy now and bids';
    case OFFERS_ONLY:
      return 'Bids only';
    case STEPPED_SALE:
      return 'Stepped sale';
    case RESERVE_COUNTDOWN_AUCTION:
      return 'Reserve countdown auction';
    case OPEN_EDITION:
      return 'Open Edition';
    default:
      return 'Unknown';
  }
};

export const reverseMapSearchSalesType = (salesType) => {
  switch (salesType) {
    case 'buy_now':
      return BUY_NOW
    case 'bids_only':
      return OFFERS_ONLY;
    case 'stepped_sale':
      return STEPPED_SALE
    case 'reserve_countdown_auction':
      return RESERVE_COUNTDOWN_AUCTION;
    case 'open_edition':
      return OPEN_EDITION;
  }
}

export const isNotForSaleSalesType = (salesType) => {
  return parseInt(salesType) === NOT_FOR_SALE;
};

export const isBuyNowSalesType = (salesType) => {
  return parseInt(salesType) === BUY_NOW;
};

export const isBuyNowAndOpenSalesType = (salesType) => {
  return parseInt(salesType) === OPEN_EDITION;
};

export const isBuyNowAndOffersSalesType = (salesType) => {
  return parseInt(salesType) === BUY_NOW_AND_OFFERS;
};

export const isBuyNowTypes = (salesType) => {
  return parseInt(salesType) === BUY_NOW || parseInt(salesType) === BUY_NOW_AND_OFFERS;
};

export const isOffersOnlySalesType = (salesType) => {
  return parseInt(salesType) === OFFERS_ONLY;
};

export const isReserveAuctionSalesType = (salesType) => {
  return parseInt(salesType) === RESERVE_COUNTDOWN_AUCTION;
};

export const isOffersSalesType = (salesType) => {
  return isOffersOnlySalesType(salesType) || isReserveAuctionSalesType(salesType);
};

export const isSteppedSalesType = (salesType) => {
  return parseInt(salesType) === STEPPED_SALE;
};

export const isBuyableSalesType = (salesType) => {
  return isBuyNowSalesType(salesType) || isBuyNowAndOffersSalesType(salesType) || isSteppedSalesType(salesType) || isBuyNowAndOpenSalesType(salesType);
};

export const getNextStep = (priceInWei, stepSaleStepPrice) => {
  if (priceInWei && stepSaleStepPrice) {
    return ethers.BigNumber.from(priceInWei).add(ethers.BigNumber.from(stepSaleStepPrice));
  }
  return 0;
};

export const getStepSalePriceAt = (priceInWei, stepSaleStepPrice, futureSteps) => {
  if (!priceInWei || !stepSaleStepPrice) {
    return 0;
  }
  return ethers.BigNumber.from(stepSaleStepPrice)
    .mul(ethers.BigNumber.from(futureSteps.toString()).sub('1'))
    .add(priceInWei);
};

// state flow of from -> [to] sale type changes
export const AVAILABLE_SALES_TYPES = {
  // KODA V2
  2: {
    // buy now -> buy now (no movement)
    1: [],
    // buy now & offers -> offers
    2: [3],
    // offers -> buy now & offers
    3: [2]
  },
  // KODA V3
  3: {
    // buy now -> offers
    1: [3],
    // offers -> buy now
    3: [1],
    // stepped -> buy now
    4: [1],
    // 24hr reserve -> offers & buy now
    5: [1, 3]
  }
};

export const SaleTypeMappings = {
  [BUY_NOW]: {
    name: 'Buy now',
    description: 'set a list price'
  },
  [BUY_NOW_AND_OFFERS]: {
    name: 'Buy now',
    description: 'set a list price'
  },
  [OFFERS_ONLY]: {
    name: 'Bids only',
    description: 'enable open offers with a off-chain minimum bid'
  },
  [STEPPED_SALE]: {
    name: 'Stepped sale',
    description: 'increase the price on every new sale'
  },
  [RESERVE_COUNTDOWN_AUCTION]: {
    name: 'Reserve auction',
    description: 'create a reserve auction with a 24hr extendable countdown'
  },
  [OPEN_EDITION]: {
    name: 'Open edition',
    description: 'an NFT with no set supply limit'
  }
};

export const getPhaseState = (phase) => {
  if (phase && parseInt(phase.startTime) > moment.utc().unix()) {
    return 'pre-phase';
  }
  if (phase && moment.utc().unix() > parseInt(phase.endTime)) {
    return 'public';
  }
  return 'active';
};
