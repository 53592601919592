var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-image", attrs: { "data-testid": "artworkImg" } },
    [
      _vm.isVideoImageType
        ? _c(
            "span",
            [
              _c("b-icon", {
                staticStyle: {
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  "z-index": "10",
                },
                attrs: { icon: "video", type: "is-light" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.is3DImageType
        ? _c(
            "span",
            [
              _c("b-icon", {
                staticStyle: {
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  "z-index": "10",
                },
                attrs: { icon: "video-3d-variant", type: "is-light" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.is360Image
        ? _c(
            "span",
            [
              _c("b-icon", {
                staticStyle: {
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  "z-index": "10",
                },
                attrs: { icon: "panorama", type: "is-light" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (!_vm.startDatePassed || _vm.startDateJustPassed) &&
      _vm.edition.startDate > 0
        ? _c("span", { staticClass: "has-text-light" }, [
            _c(
              "span",
              {
                staticStyle: {
                  position: "absolute",
                  top: "1rem",
                  left: "1rem",
                  "z-index": "10",
                },
              },
              [_c("b-icon", { attrs: { icon: "alarm", type: "is-light" } })],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.edition && !_vm.disableIcons
        ? _c("unlockable-edition-icon", {
            attrs: { "edition-number": _vm.edition.id },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.edition && !_vm.disableIcons
        ? _c("enhanced-icon", { attrs: { edition: _vm.edition } })
        : _vm._e(),
      _vm._v(" "),
      _c("creator-contract-icon", {
        attrs: { "is-creator-contract": _vm.isCc },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "asset-card-wrapped-link hand-pointer" },
        [
          _c(
            "n-link",
            { attrs: { to: _vm.handleMarketPlaceRouting } },
            [
              _c("image-asset", {
                attrs: {
                  "edition-number": _vm.edition.id,
                  metadata: _vm.workingMetadata,
                  resolution: _vm.resolution,
                  cover: _vm.cover,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }