<template>
  <section>
    <Artist1 v-if="panelType === 'artist1'" :cms-component="cmsComponent" />
    <ArtCarousel v-else-if="panelType === 'artwork1'" :cms-component="cmsComponent" />

    <EarlyAccess v-else-if="panelType === 'editorial1'" :cms-component="cmsComponent" />

    <Editorial2 v-else-if="panelType === 'editorial2'" :cms-component="cmsComponent" :is-application="false" />
    <CollectionHero v-else-if="panelType === 'editorial3'" :cms-component="cmsComponent" />
    <AnimBanner v-else-if="panelType === 'creatorContractBanner'" :content="ccContent" />
    <Contract1 v-else-if="panelType === 'contract1'" :cms-component="cmsComponent" />
  </section>
</template>
<script>

export default {
  props: ['cmsComponent'],
  data() {
    return {
      ccContent: {
                pretitle: 'New feature',
                title: 'Creator Contracts',
                subheader: 'from KnownOrigin',
                largeText: "It's time to take control of your work",
                mainText: "<p>At KnownOrigin, we strive to empower our community with the most effective tools for success in the web3 space.</p> <p>Through our innovative creator contracts, we have streamlined the process of deploying and minting artwork on your own creator-owned contract, all through a simple to use, no-code solution that's available to every creator on our platform.</p>",
                image: 'creator-contracts'
            }
    }
  },
  computed: {
    panelType() {
      return this.cmsComponent && this.cmsComponent.panelType
    }
  }
}
</script>
