<template>
  <section class="is-hidden-mobile">
    <b-navbar fixed-top :close-on-click="true" class="container px-2 mb-1" ref="navbarMenu" type="is-white">

      <template slot="brand">
        <b-navbar-item class="pl-1 is-flex is-align-items-center" tag="router-link" :to="{ path: '/' }">
          <img src="/branding/Black-blue-ko-logo.svg" />
        </b-navbar-item>
      </template>

      <template slot="start">

        <omni-search
          class="mt-0 pt-2 ml-7-widescreen  searchbar-nav-desktop is-one-third column is-hidden-desktop-only margin-left-searchbar nav-searchbar"
          @show-search="toggleSearchBar(false)"
          v-on-clickaway="awayWidescreen"
          :is-mobile="false"
          @update-search-string="updateSearch"
          :key="componentKey"
        ></omni-search>
        <!--component key has been added to force rerendering in an efficient manner, synced parent->child data would be a better solution for v2
        -->

      </template>

      <template slot="end" v-if="searchBarEnabled">

        <omni-search class=" mt-3 searchbar-nav-desktop is-half column is-hidden-widescreen nav-searchbar"
                     @show-search="toggleSearchBar(false)"
                     v-on-clickaway="awayDesktop"
                     :is-mobile="false"

        ></omni-search>

      </template>
      <template slot="end" v-else>

        <b-navbar-item @click="toggleSearchBar(true)" class="is-hidden-widescreen">
          <b-icon icon="magnify"></b-icon>
        </b-navbar-item>
        <b-navbar-item data-testid="marketplaceNav" tag="router-link" :to="{ name: 'marketplace' }" class="text-size is-family-tertiary">
          Marketplace
        </b-navbar-item>
        <b-navbar-item data-testid="activityNav" label="Activity" tag="router-link" :to="{ name: 'activity' }" class="text-size is-family-tertiary">
          Activity
        </b-navbar-item>
        <b-navbar-item data-testid="applyNav" tag="router-link" :to="{ name: 'apply'}" class="text-size is-family-tertiary">
          Apply
        </b-navbar-item>
        <b-navbar-item data-testid="launchMessenger" id="launchMessenger" @click="launchMessenger" class="text-size is-family-tertiary">
          Help
        </b-navbar-item>
        <b-navbar-dropdown right label="Discover" hoverable class="is-hidden-mobile is-right text-size is-family-tertiary" data-testid="discoverNav"
        >
          <b-navbar-item data-testid="creator-contracts_nav" tag="router-link" :to="{ name: 'creator-contracts'}" class="text-size is-family-tertiary">
            Creator Contracts
          </b-navbar-item>
          <b-navbar-item data-testid="trendingNav" tag="router-link" :to="{ name: 'trending' }" class="text-size is-family-tertiary">
            Trending
          </b-navbar-item>
          <b-navbar-item data-testid="hallofNav" tag="router-link" :to="{ name: 'halloffame' }" class="text-size is-family-tertiary">
            Hall of fame
          </b-navbar-item>
          <b-navbar-item data-testid="collectionsNav" tag="router-link" :to="{ name: 'collections'}" class="text-size is-family-tertiary">
            Collections
          </b-navbar-item>
          <b-navbar-item data-testid="journalNav" tag="router-link" :to="{ name: 'journal' }" class="text-size is-family-tertiary">
            Journal
          </b-navbar-item>
          <b-navbar-item data-testid="helpCentreNav" href="https://docs.knownorigin.io/en/" target="_blank" class="text-size">
            Help Centre
          </b-navbar-item>
          <hr class="dropdown-divider">
          <b-navbar-item tag="div">
            <a href="https://discord.gg/knownorigin" target="_blank" data-testid="discord_nav" class="is-flex is-flex-direction-column is-align-items-center">
              <font-awesome-icon :icon="faDiscord" size="xs" class="ml-2" />
            </a>
            <a href="https://twitter.com/knownorigin_io" target="_blank" data-testid="twitterNav">
              <b-icon
                class="ml-6"
                icon="twitter"
                size="is-small">
              </b-icon>
            </a>
            <a href="https://www.instagram.com/knownorigin.io/" target="_blank" data-testid="instaNav">
              <b-icon
                class="ml-6"
                icon="instagram"
                size="is-small">
              </b-icon>
            </a>
            <a href="https://www.reddit.com/r/knownorigin/" target="_blank" data-testid="redditNav">
              <b-icon
                class="ml-6 mr-5"
                icon="reddit"
                size="is-small">
              </b-icon>
            </a>
          </b-navbar-item>
        </b-navbar-dropdown>
        <notification-menu-list class="is-hidden-mobile"></notification-menu-list>

        <b-navbar-item v-if="!isWeb3Connected">
          <b-button @click="loginToWeb3" type="is-primary" outlined data-testid="connectWalletnBtn">Connect wallet
          </b-button>
        </b-navbar-item>

        <b-navbar-dropdown right v-if="isWeb3Connected" label="Profile" hoverable class="is-hidden-mobile text-size is-family-tertiary"
                           data-testid="profileNav">
          <b-navbar-item tag="router-link" class="text-size"
                         :to="{ name: 'profile-id', params:{id: account}}"
                         @click.native="hideMenuOverlay"
                         data-testid="viewprofileNav">
            View profile
          </b-navbar-item>
          <!--         <b-navbar-item @click="launchDonationModal" class="text-size"-->
          <!--                        @click.native="hideMenuOverlay"-->
          <!--                        data-cy="donate_nav">-->
          <!--           Donate to charity-->
          <!--         </b-navbar-item>-->
          <b-navbar-item @click.native="hideMenuOverlay" class="text-size"
                         @click="logoutWeb3"
                         data-testid="logoutNav">
            Logout
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

    </b-navbar>
  </section>
</template>
<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import {mixin as clickaway} from 'vue-clickaway';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import NotificationMenuList from '../NotificationMenuList';
import DonationModal from '../modal/DonationModal';
import OmniSearch from '../search/OmniSearch.vue';

export default {
  components: {NotificationMenuList, OmniSearch},
  mixins: [clickaway],
  data() {
    return {
      searchBarEnabled: false,
      showResults: false,
      componentKey: 0,
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters('web3Store', [
      'isWeb3Connected'
    ]),
    ...mapState('web3Store', ['account']),
    faDiscord() {
      return faDiscord
    }
  },

  methods: {
    ...mapActions('analytics/intercomStore', ['launchMessenger']),
    updateSearch(input) {
      this.searchInput = input;
    },
    awayDesktop() {
      this.searchBarEnabled = false;
    },
    awayWidescreen() {
      this.showResults = false;
      if (this.searchInput.length > 0) {
        this.componentKey += 1;
        this.searchInput = '';
      }
    },
    toggleSearchBar(state) {
      this.searchBarEnabled = state;
    },
    loginToWeb3() {
      console.log('Logging into web3');
      this.$store.dispatch('web3Store/connectToWeb3');
      this.hideMenuOverlay();
    },
    logoutWeb3() {
      console.log('Logging out of web3');
      this.$store.dispatch('web3Store/logoutWeb3');
    },
    hideMenuOverlay() {
      this.$refs.navbarMenu.closeMenu();
    },
    async launchDonationModal() {
      const profile = await this.$store.dispatch('userStore/getUserBySlugOrAddress', this.account);
      this.$buefy.modal.open({
        parent: this,
        component: DonationModal,
        width: 600,
        props: {profile}
      });
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: 'has-navbar-fixed-top'
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.text-size {
  font-size: 18px !important;
}
.nav-searchbar {
  * {
    font-family: 'Space Grotesk', sans-serif !important;
    font-weight: 400;
  }
}

// widescreen
@media only screen and (min-width: 1408px) {
  .margin-left-searchbar {
    margin-left: 3em;
  }
}

// below widesceen
@media only screen and (max-width: 1408px) {
  .searchbar-nav-desktop {
    max-width: 380px;
  }
}
</style>
