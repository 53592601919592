var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-tag", { staticClass: "is-muted is-small is-uppercase" }, [
    _vm._v("🌱 Contributor"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }