import { render, staticRenderFns } from "./HeartIcon.vue?vue&type=template&id=2b25ce56&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b25ce56')) {
      api.createRecord('2b25ce56', component.options)
    } else {
      api.reload('2b25ce56', component.options)
    }
    module.hot.accept("./HeartIcon.vue?vue&type=template&id=2b25ce56&", function () {
      api.rerender('2b25ce56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/common/HeartIcon.vue"
export default component.exports