<template>
  <div class="content" style="margin-left: 20px; margin-right: 20px">
    <div class="card">
      <div class="columns is-centered mb-0">
        <div class="card-content column is-11 pb-0">
          <div class="columns is-mobile mt-2">
            <div class="column is-3">
              <modal-image-asset
                custom-class="image is-square is-128x128"
                :edition-number="getEditionId"
                class="has-margin-top-7"
                v-if="getEditionId"
              >
              </modal-image-asset>
            </div>
            <div class="column">
              <section class="has-padding-top-7 has-padding-bottom-7 ml-3">
                <h2 class="is-size-4 mb-1">
                  <div>
                    {{ getModalTitle }}
                  </div>
                </h2>
                <h5 class="mb-5">
                  <artwork-name-and-artist
                    :metadata="edition.metadata"
                    :edition-number="edition.editionNumber"
                    custom-class="is-size-7"
                  >
                  </artwork-name-and-artist>
                </h5>
              </section>
            </div>
          </div>

          <div class="content mb-3">
            <hr class="p-0 m-0" />
            <section v-if="error === true">
              <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                The transaction has failed
              </p>
              <p class="has-text-weight-light pr-3 mb-5">
                Please return to the edition page and try again.
              </p>
              <div class="is-flex mb-5">
                <b-icon icon="alert-circle" type="is-danger"></b-icon>
                <span class="ml-3">Transaction failed</span>
              </div>
            </section>
            <!-- Cancel listing content -->
            <div v-else-if="operation === 'cancel'">
              <section v-if="step === 0">
                <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                  Are you sure you want to cancel this listing?
                </p>
                <p class="has-text-weight-light pr-3 mb-5">
                  You'll be asked to sign a transaction from your wallet that
                  will cancel this listing
                </p>
                <div class="is-flex mb-5"></div>
              </section>
              <section v-else-if="step === 1">
                <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                  Go to your wallet
                </p>
                <p class="has-text-weight-light pr-3 mb-5">
                  You'll be asked to review and confirm this transaction from
                  your wallet
                </p>
                <div class="is-flex mb-5">
                  <b-icon
                    :icon="
                      complete
                        ? 'check-circle-outline has-text-success'
                        : 'clock-outline has-text-warning-dark'
                    "
                  ></b-icon>
                  <span class="ml-3">{{
                    complete
                      ? "Transaction confirmed"
                      : "Waiting for the transaction to be confirmed"
                  }}</span>
                </div>
              </section>
            </div>
            <!-- Edit / New listing content   -->
            <div v-else>
              <section v-if="step === 0">
                <section class="has-padding-top-7 has-padding-bottom-7">
                  <b-field
                    label="Set ETH sale price"
                    custom-class="my-2"
                    class="mb-0"
                  >
                    <b-input
                      placeholder="0.001"
                      expanded
                      autofocus
                      min="0.001"
                      type="number"
                      step="0.001"
                      v-model="form.salePrice"
                      custom-class="has-text-weight-light"
                    >
                    </b-input>
                  </b-field>
                  <p class="is-muted is-small mt-1">
                    Approximately.
                    <eth-with-fiat-price :only-fiat="true" :price-in-eth="form.salePrice">
                    </eth-with-fiat-price>
                  </p>
                  <b-field label="Set end date" custom-class="mb-2 mt-4">
                    <b-datetimepicker
                      placeholder="Type or select a date..."
                      icon="calendar-today"
                      v-model="form.endDate"
                      :min-datetime="this.form.endDate"
                      editable
                    >
                    </b-datetimepicker>
                  </b-field>

                  <b-field label="Summary" custom-class="mb-2 mt-4">
                    <div class="content">
                      <table class="is-size-7">
                        <tr>
                          <td class="has-text-weight-medium no-border">
                            Listing price
                          </td>
                          <td class="has-text-right no-border">
                            {{ form.salePrice }}
                          </td>
                        </tr>
                        <tr>
                          <td class="has-text-weight-medium no-border">
                            OpenSea fee (0.5%)
                          </td>
                          <td class="has-text-right no-border">
                            -{{ calculateSummary.openSeaFee.toFixed(5) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="has-text-weight-medium no-border">
                            Creator royalties (10%)
                          </td>
                          <td class="has-text-right no-border">
                            -{{ calculateSummary.creatorRoyalties.toFixed(5) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </b-field>
                </section>
                <hr class="p-0 my-2" />
                <div
                  class="is-flex is-justify-content-space-between has-text-weight-medium mt-4"
                >
                  <p>Potential earnings</p>
                  <p>{{ calculateSummary.potentialEarnings }} ETH</p>
                </div>
              </section>
              <section v-else-if="step === 1">
                <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                  Go to your wallet
                </p>
                <p class="has-text-weight-light pr-3 mb-5">
                  You'll be asked to approve the collection from your wallet.
                  You'll only need to approve each collection once.
                </p>
                <div class="is-flex mb-5">
                  <b-icon
                    :icon="
                      approved
                        ? 'check-circle-outline has-text-success'
                        : 'clock-outline has-text-warning-dark'
                    "
                  ></b-icon>
                  <span class="ml-3">{{
                    approved
                      ? "Transaction confirmed"
                      : "Waiting for the transaction to be confirmed"
                  }}</span>
                </div>
              </section>
              <section v-else-if="step === 2">
                <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                  Go to your wallet
                </p>
                <p class="has-text-weight-light pr-3 mb-5">
                  You'll be asked to review and confirm this listing from your
                  wallet
                </p>
                <div class="is-flex mb-5">
                  <b-icon
                    :icon="
                      complete
                        ? 'check-circle-outline has-text-success'
                        : 'clock-outline has-text-warning-dark'
                    "
                  ></b-icon>
                  <span class="ml-3">{{
                    complete
                      ? "Trasaction confirmed"
                      : "Waiting for the transaction to be confirmed"
                  }}</span>
                </div>
              </section>
              <section v-else-if="step === 3">
                <b-field label="Summary" custom-class="mb-2 mt-4" class="mb-5">
                  <div class="content">
                    <table class="is-size-7">
                      <tr>
                        <td class="has-text-weight-medium no-border">
                          Listing price
                        </td>
                        <td class="has-text-right no-border">
                          {{ form.salePrice }}
                        </td>
                      </tr>
                      <tr>
                        <td class="has-text-weight-medium no-border">
                          Valid Until
                        </td>
                        <td class="has-text-right no-border">
                          {{ form.endDate }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </b-field>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="is-flex is-align-items-center">
                  <share
                    class="mt-2 is-inline-block"
                    v-if="edition"
                    type="CC_EDITION"
                    :data="edition"
                  >
                  </share>
                  </div>
                  <b-button
                  outlined
                  tag="a"
                  type="is-primary"
                  target="_blank"
                  :href="getOpenseaLink">
                    <b-icon icon="open-in-new" size="is-small" class="mr-1"></b-icon>
                    View on OpenSea
                  </b-button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- On error buttons -->
      <div class="card-footer" v-if="error === true">
        <div class="card-footer-item">
          <b-button expanded type="" @click="close()"> Close </b-button>
        </div>
      </div>
      <!-- Cancel listing button -->
      <div v-else-if="operation === 'cancel'">
        <div class="card-footer" v-if="step === 0">
          <div class="card-footer-item">
            <b-button expanded @click="close()"> Nevermind </b-button>
          </div>
          <div class="card-footer-item">
            <b-button expanded type="is-primary" @click="cancelListing()">
              End listing
            </b-button>
          </div>
        </div>
        <div class="card-footer" v-if="complete === true">
          <div class="card-footer-item">
            <b-button expanded type="is-primary" @click="close()">
              Done
            </b-button>
          </div>
        </div>
      </div>
      <!-- List buttons -->
      <div v-else>
        <div class="card-footer" v-if="step === 0">
          <div class="card-footer-item">
            <b-button expanded @click="close()"> Nevermind </b-button>
          </div>

          <div class="card-footer-item">
            <b-button expanded type="is-primary" @click="listOnSecondary()">
              Create listing
            </b-button>
          </div>
        </div>
        <div class="card-footer" v-else-if="step === 3">
          <div class="card-footer-item">
            <b-button expanded type="is-primary" @click="close()">
              Done
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import ModalImageAsset from '../../asset-renders/ModalImageAsset';
import { getChainName } from '../../../services/utils'

export default {
  components: {
    ArtworkNameAndArtist,
    ModalImageAsset
  },
  props: ['edition', 'token', 'operation', 'secondaryService'],
  data() {
    return {
      form: {
        salePrice: 0.001,
        endDate: new Date(new Date().getTime() + 86400000) // 86400000 = 1 day in ms
      },
      step: 0,
      approved: false,
      complete: false,
      error: false
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    calculateSummary() {
      // const listingFee = this.form.salePrice * 0.005;
      const listingFee = 0;
      const openSeaFee = this.form.salePrice * 0.025;
      const creatorRoyalties = this.form.salePrice * 0.1;
      const totalFees = listingFee + openSeaFee + creatorRoyalties;

      const potentialEarnings = (this.form.salePrice - totalFees).toFixed(5);

      return { listingFee, openSeaFee, creatorRoyalties, potentialEarnings };
    },
    getEditionId() {
      return this.edition.id;
    },
    getModalTitle() {
      switch (this.operation) {
        case 'cancel':
          return 'Cancel listing';
        case 'list':
          switch (this.step) {
            case 0:
              return 'List this item on OpenSea';
            case 1:
              return 'Approve Collection';
            case 2:
              return 'Complete listing';
            case 3:
              return 'Listing confirmed!';
          }
      }
      return '';
    },
    getOpenseaLink() {
      return `https://${this.chainId !== 1 ? 'testnets.' : ''}opensea.io/assets/${getChainName(this.chainId)}/${this.edition.creatorContract.id}/${this.token.id}`
    }
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    async listOnSecondary() {
      try {
        const params = {
          token: `${this.edition.creatorContract.id}:${this.token.id}`,
          creator: this.token.owner,
          price: String(this.form.salePrice),
          endDate: Math.floor(
            new Date(this.form.endDate).getTime() / 1000
          ).toString()
        };

        const createListingResponse = await this.secondaryService.createListing(
          params
        );
        const [transaction, signature] = this.secondaryService.handleSteps(
          createListingResponse
        );
        const canProceed = await this.$store.dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
        if (!canProceed) {
          return false;
        }
        if (transaction) {
          this.step = 1;
          const tx = await this.$store.dispatch(
            'web3ActionsStore/sendTransaction',
            transaction
          );
          if (tx) {
            this.approved = true;
          } else {
            this.error = true;
            return;
          }
        }
        if (signature) {
          this.step = 2;
          const signedDataRequest = await this.$store.dispatch(
            'web3ActionsStore/signTokenForSecondary',
            signature
          );

          if (signedDataRequest) {
            const signedOrderResponse =
              await this.secondaryService.submitSignedOrder(signedDataRequest);
            for (const result of signedOrderResponse.results) {
              if (result.message === 'success') {
              this.step = 3;
              this.complete = true;
              return;
              }
            }
          }
          this.error = true;
        }
      } catch (e) {
        this.error = true;
      }
    },
    async cancelListing() {
      try {
        const { orderId } = this.token;

        const cancelListingResponse = await this.secondaryService.cancelListing([
          orderId
      ]);
        const [transaction] = this.secondaryService.handleSteps(
          cancelListingResponse
        );

        if (transaction) {
          this.step = 1;
          const tx = await this.$store.dispatch(
            'web3ActionsStore/sendTransaction',
            transaction
          );
          if (tx) {
            this.approved = true;
            this.complete = true;
            return true;
          }
          this.error = true;
          return false;
        }
      } catch (e) {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss">
.no-border {
  border-style: none !important;
  padding: 0 !important;
}
</style>
