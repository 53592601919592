var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "container" }, [
    _c("section", { staticClass: "mt-6 mb-6 editorial4-desktop" }, [
      _c("div", { staticClass: "columns content has-text-centered mb-4" }, [
        _c("div", { staticClass: "column" }, [
          _c("h1", { staticClass: "title is-size-3 pb-2" }, [
            _vm._v(_vm._s(_vm.cmsComponent.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "columns mx-3" },
            _vm._l(_vm.cmsComponent.editorial4Fields, function (field) {
              return _c(
                "div",
                {
                  key: field.uniqueKey,
                  staticClass: "column content has-text-centered",
                },
                [
                  _c("b-icon", {
                    attrs: { icon: field.icon, size: "is-medium" },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "is-size-5 has-text-weight-bold" }, [
                    _vm._v(_vm._s(field.subheading)),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(field.content))]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "editorial4-mobile" }, [
      _c("div", { staticClass: "columns content has-text-centered mb-4" }, [
        _c("div", { staticClass: "column" }, [
          _c("h1", { staticClass: "title is-size-5 pb-2" }, [
            _vm._v(_vm._s(_vm.cmsComponent.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "columns content has-text-centered" },
            _vm._l(_vm.cmsComponent.editorial4Fields, function (field) {
              return _c(
                "div",
                { key: field.uniqueKey, staticClass: "my-5" },
                [
                  _c("b-icon", {
                    attrs: { icon: field.icon, size: "is-medium" },
                  }),
                  _vm._v(" "),
                  _c("h2", { staticClass: "is-size-5 has-text-weight-bold" }, [
                    _vm._v(_vm._s(field.subheading)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mx-4 mx-6-tablet" }, [
                    _vm._v(_vm._s(field.content)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }