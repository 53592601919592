import gql from 'graphql-tag';
import {TOKEN_FIELDS} from './tokenQueries';

export const RECENT_WHALE_PURCHASES = gql(`query recentWhalePurchases($first: Int!) {
    recentWhalePurchases: collectors(first: $first, orderBy: lastPurchaseTimeStamp, orderDirection: desc, where: {totalPurchaseEthSpent_gte: 5}) {
    tokens (first: 1, orderBy: lastTransferTimestamp, orderDirection: desc, subgraphError: allow) {
      ${TOKEN_FIELDS}
    }
  }
}`);
