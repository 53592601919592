/* eslint-disable-next-line lodash/import-scope */
import _ from 'lodash';

const failedProvenanceCheck = {passed: false};

// see: https://contentauthenticity.org/
export function validateCai(version, provenance, minter) {
  console.log('Attempting CAI');
  try {
    if (provenance?.exists && version === 1) {
      // Lookup signature addresses
      const foundAssertion = provenance.activeClaim?.findAssertion('adobe.crypto.addresses') || [];

      // Find all signers
      const ethSigners = _.get(foundAssertion, 'assertion.data.ethereum', []);

      if (ethSigners) {
        // check the signers against the original minter we have
        const provenceCheckPassed = ethSigners.some(signer => signer.toLowerCase() === minter.toLowerCase());

        // Build response payload
        const results = {
          passed: provenceCheckPassed,
          signers: ethSigners,
          claimSigned: provenance.activeClaim?.signature?.date,
          claimIssuer: provenance.activeClaim?.signature?.issuer,
          claimProducer: provenance.activeClaim?.recorder
        };

        console.log('CAI provence result', results);
        return results;
      }

      console.log('CAI provenance not found', ethSigners);
      return failedProvenanceCheck;
    } else if (provenance.data.active_manifest && version === 2) {
      const currentProvenance = provenance.data.manifests[provenance.data.active_manifest]
      const foundAssertion = getAssertion(currentProvenance.assertions, 'adobe.crypto.addresses')
      if (foundAssertion.found) {
        const ethSigners = foundAssertion.data.ethereum
        const provenceCheckPassed = ethSigners.some(signer => signer.toLowerCase() === minter.toLowerCase());

        const results = {
          passed: provenceCheckPassed,
          signers: ethSigners,
          claimSigned: currentProvenance.signature_info?.time,
          claimIssuer: currentProvenance.signature_info?.issuer,
          claimProducer: currentProvenance.claim_generator
        };

        console.log('CAI provence result', results);
        return results;
      }
    } else {
      console.log('Provenance does not exist')
    }
  } catch (e) {
    console.error('CAI provence error ', e);
    return failedProvenanceCheck;
  }
}

export function isCaiApplicable(chainId, editionId, imageUrl) {
  if (!chainId || !editionId || !imageUrl) {
    return false;
  }

  // Only jpg and png supported atm
  const isValidImageType = (imageUrl || '').includes('.jpg') || (imageUrl || '').includes('.png');

  // do we need some goerli logic here?
  if (parseInt(chainId) === 4) {
    return parseInt(editionId || '0') >= 193000 && isValidImageType;
  }

  return parseInt(editionId || '0') >= 3624000;
}

function getAssertion(_array, label) {
    let results = {found: false}
    _array.forEach((item) => {
      if (item.label.toLowerCase() === label.toLowerCase()) {
        results = {found: true, data: item.data}
      }
    })
    return results
}
