var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                  ? "Change reserve price"
                  : "Change price"
              ) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _c(
          "section",
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                    ? "Enter new reserve price"
                    : "Enter new price",
                  message: `Min. sale price is ${_vm.minAmount}`,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    placeholder: "1.23",
                    expanded: "",
                    autofocus: "",
                    type: "number",
                    min: 0,
                    max: 100000,
                    step: "any",
                  },
                  model: {
                    value: _vm.form.priceInEth,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "priceInEth", $$v)
                    },
                    expression: "form.priceInEth",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.edition.offersOnly &&
            _vm.isBuyNowSalesType(_vm.edition.salesType)
              ? _c(
                  "p",
                  [
                    _vm._v("\n        Current price\n        "),
                    _c("eth-with-fiat-price", {
                      attrs: { "price-in-wei": _vm.edition.priceInEth },
                    }),
                  ],
                  1
                )
              : _vm.isReserveAuctionSalesType(_vm.edition.salesType)
              ? _c(
                  "p",
                  [
                    _vm._v("\n        Current reserve price\n        "),
                    _c("eth-with-fiat-price", {
                      attrs: { "price-in-wei": _vm.edition.reservePrice },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isReserveAuctionSalesType(_vm.edition.salesType)
              ? _c(
                  "div",
                  {},
                  [
                    _c(
                      "b-message",
                      {
                        staticClass: "is-size-7",
                        attrs: {
                          type: "is-info",
                          closable: false,
                          duration: 0,
                        },
                      },
                      [
                        _vm._v(
                          "\n          Lowering the reserve price below an existing bid will trigger the 24hr countdown and the auction wll\n          begin.\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                ? "Changing reserve price"
                : "Changing price"
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                expanded: "",
                disabled: _vm.disableButton,
              },
              on: { click: _vm.changePrice },
            },
            [_vm._v("\n      Change price\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }