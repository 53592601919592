var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.tokens
      ? _c(
          "aside",
          { staticClass: "content scrollable-content is-family-tertiary" },
          [
            _c(
              "ul",
              _vm._l(_vm.tokens, function (token, idx) {
                return _c("li", { key: idx }, [
                  _c("div", { staticClass: "columns is-mobile is-multiline" }, [
                    _c(
                      "div",
                      { staticClass: "column is-half" },
                      [
                        _c(
                          "n-link",
                          { attrs: { to: _vm.rerouteToken(token) } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "heading has-text-weight-semibold",
                              },
                              [
                                _vm._v(
                                  "#" +
                                    _vm._s(
                                      _vm.calculateIterativeEditionId(
                                        _vm.edition,
                                        token.id
                                      )
                                    )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "heading" },
                          [
                            token.currentOwner
                              ? _c("profile-name-link", {
                                  attrs: {
                                    "eth-account": token.currentOwner.id,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-half" }, [
                      _c(
                        "span",
                        { staticClass: "heading" },
                        [
                          _c("date-from", {
                            attrs: {
                              "timestamp-in-secs": token.lastTransferTimestamp,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "heading" },
                        [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": token.lastSalePriceInEth || 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.tokens && _vm.tokens.length > 0
      ? _c("aside", { staticClass: "content" }, [
          _c("ul", [
            _c("li", [
              _c("div", { staticClass: "columns is-mobile is-multiline" }, [
                _c("div", { staticClass: "column is-half" }, [
                  _c(
                    "span",
                    {
                      staticClass: "heading is-clickable",
                      on: { click: _vm.launchOwnershipGraph },
                    },
                    [
                      _vm._v("\n            Ownership graph "),
                      _c("b-icon", {
                        attrs: { icon: "graphql", size: "is-small" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }