<template>
  <div>
    <header class="header-image has-text-white" v-if="headerImageUrl" v-lazy:background-image="imgObj">
      <section class="hero">
        <div class="hero-body">
          <div class="container content">
            <h1 class="title has-text-white has-margin-top-1 is-size-2-mobile">
              <n-link :to="linkToJournal"
                      class="has-text-white has-padding-top-6"
                      v-if="headerImageUrl">
                {{ title }}
                <br />
                {{ subtitle }}
                <span v-if="more">
                  <br />
                  {{ more}}
                </span>
              </n-link>
            </h1>
            <div class="content">
              <div class="field is-grouped">
                <p class="control">
                  <n-link :to="linkToJournal" class="button is-white is-outlined" v-if="!newTab">
                    {{ buttonLabel }}
                  </n-link>
                  <a :href="linkToJournal" class="button is-white is-outlined" v-else target="_blank">
                    {{ buttonLabel }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  </div>
</template>

<script>
  import {PUBLIC_BLOG_ASSET_BUCKET} from '../../services/utils';

  export default {
    props: ['headerImageUrl', 'title', 'subtitle', 'more', 'buttonLabel', 'linkToJournal', 'newTab'],
    data() {
      return {};
    },
    computed: {
      imgObj() {
        if (this.headerImageUrl && this.headerImageUrl.startsWith(PUBLIC_BLOG_ASSET_BUCKET)) {
          return this.$publicBlogAssetImgixClient.buildURL(
            this.headerImageUrl.replace(PUBLIC_BLOG_ASSET_BUCKET, ''),
            {h: 800, ar: '16:9', fit: 'cover'}
          );
        }

        return this.headerImageUrl;
      }
    }
  };
</script>
