var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [_c("offers-combined", { attrs: { address: _vm.address } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }