var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level is-mobile box is-family-tertiary" }, [
    _c("div", { staticClass: "level-item has-text-centered" }, [
      _c("div", [
        _c("p", { staticClass: "heading" }, [_vm._v("Edition #")]),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [_vm._v("#" + _vm._s(_vm.formatId))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "level-item has-text-centered" }, [
      _c("div", [
        _c("p", { staticClass: "heading" }, [_vm._v("Editions")]),
        _vm._v(" "),
        _vm.edition.isOpenEdition
          ? _c("p", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.edition.totalSold)),
            ])
          : _c("p", { staticClass: "title" }, [
              _vm._v("1 of " + _vm._s(_vm.edition.totalAvailable)),
            ]),
      ]),
    ]),
    _vm._v(" "),
    !_vm.isNeverEndingEdition && _vm.isBuyNowAndOpenSalesType
      ? _c("div", { staticClass: "level-item has-text-centered" }, [
          _vm.edition.isOpenEdition
            ? _c("div", [
                _c("p", { staticClass: "heading" }, [_vm._v("End date")]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "title" },
                  [
                    _c("date-format", {
                      attrs: {
                        "timestamp-in-secs": _vm.formatEndDate,
                        shorter: true,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.edition.remainingSupply > 0 && !_vm.editionEndDatePassed
      ? _c("div", { staticClass: "level-item has-text-centered" }, [
          _c("div", [
            _c("p", { staticClass: "heading" }, [_vm._v("Available")]),
            _vm._v(" "),
            _c("p", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.edition.totalAvailable - _vm.edition.totalSupply) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }