import _first from 'lodash/first';
import _orderBy from 'lodash/orderBy';

/**
 * Utility method for polling for Wert based notifications from the KO API.
 * @param $store the vuex store
 * @param sentNotification handler for sending notifications
 * @param click_id the associated wert payment ID, also know as click_id
 * @param stopTimer the call back handler when the polling function stops
 * @return {number} the internal ID to be cancelled by the calling view
 */
export function startNotificationFlow($store, sentNotification, click_id, stopTimer) {
  return setInterval(async function () {
    const {data} = await $store.dispatch('web3ActionsStore/getWertPurchaseState', {click_id});
    if (data.events && data.events.length > 0) {
      const lastEvent = _first(_orderBy(data.events, 'timestamp', 'desc'));

      if (lastEvent.type === 'payment_started') {
        // we send it once user initiates the card payment, we send it the same time we send the request to the acquirer.
        // So "payment sent" can be shown upon receiving this webhook

        sentNotification({
          eventCode: `payment_started_${click_id}`,
          type: 'pending',
          message: 'Card purchase started'
        });
      } else if (lastEvent.type === 'order_complete') {
        // we send it once transaction was successfully executed(and card payment before that was successful),
        // this means that user completed the order successfully and now have token at his address.

        sentNotification({
          eventCode: `order_complete_${click_id}`,
          type: 'success',
          message: 'Card purchase success'
        });
        stopTimer();
      } else if (lastEvent.type === 'order_failed') {
        // we send it if the card payment was failed, this means that user’s card wasn’t charged and the tx wasn’t sent.
        // There many reasons why card payment can fail: user don’t have enough funds, user has entered wrong card details, wrong cvv,
        // or user’s bank didn’t let this payment through. You can show ‘payment failed’ - or something like that.

        sentNotification({
          eventCode: `order_failed_${click_id}`,
          type: 'error',
          message: 'Card purchase failed'
        });
      } else if (lastEvent.type === 'order_cancelled') {
        // that’s the worst. This means that we charged the user, but didn’t send the transaction.
        // If this happens - in 99% of the time this means that there’s an issue on our side - either something happened on our backend, and we weren’t able to process the order correctly.
        // If you get this - better advise user to contact support, and alert us. Usually we need to get in contact with the user to issue a refund.

        sentNotification({
          eventCode: `order_failed_${click_id}`,
          type: 'error',
          message: 'Card purchase failed - please contact KO team on discord'
        });
        stopTimer();
      } else if (lastEvent.type === 'tx_smart_contract_failed') {
        // this is a rare case, this means that we sent the initial transaction, but due to some reason we weren’t able to execute the transaction successfully(run out of gas)
        // this usually happens because something happened to the state of the smart contract, and the gas required to execute it was significantly higher than we estimated before transaction.

        sentNotification({
          eventCode: `tx_smart_contract_failed_${click_id}`,
          type: 'error',
          message: 'Card purchase failed - please contact KO team on discord'
        });
        stopTimer();
      }
    }
  }, 10000);
}

export default startNotificationFlow;
