<template>
  <section class="container">
    <section class="mt-6 mb-6 editorial4-desktop">
      <div class="columns content has-text-centered mb-4">
        <div class="column">
          <h1 class="title is-size-3 pb-2">{{ cmsComponent.title }}</h1>
          <div class="columns mx-3">
            <div class="column content has-text-centered" v-for="(field) in cmsComponent.editorial4Fields"
                 :key="field.uniqueKey">
              <b-icon :icon="field.icon" size="is-medium"></b-icon>
              <h2 class="is-size-5 has-text-weight-bold">{{ field.subheading }}</h2>
              <p>{{ field.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="editorial4-mobile">
      <div class="columns content has-text-centered mb-4">
        <div class="column">
          <h1 class="title is-size-5 pb-2">{{ cmsComponent.title }}</h1>
          <div class="columns content has-text-centered">
            <div v-for="(field) in cmsComponent.editorial4Fields"
                 :key="field.uniqueKey" class="my-5">
              <b-icon :icon="field.icon" size="is-medium"></b-icon>
              <h2 class="is-size-5 has-text-weight-bold">{{ field.subheading }}</h2>
              <p class="mx-4 mx-6-tablet">{{ field.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>

export default {
  props: ['cmsComponent']
}
</script>
