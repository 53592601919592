<template>
  <aside class="content scrollable-content is-family-tertiary" v-if="edition">
    <ul>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Sold</div>
          <div class="column heading has-text-weight-semibold">{{ edition.totalSold }}</div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Gifted</div>
          <div class="column heading has-text-weight-semibold">{{ gifted }}</div>
        </div>
      </li>
      <li v-if="hasSales">
        <div class="columns is-mobile">
          <div class="column heading">Avg. sale price</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price :price-in-eth="avgSalePrice"></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li v-if="hasSales">
        <div class="columns is-mobile">
          <div class="column heading">Total</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price :price-in-eth="totalEarned"></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li v-if="hasSoldOut">
        <div class="columns is-mobile">
          <div class="column heading">Time to sellout</div>
          <div class="column heading has-text-weight-semibold">
            {{ difference() }}
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Owners</div>
          <div class="column heading has-text-weight-semibold">{{ (edition.allOwners || []).length }}</div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Created</div>
          <div class="column heading has-text-weight-semibold">
            <date-format :timestamp-in-secs="edition.createdTimestamp"></date-format>
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>

<script>

import _size from 'lodash/size';
import _first from 'lodash/first';
import _last from 'lodash/last';
import _sortBy from 'lodash/sortBy';
import DateFormat from '../common/DateFormat';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, DateFormat},
  props: ['edition'],
  data() {
    return {
      editionData: null
    };
  },
  computed: {
    gifted() {
      return (this.edition.tokenIds || []).length - (this.edition.totalSold || 0) - (this.edition.totalBurnt || 0);
    },
    avgSalePrice() {
      return this.edition.totalSold ? this.$options.filters.toDp(this.edition.totalEthSpentOnEdition / this.edition.totalSold) : 0
    },
    totalEarned() {
      return this.edition.totalEthSpentOnEdition ? this.$options.filters.toDp(this.edition.totalEthSpentOnEdition) : 0
    },
    hasSoldOut() {
      return parseInt(this.edition.remainingSupply) === 0;
    },
    hasSales() {
      return _size(this.edition.sales) > 0
    },
    firstSaleTimestamp() {
      return _size(this.edition.sales) > 0 ? _first(_sortBy(this.edition.sales, 'birthTimestamp')).birthTimestamp : '';
    },
    lastSaleTimestamp() {
      return _size(this.edition.sales) > 0 ? _last(_sortBy(this.edition.sales, 'birthTimestamp')).birthTimestamp : '';
    }
  },
  methods: {
    difference() {
      let startTime;
      if (this.edition.totalSupply === '1') {
        startTime = this.edition.createdTimestamp;
      } else {
        startTime = this.firstSaleTimestamp;
      }

      const endTime = this.lastSaleTimestamp;

      const duration = this.$moment.duration(this.$moment(endTime * 1000).diff(this.$moment(startTime * 1000)));
      const hours = duration.asHours();

      if (hours <= 1) {
        const mins = duration.asMinutes().toFixed(2);
        return mins > 0 ? `${mins} mins` : '';
      }

      if (hours <= 24) {
        return `${hours.toFixed(2)} hours`
      }

      return `${duration.asDays().toFixed(2)} days`;
    }
  }
};
</script>
