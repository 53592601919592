export const state = () => ({
  trending: null
});

export const mutations = {
  storeTrending(state, trending) {
    state.trending = trending;
  }
};

export const actions = {
  async getTrending({state, commit}) {
    if (state.trending) {
      return state.trending;
    }
    const trending = await this.$axios.$get('https://known-origin-trending-engine-gp2s5beobq-uc.a.run.app/trending/scores');
    commit('storeTrending', trending);
    return trending;
  }
};
