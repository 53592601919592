var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasUnlockableContent(_vm.editionNumber)
    ? _c("span", [
        _c(
          "span",
          {
            staticClass: "hand-pointer",
            staticStyle: {
              position: "absolute",
              bottom: "1rem",
              left: "1rem",
              "z-index": "10",
            },
          },
          [
            _c(
              "b-tooltip",
              {
                staticClass: "ml-1",
                attrs: {
                  position: "is-top",
                  type: "is-dark",
                  label: "Includes unlockable content",
                },
              },
              [
                _c(
                  "b-tag",
                  {
                    ref: "unlockable-label",
                    staticClass: "is-dark is-uppercase hand-pointer",
                  },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "lock",
                        type: "is-light",
                        size: "is-small",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }