var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      !_vm.hidden
        ? _c("vue-countdown", {
            staticClass: "mx-3",
            attrs: {
              time: _vm.diff,
              transform: _vm.transform,
              "emit-events": true,
            },
            on: { progress: _vm.handleCountdownProgress },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("section", [
                        !_vm.hideLabel
                          ? _c(
                              "p",
                              {
                                staticClass: "title",
                                class: {
                                  "has-text-white": _vm.whiteText === true,
                                },
                              },
                              [_vm._v("\n          Auction ends in\n        ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "level is-mobile box",
                            class: { "has-text-white": _vm.whiteText === true },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "level-item has-text-centered" },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-3 heading is-marginless",
                                    },
                                    [_vm._v(_vm._s(props.hours))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "heading" }, [
                                    _vm._v("Hours"),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "level-item has-text-centered" },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-3 heading is-marginless",
                                    },
                                    [_vm._v(_vm._s(props.minutes))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "heading" }, [
                                    _vm._v("Minutes"),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "level-item has-text-centered" },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-size-3 heading is-marginless",
                                    },
                                    [_vm._v(_vm._s(props.seconds))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "heading" }, [
                                    _vm._v("Seconds"),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1888361337
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }