<template>
  <nuxt-link :to="{ name: 'contract-contract', params: { contract: address } }"
             @click="fireShowcaseEvent('contract');" class="mt-1 is-flex m-0">
    <div
      class="content box-fat columns is-mobile has-background-dark is-flex is-justify-content-flex-start is-align-items-center showcase-width is-family-tertiary"
      v-if="contractDetails">
      <figure class="m-0 ml-3 is-flex is-justify-content-center">
        <img class="img-cc" v-lazy="{
          src: contractThumbnail,
          error: createAccountIcon()
        }" />
      </figure>
      <div class="column content is-three-quarters is-flex is-flex-direction-column">
        <span class="is-size-7 has-text-white">CREATOR CONTRACT</span>
        <section class="content">
          <div class="level is-mobile mt-0">
            <div class="level-left content my-auto noOverflow">
                <span class="is-size-6 has-text-weight-normal has-text-white">{{
                    contractDetails.name | truncate(30)
                  }}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </nuxt-link>
</template>
<script>
import {toDataUrl} from 'myetherwallet-blockies';
import {cleanUpUrl} from '../services/utils';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../services/analyticsEvents';

export default {
  props: ['address'],
  data() {
    return {
      contractThumbnail: null,
      contractDetails: null
    };
  },
  async mounted() {
    try {
      this.contractDetails = await this.$store.dispatch('creatorContractStore/fetchContractDetails', {
        address: this.address
      });
      this.contractThumbnail = await this.$store.dispatch('creatorContractStore/fetchContractImage', {
        address: this.address,
        imageType: 'thumbnail'
      });
    } catch (e) {
      console.log('Problem with creator contract details fetch');
    }
  },
  methods: {
    cleanUpUrl,
    createAccountIcon() {
      return toDataUrl(this.address);
    },
    fireShowcaseEvent(destination) {
      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.pillClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'creator',
          [AMPLITUDE_EVENT_PROPERTIES.contractAddress]: this.address,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination
        }
      });
    }
  }
}

</script>
<style lang="scss" scoped>
@import "../assets/colours.scss";
@import "~bulma/sass/utilities/initial-variables";

.box-fat {
  margin-top: 2rem;
  height: 5rem;
  border-radius: .8rem;
}

.noOverflow {
  word-wrap: break-word;
  width: 99%;
}

.img-cc {
  border-radius: 10px;
  aspect-ratio: 1/1;
  max-width: 60px;
}

.scale-cc-tag {
  transform: scale(0.8);
  width: 9.3rem;
}

.showcase-width {
  min-width: 18em;
}

</style>
