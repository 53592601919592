var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level is-mobile box has-background-dark has-text-light" },
    [
      _c("div", { staticClass: "level-item has-text-centered" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "heading" }, [_vm._v("Token")]),
            _vm._v(" "),
            _vm._l(_vm.populatedDetails, function (token, i) {
              return _c("span", { key: i }, [
                _c("p", { staticClass: "has-text-weight-bold" }, [
                  _vm._v(_vm._s(token.symbol)),
                ]),
                _vm._v(" "),
                i !== _vm.tokens.length - 1 ? _c("br") : _vm._e(),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-item has-text-centered" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "heading" }, [_vm._v("Per edition")]),
            _vm._v(" "),
            _vm._l(_vm.populatedDetails, function (token, i) {
              return _c(
                "span",
                { key: i, staticClass: "has-text-weight-bold" },
                [
                  _c("p", [_vm._v(_vm._s(token.count))]),
                  _vm._v(" "),
                  i !== _vm.populatedDetails.length - 1 ? _c("br") : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-item has-text-centered" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "heading" }, [_vm._v("USD Value")]),
            _vm._v(" "),
            _vm._l(_vm.populatedDetails, function (token, i) {
              return _c(
                "span",
                { key: i, staticClass: "has-text-weight-bold" },
                [
                  token.usdPrice
                    ? _c("p", [
                        _vm._v(
                          "\n        $" +
                            _vm._s(_vm._f("to2Dp")(token.usdPrice)) +
                            "\n      "
                        ),
                      ])
                    : _c("p", [_vm._v("n/a")]),
                  _vm._v(" "),
                  i !== _vm.populatedDetails.length - 1 ? _c("br") : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }