var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "title" }, [_vm._v("\n    Winning account\n  ")]),
    _vm._v(" "),
    _c(
      "p",
      [
        _c("user-profile-lookup", {
          attrs: {
            "artist-account": _vm.bidderAddress,
            "metadata-artist": _vm.bidderAddress,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      [
        _c("eth-with-fiat-price", {
          attrs: {
            size: "is-large",
            "show-fiat": "true",
            "price-in-wei": _vm.weiValue,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }