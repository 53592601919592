<template>
  <div v-if="topCollectors" class="has-margin-top-7 mb-6 pb-6">
    <section class="topCollectors mb-2">
      <div
        class="topCollectors__label is-hidden-mobile title ml-2 has-text-weight-bold is-family-tertiary"
      >
        Top 50 collectors by total ETH purchased
      </div>
    </section>
    <b-table
      :data="topCollectors"
      default-sort="totalPurchaseEthSpent"
      sticky-header
      height="auto"
      striped
      paginated
      pagination-simple
      :per-page="25"
      class="topCollectors__table is-family-tertiary"
    >
      <div
        class="topCollectors__label topCollectors__label--mobile title mb-3 is-hidden-tablet is-text-weight-bold is-family-tertiary"
      >
        Top 250 collectors by total ETH purchased
      </div>

      <!--        <b-table-column field="index" label="#" centered-->
      <!--                        class="is-size-5 is-size-6-mobile">-->
      <!--          {{ props.index + 1 }}-->
      <!--        </b-table-column>-->

      <b-table-column field="address" label="Collector" v-slot="props">
        <user-profile-lookup
          :artist-account="props.row.address"
          :metadata-artist="props.row.address"
          :is-table="true"
          :no-subtitle="true"
        >
        </user-profile-lookup>
      </b-table-column>

      <b-table-column
        field="firstSeen"
        label="First purchase"
        centered
        class="is-size-5 is-size-6-mobile"
        v-slot="props"
      >
        <span class="is-family-tertiary">
          {{ (props.row.firstSeen * 1000) | moment("MMM Do YYYY") }}
        </span>
      </b-table-column>

      <b-table-column
        field="totalPurchaseEthSpent"
        label="Spend"
        centered
        sortable
        :custom-sort="sortableString('totalPurchaseEthSpent')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          {{ column.label }}
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.totalPurchaseEthSpent"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>

      <b-table-column
        field="totalPurchaseCount"
        label="Purchases"
        centered
        sortable
        :custom-sort="sortableString('totalPurchaseCount')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          {{ column.label }}
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template v-slot="props">
          <div class="is-family-tertiary">
            {{ props.row.totalPurchaseCount }}
          </div>
        </template>
      </b-table-column>

      <b-table-column
        field="secondarySaleEthTotal"
        label="Sales ETH"
        centered
        sortable
        :custom-sort="sortableString('secondarySaleEthTotal')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          {{ column.label }}
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.secondarySaleEthTotal"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>

      <b-table-column
        field="secondarySaleCount"
        label="Sales"
        centered
        sortable
        :custom-sort="sortableString('secondarySaleCount')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          {{ column.label }}
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template v-slot="props">
          <span class="is-family-tertiary">{{
            props.row.secondarySaleCount
          }}</span>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TOP_COLLECTORS } from "../../queries/hallOfFameQueries";
import UserProfileLookup from "../../components/UserProfileLookup";
import EthWithFiatPrice from "@/components/common/EthWithFiatPrice";

export default {
  components: {
    EthWithFiatPrice,
    UserProfileLookup,
  },
  data() {
    return {
      topCollectors: [],
    };
  },
  computed: {
    ...mapState("web3Store", ["gqlClient"]),
  },
  mounted() {
    this.$apollo.addSmartQuery("topCollectors", {
      client: this.gqlClient,
      query: TOP_COLLECTORS,
      error(error) {
        console.log("error", error);
      },
    });
    this.scrollTopOnPageChange();
  },
  methods: {
    sortableString(field) {
      return (a, b, isAsc) => {
        return isAsc
          ? parseFloat(b[field]) - parseFloat(a[field])
          : parseFloat(a[field]) - parseFloat(b[field]);
      };
    },
    scrollTopOnPageChange() {
      const pageLinks = document.querySelectorAll(".pagination-link");

      for (const link of pageLinks) {
        link.addEventListener("click", () => {
          if (!link.getAttribute("disabled")) {
            // setTimeout makes this work on mobile
            setTimeout(() => {
              window.scroll({
                top: 0,
                behavior: "smooth",
              });
            }, 0);
            document.querySelector(".table-wrapper").scrollTop = 0;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.topCollectors {
  position: relative;

  &__label {
    position: absolute;
    top: -40px;
    right: 0;

    &--mobile {
      position: relative;
      top: unset;
    }
  }

  &__table {
    td,
    th {
      padding-left: 0 !important;

      &:last-of-type {
        padding-right: 0;
      }
    }

    thead {
      background: white !important;
    }

    th {
      span {
        font-weight: 500;
        white-space: nowrap;
        opacity: 0.5 !important;

        i {
          display: none;
        }
      }
    }

    .media-left {
      margin-right: 0.5rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle !important;
    }

    td > aside {
      align-items: center !important;
      vertical-align: middle !important;
    }

    .level {
      justify-content: center;
    }

    .info {
      order: 2;
      margin: 0 20px;
    }

    // temp hide pagination until bug is fixed
    .top.level {
      display: none;
    }
  }
}
</style>
