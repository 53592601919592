<template>
  <div>
    <!-- The main video element videojs will render the video with -->
    <video v-if="!cover"
           ref="videoPlayer"
           class="video-js forced-white"
           loop="loop"
           muted="muted"
           autoplay="autoplay"
           playsinline="playsinline"
           disablePictureInPicture
           controlsList="nodownload"
           :poster="cardAsset"
           :id="`edition_video_${editionNumber}`">
      <p>
        Sorry your browser doesn't seem to like playing this type of media.
      </p>
    </video>
    <!-- Showing cover images of video -->
    <div v-else v-lazy-container="{ selector: 'img' }">
      <img
        v-if="!coverImageLoadError"
        :class="{'card-image-cover': cover}"
        :id="`edition_image_${editionNumber}`"
        :data-src="cardAsset"
        data-loading="/branding/bigloader-image.svg"
        data-error="/branding/holding_image.jpg"
        @error="onCoverImageLoadError"
      />
      <!-- Sometimes legacy videos dont have a cover image, if this is the case, render a static video which cannot be triggered -->
      <video v-else
             ref="videoPlayer"
             :id="`edition_video_${editionNumber}`"
             width="100%"
             height="100%"
             loop="loop"
             muted="muted"
             autoplay="autoplay"
             playsinline="playsinline"
             disablePictureInPicture
             controlsList="nodownload"
             poster="/branding/bigloader-image.svg"
             :class="{'card-image-cover': cover}">
        <source :src="fullAsset" :type="videoMimeType">
        <source :src="hlsStream" type="application/x-mpegURL">
        <p>
          Sorry your browser doesn't seem to like playing this type of media.
        </p>
      </video>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import _get from 'lodash/get';

export default {
  name: 'VideoJsPlayer',
  props: ['videoMimeType', 'editionNumber', 'cover', 'fullAsset', 'cardAsset', 'metadata'],
  data() {
    return {
      coverImageLoadError: false,
      hlsStream: null,
      loaded: false
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId'])
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
    }
  },
  async mounted() {
    if (!this.cover && !this.loaded) {
      this.loaded = true;

      const {location, mimeType} = await this.videoStream();

      // Create a play list of the HLS stream and then fallback to the normal full video
      const sources = [
        {
          src: location,
          type: mimeType
        },
        {
          src: this.fullAsset,
          type: this.videoMimeType
        }
      ];

      const options = {
        autoplay: true,
        controls: true,
        loop: true,
        muted: true,
        loadingSpinner: false,

        // Load only the meta data of the video, which includes information like the duration and dimensions of the video.
        // Sometimes, the meta data will be loaded by downloading a few frames of video.
        preload: 'metadata',

        // Setting this option to true will cause the player to customize itself based on responsive breakpoints (see: breakpoints option).
        responsive: false,

        // When true, the Video.js player will have a fluid size. In other words, it will scale to fit its container.
        fluid: true,

        // HLS/streaming config tweaks
        html5: {
          vhs: {
            enableLowInitialPlaylist: true,
            fastQualityChange: true,
            overrideNative: !_get(this.$videojs, 'browser.IS_ANY_SAFARI', false)
          }
        },

        timeout: Infinity,

        poster: this.cardAsset,
        sources,
        userActions: {
          doubleClick (_) {
            this.paused() ? this.play() : this.pause();
          },
          hotkeys (event) {
            // `space bar`
            if (event.which === 27) {
              this.paused() ? this.play() : this.pause();
            }
          }
        },
        controlBar: {
          playToggle: true,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: true, // show timers
          playbackRateMenuButton: false,
          pictureInPictureToggle: false,
          fullscreenToggle: true,
          progressControl: {
            seekBar: false
          }
        }
      };

      this.player = this.$videojs(this.$refs.videoPlayer, options, () => {
        console.log('Video loaded', options);
      });

      // Disable progress at the start
      this.player.controlBar.progressControl.disable();

      this.player.on('canplay', (_) => {
        // assume autoplay has not worked (mainly ios) and try to play it
        if (this.player.paused()) {
          this.player.play();
        }
      });

      this.player.ready(() => {
        // assume autoplay has not worked (mainly ios) and try to play it
        if (this.player.paused()) {
          this.player.play();
        }
      });

      this.player.on('error', function (error) {
        console.error('Unable to play video', error);
      });

      // Disable right click context menu
      this.player.on('contextmenu', function (e) {
        e.preventDefault();
      });
    }
  },
  methods: {
    onCoverImageLoadError() {
      console.info('Cover image failed to load, falling back to video cover');
      this.coverImageLoadError = true;
    },
    async videoStream() {
      // Order of attempts
      //    1. HLS stream
      //    2. Full CDN asset
      //    3. Raw IPFS asset
      try {
        const {data} = await this.$cdnApi.get(`/cdn/video/network/${this.chainId}/edition/${this.editionNumber}/stream?noDownload=true`);
        console.log('Transcoded video found', data);
        this.hlsStream = data.location;
        return {
          location: this.hlsStream,
          mimeType: 'application/x-mpegURL'
        };
      } catch (e) {
        try {
          console.log('Falling back to full asset during video transcoding', this.fullAsset);
          const {data} = await this.$axios.get(`${this.fullAsset}?noDownload=true`);
          return {
            location: data.location,
            mimeType: this.videoMimeType
          };
        } catch (e) {
          console.log('Falling back to raw IPFS metadata', this.metadata);
          return {
            location: ipfsUtils.extractFullIpfsPathFromUri(this.metadata.animation_url || ''),
            mimeType: this.videoMimeType
          };
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/colours";

/*Disable initial play button*/
.video-js .vjs-big-play-button {
  height: 0;
  width: 0;
  display: none !important;
  background-color: #fff !important;
}

/* We do this to remove the white default browser background when loading the player */
.forced-white {
  background-color: #fff !important;
}

/* Remove white when in fullscreen mode */
.vjs-fullscreen.forced-white {
  background-color: #000000 !important;
}

.vjs-poster {
  background-size: cover !important;
  background-position: inherit;
  /*Default all backgrounds to white */
  background-color: #fff !important;
}

/* Tweaking what timers are show */
.video-js .vjs-time-control {
  display: block;
}

.video-js .vjs-remaining-time {
  display: none;
}

/* Ensure no double controls are shown on iOS/Safari */

video::-webkit-media-controls {
  display: none !important;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-panel,
*::-webkit-media-controls-panel-container,
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

</style>
