import Cookies from 'js-cookie';
import {ethers} from 'ethers';
import {kodaV3, kodaV2, kodaV1} from 'koda-contract-tools';
import {SiweMessage, SignatureType} from 'siwe';
import notifyService from '../services/NotifyService';
import {onboard} from '../services/onboard.service';

const AUTH_KEY = 'koda-auth-token';
const CACHE_WALLET_SELECTION = 'connectedWallets';

const safeValueToWei = async (web3, account) => {
  try {
    // We need to try / catch here due to an issue with Fortmatic not returning a value wei number for eth_getBalance
    const value = await web3.getBalance(account);
    if (!ethers.BigNumber.isBigNumber(value)) {
      return ethers.utils.parseEther(value);
    }
    return value;
  } catch (e) {
    return '0';
  }
};

export const state = () => ({
  unsubscribeFunc: null,
  web3: null,
  account: null,
  accountBalance: null,
  networkName: null,
  walletProviderName: null,
  authToken: null,
  chainId: process.env.DEFAULT_CHAIN_ID || 1,
  chainError: false,
  gqlClient: process.env.DEFAULT_GQL_CLIENT || 'defaultClient',
  notifyService: null,
  signingNonce: null
});

export const mutations = {
  storeWeb3Provider(state, {web3Provider, networkName, walletProviderName}) {
    state.web3 = web3Provider;
    state.networkName = networkName;
    state.walletProviderName = walletProviderName;
  },
  storeWalletUnsubscribe(state, unsubscribeFunc) {
    state.unsubscribeFunc = unsubscribeFunc;
  },
  addressChanged(state, {account}) {
    if (state.account && state.account !== account) {
      this.dispatch('web3Store/logoutWeb3');
    }
    state.account = account;
  },
  balanceChanged(state, {balance}) {
    state.accountBalance = balance;
  },
  connectedToWeb3(state, {account, chainId, walletProviderName}) {
    if (parseInt(chainId) !== parseInt(process.env.DEFAULT_CHAIN_ID)) {
      state.chainError = true;
      onboard.setChain({ chainId: '0x' + process.env.DEFAULT_CHAIN_ID })
      return
    } else {
      state.chainError = false
    }
    state.account = account;
    state.walletProviderName = walletProviderName;
    state.chainId = parseInt(chainId);
    state.notifyService = notifyService(chainId);

    // Ensure client is flipped when moving networks...
    if (parseInt(chainId) === 5) {
      state.gqlClient = 'goerli';
    } else {
      state.gqlClient = 'defaultClient';
    }
  },
  clearWeb3(state) {
    state.web3 = null;
    if (state.unsubscribeFunc) {
      try {
        state.unsubscribeFunc();
      } catch (e) {
        console.log('Unable to unsubscribe from onboard-js');
      }
    }
    localStorage.removeItem(CACHE_WALLET_SELECTION);
  },
  clearAuthToken(state) {
    state.authToken = null;
    delete this.$api.defaults.headers.common.Authorization;
    Cookies.remove(AUTH_KEY);
  },
  setAuthToken(state, token) {
    state.authToken = token;
    this.$api.defaults.headers.common.Authorization = `Bearer ${token}`;
    Cookies.set(AUTH_KEY, token, { expires: 1, sameSite: 'strict', secure: true });
  },
  setSigningNonce(state, nonce) {
    state.signingNonce = nonce;
  }
};

export const getters = {
  isWeb3Connected: state => state.web3 !== null && state.account !== null,
  isConnectedToMainnet: state => state.chainId === 1,
  getChainId: state => state.chainId,
  etherscanTxLink: state => (hash) => {
    if (state.chainId === 5) {
      return `https://goerli.etherscan.io/tx/${hash}`;
    }
    return `https://etherscan.io/tx/${hash}`;
  },
  etherscanAddressLink: state => (address) => {
    if (state.chainId === 5) {
      return `https://goerli.etherscan.io/address/${address}`;
    }
    return `https://etherscan.io/address/${address}`;
  },
  openSeaTokenLink: (state, getters) => (address, tokenId) => {
    return getters.isConnectedToMainnet
      ? `https://opensea.io/assets/ethereum/${address}/${tokenId}`
      : `https://testnets.opensea.io/assets/goerli/${address}/${tokenId}`;
  },
  knownOriginTokenLink: state => (token_id, type) => {
    if (state.chainId === 5) {
      return `https://goerli.knownorigin.io/${type || 'tokens'}/${token_id}`;
    }
    return `https://knownorigin.io/${type || 'tokens'}/${token_id}`;
  },
  isKOCollection: state => (address) => {
    return kodaV3.isValidAddress(state.chainId, address) || kodaV2.isValidAddress(state.chainId, address) || kodaV1.isValidAddress(state.chainId, address);
  },
  isLoggedInAccount: state => address => (state.account && address) && (state.account.toLowerCase() === address.toLowerCase()),
  hasAuthToken: state => state.authToken !== null,
  getAuthToken: () => () => Cookies.get(AUTH_KEY),
  signingNonce: state => state.signingNonce
};

export const actions = {

  async getAccountBalance({state, getters}) {
    if (state.accountBalance) {
      return state.accountBalance;
    }
    if (getters.isWeb3Connected) {
      return await safeValueToWei(state.web3, state.account);
    }
    return Promise.resolve('0');
  },

  async validateAuthToken({state, commit, getters, dispatch}) {
    try {
      let existingAuthToken = getters.getAuthToken();
      if (!existingAuthToken) {
        existingAuthToken = 'null';
        console.warn('Auth JWT token not found');
      }

      // IMPORTANT: Ensure user can proceed on KO
      const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
      if (!canProceed) {
        console.error('Unable to validate is user can proceed on KO');
        commit('clearAuthToken');
        return Promise.resolve(false);
      }

      this.$api.defaults.headers.common.Authorization = `Bearer ${existingAuthToken}`;

      // always call /validate as it will generate a nonce for signing if there is no valid token
      let data
      try {
        data = await this.$api.$get(`/network/${state.chainId}/accounts/${state.account}/validate`, {
          headers: {
            Authorization: `Bearer ${existingAuthToken}`
          }
        });
      } catch (e) {
        console.error('Unable to validate JWT');
        return Promise.resolve(false);
      }

      if (!data && !data.token && !data.nonce) {
        console.error('Invalid data response');
        return Promise.resolve(false);
      }

      // if we have a token, store it, otherwise store the nonce
      if (data.token) {
        // Refresh the latest JWT token
        commit('setAuthToken', data.token);
        dispatch('analytics/intercomStore/initFields', {token: data.token, hash: data.intercomHash}, {root: true});
        return Promise.resolve(true);
      }

      console.log('Please sign in');
      commit('setSigningNonce', data.nonce);
    } catch (error) {
      console.log('Failed to validate auth token', error);
    }

    commit('clearAuthToken');
    return Promise.resolve(false);
  },

  async generateNewAuthToken({state, commit, getters, dispatch}) {
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      console.log('Unable to determine is account can proceed in KO');
      return false;
    }

    const message = new SiweMessage({
      domain: document.location.host,
      address: ethers.utils.getAddress(state.account),
      chainId: `${state.chainId}`,
      uri: document.location.origin,
      version: '1',
      statement: 'Sign into KnownOrigin',
      type: SignatureType.PERSONAL_SIGNATURE,
      nonce: getters.signingNonce
    });

    // N.B: we had to manually make the rpc call as trust/coinbase wallets wasnt working
    message.signature = await new Promise((resolve, reject) => {
      state.web3.provider.request({
        method: 'personal_sign',
        params: [ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message.prepareMessage())), state.account]
      })
        .then(resolve)
        .catch(reject);
    });

    const apiResult = await this.$api({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data: JSON.stringify({address: state.account, message}),
      url: `/network/${state.chainId}/accounts/${state.account}/auth`
    });

    commit('setAuthToken', apiResult.data.token);

    dispatch('analytics/intercomStore/initFields', {token: apiResult.data.token, hash: apiResult.data.intercomHash}, {root: true});
  },

  async createJwtSession({dispatch, getters}) {
    console.log('Generating JWT token');
    if (!getters.isWeb3Connected) {
      console.log('Skipping auth token generation as web3 not connected');
      return Promise.resolve(false);
    }
    try {
      const isAuthenticated = await dispatch('validateAuthToken');
      console.log('User has valid token', isAuthenticated);
      if (!isAuthenticated) {
        await dispatch('generateNewAuthToken');
      }
      return Promise.resolve(true);
    } catch (e) {
      console.log('Failed to generate auth token', e);
      return Promise.resolve(false);
    }
  },

  async logoutWeb3({commit, dispatch}) {
    // disconnect the first wallet in the wallets array
    const [primaryWallet] = onboard.state.get().wallets;
    if (primaryWallet) {
      await onboard.disconnectWallet({label: primaryWallet.label});
    }
    commit('clearWeb3');
    commit('clearAuthToken');
    dispatch('analytics/amplitudeStore/clearUserToken', {}, {root: true});
    dispatch('analytics/intercomStore/clearUserDetails', {}, {root: true});
    dispatch('analytics/algoliaInsightsStore/clearUserToken', {}, {root: true});
    window.location.reload();
  },

  connectToCachedProvider({dispatch}) {
    // get the selectedWallet value from local storage
    const previouslySelectedWallet = window.localStorage.getItem(CACHE_WALLET_SELECTION);
    console.log('previouslySelectedWallet', previouslySelectedWallet);

    // call wallet select with that value if it exists
    if (previouslySelectedWallet && previouslySelectedWallet.includes('MetaMask')) {
      console.log(`Cache provider found [${previouslySelectedWallet}] - trigger wallet connect`);
      dispatch('connectToWeb3');
    }
  },

  async connectToWeb3({dispatch, commit, state}) {
    console.log('connectToWeb3() called - process.client', process.client);

    if (!process.client) {
      console.error('connectToWeb3() called by mistake, not on the client yet');
      return;
    }

    try {
      let loggedInAccount;
      // Subscribe to wallet connection events
      const {unsubscribeWallet} = onboard.state.select('wallets')
        .subscribe(async (wallets) => {
          console.log('wallets update: ', wallets);
          if (wallets && wallets.length > 0) {
            const web3Provider = new ethers.providers.Web3Provider(wallets[0].provider);
            const {name} = await web3Provider.getNetwork();

            // bootstrap provider
            commit('storeWeb3Provider', {
              web3Provider,
              networkName: name,
              walletProviderName: wallets[0].label
            });

            // Setup contracts
            dispatch('web3ActionsStore/initContracts', {}, {root: true});

            // Update account once found
            loggedInAccount = wallets[0].accounts[0].address;
            console.log('Wallet address found', loggedInAccount);
            commit('addressChanged', {account: loggedInAccount});
            commit('balanceChanged', {balance: await safeValueToWei(state.web3, loggedInAccount)});

            // Setup stored web3 provider and account data
            commit('connectedToWeb3', {
              account: loggedInAccount,
              chainId: ethers.BigNumber.from(wallets[0].chains[0].id).toString(),
              walletProviderName: wallets[0].label
            });

            // store the selected wallet name to be retrieved next time the app loads
            window.localStorage.setItem(CACHE_WALLET_SELECTION, JSON.stringify(wallets.map(({label}) => label)));
          }
        });

      commit('storeWalletUnsubscribe', unsubscribeWallet);

      const cachedWallets = window.localStorage.getItem(CACHE_WALLET_SELECTION);
      // Dont auto populate for in browser wallets due to poor UX if done by accident
      if (cachedWallets && cachedWallets.includes('MetaMask')) {
        const previouslyConnectedWallets = JSON.parse(cachedWallets);
        const wallets = await onboard.connectWallet({
          autoSelect: {label: previouslyConnectedWallets[0], disableModals: true}
        });
        console.log('Auto connected to wallets', wallets);
      } else {
        const wallets = await onboard.connectWallet();
        console.log('Connected to wallets', wallets);
      }

      const currentState = onboard.state.get();
      console.log('Current wallet state', currentState);

      const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true})
      if (!canProceed) {
        await dispatch('web3Store/logoutWeb3', '', { root: true });
        return;
      }

      // If we have a wallet generate an auth token
      if (currentState.wallets && currentState.wallets.length > 0) {
        dispatch('analytics/algoliaInsightsStore/setUserToken', {}, {root: true});
        dispatch('analytics/amplitudeStore/setUserToken', {}, {root: true});

        // Force switch to a specific chain if a wallet is connected
        const success = await onboard.setChain({chainId: ethers.utils.hexValue(parseInt(state.chainId))});
        console.log(`Chain switched to [${state.chainId}]`, success);

        this.$gtm.push({
          event: 'login',
          userId: loggedInAccount
        });
      }
    } catch (e) {
      console.log('Could not get a wallet connection', e);
    }
  }
};
