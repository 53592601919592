<template>
  <div>
    <p class="title">
      {{ isBidderLoggedInUser ? 'Your bid is the highest' : 'Highest bid' }}
    </p>
    <p>
      <eth-with-fiat-price
        size="is-large"
        show-fiat="true"
        :price-in-wei="weiValue">
      </eth-with-fiat-price>
    </p>
    <p class="title">
      Placed by
    </p>
    <p>
      <user-profile-lookup
        :artist-account="bidderAddress"
        :metadata-artist="bidderAddress">
      </user-profile-lookup>
    </p>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import UserProfileLookup from '../UserProfileLookup';

export default {
  components: {UserProfileLookup, EthWithFiatPrice},
  props: ['bidderAddress', 'weiValue'],
  computed: {
    ...mapState('web3Store', ['account']),
    isBidderLoggedInUser() {
      if (!this.account || !this.bidderAddress) {
        return false;
      }
      return this.account.toLowerCase() === this.bidderAddress.toLowerCase();
    }
  }
};
</script>
<style>
</style>
