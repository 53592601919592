var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addresses
    ? _c("div", { staticClass: "columns has-padding-left-3" }, [
        _c("div", { staticClass: "column is-vcentered" }, [
          _c("div", { staticClass: "level is-mobile" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _vm._l(_vm.addresses.slice(0, 5), function (address) {
                  return _c(
                    "div",
                    {
                      key: address.address,
                      staticClass: "level-item is-clickable",
                      staticStyle: { "margin-left": "-2.5rem" },
                    },
                    [
                      _c("account-image", {
                        attrs: {
                          address: address.address,
                          size: "is-64x64",
                          "no-height-restrict": true,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.addresses.length > 5
                  ? _c(
                      "div",
                      {
                        staticClass: "level-item",
                        staticStyle: { "margin-left": "-2.5rem" },
                      },
                      [_vm._m(0)]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "level-right level-is-shrinkable" }, [
              _c("div", { staticClass: "level-item" }, [
                _c("div", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addresses.length) +
                      " eligible\n            "
                  ),
                  _c("div", [
                    _c("div", { staticClass: "hor" }, [
                      _c(
                        "a",
                        {
                          staticClass: "is-clickable is-underlined",
                          on: { click: _vm.viewAccessList },
                        },
                        [
                          _vm._v(
                            "\n                  See all\n                "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("figure", { staticClass: "image is-64x64" }, [
      _c("img", {
        staticClass: "is-rounded",
        attrs: { src: "/user/profile_circle.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }