var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", [
    _c("section", { staticStyle: { "min-height": "600px" } }, [_c("nuxt")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }