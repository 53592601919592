var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content box-fat" }, [
    _c(
      "figure",
      {
        staticClass: "avatar",
        staticStyle: {
          "background-size": "cover",
          "border-radius": "0.5rem 0.5rem 0 0",
        },
        style: { "background-image": "url(" + _vm.coverImage + ")" },
      },
      [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: {
                src: `${_vm.$userMediaApi.defaults.baseURL}/media/user/profile/${_vm.userAddress}`,
                error: _vm.createAccountIcon(),
              },
              expression:
                "{\n            src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${userAddress}`,\n            error: createAccountIcon()\n        }",
            },
          ],
          staticClass: "is-rounded",
        }),
      ]
    ),
    _vm._v(" "),
    _vm.profile
      ? _c("section", { staticClass: "content is-centered px-3" }, [
          _c(
            "div",
            { staticClass: "has-text-centered is-size-4" },
            [
              _c("span", { staticClass: "is-family-secondary" }, [
                _vm._v(_vm._s(_vm.nameOrShortEth)),
              ]),
              _vm._v(" "),
              _vm.profile &&
              _vm.isTwitterVerified(
                _vm.userAddress.toLowerCase(),
                _vm.profile.twitter
              )
                ? _c("b-icon", {
                    staticClass: "ml-1 is-size-5 is-size-6-mobile is-verified",
                    attrs: { icon: "check-decagram", size: "is-small" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "has-text-centered mt-3" },
            [
              _c("b-tag", { staticClass: "is-family-primary is-uppercase" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.profile.isArtist ? "ARTIST" : "COLLECTOR") +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.profile
      ? _c(
          "section",
          {
            staticClass: "content pl-4 pr-4",
            staticStyle: { height: "55px", overflow: "hidden" },
          },
          [
            _c("p", { staticClass: "has-text-centered" }, [
              _vm._v("\n      " + _vm._s(_vm.profile.bio || "") + "\n    "),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.profile
      ? _c("section", { staticClass: "content px-5 mb-2" }, [
          _c("div", { staticClass: "level is-mobile" }, [
            _vm.profile.twitter
              ? _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "twitter", size: "is-small" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `https://twitter.com/${_vm.cleanUpUrl(
                            _vm.profile.twitter
                          )}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c("span", { staticClass: "ml-1" }, [
                          _vm._v(
                            "@" + _vm._s(_vm.cleanUpUrl(_vm.profile.twitter))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.profile.instagram
              ? _c(
                  "div",
                  { staticClass: "level-left" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "instagram", size: "is-small" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `https://www.instagram.com/${_vm.cleanUpUrl(
                            _vm.profile.instagram
                          )}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c("span", { staticClass: "ml-1" }, [
                          _vm._v(
                            "@" + _vm._s(_vm.cleanUpUrl(_vm.profile.instagram))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }