<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Delete / Burn
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <section v-if="canPartialBurn">
        <p class="has-text-weight-semibold">
          Unsold tokens will be sent to the
          <a href="https://etherscan.io/address/0x000000000000000000000000000000000000dead"
             class="is-linkable">0xdead</a> address
        </p>
        <section v-if="edition.remainingSupply > 1">
          <b-message type="is-info">By default all remaining tokens in edition will be
          burnt. If you wish to burn a limited number of tokens you can do so by selecting the partial burn option below</b-message>
          <br />
          <b-switch v-model="editionRangeSelected">
            Partial burn
          </b-switch>
        </section>
        <b-field v-if="editionRangeSelected" label="Number of tokens">
          <b-input v-model="editionRange"
                   placeholder="1"
                   min="1"
                   :max="edition.remainingSupply"
                   type="number"
                   autofocus>
          </b-input>
        </b-field>
      </section>
      <section>
        <br />
        <p>
          Once the transaction has been confirmed, the artworks will be removed from your profile, this can take a few
          minutes.
        </p>
        <div v-if="edition.isEnhancedEdition">
        <b-message title="WARNING ABOUT BURNING EMBEDDED TOKENS" type="is-danger" has-icon :closable="false">
          If you burn an embedded token you will lose access to the token embedded within <strong>FOREVER</strong> please only burn if you are happy with this.
        </b-message>
        </div>
      </section>
    </div>

    <template slot="action-feedback-label">
      Burning
    </template>

    <template slot="content-balance">&nbsp;</template>

    <template slot="action-button">
      <b-button type="is-danger"
                expanded
                :disabled="isDisabled"
                @click="burn">
        Delete / Burn
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';

export default {
  components: {
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition'],
  data() {
    return {
      transactionHash: null,
      editionRange: null,
      editionRangeSelected: false
    };
  },
  computed: {
      isDisabled() {
        if (this.transactionHash) {
          return true;
        }
        return this.editionRangeSelected
        ? !(parseInt(this.editionRange) >= 1 && parseInt(this.editionRange) <= parseInt(this.edition.remainingSupply))
        : false
      },
      canPartialBurn() {
        if (this.edition.version === '3') {
          return true;
        }
        if (this.edition.version === '4' && !this.edition.isOpenEdition) {
          return true;
        }
        return false;
      }
    },
  methods: {
    close() {
      this.$emit('close');
    },
    setEditionRange () {
      this.editionRangeSelected = false;
    },
    async burn() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/burn', {
          edition: this.edition.id,
          version: this.edition.version,
          editionRange: this.editionRange
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
