var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "hero" }, [
    _c("div", { staticClass: "hero-body" }, [
      _c(
        "div",
        { staticClass: "container content has-text-centered" },
        [
          _c(
            "h1",
            {
              staticClass: "title mt-1 mb-3 pl-2",
              attrs: { "data-testid": "pageTitle" },
            },
            [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
          ),
          _vm._v(" "),
          _vm._t("subtitle", null, { dataTestid: "pageSubtitle" }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }