var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.profile && _vm.profile.username
    ? _c("span", { staticClass: "is-family-secondary" }, [
        _vm._v(
          "\n  " +
            _vm._s(_vm.profile.username || _vm.profile.address | _vm.shortEth) +
            "\n"
        ),
      ])
    : _vm.profile && _vm.profile.address
    ? _c("span", { staticClass: "is-family-secondary" }, [
        _vm._v(_vm._s(_vm._f("shortEth")(_vm.profile.address))),
      ])
    : _c("span", { staticClass: "is-family-secondary" }, [
        _vm._v(_vm._s(_vm._f("shortEth")(_vm.ethAccount))),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }