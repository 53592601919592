import {ethers} from 'ethers';
/* eslint-disable-next-line lodash/import-scope */
import _ from 'lodash';
import moment from 'moment';
import {isReserveAuctionSalesType} from './SaleTypes';

const KO_ADMIN = '0x3f8C962eb167aD2f80C72b5F933511CcDF0719D4';
const KO_DEPLOYER = '0x48CeF95fd927FDD5b17fDCBf2aF8e82F4c064077';

export function reserveAuctionCountdownStarted(listing) {
  return listing &&
    listing.reserveAuctionEndTimestamp &&
    parseInt(listing.reserveAuctionEndTimestamp) > 0;
}

export function reserveAuctionCountdownInProgress(listing) {
  return listing && reserveAuctionCountdownStarted(listing) && !reserveAuctionCountdownComplete(listing);
}

export function reserveAuctionCountdownComplete(listing) {
  return listing &&
    reserveAuctionCountdownStarted(listing) &&
    parseInt(listing.reserveAuctionEndTimestamp) < moment.utc().unix();
}

export function hasValidEditionOffer(edition, editionOffers, minBid) {
  const hasOpenOffer = editionOffers && editionOffers.length === 1;
  if (!hasOpenOffer) {
    return false;
  }

  // onchain reserve - check reserve meet
  if (isReserveAuctionSalesType(edition.salesType)) {
    const onchainReserve = parseFloat(ethers.utils.formatEther(_.get(edition, 'reservePrice', 0)));
    const currentOfferInEth = parseFloat(ethers.utils.formatEther(editionOffers[0].weiValue));
    return currentOfferInEth >= onchainReserve;
  }

  // offers only with minimum bid
  return minBid &&
    ethers.utils.formatEther(minBid.eth_reserve_in_wei || 0) <= ethers.utils.formatEther(editionOffers[0].weiValue);
}

export function canResultEditionReserveAuction(edition, loggedInUser) {
  const artist = edition.artistAccount;
  const bidder = edition.activeBid.bidder;
  return [artist, bidder, KO_ADMIN, KO_DEPLOYER].map(a => a.toLowerCase()).includes(loggedInUser?.toLowerCase());
}

export function canResultTokenReserveAuction(seller, buyer, loggedInUser) {
  return [seller, buyer, KO_ADMIN, KO_DEPLOYER].map(a => a.toLowerCase()).includes(loggedInUser?.toLowerCase());
}

export function isKoResulter(loggedInUser) {
  return [KO_ADMIN, KO_DEPLOYER].map(a => a.toLowerCase()).includes((loggedInUser || '').toLowerCase());
}

export default {
  reserveAuctionCountdownStarted,
  reserveAuctionCountdownInProgress,
  reserveAuctionCountdownComplete,
  hasValidEditionOffer,
  canResultEditionReserveAuction,
  canResultTokenReserveAuction,
  isKoResulter
};
