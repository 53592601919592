import gql from 'graphql-tag';

export const GET_EDITION_IDS_FOR_CONTRACT = gql(`
  query editionIds($id: String!) {
    editionIds: creatorContract(id: $id subgraphError: allow) {
      editions (orderBy: createdTimestamp, orderDirection: desc, where: {active: true}) {
        id
      }
    }
  }
`)

export const GET_CONTRACTS_BY_ARTIST = gql(`
  query contracts($id: String!) {
    contracts: creatorContracts(
      orderBy: timestamp
      orderDirection:asc
      subgraphError: allow
      where:{
        creator: $id
        isHidden: false
        transferState_not: "1"
        }){
          id
          name
          symbol
          creator
          filterRegistry
          transferState
          totalNumOfEditions
          }
  }
`)

export const GET_LATEST_CONTRACTS = gql(`
  query contracts($first: Int!) {
    trendingContracts: creatorContracts(
      first: $first
      orderBy: timestamp
      orderDirection: desc
      subgraphError: allow
      where:{
        isHidden: false
        transferState_not: "1"
        }){
          id
          name
          symbol
          creator
          }
  }
`)

export const GET_MOST_SOLD_CONTRACTS = gql(`
  query contracts($first: Int!) {
    trendingContracts: creatorContracts(
      first: $first
      orderBy: totalNumOfTokensSold
      orderDirection: desc
      subgraphError: allow
      where:{
        isHidden: false
        id_not: "0x000000000000000000000000000000000000dead"
        transferState_not: "1"
        }){
          id
          name
          symbol
          creator
          }
  }
`)

export const GET_MOST_ETH_SOLD_CONTRACTS = gql(`
  query contracts($first: Int!) {
    trendingContracts: creatorContracts(
      first: $first
      orderBy: totalEthValueOfSales
      orderDirection: desc
      subgraphError: allow
      where:{
        isHidden: false
        id_not: "0x000000000000000000000000000000000000dead"
        transferState_not: "1"
        }){
          id
          name
          symbol
          creator
          }
  }
`)

export const CONTRACT_STATS = gql(`
  query contractStats($id: String!) {
    contractStats: creatorContract(id: $id subgraphError: allow){
      totalNumOfEditions
      totalNumOfTokensSold
      totalNumOfTransfers
      timestamp
      totalEthValueOfSales
      secondaryRoyaltyPercentage
    }
  }
`)

export const CONTRACT_EDITIONS = gql(`
  query contractStats($id: String!) {
    contractStats: creatorContract(id: $id subgraphError: allow){
      totalNumOfEditions
    }
  }
`)

export const CONTRACT_BLOCKED_AND_TRANSFER_STATE = gql(`
  query contracts($id: String!) {
  contracts: creatorContracts(
    orderBy: timestamp
    orderDirection:asc
    subgraphError: allow
    where:{
      id: $id
      }){
        isHidden
        transferState
        }
}
`)
