<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">
    <template slot="content-title">
      <div>
        Buy now for
        <eth-with-fiat-price :price-in-wei="totalPrice" />
      </div>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id"></artwork-name-and-artist>
    </template>

    <template slot="total-picker">
      <div v-if="!fetchesComplete">
        <b-skeleton width="20%" :animated="true"></b-skeleton>
        <b-skeleton width="40%" :animated="true"></b-skeleton>
      </div>
      <div v-else>
        <div v-if="maxMints > 0">
          <b-field>
            <b-numberinput
              type="is-light"
              v-model="mintTotal"
              min="1"
              :max="maxMints"
            ></b-numberinput>
          </b-field>
        </div>
        <b-message
          v-else
          size="is-small"
          class="is-danger"
        >
          You have exceeded your total amount of mints for this phase
        </b-message>
      </div>
    </template>

    <template slot="content-body">
      <span v-if="maxMints > 0" class="p-3">
        <b-message class="is-info">
          <p>You have minted {{ userTotalMints }} pieces</p>
          <p>You have {{ maxMints }} pieces still available to mint</p>
        </b-message>
      </span>
    </template>

    <template slot="action-feedback-label">
      Buying
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                @click="buyNow"
                :disabled="disableBuyButton"
      >
        Buy now
      </b-button>
    </template>
  </modal-template>
</template>
<script>
import {ethers} from 'ethers';
import {mapState} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import EthWithFiatPrice from '../../common/EthWithFiatPrice';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {GET_PHASE_CAP_REMAINING_ALLOWANCE, GET_USER_MINT_TOTAL} from '../../../queries/gatedQueries';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';

export default {
  components: {
    ModalTemplate,
    EthWithFiatPrice,
    ArtworkNameAndArtist
  },
  props: ['edition', 'phase', 'proofs', 'algoliaInfo'],
  data() {
    return {
      transactionHash: null,
      mintTotal: 1,
      userTotalMints: 0,
      phaseRemainingAllowance: 0,
      userFetchComplete: false,
      phaseFetchComplete: false
    }
  },
  computed: {
    ...mapState('web3Store', [
      'gqlClient',
      'account',
      'chainId'
    ]),
    totalPrice() {
      return ethers.BigNumber.from(this.phase.priceInWei).mul(ethers.BigNumber.from(this.mintTotal)).toString();
    },
    maxMints() {
      return Math.min(this.phase.walletMintLimit - this.userTotalMints, this.phaseRemainingAllowance)
    },
    fetchesComplete() {
      return this.userFetchComplete && this.phaseFetchComplete
    },
    disableBuyButton() {
      return !this.fetchesComplete || this.maxMints === 0 || this.transactionHash != null;
    }
  },
  async mounted() {
    // Check how many mints the user has minted already
    await this.$apollo.addSmartQuery('phaseMintCount', {
      client: this.gqlClient,
      query: GET_USER_MINT_TOTAL,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          account: this.account,
          phaseId: this.phase.phaseId,
          saleId: this.phase.saleId
        };
      },
      result({data}) {
        const {phaseMintCount} = data;
        if (phaseMintCount && phaseMintCount[0]) {
          this.userTotalMints = phaseMintCount[0].count
        }

        this.userFetchComplete = true
      },
      error(error) {
        console.log('error', error);
      }
    })

    // Check how many mints are left in the phase cap
    await this.$apollo.addSmartQuery('phaseCapCheck', {
      client: this.gqlClient,
      query: GET_PHASE_CAP_REMAINING_ALLOWANCE,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          phaseId: this.phase.phaseId,
          saleId: this.phase.saleId
        };
      },
      result({data}) {
        const {phaseCapCheck} = data;
        if (phaseCapCheck && phaseCapCheck[0]) {
          this.phaseRemainingAllowance = +phaseCapCheck[0].mintCap - +phaseCapCheck[0].mintCount
        }

        this.phaseFetchComplete = true
      },
      error(error) {
        console.log('error', error);
      }
    })
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async buyNow() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/purchaseGated', {
          phase: this.phase,
          proofs: this.proofs,
          mintCount: this.mintTotal,
          priceInWei: this.totalPrice,
          collaborators: this.edition.collaborators
        });

        this.transactionHash = tx.hash;

      await tx.wait(1);

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.$options.filters.toEth(this.phase.priceInWei),
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'early access presale',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.phase.startTime,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: this.mintTotal,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.$options.filters.toEth(this.totalPrice)),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: this.mintTotal,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.txHash]: tx.hash,
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'early access presale',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary'
            }
          }
        });

        await this.$store.dispatch('userStore/getContractAddress', {
          chainId: this.chainId,
          version: this.edition.version
        }).then((contractAddress) => {
          if (contractAddress) {
            this.$gtm.push({
            event: 'PurchaseGated',
          value: this.$options.filters.toEth(this.phase.priceInWei),
          editiontitle: this.edition.metadata.name,
            tokenid: this.edition.id,
            artist: this.edition.artistAccount,
            purchasetype: 'BuywithEth',
            marketplace: 'Primary',
            chain: this.chainId,
            contracttype: this.edition.version,
            contractaddress: contractAddress
            })
          }
        })
      this.close();

      return tx;
    } catch (err) {
      console.error(err)

      if (this.algoliaInfo) {
        this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
          [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
          [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseFailed,
          [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
          [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id,
          [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
        });
      }

      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.purchaseFailed,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
          [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
          [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
          [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
          [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'early access presale'
        }
      });
    }
  }
}
}
</script>
