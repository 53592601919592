var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ApolloQuery", {
    attrs: {
      query: require("../queries/editionQueries")
        .EDITION_BY_ID_WITH_TOKENS_QUERY,
      variables: { id: _vm.id },
      "client-id": _vm.gqlClient,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ result: { loading, data } }) {
          return [
            data &&
            data.editionById &&
            data.editionById.length > 0 &&
            data.editionById[0] &&
            data.editionById[0].active &&
            !loading
              ? _c(
                  "div",
                  [
                    _c(
                      "n-link",
                      {
                        attrs: {
                          to: _vm.handleMarketPlaceRouting(data.editionById[0]),
                        },
                      },
                      [
                        _c("search-edition-card-resizeable", {
                          attrs: {
                            "card-size": _vm.cardSize,
                            "algolia-info": _vm.algoliaInfo,
                            edition: data.editionById[0],
                            cover: _vm.cover,
                            resolution: _vm.resolution,
                            size: _vm.size === "is-large" ? "is-large" : null,
                            address: _vm.address,
                            "click-handler": _vm.handleMarketPlaceRouting(
                              data.editionById[0]
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "card mb-4" }, [
                  _c("div", { staticClass: "card-image" }, [
                    _c("img", {
                      class: {
                        "card-image-cover": _vm.cardSize > 2,
                        "skeleton-small": _vm.cardSize === 1,
                        "skeleton-medium": _vm.cardSize === 2,
                      },
                      attrs: { src: "/branding/bigloader-image.svg" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content is-paddingless" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        { staticClass: "columns is-mobile is-multiline" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-9 has-padding-left-2 has-padding-top-2",
                              class: { "has-padding-top-4": _vm.cardSize > 2 },
                            },
                            [_c("b-skeleton", { attrs: { width: "50%" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column has-text-right is-3 is-size-7 has-padding-right-2 has-padding-top-2",
                              class: { "has-padding-top-4": _vm.cardSize > 2 },
                            },
                            [_c("b-skeleton", { attrs: { width: "100%" } })],
                            1
                          ),
                          _vm._v(" "),
                          _vm.cardSize > 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "column is-12 has-padding-left-2",
                                  class: {
                                    "has-padding-bottom-2": _vm.cardSize > 2,
                                    "pt-0": _vm.cardSize < 3,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "columns is-mobile" },
                                    [
                                      _vm.cardSize > 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-one-fifth",
                                            },
                                            [
                                              _c("b-skeleton", {
                                                attrs: {
                                                  circle: "",
                                                  width: "32px",
                                                  height: "32px",
                                                  animated: true,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column",
                                          class: { "pt-1": _vm.cardSize < 3 },
                                        },
                                        [
                                          _c("b-skeleton", {
                                            attrs: { width: "60%" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }