<template>
  <div class="content nft-promo-image is-paddingless is-marginless is-clipped" style="border-radius: 8px">

    <h2 class="has-text-centered is-uppercase is-family-primary has-text-weight-semibold mb-0 mt-5">
      You're killing it
    </h2>

    <p class="title has-text-centered is-uppercase is-family-primary">
      Now claim your Deadfellaz X KO POAP
    </p>

    <p class="title has-text-centered is-uppercase is-family-primary pt-2">
      <img src="/partners/promo/df_logo.png" style="max-width: 150px" />
    </p>

    <p class="has-text-centered pt-2">
      <a href="https://kiosk.poap.xyz/#/event/4nqBGsVUmYuWylMKdGKn" target="_blank" rel="noopener noreferrer">
        <b-button type="is-primary" class="is-expanded has-text-white" @click="state.showPoapCode = true">
          Claim your POAP
        </b-button>
      </a>
    </p>

    <div class="columns is-mobile mt-3">
      <div class="column"></div>
      <div class="column">
        <b-button type="is-dark" class="is-expanded" style="color: #49B373 !important;" @click="gotoPromo">
          View the Draw the Undead 2022 collection
        </b-button>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    close() {
      this.$emit('close');
    },
    gotoPromo() {
      this.$emit('close');
      this.$nuxt.$router.push({name: 'contract-contract', params: {contract: '0x584e6eb4daaaa9553a1078ee325db9671862b214'}});
    }
  }
};
</script>

<style scoped lang="scss">
.nft-promo-image {
  background-image: url("/partners/promo/DF-bg.png"); /* The image used */
  background-color: #49B373; /* Used if the image is unavailable */
  height: 540px; /* You must set a specified height */
  background-position: 0 70px; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
</style>
