<template>
  <b-table :data="salesHistory" v-if="salesHistory && salesHistory.length > 0"
           paginated
           pagination-simple
           :per-page="5"
           default-sort="timestamp"
           default-sort-direction="desc"
  >
    <b-table-column field="artwork" label=" " width="20%" centered v-slot="props">
      <n-link :to="buildLink(props.row.edition.id)">
        <image-asset :edition-number="props.row.edition.id"
                     :metadata="props.row.edition.metadata"
                     :cover="true"
                     resolution="card"
                     class="is-hidden-mobile"
                     v-if="props.row.edition">

        </image-asset>
        <span class="is-hidden-desktop" v-if="props.row">
          <artwork-name-and-artist :metadata="props.row.edition.metadata" :edition-number="props.row.edition.id">
          </artwork-name-and-artist>
        </span>
      </n-link>
    </b-table-column>
    <b-table-column field="type" label="Type" centered sortable v-slot="props">
      <b-tag type="is-dark" v-if="props.row.type === 'EDITION'" class="is-uppercase">Primary</b-tag>
      <b-tag type="is-light" class="is-uppercase" v-else>Secondary</b-tag>
    </b-table-column>
    <b-table-column field="buyer" label="Buyer" centered sortable v-slot="props">
      <profile-name-link :eth-account="props.row.buyer"></profile-name-link>
    </b-table-column>
    <b-table-column field="eventType" label="Event" centered sortable v-slot="props">
      {{ mapEvent(props.row.eventType) }}
    </b-table-column>
    <b-table-column field="eventValueInWei" label="Price" centered sortable v-slot="props">
      <eth-with-fiat-price :price-in-wei="props.row.eventValueInWei" />
    </b-table-column>
    <b-table-column field="timestamp" label="Date" centered sortable numeric v-slot="props">
        <date-format :timestamp-in-secs="props.row.timestamp"></date-format>
      </b-table-column>
  </b-table>
  <div v-else>
    <empty-state message="No sales data"></empty-state>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {GET_ARTIST_SALES_HISTORY} from '../../queries/artistQueries';
import ProfileNameLink from '../avatar/ProfileNameLink';
import {mapEvent} from '../../services/utils';
import ImageAsset from '../asset-renders/ImageAsset';
import DateFormat from '../common/DateFormat';
import EmptyState from '../common/EmptyState';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, ArtworkNameAndArtist, EmptyState, DateFormat, ImageAsset, ProfileNameLink},
  props: ['address'],
  data() {
    return {
      salesHistory: null
    };
  },
  computed: {
    ...mapState('web3Store', [
      'gqlClient'
    ])
  },
  watch: {
    address (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$apollo.queries.salesHistory.refetch();
      }
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('salesHistory', {
      client: this.gqlClient,
      query: GET_ARTIST_SALES_HISTORY,
      variables() {
        return {
          artist: this.address.toLowerCase()
        };
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    mapEvent,
    buildLink(editionId) {
      return {name: 'gallery-id', params: {id: editionId}};
    }
  }
};
</script>
