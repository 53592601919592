<template>
  <section v-if="artistStats">
    <div class="level is-mobile box is-family-tertiary" v-if="parseInt(artistStats.salesCount) > 0">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Sales</p>
          <p class="title">{{ artistStats.salesCount}}</p>
        </div>
      </div>

      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Editions</p>
          <p class="title">{{ artistStats.editionsCount}}</p>
        </div>
      </div>

      <div class="level-item has-text-centered" v-if="!skinny">
        <div>
          <p class="heading">Total</p>
          <p class="title">
            <eth-with-fiat-price :price-in-eth="artistStats.totalValueInEth"></eth-with-fiat-price>
          </p>
        </div>
      </div>
    </div>
    <div class="level is-mobile box is-family-tertiary" v-else-if="parseInt(artistStats.editionsCount) === 1">
      <div class="level-item has-text-centered" v-if="!skinny">
        <div>
          <p class="heading">Status</p>
          <p class="title">Minted Genesis Edition</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Editions</p>
          <p class="title">{{ artistStats.editionsCount}}</p>
        </div>
      </div>
    </div>
    <div class="level is-mobile box is-family-tertiary" v-else-if="parseInt(artistStats.editionsCount) === 0">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Status</p>
          <p class="title">Awaiting Genesis Edition</p>
        </div>
      </div>
    </div>
    <div class="level is-mobile box is-family-tertiary" v-else>
      <div class="level-item has-text-centered" v-if="!skinny">
        <div>
          <p class="heading">Status</p>
          <p class="title">New Artist</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Editions</p>
          <p class="title">{{ artistStats.editionsCount}}</p>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <div class="level is-mobile box is-family-tertiary">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Status</p>
          <p class="title">Awaiting Genesis Edition</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {mapState} from 'vuex';
  import {ARTIST_STATS} from '../queries/artistQueries';
  import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

  export default {
    components: {EthWithFiatPrice},
    props: ['account', 'skinny'],
    data() {
      return {
        artistStats: null
      }
    },
    computed: {
      ...mapState('web3Store', [
        'gqlClient'
      ])
    },
    mounted() {
      this.$apollo.addSmartQuery('artistStats', {
        client: this.gqlClient,
        query: ARTIST_STATS,
        variables() {
          return {
            address: this.account
          };
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
    }
  };
</script>
