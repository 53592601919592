<template>
  <div class="level is-mobile is-vcentered">
    <div class="level-left">
      <div class="level-item" v-if="ethAccount">
        <n-link :to="{name: 'profile-id', params: { id: ethAccount}}">
          <account-image :address="ethAccount"></account-image>
        </n-link>
      </div>
      <div class="level-item">
        <n-link :to="{name: 'profile-id', params: { id: ethAccount}}" :target="target" v-if="!disableLink">
          <span v-if="profile && profile.username" class="is-family-secondary">
            {{ profile.username || (profile.address | shortEth)}}
          </span>
          <span v-else-if="profile && profile.address" class="is-family-secondary">{{ profile.address | shortEth}}</span>
          <span v-else class="is-family-secondary">{{ ethAccount | shortEth}}</span>
        </n-link>
        <div v-else>
          <span v-if="profile && profile.username" class="is-family-secondary">
            {{ profile.username || (profile.address | shortEth)}}
          </span>
          <span v-else-if="profile && profile.address" class="is-family-secondary">{{ profile.address | shortEth}}</span>
          <span v-else class="is-family-secondary">{{ ethAccount | shortEth}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountImage from '../common/AccountImage';
  export default {
    components: {AccountImage},
    props: ['ethAccount', 'target', 'disableLink'],
    data: () => ({
      profile: null
    }),
    watch: {
      async ethAccount(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = await this.$store.dispatch('userStore/getUser', newVal);
        }
      }
    },
    async mounted() {
      if (this.ethAccount) {
        this.profile = await this.$store.dispatch('userStore/getUser', this.ethAccount);
      }
    }
  };
</script>
