<template>
  <span>
    <video ref="videoPlayer"
           :id="`edition_video_${editionNumber}`"
           width="100%" height="100%"
           :autoplay="!cover || !poster"
           :controls="!cover || !poster"
           loop="loop"
           muted="muted"
           playsinline="playsinline"
           disablePictureInPicture
           controlsList="nodownload"
           :poster="cardAsset"
           :class="{'card-image-cover': cover}"
           @error="onVideoError">
      <source :src="fullAsset" :type="videoMimeType">
      <p>
        Sorry your browser doesn't support this type of HTML5 video.
      </p>
    </video>
  </span>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: ['videoMimeType', 'editionNumber', 'cover', 'fullAsset', 'cardAsset'],
  data() {
    return {
      poster: true
    }
  },
  mounted() {
    // if (this.$refs["videoPlayer"]) {
    //   this.$refs["videoPlayer"].addEventListener('contextmenu', this.contextMenuHandler);
    // }
  },
  beforeDestroy() {
    // if (this.$refs["videoPlayer"]) {
    //   this.$refs["videoPlayer"].removeEventListener('contextmenu', this.contextMenuHandler);
    // }
  },
  methods: {
    contextMenuHandler (e) {
      // Disable right click
      e.preventDefault();
      return false;
    },
    onVideoError (error) {
      console.log(error);
      this.poster = false;
      return true;
    }
  }

};
</script>

<style scoped lang="scss">
video::-webkit-media-controls {
  visibility: hidden;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}
</style>
