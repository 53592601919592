<template>
  <div class="container">
    <div class="tile is-ancestor">
      <div class="tile is-parent is-vertical">

        <section class="section">
          <div class="tile is-child">
            <div class="content">
              <h2 class="title is-size-3 is-size-4-mobile">24hr Reserve Auctions</h2>
            </div>
          </div>
          <div class="tile is-child">
            <div class="columns is-multiline">
              <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd asset-card" v-for="edition in displayList"
                   :key="edition.id">
                <div @click="handleCardClickedEvent('24hr Reserve Auctions')">
                <edition-card
                  :edition="edition"
                  :cover="true"
                  resolution="card"
                  class="has-margin-bottom-4"
                  @click.native="handleItemClickInsights('card', edition.id, '24hr reserve auctions', 'token page')"
                ></edition-card>
                </div>
              </div>
            </div>
            <div @click="handleButtonClickedEvent('View all 24hr Reserve Auctions', '24hr Reserve Auctions', 'marketplace')" class="has-text-centered has-margin-bottom-1">
              <n-link :to="{ name: 'marketplace', query: {['sale_type_reserve_countdown_auction']:true}}"
                      class="button is-primary is-outlined"
                      @click.native="handleItemClickInsights('button', '', '24hr reserve auctions', 'marketplace')">
                View all 24hr reserve auctions
              </n-link>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</template>

<script>
import EditionCard from '../EditionCard';
import {RESERVE_AUCTIONS_ENDING_SOON, RESERVE_AUCTIONS_STARTING_SOON} from '../../queries/editionQueries';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  components: {EditionCard},
  props: {
    handleButtonClickedEvent: {type: Function},
    handleCardClickedEvent: {type: Function}
},
  data() {
    return {
      displayList: []
    };
  },
  async mounted() {
    const timestamp = `${Math.floor(new Date().getTime() / 1000)}`;

    this.$apollo.addSmartQuery('reserveAuctionsEndingSoon', {
      client: this.gqlClient,
      query: RESERVE_AUCTIONS_ENDING_SOON,
      variables() {
        return {
          timestamp
        };
      },
      result({data}) {
        let fetched = 0;
        if (data && data.reserveAuctionsEndingSoon) {
          const {reserveAuctionsEndingSoon} = data;
          this.displayList = this.displayList.concat(reserveAuctionsEndingSoon);
          fetched = reserveAuctionsEndingSoon.length;
        }

        if (fetched < 6) {
          const remainder = 6 - fetched;
          this.$apollo.addSmartQuery('reserveAuctionsStartingSoon', {
            client: this.gqlClient,
            query: RESERVE_AUCTIONS_STARTING_SOON,
            variables() {
              return {
                timestamp,
                first: remainder
              };
            },
            result({data}) {
              const {reserveAuctionsStartingSoon} = data;
              this.displayList = this.displayList.concat(reserveAuctionsStartingSoon);
              return reserveAuctionsStartingSoon;
            }
          });
        }

        return this.displayList;
      }
    });
  },
  methods: {
    async handleItemClickInsights(type, item, promoSection, destination) {
      if (type === 'card') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.cardClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.id]: item,
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: promoSection
          }
        });
      } else if (type === 'button') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.buttonClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.id]: item,
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: promoSection,
            [AMPLITUDE_EVENT_PROPERTIES.destination]: destination
          }
        });
      }
    }
  }
};
</script>

<style></style>
