<template>
  <button
    :href="() => createUrlForValue()"
    @click="sendInsightsEvent()"
    @click.prevent="() => refineForValue()"
    class="button is-fullwidth"
    :disabled="checkHasValue()"
    :class="{'is-primary': isRefinedForValue && !checkHasValue(), 'is-light': !isRefinedForValue && !checkHasValue()}"
  >
    {{ label }}
  </button>
</template>

<script>
import _filter from 'lodash/filter';
import {mapAttributeToName, mapSearchButtonToFilter} from '../../services/search'
import {EVENT_NAMES, AMPLITUDE_EVENT_PROPERTIES, ALGOLIA_EVENT_PROPERTIES} from '../../services/analyticsEvents';

export default {
  props: ['createURL', 'refine', 'items', 'value', 'label', 'attribute', 'index', 'sendEvent'],
  computed: {
    filteredItem() {
      const filtered = _filter((this.items || []), item => item.value === this.value);
      return (filtered.length === 1) ? filtered[0] : null;
    },
    isRefinedForValue() {
      return (this.filteredItem) ? this.filteredItem.isRefined : false;
    }
  },
  methods: {
    mapSearchButtonToFilter,
    createUrlForValue() {
      return this.createURL(this.filteredItem);
    },
    refineForValue() {
      return this.refine((this.filteredItem) ? this.filteredItem.value : null);
    },
    checkHasValue() {
      return !this.filteredItem;
    },
    sendInsightsEvent() {
      if (!this.filteredItem.isRefined) {
        this.$store.dispatch('analytics/algoliaInsightsStore/sendClickedFilterEvent', {
          index: this.index,
          [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.filterClicked,
          [ALGOLIA_EVENT_PROPERTIES.filters]: [mapSearchButtonToFilter(this.attribute, this.value)]
        });

        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.filterClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'marketplace',
            [AMPLITUDE_EVENT_PROPERTIES.category]: mapAttributeToName(this.attribute),
            [AMPLITUDE_EVENT_PROPERTIES.subCategory]: mapSearchButtonToFilter(this.attribute, this.value)
          }
        });
      }
    }
  }
};
</script>
