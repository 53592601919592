var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animCols is-flex is-flex-wrap-wrap" },
    _vm._l(_vm.items, function (item, i) {
      return _c(
        "div",
        { key: item + i, staticClass: "animCols__item my-5 p-2" },
        [
          item.image
            ? _c(
                "div",
                {
                  staticClass:
                    "animCols__imgHolder is-flex is-align-items-center is-justify-content-center p-5",
                },
                [
                  item.image === "build-your-brand"
                    ? _c("AnimBuildYourBrand")
                    : _vm._e(),
                  _vm._v(" "),
                  item.image === "sell-across-marketplaces"
                    ? _c("AnimSellAcrossMarketplaces", {
                        class: "sellAcrossMarketplaces--slide" + _vm.slide,
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.image === "collaborate"
                    ? _c("AnimCollabAnim", {
                        class: "collabAnim--slide" + _vm.slide,
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.image === "customised-drops"
                    ? _c("AnimCustomDrop", {
                        class: "customDrops--slide" + _vm.slide,
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          item.title
            ? _c("h4", {
                staticClass:
                  "animCols__title mt-4 is-size-5 has-text-weight-bold is-family-tertiary",
                domProps: { innerHTML: _vm._s(item.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          item.text
            ? _c("p", {
                staticClass: "animCols__text mt-1",
                domProps: { innerHTML: _vm._s(item.text) },
              })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }