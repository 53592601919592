var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-content has-padding-7" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "columns has-margin-7" }, [
            _vm.contractImages
              ? _c("figure", { staticClass: "mb-4 image is-128x128" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: { src: _vm.contractImages.thumbnail },
                        expression: "{ src: contractImages.thumbnail }",
                      },
                    ],
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("section", { staticClass: "has-margin-7 py-7" }, [
              _c("p", { staticClass: "title is-3 mb-1 is-family-secondary" }, [
                _vm._v(
                  "\n                            Burn Creator Contract\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "title is-6 mt-0 is-family-secondary" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.contract.name) +
                    " by " +
                    _vm._s(_vm.artistName) +
                    "\n                        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.state === 0
            ? _c("section", { staticClass: "mb-4 content notification" }, [
                _c("dl", [
                  _c("li", [
                    _vm._v(
                      "\n                            Burning means you transfer the ownership of this contract to the dead address\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.hasEditions
                    ? _c("span", [
                        _c("li", [
                          _vm._v(
                            "\n                                You  won't be able to access or edit this contract anymore and it'll disappear from everywhere on KnownOrigin\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                                You also won't be able to mint new artworks on this contract\n                            "
                          ),
                        ]),
                      ])
                    : _c("span", [
                        _c("li", [
                          _vm._v(
                            "\n                                You won't be able to mint new artworks on this contract, but your existing artwork can still be accessed\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                            If you want to burn the artwork on this contract, you'll have to do that seperately.\n                            "
                        ),
                        _c("br"),
                      ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v("\n                        This action "),
                  _c("span", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v("cannot"),
                  ]),
                  _vm._v(" be undone.\n                    "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state === 1
            ? _c("section", { staticClass: "mb-4 content notification" }, [
                _c("p", { staticClass: "is-6 mb-1" }, [
                  _vm._v("Your transaction is processing"),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [
              _c(
                "b-button",
                {
                  attrs: { expanded: "", disabled: _vm.state === 1 },
                  on: { click: _vm.close },
                },
                [
                  _vm._v(
                    "\n                        Never mind\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button is-danger",
                  attrs: { expanded: "", disabled: _vm.state === 1 },
                  on: { click: _vm.performAction },
                },
                [
                  _vm._v(
                    "\n                        Burn Contract\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(
        "\n                                You won't be able to access or edit this contract anymore, but "
      ),
      _c("span", { staticClass: "has-text-weight-semibold" }, [
        _vm._v("you'll still be paid"),
      ]),
      _vm._v(" royalties as normal\n                            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }