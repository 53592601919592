<template>
  <figure class="image is-24x24" :class="[size, shape]">
    <img
      :class="{shape, 'height-restrict': !noHeightRestrict, size}"
      :id="address"
      class="is-rounded has-background-white enforce-aspect-ratio"
      v-lazy="{
        src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${address}`,
        error: createAccountIcon,
      }"
    />
  </figure>
</template>

<script>
import { toDataUrl } from 'myetherwallet-blockies';

export default {
  props: ['address', 'size', 'shape', 'noHeightRestrict'],
  computed: {
    createAccountIcon() {
      return toDataUrl(this.address || '0x0');
    }
  },
  watch: {
    async address(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.height-restrict {
  max-height: 24px !important
}
.enforce-aspect-ratio {
  aspect-ratio: 1 / 1;
}

</style>
