<template>
  <client-only>
    <vue-countdown v-if="!hidden"
                   :time="diff"
                   :transform="transform"
                   :emit-events="true"
                   @progress="handleCountdownProgress">
      <template slot-scope="props">

<!--        {{ JSON.stringify(props) }}-->
        <section :class="{'has-text-white': whiteText === true}">
          <span v-if="props.days > 0">
             {{ props.days }}d&nbsp;&nbsp;{{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m
          </span>
          <span v-else-if="props.hours > 0">
             {{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m&nbsp;&nbsp;{{ props.seconds }}s
          </span>
          <span v-else-if="props.minutes > 0">
             {{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m&nbsp;&nbsp;{{ props.seconds }}s
          </span>
          <span v-else>
             00h&nbsp;&nbsp;00m&nbsp;&nbsp;00s
          </span>
        </section>
      </template>
    </vue-countdown>
  </client-only>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import * as moment from 'moment';

  export default {
    components: {VueCountdown},
    props: ['endDate', 'whiteText'],
    data() {
      return {
        hidden: false
      };
    },
    computed: {
      diff() {
        if (!this.endDate) { return 0; }

        // stored in UTC, ensure local is also UTC'd
        const endDate = moment.unix(this.endDate).utc();
        const now = moment().local().utc();
        const res = endDate.diff(now, 'milliseconds')
        return res >= 0 ? res : 0;
      },
      lessThanAnHour() {
        // stored in UTC, ensure local is also UTC'd
        const endDate = moment.unix(this.endDate).utc();
        const now = moment().local().utc();
        return endDate.diff(now, 'hours') < 24;
      }
    },
    mounted() {
      // const ONE_SECOND = 1000; //millis
      // const TIMEOUT = 20000; //millis

      // // In an attempt to catch browsers "Waking" up
      // let lastTime = (new Date()).getTime();
      // this.interval = setInterval(function () {
      //   const currentTime = (new Date()).getTime();
      //   if (currentTime > (lastTime + TIMEOUT + 2000)) {
      //     console.log("tab woken up", this.diff);
      //     if (this.diff <= ONE_SECOND) {
      //       this.clockFinished();
      //     }
      //   }
      //   lastTime = currentTime;
      // }, TIMEOUT);
    },
    methods: {
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;
          props[key] = `${digits}`;
        });
        return props;
      },
      handleCountdownProgress(data) {
        if (data.totalSeconds <= 0) {
          console.log('trigger sale');
          this.clockFinished();
        }
      },
      clockFinished() {
        console.log('timer called');

        this.hidden = true;

        // update UI
        this.$emit('countdown-complete');
      }
    }
    // destroyed() {
    //   if (this.interval) {
    //     clearInterval(this.interval);
    //   }
    // }
  };
</script>
