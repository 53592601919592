<template>
  <section class="mb-4">
    <article class="tile is-child content">
      <section v-if="isGatedSale">
        <marketplace-purchase-gated :edition="edition" :phase="edition.gatedSale.phases[0]">
        </marketplace-purchase-gated>
      </section>

      <!-- Primary still available -->
      <section v-else-if="isNotSoldOutPrimary">
        <!-- Primary sale revoked flow -->
        <section v-if="revokedApproval">
          <p class="has-text-danger">
            Not for sale - seller has revoked approval
          </p>
        </section>

        <!-- Buy Now -->
        <div class="columns is-mobile" v-else-if="isBuyNowTypes(edition.salesType)">
          <div class="column content">
            <p class="title is-family-tertiary has-text-weight-bold">Buy now</p>
            <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="edition.priceInWei">
            </eth-with-fiat-price>
          </div>
        </div>

        <!-- Step Sale -->
        <div class="columns is-mobile" v-else-if="isSteppedSalesType(edition.salesType)">
          <!-- Current step price -->
          <div class="column content">
            <p class="title is-family-tertiary has-text-weight-bold">Buy now</p>
            <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="edition.priceInWei">
            </eth-with-fiat-price>
          </div>

          <!-- Next step price -->
          <div class="column content" v-if="parseInt(edition.remainingSupply) > 1">
            <p class="title is-muted is-family-tertiary">Next price</p>
            <eth-with-fiat-price size="is-large" type="is-danger" :strong="true"
                                 :price-in-wei="getNextStep(edition.priceInWei, edition.stepSaleStepPrice)"
                                 :show-fiat="true">
            </eth-with-fiat-price>
          </div>
        </div>

        <!-- Reserve auction -->
        <div v-else-if="isReserveAuctionSalesType(edition.salesType)">
          <div class="columns is-mobile" v-if="canResultReserveAuction">
            <div class="column content">
              <b-button type="is-primary" size="is-medium" class="column" expanded
                        @click="resultReserveAuction(edition)">
                Complete auction
              </b-button>
            </div>
          </div>

          <div class="columns is-mobile">
            <!-- Reserve + valid offer - countdown in progress -->
            <div class="column content" v-if="hasValidEditionOffer && reserveEditionAuctionCountdownInProgress">
              <current-highest-offer :wei-value="editionOffers[0].weiValue"
                                     :bidder-address="editionOffers[0].bidder.address">
              </current-highest-offer>
            </div>

            <!-- Reserve + valid offer - countdown complete -->
            <div class="column content" v-else-if="hasValidEditionOffer && reserveEditionAuctionCountdownComplete">
              <winning-offer :wei-value="editionOffers[0].weiValue" :bidder-address="editionOffers[0].bidder.address">
              </winning-offer>
            </div>

            <!-- No offer - show reserve price -->
            <div class="column content" v-else-if="!hasValidEditionOffer">
              <p class="title is-family-tertiary has-text-weight-bold">Reserve price</p>
              <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="edition.reservePrice">
              </eth-with-fiat-price>
            </div>
          </div>
        </div>

        <!-- Offers only -->
        <div class="columns is-mobile" v-else-if="isOffersOnlySalesType(edition.salesType)">
          <div class="column is-mobile" v-if="editionOffers && editionOffers.length > 0">
            <current-highest-offer :wei-value="editionOffers[0].weiValue"
                                   :bidder-address="editionOffers[0].bidder.address">
            </current-highest-offer>
          </div>

          <div class="column is-mobile" v-if="offerValidAndNotReached">
            <p class="title is-family-tertiary has-text-weight-bold">Minimum bid</p>
            <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="minBid.eth_reserve_in_wei"
                                 v-if="minBid && minBid.eth_reserve_in_wei">
            </eth-with-fiat-price>
            <p v-else class="is-family-tertiary">No reserve</p>
          </div>
        </div>

        <!-- Show start date countdown -->
        <div class="columns is-mobile" v-if="!editionStartDatePassed && !isGatedSale">
          <div class="column content">
            <edition-sale-count-down :edition="edition" @start-sale="onSaleStarted">
            </edition-sale-count-down>
          </div>
        </div>

        <!-- Reserve Auction - Show countdown clock price if in flight -->
        <div class="columns is-mobile"
             v-if="isReserveAuctionSalesType(edition.salesType) && reserveEditionAuctionCountdownInProgress">
          <div class=" column content">
            <auction-count-down v-if="parseInt(edition.reserveAuctionEndTimestamp) > 0" :listing="edition">
            </auction-count-down>
          </div>
        </div>

        <!-- Primary sale CTAs -->
        <section class="columns is-mobile" v-if="!revokedApproval && editionStartDatePassed && isWeb3Connected" v-cloak>
          <div class="column content" v-if="isBuyableSalesType(edition.salesType)">
            <buy-now-buttons :edition="edition" :algolia-info="algoliaInfo"></buy-now-buttons>
          </div>
          <div class="column content" v-else-if="canPlaceEditionOffer">
            <b-button type="is-primary" size="is-medium" class="has-margin-bottom-7" expanded
                      @click="launchMakeOfferEditionModal">
              Place a bid
            </b-button>
            <wert-topup-link></wert-topup-link>
          </div>
        </section>

      </section>

      <!-- Secondary -->
      <section v-else-if="workingToken && !isGatedSale">
        <!-- Reserve auction -->
        <div class="columns is-mobile"
             v-if="isReserveAuctionSalesType(workingToken.salesType) && workingToken.isListed">

          <!-- Reserve + valid offer - countdown in progress -->
          <div class="column content"
               v-if="tokenOffers && tokenOffers.length > 0 && reserveTokenAuctionCountdownInProgress">
            <current-highest-offer :wei-value="tokenOffers[0].weiValue" :bidder-address="tokenOffers[0].bidder.address">
            </current-highest-offer>
          </div>

          <!-- Reserve + valid offer - countdown complete -->
          <div class="column content"
               v-else-if="tokenOffers && tokenOffers.length > 0 && reserveTokenAuctionCountdownComplete">
            <winning-offer :wei-value="tokenOffers[0].weiValue" :bidder-address="tokenOffers[0].bidder.address">
            </winning-offer>
          </div>

          <!-- Reserve price listing -->
          <div class="column content" v-else-if="!tokenOffers || tokenOffers.length === 0">
            <p class="title is-family-tertiary has-text-weight-bold">Reserve price</p>
            <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="workingToken.listing.reservePrice">
            </eth-with-fiat-price>
          </div>

          <!-- Show countdown clock price if in flight -->
          <div class="column content" v-if="reserveTokenAuctionCountdownInProgress">
            <auction-count-down
              v-if="workingToken.listing.reserveAuctionEndTimestamp && parseInt(workingToken.listing.reserveAuctionEndTimestamp) > 0"
              :listing="workingToken.listing">
            </auction-count-down>
          </div>
          <div class="column content" v-else-if="reserveTokenAuctionCountdownComplete">
            <p class="title is-family-tertiary has-text-weight-bold">
              Auction complete
            </p>
          </div>

        </div>

        <div class="columns is-mobile"
             v-if="isReserveAuctionSalesType(workingToken.salesType) && workingToken.isListed && !reserveTokenAuctionCountdownComplete && isWeb3Connected">
          <div class="column content">
            <b-button type="is-primary" size="is-medium" class="has-margin-bottom-7" expanded
                      @click="launchMakeTokenOfferModel">
              Place a bid
            </b-button>
            <wert-topup-link></wert-topup-link>
          </div>
        </div>

        <!-- Buy Now -->
        <div v-if="isBuyNowSalesType(workingToken.salesType) && workingToken.isListed">

          <div class="columns is-mobile">
            <div class="column is-full content">
              <p class="title is-family-tertiary has-text-weight-bold">Buy now</p>
              <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-eth="workingToken.listPrice">
              </eth-with-fiat-price>
            </div>
          </div>

          <div class="columns is-mobile" v-if="isWeb3Connected">
            <div class="column content">
              <b-button type="is-primary" size="is-medium" class="has-margin-bottom-7" expanded
                        @click="launchBuyNowTokenModal">
                Buy with ETH
              </b-button>
              <b-button type="is-secondary" size="is-medium" class="has-margin-bottom-7" expanded outlined
                        @click="launchBuyNowTokenWertModal" v-if="workingToken.version === '3'">
                Buy with card
              </b-button>
              <wert-topup-link v-else></wert-topup-link>
            </div>
          </div>
        </div>

        <!-- Offers only -->
        <div v-if="isOffersOnlySalesType(workingToken.salesType) && !workingToken.isListed">
          <div class="columns is-mobile" v-if="tokenOffers && tokenOffers.length > 0">
            <div class="column content">
              <current-highest-offer :wei-value="tokenOffers[0].weiValue"
                                     :bidder-address="tokenOffers[0].bidder.address">
              </current-highest-offer>
            </div>
          </div>

          <div class="columns is-mobile" v-if="minBid && minBid.eth_reserve_in_wei">
            <div class="column content">
              <p class="title">Minimum bid</p>
              <eth-with-fiat-price size="is-large" :show-fiat="true" :price-in-wei="minBid.eth_reserve_in_wei">
              </eth-with-fiat-price>
            </div>
          </div>

          <div class="columns is-mobile" v-if="canPlaceTokenOffer && isWeb3Connected">
            <div class="column content">
              <b-button type="is-primary" size="is-medium" class="has-margin-bottom-7" expanded
                        @click="launchMakeTokenOfferModel">
                Place a bid
              </b-button>
              <wert-topup-link></wert-topup-link>
            </div>
          </div>
        </div>

      </section>

      <!-- no actionable tokens on primary or secondary - CTA to move to the table below -->
      <div v-else-if="moveToOffersTable">
        <div class="columns is-mobile">
          <div class="column content">
            <a href="#salesTable" v-smooth-scroll="{ duration: 500, updateHistory: false, offset: -150 }"
               class="has-margin-bottom-7 button is-medium is-fullwidth is-secondary">
              View listings
            </a>
          </div>
        </div>
      </div>

      <section v-if="!isWeb3Connected">
        <b-button type="is-primary" size="is-medium" expanded outlined class="has-margin-bottom-7 has-margin-top-7"
                  @click="loginToWeb3" data-testid="connectWalletnBtn">
          <span>Connect wallet</span>
        </b-button>
      </section>

      <!-- If its a 1-of-1 show owner once sold out on primary -->
      <div class="columns is-mobile" v-if="isSoldOutPrimary && workingToken && edition.totalSupply === '1'">
        <div class="column content">
          <div class="has-text-weight-bold title is-family-tertiary">
            Owned by
          </div>
          <user-profile-lookup :artist-account="workingToken.currentOwner.address"
                               :metadata-artist="workingToken.currentOwner.address">
          </user-profile-lookup>
        </div>
      </div>

    </article>
  </section>
</template>

<script>

import {mapGetters, mapState} from 'vuex';
import _get from 'lodash/get';
import _first from 'lodash/first';
import _filter from 'lodash/filter';
import {ethers} from 'ethers';
import editionUtils from '../../services/editionUtils';
import {
  getNextStep,
  isBuyableSalesType,
  isBuyNowSalesType,
  isBuyNowTypes,
  isOffersOnlySalesType,
  isOffersSalesType,
  isReserveAuctionSalesType,
  isSteppedSalesType
} from '../../services/SaleTypes';
import {GET_EDITION_ACTIVE_OFFER, GET_TOKEN_ACTIVE_OFFER} from '../../queries/offersQueries';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import CurrentHighestOffer from '../offers/CurrentHighestOffer';
import AuctionCountDown from '../clocks/AuctionCountDown';
import EditionSaleCountDown from '../clocks/EditionSaleCountDown';
import WertTopupLink from '../WertTopupLink';
import PurchaseWithWert from '../modal/wert/PurchaseWithWert';
import PlaceEditionOffer from '../modal/edition/PlaceEditionOffer';
import WinningOffer from '../offers/WinningOffer';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import PurchaseToken from '../modal/token/PurchaseToken';
import PlaceTokenOffer from '../modal/token/PlaceTokenOffer';
import {findCheapestToken} from '../../services/salesStates';
import {isKoEscrow} from '../../services/utils';
import UserProfileLookup from '../UserProfileLookup';
import {TOKEN_BY_ID_QUERY} from '../../queries/tokenQueries';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';
import ResultEditionReserveAuction from '../modal/edition/ResultEditionReserveAuction';
import ResultTokenReserveAuction from '../modal/token/ResultTokenReserveAuction';
import MarketplacePurchaseGated from './MarketplacePurchaseGated';
import BuyNowButtons from './BuyNowButtons';

export default {
  components: {
    BuyNowButtons,
    UserProfileLookup,
    WinningOffer,
    EthWithFiatPrice,
    CurrentHighestOffer,
    AuctionCountDown,
    EditionSaleCountDown,
    WertTopupLink,
    MarketplacePurchaseGated
  },
  props: ['edition', 'algoliaInfo', 'address'],
  data() {
    return {
      minBid: null,
      listings: null,
      editionOffers: null,
      tokenOffers: null,
      workingToken: null,
      revokedApproval: null,
      showSingleTokenOfferButton: false,
      moveToOffersTable: false
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'isWeb3Connected'
    ]),
    ...mapState('web3Store', [
      'gqlClient',
      'chainId',
      'account'
    ]),
    editionStartDatePassed() {
      return editionUtils.startDatePassed(this.edition);
    },
    scheduledSale() {
      return editionUtils.scheduledSale(this.edition);
    },
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    isSoldOutPrimary() {
      return editionUtils.isSoldOutPrimary(this.edition);
    },
    hasValidEditionOffer() {
      return ReserveAuctionUtils.hasValidEditionOffer(this.edition, this.editionOffers, this.minBid);
    },
    reserveEditionAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.edition);
    },
    reserveEditionAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    reserveEditionAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    },
    reserveTokenAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.workingToken);
    },
    reserveTokenAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.workingToken);
    },
    canPlaceTokenOffer() {
      return !isKoEscrow(_get(this.workingToken, 'currentOwner.id'));
    },
    canPlaceEditionOffer() {
      if (this.isSoldOutPrimary) {
        return false;
      }
      if (this.isReserveAuctionSalesType(this.edition.salesType) && this.reserveEditionAuctionCountdownComplete) {
        return false;
      }
      return this.isOffersSalesType(this.edition.salesType);
    },
    isGatedSale() {
      return this.edition.gatedSale && this.edition.gatedSale.phases.length > 0 && editionUtils.isNotSoldOutPrimary(this.edition) && !isOffersOnlySalesType(this.edition.salesType);
    },
    offerValidAndNotReached() {
      const minOfferSet = this.minBid && this.minBid.eth_reserve_in_wei;
      const hasEditionOffer = this.editionOffers && this.editionOffers.length > 0;

      if (minOfferSet && !hasEditionOffer) {
        return true;
      }

      return minOfferSet &&
        hasEditionOffer &&
        ethers.utils.formatEther(this.minBid.eth_reserve_in_wei || 0) > ethers.utils.formatEther(this.editionOffers[0].weiValue);
    },
    canResultReserveAuction() {
      // Make sure its a V3 NFT
      if (parseInt(this.edition.version) === 3) {
        // If there are no tokens then we're still an edition
        if (!this.edition.tokens.length) {
          // Edition auction is complete, not in an emergency exit state and is a valid user
          if (this.edition.__typename === 'Edition' && isReserveAuctionSalesType(this.edition.salesType)) {
            return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition) &&
              !this.edition.reserveAuctionCanEmergencyExit &&
              ReserveAuctionUtils.canResultEditionReserveAuction(this.edition, this.account);
          }
        } else {
          // Otherwise the token version has a reserve auction on it
          // Auction is complete, not in an emergency exit state and is a valid user
          const token = this.edition.tokens[0]
          if (token && isReserveAuctionSalesType(token.salesType)) {
            return ReserveAuctionUtils.reserveAuctionCountdownComplete(token.listing) &&
              !token.listing.reserveAuctionCanEmergencyExit &&
              ReserveAuctionUtils.canResultTokenReserveAuction(
                token.listing.reserveAuctionSeller,
                token.listing.reserveAuctionBidder,
                this.account
              );
          }
        }
      }
      return false;
    }
  },
  async mounted() {
    if (this.edition) {
      this.$store.dispatch('marketplaceStore/getById', {chainId: this.chainId, entityId: this.edition.id})
        .then(async (result) => {
          if (result) {
            this.listings = result.filters.listings;
            if (this.isNotSoldOutPrimary) {
              await this.loadMinimumEditionBid();
              await this.loadEditionOffers();
              await this.checkPrimarySaleApprovalStatus();
            } else {
              await this.loadCheapestToken();
              await this.loadTokenOffers();
              await this.loadMinimumTokenBid();
            }
          }
        })
    }
  },
  methods: {
    isBuyNowTypes,
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isOffersSalesType,
    isSteppedSalesType,
    isReserveAuctionSalesType,
    getNextStep,
    isBuyableSalesType,
    onSaleStarted() {
      // Tweak the edition to force a re-render
      this.edition.startDate = this.edition.startDate - 1;
    },
    loginToWeb3() {
      this.$store.dispatch('web3Store/connectToWeb3');
    },
    resultReserveAuction(offer) {
      // If there are no tokens then we're still an edition
      if (!this.edition.tokens.length) {
        this.$buefy.modal.open({
          parent: this,
          component: ResultEditionReserveAuction,
          width: 600,
          props: {
            edition: offer
          },
          onCancel: () => {
            this.$emit('refresh-data');
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      } else if (this.edition.tokens.length && this.editions.tokens[0]) {
        // TODO this bit is untested, are we fetching enough info in Tokens to make this work?
        this.$buefy.modal.open({
          parent: this,
          component: ResultTokenReserveAuction,
          width: 600,
          props: {
            edition: offer,
            token: this.editions.tokens[0],
            listing: this.editions.tokens[0].listing
          },
          onCancel: () => {
            this.$emit('refresh-data');
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      }
    },
    async checkPrimarySaleApprovalStatus() {
      // V2 primary is within the NFT contract so no approvals checks are needed
      // TODO WE NEED TO CHECK APPROVAL FOR V4 AT SOME POINT
      if (this.edition.version === '2' || this.edition.version === '4') {
        this.revokedApproval = false;
      } else {
        const operator = await this.$store.dispatch('web3ActionsStore/getPrimarySaleMarketplaceAddress', {
          version: this.edition.version
        });
        const isApproved = await this.$store.dispatch('web3ActionsStore/checkApprovalStatus', {
          owner: this.edition.artistAccount,
          operator
        });
        this.revokedApproval = !isApproved;
      }
    },
    launchMakeOfferEditionModal() {
      if (this.algoliaInfo) {
        this.algoliaTrackButtonClicks(EVENT_NAMES.buttonClicked, this.edition.id)
      }

      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'make offer modal',
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'Place a bid',
          [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary'
        }
      });

      this.$buefy.modal.open({
        parent: this,
        component: PlaceEditionOffer,
        width: 600,
        props: {
          edition: this.edition,
          existingOffer: _first(this.existingOffer),
          minBid: this.minBid,
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          if (this.algoliaInfo) {
            this.algoliaTrackButtonClicks(EVENT_NAMES.offerFailed, this.edition.id)
          }

          this.$store.dispatch('analytics/amplitudeStore/logEvent', {
            name: EVENT_NAMES.offerFailed,
            properties: {
              [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
              [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
              [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
              [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
            }
          });

          this.$emit('refresh-data');
        },
        events: {
          close: (e) => {
            if (!e.purchaseComplete) {
              this.$store.dispatch('analytics/amplitudeStore/logEvent', {
                name: EVENT_NAMES.offerFailed,
                properties: {
                  [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
                  [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
                  [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
                  [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
                  [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
                }
              });

              if (this.algoliaInfo) {
                this.algoliaTrackButtonClicks(EVENT_NAMES.offerFailed, this.edition.id)
              }
            }

            this.$emit('refresh-data');
          }
        }
      });
    },
    loadEditionOffers() {
      if (this.edition) {
        this.$apollo.addSmartQuery('editionOffers', {
          query: GET_EDITION_ACTIVE_OFFER,
          pollInterval: 10000, // ms
          client: this.gqlClient,
          variables() {
            return {
              edition: `edition-${this.edition ? this.edition.id : '0'}`
            };
          },
          result({data}) {
            this.editionOffers = _get(data, 'editionOffers');
          },
          error(error) {
            console.log('error', error);
          }
        });
      }
    },
    launchMakeTokenOfferModel() {
      if (this.algoliaInfo) {
        this.algoliaTrackButtonClicks(EVENT_NAMES.buttonClicked, this.workingToken.id)
      }

      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'make offer modal',
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'Place a bid',
          [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary'
        }
      });

      this.$buefy.modal.open({
        parent: this,
        component: PlaceTokenOffer,
        width: 600,
        props: {
          token: this.workingToken,
          edition: this.edition,
          existingOffer: _first(this.tokenOffers),
          minBid: this.minBid,
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          if (this.algoliaInfo) {
            this.algoliaTrackButtonClicks(EVENT_NAMES.offerFailed, this.workingToken.id)
          }

          this.$store.dispatch('analytics/amplitudeStore/logEvent', {
            name: EVENT_NAMES.offerFailed,
            properties: {
              [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
              [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
              [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
              [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
            }
          });

          this.$emit('refresh-data');
        },
        events: {
          close: (e) => {
            if (!e.purchaseComplete) {
              this.$store.dispatch('analytics/amplitudeStore/logEvent', {
                name: EVENT_NAMES.offerFailed,
                properties: {
                  [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
                  [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
                  [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
                  [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
                  [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
                }
              });

              if (this.algoliaInfo) {
                this.algoliaTrackButtonClicks(EVENT_NAMES.offerFailed, this.workingToken.id)
              }
            }

            this.$emit('refresh-data');
          }
        }
      });
    },
    launchBuyNowTokenModal() {
      if (this.algoliaInfo) {
        this.algoliaTrackButtonClicks(EVENT_NAMES.buttonClicked)
      }

      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'buy now modal',
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'Buy with ETH',
          [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
          [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
        }
      });

      this.$buefy.modal.open({
        parent: this,
        component: PurchaseToken,
        width: 600,
        props: {
          token: this.workingToken,
          edition: this.edition,
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          if (this.algoliaInfo) {
            this.algoliaTrackButtonClicks(EVENT_NAMES.purchaseFailed, this.workingToken.id)
          }

          this.$store.dispatch('analytics/amplitudeStore/logEvent', {
            name: EVENT_NAMES.purchaseFailed,
            properties: {
              [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
              [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
              [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
              [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
            }
          });

          this.$emit('refresh-data');
        },
        events: {
          close: (e) => {
            if (!e.purchaseComplete) {
              this.$store.dispatch('analytics/amplitudeStore/logEvent', {
                name: EVENT_NAMES.purchaseFailed,
                properties: {
                  [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
                  [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
                  [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
                  [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
                  [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth'
                }
              });

              if (this.algoliaInfo) {
                this.algoliaTrackButtonClicks(EVENT_NAMES.purchaseFailed, this.workingToken.id)
              }
            }

            this.$emit('refresh-data');
          }
        }
      });
    },
    async launchBuyNowTokenWertModal() {
      if (this.algoliaInfo) {
        this.algoliaTrackButtonClicks('Marketplace;Token buy wert clicked', this.workingToken.id)
      }

      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'wert modal',
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'Buy with card',
          [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
          [AMPLITUDE_EVENT_PROPERTIES.currency]: 'wert'
        }
      });

      const tokenById = await this.$apollo.getClient()
        .query({
          query: TOKEN_BY_ID_QUERY,
          variables: {id: this.workingToken.id}
        })
        .then(({data}) => data && data.tokenById)
        .catch((error) => {
          console.warn(`Unable to load token for ID [${this.workingToken.id}]`, error);
          return {};
        });

      this.$buefy.modal.open({
        parent: this,
        component: PurchaseWithWert,
        width: this.$device.isDesktopOrTablet ? 1000 : 600,
        canCancel: ['x'],
        props: {
          token: tokenById,
          edition: this.edition,
          entityType: 'token',
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          if (this.algoliaInfo) {
            this.algoliaTrackButtonClicks(EVENT_NAMES.purchaseFailed, this.workingToken.id)
          }

          this.$store.dispatch('analytics/amplitudeStore/logEvent', {
            name: EVENT_NAMES.purchaseFailed,
            properties: {
              [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
              [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
              [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
              [AMPLITUDE_EVENT_PROPERTIES.currency]: 'wert'
            }
          });

          this.$emit('refresh-data');
        },
        events: {
          close: (e) => {
            if (!e.purchaseComplete) {
              this.$store.dispatch('analytics/amplitudeStore/logEvent', {
                name: EVENT_NAMES.purchaseFailed,
                properties: {
                  [AMPLITUDE_EVENT_PROPERTIES.id]: this.workingToken.id,
                  [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
                  [AMPLITUDE_EVENT_PROPERTIES.reason]: 'cancelled',
                  [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
                  [AMPLITUDE_EVENT_PROPERTIES.currency]: 'wert'
                }
              });

              if (this.algoliaInfo) {
                this.algoliaTrackButtonClicks(EVENT_NAMES.purchaseFailed, this.workingToken.id)
              }
            }

            this.$emit('refresh-data');
          }
        }
      });
    },
    loadTokenOffers() {
      if (this.workingToken) {
        this.$apollo.getClient()
          .query({
            query: GET_TOKEN_ACTIVE_OFFER,
            variables: {
              token: `token-${this.workingToken.id}`
            }
          })
          .then(({data}) => {
            this.tokenOffers = _get(data, 'tokenOffers');
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },
    loadCheapestToken() {
      // remove burnt tokens from the list
      const cleanTokens = _filter(this.edition.tokens, (token) => {
        return token.currentOwner.id !== '0x000000000000000000000000000000000000dead' &&
          token.currentOwner.id !== '0x0000000000000000000000000000000000000000';
      });

      this.workingToken = findCheapestToken(cleanTokens);

      if (!this.workingToken) {
        // If only one token then fallback to offers only flow for this one item
        if (cleanTokens.length === 1) {
          this.workingToken = _first(cleanTokens);
        } else {
          // Set this flag to
          this.moveToOffersTable = true;
        }
      }
    },
    async loadMinimumTokenBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.workingToken, 'currentOwner.id'),
        artwork_id: _get(this.workingToken, 'id'),
        entity_type: 'token'
      });
    },
    async loadMinimumEditionBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.edition, 'artistAccount'),
        artwork_id: _get(this.edition, 'id'),
        entity_type: 'edition'
      });
    },
    algoliaTrackButtonClicks(message, id) {
      if (this.algoliaInfo) {
        this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
          [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
          [ALGOLIA_EVENT_PROPERTIES.eventName]: message,
          [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
          [ALGOLIA_EVENT_PROPERTIES.objectId]: id || this.edition.id,
          [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
        });
      }
    }
  }
};

</script>
