<template>
  <div v-if="edition && phase">
    <section class="columns is-mobile is-flex is-align-items-end">
      <div class="column content mb-0 is-family-tertiary">
        <p
          class="title"
          :class="{'is-muted': phaseState === 'public'}">
          Early access price
        </p>
        <div v-if="phase.priceInWei === '0'">
          <p class="title">Free claim</p>
        </div>
        <eth-with-fiat-price
          size="is-large"
          :type="phaseState === 'public' ? 'is-muted' : ''"
          :show-fiat="true"
          :price-in-wei="phase.priceInWei">
        </eth-with-fiat-price>
      </div>

      <div class="column content is-family-tertiary">
        <p
          class="title mb-0"
          :class="phaseState === 'public' ? {} : {'is-muted': true}">
          Public price
        </p>
        <eth-with-fiat-price
          size="is-large"
          :type="phaseState === 'public' ? '' : 'is-muted'"
          :show-fiat="true"
          :price-in-wei="edition.priceInWei">
        </eth-with-fiat-price>
      </div>
    </section>

    <section v-if="isWeb3Connected" class="columns is-mobile">
      <div class="column content" v-if="onEarlyAccessList && phaseState === 'active'">
        <buy-now-buttons
          v-if="phase.priceInWei !== '0'"
          :edition="edition"
          :phase="phase"
          :proofs="userMerkleProofs"
          :gated="true"
          :hide-wert="true"
        >
        </buy-now-buttons>
        <buy-now-buttons
          v-else
          :edition="edition"
          :phase="phase"
          :proofs="userMerkleProofs"
          :free="true"
          :gated="true"
          :hide-wert="true"
        ></buy-now-buttons>
      </div>
      <div class="column content" v-else-if="phaseState === 'public'">
        <buy-now-buttons
          :edition="edition">
        </buy-now-buttons>
      </div>
    </section>
    <section class="columns is-mobile is-family-tertiary">
      <div class="column content" v-if="!isWeb3Connected">
        <gated-message-countdown
          v-if="phaseState !== 'public'"
          :start-date="phase.endTime"
          type="success"
        >
          <template slot="countdown-title">
            <p class="title has-text-success">
              Public sale starts in
            </p>
          </template>
        </gated-message-countdown>
        <early-access-section :edition="edition">
        </early-access-section>
      </div>
      <div v-else-if="!initComplete">
        <b-skeleton width="20%" :animated="true"></b-skeleton>
        <b-skeleton width="40%" :animated="true"></b-skeleton>
      </div>
      <div class="column content" v-else-if="onEarlyAccessList">
        <div v-if="phaseState === 'pre-phase'">
          <gated-message-countdown
            :start-date="phase.startTime"
            type="success"
          >
            <template slot="countdown-title">
              <p class="title has-text-success">
                You're eligible for early access! Starts in
              </p>
            </template>
          </gated-message-countdown>
          <early-access-section :edition="edition">
          </early-access-section>
        </div>
        <div class="column content" v-if="phaseState === 'active'">
          <gated-message-countdown
            :start-date="phase.endTime"
            type="success"
          >
            <template slot="countdown-title">
              <p class="title has-text-success">
                You're eligible for early access! Ends in
              </p>
            </template>
          </gated-message-countdown>
          <early-access-section :edition="edition">
          </early-access-section>
        </div>
      </div>
      <div v-else-if="!onEarlyAccessList && phaseState !== 'public'" class="column content">
        <gated-message-countdown
          :start-date="phase.endTime"
          type="danger">
          <template slot="countdown-title">
            <p class="title has-text-danger">
              You're ineligible for early access. Don't worry, public sale starts in
            </p>
          </template>
        </gated-message-countdown>
        <early-access-section :edition="edition">
        </early-access-section>
      </div>
    </section>
  </div>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
import {getPhaseState} from '../../services/SaleTypes'
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import GatedMessageCountdown from '../clocks/GatedMessageCountdown';
import EarlyAccessSection from '../early-access/EarlyAccessSection';
import BuyNowButtons from './BuyNowButtons';

export default {
  components: {
    EarlyAccessSection,
    EthWithFiatPrice,
    GatedMessageCountdown,
    BuyNowButtons
  },
  props: ['edition', 'phase'],
  computed: {
    ...mapState('web3Store', [
      'chainId',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'isWeb3Connected'
    ]),
    onEarlyAccessList() {
      return this.userMerkleProofs
    }
  },
  data() {
    return {
      phaseState: null,
      initComplete: false,
      userMerkleProofs: null
    }
  },
  watch: {
    async account (newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.init()
      }
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async getEarlyAccessProofs() {
      if (this.account && this.phase) {
        const res = await this.$api.$get(`/network/${this.chainId}/gatedsales/access/sale/${this.phase.saleId}/phase/${this.phase.phaseId}/address/${this.account}`)
        if (res.phase) {
          this.userMerkleProofs = res.proofs
        }
      }
    },
    async init() {
      this.phaseState = getPhaseState(this.phase)
      await this.getEarlyAccessProofs()
      this.initComplete = true
    }
  }
}
</script>
