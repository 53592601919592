<template>
  <div v-if="tokens && tokens.length > 0" class="mt-6 p-0" id="salesTable">
    <span class="content"><h2>Your tokens</h2></span>
    <b-table
      :data="tokens"
      sticky-header
      default-sort="priceInEth"
      default-sort-direction="desc"
      striped
      :mobile-cards="false">
      <b-table-column
        field="id"
        label="ID"
        centered
        sortable
        v-slot="props"
        :visible="$device.isDesktopOrTablet">
        <span class="is-clickable">#{{ props.row.tableData.id }}</span>
      </b-table-column>
      <b-table-column
        field="seller"
        label="Owned By"
        v-slot="props">
        <n-link v-if="props.row.tableData.owner" :to="{name:'profile-id', params: { id: props.row.tableData.owner}}">
          <artist-name-and-image-lookup
            class="is-small small is-inline-block"
            :artist-account="props.row.tableData.owner"
            :size="'is-small'">
          </artist-name-and-image-lookup>
        </n-link>
      </b-table-column>
      <b-table-column
        field="lastSalePriceInEth"
        label="Last Price"
        sortable
        numeric
        centered
        :custom-sort="sortableString('lastSalePriceInEth')"
        :visible="$device.isDesktopOrTablet"
        v-slot="props"
        class="is-flex is-align-items-center">
        <eth-with-fiat-price v-if="props.row.tableData.lastPrice" :price-in-eth="props.row.tableData.lastPrice">
        </eth-with-fiat-price>
      </b-table-column>
      <b-table-column
        field="priceInEth"
        label="List Price"
        sortable
        numeric
        centered
        :custom-sort="sortableString('priceInEth')"
        v-slot="props">
        <eth-with-fiat-price :price-in-eth="props.row.tableData.listPrice"
                             v-if="props.row.tableData.listPrice">
        </eth-with-fiat-price>
      </b-table-column>
      <b-table-column
        field="expiration"
        label="Expiration"
        sortable
        numeric
        centered
        v-slot="props"
        :custom-sort="sortableString('expiration')">
        <p v-if="props.row.tableData.expiration">{{ props.row.tableData.expiration }}</p>
      </b-table-column>
      <b-table-column
             field="actionButton"
             centered
             v-slot="props"
              >
              <b-dropdown v-if="props.row.tableData.editFlow" aria-role="list" class="is-clickable has-text-left" position="is-bottom-left" :triggers="['hover']">
                <b-icon icon="dots-horizontal" slot="trigger"></b-icon>
                <b-dropdown-item aria-role="list" target="_blank" :href="getOpenseaLink(props.row.tableData.id)">
                  View on OpenSea
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item aria-role="list" @click="launchSecondaryListingModal(props.row.tableData, 'cancel')">
                  Cancel listing
                </b-dropdown-item>
              </b-dropdown>
             <b-button
               class="is-small is-fullwidth"
               type="is-primary"
               @click="launchSecondaryListingModal(props.row.tableData, 'list')"
               v-else>
               Sell this item
             </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
import {utils} from 'ethers';
import _get from 'lodash/get'
import {calculateIterativeEditionId} from '../../services/editionUtils';
import ArtistNameAndImageLookup from '../avatar/ArtistNameAndImageLookup';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import SecondaryListing from '../modal/secondary/SecondaryListing.vue';
import { SecondaryService } from '../../services/SecondaryService';
import { getChainName } from '../../services/utils'

export default {
  components: {
    ArtistNameAndImageLookup,
    EthWithFiatPrice
  },
  props: [
    'edition',
    'ownedTokens'
  ],
  data() {
    return {
      tokens: [],
      editFlow: false,
      secondaryService: SecondaryService()
    }
  },
  computed: {
    ...mapState('web3Store', [
      'chainId',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'isWeb3Connected'
    ])
  },
  async mounted() {
    if (this.ownedTokens) {
      await this.refreshTokens()
    }
  },
  methods: {
    getEditionId(listing) {
      return calculateIterativeEditionId(this.edition, listing.id)
    },
    extractTableData(token, secondaryData) {
      const tokenId = String(token.id).split('-')[1]
      return {
        id: tokenId,
        owner: token.currentOwner.address.toLowerCase(),
        lastPrice: this.getLastPrice(token, secondaryData),
        listPrice: this.getListPrice(token, secondaryData),
        expiration: this.getExpiration(token, secondaryData),
        orderId: this.getOrderId(secondaryData)
      }
    },
    getOrderId(secondaryData) {
      return _get(secondaryData, 'market.floorAsk.id');
    },
    getLastPrice(token, secondaryData) {
      if (_get(secondaryData, 'token.lastBuy.value')) {
        return secondaryData.token.lastBuy.value
      }

      return token.lastSalePriceInEth
    },
    getListPrice(token, secondaryData) {
      if (_get(secondaryData, 'market.floorAsk.price.amount.native')) {
        return secondaryData.market.floorAsk.price.amount.native
      }

      return token.listPrice
    },
    getExpiration(token, secondaryData) {
      let date = null;
      const now = this.$moment.utc(Date.now());
      if (_get(secondaryData, 'market.floorAsk.validUntil')) {
        date = secondaryData.market.floorAsk.validUntil
      } else {
        date = token.listingTimestamp
      }

      if (date > now.unix()) {
        date = this.$moment.utc(new Date(date * 1000)).fromNow()
      }

      return date
    },
    sortableString(field) {
      return (a, b, isAsc) => {
        let aVal;
        let bVal;

        if (field === 'priceInEth') {
          aVal = (a.priceInEth || '0').toString();
          bVal = (b.priceInEth || '0').toString();
        } else if (field === 'lastSalePriceInEth') {
          aVal = this.formatPrice((a.lastSalePriceInEth || '0').toString());
          bVal = this.formatPrice((b.lastSalePriceInEth || '0').toString());
        }

        return isAsc
          ? this.parseSortVal(bVal, isAsc) - this.parseSortVal(aVal, isAsc)
          : this.parseSortVal(aVal, isAsc) - this.parseSortVal(bVal, isAsc);
      };
    },
    formatPrice(priceInEth) {
      return utils.parseEther(priceInEth).toString();
    },
    parseSortVal(val, isAsc) {
      const p = parseFloat(val);
      if (isAsc) {
        return p === 0 ? -Infinity : p;
      } else {
        return p === 0 ? Infinity : p;
      }
    },
    async refreshTokens() {
      this.tokens = await Promise.all(this.ownedTokens.map(async (token) => {
        try {
          const secondaryData = await this.getSecondaryListingData(token.id);
          token.tableData = this.extractTableData(token, secondaryData.tokens[0])
          token.tableData.editFlow = this.isListedOnSecondary(secondaryData.tokens[0]);
          return token
        } catch (err) {
          console.error(`Unable to fetch secondary token data for ${token.id}`, err)
          token.tableData = this.extractTableData(token, null)
          return token
        }
      }))
    },
    getOpenseaLink(tokenId) {
      return `https://${this.chainId !== 1 && 'testnets.'}opensea.io/assets/${getChainName(this.chainId)}/${this.edition.creatorContract.id}/${tokenId}`
    },
    isListedOnSecondary(secondaryData) {
      return secondaryData.market.floorAsk.validFrom ? (secondaryData.market.floorAsk.validUntil * 1000 > Date.now()) : false
    },
    launchSecondaryListingModal(token, operation) {
      this.$buefy.modal.open({
        parent: this,
        component: SecondaryListing,
        width: 600,
        props: {
          edition: this.edition,
          token,
          operation,
          secondaryService: this.secondaryService
        },
        events: {
          close: () => {
            this.refreshTokens();
          }
        }
      })
    },
    async getSecondaryListingData(token) {
      const tokenId = String(token).replace('-', ':');
      const response = await this.secondaryService.getSecondaryListing(tokenId)
      console.log(response)
      return response;
    },
    async getSecondaryListingDataForTokens(tokens) {
      const tokenArray = tokens.map((token) => { return String(token.id).replace('-', ':') })
      const response = await this.secondaryService.getSecondaryListings(tokenArray)
      return response;
    }
  }
}
</script>
