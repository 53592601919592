var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("section", [
        _vm.contract && _vm.name && _vm.symbol
          ? _c(
              "div",
              {
                staticClass: "content box-fat ccArtwork",
                class: _vm.customClass,
              },
              [
                _c(
                  "n-link",
                  {
                    attrs: {
                      to: {
                        name: "contract-contract",
                        params: { contract: _vm.contract.id },
                      },
                    },
                  },
                  [
                    _vm.contractImages
                      ? _c(
                          "figure",
                          {
                            staticClass: "avatar mb-4",
                            style: {
                              "background-image":
                                "url(" +
                                (_vm.contractImages.banner
                                  ? _vm.contractImages.banner
                                  : _vm.coverImage) +
                                ")",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: {
                                    src: _vm.contractImages.thumbnail,
                                    error: _vm.createAccountIcon(),
                                  },
                                  expression:
                                    "{\n                  src: contractImages.thumbnail,\n                  error: createAccountIcon()\n              }",
                                },
                              ],
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.name && _vm.symbol
                      ? _c(
                          "section",
                          {
                            staticClass:
                              "content is-centered px-1 contract-card-format mb-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "has-text-centered is-size-4 has-text-white",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "is-family-tertiary has-text-weight-medium",
                                  },
                                  [_vm._v(_vm._s(_vm.name))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "has-text-centered mt-3" },
                              [
                                _c(
                                  "b-tag",
                                  {
                                    staticClass:
                                      "is-family-tertiary has-text-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.symbol) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "section",
                      {
                        staticClass:
                          "content pl-4 pr-4 mb-2 description contract-card-format",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "has-text-centered has-text-white px-2",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.contractDetails
                                    ? _vm.contractDetails.description
                                    : "No Description"
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.contract.id
                  ? _c("section", { staticClass: "px-2 py-2 border-grey" }, [
                      _c("div", { staticClass: "level is-mobile" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "column level-left content creator-font m-0 p-0",
                          },
                          [
                            _c(
                              "n-link",
                              {
                                attrs: {
                                  to: {
                                    name: "profile-id",
                                    params: { id: _vm.contract.creator },
                                  },
                                },
                              },
                              [
                                _c("owner-name-and-image-lookup", {
                                  attrs: {
                                    account: _vm.contract.creator,
                                    "is-cc": true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.contractDetails
                          ? _c(
                              "div",
                              [
                                _vm.isLoggedInAccount(_vm.contract.creator) &&
                                parseInt(_vm.contract.transferState) !== 2
                                  ? _c(
                                      "b-dropdown",
                                      {
                                        staticClass:
                                          "is-clickable has-text-left mr-2 is-up is-right",
                                        attrs: {
                                          "aria-role": "list",
                                          triggers: ["click"],
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            slot: "trigger",
                                            icon: "dots-horizontal",
                                            type: "is-white",
                                          },
                                          slot: "trigger",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { "aria-role": "list" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.launchBurnContractModal()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "has-text-danger",
                                              },
                                              [_vm._v("Burn contract")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("hr", {
                                          staticClass: "dropdown-divider",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              "aria-role": "list",
                                              href: _vm.etherscanLink,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                View on Etherscan\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "p-0 m-0",
                                          attrs: {
                                            href: _vm.etherscanLink,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "etherscan",
                                            attrs: {
                                              src: require("../../assets/logos/etherscan-logo-circle-white.svg"),
                                              color: "white",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("section", [
        _c(
          "div",
          { staticClass: "content box-fat ccArtwork", class: _vm.customClass },
          [
            _c(
              "figure",
              {
                staticClass: "avatar mb-4",
                style: { "background-image": "url(" + _vm.coverImage + ")" },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: {
                        src: _vm.createAccountIcon(),
                      },
                      expression:
                        "{\n                  src: createAccountIcon()\n              }",
                    },
                  ],
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass:
                  "content is-centered px-6 contract-card-format mb-2",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "has-text-centered is-size-4 has-text-white px-1 is-flex is-align-items-center is-justify-content-center",
                  },
                  [_c("b-skeleton", { attrs: { width: "100%" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "has-text-centered mt-3 px-6" }, [
                  _c(
                    "div",
                    { staticClass: "px-6" },
                    [_c("b-skeleton", { attrs: { width: "100%" } })],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass:
                  "content px-4 pt-2 description contract-card-format is-flex flex-direction-column is-align-items-center",
              },
              [_c("b-skeleton", { attrs: { width: "100%" } })],
              1
            ),
            _vm._v(" "),
            _c("section", { staticClass: "px-2 py-2 mb-1 border-grey" }, [
              _c("div", { staticClass: "level is-mobile mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "column level-left content creator-font m-0 p-0",
                  },
                  [_c("b-skeleton", { attrs: { width: "50%" } })],
                  1
                ),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [
        _c("img", {
          staticClass: "etherscan",
          attrs: {
            src: require("../../assets/logos/etherscan-logo-circle-white.svg"),
            color: "white",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }