var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-link",
    { attrs: { to: { name: "gallery-id", params: { id: _vm.editionId } } } },
    [
      _c(
        "figure",
        {
          directives: [
            {
              name: "lazy-container",
              rawName: "v-lazy-container",
              value: { selector: "img" },
              expression: "{ selector: 'img' }",
            },
          ],
        },
        [
          _c("img", {
            attrs: {
              "data-src": `${_vm.$cdnApi.defaults.baseURL}/cdn/images/network/${_vm.chainId}/edition/${_vm.editionId}/thumbnail`,
              "data-loading": "/branding/ko-load.svg",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }