var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level is-mobile is-vcentered" }, [
    _c("div", { staticClass: "level-left" }, [
      _vm.account
        ? _c(
            "div",
            { staticClass: "level-item" },
            [_c("account-image", { attrs: { address: _vm.account } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "level-item content" }, [
        _vm.nameOrShortEth && _vm.size === "is-large"
          ? _c(
              "h4",
              {
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
              },
              [_vm._v(_vm._s(_vm.nameOrShortEth))]
            )
          : _vm.nameOrShortEth
          ? _c(
              "h6",
              {
                staticClass: "has-text-weight-medium drop-mb",
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
              },
              [_vm._v(_vm._s(_vm._f("truncate")(_vm.nameOrShortEth, 15)))]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }