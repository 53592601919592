// Override default scroll behaviour
export default function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  // if the returned position is falsy or an empty object, will retain current scroll position.
  if (to.params.savePosition) {
    return {};
  }

  // scroll to anchor by returning the selector
  if (to.hash) {
    return {selector: to.hash};
  }

  // scroll to top by default
  return {x: 0, y: 0};
}
