<template>
  <div style="font-weight: 400; font-size: 0.85rem">
    <div class="level is-mobile is-family-tertiary">
      <div class="level-left">
        <div
          class="level-item has-padding-right-7 is-hidden-mobile"
          v-if="contractStats && contractStats.totalNumOfEditions"
        >
          <div>
            <p data-cy="Editions_count">
              {{ contractStats.totalNumOfEditions }} Editions
            </p>
          </div>
        </div>
        <div
          class="level-item has-padding-right-7"
          v-if="contractStats && contractStats.totalNumOfTokensSold !== '0'"
        >
          <div>
            <p data-cy="sales_count">
              {{ contractStats.totalNumOfTokensSold }} Sales
            </p>
          </div>
        </div>
        <div
          class="level-item has-padding-right-7"
          v-if="contractStats && contractStats.totalEthValueOfSales !== '0'"
        >
          <div>
            <p data-cy="total_count">
              <eth-with-fiat-price
                :price-in-eth="contractStats.totalEthValueOfSales"
              />
              Total
            </p>
          </div>
        </div>
        <div
          class="level-item has-padding-right-7"
          v-if="contractStats && royaltiesValue"
        >
          <div>
            <p data-cy="royalties_value" v-if="royaltiesValue">
              {{ royaltiesValue }}% CREATOR ROYALTIES
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="level is-mobile is-family-tertiary">
      <div class="level-left is-block-mobile">
        <div
          class="level-item-tablet has-padding-right-7 mb-5"
          v-if="contractStats && contractStats.timestamp"
        >
          <div>
            <p class="">
              Deployed
              <span>
                {{ (contractStats.timestamp * 1000) | moment("MMM Do YYYY") }}
              </span>
            </p>
          </div>
        </div>
        <div class="level-item has-padding-right-7 mb-5" v-if="contractStats">
          <div>
            <b-tag v-if="isBlockedRegistry" class="is-dark is-uppercase hand-pointer" @click.native="openModal('protected')">
              <span class="is-underlined-hover">Protected marketplaces</span>
            </b-tag>
            <b-tag v-if="isOwnershipRenounced" class="is-dark is-uppercase hand-pointer ml-1
            " @click.native="openModal('renounced')">
              <span class="is-underlined-hover">Ownership renounced</span>
            </b-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {CONTRACT_STATS} from '../../queries/creatorContracts';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import MarketplaceBlockerModal from '../modal/MarketplaceBlockerModal';
import OwnershipRenouncedModal from '../modal/OwnershipRenouncedModal';

export default {
  components: {EthWithFiatPrice},
  props: ['address', 'isBlockedRegistry', 'isOwnershipRenounced'],
  data() {
    return {
      contractStats: null,
      marketplaceBlockerModalInstance: null,
      ownershipRenouncedModalInstance: null,
      royaltiesValue: null
    };
  },
  computed: {
    ...mapState("web3Store", ["gqlClient"]),
  },
  mounted() {
    this.$apollo.addSmartQuery("contractStats", {
      client: this.gqlClient,
      query: CONTRACT_STATS,
      variables() {
        return {
          id: this.address,
        };
      },
      result({ data }) {
        if (data && data.contractStats) {
          this.contractStats = data.contractStats;
          this.royaltiesValue =
            data.contractStats.secondaryRoyaltyPercentage / 100000;
        }
      },
      error(error) {
        console.log("error", error);
      },
    });
  },
  methods: {
    openModal(type) {
      if (type === 'protected') {
        this.marketplaceBlockerModalInstance = this.$buefy.modal.open({
          parent: this,
          component: MarketplaceBlockerModal,
          canCancel: true,
          trapFocus: true
        })
      } else if (type === 'renounced') {
        this.ownershipRenouncedModalInstance = this.$buefy.modal.open({
          parent: this,
          component: OwnershipRenouncedModal,
          canCancel: true,
          trapFocus: true
        })
      }
    }
  }
};
</script>

<style>
.is-underlined-hover:hover {
  text-decoration: underline;
}
</style>
