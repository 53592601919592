<template>
  <section class="editorial-one has-background-light mb-6">
    <div class="editorial-one-mobile-image is-hidden-tablet">
      <img :src="cmsComponent.backgroundImage" aria-hidden="true" />
    </div>

    <section class="container section py-0">
      <div class="editorial-one-columns columns content is-vcentered">
        <div class="column is-6">
          <h2 class="remove-margin title is-size-7">
            <img src="/branding/KO-Icon-Blue.svg" class="editorial-one-logo" />
            <span class="editorial-one-subtitle has-text-weight-light">{{ cmsComponent.subtitle }}</span>
          </h2>
          <h2 class="remove-margin title is-size-3 has-text-weight-medium">{{ cmsComponent.title }}</h2>

          <div class="editorial-one-text-holder">
            <p class="editorial-one-text is-family-primary py-0">
              {{ cmsComponent.description }}
            </p>
          </div>

          <!--          <NuxtLink to="/journal/cms/stories/early-access-sales" class="editorial-one-button button is-primary mb-2">-->
          <!--            Learn more-->
          <!--          </NuxtLink>-->
          <NuxtLink :to="cmsComponent.buttonLink" class="editorial-one-button button"
                    @click.native="handleButtonClickedEvent(cmsComponent.buttonLink)">
            {{ cmsComponent.buttonLabel }}
          </NuxtLink>
          <div class="mt-5">
            <p v-for="credit in cmsComponent.editorial2Fields" :key="credit.title" class="is-size-7">
              <a :href="credit.link">
                {{ credit.title }}</a><br />
            </p>
            <!-- computed property cleaning the link needed here for these two nuxt links -->
          </div>
        </div>
        <img :src="cmsComponent.backgroundImage" aria-hidden="true" class="editorial-one is-hidden-mobile">

      </div>

    </section>
  </section>
</template>

<script>
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '~/services/analyticsEvents';

export default {
  props: ['cmsComponent'],
  methods: {
    async handleButtonClickedEvent(destination) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial1',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      })
    }
  }
}
</script>

<style lang="scss">
.editorial-one {
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center right;

  &-mobile-image {
    height: 55vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-columns {
    min-height: 500px;
  }

  &-logo {
    height: 10px;
    margin-right: 3px;
  }

  &-subtitle {
    opacity: 0.7;
  }

  &-text-holder {
    max-width: 432px;
    margin-top: 12px;
    margin-bottom: 40px !important;
    opacity: 0.7;
  }

  &-text {
    font-weight: 400;
  }

  &-button {
    display: block;
    max-width: 269px;
    height: 56px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1199px) {
  .editorial-one {
    background-size: 45%;
  }
}

@media screen and (max-width: 768px) {
  .editorial-one {
    background-image: none;

    &-columns {
      min-height: 0;
      padding: 48px 0;
    }
  }
}
</style>
