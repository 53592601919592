<template>
  <span v-if="shorter">{{new Date(timestampInSecs * 1000) | moment('MMM Do YY')}}</span>
  <span v-else>{{new Date(timestampInSecs * 1000) | moment('MMM Do YYYY')}}</span>
</template>

<script>
  export default {
    props: ['timestampInSecs', 'shorter']
  };
</script>
