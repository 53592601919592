var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "20px", "margin-right": "20px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "columns is-centered mb-0" }, [
          _c("div", { staticClass: "card-content column is-11 pb-0" }, [
            _c("div", { staticClass: "columns is-mobile mt-2" }, [
              _c(
                "div",
                { staticClass: "column is-3" },
                [
                  _vm.getEditionId
                    ? _c("modal-image-asset", {
                        staticClass: "has-margin-top-7",
                        attrs: {
                          "custom-class": "image is-square is-128x128",
                          "edition-number": _vm.getEditionId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "column" }, [
                _c(
                  "section",
                  {
                    staticClass: "has-padding-top-7 has-padding-bottom-7 ml-3",
                  },
                  [
                    _c("h2", { staticClass: "is-size-4 mb-1" }, [
                      _c("div", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.getModalTitle) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "h5",
                      { staticClass: "mb-5" },
                      [
                        _c("artwork-name-and-artist", {
                          attrs: {
                            metadata: _vm.edition.metadata,
                            "edition-number": _vm.edition.editionNumber,
                            "custom-class": "is-size-7",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content mb-3" }, [
              _c("hr", { staticClass: "p-0 m-0" }),
              _vm._v(" "),
              _vm.error === true
                ? _c("section", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-weight-medium is-size-5 mt-4 mb-0",
                      },
                      [
                        _vm._v(
                          "\n              The transaction has failed\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "has-text-weight-light pr-3 mb-5" },
                      [
                        _vm._v(
                          "\n              Please return to the edition page and try again.\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "is-flex mb-5" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "alert-circle", type: "is-danger" },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("Transaction failed"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm.operation === "cancel"
                ? _c("div", [
                    _vm.step === 0
                      ? _c("section", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-5 mt-4 mb-0",
                            },
                            [
                              _vm._v(
                                "\n                Are you sure you want to cancel this listing?\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "has-text-weight-light pr-3 mb-5" },
                            [
                              _vm._v(
                                "\n                You'll be asked to sign a transaction from your wallet that\n                will cancel this listing\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "is-flex mb-5" }),
                        ])
                      : _vm.step === 1
                      ? _c("section", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-5 mt-4 mb-0",
                            },
                            [
                              _vm._v(
                                "\n                Go to your wallet\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "has-text-weight-light pr-3 mb-5" },
                            [
                              _vm._v(
                                "\n                You'll be asked to review and confirm this transaction from\n                your wallet\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "is-flex mb-5" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.complete
                                    ? "check-circle-outline has-text-success"
                                    : "clock-outline has-text-warning-dark",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.complete
                                      ? "Transaction confirmed"
                                      : "Waiting for the transaction to be confirmed"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _c("div", [
                    _vm.step === 0
                      ? _c("section", [
                          _c(
                            "section",
                            {
                              staticClass:
                                "has-padding-top-7 has-padding-bottom-7",
                            },
                            [
                              _c(
                                "b-field",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    label: "Set ETH sale price",
                                    "custom-class": "my-2",
                                  },
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      placeholder: "0.001",
                                      expanded: "",
                                      autofocus: "",
                                      min: "0.001",
                                      type: "number",
                                      step: "0.001",
                                      "custom-class": "has-text-weight-light",
                                    },
                                    model: {
                                      value: _vm.form.salePrice,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "salePrice", $$v)
                                      },
                                      expression: "form.salePrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "is-muted is-small mt-1" },
                                [
                                  _vm._v(
                                    "\n                  Approximately.\n                  "
                                  ),
                                  _c("eth-with-fiat-price", {
                                    attrs: {
                                      "only-fiat": true,
                                      "price-in-eth": _vm.form.salePrice,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Set end date",
                                    "custom-class": "mb-2 mt-4",
                                  },
                                },
                                [
                                  _c("b-datetimepicker", {
                                    attrs: {
                                      placeholder: "Type or select a date...",
                                      icon: "calendar-today",
                                      "min-datetime": this.form.endDate,
                                      editable: "",
                                    },
                                    model: {
                                      value: _vm.form.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endDate", $$v)
                                      },
                                      expression: "form.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Summary",
                                    "custom-class": "mb-2 mt-4",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "content" }, [
                                    _c("table", { staticClass: "is-size-7" }, [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-weight-medium no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Listing price\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-right no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(_vm.form.salePrice) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-weight-medium no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          OpenSea fee (0.5%)\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-right no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          -" +
                                                _vm._s(
                                                  _vm.calculateSummary.openSeaFee.toFixed(
                                                    5
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-weight-medium no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Creator royalties (10%)\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-right no-border",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          -" +
                                                _vm._s(
                                                  _vm.calculateSummary.creatorRoyalties.toFixed(
                                                    5
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("hr", { staticClass: "p-0 my-2" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-flex is-justify-content-space-between has-text-weight-medium mt-4",
                            },
                            [
                              _c("p", [_vm._v("Potential earnings")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.calculateSummary.potentialEarnings
                                  ) + " ETH"
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm.step === 1
                      ? _c("section", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-5 mt-4 mb-0",
                            },
                            [
                              _vm._v(
                                "\n                Go to your wallet\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "has-text-weight-light pr-3 mb-5" },
                            [
                              _vm._v(
                                "\n                You'll be asked to approve the collection from your wallet.\n                You'll only need to approve each collection once.\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "is-flex mb-5" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.approved
                                    ? "check-circle-outline has-text-success"
                                    : "clock-outline has-text-warning-dark",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.approved
                                      ? "Transaction confirmed"
                                      : "Waiting for the transaction to be confirmed"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm.step === 2
                      ? _c("section", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-5 mt-4 mb-0",
                            },
                            [
                              _vm._v(
                                "\n                Go to your wallet\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "has-text-weight-light pr-3 mb-5" },
                            [
                              _vm._v(
                                "\n                You'll be asked to review and confirm this listing from your\n                wallet\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "is-flex mb-5" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.complete
                                    ? "check-circle-outline has-text-success"
                                    : "clock-outline has-text-warning-dark",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.complete
                                      ? "Trasaction confirmed"
                                      : "Waiting for the transaction to be confirmed"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm.step === 3
                      ? _c(
                          "section",
                          [
                            _c(
                              "b-field",
                              {
                                staticClass: "mb-5",
                                attrs: {
                                  label: "Summary",
                                  "custom-class": "mb-2 mt-4",
                                },
                              },
                              [
                                _c("div", { staticClass: "content" }, [
                                  _c("table", { staticClass: "is-size-7" }, [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "has-text-weight-medium no-border",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Listing price\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "has-text-right no-border",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.form.salePrice) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "has-text-weight-medium no-border",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Valid Until\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "has-text-right no-border",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.form.endDate) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "is-flex is-align-items-center is-justify-content-space-between",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "is-flex is-align-items-center",
                                  },
                                  [
                                    _vm.edition
                                      ? _c("share", {
                                          staticClass: "mt-2 is-inline-block",
                                          attrs: {
                                            type: "CC_EDITION",
                                            data: _vm.edition,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      outlined: "",
                                      tag: "a",
                                      type: "is-primary",
                                      target: "_blank",
                                      href: _vm.getOpenseaLink,
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        icon: "open-in-new",
                                        size: "is-small",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  View on OpenSea\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.error === true
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "div",
                { staticClass: "card-footer-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { expanded: "", type: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.operation === "cancel"
          ? _c("div", [
              _vm.step === 0
                ? _c("div", { staticClass: "card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "" },
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          },
                          [_vm._v(" Nevermind ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelListing()
                              },
                            },
                          },
                          [_vm._v("\n            End listing\n          ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.complete === true
                ? _c("div", { staticClass: "card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          },
                          [_vm._v("\n            Done\n          ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ])
          : _c("div", [
              _vm.step === 0
                ? _c("div", { staticClass: "card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "" },
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          },
                          [_vm._v(" Nevermind ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.listOnSecondary()
                              },
                            },
                          },
                          [_vm._v("\n            Create listing\n          ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm.step === 3
                ? _c("div", { staticClass: "card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "card-footer-item" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { expanded: "", type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          },
                          [_vm._v("\n            Done\n          ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }