var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-content has-padding-7" }, [
        _c("div", { staticClass: "content" }, [
          _c("section", { staticClass: "has-margin-7 py-7" }, [
            _c("p", { staticClass: "title is-3 is-family-secondary" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.title) +
                  "\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "mb-4 content notification" }, [
            _c("dl", [
              _vm._m(0),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.description) +
                    "\n                        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [
              _c(
                "b-button",
                {
                  attrs: { expanded: "", disabled: _vm.saving },
                  on: { click: _vm.close },
                },
                [
                  _vm._v(
                    "\n                        Nevermind\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button is-danger",
                  attrs: { expanded: "", disabled: _vm.saving },
                  on: { click: _vm.performAction },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.actionButton) +
                      "\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v("\n                            This action "),
      _c("span", { staticClass: "has-text-weight-semibold" }, [
        _vm._v("cannot"),
      ]),
      _vm._v(" be undone\n                        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }