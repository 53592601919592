var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content" }, [
      _vm.editions && _vm.editions.length > 0
        ? _c("div", [
            _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(_vm.editions, function (edition, $index) {
                return _c(
                  "div",
                  {
                    key: $index,
                    staticClass:
                      "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card",
                  },
                  [
                    _c("edition-card", {
                      staticClass: "has-margin-bottom-4",
                      attrs: {
                        edition: edition,
                        cover: true,
                        address: _vm.profile.address,
                        resolution: "card",
                      },
                      on: { "editions-refresh": _vm.refreshEditions },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _c(
            "div",
            { staticClass: "column is-fullwidth" },
            [
              _c("empty-state", {
                attrs: { message: "No items for sale on primary" },
              }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }