var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content card m-auto column is-half p-5" },
    [
      _c("h4", [_vm._v("\n    This contracts ownership is renounced\n  ")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-message",
        {
          staticClass: "is-size-7 my-5",
          attrs: { type: "is-warning", closable: false, duration: 0 },
        },
        [
          _c("p", [
            _vm._v(
              "\n      The creator of this contract has burned it – this means ownership has been transferred to the dead address.\n      "
            ),
            _c("br"),
            _vm._v(
              "\n      No more editions can be minted onto this contract and the creator can’t access or edit it anymore. However, creators still get royalties on any secondary sales.\n    "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column mt-2" },
          [
            _c(
              "b-button",
              {
                attrs: { expanded: "", outlined: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("\n        Close\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("hr", { staticClass: "m-0 mt-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }