<template>
  <!-- small icons and names-->
  <div class="level is-mobile is-vcentered" :class="customClass">
    <div class="level-left">
      <div class="level-item" v-if="artistAccount && imageVisible">
        <account-image :address="artistAccount"></account-image>
      </div>
      <div
        class="level-item"
        style="margin-left: -1.5rem"
        v-for="(collab, index) in splicedCollabs"
        :key="index"
        v-if="filteredCollabs.length > 0 && imageVisible"
      >
        <account-image :address="collab"></account-image>
      </div>
      <div
        class="level-item has-text-weight-semibold"
        v-if="filteredCollabs.length > 2"
        style="margin-left: -0.5rem"
      >
        +{{ filteredCollabs.length - 2 }}
      </div>
      <div class="level-item">
        <section v-if="!loadingUser">
          <h4
            v-if="name && size === 'is-large'"
            :class="{ ccArtworkInfoText: isCc }"
          >
            {{ nameOrShortEth }}
          </h4>
          <h6
            v-else
            class="is-family-secondary has-text-weight-medium h6-width"
            :class="{ ccArtworkInfoText: isCc }"
          >
            {{ nameOrShortEth }}
          </h6>
        </section>
        <div v-else class="pl-2">
          <b-skeleton width="3rem"></b-skeleton>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import _uniq from 'lodash/uniq';
import AccountImage from '../common/AccountImage';
import { notInExclusions } from '../../services/utils';

export default {
  components: { AccountImage },
  props: ['artistAccount', 'name', 'collaborators', 'size', 'noImage', 'isCc', 'customClass'],
  data() {
    return {
      displayName: '',
      imageVisible: true,
      loadingUser: true
    };
  },
  computed: {
    nameOrShortEth() {
      if (this.displayName && this.displayName.startsWith('0x')) {
        return this.$options.filters.shortEth(this.displayName);
      }
      return this.displayName;
    },
    filteredCollabs() {
      return _uniq(this.collaborators)
        .filter(notInExclusions) // remove hard coded excludes
        .filter((address) => {
          // remove the primary artist
          return address.toLowerCase() !== this.artistAccount.toLowerCase();
        });
    },
    splicedCollabs() {
      return _uniq(this.collaborators)
        .filter(notInExclusions) // remove hard coded excludes
        .filter((address) => {
          // remove the primary artist
          return address.toLowerCase() !== this.artistAccount.toLowerCase();
        })
        .splice(0, 2);
    }
  },
  watch: {
    async artistAccount(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.loadUser();
      }
    }
  },
  async mounted() {
    this.loadingUser = true
    await this.loadUser();
    this.setImageVisible();
  },
  methods: {
    notInExclusions,
    async loadUser() {
      let name = this.name;
        try {
          const res = await this.$store.dispatch(
            'userStore/getUser',
            this.artistAccount
          );
          if (res.username) {
            name = res.username;
          } else {
            name = this.artistAccount;
          }
        } catch (err) {
          name = this.artistAccount;
        }

      this.displayName = name;
      this.loadingUser = false
    },
    setImageVisible() {
      if (this.noImage) {
        this.imageVisible = false;
      } else {
        this.imageVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ccArtworkInfoText {
  color: white;
}

.h6-width {
  max-width: 250px;
  margin-bottom: -0.2rem !important;
}
</style>
