<template>
  <modal-template :edition="offer.edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Withdraw offer
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="offer.edition.metadata" :edition-number="offer.edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <highest-offer-label :wei-value="offer.weiValue" :bidder="offer.bidder.address">
      </highest-offer-label>
    </div>

    <template slot="action-feedback-label">
      Offer withdrawn
    </template>

    <template slot="action-button">
      <b-button class="button is-danger"
                expanded
                :disabled="transactionHash"
                @click="withdrawOffer">
        Withdraw offer
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import HighestOfferLabel from '../../offers/HighestOfferLabel';

export default {
  components: {HighestOfferLabel, ArtworkNameAndArtist, ModalTemplate},
  props: ['offer'],
  data() {
    return {
      transactionHash: null
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async withdrawOffer() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/withdrawEditionOffer', {
          edition: this.offer.edition,
          version: this.offer.version
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
