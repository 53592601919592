var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "columns is-mobile" }, [
            _c(
              "div",
              { staticClass: "column is-one-third" },
              [
                _vm._t("main-content", function () {
                  return [
                    _vm.getEditionId
                      ? _c("modal-image-asset", {
                          staticClass: "has-margin-top-7",
                          attrs: { "edition-number": _vm.getEditionId },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column" }, [
              _c(
                "section",
                { staticClass: "has-padding-top-7 has-padding-bottom-7" },
                [
                  _c(
                    "h2",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [_vm._t("content-title")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "h5",
                    { staticClass: "mb-5" },
                    [_vm._t("content-sub-title")],
                    2
                  ),
                  _vm._v(" "),
                  _vm._t("total-picker"),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "section",
                { staticClass: "has-padding-top-7 has-padding-bottom-7" },
                [
                  _vm._t("content-body"),
                  _vm._v(" "),
                  _vm._t("content-balance", function () {
                    return [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-bold" },
                        [
                          _c("b-icon", {
                            staticClass: "ml-1",
                            attrs: { size: "is-small", icon: "wallet-outline" },
                          }),
                          _vm._v(" Your balance is "),
                          _c("account-balance"),
                        ],
                        1
                      ),
                    ]
                  }),
                  _vm._v(" "),
                  _vm._t("secondary-content"),
                  _vm._v(" "),
                  _vm.transactionHash
                    ? _c("section", { staticClass: "level is-mobile pt-2" }, [
                        _c("div", { staticClass: "level-left" }, [
                          _c(
                            "div",
                            { staticClass: "level-item" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "check-circle-outline",
                                  size: "is-small",
                                },
                              }),
                              _vm._v("\n                 \n                "),
                              _c(
                                "span",
                                { staticClass: "is-uppercase is-size-7" },
                                [_vm._t("action-feedback-label")],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "level-right" }, [
                          _c("div", { staticClass: "level-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "is-muted-7 is-family-tertiary",
                                attrs: {
                                  href: _vm.etherscanTxLink(
                                    _vm.transactionHash
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  [view tx]\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("primary-action-extras"),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [
              _vm._t("action-button-nevermind", function () {
                return [
                  _c(
                    "b-button",
                    { attrs: { expanded: "" }, on: { click: _vm.close } },
                    [_vm._v("\n            Nevermind\n          ")]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer-item" },
            [_vm._t("action-button")],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }