import gql from 'graphql-tag';
import {COLLECTOR, LISTING_FIELDS, METADATA_FIELDS, TOKEN_OFFER, CREATOR_CONTRACT_FIELDS} from './_commonModels';

export const TOKENS_QUERY = gql(`
  query tokens($first: Int!, $skip: Int!) {
    tokens(first: $first, skip: $skip, orderBy: birthTimestamp, orderDirection: desc subgraphError: allow) {
      id
      version
      primaryValueInEth
      birthTimestamp
      editionNumber
      artistAccount
      ${COLLECTOR('currentOwner')}
      ${METADATA_FIELDS}
    }
  }
`);

export const TOKENS_WITH_INCLUDE = (includedTokens, sortByBirthTimestamp = true) => {
  const whereQuery = `{
      ${includedTokens && includedTokens.length > 0 ? `id_in: [${includedTokens}],` : ''}
      currentOwner: $owner,
    }`;

  let orderQuery = '';
  if (sortByBirthTimestamp) {
    orderQuery = 'orderBy: birthTimestamp, orderDirection: desc,';
  }

  return gql(`
  query tokens($first: Int!, $skip: Int!, $owner: String!) {
    tokens(first: $first, skip: $skip, ${orderQuery} where: ${whereQuery} subgraphError: allow) {
      id
      version
      salesType
      primaryValueInEth
      birthTimestamp
      editionNumber
      artistAccount
      lastSalePriceInEth
      isListed
      listPrice
      ${COLLECTOR('currentOwner')}
      edition {
        id
        collaborators
        totalSupply
        totalAvailable
      }
      ${METADATA_FIELDS}
      ${LISTING_FIELDS}
    }
  }
`);
};

const TOKEN_BY_ID_DEFINITION = `
  tokenById($id: String!) {
    tokenById: token(id: $id subgraphError: allow) {
      id
      version
      salesType
      primaryValueInEth
      birthTimestamp
      editionNumber
      tokenURI
      artistAccount
      edition {
        id
        version
        collaborators
        totalSupply
        totalAvailable
        creatorContract {
          ${CREATOR_CONTRACT_FIELDS}
        }
      }
      ${COLLECTOR('currentOwner')}
      ${COLLECTOR('lister')}
      listPrice
      isListed
      listingTimestamp
      lastSalePriceInEth
      ${METADATA_FIELDS}
      ${LISTING_FIELDS}
      tokenEvents(orderBy: timestamp, orderDirection:desc) {
        id
        name
        timestamp
        ethValue
      }
    }
  }
`;

export const TOKEN_BY_ID_QUERY = gql(`
  query ${TOKEN_BY_ID_DEFINITION}
`);

export const TOKEN_INSIGHTS_QUERY = gql(`
  query tokenById($id: String!) {
    tokenById: token(id: $id subgraphError: allow) {
      id
      version
      salesType
      primaryValueInEth
      lastSalePriceInEth
      lastTransferTimestamp
      totalPurchaseValue
      totalPurchaseCount
      birthTimestamp
      editionNumber
      edition {
        id
        collaborators
      }
      ${COLLECTOR('currentOwner')}
      ${COLLECTOR('lister')}
      listPrice
      isListed
      listingTimestamp
      tokenEvents(orderBy: timestamp, orderDirection:desc) {
        id
        name
        timestamp
        ethValue
      }
    }
  }
`);

export const TOKEN_FIELDS = `
id
salesType
version
primaryValueInEth
birthTimestamp
editionNumber
tokenURI
artistAccount
${COLLECTOR('currentOwner')}
${COLLECTOR('lister')}
listPrice
isListed
listingTimestamp
lastSalePriceInEth
edition {
  id
  collaborators
}
${METADATA_FIELDS}
${LISTING_FIELDS}
`;

export const EDITION_TRANSFER_HISTORY = gql(`
query editionTransferHistory($editionId:String!) {
  tokens(where:{editionNumber:$editionId} subgraphError: allow) {
    id
    version
    transfers {
      id
      from
      to
      tokenId
      timestamp
      transactionHash
    }
  }
}`);

export const TOKENS_BY_OWNER_QUERY = gql(`
  query   tokensByOwner($id: String!, $first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!) {
    tokensByOwner: tokens(first: $first, skip: $skip,
      where: {
        currentOwner: $id
      },
      orderBy: $orderBy
      orderDirection: $orderDirection
      subgraphError: allow
    ) {
      ${TOKEN_FIELDS}
      edition {
        id
        collaborators
      }
    }
  }
`);

export const TOKENS_BY_OWNER_QUERY_WITH_EXCLUDE = (excludedTokens) => {
  const whereQuery = `{
      ${excludedTokens && excludedTokens.length > 0 ? `id_not_in: [${excludedTokens}],` : ''}
      currentOwner: $owner
    }`;

  return gql(`
  query tokensByOwner($owner: String!, $first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!) {
    tokensByOwner: tokens(first: $first, skip: $skip,
      where: ${whereQuery},
      orderBy: $orderBy
      orderDirection: $orderDirection
      subgraphError: allow
    ) {
      ${TOKEN_FIELDS}
      edition {
        id
        collaborators
        totalAvailable
        totalSupply
        creatorContract {
          ${CREATOR_CONTRACT_FIELDS}
        }
      }
    }
  }
`);
};

export const TOKENS_BY_OWNER_LISTED = () => {
  const whereQuery = `{
      currentOwner: $owner,
      isListed: true
    }`;

  return gql(`
  query tokensByOwner($owner: String!, $first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!) {
    tokensByOwner: tokens(first: $first, skip: $skip,
      where: ${whereQuery},
      orderBy: $orderBy
      orderDirection: $orderDirection
      subgraphError: allow
    ) {
      ${TOKEN_FIELDS}
      edition {
        id
        collaborators
        totalAvailable
        totalSupply
        creatorContract {
          isHidden
        }
      }
    }
  }
  `)
}

export const TOKEN_CURRENT_OWNERS_FOR_EDITION = () => {
  return gql(`
  query tokenCurrentOwnersByEdition($editionId:String!){
    tokens: editions(where: {id: $editionId}, orderBy:id, orderDirection:"asc" subgraphError: allow) {
      tokens {
        id
        version
        editionNumber
        ${COLLECTOR('currentOwner')}
        lastTransferTimestamp
        lastSalePriceInEth
        }
    }
  }
  `)
}

// TODO WHY WONT THIS WORK? WORKS FOR CURRENT OWNER BUT BREAKS ON EDITION. NEEDS LOOKING INTO
// export const TOKENS_OWNED_FOR_EDITION_BY_USER = () => {
//   return gql(`
//     query tokensOwnedForEditionByUser($edition:String!, $account:String!){
//       tokens: tokens(where: {
//         edition_: {id: $edition}
//         currentOwner_: {id: $account}
//       } subgraphError: allow) {
//         id
//         currentOwner {
//           id
//         }
//         edition {
//           id
//         }
//       }
//     }
//   `)
// }

export const TOKEN_OWNERS_FOR_EDITION = gql`
  query GetTokenOwnersForEditionWithOffers($editionNumber:String!){
    tokens: tokens(where: {editionNumber: $editionNumber}, orderBy:id, orderDirection:"asc" subgraphError: allow) {
      id
      version
      editionNumber
      ${COLLECTOR('currentOwner')}
      ${TOKEN_OFFER}
      lastTransferTimestamp
      transferCount
      birthTimestamp
      primaryValueInEth
      lastSalePriceInEth
      isListed
      listPrice
      salesType
      ${LISTING_FIELDS}
      # get last token transfer for each token as well
      transfers(first:1, orderBy:timestamp, orderDirection:"desc" subgraphError: allow){
        id
        transactionHash
        from
        to
        timestamp
      }
    }
  }
`;

export const LISTED_TOKENS_FOR_EDITION = gql`
  query GetListedTokensForEdition($editionNumber:String!){
    tokens: tokens(where: {editionNumber: $editionNumber, isListed: true}, orderBy: "listPrice", orderDirection:"asc" subgraphError: allow) {
      id
      version
      editionNumber
      isListed
      listPrice
      salesType
      ${LISTING_FIELDS}
    }
  }
`;

export const JUST_MISSED = gql(`query justMissed($first: Int!) {
     justMissed: tokens(first: $first, orderBy: lastTransferTimestamp, orderDirection: desc, subgraphError: allow, where: {isListed: true}) {
    ${TOKEN_FIELDS}
  }
}`);
