var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticClass: "section has-background-white has-text-dark",
        staticStyle: {
          "min-height": "300px",
          "padding-top": "150px",
          "padding-bottom": "150px",
        },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns is-centered" }, [
            _c("div", { staticClass: "column is-half-desktop" }, [
              _c("div", { staticClass: "content" }, [
                _c("h1", { staticClass: "title has-text-dark" }, [
                  _vm._v("Don't miss out on DROPS"),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "has-margin-top-1",
                    attrs: {
                      id: "mc-embedded-subscribe-form",
                      name: "mc-embedded-subscribe-form",
                      action:
                        "https://knownorigin.us19.list-manage.com/subscribe/post?u=84b0312927af7712ac2e6dd5a&id=70433f1407",
                      method: "post",
                      target: "_blank",
                      novalidate: "",
                    },
                  },
                  [
                    _c("div", { staticClass: "field has-addons" }, [
                      _c("div", { staticClass: "control is-expanded" }, [
                        _c("input", {
                          staticClass: "input is-medium",
                          attrs: {
                            type: "email",
                            value: "",
                            name: "EMAIL",
                            id: "mce-EMAIL",
                            placeholder: "Your email address",
                            required: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            left: "-5000px",
                          },
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "text",
                              name: "b_84b0312927af7712ac2e6dd5a_ebee270c72",
                              tabindex: "-1",
                              value: "",
                              hidden: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          staticClass: "button is-medium is-dark",
                          attrs: {
                            type: "submit",
                            value: "Subscribe",
                            name: "subscribe",
                            id: "mc-embedded-subscribe",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }