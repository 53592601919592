var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "content",
        staticStyle: { "margin-left": "40px", "margin-right": "40px" },
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-content has-padding-7" }, [
            _c("div", { attrs: { id: "wert-widget" } }),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }