var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "header-editorial-banner editorial-background-mobile section",
    },
    [
      _c(
        "section",
        { staticClass: "editorial3-desktop editorial-background-desktop p-5" },
        [
          _c(
            "div",
            { staticClass: "columns content is-centered is-vcentered" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-editorial-embed column is-6 is-two-fifths-desktop mr-6",
                },
                [
                  _vm.componentData
                    ? _c("edition-card", {
                        attrs: {
                          edition: _vm.componentData,
                          cover: true,
                          resolution: "card",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleCardClickedEvent.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      })
                    : _c("EditionCardSkeleton"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "header-editorial-text column has-text-left is-6 is-two-fifths-desktop pl-6",
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "remove-margin title is-size-5 has-text-weight-light",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cmsComponent.subtitle) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("h2", { staticClass: "remove-margin title is-size-2" }, [
                    _vm._v(_vm._s(_vm.cmsComponent.title)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "pt-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cmsComponent.description) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.cmsComponent.buttonLink
                    ? _c(
                        "div",
                        {
                          staticClass: "pt-2",
                          on: {
                            click: function ($event) {
                              return _vm.handleButtonClickedEvent(
                                _vm.cmsComponent.buttonLink
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "n-link",
                            {
                              staticClass: "button is-primary",
                              attrs: { to: _vm.cmsComponent.buttonLink },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.cmsComponent.buttonLabel) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "editorial3-mobile editorial-background-mobile" },
        [
          _c(
            "div",
            { staticClass: "columns content is-centered is-vcentered" },
            [
              _c(
                "div",
                { staticClass: "column has-text-left is-two-fifths pr-3" },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "remove-margin title is-size-6 has-text-weight-light has-text-centered",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cmsComponent.subtitle) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass:
                        "remove-margin title is-size-2 has-text-centered",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cmsComponent.title) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column has-text-centered is-one-third" },
                    [
                      _vm.componentData
                        ? _c("edition-card", {
                            attrs: {
                              edition: _vm.componentData,
                              cover: true,
                              resolution: "card",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handleCardClickedEvent.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          })
                        : _c("EditionCardSkeleton"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "has-text-centered pt-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("truncate")(_vm.cmsComponent.description, 120)
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "has-text-centered pt-2",
                      on: {
                        click: function ($event) {
                          return _vm.handleButtonClickedEvent(
                            _vm.cmsComponent.buttonLink
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "n-link",
                        {
                          staticClass: "button is-primary",
                          attrs: { to: _vm.cmsComponent.buttonLink },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.cmsComponent.buttonLabel) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }