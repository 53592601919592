var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fileDimension
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(_vm.fileDimension) +
              " " +
              _vm._s(_vm.fileSize) +
              "\n    "
          ),
          _vm.exifData
            ? _c(
                "span",
                {
                  staticClass: "is-clickable is-size-7",
                  on: {
                    click: function ($event) {
                      _vm.expanded = !_vm.expanded
                    },
                  },
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "arrow-expand-down", size: "is-small" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.exifData && _vm.expanded,
          expression: "exifData && expanded",
        },
      ],
      staticClass: "mb-2 mt-2",
      domProps: { innerHTML: _vm._s(_vm.exifData) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }