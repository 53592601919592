const {editionStartDatePassed} = require('./utils');
const {isCreatorContract, splitCreatorEditionId} = require('./CreatorContractUtils');
const { isBuyNowAndOpenSalesType } = require('./SaleTypes');

function isNeverEndingEdition(edition) {
  return edition.endDate === '0' || edition.endDate === 0
}

function endDatePassed(edition) {
  if (isNeverEndingEdition(edition)) {
    return false
  }
  return edition && editionStartDatePassed(edition.endDate);
}

module.exports = {
  isNotSoldOutPrimary(edition) {
    if (edition && parseInt(edition.remainingSupply) > 0) {
      const statement = (parseInt(edition.totalAvailable) - parseInt(edition.totalSupply) > 0)
      if (isBuyNowAndOpenSalesType(edition.salesType)) {
        return (!(edition && endDatePassed(edition)) && statement)
      } else {
        return statement
      }
    }
    return false;
  },

  isSoldOutPrimary(edition) {
    if (isBuyNowAndOpenSalesType(edition.salesType)) {
      return ((edition && endDatePassed(edition)) || (parseInt(edition.totalAvailable) - parseInt(edition.totalSupply) < 1))
    } else {
      return edition && parseInt(edition.remainingSupply) === 0;
    }
  },

  availability(edition) {
    return parseInt(edition.totalAvailable) - parseInt(edition.totalSupply);
  },

  scheduledSale(edition) {
    return edition && edition.startDate > 0;
  },

  startDatePassed(edition) {
    return edition && editionStartDatePassed(edition.startDate);
  },

  isNeverEndingEdition,

  endDatePassed,

  // Calculates an edition ID relative to sales and edition size, i.e. first token is #1 etc
  calculateIterativeEditionId(edition, tokenId) {
    if (isCreatorContract(edition)) {
      // TODO probably some better maths could be done here
      const {editionId} = splitCreatorEditionId(tokenId)
      return Math.abs(parseInt(editionId) - parseInt(edition.editionNmber) - edition.totalAvailable)
    } else {
      return tokenId - edition.id + (edition.version - 2)
    }
  },

  // calculates if id is a cc edition id
  // checks the contract address splitter '-' exists and id length is longer than the minimum cc id length
  isCcEditionId(id) {
    return id.includes('-') && id.length > 48
  },

  doesMetadataExist(token) {
    return (token && token.metadata && token.metadata.name)
  },

  hasOpenEditionEndedWithNoSales(edition) {
    return endDatePassed(edition) && parseInt(edition.totalSupply) === 0 && edition.isOpenEdition
  }
}
