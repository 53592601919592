<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">
      <div class="card-content has-padding-7">
        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <h2 style="margin-bottom: 5px">
              Collaboration
            </h2>
            <h5 class="mb-5">
              Shows splits for this edition
            </h5>
          </section>
          <hr class="is-marginless" />

          <section class="mb-4 content" v-if="collective && collective.recipients"
                   style="max-height: 450px; overflow: scroll">
            <table class="table is-small is-striped">
              <tbody>
              <tr v-for="(recipient, index) in collective.recipients" :key="index">
                <td>
                  <user-profile-lookup :artist-account="recipient" :metadata-artist="recipient">
                  </user-profile-lookup>
                </td>
                <td>
                  <span class="subtitle">{{ collective.splits[index] / 100000 }}%</span>
                </td>
              </tr>
              </tbody>
            </table>
            <p class="is-muted-7">
              <a :href="etherscanTxLink(collective.transactionHash)" target="_blank">[view tx]</a>
            </p>
          </section>

          <section class="mb-4 content" v-else-if="edition"
                   style="max-height: 450px; overflow: scroll">
            <table class="table is-small is-striped">
              <tbody>
              <tr>
                <td>
                  <user-profile-lookup :artist-account="edition.artistAccount">
                  </user-profile-lookup>
                </td>
                <td>
                  <span class="subtitle">{{ (parseInt(edition.artistCommission) / (parseInt(edition.artistCommission) + parseInt(edition.optionalCommissionRate)) * 100).toFixed(0) }}%</span>
                </td>
              </tr>
              <tr>
                <td>
                  <user-profile-lookup :artist-account="edition.optionalCommissionAccount">
                  </user-profile-lookup>
                </td>
                <td>
                  <span class="subtitle">{{ (parseInt(edition.optionalCommissionRate) / (parseInt(edition.artistCommission) + parseInt(edition.optionalCommissionRate)) * 100).toFixed(0) }}%</span>
                </td>
              </tr>
              </tbody>
            </table>
            <p class="is-muted-7" v-if="collective">
              <a :href="etherscanTxLink(collective.transactionHash)" target="_blank">[view tx]</a>
            </p>
          </section>
        </div>

        <div class="card-footer">
          <div class="card-footer-item">
            <b-button expanded @click="close">
              Close
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import _head from 'lodash/head';
import {GET_COLLECTIVE_FOR_EDITION} from '../../queries/collectiveQueries';
import UserProfileLookup from '../UserProfileLookup';
import {EDITION_BY_ID_QUERY} from '../../queries/editionQueries';
import { getNetworkClient } from '../../utils/apolloUtils';

export default {
  components: {UserProfileLookup},
  props: ['editionId'],
  data() {
    return {
      collective: {},
      edition: null
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    ...mapGetters('web3Store', [
      'etherscanTxLink'
    ])
  },
  async mounted() {
    const client = getNetworkClient(this.chainId, this.$apollo.provider);

    const {data} = await client.query({
      query: GET_COLLECTIVE_FOR_EDITION,
      variables: {
        editions: [this.editionId]
      }
    });

    this.collective = _head(data.collectives);

    const res = await client.query({
      query: EDITION_BY_ID_QUERY,
      variables: {
        id: this.editionId
      }
    });

    this.edition = res.data && _head(res.data.editionById);
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
