<template>
  <span v-if="views">
    <b-icon icon="eye-outline" size="is-small"></b-icon>
    <span class="has-padding-left-7">{{ views }}</span>
  </span>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: ['editionId'],
  computed: {
    ...mapState('web3Store', ['chainId'])
  },
  data() {
    return {
      views: null
    };
  },
  async mounted() {
    try {
      if (this.editionId) {
        const {views} = await this.$cacheApi.$get(`/network/${this.chainId}/pagecount/edition/${this.editionId}`);
        this.views = views;
      }
    } catch (e) {
      console.log('Problem with API', e);
    }
  }
};
</script>
