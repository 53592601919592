<template>
  <div class="columns is-multiline" v-if="ids && ids.length > 0">
    <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card" v-for="id in ids" :key="id">
      <edition-wrapper :id="id" :cover="true" resolution="card" size="is-small" :address="profile ? profile.address : null"></edition-wrapper>
    </div>
  </div>

  <div v-else>
    <empty-state message="No collection"></empty-state>
  </div>
</template>
<script>

import EditionWrapper from '../EditionWrapper';
import EmptyState from '../common/EmptyState';

export default {
  components: {EmptyState, EditionWrapper},
  props: ['ids', 'profile'],
  data() {
    return {};
  }
}
</script>
