<template>
  <section>
    <div v-if="!hasAuthToken">
      <empty-state message="You need to sign in to view your active offers"></empty-state>
      <div class="is-flex is-flex-direction-column is-justify-content-center px-6">
        <b-button
          @click="signInAndGetAuthToken"
          type="is-primary"
          outlined>
          Sign in
        </b-button>
      </div>
    </div>
    <b-table :data="sortedOffers" v-else-if="sortedOffers && sortedOffers.length > 0">
      <b-table-column field="edition.id" label=" " width="20%" v-slot="props">
        <n-link :to="buildLink(props.row)">
          <modal-image-asset :edition-number="props.row.edition.id"
                             :metadata="props.row.edition.metadata"
                             v-if="props.row.edition">
          </modal-image-asset>
          <span v-if="props.row.edition" class="is-size-7">
            <artwork-name-and-artist :metadata="props.row.edition.metadata" :edition-number="props.row.edition.id">
            </artwork-name-and-artist>
          </span>
          <div class="is-muted is-size-7">
            {{ props.row.type }} / V{{ props.row.version }}
            <span v-if="props.row.token">
              / ST{{ props.row.token.salesType }} / ID{{ props.row.token.id }}
            </span>
            <span v-else-if="props.row.edition">
              / ST{{ props.row.edition.salesType }} / ID{{ props.row.edition.id }}
            </span>
          </div>
        </n-link>
      </b-table-column>
      <b-table-column field="label" label="Action" centered v-slot="props">
        <b-tag
          v-if="props.row.label === 'Offer placed'"
          class="is-uppercase is-family-primary"
          type="is-primary"
        >
          {{ props.row.label }}
        </b-tag>
        <b-tag
          v-if="props.row.label === 'Offer received'"
          class="is-uppercase is-family-primary"
          type="is-dark"
        >
          {{ props.row.label }}
        </b-tag>
      </b-table-column>
      <b-table-column field="weiValue" label="Offer" centered v-slot="props">
        <eth-with-fiat-price :price-in-wei="props.row.weiValue" />
      </b-table-column>
      <b-table-column field="currentOwner.address" label="Owner" centered v-slot="props">
        <profile-name-link :eth-account="props.row.currentOwner.address"></profile-name-link>
      </b-table-column>
      <b-table-column field="bidder.address" label="Offer by" centered v-slot="props">
        <profile-name-link :eth-account="props.row.bidder.address"></profile-name-link>
      </b-table-column>
      <b-table-column field="action" label="" v-slot="props">

        <!-- Actions if offer placed -->
        <span v-if="props.row.label === 'Offer placed'">
            <!-- only support increases in secondary market for now -->
            <b-button type="is-primary"
                      size="is-small"
                      v-if="props.row.type === 'Token' && isLoggedInBidder(props.row) && props.row.version === 2"
                      @click="increaseOffer(props.row)">
              Increase offer
            </b-button>
            <b-button type="is-danger"
                      size="is-small"
                      outlined
                      v-if="isWithdrawalEnabled(props.row)"
                      @click="withdrawOffer(props.row)">
              Withdraw offer
            </b-button>
            <span class="has-text-info" v-if="showBidCanBeWithdrawnLabel(props.row)">
              Bid can be withdrawn <date-from :timestamp-in-secs="props.row.lockedUntil"></date-from>
            </span>
        </span>

        <!-- Actions if offer received -->
        <span v-if="props.row.label === 'Offer received'">

          <!-- Token offer received -->
          <span v-if="props.row.type === 'Token'">
            <b-button type="is-primary"
                      size="is-small"
                      v-if="!isReserveAuctionSalesType(props.row.token.salesType) && isLoggedInCurrentOwner(props.row)"
                      @click="acceptOffer(props.row)">
              Accept offer
            </b-button>
            <b-button type="is-danger"
                      size="is-small"
                      outlined
                      v-if="!isReserveAuctionSalesType(props.row.token.salesType) && isLoggedInCurrentOwner(props.row)"
                      @click="rejectOffer(props.row)">
              Reject offer
            </b-button>
          </span>

          <!-- Edition offers received -->
          <span v-if="props.row.type === 'Edition'">
            <b-button type="is-primary"
                      size="is-small"
                      v-if="!isReserveAuctionSalesType(props.row.edition.salesType) && isLoggedInCurrentOwner(props.row) && props.row.edition.remainingSupply !== '0'"
                      @click="acceptOffer(props.row)">
              Accept offer
            </b-button>
            <b-button type="is-danger"
                      size="is-small"
                      outlined
                      v-if="!isReserveAuctionSalesType(props.row.edition.salesType) && isLoggedInCurrentOwner(props.row) && props.row.edition.remainingSupply !== '0'"
                      @click="rejectOffer(props.row)">
              Reject offer
            </b-button>
            <b-tag v-if="props.row.edition.remainingSupply === '0'" type="is-light">
              Sold out
            </b-tag>
          </span>

          <span class="has-text-info" v-if="offerBelowReserve(props.row)">
            Current bid below reserve
          </span>
        </span>

        <!-- Common actions and labels -->

        <!-- Emergency withdrawal state -->
        <b-button type="is-danger"
                  size="is-small"
                  outlined
                  v-if="isInForcedWithdrawalReserveAuction(props.row)"
                  @click="withdrawOffer(props.row)">
          Withdraw offer
        </b-button>

        <!-- Result a 24hr reserve auction -->
        <b-button type="is-primary"
                  size="is-small"
                  v-if="canResultReserveAuction(props.row)"
                  @click="resultReserveAuction(props.row)">
          Complete auction
        </b-button>
        <!-- 24hr reserve auction in progress -->
        <span class="has-text-info" v-if="auctionHasStarted(props.row)">
          Auction has started
        </span>

      </b-table-column>
    </b-table>
    <div v-else>
      <empty-state message="No active offers"></empty-state>
    </div>
  </section>
</template>
<script>
import _get from 'lodash/get';
import {mapState, mapGetters} from 'vuex';
import ProfileNameLink from '../avatar/ProfileNameLink';
import WithdrawEditionOffer from '../modal/edition/WithdrawEditionOffer';
import WithdrawTokenOffer from '../modal/token/WithdrawTokenOffer';
import PlaceTokenOffer from '../modal/token/PlaceTokenOffer.vue';
import RejectEditionOffer from '../modal/edition/RejectEditionOffer';
import RejectTokenOffer from '../modal/token/RejectTokenOffer';
import AcceptEditionOffer from '../modal/edition/AcceptEditionOffer';
import AcceptTokenOffer from '../modal/token/AcceptTokenOffer';
import EmptyState from '../common/EmptyState';
import {OFFERS_MADE_FOR_ACCOUNT, OFFERS_RECEIVED_FOR_ACCOUNT} from '../../queries/offersQueries';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import ModalImageAsset from '../asset-renders/ModalImageAsset';
import DateFrom from '../../components/common/DateFrom';
import {isReserveAuctionSalesType} from '../../services/SaleTypes';
import ReserveAuctionUtils, {isKoResulter} from '../../services/ReserveAuctionUtils';
import ResultEditionReserveAuction from '../modal/edition/ResultEditionReserveAuction';
import ResultTokenReserveAuction from '../modal/token/ResultTokenReserveAuction';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    DateFrom, ModalImageAsset, EthWithFiatPrice, ArtworkNameAndArtist, EmptyState, ProfileNameLink
  },
  props: ['address'],
  data() {
    return {
      offersPlaced: [],
      offersReceived: []
    };
  },
  computed: {
    ...mapState('web3Store', [
      'gqlClient',
      'account',
      'chainId'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'etherscanAddressLink',
      'hasAuthToken'
    ]),
    sortedOffers() {
      return (this.offersPlaced || [])
        .map((element) => {
          element.label = 'Offer placed';
          return element;
        })
        .concat(
          (this.offersReceived || [])
            .map((element) => {
              element.label = 'Offer received';
              return element;
            })
        );
    },
    isLoggedInAccountViewingPage() {
      return this.isLoggedInAccount(this.address);
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('offersPlaced', {
      client: this.gqlClient,
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000, // ms
      deep: false,
      query: OFFERS_MADE_FOR_ACCOUNT,
      variables() {
        return {
          address: this.address.toLowerCase()
        };
      },
      error(error) {
        console.log('error', error);
      }
    });

    this.$apollo.addSmartQuery('offersReceived', {
      client: this.gqlClient,
      query: OFFERS_RECEIVED_FOR_ACCOUNT,
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000, // ms
      deep: false,
      variables() {
        return {
          address: this.address.toLowerCase()
        };
      },
      error(error) {
        console.log('error', error);
      }
    });
  },
  methods: {
    isReserveAuctionSalesType,
    showBidCanBeWithdrawnLabel(offer) {
      return !this.isWithdrawalEnabled(offer) && offer.lockedUntil && offer.lockedUntil > 0;
    },
    canResultReserveAuction(offer) {
      if (parseInt(offer.version) === 3) {
        // Auction is complete, not in an emergency exit state and is a valid user
        if (offer.type === 'Token' && isReserveAuctionSalesType(offer.token.salesType)) {
          return ReserveAuctionUtils.reserveAuctionCountdownComplete(offer.token.listing) &&
            !offer.token.listing.reserveAuctionCanEmergencyExit &&
            ReserveAuctionUtils.canResultTokenReserveAuction(
              offer.token.listing.reserveAuctionSeller,
              offer.token.listing.reserveAuctionBidder,
              this.account
            );
        }

        // Auction is complete, not in an emergency exit state and is a valid user
        if (offer.type === 'Edition' && isReserveAuctionSalesType(offer.edition.salesType)) {
          return ReserveAuctionUtils.reserveAuctionCountdownComplete(offer.edition) &&
            !offer.edition.reserveAuctionCanEmergencyExit &&
            ReserveAuctionUtils.canResultEditionReserveAuction(offer.edition, this.account);
        }
      }
      return false;
    },
    isInForcedWithdrawalReserveAuction(offer) {
      if (parseInt(offer.version) === 3) {
        if (offer.type === 'Token' && isReserveAuctionSalesType(offer.token.salesType)) {
          return offer.token.listing.reserveAuctionCanEmergencyExit;
        }
        if (offer.type === 'Edition' && isReserveAuctionSalesType(offer.edition.salesType)) {
          return offer.edition.reserveAuctionCanEmergencyExit;
        }
      }
      return false;
    },
    isWithdrawalEnabled(offer) {
      if (parseInt(offer.version) === 3) {
        // is reserve auction and countdown not started
        if (offer.type === 'Token' && isReserveAuctionSalesType(offer.token.salesType)) {
          return this.isLoggedInBidder(offer) && !ReserveAuctionUtils.reserveAuctionCountdownStarted(offer.token.listing);
        }
        // Is reserve and countdown not started
        if (offer.type === 'Edition' && isReserveAuctionSalesType(offer.edition.salesType)) {
          return this.isLoggedInBidder(offer) && !ReserveAuctionUtils.reserveAuctionCountdownStarted(offer.edition);
        }
        // Lockout time has passed
        if (offer.lockedUntil && offer.lockedUntil > 0) {
          return this.isLoggedInBidder(offer) && this.lockupTimeHasPassed(offer);
        }
      }
      return this.isLoggedInBidder(offer);
    },
    auctionHasStarted(offer) {
      if (parseInt(offer.version) === 3) {
        if (offer.type === 'Token') {
          return isReserveAuctionSalesType(offer.token.salesType) &&
            (ReserveAuctionUtils.reserveAuctionCountdownStarted(offer.token.listing) && !ReserveAuctionUtils.reserveAuctionCountdownComplete(offer.token.listing)) &&
            !offer.token.listing.reserveAuctionCanEmergencyExit;
        }
        if (offer.type === 'Edition') {
          return isReserveAuctionSalesType(offer.edition.salesType) &&
            (ReserveAuctionUtils.reserveAuctionCountdownStarted(offer.edition) && !ReserveAuctionUtils.reserveAuctionCountdownComplete(offer.edition)) &&
            !offer.edition.reserveAuctionCanEmergencyExit;
        }
      }
      return false;
    },
    offerBelowReserve(offer) {
      if (parseInt(offer.version) === 3) {
        if (offer.type === 'Token') {
          return isReserveAuctionSalesType(offer.token.salesType) &&
            parseInt(offer.token.listing.reserveAuctionEndTimestamp) === 0;
        }
        if (offer.type === 'Edition') {
          return isReserveAuctionSalesType(offer.edition.salesType) &&
            parseInt(offer.edition.reserveAuctionEndTimestamp) === 0 &&
            parseInt(offer.edition.remainingSupply) > 0;
        }
      }
      return false;
    },
    resultReserveAuction(offer) {
      if (offer.type === 'Edition') {
        this.$buefy.modal.open({
          parent: this,
          component: ResultEditionReserveAuction,
          width: 600,
          props: {
            edition: offer.edition
          },
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
              this.$apollo.queries.offersPlaced.refetch();
            }
          }
        });
      } else if (offer.type === 'Token') {
        this.$buefy.modal.open({
          parent: this,
          component: ResultTokenReserveAuction,
          width: 600,
          props: {
            edition: offer.edition,
            token: offer.token,
            listing: offer.token.listing
          },
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
              this.$apollo.queries.offersPlaced.refetch();
            }
          }
        });
      }
    },
    withdrawOffer(offer) {
      if (offer.type === 'Edition') {
        this.$buefy.modal.open({
          parent: this,
          component: WithdrawEditionOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersPlaced.refetch();
            }
          }
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: WithdrawTokenOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersPlaced.refetch();
            }
          }
        });
      }
    },
    increaseOffer(offer) {
      // console.log("offer", offer);
      this.$buefy.modal.open({
        parent: this,
        component: PlaceTokenOffer,
        width: 600,
        props: {
          existingOffer: offer,
          tokenId: offer.token.id,
          edition: offer.edition
        },
        events: {
          close: () => {
            this.$apollo.queries.offersPlaced.refetch();
          }
        }
      });
    },
    rejectOffer(offer) {
      if (offer.type === 'Edition') {
        this.$buefy.modal.open({
          parent: this,
          component: RejectEditionOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
            }
          }
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: RejectTokenOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
            }
          }
        });
      }
    },
    acceptOffer(offer) {
      if (offer.type === 'Edition') {
        this.$buefy.modal.open({
          parent: this,
          component: AcceptEditionOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
            }
          }
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: AcceptTokenOffer,
          width: 600,
          props: {offer},
          events: {
            close: () => {
              this.$apollo.queries.offersReceived.refetch();
            }
          }
        });
      }
    },
    buildLink(offer) {
      if (offer.type === 'Token') {
        return {name: 'tokens-id', params: {id: offer.token.id}};
      }
      return {name: 'gallery-id', params: {id: offer.edition.id}};
    },
    isLoggedInBidder(offer) {
      return this.isLoggedInAccount(_get(offer, 'bidder.address'));
    },
    lockupTimeHasPassed(offer) {
      const now = this.$moment.utc(Date.now());
      const lockupTimeUtc = this.$moment.unix(offer.lockedUntil).utc();
      return now.isAfter(lockupTimeUtc);
    },
    isLoggedInCurrentOwner(offer) {
      return this.isLoggedInAccount(_get(offer, 'currentOwner.address')) || isKoResulter(this.account);
    },
    async signInAndGetAuthToken() {
      if (!this.hasAuthToken) {
        await this.$store.dispatch('web3Store/createJwtSession');
      }
    }
  }
};
</script>
