import gql from 'graphql-tag';
import {COLLECTOR, METADATA_FIELDS} from './_commonModels';

export const TOP_ARTISTS = gql(`
  {
    topArtists: artists(first: 250, orderBy: totalValueInEth, orderDirection: desc, where: {totalValueInEth_gt: 0} subgraphError: allow) {
      id
      address
      totalValueInEth
      salesCount
      editionsCount
      firstEditionTimestamp
      lastEditionTimestamp
      highestSaleValueInEth
      totalPrimarySales
      totalPrimarySalesInEth
      totalSecondarySales
      totalSecondarySalesInEth
    }
  }
`);

export const TOP_TOKENS = gql(`
  {
    topTokens: tokens(first: 250, orderBy: totalPurchaseValue, orderDirection:desc subgraphError: allow) {
      id
      version
      tokenId
      birthTimestamp
      editionNumber
      artistAccount
      isListed
      primaryValueInEth
      totalPurchaseValue
      totalPurchaseCount
      largestSecondaryValueInEth
      largestSalePriceEth
      ${COLLECTOR('currentOwner')}
      edition {
        id
        active
        artistAccount
        totalAvailable
        totalSupply
        collaborators
      }
      ${METADATA_FIELDS}
    }
  }
`);

export const TOP_COLLECTORS = gql`
  {
    topCollectors: collectors(first: 250, orderBy: totalPurchaseEthSpent, orderDirection: desc, where: {totalPurchaseEthSpent_gt: 0} subgraphError: allow) {
      id
      address
      firstSeen
      firstPurchaseTimeStamp
      totalPurchaseCount
      totalPurchaseEthSpent
      secondarySaleEthTotal
      secondarySaleCount
      secondaryPurchaseCount
      secondaryPurchaseEthSpent
    }
  }
`;
