var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column is-justify-content-start pt-5 v-offset" },
      [
        _c(
          "div",
          {
            staticClass: "is-clickable is-inline-block pt-2",
            on: {
              click: _vm.toggleLike,
              mouseover: function ($event) {
                _vm.hovering = true
              },
              mouseleave: function ($event) {
                _vm.hovering = false
              },
            },
          },
          [
            _c("b-icon", {
              class: {
                "liked-heart": _vm.like || (_vm.hovering && !_vm.like),
                "hover-heart": _vm.hovering && _vm.like,
              },
              attrs: { icon: _vm.iconToShow, "custom-size": "mdi-36px" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "is-inline-block has-padding-left-7 is-size-4 has-text-weight-light like-count",
          },
          [_vm._v(_vm._s(_vm.displayTotalEditionLikes))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }