import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _sortBy from 'lodash/sortBy';
import {isBuyNowSalesType, isReserveAuctionSalesType} from './SaleTypes';
import ReserveAuctionUtils from './ReserveAuctionUtils';

function findCheapestToken(cleanTokens) {
  // Order tokens by birth timestamp
  const tokens = _orderBy(cleanTokens, 'birthTimestamp');

  const tokensWithOnGoingAuctions = _filter(tokens, (token) => {
    return isReserveAuctionSalesType(token.salesType) && ReserveAuctionUtils.reserveAuctionCountdownStarted(token.listing);
  });

  // reserve auction which has started - take highest
  if (tokensWithOnGoingAuctions.length > 0) {
    // console.log('Found tokens with ongoing auctions', tokensWithOnGoingAuctions);
    return _first(_sortBy(tokensWithOnGoingAuctions, token => parseFloat(token.listing.reserveAuctionBid)));
  }

  const tokensWithBuyNow = _filter(tokens, token => isBuyNowSalesType(token.salesType));

  // cheapest buy now
  if (tokensWithBuyNow.length > 0) {
    // console.log('Found tokens with buy now', tokensWithBuyNow);
    return _first(_sortBy(tokensWithBuyNow, token => parseFloat(token.listPrice)));
  }

  const tokensWithAuctions = _filter(tokens, token => isReserveAuctionSalesType(token.salesType));

  // reserve auction not start - take lowest
  if (tokensWithAuctions.length > 0) {
    // console.log('Found tokens with reserve auctions', tokensWithAuctions);
    return _first(_sortBy(tokensWithAuctions, token => parseFloat(token.listing.reserveAuctionBid)));
  }
}

export {
  findCheapestToken
};
