var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content" }, [
      _vm.filterPrivateCollections && _vm.filterPrivateCollections.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.slicedCollections, function (profileCollection) {
                return _c(
                  "div",
                  { key: profileCollection.name },
                  [
                    _c("edition-profile-collection", {
                      attrs: {
                        "profile-collection": profileCollection,
                        "profile-slug": _vm.profile.slug,
                        "is-logged-in-account-viewing-page":
                          _vm.isLoggedInAccountViewingPage,
                        address: _vm.profile.address,
                        editions: _vm.combinedEditions,
                      },
                      on: {
                        "edit-collection": _vm.editCollection,
                        "refresh-collection": _vm.refreshCollection,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.collectionsAvailable > 0
                ? _c(
                    "div",
                    { staticClass: "is-flex is-justify-content-center" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary is-outlined",
                          on: { click: _vm.viewMoreCollections },
                        },
                        [
                          _vm._v(
                            "View " +
                              _vm._s(_vm.collectionsAvailable) +
                              " more collection" +
                              _vm._s(_vm.collectionsAvailable === 1 ? "" : "s")
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.filteredExtraEditions && _vm.filteredExtraEditions.length > 0
      ? _c(
          "div",
          { staticClass: "columns is-multiline" },
          _vm._l(_vm.filteredExtraEditions, function (edition, $index) {
            return _c(
              "div",
              {
                key: $index,
                staticClass:
                  "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card",
              },
              [
                _c("edition-card", {
                  staticClass: "has-margin-bottom-4",
                  attrs: {
                    edition: edition,
                    cover: true,
                    address: _vm.profile.address,
                    resolution: "card",
                  },
                  on: {
                    "editions-refresh": () => _vm.$emit("editions-refresh"),
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "level" }, [
      _vm.profileCollections &&
      _vm.profileCollections.length === 0 &&
      _vm.filteredExtraEditions &&
      _vm.filteredExtraEditions.length === 0
        ? _c(
            "div",
            { staticClass: "level-item" },
            [_c("empty-state", { attrs: { message: "No items for sale" } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }