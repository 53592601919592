<template>
  <b-navbar-dropdown v-if="isWeb3Connected && $device.isDesktopOrTablet"
                     :arrowless="true" :right="true" :close-on-click="true"
                     @click.native="menuOpened">
    <template slot="label">
      <b-icon icon="bell-outline"></b-icon>
      <span v-if="newNotifications.length > 0"
            @click="menuOpened"
            style="position:relative; top: -8px; left: -5px;">
        <span class="pulse"><span class="pulse custom"></span></span>
      </span>
    </template>
    <div style="max-height: 375px; min-width: 300px; overflow: scroll;" class="is-family-tertiary">
      <b-navbar-item v-for="(event, $index) in shortList" :key="$index">
        <n-link :to="buildLink(event)" tag="span">
          <div class="columns is-mobile is-multiline">
            <div class="column is-full">
              {{ event.edition.metadataName | truncate(30) }}
            </div>
            <div class="column is-two-fifths paddding-top-0" style="padding-top: 0">
              <span class="nav-item-heading has-text-weight-semibold">
                {{ mapNotification(event.eventType, event.eventPrimaryUser) }}
              </span>
            </div>
            <div class="column is-one-fifth" style="padding-top: 0;">
            </div>
            <div class="column is-two-fifths" style="padding-top: 0;">
              <span class="nav-item-heading">
                <date-from :timestamp-in-secs="event.timestamp" />
              </span>
            </div>
          </div>
        </n-link>
      </b-navbar-item>
    </div>
    <b-navbar-item class="is-family-tertiary" v-if="shortList && shortList.length === 0">
      No activity
    </b-navbar-item>
    <hr class="navbar-divider">
    <n-link :to="{name: 'activity-id', params: { id: account }}" class="navbar-item is-family-tertiary" @click="closeMenu">
      My Activity
    </n-link>
  </b-navbar-dropdown>

  <b-navbar-item v-else-if="isWeb3Connected && $device.isMobile"
                 @click="visitMyActivity"
                 @click.native="visitMyActivity"
                 class="is-family-tertiary"
  >
    My Activity
    <span v-if="newNotifications.length === 0" style="position:relative; top: -25px; left: 98px;"
          @click="visitMyActivity">
      <span class="pulse"><span class="pulse custom"></span></span>
    </span>
  </b-navbar-item>

</template>
<script>
import _take from 'lodash/take';
import _includes from 'lodash/includes';
import _first from 'lodash/first';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import {mapGetters, mapState} from 'vuex';
import {mapNotification} from '../services/utils';
import { filterHidden } from '../utils/contractUtils'
import {
  GET_BUYER_NOTIFICATION_EVENTS,
  GET_CREATOR_NOTIFICATION_EVENTS,
  GET_SELLER_NOTIFICATION_EVENTS
} from '../queries/activityQueries';
import {isCreatorContract, splitCreatorEditionId} from '../services/CreatorContractUtils';
import {handleTokenCardRouting} from '../services/routing';
import DateFrom from './common/DateFrom';

const NOTIFICATIONS_REFRESH_INTERVAL = 60000; /* millis */

const MAX_EVENTS_TO_SHOW = 20;

export default {
  name: 'NotificationMenuList',
  components: {DateFrom},
  data() {
    return {
      finishedLoading: false,
      lastCheckpointEvent: null,
      newNotifications: [],
      notifications: [],
      pollingFnc: null
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'isWeb3Connected',
      'hasAuthToken'
    ]),
    ...mapState('web3Store', [
      'chainId',
      'account',
      'gqlClient'
    ]),
    shortList() {
      return _take(this.notifications, MAX_EVENTS_TO_SHOW);
    }
  },
  watch: {
    // Notifications for bids, withdrawals, buys, outbids
    account(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.pollForNewEvents();
      }
    },
    // Notifications for bids, withdrawals, buys, outbids
    gqlClient(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.notifications = [];
        this.newNotifications = [];
        this.lastCheckpointEvent = null;
      }
    }
  },
  mounted() {
    if (this.account) {
      this.pollForNewEvents();
    }
  },
  beforeDestroy() {
    if (this.pollingFnc) {
      clearInterval(this.pollingFnc);
    }
  },
  methods: {
    isCreatorContract,
    mapNotification,
    visitMyActivity() {
      this.menuOpened();
      this.$router.push({name: 'activity-id', params: {id: this.account}});
      this.closeMenu();
    },
    closeMenu() {
      this.$parent.$parent.$refs.navbarMenu.closeMenu();
    },
    buildLink(event) {
      // Handle V4 entries
      if ((event.edition && event.edition.version === '4') || (event.token && event.token.version === '4')) {
        if (event.token) {
          return handleTokenCardRouting(event.token)
        } else {
          const idSplit = splitCreatorEditionId(event.edition.id)
          return {name: 'contract-contract-id', params: {id: idSplit.editionId, contract: idSplit.contract}}
        }
      }

      // Otherwise default to old logic
      // Move to the token page when its the seller
      if (_includes(['BidAccepted', 'Purchase'], event.eventType) && event.eventPrimaryUser === 'seller') {
        return {name: 'tokens-id', params: {id: event.token.id}};
      }
      // Move to the token page when its the buyer
      if (_includes(['BidAccepted', 'Purchase'], event.eventType) && event.eventPrimaryUser === 'buyer') {
        return {name: 'tokens-id', params: {id: event.token.id}};
      }
      // If creator then move to offers tab
      const OFFERS_EVENTS = ['BidPlaced', 'BidAccepted', 'BidIncreased', 'BidRejected', 'BidWithdrawn'];
      if (_includes(OFFERS_EVENTS, event.eventType) && event.eventPrimaryUser === 'creator') {
        return {name: 'profile-id', params: {id: this.account.toLowerCase(), query: {tab: 'offers'}}};
      }
      // Move to token page for other token events
      if (event.type === 'TOKEN') {
        return {name: 'tokens-id', params: {id: event.token.id}};
      }
      return {name: 'gallery-id', params: {id: event.edition.id}};
    },
    menuOpened() {
      if (this.finishedLoading && this.newNotifications.length > 0) {
        this.$store.dispatch('userStore/checkpointLastSeenEventForUser', {
          account: this.account,
          event: _first(this.notifications)
        });
      }
      this.newNotifications = [];
    },
    pollForNewEvents() {
      this.loadNotifications();
      this.pollingFnc = setInterval(() => {
        this.loadNotifications();
      }, NOTIFICATIONS_REFRESH_INTERVAL);
    },
    async loadNotifications() {
      this.finishedLoading = false;

      // Load previous checkpoint so we know when to show "new" notifications
      this.loadLastSeenEventForUser()
        .then(() => this.getOwnerAndBidderEvents())
        .then(() => {
          const newestEvent = _first(this.notifications);

          // If we dont already have a checkpoint and we have some events, flag as new
          if (!this.lastCheckpointEvent && newestEvent) {
            this.newNotifications = this.notifications;
          }
          // We have a checkpoint and new events, check if we have new ones
          else if (this.lastCheckpointEvent && newestEvent) {
            this.newNotifications = _filter(this.notifications, (notification) => {
              return notification.timestamp > this.lastCheckpointEvent.eventTimestamp;
            });
          }
          return this.newNotifications
        })
        .finally(() => {
          this.$forceUpdate();
          this.finishedLoading = true;
        });
    },
    async loadLastSeenEventForUser() {
      this.lastCheckpointEvent = await this.$store.dispatch('userStore/getLastSeenEventForUser', {
        account: this.account
      });
      return this.lastCheckpointEvent;
    },
    async getOwnerAndBidderEvents() {
      const loadEventData = (query, primaryUser) => {
        return this.$apollo.query({
          client: this.gqlClient,
          query,
          variables: {
            address: this.account.toLowerCase()
          },
          fetchPolicy: 'no-cache'
        }).then(({data}) => data.activity.map(activity => ({
          ...activity,
          eventPrimaryUser: primaryUser
        })));
      };

      // Load bidder and owner
      const [bidderEventResults, ownerEventResults] = await Promise.all([
        loadEventData(GET_BUYER_NOTIFICATION_EVENTS, 'buyer'),
        loadEventData(GET_CREATOR_NOTIFICATION_EVENTS, 'creator'),
        loadEventData(GET_SELLER_NOTIFICATION_EVENTS, 'seller')
      ]);

      // Get sorted events
      const allEvents = filterHidden(_orderBy([...(bidderEventResults || []), ...(ownerEventResults || [])], 'timestamp', 'desc'))
      // console.log("allEvents", allEvents);
      this.$set(this, 'notifications', allEvents);
      return allEvents;
    }
  }
};
</script>

<style scoped lang="scss">
@import "assets/colours.scss";

.pulse {
  background: $primary;
  height: 10px;
  width: 10px;
  border: 0px solid $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.5);
}

@keyframes pulse {
  0% {
    border: 0px solid $primary;
    opacity: 1;
  }
  100% {
    border: 15px solid $primary;
    opacity: 0;
  }
}

.custom {
  position: absolute;
  animation: pulse 0.89s linear infinite;
}
</style>
