<template>
  <section v-if="breakdown">
    <b-collapse :open="false">
      <p slot="trigger" class="is-muted-7 has-text-centered">
        <span class="">Analysed <span class="is-bold">{{breakdown.totalEvents}}</span> events over a <span class="is-bold">14</span>
        day period.</span>
        Show more <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
      </p>

      <div class="is-flex is-flex-direction-column	is-align-items-center">
        <p>
          <breakdown-radar-chart :breakdown="breakdown"></breakdown-radar-chart>
        </p>
        <p class="is-muted-7">
          Last updated {{new Date(created) | moment('from')}}
        </p>
      </div>
    </b-collapse>
  </section>
</template>

<script>
  import BreakdownRadarChart from '../charts/BreakdownRadarChart';

  export default {
    components: {
      BreakdownRadarChart
    },
    props: ['breakdown', 'created', 'events']
  };
</script>
