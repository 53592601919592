<template>
  <div>
    <!-- if static image (not video) available then remove /card -->
    <header class="header-image has-text-white" v-if="headerImage" :style="{ backgroundImage: `url(https://cdn.knownorigin.io/cdn/images/network/1/edition/${headerImage}/card`}">
      <section class="hero">
        <div class="hero-body">
          <div class="container content">
            <h2 class="title has-text-white has-margin-top-1 is-size-1">
              <n-link :to="linkToJournal"
                      class="has-text-white has-padding-top-6"
                      v-if="headerImage">
                Artist spotlight <br /> {{ artist }}
              </n-link>
            </h2>
            <div class="content">
              <div class="field is-grouped">
                <p class="control">
                  <n-link :to="linkToJournal" class="button is-white is-outlined">
                    Read the article
                  </n-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  </div>
</template>

<script>
  export default {
    props: ['artist', 'headerImage', 'linkToJournal']
  };
</script>
