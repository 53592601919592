var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.salesHistory && _vm.salesHistory.length > 0
    ? _c(
        "b-table",
        {
          attrs: {
            data: _vm.salesHistory,
            paginated: "",
            "pagination-simple": "",
            "per-page": 5,
            "default-sort": "timestamp",
            "default-sort-direction": "desc",
          },
        },
        [
          _c("b-table-column", {
            attrs: { field: "artwork", label: " ", width: "20%", centered: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "n-link",
                        { attrs: { to: _vm.buildLink(props.row.edition.id) } },
                        [
                          props.row.edition
                            ? _c("image-asset", {
                                staticClass: "is-hidden-mobile",
                                attrs: {
                                  "edition-number": props.row.edition.id,
                                  metadata: props.row.edition.metadata,
                                  cover: true,
                                  resolution: "card",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          props.row
                            ? _c(
                                "span",
                                { staticClass: "is-hidden-desktop" },
                                [
                                  _c("artwork-name-and-artist", {
                                    attrs: {
                                      metadata: props.row.edition.metadata,
                                      "edition-number": props.row.edition.id,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              613159603
            ),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: { field: "type", label: "Type", centered: "", sortable: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      props.row.type === "EDITION"
                        ? _c(
                            "b-tag",
                            {
                              staticClass: "is-uppercase",
                              attrs: { type: "is-dark" },
                            },
                            [_vm._v("Primary")]
                          )
                        : _c(
                            "b-tag",
                            {
                              staticClass: "is-uppercase",
                              attrs: { type: "is-light" },
                            },
                            [_vm._v("Secondary")]
                          ),
                    ]
                  },
                },
              ],
              null,
              false,
              454712185
            ),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "buyer",
              label: "Buyer",
              centered: "",
              sortable: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("profile-name-link", {
                        attrs: { "eth-account": props.row.buyer },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              2941428713
            ),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "eventType",
              label: "Event",
              centered: "",
              sortable: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _vm._v(
                        "\n    " +
                          _vm._s(_vm.mapEvent(props.row.eventType)) +
                          "\n  "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3084172344
            ),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "eventValueInWei",
              label: "Price",
              centered: "",
              sortable: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("eth-with-fiat-price", {
                        attrs: { "price-in-wei": props.row.eventValueInWei },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              375384386
            ),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "timestamp",
              label: "Date",
              centered: "",
              sortable: "",
              numeric: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("date-format", {
                        attrs: { "timestamp-in-secs": props.row.timestamp },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              237879826
            ),
          }),
        ],
        1
      )
    : _c("div", [_c("empty-state", { attrs: { message: "No sales data" } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }