var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.cmsComponent
      ? _c(
          "header",
          {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: _vm.imgObj,
                expression: "imgObj",
                arg: "background-image",
              },
            ],
            staticClass: "header-image has-text-white push-up-homepage-mobile",
          },
          [
            _c("section", { staticClass: "hero" }, [
              _c("div", { staticClass: "hero-body" }, [
                _c("div", { staticClass: "container content" }, [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "title has-text-white has-margin-top-1 is-size-2-mobile",
                    },
                    [
                      _c(
                        "n-link",
                        {
                          staticClass: "has-text-white has-padding-top-6",
                          attrs: { to: _vm.cmsComponent.buttonLink },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.cmsComponent.title) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.cmsComponent.subtitle) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "field is-grouped",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickEventTracking(
                              _vm.cmsComponent.buttonLabel,
                              _vm.cmsComponent.buttonLink.name
                            )
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-white is-outlined",
                              attrs: {
                                href: _vm.cmsComponent.buttonLink.name,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.cmsComponent.buttonLabel) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }