<template>
  <section>
    <b-taglist attached>
      <div>
        <b-tag type="is-secondary" class="is-family-primary" v-if="flames === 3">
          🔥🔥🔥
        </b-tag>
        <b-tag type="is-secondary" class="is-family-primary" v-if="flames === 2">
          🔥🔥
        </b-tag>
        <b-tag type="is-secondary" class="is-family-primary" v-if="flames === 1">
          🔥
        </b-tag>
      </div>
      <div v-if="artistStats" class="is-family-tertiary has-text-weight-medium" :class="noPadding ? 'pl-0' : 'pl-3'">
        <b-tag v-if="artistStats.salesCount === '1'" :class="strong ? 'tag--strong' : 'tag'">
          {{ artistStats.salesCount }} Sale
        </b-tag>
        <b-tag v-if="artistStats.salesCount === '0'" class="mb-0" :class="strong ? 'tag--strong' : 'tag'">
          New Artist
        </b-tag>
        <b-tag v-if="artistStats.salesCount !== '0' && artistStats.salesCount !== '1'" class="mb-0" :class="strong ? 'tag--strong' : 'tag'">
          {{ artistStats.salesCount }} Sales
        </b-tag>
      </div>
    </b-taglist>

  </section>
</template>

<script>
import _get from 'lodash/get';
import {USER_STATS} from '../../queries/artistQueries';

export default {
  components: {},
  props: ['score', 'address', 'flames', 'noPadding', 'strong'],
  data() {
    return {
      artistStats: null
    };
  },
  async mounted() {
    if (this.address) {
      await this.$apollo.addSmartQuery('artistStats', {
        client: this.gqlClient,
        query: USER_STATS,
        variables() {
          return {
            address: this.address.toLowerCase()
          };
        },
        result({data}) {
          this.artistStats = _get(data, 'artistStats');
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
    }
  }
};
</script>
