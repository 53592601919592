<template>
  <div class="columns has-padding-left-3" v-if="addresses">
    <div class="column is-vcentered">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item is-clickable" style="margin-left: -2.5rem; "
               v-for="address in addresses.slice(0, 5)"
               :key="address.address">
            <account-image :address="address.address" size="is-64x64" :no-height-restrict="true">
            </account-image>
          </div>
          <div class="level-item" style="margin-left: -2.5rem;" v-if="addresses.length > 5">
            <figure class="image is-64x64">
              <img class="is-rounded" src="/user/profile_circle.png">
            </figure>
          </div>
        </div>
        <div class="level-right level-is-shrinkable">
          <div class="level-item">
            <div>
              {{ addresses.length }} eligible
              <div>
                <div class="hor">
                  <a @click="viewAccessList" class="is-clickable is-underlined">
                    See all
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapState} from 'vuex';
import _map from 'lodash/map';
import EarlyAccessListModal from '../early-access/EarlyAccessListModal';
import AccountImage from '../common/AccountImage';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  components: {AccountImage},
  props: ['edition'],
  computed: {
    ...mapState('web3Store', [
      'chainId',
      'account'
    ])
  },
  data() {
    return {
      addresses: null,
      earlyAccess: null
    };
  },
  watch: {},
  async mounted() {
    this.loadEarlyAccessList();
  },
  methods: {
    async loadEarlyAccessList() {
      if (this.edition) {
        if (this.edition.gatedSale && this.edition.gatedSale.phases.length > 0) {
          const saleId = this.edition.gatedSale.phases[0].saleId;
          const phaseId = this.edition.gatedSale.phases[0].phaseId;
          const res = await this.$api.$get(`/network/${this.chainId}/gatedsales/access/sale/${saleId}/phase/${phaseId}`);
          if (res) {
            this.earlyAccess = res;
            this.addresses = _map(res.addresses, (address) => {
              return {
                address
              };
            });
          }
        }
      }
    },
    async viewAccessList() {
      this.$buefy.modal.open({
        parent: this,
        component: EarlyAccessListModal,
        width: 500,
        props: {
          edition: this.edition,
          addresses: this.addresses
        }
      });

      // Fire amplitude event for the opening edition amplitude
      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.id]: this.$route.params.id,
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'early access list'
        }
      });
    }
  }
};
</script>
