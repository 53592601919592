<template>
  <article>
    <section class="is-hidden-desktop fixed-top-mobile-menu-bar">
      <div class="nav-mobile"></div>
      <b-sidebar
        type="is-white"
        :fullheight="true"
        :fullwidth="true"
        :overlay="true"
        :right="true"
        v-model="state.open">

        <b-navbar-item class="nav-ko-logo" tag="router-link" :to="{path: '/'}" @click.native="closeMenu">
          <span class="has-text-dark is-size-3 fixed-top-mobile-logo nav-logo">
            KnownOrigin
          </span>
          <span class="has-text-primary is-size-3 fixed-top-mobile-logo-dot">.</span>
        </b-navbar-item>
        <b-button @click="closeMenu" class="header-close-icon">
          X
        </b-button>

        <b-menu class="is-size-5 is-family-tertiary" style="padding-top: 3em;">
          <b-menu-list label="">

            <div v-if="state.selected === 'all'">
              <b-menu-item label="Marketplace"
                           tag="router-link"
                           :to="{name: 'marketplace'}"
                           data-testid="marketplaceNavMobile"
                           @click.native="closeMenu"
              ></b-menu-item>
              <b-menu-item label="Apply"
                           tag="router-link"
                           :to="{name: 'apply'}"
                           data-testid="applyNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Activity"
                           tag="router-link"
                           :to="{name: 'activity'}"
                           data-testid="activityNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Help"
                           data-testid="helpNavMobile"
                           @click.native="launchMessenger">
              </b-menu-item>
              <b-menu-item label="Creator Contracts"
                           tag="router-link"
                           :to="{name: 'creator-contracts'}"
                           data-testid="creatorContractsNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Trending"
                           tag="router-link"
                           :to="{name: 'trending'}"
                           data-testid="trendingNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Hall of fame"
                           tag="router-link"
                           :to="{name: 'halloffame'}"
                           data-testid="hallOfFameNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Collections"
                           tag="router-link"
                           :to="{name: 'collections'}"
                           data-testid="collectionsNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Journal"
                           tag="router-link"
                           :to="{name: 'journal'}"
                           data-testid="journalNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-menu-item label="Help Centre"
                           data-testid="helpCentreNavMobile"
                           href="https://docs.knownorigin.io/en/"
                           target="_blank"
                           @click.native="closeMenu">
              </b-menu-item>
              <b-button v-if="!isWeb3Connected"
                        @click="loginToWeb3"
                        type="is-primary"
                        style="margin: 10px;"
                        outlined
                        @click.native="closeMenu">
                Connect wallet
              </b-button>
            </div>

            <!-- Profile - link -->
            <b-menu-item v-if="isWeb3Connected"
                         @click="toggleProfileMenuView"
                         :class="{'pb-2 bottom-border': state.selected === 'profile'}"
                         data-testid="profileNavMobile"
                         class="is-family-tertiary">
              <template #label="">
                Profile
                <b-icon :class="{
                    'is-pulled-right': state.selected === 'all',
                    'is-pulled-left': state.selected === 'profile'
                }" :icon="state.selected === 'all' ? 'menu-right' : 'menu-left'">
                </b-icon>
              </template>
            </b-menu-item>

            <!-- Profile - submenu -->
            <div v-show="state.selected === 'profile'" class="mt-2">
              <b-menu-item tag="router-link"
                           label="View Profile"
                           :to="{name: 'profile-id', params:{id: account}}"
                           data-testid="viewProfileNavMobile"
                           @click.native="closeMenu">
              </b-menu-item>
              <!--              <b-menu-item label="Donate to charity" @click="launchDonationModal" @click.native="closeMenu">-->
              <!--              </b-menu-item>-->
              <b-menu-item label="Logout" @click="logoutWeb3" @click.native="closeMenu"
                           data-testid="logoutNavMobile">
              </b-menu-item>
            </div>
          </b-menu-list>

          <hr />

          <div class="columns is-mobile mt-5">
            <div class="column"></div>
            <div class="column">
              <a href="https://discord.gg/knownorigin" target="_blank" data-testid="discordNavMobile" class="hand-pointer">
                <font-awesome-icon :icon="faDiscord" />
              </a>
            </div>
            <div class="column">
              <a href="https://twitter.com/knownorigin_io" target="_blank" data-testid="twitterNavMobile" class="hand-pointer">
                <b-icon icon="twitter">
                </b-icon>
              </a>
            </div>
            <div class="column">
              <a href="https://www.instagram.com/knownorigin.io/" target="_blank" data-testid="instaNavMobile" class="hand-pointer">
                <b-icon icon="instagram">
                </b-icon>
              </a>
            </div>
            <div class="column ">
              <a href="https://www.reddit.com/r/knownorigin/" target="_blank" data-testid="twitterNavMobile" class="hand-pointer">
                <b-icon icon="reddit">
                </b-icon>
              </a>
            </div>
            <div class="column ">
              <a href="https://www.youtube.com/channel/UCZBkUkm5SSXQqzxBPezd5gw" target="_blank" class="hand-pointer">
                <b-icon icon="youtube">
                </b-icon>
              </a>
            </div>
            <div class="column"></div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <hr class="mt-0 mb-0 pt-0 pb-0" />
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column is-small is-muted">
              <p class="pl-3 is-size-6">
                &copy; KnownOrigin 2022
              </p>
              <p class="pl-3 pt-4 is-size-6">
                Discover rare digital art and collect NFTs
              </p>
              <p class="pl-3 pt-4 is-size-7 is-family-tertiary">
                <a href="https://docs.knownorigin.io" target="_blank" class="">
                  Documentation
                </a>
                &bullet;
                <a href="https://docs.knownorigin.io/en/articles/7128351-terms-of-service" target="_blank" class="">
                  Terms of Service
                </a>
                &bullet;
                <a href="https://docs.knownorigin.io/en/articles/7128342-privacy-policy" target="_blank" class="">
                  Privacy
                </a>
                &bullet;
                <a href="https://docs.knownorigin.io/en/articles/6118953-code-of-conduct" target="_blank" class="">
                  Code of Conduct
                </a>
                &bullet;
                <a href="https://discord.gg/knownorigin" target="_blank" class="">
                  Support
                </a>
                &bullet;
                <a href="mailto:partnerships@knownorigin.io?subject=Partnership opportunity" target="_blank">
                  Partnership enquiry
                </a>
                &bullet;
                <a href="https://jobs.ebayinc.com/us/en/search-results?keywords=knownorigin" target="_blank">
                  Careers
                </a>
              </p>
            </div>
          </div>

        </b-menu>
      </b-sidebar>
      <div v-if="showHeader">
        <router-link class="header-ko-logo is-flex is-align-items-center" :to="{ path: '/' }">
          <img src="/branding/Black-blue-ko-logo.svg" class="mobileNav__koLogo mt-2" />
        </router-link>
        <div>
          <omni-search class="fixed-top-tablet-search-bar  column is-hidden-mobile"
                       @show-search="closeSearch"
                       :is-mobile="true"
                       v-on-clickaway="awayTablet"
                       :key="componentKey"
                       @update-search-string="updateSearch"
          ></omni-search>
          <router-link type="button" class="fixed-top-mobile-apply-button apply-button is-align-self-center is-hidden-tablet p-2 is-family-tertiary" :to="{ path: 'apply' }">
            Apply
          </router-link>
          <b-button @click="openSearch" class="fixed-top-mobile-search-button is-align-self-center is-hidden-tablet">
            <b-icon icon="magnify" class=""></b-icon>
          </b-button>
          <b-button @click="openMenu" class="fixed-top-mobile-menu-button header-style">
            <b-icon v-if="state.open" class="is-vcentered" icon="close"></b-icon>
            <b-icon v-else class="is-vcentered" icon="menu"></b-icon>
          </b-button>
        </div>
      </div>
      <div v-else>

        <omni-search class="fixed-top-mobile-search-bar ml-1 mr-2 column is-full"
                     @show-search="closeSearch"
                     :is-mobile="true"
                     v-on-clickaway="away"
        ></omni-search>
      </div>
    </section>
  </article>
</template>
<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import DonationModal from '../modal/DonationModal';
import OmniSearch from '../search/OmniSearch.vue';

export default {
  components: {OmniSearch},
  mixins: [clickaway],
  data() {
    return {
      state: {
        open: false,
        selected: 'all'
      },
      showHeader: true,
      componentKey: 0,
      searchInput: ''
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'isWeb3Connected'
    ]),
    ...mapState('web3Store', [
      'account'
    ]),
    faDiscord() {
      return faDiscord;
    }
  },
  methods: {
    ...mapActions('analytics/intercomStore', ['launchMessenger']),
    updateSearch(input) {
      this.searchInput = input;
    },
    away() {
      this.closeSearch();
    },
    awayTablet() {
      if (this.searchInput.length > 0) {
        this.componentKey += 1;
        this.searchInput = '';
      }
    },

    openSearch() {
      this.showHeader = false;
    },
    closeSearch() {
      this.showHeader = true;
    },
    closeMenu() {
      this.state.selected = 'all';
      this.state.open = false;
    },
    openMenu() {
      this.state.selected = 'all';
      this.state.open = true;
    },
    toggleProfileMenuView() {
      if (this.state.selected === 'all') {
        this.state.selected = 'profile';
      } else {
        this.state.selected = 'all';
      }
    },
    loginToWeb3() {
      console.log('Logging into web3');
      this.$store.dispatch('web3Store/connectToWeb3');
      this.state.open = false;
    },
    logoutWeb3() {
      console.log('Logging out of web3');
      this.$store.dispatch('web3Store/logoutWeb3');
    },
    async launchDonationModal() {
      this.state.open = false;
      const profile = await this.$store.dispatch('userStore/getUserBySlugOrAddress', this.account);
      this.$buefy.modal.open({
        parent: this,
        component: DonationModal,
        width: 600,
        props: {profile}
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "assets/colours.scss";

.mobileNav__koLogo {
  max-width: 200px;
}

.header-ko-logo {
  position: absolute;
  display: flex;
  margin-top: -3.15em;
  padding: 0.25em;
  padding-left: 1.25em;
}

.header-ko-icon {
  width: 32px !important;
}

.header-close-icon {
  float: right;
  margin-left: 350px;
  margin-right: 20px;
  position: absolute;
  top: 10px;
}

.bottom-border {
  border-bottom: $grey-lighter 1px solid;
}
.text-size {
  font-size: 18px !important;
}

.header-style{
  border-width:0px;
}
.header-style:focus,.header-style:active {
  outline: none !important;
  box-shadow: none;
}

@media screen and (max-width: 440px) {
  .apply-button{
    display: none;
  }

  .mobileNav__koLogo {
    max-width: 175px;
    margin-top: 0.6rem !important;
  }
}

@media screen and (max-width: 350px) {
  .header-ko-logo,
  .menu-ko-logo {
    margin-top: -3.25em;

    span {
      font-size: 1.5em !important;
    }
  }

  .mobileNav__koLogo {
    margin-top: 0.65rem !important;
  }
}
</style>
