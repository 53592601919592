<template>
  <div>
    <modal-template v-if="!isWertPurchaseInFlight" :edition="edition" :transaction-hash="transactionHash">
      <template slot="content-title">
        Buy with card for
        <eth-with-fiat-price :price-in-eth="itemPrice" />
      </template>

      <template slot="content-sub-title">
        <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id"></artwork-name-and-artist>
      </template>

      <template slot="secondary-content">
        <section class="has-padding-bottom-7">
          <p>
            Read more about making
            <a href="https://knownorigin.io/journal/platformupdate/card-purchases" class="is-linkable" target="_blank">
              card purchases
            </a>
          </p>
          <b-notification :closable="false">
            <p class="has-text-weight-semibold">
              The first time you make a purchase you will need to provide proof-of-identity details.
            </p>
            <p>
              After your initial card purchase you will not need to do it again.
            </p>
            <p>
              Once the payment has been triggered, you may close the popup as it will complete automatically.
            </p>
          </b-notification>
          <p>
            Payments are done by
            <strong><a href="https://wert.io/" target="_blank" class="is-linkable">Wert.io</a></strong>
          </p>
          <section v-if="marketplacesBlocked">
            <marketplace-blocker-message />
          </section>
        </section>
      </template>
      <template slot="action-feedback-label">
        Buying
      </template>

      <template slot="content-balance">&nbsp;</template>

      <template slot="action-button">
        <b-button type="is-primary" expanded @click="startWertFlow">
          Continue
        </b-button>
      </template>
    </modal-template>

    <template slot="action-button-nevermind">
      <b-button expanded @click="close(false)">
        Nevermind
      </b-button>
    </template>

    <div id="wert-widget" />
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {ethers} from 'ethers';
import WertWidget from '@wert-io/widget-initializer';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import MarketplaceBlockerMessage from '../../messages/MarketplaceBlockerMessage';
import {startNotificationFlow} from './wertNotificationService';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    MarketplaceBlockerMessage,
    ArtworkNameAndArtist,
    ModalTemplate,
    EthWithFiatPrice
  },
  props: ['edition', 'token', 'entityType', 'algoliaInfo'],
  data() {
    return {
      transactionHash: null,
      isWertPurchaseInFlight: false,
      dismissNotification: null,
      timer: null,
      marketplacesBlocked: false
    };
  },
  computed: {
    ...mapState('web3Store', [
      'notifyService',
      'chainId'
    ]),
    itemPrice() {
      return this.entityType === 'edition'
        ? ethers.utils.formatEther(this.edition.priceInWei)
        : ethers.utils.formatEther(ethers.utils.parseEther(this.token.listPrice));
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.dismissNotification) {
      this.dismissNotification();
    }
  },
  async mounted() {
    if (this.edition.creatorContract && this.edition.creatorContract.id) {
      const contractDetails = await this.$store.dispatch('creatorContractStore/fetchContractDetails', {
        address: this.edition.creatorContract.id
      })
      if (contractDetails.filterRegistry?.supported) { // check filter registry for blocked marketplaces
        this.marketplacesBlocked = true;
      }
    }
  },
  methods: {
    close(complete) {
      this.$emit('close', {purchaseComplete: complete});
    },
    async startWertFlow() {
      try {
        let artworkId, salesType, editionOrToken;

        if (this.entityType === 'edition') {
          editionOrToken = this.edition;
          artworkId = this.edition.id;
          salesType = this.edition.salesType;
        } else if (this.entityType === 'token') {
          editionOrToken = this.token;
          artworkId = this.token.id;
          salesType = this.token.salesType;
        } else {
          console.error('Unknown purchase type - ', this.entityType);
          return;
        }

        const wertConfig = await this.$store.dispatch('web3ActionsStore/startWertPurchase', {
          artwork_id: artworkId,
          entity_type: this.entityType,
          koda_version: this.edition.version,
          price_in_ether: this.itemPrice,
          sales_type: salesType,
          edition_or_token: editionOrToken
        });

        try {
          const wertwidg = new WertWidget({
            container_id: 'wert-widget',
            height: 800,
            ...wertConfig,
            extra: {
              item_info: {
                author_image_url: `${this.$userMediaApi.defaults.baseURL}/media/user/profile/${this.edition.artistAccount}`,
                author: `${this.edition.metadata.artist}`,
                image_url: `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.edition.id}/thumbnail`,
                name: `${this.edition.metadata.name}`
              }
            }
          });

          this.isWertPurchaseInFlight = true;

          const {click_id} = wertConfig;

          let updateNotification = null;

          const sentNotification = (notificationObject) => {
            if (updateNotification) {
              updateNotification(notificationObject);
            } else {
              const {update, dismiss} = this.notifyService.notification(notificationObject);
              updateNotification = update;
              this.dismissNotification = dismiss;
            }
          };

          const stopTimer = () => {
            if (this.timer) {
              clearInterval(this.timer);
            }
          };

          this.time = startNotificationFlow(this.$store, sentNotification, click_id, stopTimer);

          wertwidg.mount();
        } catch (e) {
          console.log(e);
        }

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.entityType === 'edition' ? this.edition.id : this.token.id
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.entityType === 'edition' ? this.edition.id : this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.$options.filters.toEth(this.itemPrice),
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'wert',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: this.entityType === 'edition' ? 'primary' : 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.edition.startDate,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.$options.filters.toEth(this.itemPrice)),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.entityType === 'edition' ? this.edition.id : this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'wert',
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: this.entityType === 'edition' ? 'primary' : 'secondary'
            }
          }
        });
      } catch (e) {
        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseFailed,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.entityType === 'edition' ? this.edition.id : this.token.id,
            [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseFailed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.entityType === 'edition' ? this.edition.id : this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
            [AMPLITUDE_EVENT_PROPERTIES.price]: ethers.utils.parseEther(this.itemPrice).toString(),
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'wert',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: this.entityType === 'edition' ? 'primary' : 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now'
          }
        });

        console.error(e);
      }
    }
  }
};
</script>
