import gql from 'graphql-tag';
import {METADATA_FIELDS, RESERVE_AUCTION_FIELDS, LISTING_FIELDS, COLLECTOR, ACTIVE_EDITION_BID} from './_commonModels';

const OFFERS_FIELDS = `
id
version
type
token {
  id
  version
  salesType
  ${COLLECTOR('currentOwner')}
  ${LISTING_FIELDS}
}
edition {
  id
  version
  salesType
  artistAccount
  collaborators
  remainingSupply
  ${ACTIVE_EDITION_BID}
  ${METADATA_FIELDS}
  ${RESERVE_AUCTION_FIELDS}
}
${COLLECTOR('currentOwner')}
${COLLECTOR('bidder')}
ethValue
weiValue
transactionHash
isActive
lockedUntil
type
`;

const GET_OFFERS_RECEIVED_FOR_ACCOUNT = `
  offersReceived($address: String!) {
    offersReceived: offers(where: {isActive: true, currentOwner: $address} orderBy: "timestamp" orderDirection: "desc" subgraphError: allow) {
      ${OFFERS_FIELDS}
    }
}`

export const OFFERS_RECEIVED_FOR_ACCOUNT = gql(`
  query ${GET_OFFERS_RECEIVED_FOR_ACCOUNT}
`);

const GET_OFFERS_PLACED_FOR_ACCOUNT = `
  offersPlaced($address: String!) {
    offersPlaced: offers(where: {isActive: true, bidder: $address} orderBy: "timestamp" orderDirection: "desc" subgraphError: allow) {
      ${OFFERS_FIELDS}
    }
}`;

export const OFFERS_MADE_FOR_ACCOUNT = gql(`
  query ${GET_OFFERS_PLACED_FOR_ACCOUNT}
`);

export const GET_EDITION_ACTIVE_OFFER = gql`
    query GetEditionActiveOffer($edition: String!) {
        editionOffers: offers(where: {isActive: true, id: $edition, type: "Edition"} subgraphError: allow) {
            ${OFFERS_FIELDS}
        }
    }
`;

export const GET_TOKEN_ACTIVE_OFFER = gql`
    query GetEditionActiveOffer($token: String!) {
        tokenOffers: offers(where: {isActive: true, id: $token, type: "Token"} subgraphError: allow) {
            ${OFFERS_FIELDS}
        }
    }
`;
