import {isJsonString} from '../../utils/JsonHelper';
import Cookies from 'js-cookie'

export const state = () => ({
    account: null,
    username: null,
    email: null,
    hash: null
});

// synchronous way to update state
export const mutations = {
    setupFields(state, {
        account,
        username,
        email,
        hash

    }) {
        state.account = account;
        state.username = username;
        state.email = email;
        state.hash = hash;
    }
};

export const getters = {
    getAccount: state => state.account,
    getUsername: state => state.username,
    getEmail: state => state.email
};

// asynchronus operations
export const actions = {
    // initialise state here.
    async initFields({state, rootState, dispatch, commit}, {token, hash}) {
        const account = rootState.web3Store.account.toLowerCase();
        const user = await dispatch('userStore/getFullUser', token, {root: true});
        if (user) {
            const email = user.email;
            const username = user.username;

            commit('setupFields', {
                account,
                email,
                username,
                hash
            })
        }
    },

    async clearUserDetails({commit, dispatch}) {
        commit('setupFields', {
            account: null,
            username: null,
            email: null,
            hash: null
        })
        console.log('messenger store user details cleared')
        await dispatch('rebootMessenger', null);
    },
    // #region Intercom vue
    updateMessenger({state, rootState, dispatch, commit}, options = {}) {
        options = isJsonString(options) ? options : options = {};
        if (options === {}) { this.$intercom.update(); } else { this.$intercom.update(options); }
    },
    async rebootMessenger({state, rootState, dispatch, commit}, {user_id}) {
        await this.$intercom.shutdown();
        if (this.$intercom.isBooted) { return; }
        state.account = user_id
        await dispatch('bootMessenger');
    },
    async bootMessenger({state, rootState, dispatch, commit}) {
        for (const cookie in Cookies.get()) {
            if (cookie.includes('intercom')) {
              Cookies.remove(cookie, { path: '/', domain: '.knownorigin.io' })
            }
        }
        return new Promise((resolve, reject) => {
            if (!this.$intercom.ready) {
                this.$intercom.once('ready', () => {
                        this.$intercom.boot({
                            name: state.username,
                            user_id: state.account ? state.account.toLowerCase() : null,
                            email: state.email,
                            user_hash: state.hash
                        })
                    resolve();
                });
            } else if (!this.$intercom.isBooted) {
                this.$intercom.boot({
                    name: state.username,
                    user_id: state.account ? state.account.toLowerCase() : null,
                    email: state.email,
                    user_hash: state.hash
                })
                resolve();
            } else {
                reject('Failed to boot intercom messenger.')
            }
        })
    },
    // #endregion

    // #region API calls
    async updateUser({state, rootState, dispatch, commit}, data) {
        try {
            await this.$api.put('/intercom/update-user/', {
                account: data.account,
                username: data.username,
                email: data.email,
                customAttributes: data.customAttributes
            });
        } catch (err) {
            console.error(`Error occured on update user ${err}`);
        }
    },
    async getUser() {
        try {
            const response = await this.$api.get('/intercom/get-user/');
            return response.status;
        } catch (err) {
            console.error(`Error occured on getting user ${err}`);
            return null;
        }
    },
    async trackIntercomEvent({state, rootState, dispatch, commit}, event) {
        if (!state.account) { return; }

        const metadata = event.metadata ? event.metadata : {};

        try {
            // make sure user exists before trying to dispatch an event.
            const user = await dispatch('getUser');
            if (!user === 200) { return; }

            await this.$api.post('/intercom/create-event/', {
                eventName: event.eventName,
                account: state.account,
                metadata
            })
        } catch (err) {
            console.error(`Error occured on track event ${err}`);
        }
    },
    async addTag({state, rootState, dispatch, commit}, campaignName) {
        console.log(campaignName)
        if (!campaignName.username || !campaignName.email) {
          console.log('Failed to upload tag to intercom, invalid name or email')
          return;
        }
        try {
          const result = await this.$api.post('/intercom-campaign/find-and-create-user/', {
              name: campaignName.username,
              email: campaignName.email,
              campaignName: campaignName.campaignName
          });
          console.log(result)
        } catch (err) {
          console.log('Failed to upload tag to intercom', err)
        }
      },
      async launchMessenger({state, rootState, dispatch, commit}) {
        const {web3Store} = rootState;
        const {account, authToken} = web3Store;

        if (account) {
            if (authToken !== null && state.hash) {
                await dispatch('rebootMessenger', {user_id: account})
            } else {
                await dispatch('web3Store/createJwtSession', {}, {root: true});
                await dispatch('rebootMessenger', {user_id: account})
            }
        }

        this.$intercom.show()
      }
    // #endregion
}
