var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      _c("vue-countdown", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hidden,
            expression: "!hidden",
          },
        ],
        attrs: {
          time: _vm.diff,
          transform: _vm.transform,
          "emit-events": true,
        },
        on: { progress: _vm.handleCountdownProgress },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("section", [
                  !_vm.hideLabel
                    ? _c(
                        "p",
                        {
                          staticClass: "title",
                          class: { "has-text-white": _vm.whiteText === true },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.message) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "level is-mobile box",
                      class: { "has-text-white": _vm.whiteText === true },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "level-item has-text-centered" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "is-size-3 heading" }, [
                              _vm._v(_vm._s(props.days)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "heading" }, [
                              _vm._v("Days"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "level-item has-text-centered" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "is-size-3 heading" }, [
                              _vm._v(_vm._s(props.hours)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "heading" }, [
                              _vm._v("Hours"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "level-item has-text-centered" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "is-size-3 heading" }, [
                              _vm._v(_vm._s(props.minutes)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "heading" }, [
                              _vm._v("Minutes"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "level-item has-text-centered" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "is-size-3 heading" }, [
                              _vm._v(_vm._s(props.seconds)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "heading" }, [
                              _vm._v("Seconds"),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }