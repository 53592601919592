<template>
  <div data-cy="artist_bio" style="overflow-wrap: anywhere">
    <span
      class="is-block pb-4 is-family-primary"
      v-for="(para, index) in formatDescription"
      :key="index"
    >
      <span class="m-0 p-0 is-block is-family-primary" v-for="(line, index) in para" :key="index">
        <span v-html="line"></span>
      </span>
    </span>
    <span
      class="heading is-clickable has-text-weight-bold is-family-tertiary"
      @click="moreDescription"
      v-if="descriptionLength === 300 && linkifyDescription.length > 300 || descriptionLength === 150 && linkifyDescription.length > 150"
    >
      Expand <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
    </span>
  </div>
</template>
<script>
import * as linkify from 'linkifyjs/string';

export default {
  props: ['description'],
  data: () => ({
    descriptionLength: 300
  }),
  computed: {
    linkifyDescription() {
      return linkify(this.description || '', {
        target: '_blank',
        className: 'has-text-weight-normal'
      });
    },
    formatDescription() {
      const paragraphs = this.linkifyDescription
        .slice(0, this.descriptionLength)
        .split('\n\n');
      const lines = paragraphs.map((para) => {
        return para.split('\n');
      });
      return lines;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateDescriptionLength);
    this.updateDescriptionLength();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDescriptionLength);
  },
  methods: {
    moreDescription() {
      this.descriptionLength = this.linkifyDescription.length;
    },
    updateDescriptionLength() {
      if (window.innerWidth < 500) {
        this.descriptionLength = 150;
      } else {
        this.descriptionLength = 300;
      }
    }
  }
};
</script>
