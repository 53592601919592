var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "mt-6 mb-6 title-font pt-4 pb-4",
      class: { "rounded-container": _vm.isApplication },
    },
    [
      _c("div", { staticClass: "container content section" }, [
        _c(
          "h2",
          {
            staticClass:
              "column title is-size-3-tablet is-size-4-mobile has-text-centered mb-5 has-text-weight-black",
          },
          [_vm._v(_vm._s(_vm.cmsComponent.title))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns" },
          _vm._l(_vm.cmsComponent.editorial2Fields, function (blogPost) {
            return _c(
              "n-link",
              {
                key: blogPost.title,
                staticClass: "column is-one-third mb-4",
                attrs: { to: blogPost.link, target: _vm.newTabDecider },
              },
              [
                _c("div", { staticClass: "card-image" }, [
                  _c("figure", { staticClass: "image is-16by9" }, [
                    _c("img", {
                      staticClass: "journal-image-cover",
                      attrs: { src: blogPost.imageLink },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content is-paddingless" }, [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "is-family-tertiary has-margin-top-6 is-size-4 has-text-weight-bold",
                      },
                      [_vm._v(_vm._s(blogPost.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        blogPost.journalType
                          ? _c(
                              "b-tag",
                              {
                                staticClass: "is-uppercase is-family-primary",
                                attrs: { type: "is-secondary" },
                              },
                              [_vm._v(_vm._s(blogPost.journalType))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        blogPost.description
                          ? [_vm._v(_vm._s(blogPost.description))]
                          : _vm._e(),
                        _vm._v(" "),
                        blogPost.youtube
                          ? _c(
                              "span",
                              [_c("b-icon", { attrs: { icon: "youtube" } })],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    blogPost.date
                      ? _c(
                          "time",
                          { staticClass: "is-muted is-size-7 level is-mobile" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "level-left is-family-tertiary has-text-weight-bold",
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "level-item",
                                  attrs: { icon: "calendar", size: "is-small" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "level-item" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        new Date(blogPost.date),
                                        "from"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            )
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }