var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "modal-template",
        {
          attrs: {
            edition: _vm.offer.edition,
            "transaction-hash": _vm.transactionHash,
          },
        },
        [
          _c("template", { slot: "content-title" }, [
            _vm.isApproved
              ? _c("span", [_vm._v("\n      Accept offer\n    ")])
              : _c("span", [_vm._v("\n      Enable marketplace\n    ")]),
          ]),
          _vm._v(" "),
          !_vm.isApproved
            ? _c("template", { slot: "content-balance" }, [
                _vm._v("\n     \n  "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isApproved
            ? _c(
                "template",
                { slot: "content-sub-title" },
                [
                  _c("artwork-name-and-artist", {
                    attrs: {
                      metadata: _vm.offer.edition.metadata,
                      "edition-number": _vm.offer.edition.id,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
            [
              _vm.isApproved
                ? _c(
                    "section",
                    { staticClass: "has-padding-top-7 has-padding-bottom-7" },
                    [
                      _c("highest-offer-label", {
                        attrs: {
                          "wei-value": _vm.offer.weiValue,
                          bidder: _vm.offer.bidder.address,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-message",
                        {
                          staticClass: "is-size-7 mt-2",
                          attrs: {
                            type: "is-info",
                            closable: false,
                            duration: 0,
                          },
                        },
                        [
                          _vm._v(
                            "\n        You will receive approximately\n        "
                          ),
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": _vm.finalAmount,
                              "override-dp": 3,
                            },
                          }),
                          _vm._v(
                            "\n        after royalties and platform fees\n        "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://knownorigin.io/journal/platformupdate/making-a-secondary-marketplace",
                                target: "_blank",
                              },
                            },
                            [_vm._v("\n          Learn more\n        ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("section", [
                    _c("p", { staticClass: "title" }, [
                      _vm._v(
                        "\n        You will need to approve the new\n        "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.marketplaceAddress,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n          KnownOrigin marketplace smart contract\n        "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("section", [
                      _vm._v(
                        "\n        You only need to do this once. The smart contract allows you to resell your artworks, handle payments &\n        royalties and to move tokens on your behalf.\n      "
                      ),
                    ]),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c("template", { slot: "action-feedback-label" }, [
            _vm.isApproved
              ? _c("span", [_vm._v("\n      Offer accepted\n    ")])
              : _c("span", [_vm._v("\n      Enable marketplace\n    ")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "card-footer-item",
              attrs: { slot: "action-button" },
              slot: "action-button",
            },
            [
              _vm.isApproved
                ? _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { expanded: "", disabled: _vm.disableOfferButton },
                      on: { click: _vm.acceptOffer },
                    },
                    [_vm._v("\n      Accept offer\n    ")]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { expanded: "", disabled: _vm.transactionHash },
                      on: { click: _vm.approveMarketplace },
                    },
                    [_vm._v("\n      Approve marketplace\n    ")]
                  ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }