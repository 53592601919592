<template>
  <p class="has-text-centered">
    <a class="is-link is-linkable is-muted" @click="buyEth">Need more ETH?</a>
  </p>
</template>
<script>
import WertTopUpModal from './modal/wert/WertTopUpModal';

export default {
  methods: {
    async buyEth() {
      this.$buefy.modal.open({
        parent: this,
        component: WertTopUpModal,
        canCancel: ['x'],
        width: 600
      });
    }
  }
};
</script>>
