var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Delete / Burn\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _vm.canPartialBurn
            ? _c(
                "section",
                [
                  _c("p", { staticClass: "has-text-weight-semibold" }, [
                    _vm._v(
                      "\n        Unsold tokens will be sent to the\n        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "is-linkable",
                        attrs: {
                          href: "https://etherscan.io/address/0x000000000000000000000000000000000000dead",
                        },
                      },
                      [_vm._v("0xdead")]
                    ),
                    _vm._v(" address\n      "),
                  ]),
                  _vm._v(" "),
                  _vm.edition.remainingSupply > 1
                    ? _c(
                        "section",
                        [
                          _c("b-message", { attrs: { type: "is-info" } }, [
                            _vm._v(
                              "By default all remaining tokens in edition will be\n        burnt. If you wish to burn a limited number of tokens you can do so by selecting the partial burn option below"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-switch",
                            {
                              model: {
                                value: _vm.editionRangeSelected,
                                callback: function ($$v) {
                                  _vm.editionRangeSelected = $$v
                                },
                                expression: "editionRangeSelected",
                              },
                            },
                            [_vm._v("\n          Partial burn\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editionRangeSelected
                    ? _c(
                        "b-field",
                        { attrs: { label: "Number of tokens" } },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "1",
                              min: "1",
                              max: _vm.edition.remainingSupply,
                              type: "number",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.editionRange,
                              callback: function ($$v) {
                                _vm.editionRange = $$v
                              },
                              expression: "editionRange",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("section", [
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Once the transaction has been confirmed, the artworks will be removed from your profile, this can take a few\n        minutes.\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.edition.isEnhancedEdition
              ? _c(
                  "div",
                  [
                    _c(
                      "b-message",
                      {
                        attrs: {
                          title: "WARNING ABOUT BURNING EMBEDDED TOKENS",
                          type: "is-danger",
                          "has-icon": "",
                          closable: false,
                        },
                      },
                      [
                        _vm._v(
                          "\n        If you burn an embedded token you will lose access to the token embedded within "
                        ),
                        _c("strong", [_vm._v("FOREVER")]),
                        _vm._v(
                          " please only burn if you are happy with this.\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Burning\n  "),
      ]),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-danger",
                expanded: "",
                disabled: _vm.isDisabled,
              },
              on: { click: _vm.burn },
            },
            [_vm._v("\n      Delete / Burn\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }