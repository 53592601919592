var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: {
        href: "https://docs.knownorigin.io/en/articles/6554600-composing-other-tokens",
        target: "_blank",
      },
    },
    [
      _c(
        "b-tag",
        { staticClass: "is-dark is-uppercase hand-pointer" },
        [
          _c("b-icon", {
            attrs: { icon: "set-center", type: "is-light", size: "is-small" },
          }),
          _vm._v("\n    EMBEDDED ITEMS\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }