// Setup search insights for event logging
import aa from 'search-insights';

const APP_ID = `${process.env.ALGOLIA_APP_ID}`
const API_KEY = `${process.env.ALGOLIA_API_KEY}`
// const API_ROUTE = 'https://insights.algolia.io/1/events'

aa('init', {
  appId: APP_ID,
  apiKey: API_KEY
})

export const state = () => ({
  userToken: '0x0000000000000000000000000000000000000000' // Use 0 address for default, no wallet connected
});

export const mutations = {
  setUserToken(state, {userToken}) {
    state.userToken = userToken
  }
}

export const actions = {
  async setUserToken({rootState, commit}) {
    const {web3Store} = rootState;
    const {account} = web3Store
    commit('setUserToken', {userToken: account})
  },

  clearUserToken({commit}) {
    commit('setUserToken', {userToken: '0x0000000000000000000000000000000000000000'})
  },

  // async sendInsightsEvent({state, dispatch}, data) {
  //   try {
  //     data.userToken = state.userToken
  //
  //     await this.$axios.$post(API_ROUTE, {events: [data]}, {
  //       headers: {
  //         'X-Algolia-Application-Id': APP_ID,
  //         'X-Algolia-API-Key': API_KEY,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //   } catch (err) {
  //     console.error(err)
  //   }
  // },

  async sendClickedFilterEvent({state, dispatch}, data) {
    try {
      aa('clickedFilters', {
          userToken: state.userToken,
          index: data.index,
          eventName: data.eventName,
          filters: data.filters
        })
    } catch (err) {
      console.error(err)
    }
  },

  async sendObjectClickedEvent({state, dispatch}, data) {
    try {
      aa('clickedObjectIDsAfterSearch', {
        userToken: state.userToken,
        eventName: data.eventName,
        index: data.index,
        queryID: data.queryId,
        objectIDs: [data.objectId],
        positions: [data.positions]
      })
    } catch (err) {
      console.error(err)
    }
  },

  async convertedObjectIDsAfterSearch({state, dispatch}, data) {
    try {
      aa('convertedObjectIDsAfterSearch', {
        userToken: state.userToken,
        eventName: data.eventName,
        index: data.index,
        queryID: data.queryId,
        objectIDs: [data.objectId]
      });
    } catch (err) {
      console.error(err)
    }
  }
}
