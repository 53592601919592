import {ethers} from 'ethers';

import contracts, { abi} from 'koda-contract-tools';
import _takeRight from 'lodash/takeRight';
import _difference from 'lodash/difference';
import {
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isOffersSalesType,
  isReserveAuctionSalesType,
  isSteppedSalesType
} from '../services/SaleTypes';

import {isCreatorContract, splitCreatorEditionId} from '../services/CreatorContractUtils';
import { UNSOLD_CREATOR_EDITIONS } from '../queries/editionQueries';

export const state = () => ({
  // KODA V2
  kodaV2: null,
  auctionV2: null, // primary marketplace KODA V2
  marketplaceV2: null, // secondary marketplace KODA V2
  artistControlsV2: null, // artist contract KODA V2
  artistEditionBurnerV1: null, // artist burner KODA V2

  // KODA V3
  kodaV3: null,
  kodaV3PrimaryMarketplace: null,
  kodaV3SecondaryMarketplace: null,
  kodaV3GatedMarketplace: null,

  // KODA V4 vars not needed
  kodaV4: null
});

export const mutations = {
  setupContracts(state, {
    kodaV2,
    auctionV2,
    marketplaceV2,
    artistEditionBurnerV1,
    artistControlsV2,
    kodaV3,
    kodsV3PrimaryMarketplace,
    kodaV3SecondaryMarketplace,
    kodaV3GatedMarketplace,
    merkleVault
  }) {
    // V2
    state.kodaV2 = kodaV2;
    state.auctionV2 = auctionV2;
    state.marketplaceV2 = marketplaceV2;
    state.artistControlsV2 = artistControlsV2;
    state.artistEditionBurnerV1 = artistEditionBurnerV1;
    // // V3
    state.kodaV3 = kodaV3;
    state.kodaV3PrimaryMarketplace = kodsV3PrimaryMarketplace;
    state.kodaV3SecondaryMarketplace = kodaV3SecondaryMarketplace;
    state.kodaV3GatedMarketplace = kodaV3GatedMarketplace;
    // MerkleVault
    state.merkleVault = merkleVault;
  }
};

export const getters = {};

const DEFAULT_GAS_BUFFER = 10000;
const V3_BUY_GAS_BUFFER = 30000;

export const actions = {

  initContracts({state, rootState, dispatch, commit}) {
    const {web3Store} = rootState;
    const {web3, chainId} = web3Store;

    console.log(`Init contract - chain ID [${chainId}]`);

    const kodaV2 = new ethers.Contract(contracts.getKodaV2Address(chainId), abi.kodaV2, web3.getSigner());

    let auctionV2;
    let marketplaceV2;
    let artistEditionBurnerV1;
    let artistControlsV2;

    // KODA V2 supporting contracts are only mainnet
    if (chainId === 1) {
      auctionV2 = new ethers.Contract(contracts.getAuctionV2Address(chainId), abi.auctionV2, web3.getSigner());
      marketplaceV2 = new ethers.Contract(contracts.getTokenMarketplaceV2Address(chainId), abi.tokenMarketplaceV2, web3.getSigner());
      artistEditionBurnerV1 = new ethers.Contract(contracts.getArtistEditionBurnerAddress(chainId), abi.artistEditionBurnerV1, web3.getSigner());
      artistControlsV2 = new ethers.Contract(contracts.getArtistControlsV2Address(chainId), abi.artistControlsV2, web3.getSigner());
    }

    // KODA V3
    const kodaV3 = new ethers.Contract(contracts.getKodaV3Address(chainId), abi.kodaV3, web3.getSigner());
    const kodsV3PrimaryMarketplace = new ethers.Contract(contracts.getKodaV3PrimaryMarketV1Address(chainId), abi.kodaV3PrimaryMarketV1, web3.getSigner());
    const kodaV3SecondaryMarketplace = new ethers.Contract(contracts.getKodaV3SecondaryMarketV1Address(chainId), abi.kodaV3SecondaryMarketV1, web3.getSigner());
    const kodaV3GatedMarketplace = new ethers.Contract(contracts.getKodaV3UpgradableGatedSaleAddress(chainId), abi.kodaV3UpgradableGatedMarketplaceAbi, web3.getSigner());
    // MerkleVault
    const merkleVault = new ethers.Contract(contracts.getMerkleVaultAddress(chainId), abi.merkleVault, web3.getSigner());

    commit('setupContracts', {
      // V2
      kodaV2,
      auctionV2,
      marketplaceV2,
      artistControlsV2,
      artistEditionBurnerV1,
      // V3
      kodaV3,
      kodsV3PrimaryMarketplace,
      kodaV3SecondaryMarketplace,
      kodaV3GatedMarketplace,
      // MerkleVault
      merkleVault
    });
  },

  async purchaseEdition({state, rootState, dispatch, commit}, {edition, priceInWei, version}) {
    console.log('purchaseEdition', {edition, priceInWei, version});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    if (parseInt(version) === 2) {
      const gasEstimate = await state.kodaV2.estimateGas.purchase(edition, {from: account, value: priceInWei});
      const tx = await state.kodaV2.purchase(edition, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER),
        value: priceInWei
      });

      notifyService.hash(tx.hash);

      return tx;
    } else if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.buyEditionToken(edition, {
        from: account,
        value: priceInWei
      });

      const tx = await state.kodaV3PrimaryMarketplace.buyEditionToken(edition, {
        from: account,
        gasLimit: gasEstimate.add(V3_BUY_GAS_BUFFER),
        value: priceInWei
      });

      notifyService.hash(tx.hash);

      return tx;
    }
  },

  async purchaseEditionV4({state, rootState, dispatch, commit}, {edition}) {
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {web3, account, notifyService} = web3Store;

    const ccContractMarketplace = new ethers.Contract(edition.creatorContract.id, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());

    const editionSplit = splitCreatorEditionId(edition.id)
    const gasEstimate = await ccContractMarketplace.estimateGas.buyEditionToken(editionSplit.editionId, account, {
      from: account,
      value: edition.priceInWei
    })

    const tx = await ccContractMarketplace.buyEditionToken(editionSplit.editionId, account, {
      from: account,
      gasLimit: gasEstimate.add(V3_BUY_GAS_BUFFER),
      value: edition.priceInWei
    });

    notifyService.hash(tx.hash);

    return tx;
  },

  async purchaseGated({state, rootState, dispatch, commit}, {phase, proofs, mintCount, priceInWei, collaborators}) {
    console.log('purchaseGated', {phase, proofs, mintCount, priceInWei, collaborators});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    const gasEstimate = await state.kodaV3GatedMarketplace.estimateGas.mint(
      phase.saleId,
      phase.phaseId,
      mintCount,
      proofs.index,
      proofs.proof,
      {
        from: account,
        value: priceInWei === '0' && collaborators ? '1' : priceInWei
      });

    const tx = await state.kodaV3GatedMarketplace.mint(
      phase.saleId,
      phase.phaseId,
      mintCount,
      proofs.index,
      proofs.proof,
      {
        from: account,
        gasLimit: gasEstimate.add(V3_BUY_GAS_BUFFER),
        value: priceInWei === '0' && collaborators ? '1' : priceInWei
      });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async startWertPurchase({state, rootState, dispatch},
                          {artwork_id, entity_type, koda_version, price_in_ether, sales_type, edition_or_token}) {
    console.log('Starting Wert purchase', {artwork_id, entity_type, koda_version, price_in_ether, sales_type, edition_or_token});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {account, chainId} = web3Store;

    // Confirm they are auth'd
    await dispatch('web3Store/createJwtSession', {}, {root: true});

    const buyer = account;
    const isPrimarySale = entity_type === 'edition';
    const kodaVersion = koda_version.toString();

    let currentChainId = chainId;
    const priceInEther = price_in_ether.toString();

    let encodedCall;
    let contractAddress;

    let artworkId = artwork_id.toString(); // edition or token ID
    let wertEnvURL = 'https://widget.wert.io'; // production by default

    // check if we are on goerli
    if (parseInt(chainId) === 5) {
      wertEnvURL = 'https://sandbox.wert.io';
      currentChainId = '5';
    }
      // Otherwise, we need to configure the smart contract and method to call based on whether it's a primary sale and what KODA version we want to talk to
    // Version 2 logic
     if (kodaVersion === '2') {
      // Note: we only support primary sales credit card purchases
      if (isPrimarySale) {
        contractAddress = state.kodaV2.address;
        encodedCall = state.kodaV2.interface.encodeFunctionData(
          'purchaseTo',
          [buyer, artworkId]
        );
      }
    }
    // Version 3 logic
    else if (kodaVersion === '3') {
      if (isPrimarySale) {
        contractAddress = state.kodaV3PrimaryMarketplace.address;
        if (isBuyNowSalesType(sales_type)) {
          encodedCall = state.kodaV3PrimaryMarketplace.interface.encodeFunctionData(
            'buyEditionTokenFor',
            [artworkId, buyer]
          );
        } else if (isSteppedSalesType(sales_type)) {
          encodedCall = state.kodaV3PrimaryMarketplace.interface.encodeFunctionData(
            'buyNextStepFor',
            [artworkId, buyer]
          );
        } else if (isReserveAuctionSalesType(sales_type)) {
          encodedCall = state.kodaV3PrimaryMarketplace.interface.encodeFunctionData(
            'placeBidOnReserveAuctionFor',
            [artworkId, buyer]
          );
        } else if (isOffersSalesType(sales_type)) {
          encodedCall = state.kodaV3PrimaryMarketplace.interface.encodeFunctionData(
            'placeEditionBidFor',
            [artworkId, buyer]
          );
        } else {
          console.error(`Unknown sales type [${sales_type}] for wert purchase`);
          return;
        }
      } else {
        contractAddress = state.kodaV3SecondaryMarketplace.address;
        if (isBuyNowSalesType(sales_type)) {
          encodedCall = state.kodaV3SecondaryMarketplace.interface.encodeFunctionData(
            'buyEditionTokenFor',
            [artworkId, buyer]
          );
        } else if (isOffersSalesType(sales_type)) {
          encodedCall = state.kodaV3SecondaryMarketplace.interface.encodeFunctionData(
            'placeTokenBidFor',
            [artworkId, buyer]
          );
        } else if (isReserveAuctionSalesType(sales_type)) {
          encodedCall = state.kodaV3SecondaryMarketplace.interface.encodeFunctionData(
            'placeBidOnReserveAuctionFor',
            [artworkId, buyer]
          );
        } else {
          console.error(`Unknown sales type [${sales_type}] for wert purchase`);
          return;
        }
        // TODO add other secondary sales open edition offers flow once we have implemented
        // placeEditionBidFor(uint256 _editionId, address _bidder)
      }
    } else if (kodaVersion === '4') {
      const {web3Store} = rootState;
      const {web3} = web3Store;
      const ccContractMarketplace = new ethers.Contract(edition_or_token.creatorContract.id, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());
       if (isPrimarySale) {
        const data = splitCreatorEditionId(artworkId);
        artworkId = data.editionId;
        artwork_id = artworkId;
        contractAddress = data.contract;
        if (isBuyNowSalesType(sales_type)) {
          encodedCall = ccContractMarketplace.interface.encodeFunctionData(
            'buyEditionToken',
            [artworkId, buyer]
          )
        }
      }
    }
    const price = parseFloat(priceInEther).toString() // strip trailing zeros
    const paymentRequest = {
      tx: {
        address: buyer.toLowerCase(),
        commodity: 'ETH',
        commodity_amount: price,
        sc_address: contractAddress,
        sc_input_data: encodedCall
      },
      artwork_id,
      entity_type,
      koda_version,
      sales_type
    };

    console.log('Sending payment request', paymentRequest);

    const {data} = await this.$api.post(`/network/${currentChainId}/wert/sign-tx`, paymentRequest);

    console.log('Payment request set', data);

    return {
      click_id: data.click_id,
      partner_id: data.partner_id,
      origin: wertEnvURL,
      address: buyer,
      commodity: 'ETH',
      commodity_amount: price,
      pk_id: 'key1',
      sc_address: contractAddress,
      sc_id: data.tx.sc_id, // internal tx ID
      sc_input_data: encodedCall,
      signature: data.signature
    };
  },

  async startWertEthTopUp({state, rootState, dispatch}, {amount}) {
    const {web3Store} = rootState;
    const {account, chainId} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    // Confirm they are auth'd
    await dispatch('web3Store/createJwtSession', {}, {root: true});

    const buyer = account;
    let currentChainId = chainId;
    let wertEnvURL = 'https://widget.wert.io'; // production by default

    if (parseInt(chainId) === 4) {
      // default to ropsten Koda V2 contract address as sandbox only works on ropsten
      wertEnvURL = 'https://sandbox.wert.io';
      currentChainId = '3';
    }

    const {data} = await this.$api.post(`/network/${currentChainId}/wert/sign-tx`, {
      tx: {
        address: buyer.toLowerCase(),
        commodity: 'ETH',
        commodity_amount: amount
      },
      artwork_id: 'null',
      entity_type: 'eth_top_up',
      koda_version: '*'
    });

    return {
      click_id: data.click_id,
      partner_id: data.partner_id,
      origin: wertEnvURL,
      address: buyer,
      commodity: 'ETH',
      commodity_amount: amount,
      pk_id: 'key1',
      sc_id: data.tx.sc_id, // internal tx ID
      signature: data.signature
    };
  },

  async getWertPurchaseState({state, rootState}, {click_id}) {
    const {web3Store} = rootState;
    /* eslint-disable-next-line prefer-const */
    let {chainId} = web3Store;

    // Wert support ropsten only so flip to ropsten for test purchases...
    if (parseInt(chainId) === 4) {
      chainId = 3;
    }

    return this.$api.get(`/network/${chainId}/wert/${click_id}/web-hook-events`);
  },

  async purchaseEditionStep({state, rootState, dispatch, commit}, {edition, priceInWei, version}) {
    console.log('purchaseEditionStep', {edition, priceInWei, version});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.buyNextStep(edition, {
      from: account,
      value: priceInWei
    });

    const tx = await state.kodaV3PrimaryMarketplace.buyNextStep(edition, {
      from: account,
      gasLimit: gasEstimate.add(V3_BUY_GAS_BUFFER),
      value: priceInWei
    });

    notifyService.hash(tx.hash);

    return tx;
  },

  async makeEditionOffer({state, rootState, dispatch, commit}, {edition, offerAmount, version, salesType}) {
    console.log('makeEditionOffer', {edition, offerAmount, version, salesType});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;
    if (parseInt(version) === 2) {
      const gasEstimate = await state.auctionV2.estimateGas.placeBid(edition, {from: account, value: offerAmount});
      tx = await state.auctionV2.placeBid(edition, {
        from: account,
        value: offerAmount,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    } else if (parseInt(version) === 3) {
      if (isReserveAuctionSalesType(salesType)) {
        const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.placeBidOnReserveAuction(edition, {
          from: account,
          value: offerAmount
        });
        tx = await state.kodaV3PrimaryMarketplace.placeBidOnReserveAuction(edition, {
          from: account,
          value: offerAmount,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      } else {
        const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.placeEditionBid(edition, {
          from: account,
          value: offerAmount
        });
        tx = await state.kodaV3PrimaryMarketplace.placeEditionBid(edition, {
          from: account,
          value: offerAmount,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      }
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async withdrawEditionOffer({state, rootState, dispatch, commit}, {edition, version}) {
    console.log('withdrawEditionOffer', {edition, version});

    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const editionId = edition.id;

    let tx;

    if (parseInt(version) === 2) {
      const gasEstimate = await state.auctionV2.estimateGas.withdrawBid(editionId, {from: account});
      tx = await state.auctionV2.withdrawBid(editionId, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    }
    // V3
    else if (parseInt(version) === 3) {
      // Reserves
      if (isReserveAuctionSalesType(edition.salesType) && edition.reserveAuctionCanEmergencyExit) {
        const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.emergencyExitBidFromReserveAuction(editionId, {from: account});
        tx = await state.kodaV3PrimaryMarketplace.emergencyExitBidFromReserveAuction(editionId, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      } else if (isReserveAuctionSalesType(edition.salesType)) {
        const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.withdrawBidFromReserveAuction(editionId, {from: account});
        tx = await state.kodaV3PrimaryMarketplace.withdrawBidFromReserveAuction(editionId, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      } else {
        // Offers
        const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.withdrawEditionBid(editionId, {from: account});
        tx = await state.kodaV3PrimaryMarketplace.withdrawEditionBid(editionId, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      }
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async acceptEditionOffer({state, rootState, dispatch, commit}, {edition, offerPrice, version}) {
    console.log('acceptEditionOffer', {edition, offerPrice, version});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    let tx;

    if (parseInt(version) === 2) {
      const gasEstimate = await state.auctionV2.estimateGas.acceptBid(edition, {from: account});
      tx = await state.auctionV2.acceptBid(edition, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    } else if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.acceptEditionBid(edition, offerPrice, {from: account});
      tx = await state.kodaV3PrimaryMarketplace.acceptEditionBid(edition, offerPrice, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async rejectEditionOffer({state, rootState, dispatch, commit}, {edition, version}) {
    console.log('rejectEditionOffer', {edition, version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 2) {
      const gasEstimate = await state.auctionV2.estimateGas.rejectBid(edition, {from: account});
      tx = await state.auctionV2.rejectBid(edition, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    } else if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.rejectEditionBid(edition, {from: account});
      tx = await state.kodaV3PrimaryMarketplace.rejectEditionBid(edition, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async resultEditionReserveAuction({state, rootState, dispatch, commit}, {editionId, version}) {
    console.log('resultEditionReserveAuction', {editionId, version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;
    if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3PrimaryMarketplace.estimateGas.resultReserveAuction(editionId, {from: account});
      tx = await state.kodaV3PrimaryMarketplace.resultReserveAuction(editionId, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    }
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async resultTokenReserveAuction({state, rootState, dispatch, commit}, {tokenId, version}) {
    console.log('resultTokenReserveAuction', {tokenId, version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;
    if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.resultReserveAuction(tokenId, {from: account});
      tx = await state.kodaV3SecondaryMarketplace.resultReserveAuction(tokenId, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });
    }
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async approveTokenMarketplace({state, rootState, dispatch}, {version}) {
    console.log('approveTokenMarketplace', {version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;
    if (parseInt(version) === 2) {
      console.log('Approving V2 secondary marketplace');
      tx = await state.kodaV2.setApprovalForAll(state.marketplaceV2.address, true, {from: account});
      notifyService.hash(tx.hash);
    }
    if (parseInt(version) === 3) {
      console.log('Approving V3 secondary marketplace');
      tx = await state.kodaV3.setApprovalForAll(state.kodaV3SecondaryMarketplace.address, true, {from: account});
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  /* eslint-disable-next-line no-empty-pattern */
  async getTokenMarketplaceAddress({rootState}, {version, network}) {
    console.log('getTokenMarketplaceAddress', {version, network});
    const {web3Store} = rootState;
    const {chainId} = web3Store;

    let address;
    if (version === '2') {
      if (parseInt(chainId) === 1 || parseInt(chainId) === 4 || parseInt(chainId) === 5) {
        address = await contracts.getTokenMarketplaceV2Address(network);
      }
    } else if (version === '3') {
      address = await contracts.getKodaV3SecondaryMarketV1Address(network)
    }

    return address
  },

  async getPrimarySaleMarketplaceAddress({rootState}, {version}) {
    console.log('getPrimarySaleMarketplaceAddress', {version});
    const {web3Store} = rootState;
    const {chainId} = web3Store;
    let address;
    if (version === '2') {
      if (chainId === 1 || chainId === 4 || parseInt(chainId) === 5) {
        address = await contracts.getKodaV2Address(chainId)
      }
    } else if (version === '3') {
      address = await contracts.getKodaV3PrimaryMarketV1Address(chainId)
    }
    return address
  },

  async checkApprovalStatus({rootState}, {owner, operator}) {
    console.log('checkApprovalStatus', {owner, operator});
    const {web3Store} = rootState;
    const {chainId} = web3Store;
    try {
      const approvalCheck = await this.$api(`/network/${chainId}/approval/owner/${owner.toLowerCase()}/operator/${operator.toLowerCase()}`);
      return approvalCheck.data.isApproved;
    } catch (e) {
      console.log('Failed to get approval - assume approval exists', e);
      return true;
    }
  },

  async makeTokenOffer({state, rootState, dispatch, commit}, {token, offerAmount, version, salesType}) {
    console.log('makeTokenOffer', {token, offerAmount, version, salesType});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      if (isOffersOnlySalesType(salesType)) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.placeTokenBid(token, {
          from: account,
          value: offerAmount
        });
        tx = await state.kodaV3SecondaryMarketplace.placeTokenBid(token, {
          from: account,
          value: offerAmount,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      }
      if (isReserveAuctionSalesType(salesType)) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.placeBidOnReserveAuction(token, {
          from: account,
          value: offerAmount
        });
        tx = await state.kodaV3SecondaryMarketplace.placeBidOnReserveAuction(token, {
          from: account,
          value: offerAmount,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      }

      if (tx) {
        notifyService.hash(tx.hash);
      }
      return tx;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.placeBid(token, {from: account, value: offerAmount});

    // Always place new offers on the new contract
    tx = await state.marketplaceV2.placeBid(token, {
      from: account,
      value: offerAmount,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async withdrawTokenOffer({state, rootState, dispatch, commit}, {token, version, salesType, emergencyWithdraw}) {
    console.log('withdrawTokenOffer', {token, version, salesType, emergencyWithdraw});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      if (emergencyWithdraw) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.emergencyExitBidFromReserveAuction(token, {from: account});
        tx = await state.kodaV3SecondaryMarketplace.emergencyExitBidFromReserveAuction(token, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      } else if (isReserveAuctionSalesType(salesType)) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.withdrawBidFromReserveAuction(token, {from: account});
        tx = await state.kodaV3SecondaryMarketplace.withdrawBidFromReserveAuction(token, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      } else {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.withdrawTokenBid(token, {from: account});
        tx = await state.kodaV3SecondaryMarketplace.withdrawTokenBid(token, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
      }

      if (tx) {
        notifyService.hash(tx.hash);
      }

      return tx;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.withdrawBid(token, {from: account});

    tx = await state.marketplaceV2.withdrawBid(token, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async rejectTokenOffer({state, rootState, dispatch, commit}, {token, version}) {
    console.log('rejectTokenOffer', {token, version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.rejectTokenBid(token, {from: account});

      tx = await state.kodaV3SecondaryMarketplace.rejectTokenBid(token, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });

      if (tx) {
        notifyService.hash(tx.hash);
      }

      return tx;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.rejectBid(token, {from: account});

    tx = await state.marketplaceV2.rejectBid(token, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async acceptTokenOffer({state, rootState, dispatch, commit}, {token, offerAmount, version}) {
    console.log('acceptTokenOffer', {token, version, offerAmount});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.acceptTokenBid(token, offerAmount, {from: account});

      tx = await state.kodaV3SecondaryMarketplace.acceptTokenBid(token, offerAmount, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
      });

      if (tx) {
        notifyService.hash(tx.hash);
      }

      return tx;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.acceptBid(token, offerAmount, {from: account});

    // V2 introduced a bug fix around offer snipping
    tx = await state.marketplaceV2.acceptBid(token, offerAmount, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async listToken({state, rootState, dispatch, commit}, {token, listAmount}) {
    console.log('listToken', {token, listAmount});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.listToken(token, listAmount, {from: account});

    const tx = await state.marketplaceV2.listToken(token, listAmount, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async sendTransaction({state, rootState, dispatch, commit}, transaction) {
    const {web3Store} = rootState;
    const {web3} = web3Store;

    try {
      const tx = await web3.getSigner().sendTransaction(transaction)
      await tx.wait(1);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async signTokenForSecondary({state, rootState, dispatch, commit}, signature) {
    const {web3Store} = rootState;
    const {web3} = web3Store;

    if (signature) {
      const {domain, types, value} = signature;
      try {
      const signedData = await web3.getSigner()._signTypedData(domain, types, value);

      const signedOrderRequest = {
        orderData: signature.body,
        signature: signedData
      }
      return signedOrderRequest;
    } catch (error) {
      console.error(error);
      return false;
    }
    }
  },

  async delistToken({state, rootState, dispatch, commit}, {token, version, salesType}) {
    console.log('delistToken', {token, version, salesType});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      if (isBuyNowSalesType(salesType)) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.delistToken(token, {from: account});
        tx = await state.kodaV3SecondaryMarketplace.delistToken(token, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
        if (tx) {
          notifyService.hash(tx.hash);
        }
        return tx;
      }

      if (isReserveAuctionSalesType(salesType)) {
        const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.convertReserveAuctionToOffers(token, {from: account});
        tx = await state.kodaV3SecondaryMarketplace.convertReserveAuctionToOffers(token, {
          from: account,
          gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
        });
        if (tx) {
          notifyService.hash(tx.hash);
        }
        return tx;
      }
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.delistToken(token, {from: account});

    tx = await state.marketplaceV2.delistToken(token, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async purchaseToken({state, rootState, dispatch, commit}, {token, listPrice, version}) {
    console.log('purchaseToken', {token, listPrice, version});
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 3) {
      const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.buyEditionToken(token, {
        from: account,
        value: listPrice
      });
      tx = await state.kodaV3SecondaryMarketplace.buyEditionToken(token, {
        from: account,
        gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER),
        value: listPrice
      });
      if (tx) {
        notifyService.hash(tx.hash);
      }
      return tx;
    }

    const gasEstimate = await state.marketplaceV2.estimateGas.buyToken(token, {from: account, value: listPrice});

    tx = await state.marketplaceV2.buyToken(token, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER),
      value: listPrice
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async transferToken({state, rootState, dispatch, commit}, {token, address, version}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store;

    let tokenId;
    let contractId;

    if (isCreatorContract(token)) {
      const {editionId, contract} = splitCreatorEditionId(token.id)
      tokenId = editionId
      contractId = contract
    } else {
      tokenId = token.id
    }

    console.log('transferToken', {tokenId, address, account, version});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 2) {
      tx = await state.kodaV2['transferFrom(address,address,uint256)'](account, address, tokenId, {from: account});
    } else if (parseInt(version) === 3) {
      tx = await state.kodaV3['transferFrom(address,address,uint256)'](account, address, tokenId, {from: account});
    } else if (parseInt(version) === 4) {
      const ccContractMarketplace = new ethers.Contract(contractId, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());
      tx = await ccContractMarketplace['safeTransferFrom(address,address,uint256)'](account, address, tokenId, {from: account})
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async burn({state, rootState, dispatch, commit}, {edition, version, editionRange}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store;
    const gqlProvider = this.app.apolloProvider.clients[web3Store.gqlClient]

    console.log('burn edition', {edition, version, editionRange});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const DEAD_ADDRESS = '0x000000000000000000000000000000000000dead';
    let tx;

    if (parseInt(version) === 2) {
      tx = await state.artistEditionBurnerV1['deactivateOrReduceEditionSupply(uint256)'](edition, {from: account});
    }

    if (parseInt(version) === 3) {
      const _tokenIds = await state.kodaV3.callStatic.getAllUnsoldTokenIdsForEdition(edition);
      if (editionRange) {
        const _selectedTokenIds = _takeRight(_tokenIds, editionRange)
        tx = await state.kodaV3.batchTransferFrom(account, DEAD_ADDRESS, _selectedTokenIds, {from: account});
      } else {
        tx = await state.kodaV3.batchTransferFrom(account, DEAD_ADDRESS, _tokenIds, {from: account});
      }
    }

    if (parseInt(version) === 4) {
      const { contract, editionId } = splitCreatorEditionId(edition);

      const { data } = await gqlProvider.query({
        query: UNSOLD_CREATOR_EDITIONS,
        variables: {
          id: edition
        }
      });

      const { totalAvailable, totalSold, tokenIds } = data.edition;
      const remainingTokens = totalAvailable - totalSold;
      const tokenIdsToBurn = [];

      for (let i = 0; i < remainingTokens; i++) {
        const tokenId = Number(editionId) + i;
        const editionIdToBurn = `${contract}-${tokenId}`;
        tokenIdsToBurn.push(editionIdToBurn);
      }

      let filteredTokenIds = _difference(tokenIdsToBurn, tokenIds).map(tokenId => splitCreatorEditionId(tokenId).editionId);

      // If it's a partial burn take only the range applied!
      if (editionRange && editionRange > 0) {
        filteredTokenIds = _takeRight(filteredTokenIds, editionRange)
      }
      const ccContractMarketplace = new ethers.Contract(contract, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());
      const gasEstimate = await ccContractMarketplace.estimateGas.batchTransferFrom(account, DEAD_ADDRESS, filteredTokenIds, {from: account});
      tx = await ccContractMarketplace['batchTransferFrom(address,address,uint256[])'](account, DEAD_ADDRESS, filteredTokenIds, {from: account, gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)})
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async changePrice({state, rootState, dispatch, commit}, {edition, priceInWei, stepPriceInWei, salesType, version, creatorContract}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store;
    console.log('change edition price', {edition, priceInWei, stepPriceInWei, salesType, version, creatorContract});
    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 2) {
      tx = await state.artistControlsV2.updateEditionPrice(edition, priceInWei, {
        from: account
      });
    }

    if (parseInt(version) === 3) {
      if (isReserveAuctionSalesType(salesType)) {
        tx = await state.kodaV3PrimaryMarketplace.updateReservePriceForReserveAuction(edition, priceInWei, {
          from: account
        });
      } else if (isBuyNowSalesType(salesType)) {
        tx = await state.kodaV3PrimaryMarketplace.setBuyNowPriceListing(edition, priceInWei, {
          from: account
        });
      } else if (isSteppedSalesType(salesType)) {
        tx = await state.kodaV3PrimaryMarketplace.updateSteppedAuction(edition, priceInWei, stepPriceInWei, {
          from: account
        });
      }
    }

    if (parseInt(version) === 4) {
      const ccContractMarketplace = new ethers.Contract(creatorContract.id, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());
      const { editionId } = splitCreatorEditionId(edition)
      tx = await ccContractMarketplace.updateEditionBuyItNowListingPrice(editionId, priceInWei, {
        from: account
      });
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  // N:B: this is for V3 only
  async changeEditionSalesType({state, rootState, dispatch, commit}, {
    edition,
    currentSaleType,
    newSaleType,
    priceInWei
  }) {
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    console.log('change V3 sale type', {edition, priceInWei, currentSaleType, newSaleType});

    /* eslint-disable-next-line prefer-const */
    let tx;

    // from offers
    if (isOffersOnlySalesType(currentSaleType)) {
      if (isBuyNowSalesType(newSaleType)) {
        // convertOffersToBuyItNow(uint256 _editionId, uint128 _listingPrice, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertOffersToBuyItNow(edition, priceInWei, '0', {from: account});
      } else {
        console.error('Unknown sales type', {edition, currentSaleType, newSaleType});
      }
    }
    // from buy now
    else if (isBuyNowSalesType(currentSaleType)) {
      if (isOffersSalesType(newSaleType)) {
        // convertFromBuyNowToOffers(uint256 _editionId, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertFromBuyNowToOffers(edition, '0', {from: account});
      } else {
        console.error('Unknown sales type', {edition, currentSaleType, newSaleType});
      }
    }
    // from 24hr reserves
    else if (isReserveAuctionSalesType(currentSaleType)) {
      if (isBuyNowSalesType(newSaleType)) {
        // convertReserveAuctionToBuyItNow(uint256 _editionId, uint128 _listingPrice, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertReserveAuctionToBuyItNow(edition, priceInWei, '0', {from: account});
      } else if (isOffersSalesType(newSaleType)) {
        // convertReserveAuctionToOffers(uint256 _editionId, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertReserveAuctionToOffers(edition, '0', {from: account});
      } else {
        console.error('Unknown sales type', {edition, currentSaleType, newSaleType});
      }
    }
    // from stepped sale
    else if (isSteppedSalesType(currentSaleType)) {
      if (isBuyNowSalesType(newSaleType)) {
        // convertSteppedAuctionToListing(uint256 _editionId, uint128 _listingPrice, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertSteppedAuctionToListing(edition, priceInWei, '0', {from: account});
      } else if (isOffersSalesType(newSaleType)) {
        // convertSteppedAuctionToOffers(uint256 _editionId, uint128 _startDate)
        tx = await state.kodaV3PrimaryMarketplace.convertSteppedAuctionToOffers(edition, '0', {from: account});
      } else {
        console.error('Unknown sales type', {edition, currentSaleType, newSaleType});
      }
    }

    if (tx) {
      notifyService.hash(tx.hash);
    } else {
      console.error('Unknown sales type', {edition, currentSaleType, newSaleType});
    }

    return tx;
  },

  async launchDonationFlow({rootState, dispatch}, {amountInEth, charity}) {
    const {web3Store} = rootState;
    const {notifyService} = web3Store;
    console.log('Sending charity donation', {amountInEth, charity});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const tx = await web3Store.web3.getSigner().sendTransaction({
      from: web3Store.account,
      to: charity,
      value: ethers.utils.parseEther(amountInEth)
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async transferEditionToken({state, rootState, dispatch, commit}, {edition, address, version = 2}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store;
    console.log('transferEditionToken', {edition, address, account});

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    let tx;

    if (parseInt(version) === 2) {
      tx = await state.artistControlsV2['gift(address,uint256)'](address, edition.id, {from: account});
    } else if (parseInt(version) === 3) {
      // get first available token ID
      const tokenId = await state.kodaV3.getNextAvailablePrimarySaleToken(edition.id);
      tx = await state.kodaV3['safeTransferFrom(address,address,uint256)'](account, address, tokenId, {from: account});
    } else if (parseInt(version) === 4) {
      const {editionId, contract} = splitCreatorEditionId(edition.id)
      const ccContractMarketplace = new ethers.Contract(contract, abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());
      const tokenId = await ccContractMarketplace['getNextAvailablePrimarySaleToken(uint256)'](editionId, {from: account})
      tx = await ccContractMarketplace['safeTransferFrom(address,address,uint256)'](account, address, tokenId, {from: account})
    }

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async getNextAvailablePrimarySaleToken({state, rootState, dispatch, commit}, {salesType, version, editionId}) {
    try {
      const {web3Store} = rootState;
      const {chainId} = web3Store;

      // Only do this check for v3 tokens
      if (parseInt(version) !== 3) {
        return;
      }

      const {data} = await this.$api.get(`/network/${chainId}/edition/v3/${editionId}/salestype/${salesType}/next-primary-sale-token`);
      if (!data || data.soldOut) {
        return null;
      }

      if (data.nextToken) {
        return data.nextToken;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  // KODA V3 Secondary marketplace

  async listTokenForBuyNow({state, rootState, dispatch, commit}, {tokenId, listPrice, startDate}) {
    console.log('listTokenForBuyNow', tokenId, listPrice, startDate);
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.listForBuyNow(account, tokenId, listPrice, startDate, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.listForBuyNow(account, tokenId, listPrice, startDate, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async setTokenBuyNowPriceListing({state, rootState, dispatch, commit}, {tokenId, listPrice}) {
    console.log('setBuyNowPriceListing', tokenId, listPrice);
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.setBuyNowPriceListing(tokenId, listPrice, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.setBuyNowPriceListing(tokenId, listPrice, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });
    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async listTokenForReserveAuction({state, rootState, dispatch, commit}, {tokenId, listPrice, startDate}) {
    console.log('listTokenForReserveAuction', tokenId, listPrice, startDate);
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.listForReserveAuction(account, tokenId, listPrice, startDate, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.listForReserveAuction(account, tokenId, listPrice, startDate, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async setTokenReserveAuctionPrice({state, rootState, dispatch, commit}, {tokenId, listPrice}) {
    console.log('setTokenReserveAuctionPrice', tokenId, listPrice);
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.updateReservePriceForReserveAuction(tokenId, listPrice, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.updateReservePriceForReserveAuction(tokenId, listPrice, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async placeBidOnAnyTokenInEdition({state, rootState, dispatch, commit}, {editionId}) {
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.placeEditionBid(editionId, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.placeEditionBid(editionId, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async withdrawGlobalEditionBid({state, rootState, dispatch, commit}, {editionId}) {
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.withdrawEditionBid(editionId, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.withdrawEditionBid(editionId, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async acceptEditionBidForToken({state, rootState, dispatch, commit}, {tokenId, offerPrice}) {
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    const gasEstimate = await state.kodaV3SecondaryMarketplace.estimateGas.acceptEditionBid(tokenId, offerPrice, {from: account});

    const tx = await state.kodaV3SecondaryMarketplace.acceptEditionBid(tokenId, offerPrice, {
      from: account,
      gasLimit: gasEstimate.add(DEFAULT_GAS_BUFFER)
    });

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async claimRoyaltiesFromMerkleVault({state, rootState, dispatch, commit}, merkleProofAndIndex) {
    const {web3Store} = rootState;
    const {account, notifyService} = web3Store;

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});
    if (!canProceed) {
      return false;
    }

    /// @notice Allows a beneficiary to claim ETH or ERC20 tokens provided they have a node in the Merkle tree
    /// @param _index Nonce assigned to beneficiary
    /// @param _token Contract address or zero address if claiming ETH
    /// @param _amount Amount being claimed - must be exact
    /// @param _merkleProof Proof for the claim
    //   function claim(
    //     uint256 _index,
    //     address _token,
    //     uint256 _amount,
    //     bytes32[] calldata _merkleProof
    // ) external override whenNotPaused nonReentrant {

    console.log(merkleProofAndIndex);

    const tx = await state.merkleVault.claim(
      merkleProofAndIndex.index,
      merkleProofAndIndex.token,
      merkleProofAndIndex.amount,
      merkleProofAndIndex.proof,
      {
        from: account
      }
    );

    if (tx) {
      notifyService.hash(tx.hash);
    }

    return tx;
  },

  async toggleEditionSalesDisabled({state, rootState, dispatch, commit}, {edition}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});

    if (!canProceed) {
      return false
    }

    const { contract, editionId } = splitCreatorEditionId(edition)

    const ccContractMarketplace = new ethers.Contract(contract, abi.kodaV4CreatorWithBuyNowAndOperatorFilterAbi, web3.getSigner());

    const tx = await ccContractMarketplace.toggleEditionSalesDisabled(editionId, {from: account})

    if (tx) {
      notifyService.hash(tx.hash);
    }
    return tx;
  },

  async burnCreatorContract({state, rootState, dispatch, commit}, {contract}) {
    const {web3Store} = rootState;
    const {account, notifyService, web3} = web3Store

    // IMPORTANT: Ensure user can proceed on KO
    const canProceed = await dispatch('userStore/loggedInUserCanProceedOnKO', {}, {root: true});

    if (!canProceed) {
      return false
    }

    const creatorContractInstance = new ethers.Contract(contract.id, contract.filterRegistry !== null ? abi.kodaV4CreatorWithBuyNowAndOperatorFilterAbi : abi.kodaV4CreatorWithBuyNowAbi, web3.getSigner());

    const estimateGas = await creatorContractInstance.estimateGas.renounceOwnership(
      {from: account}
    );

    const tx = await creatorContractInstance.renounceOwnership(
      {from: account, gasLimit: estimateGas.add(10000)}
    )

    if (tx) {
      notifyService.hash(tx.hash)
    }

    return tx
  }
};
