export { default as ArtistCard } from '../../components/ArtistCard.vue'
export { default as ArtistStats } from '../../components/ArtistStats.vue'
export { default as ArtworkNameAndArtist } from '../../components/ArtworkNameAndArtist.vue'
export { default as ArtworksCard } from '../../components/ArtworksCard.vue'
export { default as AssetCardHolder } from '../../components/AssetCardHolder.vue'
export { default as AssetDescription } from '../../components/AssetDescription.vue'
export { default as Availability } from '../../components/Availability.vue'
export { default as CollectionTab } from '../../components/CollectionTab.vue'
export { default as CreatorContractShowcase } from '../../components/CreatorContractShowcase.vue'
export { default as DoubleClickHandler } from '../../components/DoubleClickHandler.vue'
export { default as EditionCard } from '../../components/EditionCard.vue'
export { default as EditionCardSkeleton } from '../../components/EditionCardSkeleton.vue'
export { default as EditionPrice } from '../../components/EditionPrice.vue'
export { default as EditionThumbnailLink } from '../../components/EditionThumbnailLink.vue'
export { default as EditionWrapper } from '../../components/EditionWrapper.vue'
export { default as ImageMetadata } from '../../components/ImageMetadata.vue'
export { default as JournalTab } from '../../components/JournalTab.vue'
export { default as MarketplaceTag } from '../../components/MarketplaceTag.vue'
export { default as MoreFromThisArtist } from '../../components/MoreFromThisArtist.vue'
export { default as NotificationMenuList } from '../../components/NotificationMenuList.vue'
export { default as OwnerNameAndImageLookup } from '../../components/OwnerNameAndImageLookup.vue'
export { default as PageTitleSection } from '../../components/PageTitleSection.vue'
export { default as PageViewsEdition } from '../../components/PageViewsEdition.vue'
export { default as PageViewsProfile } from '../../components/PageViewsProfile.vue'
export { default as PulsatingHeart } from '../../components/PulsatingHeart.vue'
export { default as SearchEditionCard } from '../../components/SearchEditionCard.vue'
export { default as SearchEditionWrapper } from '../../components/SearchEditionWrapper.vue'
export { default as SingleAsset } from '../../components/SingleAsset.vue'
export { default as TokenCard } from '../../components/TokenCard.vue'
export { default as TokenId } from '../../components/TokenId.vue'
export { default as TokenIdOfX } from '../../components/TokenIdOfX.vue'
export { default as UserProfileLookup } from '../../components/UserProfileLookup.vue'
export { default as Web3CacheProvider } from '../../components/Web3CacheProvider.vue'
export { default as WertTopupLink } from '../../components/WertTopupLink.vue'
export { default as AlgoliaSearchButton } from '../../components/ais/AlgoliaSearchButton.vue'
export { default as AnimBanner } from '../../components/anim/AnimBanner.vue'
export { default as AnimCols } from '../../components/anim/AnimCols.vue'
export { default as AnimCta } from '../../components/anim/AnimCta.vue'
export { default as ApplicationsComponentWrapper } from '../../components/artist-applications/ApplicationsComponentWrapper.vue'
export { default as ActivityFooterLeftAssetCard } from '../../components/asset-card/ActivityFooterLeftAssetCard.vue'
export { default as ActivityFooterRightAssetCard } from '../../components/asset-card/ActivityFooterRightAssetCard.vue'
export { default as ArtworkInfo } from '../../components/asset-card/ArtworkInfo.vue'
export { default as FooterLeftAssetCard } from '../../components/asset-card/FooterLeftAssetCard.vue'
export { default as FooterRightAssetCard } from '../../components/asset-card/FooterRightAssetCard.vue'
export { default as ImageForAssetCard } from '../../components/asset-card/ImageForAssetCard.vue'
export { default as SearchEditionCardResizeable } from '../../components/asset-card/SearchEditionCardResizeable.vue'
export { default as SearchFooterLeftAssetCard } from '../../components/asset-card/SearchFooterLeftAssetCard.vue'
export { default as SearchFooterRightAssetCard } from '../../components/asset-card/SearchFooterRightAssetCard.vue'
export { default as ImageAsset } from '../../components/asset-renders/ImageAsset.vue'
export { default as ImageSphereViewer } from '../../components/asset-renders/ImageSphereViewer.vue'
export { default as InteractivePlayer } from '../../components/asset-renders/InteractivePlayer.vue'
export { default as ModalImageAsset } from '../../components/asset-renders/ModalImageAsset.vue'
export { default as VideoJsPlayer } from '../../components/asset-renders/VideoJsPlayer.vue'
export { default as VideoPlayer } from '../../components/asset-renders/VideoPlayer.vue'
export { default as ArtistBadgeHeader } from '../../components/avatar/ArtistBadgeHeader.vue'
export { default as ArtistNameAndImageLookup } from '../../components/avatar/ArtistNameAndImageLookup.vue'
export { default as ContractAvatar } from '../../components/avatar/ContractAvatar.vue'
export { default as CreatorContractCard } from '../../components/avatar/CreatorContractCard.vue'
export { default as EditionCreators } from '../../components/avatar/EditionCreators.vue'
export { default as ProfileAvatar } from '../../components/avatar/ProfileAvatar.vue'
export { default as ProfileName } from '../../components/avatar/ProfileName.vue'
export { default as ProfileNameLink } from '../../components/avatar/ProfileNameLink.vue'
export { default as ProfileNameLinkAndAvatar } from '../../components/avatar/ProfileNameLinkAndAvatar.vue'
export { default as UserProfileCard } from '../../components/avatar/UserProfileCard.vue'
export { default as CollaborationBadge } from '../../components/badges/CollaborationBadge.vue'
export { default as CreatorContractIcon } from '../../components/badges/CreatorContractIcon.vue'
export { default as CurationBadge } from '../../components/badges/CurationBadge.vue'
export { default as DonationBadge } from '../../components/badges/DonationBadge.vue'
export { default as EnhancedBadge } from '../../components/badges/EnhancedBadge.vue'
export { default as EnhancedIcon } from '../../components/badges/EnhancedIcon.vue'
export { default as UnlockableEdition } from '../../components/badges/UnlockableEdition.vue'
export { default as UnlockableEditionIcon } from '../../components/badges/UnlockableEditionIcon.vue'
export { default as ArtistSpotlightBanner } from '../../components/banners/ArtistSpotlightBanner.vue'
export { default as CollectionBanner } from '../../components/banners/CollectionBanner.vue'
export { default as DropBanner } from '../../components/banners/DropBanner.vue'
export { default as DropsEmailBanner } from '../../components/banners/DropsEmailBanner.vue'
export { default as FeaturedDropBanner } from '../../components/banners/FeaturedDropBanner.vue'
export { default as FeaturedListBanner } from '../../components/banners/FeaturedListBanner.vue'
export { default as GuestEditorBanner } from '../../components/banners/GuestEditorBanner.vue'
export { default as HeaderBanner } from '../../components/banners/HeaderBanner.vue'
export { default as NewReleaseBanner } from '../../components/banners/NewReleaseBanner.vue'
export { default as PromoBanner } from '../../components/banners/PromoBanner.vue'
export { default as PromoCmsBanner } from '../../components/banners/PromoCmsBanner.vue'
export { default as ReserveAuctionBanner } from '../../components/banners/ReserveAuctionBanner.vue'
export { default as SpecialDropBanner } from '../../components/banners/SpecialDropBanner.vue'
export { default as BreakdownDoughnutChart } from '../../components/charts/BreakdownDoughnutChart.vue'
export { default as BreakdownPolarArea } from '../../components/charts/BreakdownPolarArea.vue'
export { default as BreakdownRadarChart } from '../../components/charts/BreakdownRadarChart.vue'
export { default as AuctionCountDown } from '../../components/clocks/AuctionCountDown.vue'
export { default as AuctionCountDownSimple } from '../../components/clocks/AuctionCountDownSimple.vue'
export { default as ClockCountDown } from '../../components/clocks/ClockCountDown.vue'
export { default as ClockCountDownSimple } from '../../components/clocks/ClockCountDownSimple.vue'
export { default as EditionSaleCountDown } from '../../components/clocks/EditionSaleCountDown.vue'
export { default as EditionSaleCountDownLastHour } from '../../components/clocks/EditionSaleCountDownLastHour.vue'
export { default as GatedMessageCountdown } from '../../components/clocks/GatedMessageCountdown.vue'
export { default as AccountBalance } from '../../components/common/AccountBalance.vue'
export { default as AccountImage } from '../../components/common/AccountImage.vue'
export { default as CopyAddress } from '../../components/common/CopyAddress.vue'
export { default as DateFormat } from '../../components/common/DateFormat.vue'
export { default as DateFrom } from '../../components/common/DateFrom.vue'
export { default as EmptyState } from '../../components/common/EmptyState.vue'
export { default as EnsResolver } from '../../components/common/EnsResolver.vue'
export { default as Erc20TokenInformation } from '../../components/common/Erc20TokenInformation.vue'
export { default as EthWithFiatPrice } from '../../components/common/EthWithFiatPrice.vue'
export { default as HeartIcon } from '../../components/common/HeartIcon.vue'
export { default as Likes } from '../../components/common/Likes.vue'
export { default as Share } from '../../components/common/Share.vue'
export { default as SimpleError } from '../../components/common/SimpleError.vue'
export { default as TopArtists } from '../../components/data-tables/TopArtists.vue'
export { default as TopArtworks } from '../../components/data-tables/TopArtworks.vue'
export { default as TopCollectors } from '../../components/data-tables/TopCollectors.vue'
export { default as EarlyAccessListModal } from '../../components/early-access/EarlyAccessListModal.vue'
export { default as EarlyAccessSection } from '../../components/early-access/EarlyAccessSection.vue'
export { default as EmbeddedNftTokens } from '../../components/embedded-tokens/EmbeddedNftTokens.vue'
export { default as EmbeddedTokens } from '../../components/embedded-tokens/EmbeddedTokens.vue'
export { default as ParentAndChildEmbeddedNft } from '../../components/embedded-tokens/ParentAndChildEmbeddedNft.vue'
export { default as SingleEmbeddedNft } from '../../components/embedded-tokens/SingleEmbeddedNft.vue'
export { default as ArtCarousel } from '../../components/homepage/ArtCarousel.vue'
export { default as CollectionHero } from '../../components/homepage/CollectionHero.vue'
export { default as EarlyAccess } from '../../components/homepage/EarlyAccess.vue'
export { default as EditionImage } from '../../components/homepage/EditionImage.vue'
export { default as GradientCta } from '../../components/homepage/GradientCta.vue'
export { default as HomepageComponentWrapper } from '../../components/homepage/HomepageComponentWrapper.vue'
export { default as JournalHeader } from '../../components/journal/JournalHeader.vue'
export { default as JournalSide } from '../../components/journal/JournalSide.vue'
export { default as ManageCCEdition } from '../../components/manage/ManageCCEdition.vue'
export { default as MarketplaceBlockerMessage } from '../../components/messages/MarketplaceBlockerMessage.vue'
export { default as DesktopNav } from '../../components/navbar/DesktopNav.vue'
export { default as MobileNav } from '../../components/navbar/MobileNav.vue'
export { default as BurnCreatorContractModal } from '../../components/modal/BurnCreatorContractModal.vue'
export { default as ClaimRoyaltiesModal } from '../../components/modal/ClaimRoyaltiesModal.vue'
export { default as ConfirmModal } from '../../components/modal/ConfirmModal.vue'
export { default as DeleteProfile } from '../../components/modal/DeleteProfile.vue'
export { default as DonationClaimModal } from '../../components/modal/DonationClaimModal.vue'
export { default as DonationModal } from '../../components/modal/DonationModal.vue'
export { default as HasNFTPromo } from '../../components/modal/HasNFTPromo.vue'
export { default as MarketplaceBlockerModal } from '../../components/modal/MarketplaceBlockerModal.vue'
export { default as ModalTemplate } from '../../components/modal/ModalTemplate.vue'
export { default as MultiCollabBreakdownModal } from '../../components/modal/MultiCollabBreakdownModal.vue'
export { default as OwnershipRenouncedModal } from '../../components/modal/OwnershipRenouncedModal.vue'
export { default as SetOffchainMinBid } from '../../components/modal/SetOffchainMinBid.vue'
export { default as ShareTokenModal } from '../../components/modal/ShareTokenModal.vue'
export { default as UploadCoverImageModal } from '../../components/modal/UploadCoverImageModal.vue'
export { default as CurrentHighestOffer } from '../../components/offers/CurrentHighestOffer.vue'
export { default as HighestOfferLabel } from '../../components/offers/HighestOfferLabel.vue'
export { default as WinningOffer } from '../../components/offers/WinningOffer.vue'
export { default as AddressInput } from '../../components/profile/AddressInput.vue'
export { default as ArtistCreations } from '../../components/profile/ArtistCreations.vue'
export { default as ArtistInsights } from '../../components/profile/ArtistInsights.vue'
export { default as CreateEditionCollection } from '../../components/profile/CreateEditionCollection.vue'
export { default as CreateTokenCollection } from '../../components/profile/CreateTokenCollection.vue'
export { default as DOBInput } from '../../components/profile/DOBInput.vue'
export { default as EditionProfileCollection } from '../../components/profile/EditionProfileCollection.vue'
export { default as ManageProfile } from '../../components/profile/ManageProfile.vue'
export { default as OffersCombined } from '../../components/profile/OffersCombined.vue'
export { default as ProfileBadges } from '../../components/profile/ProfileBadges.vue'
export { default as ProfileBespokeCollection } from '../../components/profile/ProfileBespokeCollection.vue'
export { default as ProfileBespokeTokenCollection } from '../../components/profile/ProfileBespokeTokenCollection.vue'
export { default as ProfileCollection } from '../../components/profile/ProfileCollection.vue'
export { default as ProfileFavorite } from '../../components/profile/ProfileFavorite.vue'
export { default as ProfileLabels } from '../../components/profile/ProfileLabels.vue'
export { default as ProfileOffers } from '../../components/profile/ProfileOffers.vue'
export { default as ProfileSales } from '../../components/profile/ProfileSales.vue'
export { default as TokenProfileCollection } from '../../components/profile/TokenProfileCollection.vue'
export { default as UserProfileStats } from '../../components/profile/UserProfileStats.vue'
export { default as BuyNowButtons } from '../../components/purchasing/BuyNowButtons.vue'
export { default as MarketplacePurchaseActions } from '../../components/purchasing/MarketplacePurchaseActions.vue'
export { default as MarketplacePurchaseCreator } from '../../components/purchasing/MarketplacePurchaseCreator.vue'
export { default as MarketplacePurchaseGated } from '../../components/purchasing/MarketplacePurchaseGated.vue'
export { default as ArtworkSearchResultRow } from '../../components/search/ArtworkSearchResultRow.vue'
export { default as ContractSearchResultRow } from '../../components/search/ContractSearchResultRow.vue'
export { default as OmniSearch } from '../../components/search/OmniSearch.vue'
export { default as SearchAutocomplete } from '../../components/search/SearchAutocomplete.vue'
export { default as SearchResultRow } from '../../components/search/SearchResultRow.vue'
export { default as ContractStats } from '../../components/stats/ContractStats.vue'
export { default as EditionStats } from '../../components/stats/EditionStats.vue'
export { default as TokenStats } from '../../components/stats/TokenStats.vue'
export { default as MarketplaceSalesTable } from '../../components/tables/MarketplaceSalesTable.vue'
export { default as TokenManageTable } from '../../components/tables/TokenManageTable.vue'
export { default as CollectorsNetwork } from '../../components/tabs/CollectorsNetwork.vue'
export { default as EditionHistory } from '../../components/tabs/EditionHistory.vue'
export { default as EditionInfo } from '../../components/tabs/EditionInfo.vue'
export { default as EditionInsights } from '../../components/tabs/EditionInsights.vue'
export { default as EditionOwners } from '../../components/tabs/EditionOwners.vue'
export { default as TokenHistory } from '../../components/tabs/TokenHistory.vue'
export { default as TokenInsights } from '../../components/tabs/TokenInsights.vue'
export { default as TrendingArtist } from '../../components/trending/TrendingArtist.vue'
export { default as TrendingArtistSingle } from '../../components/trending/TrendingArtistSingle.vue'
export { default as TrendingImage } from '../../components/trending/TrendingImage.vue'
export { default as TrendingScore } from '../../components/trending/TrendingScore.vue'
export { default as TrendingTab } from '../../components/trending/TrendingTab.vue'
export { default as TrendingUserLookup } from '../../components/trending/TrendingUserLookup.vue'
export { default as ContentAuthLabel } from '../../components/common/cai/ContentAuthLabel.vue'
export { default as ContentAuthTag } from '../../components/common/cai/ContentAuthTag.vue'
export { default as Artist1 } from '../../components/homepage/artist/Artist1.vue'
export { default as ArtistSkeleton } from '../../components/homepage/artist/ArtistSkeleton.vue'
export { default as Artwork1 } from '../../components/homepage/artwork/Artwork1.vue'
export { default as Contract1 } from '../../components/homepage/contract/Contract1.vue'
export { default as Editorial1 } from '../../components/homepage/editorial/Editorial1.vue'
export { default as Editorial2 } from '../../components/homepage/editorial/Editorial2.vue'
export { default as Editorial3 } from '../../components/homepage/editorial/Editorial3.vue'
export { default as Editorial4 } from '../../components/homepage/editorial/Editorial4.vue'
export { default as EditionOwnersGraph } from '../../components/modal/data/EditionOwnersGraph.vue'
export { default as AcceptEditionOffer } from '../../components/modal/edition/AcceptEditionOffer.vue'
export { default as BurnEdition } from '../../components/modal/edition/BurnEdition.vue'
export { default as ChangeEditionPrice } from '../../components/modal/edition/ChangeEditionPrice.vue'
export { default as ChangeEditionSaleType } from '../../components/modal/edition/ChangeEditionSaleType.vue'
export { default as ChangeSteppedEditionPrice } from '../../components/modal/edition/ChangeSteppedEditionPrice.vue'
export { default as EndOpenEdition } from '../../components/modal/edition/EndOpenEdition.vue'
export { default as PlaceEditionOffer } from '../../components/modal/edition/PlaceEditionOffer.vue'
export { default as PurchaseEdition } from '../../components/modal/edition/PurchaseEdition.vue'
export { default as PurchaseSecondaryMarketTokens } from '../../components/modal/edition/PurchaseSecondaryMarketTokens.vue'
export { default as RejectEditionOffer } from '../../components/modal/edition/RejectEditionOffer.vue'
export { default as ResultEditionReserveAuction } from '../../components/modal/edition/ResultEditionReserveAuction.vue'
export { default as SecondaryMarketTokens } from '../../components/modal/edition/SecondaryMarketTokens.vue'
export { default as TransferEditionTokenModal } from '../../components/modal/edition/TransferEditionTokenModal.vue'
export { default as WithdrawEditionOffer } from '../../components/modal/edition/WithdrawEditionOffer.vue'
export { default as EnhancedToken } from '../../components/modal/enhanced/EnhancedToken.vue'
export { default as PurchaseGated } from '../../components/modal/purchasing/PurchaseGated.vue'
export { default as SecondaryListing } from '../../components/modal/secondary/SecondaryListing.vue'
export { default as AcceptTokenOffer } from '../../components/modal/token/AcceptTokenOffer.vue'
export { default as DeListTokenModal } from '../../components/modal/token/DeListTokenModal.vue'
export { default as ListReserveAuctionModal } from '../../components/modal/token/ListReserveAuctionModal.vue'
export { default as ListTokenModal } from '../../components/modal/token/ListTokenModal.vue'
export { default as PlaceTokenOffer } from '../../components/modal/token/PlaceTokenOffer.vue'
export { default as PurchaseToken } from '../../components/modal/token/PurchaseToken.vue'
export { default as RejectTokenOffer } from '../../components/modal/token/RejectTokenOffer.vue'
export { default as ResultTokenReserveAuction } from '../../components/modal/token/ResultTokenReserveAuction.vue'
export { default as TransferTokenModal } from '../../components/modal/token/TransferTokenModal.vue'
export { default as WithdrawTokenOffer } from '../../components/modal/token/WithdrawTokenOffer.vue'
export { default as PurchaseWithWert } from '../../components/modal/wert/PurchaseWithWert.vue'
export { default as WertTopUpModal } from '../../components/modal/wert/WertTopUpModal.vue'
export { default as WertNotificationService } from '../../components/modal/wert/wertNotificationService.js'
export { default as CollectionsView } from '../../components/profile/views/CollectionsView.vue'
export { default as CreatorContractsView } from '../../components/profile/views/CreatorContractsView.vue'
export { default as OnSaleView } from '../../components/profile/views/OnSaleView.vue'
export { default as ClaimEditionUnlockableContent } from '../../components/modal/edition/unlockable/ClaimEditionUnlockableContent.vue'
export { default as SetEditionUnlockableContent } from '../../components/modal/edition/unlockable/SetEditionUnlockableContent.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
