<template>
  <aside class="content scrollable-content is-family-tertiary" v-if="edition">
    <ul>
      <li>
        <div class="columns is-mobile" v-if="tokenData">
          <div class="column heading">Avg. sale price</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price v-if="tokenData.totalPurchaseValue !== '0'" :price-in-eth="tokenData.totalPurchaseValue / tokenData.totalPurchaseCount">
            </eth-with-fiat-price>
            <eth-with-fiat-price price-in-eth="0" v-else></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile" v-if="tokenData">
          <div class="column heading">Total Sales</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price :price-in-eth="tokenData.totalPurchaseValue"></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile" v-if="tokenData">
          <div class="column heading">Sale Count</div>
          <div class="column heading has-text-weight-semibold">
            {{ tokenData.totalPurchaseCount }}
          </div>
        </div>
      </li>
      <li v-if="tokenData">
        <div class="columns is-mobile">
          <div class="column heading">Last Sale Price</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price :price-in-eth="tokenData.lastSalePriceInEth"></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile" v-if="tokenData">
          <div class="column heading">Last transferred</div>
          <div class="column heading has-text-weight-semibold">
            <date-format :timestamp-in-secs="tokenData.lastTransferTimestamp"></date-format>
          </div>
        </div>
      </li>
      <li>
        <hr />
      </li>
      <li v-if="tokenData">
        <div class="columns is-mobile">
          <div class="column heading">Primary Sale Price</div>
          <div class="column heading has-text-weight-semibold">
            <eth-with-fiat-price :price-in-eth="tokenData.primaryValueInEth"></eth-with-fiat-price>
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Owners Count</div>
          <div class="column heading has-text-weight-semibold">{{ edition.allOwners.length }}</div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">Edition created</div>
          <div class="column heading has-text-weight-semibold">
            <date-format :timestamp-in-secs="edition.createdTimestamp"></date-format>
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>

<script>

import DateFormat from '../common/DateFormat';
import {TOKEN_INSIGHTS_QUERY} from '../../queries/tokenQueries';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, DateFormat},
  props: ['edition', 'tokenId'],
  data() {
    return {
      tokenData: null
    };
  },
  computed: {
    avgSalePrice() {
      return 10;
    }
  },
  mounted() {
    this.$apollo.getClient().query({
      query: TOKEN_INSIGHTS_QUERY,
      variables: {
        id: this.tokenId
      }
    }).then(({data}) => {
      this.tokenData = data.tokenById;
    });
  },
  methods: {
    difference() {
      let startTime;
      if (this.edition.totalSupply === '1') {
        startTime = this.edition.createdTimestamp;
      } else {
        startTime = this.firstSaleTimestamp;
      }

      const endTime = this.lastSaleTimestamp;

      const duration = this.$moment.duration(this.$moment(endTime * 1000).diff(this.$moment(startTime * 1000)));
      const hours = duration.asHours();

      if (hours <= 1) {
        const mins = duration.asMinutes().toFixed(2);
        return mins > 0 ? `${mins} mins` : '';
      }

      if (hours <= 24) {
        return `${hours.toFixed(2)} hours`
      }

      return `${duration.asDays().toFixed(2)} days`;
    }
  }
};
</script>
