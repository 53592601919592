var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mb-4" }, [
    _c("article", { staticClass: "tile is-child content" }, [
      _c("div", { staticClass: "is-family-tertiary" }, [
        _vm.isSoldOutPrimary
          ? _c("section", [
              _vm.isCreator && _vm.hasOpenEditionEndedWithNoSales
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("manage-c-c-edition", {
                          attrs: { edition: _vm.edition },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ownedTokens.length > 0
                ? _c("section", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "mb-5 button is-medium is-fullwidth is-primary is-outlined",
                        attrs: {
                          target: "_blank",
                          href: _vm.viewOnOpenSeaHref,
                        },
                      },
                      [_vm._v("\n            View on OpenSea\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "smooth-scroll",
                            rawName: "v-smooth-scroll",
                            value: {
                              duration: 500,
                              updateHistory: false,
                              offset: -150,
                            },
                            expression:
                              "{ duration: 500, updateHistory: false, offset: -150 }",
                          },
                        ],
                        staticClass:
                          "mb-5 button is-medium is-fullwidth is-secondary",
                        attrs: { href: "#salesTable" },
                      },
                      [_vm._v("\n            View listings\n          ")]
                    ),
                  ])
                : _c("section", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "has-text-weight-semibold title has-text-danger",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.isBuyNowAndOpenSalesType(
                                _vm.edition.salesType
                              )
                                ? "Sale ended"
                                : "Sold out"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "mb-5 button is-medium is-fullwidth is-primary is-outlined",
                        attrs: {
                          target: "_blank",
                          href: _vm.viewOnOpenSeaHref,
                        },
                      },
                      [_vm._v("\n            View on OpenSea\n          ")]
                    ),
                  ]),
            ])
          : _c("section", [
              _vm.revokedApproval
                ? _c("section", [
                    _c("p", { staticClass: "has-text-danger" }, [
                      _vm._v(
                        "\n            Not for sale - seller has revoked approval\n          "
                      ),
                    ]),
                  ])
                : _vm.isBuyNowSalesType(_vm.edition.salesType)
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("p", { staticClass: "title" }, [_vm._v("Buy now")]),
                        _vm._v(" "),
                        _c("eth-with-fiat-price", {
                          attrs: {
                            size: "is-large",
                            strong: true,
                            "show-fiat": true,
                            "price-in-wei": _vm.edition.priceInWei,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("p", { staticClass: "title" }, [
                          _vm._v("Open edition"),
                        ]),
                        _vm._v(" "),
                        _c("eth-with-fiat-price", {
                          attrs: {
                            size: "is-large",
                            strong: true,
                            "show-fiat": true,
                            "price-in-wei": _vm.edition.priceInWei,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column content" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("Pieces minted"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _c("span", { staticClass: "subtitle is-2" }, [
                          _vm._v(_vm._s(_vm.edition.totalSold)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.edition.totalAvailable !== "100000"
                        ? _c("span", { staticClass: "is-muted is-small" }, [
                            _vm._v(
                              "\n              / " +
                                _vm._s(_vm.edition.totalAvailable) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isCreator
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("manage-c-c-edition", {
                          attrs: { edition: _vm.edition },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.editionStartDatePassed
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("edition-sale-count-down", {
                          attrs: { edition: _vm.edition },
                          on: { "start-sale": _vm.onSaleStarted },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editionStartDatePassed &&
              _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType) &&
              !_vm.editionEndDatePassed &&
              !_vm.isNeverEndingEdition
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c("edition-sale-count-down", {
                          attrs: { edition: _vm.edition, "is-end-date": true },
                          on: { "end-sale": _vm.onSaleEnded },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType) &&
                  (_vm.editionEndDatePassed ||
                    _vm.edition.totalAvailable === _vm.edition.totalSold)
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "has-text-weight-semibold title has-text-danger",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.edition.salesType === 6
                                ? "Sale ended"
                                : "Sold out"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "mb-5 button is-medium is-fullwidth is-primary is-outlined",
                        attrs: {
                          target: "_blank",
                          href: _vm.viewOnOpenSeaHref,
                        },
                      },
                      [_vm._v("\n            View on OpenSea\n          ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.revokedApproval &&
              _vm.editionStartDatePassed &&
              _vm.isWeb3Connected &&
              !_vm.editionEndDatePassed
                ? _c("section", { staticClass: "columns is-mobile" }, [
                    _vm.isBuyNowSalesType(_vm.edition.salesType) ||
                    _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                      ? _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c("buy-now-buttons", {
                              attrs: {
                                edition: _vm.edition,
                                "algolia-info": _vm.algoliaInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.ownedTokens.length > 0
          ? _c(
              "section",
              [
                _c(
                  "article",
                  { staticClass: "tile is-child" },
                  [
                    _c(
                      "div",
                      { staticClass: "has-text-weight-semibold title" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.ownedTokens.length) +
                            " owned by\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("user-profile-lookup", {
                      attrs: {
                        "artist-account": _vm.account,
                        "metadata-artist": _vm.account,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "is-primary",
                      size: "is-medium",
                      expanded: "",
                      outlined: "",
                    },
                    on: { click: _vm.downloadArtwork },
                  },
                  [_vm._v("\n          View artwork\n        ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isWeb3Connected
          ? _c(
              "section",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "has-margin-bottom-7 has-margin-top-7",
                    attrs: {
                      "data-testid": "connectWalletnBtn",
                      type: "is-primary",
                      size: "is-medium",
                      expanded: "",
                      outlined: "",
                    },
                    on: { click: _vm.loginToWeb3 },
                  },
                  [_c("span", [_vm._v("Connect wallet")])]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }