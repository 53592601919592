<template>
  <div>
    <client-only>
      <header class="header-image has-text-white has-background-grey-darker" :style="{ backgroundImage: `url(${image})`}" v-if="editionData && editionId">
        <section class="hero">
          <div class="hero-body">
            <div class="container content">
              <h1 class="has-text-white has-margin-top-1 is-size-2-mobile">
                <n-link :to="{name:'gallery-id', params:{id: editionId }}" class="has-text-white has-padding-top-6">
                  <b-icon icon="video" v-if="isVideo" size="is-small" class="is-pulled-right" custom-class="is-size-3">></b-icon>
                  Genesis collection by {{ editionData.metadata.artist }}
                  <span v-if="editionData.optionalCommissionAccount"> X <profile-name :eth-account="editionData.optionalCommissionAccount"></profile-name></span>
<!--                  <span v-if="!startDatePassed"><br/>coming {{ new Date(editionData.startDate * 1000) | moment('Do MMM') }}</span>-->
<!--                  <span v-else><br/>{{ new Date(editionData.startDate * 1000) | moment('Do MMM') }}</span>-->
                </n-link>
              </h1>
              <client-only v-if="editionData && !startDatePassed">
                <div class="columns has-text-white">
                  <div class="column is-one-third-desktop">
                    <edition-sale-count-down :edition="editionData" :white-text="true" :hide-label="true"></edition-sale-count-down>
                  </div>
                </div>
              </client-only>
              <div class="content" v-if="learnMore">
                <div class="field is-grouped">
                  <p class="control">
                    <n-link :to="{name:'specials-id', params:{id: slug }}" class="button is-white is-outlined">
                      Learn more
                    </n-link>
                  </p>
                </div>
              </div>
              <div class="content" v-if="editionData && editionId">
                <n-link :to="{name:'gallery-id', params:{id: editionId }}" class="is-size-10 has-text-white has-padding-top-6">
                  <artwork-name-and-artist style="overflow-wrap: anywhere;"
                                           :metadata="editionData.metadata"
                                           :edition-number="editionId">
                  </artwork-name-and-artist>
                </n-link>
              </div>
            </div>
          </div>
        </section>
      </header>
    </client-only>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {urlUtils} from '@knownorigin/ko-token-uri-tools';
  import {HEADER_BANNER_EDITION} from '../../queries/editionQueries';
  import EditionSaleCountDown from '../clocks/EditionSaleCountDown';
  import {editionStartDatePassed} from '../../services/utils';
  import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
  import ProfileName from '../avatar/ProfileName';
  const {isWebM, isMp4} = urlUtils;

  export default {
    components: {ProfileName, ArtworkNameAndArtist, EditionSaleCountDown},
    props: ['editionId', 'coverImage', 'learnMore', 'slug'],
    computed: {
      ...mapState('web3Store', [
        'gqlClient'
      ]),
      startDatePassed() {
        return this.editionData && editionStartDatePassed(this.editionData.startDate);
      }
    },
    data() {
      return {
        editionData: '',
        isVideo: false,
        image: '',
        imageUrl: ''
      };
    },
    mounted() {
      this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        query: HEADER_BANNER_EDITION,
        variables() {
          return {
            id: this.editionId.toString()
          };
        },
        update({editions}) {
          this.editionData = editions[0];

          const isWebmVideo = isWebM(editions[0].metadata.image_type) || isWebM(editions[0].metadata.image);
          const isMp4Video = isMp4(editions[0].metadata.image_type) || isMp4(editions[0].metadata.image);

          this.setImage((isWebmVideo || isMp4Video));
          this.isVideo = (isWebmVideo || isMp4Video);
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
    },
    methods: {
      setImage(useCard = false) {
        this.imageUrl = `${this.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${this.editionId}${ useCard ? '/cover' : ''}`;

        if (this.coverImage) {
          this.imageUrl = this.coverImage;
        }

        const highResImage = new Image();
        highResImage.onload = () => {
          this.image = this.imageUrl;
        }
        highResImage.src = this.imageUrl;
      }
    }
  };
</script>
