var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: {
        edition: _vm.token,
        token: _vm.token,
        "transaction-hash": _vm.transactionHash,
      },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Cancel sale\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.token.metadata,
              "edition-number": _vm.token.editionNumber,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _c("section", [
            _vm._v(
              "\n      Cancelling a sale will remove this artwork from the marketplace. "
            ),
            _c("br"),
            _c("br"),
            _c("span", { staticClass: "has-text-weight-semibold" }, [
              _vm._v("Are you sure?"),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Cancelling listing\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: { expanded: "", disabled: _vm.transactionHash },
              on: { click: _vm.delistToken },
            },
            [_vm._v("\n      Cancel sale\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }