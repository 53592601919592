var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.moreForTheArtist && _vm.moreForTheArtist.length > 0
    ? _c("section", { staticClass: "content is-hidden-mobile mt-6" }, [
        _c("h2", [_vm._v("More by this artist")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns is-multiline" },
          _vm._l(_vm.moreForTheArtist, function (edition) {
            return _c(
              "div",
              {
                key: edition.id,
                staticClass:
                  "column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen-widescreen is-half-third-fullhd",
              },
              [
                _c("search-edition-wrapper", {
                  staticClass: "has-text-white has-margin-bottom-4",
                  attrs: {
                    "card-size": 2,
                    id: edition.id,
                    cover: true,
                    resolution: "card",
                    size: "is-small",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }