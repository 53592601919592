var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "columns column is-flex has-padding-top-0",
      class: { "is-size-7 p-0 m-0": _vm.size < 3 },
    },
    [
      _vm.isCc && _vm.startDatePassed
        ? _c(
            "div",
            {
              staticClass: "columns is-flex is-vcentered column",
              class: { "pl-0": _vm.isCc },
            },
            [
              _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                ? _c("section", [
                    !_vm.startDatePassed
                      ? _c(
                          "div",
                          { staticClass: "has-text-white" },
                          [
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v("Starts in"),
                            ]),
                            _vm._v(" "),
                            _c("clock-count-down-simple", {
                              attrs: {
                                "start-date": _vm.edition.startDate,
                                "white-text": true,
                              },
                            }),
                          ],
                          1
                        )
                      : !_vm.endDatePassed(_vm.edition) &&
                        !_vm.isNeverEndingEdition(_vm.edition)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "has-text-white has-text-weight-semibold",
                          },
                          [
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v("Ends in"),
                            ]),
                            _vm._v(" "),
                            _c("clock-count-down-simple", {
                              attrs: {
                                "start-date": _vm.edition.endDate,
                                "white-text": true,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "columns is-flex is-vcentered column",
              class: {
                "countdown-started":
                  _vm.reserveEditionAuctionCountdownInProgress,
                "pl-0": _vm.isCc,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "has-text-weight-semibold has-text-left has-padding-left-3 has-padding-top-0",
                  class: { "is-size-7 p-0 m-0": _vm.size < 3 },
                },
                [
                  _c(
                    "n-link",
                    {
                      staticClass: "asset-card-wrapped-link",
                      attrs: { to: _vm.handleMarketPlaceRouting },
                    },
                    [
                      _c("div", [
                        _c("section", [
                          _vm.phase &&
                          _vm.phase.state !== "public" &&
                          _vm.isNotSoldOutPrimary
                            ? _c("div", [
                                _vm.phase.state === "pre-phase"
                                  ? _c(
                                      "div",
                                      { class: { "has-text-white": _vm.isCc } },
                                      [
                                        _c("p", [_vm._v("Starts in")]),
                                        _vm._v(" "),
                                        _c("clock-count-down-simple", {
                                          attrs: {
                                            "start-date": _vm.phase.startTime,
                                            "white-text": _vm.isCc,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm.phase.state === "active"
                                  ? _c(
                                      "div",
                                      { class: { "has-text-white": _vm.isCc } },
                                      [
                                        _c(
                                          "p",
                                          { class: { "is-muted": !_vm.isCc } },
                                          [_vm._v("Public price")]
                                        ),
                                        _vm._v(" "),
                                        _c("eth-with-fiat-price", {
                                          staticClass:
                                            "has-text-weight-semibold",
                                          class: { "is-muted": !_vm.isCc },
                                          attrs: {
                                            "price-in-wei":
                                              _vm.edition.priceInWei,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : !_vm.startDatePassed
                            ? _c(
                                "div",
                                { class: { "has-text-white": _vm.isCc } },
                                [
                                  _c("p", [_vm._v("Starts in")]),
                                  _vm._v(" "),
                                  _c("clock-count-down-simple", {
                                    attrs: {
                                      "start-date": _vm.edition.startDate,
                                      "white-text": _vm.isCc,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.isNotSoldOutPrimary &&
                              _vm.isSteppedSalesType(_vm.edition.salesType)
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "p",
                                    { class: { "is-muted": !_vm.isCc } },
                                    [_vm._v("Next price")]
                                  ),
                                  _vm._v(" "),
                                  _c("eth-with-fiat-price", {
                                    staticClass:
                                      "has-text-danger has-text-weight-semibold",
                                    attrs: {
                                      "price-in-wei": _vm.getNextStep(
                                        _vm.edition.priceInWei,
                                        _vm.edition.stepSaleStepPrice
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.isNotSoldOutPrimary &&
                              _vm.isReserveAuctionSalesType(
                                _vm.edition.salesType
                              ) &&
                              _vm.reserveAuctionCountdownStarted &&
                              !_vm.reserveAuctionCountdownComplete
                            ? _c(
                                "div",
                                { class: { "has-text-white": _vm.isCc } },
                                [
                                  _c("p", [_vm._v("Auction ends in")]),
                                  _vm._v(" "),
                                  _c("auction-count-down-simple", {
                                    attrs: {
                                      "end-date":
                                        _vm.edition.reserveAuctionEndTimestamp,
                                      "white-text": _vm.isCc,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.workingToken &&
                              _vm.isReserveAuctionSalesType(
                                _vm.workingToken.salesType
                              ) &&
                              _vm.reserveAuctionCountdownStarted &&
                              !_vm.reserveAuctionCountdownComplete
                            ? _c(
                                "div",
                                { class: { "has-text-white": _vm.isCc } },
                                [
                                  _c("p", [_vm._v("Auction ends in")]),
                                  _vm._v(" "),
                                  _c("auction-count-down-simple", {
                                    attrs: {
                                      "end-date":
                                        _vm.workingToken
                                          .reserveAuctionEndTimestamp,
                                      "white-text": _vm.isCc,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }