var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "content" }, [
    _c("div", { staticClass: "card is-shadowless" }, [
      _c("div", { staticClass: "card-content has-padding-7" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "section",
            {
              staticClass:
                "has-margin-7 has-padding-top-7 has-padding-bottom-7",
            },
            [
              _c("h2", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n            Collectors network for " +
                    _vm._s(_vm.profile.username) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mb-5" },
                [
                  _vm.loading
                    ? _c(
                        "b-progress",
                        {
                          staticClass: "progress-small",
                          attrs: { "show-value": "", type: "is-primary" },
                        },
                        [
                          _vm._v(
                            "\n              Loading network graph\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c("network", {
                    ref: "network",
                    staticClass: "wrapper",
                    attrs: {
                      nodes: _vm.nodes,
                      edges: _vm.edges,
                      options: _vm.options,
                    },
                    on: {
                      "deselect-node": _vm.onDeselectNode,
                      "select-node": _vm.onSelectNode,
                      "double-click": _vm.onDoubleClick,
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-5" }, [
                    _vm._v(
                      "\n              Circles represent editions, diamonds represent collectors. Data is king and blockchain enables this!\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "is-muted" }, [
                    _vm._v(
                      "\n              Double click on each item to view the artwork or profile, colours are randomly generated.\n            "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }