<template>
  <span v-if="isEnhanced">
    <span style="position: absolute; bottom: 1rem; right: 1rem; z-index: 10;" class="hand-pointer">
      <b-tooltip position="is-top" class="ml-1" type="is-dark" label="Contains embedded items">
        <b-tag class="is-dark is-uppercase hand-pointer" ref="enhanced-label">
          <b-icon icon="set-center" type="is-light" size="is-small"></b-icon>
        </b-tag>
      </b-tooltip>
    </span>
  </span>
</template>

<script>
import _get from 'lodash/get';
import {mapState} from 'vuex';
import {ENHANCED_DATA_FOR_EDITION} from '../../queries/enhancedTokenQueries';

export default {
  components: {},
  props: ['edition', 'tokenId'],
  data() {
    return {
      nextTokenID: null,
      isEnhanced: false
    };
  },
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  async mounted() {
    await this.loadNextToken();
  },
  methods: {
    async loadNextToken() {
      if (!this.tokenId) {
        this.nextTokenID = await this.$store.dispatch('web3ActionsStore/getNextAvailablePrimarySaleToken', {
          version: _get(this.edition, 'version'),
          salesType: _get(this.edition, 'salesType'),
          editionId: _get(this.edition, 'id')
        });
      }
      await this.fetchEnhancedData();
    },
    async fetchEnhancedData() {
      this.$apollo.addSmartQuery('composableData', {
        query: ENHANCED_DATA_FOR_EDITION,
        client: this.gqlClient,
        variables() {
          let id = this.tokenId;
          if (!id && this.nextTokenID) {
            id = this.nextTokenID.toString();
          }
          if (!id && this.edition) {
            id = this.edition.id;
          }
          return {
            id
          };
        },
        result({data, loading}) {
          if (!loading) {
            if (data && data.composableData) {
              const {composableData} = data;
              if (composableData && composableData[0]) {
                if (composableData[0].items.length > 0) {
                  this.isEnhanced = true;
                }
              }
            }
          }
        },
        error(error) {
          console.log('enhanced data error : ', error);
        }
      });
    }
  }
};
</script>
