<template>
  <div v-if="results && results.passed">
    <li>
      <div class="columns ">
        <div class="column heading">
          <a :href="generateVerifyLink" target="_blank">
            Content Credentials
            <b-icon icon="check-decagram" size="is-small" class="is-size-7 is-size-6-mobile"></b-icon>
          </a>
        </div>
      </div>
    </li>
    <li>
      <div class="columns  heading">
        <div class="column">
          Wallet signer:
          <profile-name-link :eth-account="minter"></profile-name-link>
        </div>
      </div>
    </li>
    <li v-if="results && results.claimSigned">
      <div class="columns ">
        <div class="column heading">
          Signed on: {{ results.claimSigned | moment('Do MMM YYYY HH:mm:ss') }}
        </div>
      </div>
    </li>
    <li v-if="results && results.claimIssuer">
      <div class="columns is-vcentered">
        <div class="column heading">
          Signed by:
          <span v-if="isAdobeIssuer">
            <img src="/partners/cai-issuer/adobe.svg" alt="adobe-logo"
                 style="max-height: 15px;">
          </span>
          <span v-else>{{ results.claimIssuer }}</span>
        </div>
      </div>
    </li>
    <li v-if="results && results.claimProducer">
      <div class="columns is-vcentered">
        <div class="column heading">
          Produced with:
          <span v-if="isPhotoshopProduced">
            <img src="/partners/cai-issuer/Adobe_Photoshop_CC_icon.svg" alt="photoshop-logo"
                 style="max-height: 15px;">
          </span>
          <span v-else>{{ results.claimProducer }}</span>
        </div>
      </div>
    </li>
    <li>
      <div class="columns">
        <div class="column heading">
          <a href="/journal/platformupdate/content-authenticity-initiative"
             class="is-linkable" target="_blank">
            Learn more
          </a>
        </div>
      </div>
    </li>
    <hr />
  </div>
</template>
<script>
import {ContentAuth} from '@contentauth/sdk/dist/cai-sdk.esm';
import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import {mapState} from 'vuex';
import _get from 'lodash/get';
import ProfileNameLink from '../../avatar/ProfileNameLink';
import { caiProvenance } from '../../../services/caiProvenance'

export default {
  components: {ProfileNameLink},
  props: ['editionId', 'imageUrl', 'minter'],
  data() {
    return {
      results: {}
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    generateVerifyLink() {
      return ContentAuth.generateVerifyUrl(
        ipfsUtils.buildPinataLookupUri(this.imageUrl)
      );
    },
    isAdobeIssuer() {
      return _get(this.results, 'claimIssuer', '').toLowerCase().includes('adobe');
    },
    isPhotoshopProduced() {
      return _get(this.results, 'claimProducer', '').toLowerCase().includes('photoshop');
    }
  },
  async mounted() {
    this.results = await caiProvenance(this.chainId, this.editionId, this.imageUrl, this.minter);
  }
};

</script>
<style>

</style>
