import {EVENT_NAMES} from '../../services/analyticsEvents'

export const state = () => ({
  userToken: '0x0000000000000000000000000000000000000000' // Use 0 address for default, no wallet connected
});

export const mutations = {
  storeUserToken(state, {userToken}) {
    state.userToken = userToken
  }
}

export const actions = {
  async setUserToken({rootState, commit, dispatch}) {
    const {web3Store} = rootState;
    const {account} = web3Store
    commit('storeUserToken', {userToken: account})
    await dispatch('logEvent', {name: EVENT_NAMES.walletConnected});
  },

  async clearUserToken({commit, dispatch}) {
    commit('storeUserToken', {userToken: '0x0000000000000000000000000000000000000000'})

    await dispatch('logEvent', {name: EVENT_NAMES.walletDisconnected});
  },

  async logEvent({state, rootState, dispatch}, event) {
    try {
      let properties = event.properties ? event.properties : {}
      const deviceDetails = await dispatch('getDeviceAndOS', {})
      properties = {...properties, ...deviceDetails}

      await this.$amplitude.logEvent({
        event_type: event.name,
        user_id: state.userToken,
        time: new Date().getTime(),
        event_properties: properties
      })
    } catch (err) {
      console.error(err)
    }
  },

  getDeviceAndOS() {
    if (this.app && this.app.$device) {
      if (this.app.$device.isDesktop) {
        return {device: 'desktop', os: this.app.$device.isWindows ? 'windows' : this.app.$device.isMacOS ? 'mac' : 'other'}
      }
      if (this.app.$device.isTablet) {
        return {device: 'tablet', os: this.app.$device.isAndroid ? 'android' : this.app.$device.isIos ? 'ios' : 'other'}
      }
      if (this.app.$device.isMobile) {
        return {device: 'mobile', os: this.app.$device.isAndroid ? 'android' : this.app.$device.isIos ? 'ios' : 'other'}
      }
    }
    // Default to desktop
    return {device: 'desktop', os: 'other'}
  }
}
