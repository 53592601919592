var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm.isSteppedSalesType(_vm.edition.salesType)
          ? _c("div", [_vm._v("\n      Buy next step\n    ")])
          : _c(
              "div",
              [
                _vm._v("\n      Buy now for\n      "),
                _c("eth-with-fiat-price", {
                  attrs: { "price-in-wei": _vm.edition.priceInWei },
                }),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Buying\n  "),
      ]),
      _vm._v(" "),
      _vm.isSteppedSalesType(_vm.edition.salesType)
        ? _c("template", { slot: "secondary-content" }, [
            _c(
              "section",
              { staticClass: "has-padding-bottom-7" },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-radio",
                      {
                        attrs: {
                          "native-value": "1",
                          disabled: _vm.transactionHash != null,
                        },
                        model: {
                          value: _vm.stepX,
                          callback: function ($$v) {
                            _vm.stepX = $$v
                          },
                          expression: "stepX",
                        },
                      },
                      [
                        _vm._v("\n          Buy current step\n          "),
                        _c("eth-with-fiat-price", {
                          attrs: { "price-in-wei": _vm.edition.priceInWei },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                parseInt(_vm.edition.remainingSupply) > 1
                  ? _c(
                      "b-field",
                      [
                        _c(
                          "b-radio",
                          {
                            attrs: {
                              "native-value": "2",
                              disabled: _vm.transactionHash != null,
                            },
                            model: {
                              value: _vm.stepX,
                              callback: function ($$v) {
                                _vm.stepX = $$v
                              },
                              expression: "stepX",
                            },
                          },
                          [
                            _vm._v("\n          Buy next step\n          "),
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-wei": _vm.getNextStep(
                                  _vm.edition.priceInWei,
                                  _vm.edition.stepSaleStepPrice
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                parseInt(_vm.edition.remainingSupply) > 2
                  ? _c(
                      "b-field",
                      [
                        _c(
                          "b-radio",
                          {
                            attrs: {
                              "native-value": "3",
                              disabled: _vm.transactionHash != null,
                            },
                            model: {
                              value: _vm.stepX,
                              callback: function ($$v) {
                                _vm.stepX = $$v
                              },
                              expression: "stepX",
                            },
                          },
                          [
                            _vm._v(
                              "\n          Custom step (advanced)\n          "
                            ),
                            _vm.stepX === "3"
                              ? _c("eth-with-fiat-price", {
                                  attrs: {
                                    "price-in-wei": _vm.getStepSalePriceAt(
                                      _vm.edition.priceInWei,
                                      _vm.edition.stepSaleStepPrice,
                                      _vm.selectedStep
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.stepX === "3"
                  ? _c(
                      "b-field",
                      { attrs: { label: "Choose a step" } },
                      [
                        _c("b-slider", {
                          attrs: {
                            min: 1,
                            max: parseInt(_vm.edition.remainingSupply),
                            step: 1,
                            rounded: "",
                            ticks: "",
                            disabled: _vm.transactionHash != null,
                          },
                          model: {
                            value: _vm.selectedStep,
                            callback: function ($$v) {
                              _vm.selectedStep = $$v
                            },
                            expression: "selectedStep",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  [
                    _c(
                      "b-message",
                      {
                        staticClass: "is-size-7",
                        attrs: {
                          type: "is-info",
                          closable: false,
                          duration: 0,
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n            Each sale increases the price, choose the maximum price you are willing to pay to secure this artwork.\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            "\n            You only pay for the first step you secure, you will be refunded any overpayment in the same transaction.\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "is-linkable",
                            attrs: {
                              href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Learn more")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.marketplacesBlocked
        ? _c("template", { slot: "secondary-content" }, [
            _c("section", [_c("marketplace-blocker-message")], 1),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _vm.isBuyableSalesType(_vm.edition.salesType)
            ? _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary",
                    expanded: "",
                    disabled: _vm.disableBuyButton,
                    "data-testid": "buynowbtn",
                  },
                  on: { click: _vm.buyNow },
                },
                [_vm._v("\n      Buy now\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button-nevermind" },
        [
          _c(
            "b-button",
            {
              attrs: { expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.close(false)
                },
              },
            },
            [_vm._v("\n      Nevermind\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }