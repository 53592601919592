var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.workingMetadata
    ? _c("span", { class: _vm.customClass }, [
        _vm._v(
          "\n  " +
            _vm._s(_vm.workingMetadata.name) +
            " by " +
            _vm._s(_vm.workingMetadata.artist) +
            "\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }