<template>
  <div class="is-flex is-flex-direction-column">
    <user-profile-lookup
      v-if="artist && artist.id"
      class="mb-4"
      :artist-account="artist.id"
      :metadata-artist="artist.id"
    >
      <template slot="artist-profile-header">
        <p class="artist-header-text mb-1 is-size-7 is-uppercase">
          <span class="is-capitalized">Trending creator</span>
        </p>
      </template>

      <template slot="artist-profile-subtitle">
        <trending-score :score="artist.score" :address="artist.id" :no-padding="true"></trending-score>
      </template>
    </user-profile-lookup>

    <div v-else class="media is-align-items-center mb-5">
      <div class="media-left">
        <b-skeleton circle active width="64px" height="64px" />
      </div>

      <div class="media-content">
        <div class="content is-flex is-flex-wrap-wrap">
          <b-skeleton active width="100px" height="12px" />
          <b-skeleton active width="232px" height="24px" />
          <b-skeleton active width="75px" height="24px" />
        </div>
      </div>
    </div>

    <trending-image v-if="artist && artist.editions[0]" :edition="artist.editions[0]" />
  </div>
</template>

<script>
export default {
  props: ['artist']
}
</script>

<style lang="scss" scoped>
.artist-header-text {
  line-height: 1 !important;
  opacity: 0.5;
}
</style>
