<template>
  <span>
    <client-only>
      <vue-countdown v-if="lessThanAnHour"
                     :time="diff"
                     :transform="transform"
                     :emit-events="true">
        <template slot-scope="props">
          {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
        </template>
      </vue-countdown>
    </client-only>
    </span>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import * as moment from 'moment';

  export default {
    components: {VueCountdown},
    props: ['startDate'],
    data() {
      return {
      };
    },
    computed: {
      diff() {
        // stored in UTC, ensure local is also UTC'd
        const startDate = moment.utc(this.startDate * 1000);
        const now = moment().local().utc();
        return startDate.diff(now, 'milliseconds');
      },
      lessThanAnHour() {
        // stored in UTC, ensure local is also UTC'd
        const startDate = moment.utc(this.startDate * 1000);
        const now = moment().local().utc();
        return startDate.diff(now, 'hours') < 24;
      }
    },
    methods: {
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;

          props[key] = `${digits}`;
        });
        return props;
      }
    }
  };
</script>
