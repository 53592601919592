<template>
  <section>
    <b-table :data="insights" v-if="insights && insights.length > 0"
             paginated
             pagination-simple
             :per-page="10"
             striped
             default-sort="createdTimestamp"
             default-sort-direction="desc">
      <b-table-column field="artwork" label=" " width="20%" centered v-slot="props">
        <n-link v-if="props" :to="buildLink(props.row.id)">
          <image-asset :edition-number="props.row.id"
                       :metadata="props.row.metadata"
                       :cover="false"
                       resolution="thumbnail"
                       class="is-hidden-mobile"
                       v-if="props.row">
          </image-asset>
          <span class="is-hidden-desktop" v-if="props.row">
            <artwork-name-and-artist :metadata="props.row.metadata" :edition-number="props.row.id">
            </artwork-name-and-artist>
          </span>
        </n-link>
      </b-table-column>
      <b-table-column field="totalAvailable" label="Size" centered sortable numeric v-slot="props">
        <span v-if="props">1 of {{ props.row.totalAvailable }}</span>
      </b-table-column>
      <b-table-column field="action" label="Status" centered v-slot="props">
        <span v-if="props">
          <b-tag type="is-dark" v-if="props.row.hasSoldOut" class="is-uppercase">Sold out</b-tag>
          <span v-else class="is-uppercase is-size-7">
            {{ props.row.totalAvailable - props.row.totalSupply }} Available
          </span>
        </span>
      </b-table-column>
      <b-table-column field="totalSold" label="Sold" centered sortable numeric v-slot="props">
        <span v-if="props">{{ props.row.totalSold }}</span>
      </b-table-column>
      <b-table-column field="action" label="Gift" centered v-slot="props">
        <span v-if="props">{{ props.row.tokenIds.length - props.row.totalSold }}</span>
      </b-table-column>
      <b-table-column field="action" label="Avg." centered v-slot="props">
        <span v-if="props">
          <eth-with-fiat-price :price-in-eth="(props.row.totalEthSpentOnEdition / props.row.totalSold)"
                               v-slot="props">
          </eth-with-fiat-price>
        </span>
      </b-table-column>
      <b-table-column field="totalEthSpentOnEdition" label="Total" centered sortable numeric v-slot="props">
        <span v-if="props">
          <eth-with-fiat-price :price-in-eth="props.row.totalEthSpentOnEdition"></eth-with-fiat-price>
        </span>
      </b-table-column>
      <b-table-column field="allOwners" label="Owners" centered sortable numeric v-slot="props">
        <span v-if="props">{{ props.row.allOwners.length }}</span>
      </b-table-column>
      <b-table-column field="action" label="Sell out" centered v-slot="props">
        <span v-if="(props.row.totalAvailable - props.row.totalSupply) === 0">{{ difference(props.row) }}</span>
      </b-table-column>
      <b-table-column field="createdTimestamp" label="Created" centered sortable numeric v-slot="props">
        <span v-if="props">
          <date-format :timestamp-in-secs="props.row.createdTimestamp"></date-format>
        </span>
      </b-table-column>
    </b-table>
    <div v-else>
      <empty-state message="No insights"></empty-state>
    </div>
  </section>
</template>
<script>
import _first from 'lodash/first';
import _last from 'lodash/last';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _size from 'lodash/size';
import {mapState} from 'vuex';
import {ARTIST_EDITION_INSIGHTS} from '../../queries/editionQueries';
import ImageAsset from '../asset-renders/ImageAsset';
import DateFormat from '../common/DateFormat';
import EmptyState from '../common/EmptyState';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, ArtworkNameAndArtist, EmptyState, DateFormat, ImageAsset},
  props: ['address'],
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  data() {
    return {
      rawGraphData: {},
      insights: []
    };
  },
  mounted() {
    this.$apollo.getClient()
      .query({
        query: ARTIST_EDITION_INSIGHTS,
        variables: {
          address: this.address.toLowerCase()
        }
      })
      .then(({data}) => {
        this.rawGraphData = data.editions;
        this.buildInsights();
      });
  },
  methods: {
    buildLink(editionId) {
      return {name: 'gallery-id', params: {id: editionId}};
    },
    difference(edition) {
      let startTime;
      if (edition.hasSoldOut && edition.totalSupply === '1') {
        startTime = edition.createdTimestamp;
      } else {
        startTime = edition.firstSaleTime;
      }

      const endTime = edition.lastSaleTime;

      const duration = this.$moment.duration(this.$moment(endTime * 1000).diff(this.$moment(startTime * 1000)));
      const hours = duration.asHours();

      if (hours <= 1) {
        const mins = duration.asMinutes().toFixed(2);
        return mins > 0 ? `${mins} mins` : '';
      }

      if (hours <= 24) {
        return `${hours.toFixed(2)} hours`;
      }

      return `${duration.asDays().toFixed(2)} days`;
    },
    getEdition(edition) {
      return _find(this.editions, {id: edition});
    },
    buildInsights() {
      this.insights = _map(this.rawGraphData, (edition) => {
        const sortedSales = _sortBy(edition.sales, 'birthTimestamp');
        const hasMadeSale = _size(sortedSales) > 0;
        edition.hasMadeSale = hasMadeSale;
        edition.hasSoldOut = edition.totalSupply === edition.totalAvailable;
        edition.firstSaleTime = hasMadeSale ? _first(sortedSales).birthTimestamp : '';
        edition.lastSaleTime = hasMadeSale ? _last(sortedSales).birthTimestamp : '';
        return edition;
      });
    }
  }
};
</script>
