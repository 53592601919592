<template>
  <client-only>
    <vue-countdown v-if="!hidden"
                   :time="diff"
                   :transform="transform"
                   :emit-events="true"
                   @progress="handleCountdownProgress">
      <template slot-scope="props">
        <section>
          <slot name="countdown-title">
            <p class="title is-family-tertiary has-font-weight-bold" :class="{'has-text-white': whiteText === true}" v-if="!hideLabel">
              {{isEndDate ? 'Closes in' : 'Sale starts in'}}
            </p>
          </slot>
          <slot name="countdown-content">
          <div class="level is-mobile box is-family-tertiary has-text-weight-normal" :class="
            {
              'has-text-white': whiteText === true,
              'has-text-success': type === 'success',
              'has-text-danger': type === 'danger',
            }">
            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.days }}</p>
                <p class="heading">Days</p>
              </div>
            </div>

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.hours }}</p>
                <p class="heading">Hours</p>
              </div>
            </div>

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.minutes }}</p>
                <p class="heading">Minutes</p>
              </div>
            </div>

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.seconds }}</p>
                <p class="heading">Seconds</p>
              </div>
            </div>
          </div>
          </slot>
        </section>
      </template>
    </vue-countdown>
  </client-only>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import * as moment from 'moment';

  export default {
    components: {VueCountdown},
    props: ['edition', 'whiteText', 'hideLabel', 'type', 'startDate', 'isEndDate'],
    data() {
      return {
        hidden: false
      };
    },
    computed: {
      diff() {
        // stored in UTC, ensure local is also UTC'd
        const startDate = this.startDate ? moment.utc(this.startDate * 1000) : moment.utc((this.isEndDate ? this.edition.endDate : this.edition.startDate) * 1000);

        const now = moment().local().utc();
        return startDate.diff(now, 'milliseconds');
      }
    },
    mounted() {
      const ONE_SECOND = 1000; // millis
      const TIMEOUT = 20000; // millis
      if ((((new Date(this.edition.endDate * 1000)).valueOf() - Date.now()) > 31556926000) && this.isEndDate) {
          this.hidden = true;
      }

      // In an attempt to catch browsers "Waking" up
      let lastTime = (new Date()).getTime();
      this.interval = setInterval(function () {
        const currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + TIMEOUT + 2000)) {
          console.log('tab woken up', this.diff);
          if (this.diff <= ONE_SECOND) {
            this.triggerSale();
          }
        }
        lastTime = currentTime;
      }, TIMEOUT);
    },
    destroyed() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    methods: {
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;

          // // uses singular form when the value is less than 2
          // const word = value < 2 ? key.replace(/s$/, '') : key;

          props[key] = `${digits}`;
        });
        return props;
      },
      handleCountdownProgress(data) {
        if (data.totalSeconds <= 0) {
          console.log('trigger sale');
          this.triggerSale();
        }
      },
      triggerSale() {
        console.log('triggerSale called');

        this.hidden = true;

        // update UI
        this.$emit(this.isEndDate ? 'end-sale' : 'start-sale');
      }
    }
  };
</script>
<style>

</style>
