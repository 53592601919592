var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.hasAuthToken
        ? _c(
            "div",
            [
              _c("empty-state", {
                attrs: {
                  message: "You need to sign in to view your active offers",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-flex-direction-column is-justify-content-center px-6",
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", outlined: "" },
                      on: { click: _vm.signInAndGetAuthToken },
                    },
                    [_vm._v("\n        Sign in\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.sortedOffers && _vm.sortedOffers.length > 0
        ? _c(
            "b-table",
            { attrs: { data: _vm.sortedOffers } },
            [
              _c("b-table-column", {
                attrs: { field: "edition.id", label: " ", width: "20%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "n-link",
                          { attrs: { to: _vm.buildLink(props.row) } },
                          [
                            props.row.edition
                              ? _c("modal-image-asset", {
                                  attrs: {
                                    "edition-number": props.row.edition.id,
                                    metadata: props.row.edition.metadata,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            props.row.edition
                              ? _c(
                                  "span",
                                  { staticClass: "is-size-7" },
                                  [
                                    _c("artwork-name-and-artist", {
                                      attrs: {
                                        metadata: props.row.edition.metadata,
                                        "edition-number": props.row.edition.id,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "is-muted is-size-7" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(props.row.type) +
                                  " / V" +
                                  _vm._s(props.row.version) +
                                  "\n          "
                              ),
                              props.row.token
                                ? _c("span", [
                                    _vm._v(
                                      "\n            / ST" +
                                        _vm._s(props.row.token.salesType) +
                                        " / ID" +
                                        _vm._s(props.row.token.id) +
                                        "\n          "
                                    ),
                                  ])
                                : props.row.edition
                                ? _c("span", [
                                    _vm._v(
                                      "\n            / ST" +
                                        _vm._s(props.row.edition.salesType) +
                                        " / ID" +
                                        _vm._s(props.row.edition.id) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "label", label: "Action", centered: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        props.row.label === "Offer placed"
                          ? _c(
                              "b-tag",
                              {
                                staticClass: "is-uppercase is-family-primary",
                                attrs: { type: "is-primary" },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(props.row.label) +
                                    "\n      "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        props.row.label === "Offer received"
                          ? _c(
                              "b-tag",
                              {
                                staticClass: "is-uppercase is-family-primary",
                                attrs: { type: "is-dark" },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(props.row.label) +
                                    "\n      "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "weiValue", label: "Offer", centered: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("eth-with-fiat-price", {
                          attrs: { "price-in-wei": props.row.weiValue },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "currentOwner.address",
                  label: "Owner",
                  centered: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("profile-name-link", {
                          attrs: {
                            "eth-account": props.row.currentOwner.address,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "bidder.address",
                  label: "Offer by",
                  centered: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("profile-name-link", {
                          attrs: { "eth-account": props.row.bidder.address },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "action", label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        props.row.label === "Offer placed"
                          ? _c(
                              "span",
                              [
                                props.row.type === "Token" &&
                                _vm.isLoggedInBidder(props.row) &&
                                props.row.version === 2
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "is-primary",
                                          size: "is-small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.increaseOffer(props.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            Increase offer\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isWithdrawalEnabled(props.row)
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "is-danger",
                                          size: "is-small",
                                          outlined: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.withdrawOffer(props.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            Withdraw offer\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showBidCanBeWithdrawnLabel(props.row)
                                  ? _c(
                                      "span",
                                      { staticClass: "has-text-info" },
                                      [
                                        _vm._v(
                                          "\n            Bid can be withdrawn "
                                        ),
                                        _c("date-from", {
                                          attrs: {
                                            "timestamp-in-secs":
                                              props.row.lockedUntil,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        props.row.label === "Offer received"
                          ? _c("span", [
                              props.row.type === "Token"
                                ? _c(
                                    "span",
                                    [
                                      !_vm.isReserveAuctionSalesType(
                                        props.row.token.salesType
                                      ) && _vm.isLoggedInCurrentOwner(props.row)
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                type: "is-primary",
                                                size: "is-small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.acceptOffer(
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            Accept offer\n          "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isReserveAuctionSalesType(
                                        props.row.token.salesType
                                      ) && _vm.isLoggedInCurrentOwner(props.row)
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                type: "is-danger",
                                                size: "is-small",
                                                outlined: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rejectOffer(
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            Reject offer\n          "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              props.row.type === "Edition"
                                ? _c(
                                    "span",
                                    [
                                      !_vm.isReserveAuctionSalesType(
                                        props.row.edition.salesType
                                      ) &&
                                      _vm.isLoggedInCurrentOwner(props.row) &&
                                      props.row.edition.remainingSupply !== "0"
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                type: "is-primary",
                                                size: "is-small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.acceptOffer(
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            Accept offer\n          "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isReserveAuctionSalesType(
                                        props.row.edition.salesType
                                      ) &&
                                      _vm.isLoggedInCurrentOwner(props.row) &&
                                      props.row.edition.remainingSupply !== "0"
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                type: "is-danger",
                                                size: "is-small",
                                                outlined: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rejectOffer(
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            Reject offer\n          "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      props.row.edition.remainingSupply === "0"
                                        ? _c(
                                            "b-tag",
                                            { attrs: { type: "is-light" } },
                                            [
                                              _vm._v(
                                                "\n            Sold out\n          "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.offerBelowReserve(props.row)
                                ? _c("span", { staticClass: "has-text-info" }, [
                                    _vm._v(
                                      "\n          Current bid below reserve\n        "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isInForcedWithdrawalReserveAuction(props.row)
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "is-danger",
                                  size: "is-small",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.withdrawOffer(props.row)
                                  },
                                },
                              },
                              [_vm._v("\n        Withdraw offer\n      ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canResultReserveAuction(props.row)
                          ? _c(
                              "b-button",
                              {
                                attrs: { type: "is-primary", size: "is-small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resultReserveAuction(props.row)
                                  },
                                },
                              },
                              [_vm._v("\n        Complete auction\n      ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auctionHasStarted(props.row)
                          ? _c("span", { staticClass: "has-text-info" }, [
                              _vm._v("\n        Auction has started\n      "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          )
        : _c(
            "div",
            [_c("empty-state", { attrs: { message: "No active offers" } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }