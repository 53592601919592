var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "font-weight": "400", "font-size": "0.85rem" } },
    [
      _c("div", { staticClass: "level is-mobile is-family-tertiary" }, [
        _c("div", { staticClass: "level-left" }, [
          _vm.contractStats && _vm.contractStats.totalNumOfEditions
            ? _c(
                "div",
                {
                  staticClass:
                    "level-item has-padding-right-7 is-hidden-mobile",
                },
                [
                  _c("div", [
                    _c("p", { attrs: { "data-cy": "Editions_count" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.contractStats.totalNumOfEditions) +
                          " Editions\n          "
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contractStats && _vm.contractStats.totalNumOfTokensSold !== "0"
            ? _c("div", { staticClass: "level-item has-padding-right-7" }, [
                _c("div", [
                  _c("p", { attrs: { "data-cy": "sales_count" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.contractStats.totalNumOfTokensSold) +
                        " Sales\n          "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.contractStats && _vm.contractStats.totalEthValueOfSales !== "0"
            ? _c("div", { staticClass: "level-item has-padding-right-7" }, [
                _c("div", [
                  _c(
                    "p",
                    { attrs: { "data-cy": "total_count" } },
                    [
                      _c("eth-with-fiat-price", {
                        attrs: {
                          "price-in-eth":
                            _vm.contractStats.totalEthValueOfSales,
                        },
                      }),
                      _vm._v("\n            Total\n          "),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.contractStats && _vm.royaltiesValue
            ? _c("div", { staticClass: "level-item has-padding-right-7" }, [
                _c("div", [
                  _vm.royaltiesValue
                    ? _c("p", { attrs: { "data-cy": "royalties_value" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.royaltiesValue) +
                            "% CREATOR ROYALTIES\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level is-mobile is-family-tertiary" }, [
        _c("div", { staticClass: "level-left is-block-mobile" }, [
          _vm.contractStats && _vm.contractStats.timestamp
            ? _c(
                "div",
                { staticClass: "level-item-tablet has-padding-right-7 mb-5" },
                [
                  _c("div", [
                    _c("p", {}, [
                      _vm._v("\n            Deployed\n            "),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.contractStats.timestamp * 1000,
                                "MMM Do YYYY"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contractStats
            ? _c(
                "div",
                { staticClass: "level-item has-padding-right-7 mb-5" },
                [
                  _c(
                    "div",
                    [
                      _vm.isBlockedRegistry
                        ? _c(
                            "b-tag",
                            {
                              staticClass: "is-dark is-uppercase hand-pointer",
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.openModal("protected")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "is-underlined-hover" },
                                [_vm._v("Protected marketplaces")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOwnershipRenounced
                        ? _c(
                            "b-tag",
                            {
                              staticClass:
                                "is-dark is-uppercase hand-pointer ml-1",
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.openModal("renounced")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "is-underlined-hover" },
                                [_vm._v("Ownership renounced")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }