<template>
  <section class="section has-background-black pt-6 pb-5 mt-6">
      <div class="container pt-4 pb-3">
        <div class="columns">
          <div class="column is-one-third has-text-left mb-5">
            <div class="content">
              <h1 class="title has-text-white is-size-3 mb-2">Discover and collect</h1>
              <p class="has-text-white mb-5">
                KnownOrigin gives artists a place to create and collect unique, authentic, digital collectibles in the form of NFTs using blockchain technology.
              </p>
              <n-link to="/journal" class="button is-white is-outlined is-family-tertiary" @click.native="handleItemClickInsights('button', '', 'learn more', 'view journal')">
                Learn more
              </n-link>
            </div>
          </div>
          <div class="column is-one-third has-text-left mb-5">
            <div class="content">
              <h1 class="title has-text-white is-size-3 mb-2">Become an artist</h1>
              <p class="has-text-white mb-5">
                Join our community of creatives, showcase and sell your digital artworks. Digitally sign your work by creating a tokenized certificate.
              </p>
              <n-link to="/apply" class="button is-white is-outlined is-family-tertiary" @click.native="handleItemClickInsights('button', '', 'apply to join', 'apply page')">
                Apply to join
              </n-link>
            </div>
          </div>
          <div class="column is-one-third has-text-left mb-5">
            <div class="content">
              <h1 class="title has-text-white is-size-3 mb-2">Join our community</h1>
              <p class="has-text-white mb-5">
                Meet the KnownOrigin team, artists, collectors, and get platform updates, announcements and more in our Discord.
              </p>
              <a href="https://discord.gg/knownorigin" target="_blank" class="button is-white is-outlined is-family-tertiary" @click="handleItemClickInsights('button', '', 'join the discord', 'discord join link')">
                Launch Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '~/services/analyticsEvents';

export default {
  name: 'GradientCta',
  methods: {
    handleItemClickInsights(type, item, promoSection, destination) {
      if (type === 'card') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.cardClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.id]: item,
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: promoSection
          }
        })
      } else if (type === 'button') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.buttonClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: promoSection,
            [AMPLITUDE_EVENT_PROPERTIES.destination]: destination
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  background: #000 url('/branding/gradient-wide.jpg') center bottom no-repeat;
  background-size: cover;
}

.title {
  line-height: 1;
}
</style>
