<template>
  <div v-if="edition.isOpenEdition" class="column content">
    <b-button @click="openEndEditionModal" outlined class="has-margin-bottom-7 is-medium is-danger  is-fullwidth">End open edition</b-button>
  </div>
</template>

<script>
import EndOpenEdition from '../modal/edition/EndOpenEdition';

export default {
  name: 'ManageToken',
  props: ['edition'],
  methods: {
    openEndEditionModal() {
      console.log('Opening modal')
      this.endOpenEditionModalInstance = this.$buefy.modal.open({
        parent: this,
        component: EndOpenEdition,
        width: 600,
        props: {
          editionNumber: this.edition.id,
          metadata: this.edition.metadata
        },
        canCancel: true
      })
    }
  }
};
</script>

<style scoped>

</style>
