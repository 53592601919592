<template>
  <section class="journalSide">
   <div v-if="jumpto.length > 0" class="journalSide__jumpTo is-hidden-touch">
     <p class="heading is-family-tertiary has-text-weight-bold">Jump to</p>
     <ul class="journalSide__items mt-3">
       <li v-for="(el, i) in jumpto" :key="i" class="journalSide__item">
         <a :href="formatLink(el)" class="journalSide__link">{{ el }}</a>
       </li>
     </ul>
     <hr v-if="jumpto.length > 0 && artist" />
   </div>
    <NuxtLink v-if="artist" :to="{ name: 'profile-id', params: { id: artist.address }}">
      <ArtistCard :artist="artist" />
    </NuxtLink>
  </section>
</template>

<script>
export default {
  name: 'JournalSide',
  props: {
    jumpto: {
      type: Array,
      default: null
    },
    artist: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatLink(text) {
      return '#' + text.toLowerCase().replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '')
    }
  }
}
</script>

<style lang="scss">
@import '~bulma/sass/utilities/mixins';

.journalSide {
  position: sticky;
  top: 64px;
  border: 1px solid #DADADA;
  padding: 20px;
  border-radius: 8px;

  &__item {
    margin-bottom: 4px;
    padding-left: 20px;

    &::before {
      content: '-';
      margin: 0 6px 0 -20px;
      display: inline-block;
    }
  }

  &__link {
    display: inline-block;
    line-height: 1.4;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  hr {
    width: calc(100% + 64px);
    margin: 24px -32px;
    border-top: 1px solid #DADADA;
  }

  // override default artistCard
  .avatar {
    margin: 0 0 16px !important;
    padding: 24px 0;
    border-radius: 8px;

    img {
      width: 88px;
      height: 88px;
      padding: 2px;
    }
  }

  .content {
    margin: 0;

    &.box-fat {
      height: auto;
    }

    &.has-margin-7 {
      display: flex;
      flex-wrap: wrap;
      margin: 0 !important;
    }

    > h2 {
      margin: 0 0 8px;
      font-size: 24px;
    }

    > div:first-child {
      order: 2;
      width: 100%;
      margin: 0;
    }

    > div:last-child {
      order: 1;
      font-size: 15px;
    }

    .level {
      box-shadow: none;
      padding: 0;
    }

    .level .level-item {
      text-align: left !important;
      justify-content: start;
    }

    .level .level-item .heading {
      margin: 12px 0 0;
    }
  }
}
/*----------------------------------------*/

// > 1024px
@include desktop {
  .journalSide {
    padding: 32px;
  }
}
</style>
