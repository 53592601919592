<template>
  <div class="columns column is-flex has-padding-top-0" :class="{'is-size-7 p-0 m-0': size < 3}">
    <div v-if="isCc && startDatePassed" class="columns is-flex is-vcentered column" :class="{'pl-0': isCc}">
      <section v-if="isBuyNowAndOpenSalesType(edition.salesType)">
        <div v-if="!startDatePassed" class="has-text-white">
          <p class="m-0">Starts in</p>
          <clock-count-down-simple :start-date="edition.startDate" :white-text="true">
          </clock-count-down-simple>
        </div>
        <div v-else-if="!endDatePassed(edition) && !isNeverEndingEdition(edition)" class="has-text-white has-text-weight-semibold">
          <p class="m-0">Ends in</p>
          <clock-count-down-simple :start-date="edition.endDate" :white-text="true">
          </clock-count-down-simple>
        </div>
      </section>
    </div>
    <div v-else class="columns is-flex is-vcentered column"
         :class="{'countdown-started': reserveEditionAuctionCountdownInProgress, 'pl-0': isCc}">
      <div class="has-text-weight-semibold has-text-left has-padding-left-3 has-padding-top-0" :class="{'is-size-7 p-0 m-0': size < 3}">
        <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link">
          <div>
            <section>
              <div v-if="phase && phase.state !== 'public' && isNotSoldOutPrimary">
                <div v-if="phase.state === 'pre-phase'" :class="{'has-text-white': isCc}">
                  <p>Starts in</p>
                  <clock-count-down-simple :start-date="phase.startTime" :white-text="isCc">
                  </clock-count-down-simple>
                </div>
                <div v-else-if="phase.state === 'active'" :class="{'has-text-white': isCc}">
                  <p :class="{'is-muted': !isCc}">Public price</p>
                  <eth-with-fiat-price :price-in-wei="edition.priceInWei"
                                       class="has-text-weight-semibold" :class="{'is-muted': !isCc}">
                  </eth-with-fiat-price>
                </div>
              </div>
              <div v-else-if="!startDatePassed" :class="{'has-text-white': isCc}">
                <p>Starts in</p>
                <clock-count-down-simple :start-date="edition.startDate" :white-text="isCc">
                </clock-count-down-simple>
              </div>
              <div v-else-if="isNotSoldOutPrimary && isSteppedSalesType(edition.salesType)">
                <p :class="{'is-muted': !isCc}">Next price</p>
                <eth-with-fiat-price :price-in-wei="getNextStep(edition.priceInWei, edition.stepSaleStepPrice)"
                                     class="has-text-danger has-text-weight-semibold">
                </eth-with-fiat-price>
              </div>
              <div v-else-if="isNotSoldOutPrimary
                        && isReserveAuctionSalesType(edition.salesType)
                        && (reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete)"
                   :class="{'has-text-white': isCc}">
                <p>Auction ends in</p>
                <auction-count-down-simple :end-date="edition.reserveAuctionEndTimestamp" :white-text="isCc">
                </auction-count-down-simple>
              </div>
              <div v-else-if="workingToken
                            && isReserveAuctionSalesType(workingToken.salesType)
                            && (reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete)"
                   :class="{'has-text-white': isCc}">
                <p>Auction ends in</p>
                <auction-count-down-simple :end-date="workingToken.reserveAuctionEndTimestamp" :white-text="isCc">
                </auction-count-down-simple>
              </div>
            </section>
          </div>
        </n-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import EthWithFiatPrice from '../../components/common/EthWithFiatPrice';
import ClockCountDownSimple from '../clocks/ClockCountDownSimple';
import AuctionCountDownSimple from '../clocks/AuctionCountDownSimple';
import editionUtils from '../../services/editionUtils';
import {
  getNextStep,
  isBuyableSalesType,
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isOffersSalesType,
  isReserveAuctionSalesType,
  isSteppedSalesType,
  isBuyNowAndOpenSalesType
} from '../../services/SaleTypes';
import {editionStartDatePassed} from '../../services/utils';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import {isCreatorContract} from '../../services/CreatorContractUtils';
import {handleGalleryRouting} from '../../services/routing';

export default {
  components: {
    EthWithFiatPrice,
    ClockCountDownSimple,
    AuctionCountDownSimple
  },
  props: [
    'edition',
    'workingToken',
    'clickHandler',
    'phase',
    'size'
  ],
  methods: {
    getNextStep,
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isOffersSalesType,
    isSteppedSalesType,
    isReserveAuctionSalesType,
    isBuyableSalesType,
    isBuyNowAndOpenSalesType,
    endDatePassed(edition) {
      return editionUtils.endDatePassed(edition)
    },
    isNeverEndingEdition(edition) {
      return editionUtils.isNeverEndingEdition(edition)
    }
  },
  computed: {
    ...mapState('web3Store', [
      'chainId',
      'gqlClient',
      'account'
    ]),
    reserveEditionAuctionCountdownInProgress() {
      return this.workingToken
        ? ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.workingToken)
        : ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    reserveAuctionCountdownStarted() {
      return this.workingToken
        ? ReserveAuctionUtils.reserveAuctionCountdownStarted(this.workingToken)
        : ReserveAuctionUtils.reserveAuctionCountdownStarted(this.edition);
    },
    reserveAuctionCountdownComplete() {
      return this.workingToken
        ? ReserveAuctionUtils.reserveAuctionCountdownComplete(this.workingToken)
        : ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    },
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    startDatePassed() {
      if (this.workingToken) {
        return true;
      }
      return editionStartDatePassed(this.edition.startDate);
    },
    handleMarketPlaceRouting() {
      if (this.isNotSoldOutPrimary || isCreatorContract(this.edition)) {
        return handleGalleryRouting(this.edition, this.clickHandler)
      }
      if (this.workingToken) {
        return {name: 'tokens-id', params: {id: this.workingToken.id}};
      }
      return {};
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.countdown-started {
  background-color: $black;
  color: $white !important;

  div > * {
    background-color: $black;
    color: $white !important;
  }

  span > * {
    background-color: $black;
    color: $white !important;
  }
}

.ccPillStyle {
  color: white;
  background-color: transparent;
  border: 0.8px solid white;
  border-radius: 500px;
  font-size: 14px;
}

.ccPillIcon {
  vertical-align: middle;
}
</style>
