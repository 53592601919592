<template>
  <aside class="content scrollable-content" v-if="activityEvents">
    <ul>
      <li v-for="activityEvent in activityEvents" :key="activityEvent.id">
        <div class="columns is-mobile is-multiline">
          <div class="column is-half">
            <span class="heading has-text-weight-semibold">{{ mapEvent(activityEvent.eventType) }}</span>
            <span class="heading">
              <profile-name-link :eth-account="activityEvent.buyer"></profile-name-link>
            </span>
            <a class="is-muted-7" :href="etherscanTxLink(activityEvent.transactionHash)"
               target="_blank">[view tx]</a>
          </div>
          <div class="column is-half">
            <span class="heading"><date-from :timestamp-in-secs="activityEvent.timestamp" /></span>
            <span class="heading">
              <eth-with-fiat-price v-if="activityEvent.eventValueInWei"
                size="is-small"
                show-historical-fiat="true"
                :price-in-wei="activityEvent.eventValueInWei"
                :transaction-hash="activityEvent.transactionHash">
              </eth-with-fiat-price>
            </span>
          </div>
        </div>
      </li>
      <li v-if="creationEvent && creationEvent.length === 1">
        <div class="columns is-mobile is-multiline">
          <div class="column is-half">
            <span class="heading has-text-weight-semibold">{{ mapEvent(creationEvent[0].eventType) }}</span>
            <a class="is-muted-7" :href="etherscanTxLink(creationEvent[0].transactionHash)"
               target="_blank">[view tx]</a>
          </div>
          <div class="column is-half">
            <span class="heading"><date-from :timestamp-in-secs="creationEvent[0].timestamp" /></span>
          </div>
        </div>
      </li>
    </ul>
  </aside>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {mapEvent} from '../../services/utils';
import {EDITION_CREATION_EVENT, TOKEN_HISTORY} from '../../queries/activityQueries';
import DateFrom from '../common/DateFrom';
import ProfileNameLink from '../avatar/ProfileNameLink';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {ProfileNameLink, EthWithFiatPrice, DateFrom},
  props: ['tokenId', 'editionId'],
  data() {
    return {
      activityEvents: null,
      creationEvent: null
    }
  },
  computed: {
    ...mapState('web3Store', ['gqlClient']),
    ...mapGetters('web3Store', [
      'etherscanTxLink'
    ])
  },
  watch: {
    activityEvents (newVal, oldVal) {
      if (newVal !== oldVal) {
        (newVal || []).forEach((event) => {
          const {transactionHash} = event;
          this.$store.dispatch('transactionLookupStore/lookupTransactionDetail', transactionHash);
        });
      }
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('activityEvents', {
      client: this.gqlClient,
      query: TOKEN_HISTORY,
      variables() {
        return {
          token: this.tokenId
        };
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });

    this.$apollo.addSmartQuery('creationEvent', {
      client: this.gqlClient,
      query: EDITION_CREATION_EVENT,
      variables() {
        return {
          edition: this.editionId.toString()
        };
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    mapEvent,
    // called from external components to refresh
    refresh() {
      this.$apollo.queries.activityEvents.refetch();
      this.$apollo.queries.activityEvents.setOptions({
        fetchPolicy: 'network-only',
        pollInterval: 15000 // ms
      });
    }
  }
};
</script>
