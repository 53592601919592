<template>
  <section class="section p-0 mb-6">
    <!--  -->

    <div class="editorial-top-banner content p-5">
      <h1 class="editorial-header py-6 has-text-white is-size-3-tablet is-size-5-mobile has-text-centered is-family-secondary">
        Discover, sell and collect NFT art
      </h1>
    </div>

    <div class="content has-background-light mb-6">
      <div class="section container">

        <div class="columns is-hidden-mobile">
          <template v-if="editions">
            <div
              v-for="(el, i) in editions"
              :key="el + i + 100"
              class="editorial-cards column p-1">
              <EditionImage :edition="{ id: el.toString() }" />
            </div>
          </template>

          <template v-else>
            <div v-for="(el, i) in 6" :key="el + i" class="editorial-cards column p-1">
              <img src="/branding/bigloader-image.svg" />
              <b-skeleton :animated="true" height="12px" width="140px" />
            </div>
          </template>
        </div>

        <!-- Mobile cards slider -->
        <div
          class="container editorial-cards editorial-cards-slider keen-slider is-hidden-tablet"
          :class="{ 'is-slider-loaded': isSliderReady }">
          <div
            v-for="(el, i) in editions"
            :key="el + i + 300"
            class="slider-card py-0 keen-slider__slide">
            <EditionImage :edition="{ id: el.toString() }" />
          </div>
        </div>

        <template v-if="!isSliderReady">
          <div class="editorial-cards column p-1 is-hidden-tablet">
            <img src="/branding/bigloader-image.svg" />
            <b-skeleton :animated="true" height="12px" width="140px" />
          </div>
        </template>

        <div class="editorial-content content px-0 pt-5 mt-1 columns is-multiline has-text-centered">
          <div class="columns is-multiline is-12 is-flex is-flex-direction-column is-align-items-center">
            <div class="">
              <h2 class="is-size-7 p-0 has-text-weight-light column is-uppercase">
                <img src="/branding/KO-Icon-Blue.svg" class="editorial-one-logo" />
                {{ cmsComponent.subtitle }}
              </h2>
              <h2 class="title is-size-3 has-text-weight-black mt-2 mb-0">{{ cmsComponent.title }}</h2>

              <!-- <div v-if="cmsComponent.buttonLink" class="is-hidden-mobile mt-5" @click="handleButtonClickedEvent(cmsComponent.buttonLink)">
                <NuxtLink :to="cmsComponent.buttonLink" class="button is-primary p-5">
                  {{ cmsComponent.buttonLabel }}
                </NuxtLink>
              </div> -->
            </div>

            <div class="mb-2">
              <p class="editorial-description pt-0">{{ cmsComponent.description }}</p>
            </div>

            <div v-if="cmsComponent.buttonLink" class="pt-2 mb-5" @click="handleButtonClickedEvent(cmsComponent.buttonLink)">
            <NuxtLink :to="cmsComponent.buttonLink" class="button is-primary p-5">
              {{ cmsComponent.buttonLabel }}
            </NuxtLink>
          </div>
          </div>


        </div>

      </div>
    </div>

    <!--  -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash/get';
import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  props: ['cmsComponent'],
  data() {
    return {
      slider: null,
      isSliderReady: false,
      editions: null
    }
  },
  computed: {
    ...mapState(['gqlClient'])
  },
  watch: {
    editions() {
      // when the editions have loaded, create carousel
      setTimeout(() => {
        this.slider = new KeenSlider(this.$el.querySelector('.editorial-cards-slider'), {
          slides: {
            perView: 1.15,
            spacing: 16
          },
          created: () => {
            this.isSliderReady = true

            setTimeout(() => {
              this.slider.container.addEventListener('wheel', this.eventWheel, {
                passive: false
              })
            }, 100)
          }
        })
      }, 100)
    }
  },
  mounted() {
    // remove spaces if there are any, split values into new array
    if (this.cmsComponent.editionIds) {
      const ids = this.cmsComponent.editionIds.replace(/\s/g, '')
      this.editions = ids.split(',')
    }
  },
  methods: {
    async handleButtonClickedEvent(destination) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial5',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      })
    },
    async handleCardClickedEvent() {
      const subject = get(this.cmsComponent, 'data.metadata.artist');

      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.cardClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial5',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]: this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.depth]: 0,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.subject]: subject,
          [AMPLITUDE_EVENT_PROPERTIES.subjectType]: 'artist'
        }
      })
    },
    dispatch(e, name) {
      this.position.x -= e.deltaX
      this.position.y -= e.deltaY
      this.slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: this.position.x,
            y: this.position.y
          }
        })
      )
    },
    wheelStart(e) {
      this.position = {
        x: e.pageX,
        y: e.pageY
      }
      this.dispatch(e, 'ksDragStart')
    },
    wheel(e) {
      this.dispatch(e, 'ksDrag')
    },
    wheelEnd(e) {
      this.dispatch(e, 'ksDragEnd')
    },
    eventWheel(e) {
      if (e.deltaX !== 0) {
        e.preventDefault()
      }
      if (!this.wheelActive) {
        this.wheelStart(e)
        this.wheelActive = true
      }
      this.wheel(e)
      clearTimeout(this.touchTimeout)
      this.touchTimeout = setTimeout(() => {
        this.wheelActive = false
        this.wheelEnd(e)
      }, 50)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/mixins';

.editorial-top-banner {
  background: #000 url('/branding/gradient-wide.jpg') center bottom no-repeat;
  background-size: cover;
}

.editorial-header {
  margin: 0 auto;
  max-width: 600px;
  font-size: 28px !important;
}

.editorial-cards {
  margin-top: -80px;
}

.editorial-cards-slider {
  overflow: visible !important;
  position: absolute;
  visibility: hidden;

  &.is-slider-loaded {
    position: relative;
    visibility: visible;
  }
}

.editorial-content {
  .columns {
    width: 100%;
  }

  .title {
    max-width: 500px;
  }
}

.editorial-description {
  max-width: 600px;
}
/*----------------------------------------*/

// < 1024
@include until-widescreen {
  .editorial-cards:nth-child(5),
  .editorial-cards:nth-child(6) {
    display: none;
  }
}
/*----------------------------------------*/

// < 768
@include mobile {
  .editorial-content {
    padding-top: 1rem;
  }

  .editorial-cards {
    margin-top: -100px;
  }
}
</style>
