var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.curationDataBlobs && _vm.curationDataBlobs.length > 0
    ? _c(
        "div",
        {},
        _vm._l(_vm.curationDataBlobs, function (curation, i) {
          return _c("div", { key: i, staticClass: "mt-4" }, [
            _c("section", { staticClass: "mb-2" }, [
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("artist-badge-header", {
                    attrs: {
                      address: curation.ethAddress,
                      message: _vm.getMessage(curation),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "media-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "p",
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "format-quote-open",
                              size: "is-medium",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.quotes[i]) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showMoreToggles[i]
                        ? _c("div", [
                            _vm.showMoreToggles[i] === 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "heading is-clickable pt-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleMoreDescription(
                                          curation.quote,
                                          i
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Show more "),
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "arrow-expand-down",
                                        size: "is-small",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMoreToggles[i] === 2
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "heading is-clickable pt-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleMoreDescription(
                                          curation.quote,
                                          i
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Show less "),
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "arrow-expand-up",
                                        size: "is-small",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }