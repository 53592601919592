<template>
  <client-only>
    <vue-countdown v-if="!hidden"
                   :time="diff"
                   :transform="transform"
                   :emit-events="true"
                   @progress="handleCountdownProgress">
      <template slot-scope="props">
        <section>
          <slot name="countdown-title">
            <p class="title">
              Sale starts in
            </p>
          </slot>
          <b-message
            size="is-small"
            class="mb-4"
            :class="{
            'is-success': type === 'success',
            'is-danger': type === 'danger',
            }"
          >
            <div class="level is-mobile is-family-tertiary">
              <div class="level-item has-text-centered">
                <div>
                  <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.days }}</p>
                  <p class="heading">Days</p>
                </div>
              </div>

              <div class="level-item has-text-centered">
                <div>
                  <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.hours }}</p>
                  <p class="heading">Hours</p>
                </div>
              </div>

              <div class="level-item has-text-centered">
                <div>
                  <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.minutes }}</p>
                  <p class="heading">Minutes</p>
                </div>
              </div>

              <div class="level-item has-text-centered">
                <div>
                  <p class="is-size-3 heading mb-0 has-text-weight-bold">{{ props.seconds }}</p>
                  <p class="heading">Seconds</p>
                </div>
              </div>
            </div>
          </b-message>
        </section>
      </template>
    </vue-countdown>
  </client-only>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import * as moment from 'moment';

export default {
  components: {VueCountdown},
  props: ['type', 'startDate'],
  data() {
    return {
      hidden: false
    };
  },
  computed: {
    diff() {
      // stored in UTC, ensure local is also UTC'd
      const startDate = moment.utc(this.startDate * 1000)

      const now = moment().local().utc();
      return startDate.diff(now, 'milliseconds');
    }
  },
  mounted() {
    const ONE_SECOND = 1000; // millis
    const TIMEOUT = 20000; // millis

    // In an attempt to catch browsers "Waking" up
    let lastTime = (new Date()).getTime();
    this.interval = setInterval(function () {
      const currentTime = (new Date()).getTime();
      if (currentTime > (lastTime + TIMEOUT + 2000)) {
        console.log('tab woken up', this.diff);
        if (this.diff <= ONE_SECOND) {
          this.triggerSale();
        }
      }
      lastTime = currentTime;
    }, TIMEOUT);
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        props[key] = `${digits}`;
      });
      return props;
    },
    handleCountdownProgress(data) {
      if (data.totalSeconds <= 0) {
        console.log('trigger sale');
        this.triggerSale();
      }
    },
    triggerSale() {
      console.log('triggerSale called');

      this.hidden = true;

      // update UI
      this.$emit('start-sale');
    }
  }
};
</script>
<style>

</style>
