<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Make an offer
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="secondary-content">
      <section>
        <b-field label="Enter your offer"
                 :message="`Min. offer is Ξ ${minBidInEthCalculated}. Must increase in increments of Ξ ${minBidAmount}.`">
          <b-input :placeholder="minBidInEthCalculated"
                   expanded
                   autofocus
                   :min="minBidAmount"
                   v-model="form.offerAmount"
                   type="number"
                   :disabled="transactionHash"
                   :step="minBidAmount">
          </b-input>
        </b-field>

        <p v-if="shouldShowHighestOffer" class="has-text-weight-semibold">
          Current highest bid
          <eth-with-fiat-price
            size="is-medium"
            :show-fiat="false"
            :price-in-wei="existingOffer.weiValue">
          </eth-with-fiat-price>
        </p>

        <p v-if="shouldShowReservePrice" class="has-text-weight-semibold">
          Reserve price
          <eth-with-fiat-price
            size="is-medium"
            :show-fiat="false"
            :price-in-wei="token.listing.reservePrice">
          </eth-with-fiat-price>
        </p>

        <div v-if="isLockableEdition(token) && !isReserveAuctionSalesType(token.salesType)" v-cloak>
          <b-message v-if="minBid" type="is-info" class="is-size-7" :closable="false" :duration="0">
            You will not be able to withdraw your bid for {{ lockoutPeriodForOffers }}.
          </b-message>
          <b-message v-else type="is-info" class="is-size-6">
            <p>This is an unlisted artwork and is not currently for sale, any bid placed is at your own discretion.</p>
          </b-message>
        </div>

        <div v-if="isReserveAuctionSalesType(token.salesType)">

          <!-- No offers but offer which will start the auction - show start notification -->
          <div v-if="auctionWillStart" v-cloak>
            <b-message type="is-success" class="is-size-7" :closable="false" :duration="0">
              <strong>This bid will start the auction.</strong>
              <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                 class="is-linkable">Learn how 24 hour auctions work</a>
            </b-message>
          </div>

          <!-- Auction started and above highest offer -->
          <div v-else-if="reserveAuctionCountdownStarted && isOutBid" v-cloak>
            <b-message type="is-success" class="is-size-7" :closable="false" :duration="0">
              Reserve price met, auction will be extended if bid is made within the last 15 minutes.
              <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                 class="is-linkable">Learn how 24 hour auctions work</a>
            </b-message>
          </div>

          <!-- Bid entered is above highest but will not start countdown -->
          <div v-else-if="auctionWillNotStart" v-cloak>
            <b-message type="is-warning" class="is-size-7" :closable="false" :duration="0"
                       v-if="reserveAuctionCountdownStarted">
              You must outbid the highest bidder.
              <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                 class="is-linkable">Learn how 24 hour auctions work</a>
            </b-message>
            <b-message type="is-warning" class="is-size-7" :closable="false" :duration="0" v-else>
              <strong>This bid will not start the auction.</strong>
              You can withdraw this bid at anytime.
              <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                 class="is-linkable">Learn how 24 hour auctions work</a>
            </b-message>
          </div>

          <!-- Default message - show info links to docs -->
          <div v-else v-cloak>
            <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
              Bids placed during an auction cannot be withdrawn. By placing a bid you have indicated that you agree
              to the
              <a href="https://docs.knownorigin.io/en/articles/7128351-terms-of-service" class="is-linkable">
                terms of service
              </a>.
              <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                 class="is-linkable">Learn how 24 hour auctions work</a>
            </b-message>
          </div>
        </div>

      </section>
    </template>

    <template slot="action-feedback-label">
      Offer submitted
    </template>

    <div slot="action-button" class="card-footer-item">
      <b-button class="button is-primary"
                expanded
                :disabled="disableOfferButton"
                @click="makeOffer">
        Submit offer
      </b-button>
    </div>

    <template slot="action-button-nevermind">
      <b-button expanded @click="close(false)">
        Nevermind
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import _get from 'lodash/get';
import {mapState} from 'vuex';
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth, lockoutPeriodForOffers} from '../../../services/utils';
import {isLockableEdition, isReserveAuctionSalesType} from '../../../services/SaleTypes';
import ReserveAuctionUtils from '../../../services/ReserveAuctionUtils';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    EthWithFiatPrice,
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition', 'token', 'existingOffer', 'minBid', 'algoliaInfo'],
  data() {
    return {
      lockoutPeriodForOffers,
      minBidAmount: listOrOfferAmountFloorInEth,
      transactionHash: null,
      form: {
        offerAmount: null
      }
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    disableOfferButton() {
      if (this.transactionHash) {
        return true;
      }

      if (this.form.offerAmount < this.minBidAmount) {
        return true;
      }

      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      if (hasOffer) {
        return parseFloat(this.form.offerAmount) < (parseFloat(this.existingOffer.ethValue) + parseFloat(this.minBidAmount)).toFixed(6);
      }

      const offerEntered = parseFloat(this.form.offerAmount || 0);

      if (isReserveAuctionSalesType(this.token.salesType)) {
        const hasOffer = this.existingOffer && this.existingOffer.ethValue;
        if (hasOffer) {
          const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
          return offerEntered < currentOfferInEth;
        }
        return offerEntered === 0;
      }
      return offerEntered < parseFloat(this.minBidInEthCalculated);
    },
    minBidInEthCalculated() {
      if (this.minBid && this.minBid.eth_reserve_in_wei && ethers.utils.formatEther(this.minBid.eth_reserve_in_wei) && ethers.utils.formatEther(this.minBid.eth_reserve_in_wei) > 0) {
        return ethers.utils.formatEther(this.minBid.eth_reserve_in_wei);
      }
      return this.minBidAmount;
    },
    shouldShowHighestOffer() {
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      if (isReserveAuctionSalesType(this.token.salesType) && hasOffer && this.token.listing) {
        const reservePrice = parseFloat(ethers.utils.formatEther(this.token.listing.reservePrice));
        const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
        return currentOfferInEth >= reservePrice;
      }
      return hasOffer;
    },
    shouldShowReservePrice() {
      if (isReserveAuctionSalesType(this.token.salesType)) {
        const hasOffer = this.existingOffer && this.existingOffer.ethValue;
        if (hasOffer && this.token.listing) {
          const reservePrice = parseFloat(ethers.utils.formatEther(this.token.listing.reservePrice));
          const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
          return currentOfferInEth < reservePrice;
        }
        return true;
      }
      return false;
    },
    auctionWillNotStart() {
      if (!this.form.offerAmount) {
        return false;
      }
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));

      if (hasOffer) {
        return parseFloat(this.form.offerAmount) < (currentOfferInEth + listOrOfferAmountFloorInEth);
      }

      const reservePrice = parseFloat(ethers.utils.formatEther(this.token.listing.reservePrice));
      return parseFloat(this.form.offerAmount) < reservePrice;
    },
    bidAboveCurrentReserve() {
      const offerToPlace = parseFloat(this.form.offerAmount || 0);
      const reservePrice = parseFloat(ethers.utils.formatEther(this.token.listing.reservePrice));

      if (this.reserveAuctionCountdownInProgress) {
        const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
        return Math.floor(offerToPlace.toFixed(2)) > Math.floor(currentOfferInEth.toFixed(2));
      }

      return offerToPlace >= reservePrice;
    },
    auctionWillStart() {
      return !this.reserveAuctionCountdownStarted && this.bidAboveCurrentReserve;
    },
    isOutBid() {
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      if (!hasOffer || !this.form.offerAmount) {
        return false;
      }
      const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
      return parseFloat(this.form.offerAmount) >= (currentOfferInEth + listOrOfferAmountFloorInEth);
    },
    reserveAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.token.listing);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.token.listing);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.token.listing);
    }
  },
  methods: {
    isLockableEdition,
    isReserveAuctionSalesType,
    close(complete) {
      this.$emit('close', {purchaseComplete: complete});
    },
    async makeOffer() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/makeTokenOffer', {
          token: this.token.id,
          version: this.token.version,
          salesType: this.token.salesType,
          offerAmount: ethers.utils.parseEther(this.form.offerAmount).toString()
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.offerCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.token.id
          });
        }
        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.offerCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.form.offerAmount,
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.edition.salesType) ? 'reserve auction' : 'bids only',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.token.listingTimestamp ? this.token.listingTimestamp : null,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.form.offerAmount),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.edition.salesType) ? 'reserve auction' : 'bids only',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary'
            }
          }
        });

         await this.$store.dispatch('userStore/getContractAddress', {
          chainId: this.chainId,
          version: this.edition.version
        }).then((contractAddress) => {
          if (contractAddress) {
            this.$gtm.push({
            event: 'Offer',
          value: this.form.offerAmount,
          editiontitle: this.edition.metadata.name,
            tokenid: this.edition.id,
            artist: this.edition.artistAccount,
            purchasetype: 'BuywithEth',
            marketplace: 'Secondary',
            chain: this.chainId,
            contracttype: this.edition.version,
            contractaddress: contractAddress
            })
          }
        })
        this.close(true);
      } catch (e) {
        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.offerFailed,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.token.id,
            [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.offerFailed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.token.salesType) ? 'reserve auction' : 'offers only'
          }
        });

        console.error(e);
      }
    }
  }
};
</script>
