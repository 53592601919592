var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-content is-paddingless" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "columns is-mobile is-multiline" }, [
        _c(
          "div",
          { staticClass: "column is-7 has-padding-left-2 has-padding-top-4" },
          [
            _c(
              "n-link",
              {
                staticClass: "asset-card-wrapped-link",
                class: { ccArtworkInfoText: _vm.isCc },
                staticStyle: { "overflow-wrap": "break-word" },
                attrs: {
                  to: _vm.handleMarketPlaceRouting,
                  "data-testid": "artworkName",
                },
              },
              [
                _vm.size === "is-large"
                  ? _c(
                      "h2",
                      {
                        staticClass:
                          "has-text-left is-family-tertiary has-text-weight-bold",
                        class: {
                          "has-text-dark": !_vm.isCc,
                          "has-text-white": _vm.isCc,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("truncate")(_vm.workingMetadata.name, 30)
                          )
                        ),
                      ]
                    )
                  : _c(
                      "h6",
                      {
                        staticClass:
                          "has-text-left is-family-tertiary has-text-weight-bold",
                        class: {
                          "has-text-dark": !_vm.isCc,
                          "has-text-white": _vm.isCc,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("truncate")(_vm.workingMetadata.name, 30)
                          )
                        ),
                      ]
                    ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "column has-text-right is-5 is-size-7 has-padding-top-5 has-padding-right-2",
          },
          [
            _c(
              "n-link",
              {
                staticClass: "asset-card-wrapped-link",
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
                attrs: {
                  to: _vm.handleMarketPlaceRouting,
                  "data-testid": "editionSize",
                },
              },
              [
                _c("availability", {
                  attrs: {
                    available: _vm.edition.totalAvailable,
                    supply: _vm.edition.totalSupply,
                    edition: _vm.edition,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "column is-four-fifths has-padding-left-2 has-padding-bottom-2",
          },
          [
            _c(
              "n-link",
              {
                class: {
                  "has-text-dark": !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
                attrs: {
                  to: {
                    name: "profile-id",
                    params: { id: _vm.edition.artistAccount.toLowerCase() },
                  },
                  "data-testid": "artistName",
                },
              },
              [
                _c("artist-name-and-image-lookup", {
                  attrs: {
                    "artist-account": _vm.edition.artistAccount,
                    name: _vm.workingMetadata.artist,
                    collaborators: _vm.edition.collective
                      ? _vm.edition.collective.recipients
                      : _vm.edition.collaborators,
                    size: _vm.size,
                    "is-cc": _vm.isCc,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.clickHandler
          ? _c(
              "div",
              {
                staticClass:
                  "column is-one-fifth has-text-right has-padding-right-2",
              },
              [
                _vm.isLoggedInAccountViewingPage
                  ? _c(
                      "b-dropdown",
                      {
                        staticClass:
                          "is-clickable has-text-left is-family-tertiary",
                        attrs: { "aria-role": "list", position: "is-top-left" },
                      },
                      [
                        _c("b-icon", {
                          class: {
                            "has-text-dark": !_vm.isCc,
                            "has-text-white": _vm.isCc,
                          },
                          attrs: { slot: "trigger", icon: "dots-horizontal" },
                          slot: "trigger",
                        }),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem", custom: "" } },
                          [
                            _c("div", { staticClass: "columns is-mobile" }, [
                              _c("div", { staticClass: "column" }, [
                                _c("span", [_vm._v("Edition #")]),
                              ]),
                              _vm._v(" "),
                              _vm.isCc
                                ? _c(
                                    "div",
                                    { staticClass: "column has-text-right" },
                                    [
                                      _c("token-id", {
                                        attrs: {
                                          id: _vm.splitCreatorEditionId(
                                            _vm.edition.id
                                          ).editionId,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "column has-text-right" },
                                    [
                                      _c("token-id", {
                                        attrs: { id: _vm.edition.id },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr", { staticClass: "dropdown-divider" }),
                        _vm._v(" "),
                        _vm.canChangeGatedPublicPrice
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "changeprice",
                                attrs: { "aria-role": "change price" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePrice(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Change public price\n          "
                                ),
                              ]
                            )
                          : _vm.canChangeBuyNowPrice
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "changeprice",
                                attrs: { "aria-role": "change price" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePrice(_vm.edition)
                                  },
                                },
                              },
                              [_vm._v("\n            Change price\n          ")]
                            )
                          : _vm.canChangeReservePrice
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "changereserveprice",
                                attrs: { "aria-role": "change reserve price" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePrice(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Change reserve price\n          "
                                ),
                              ]
                            )
                          : _vm.canResultReserveAuction
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "resultauction",
                                attrs: { "aria-role": "result auction" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resultReserveAuction(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Complete auction\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canChangeSalesListing
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "changelisting",
                                attrs: { "aria-role": "change type" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeSaleType(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Change listing\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canSetOffchainMinimumBid
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "setminimumbid",
                                attrs: { "aria-role": "set minimum bid" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setMinimumBid(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Set minimum bid\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canTransferToken
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "transfer",
                                attrs: { "aria-role": "transfer to" },
                                on: {
                                  click: function ($event) {
                                    return _vm.transferEditionToken(_vm.edition)
                                  },
                                },
                              },
                              [_vm._v("\n            Transfer\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canSetUnlockableContent
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "setunlockablecontent",
                                attrs: {
                                  "aria-role": "set unlockable content",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setUnlockableContent(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Set unlockable content\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canBurnEdition
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "burn",
                                staticClass: "has-text-danger",
                                attrs: { "aria-role": "delete or burn" },
                                on: {
                                  click: function ($event) {
                                    return _vm.burn(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.edition.isOpenEdition
                                        ? "End open edition"
                                        : "Delete / Burn"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          {
                            ref: "view",
                            attrs: { "aria-role": "listitem", "has-link": "" },
                          },
                          [
                            _c(
                              "n-link",
                              { attrs: { to: _vm.handleMarketPlaceRouting } },
                              [_vm._v("\n              View\n            ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }