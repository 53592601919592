<template>
  <div class="editionCardSkeleton">
    <img class="editionCardSkeleton__card card-image-cover" src="/branding/bigloader-image.svg" />
    <div class="editionCardSkeleton__content">
      <b-skeleton :animated="true" height="20px" width="100px" />
      <div class="editionCardSkeleton__contentArtist">
        <b-skeleton :animated="true" circle width="32px" height="32px" />
        <b-skeleton :animated="true" height="20px" width="100px" />
      </div>
    </div>
    <div class="editionCardSkeleton__footer">
      <b-skeleton :animated="true" height="24px" width="100px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditionCardSkeleton'
}
</script>

<style lang="scss">
.editionCardSkeleton {
  background: #fff;
  box-shadow: 0 0em 2.5em 0.75em rgba(10, 10, 10, 0.1);

  &__card {
    border-bottom: 1px solid #ededed;
  }

  &__content {
    height: 130px;
    padding: 20px 32px 0;
  }

  &__contentArtist {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 24px;

    .b-skeleton {
      width: auto;
    }
  }

  &__footer {
    height: 73px;
    border-top: 1px solid #ededed;
    padding: 22px 32px;
  }
}
</style>
