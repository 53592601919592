var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [_vm._v("\n    Transfer\n  ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _c(
            "b-field",
            { attrs: { label: "Wallet address or ENS name" } },
            [
              _c("b-input", {
                attrs: { placeholder: "0x123 or .eth", autofocus: "" },
                on: {
                  change: function ($event) {
                    _vm.isValid = false
                  },
                  input: _vm.validateAddressOrEns,
                },
                model: {
                  value: _vm.addressOrEns,
                  callback: function ($$v) {
                    _vm.addressOrEns = $$v
                  },
                  expression: "addressOrEns",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.resolvedLabel
            ? _c(
                "div",
                { staticClass: "has-margin-top-7 is-small is-muted" },
                [
                  _c("b-icon", {
                    attrs: { icon: "check-circle-outline", size: "is-small" },
                  }),
                  _vm._v("\n      " + _vm._s(_vm.resolvedLabel) + "\n    "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Transferring token\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: {
                expanded: "",
                disabled: !_vm.isValid || _vm.transactionHash,
              },
              on: { click: _vm.transferToken },
            },
            [_vm._v("\n      Transfer token\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }