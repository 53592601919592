<script>
  import {Doughnut} from 'vue-chartjs'
  import _map from 'lodash/map'
  import _sortBy from 'lodash/sortBy'

  export default {
    extends: Doughnut,
    props: ['breakdown'],
    data() {
      return {
        options: {
          responsive: false,
          maintainAspectRatio: true,
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      }
    },
    mounted() {
      const sort = data => _sortBy(_map(data, (value, key) => ({value, key})), ['key']);

      const mapColors = (keys) => {
        return _map(keys, (key) => {
          if (key === 'Like') {
            return 'orange';
          }
          if (key === 'BidPlaced') {
            return 'brown';
          }
          if (key === 'BidPlaced') {
            return 'yellow';
          }
          if (key === 'EditionCreated') {
            return 'red';
          }
          if (key === 'Purchase') {
            return 'green';
          }
          return 'blue';
        })
      }

      const keys = _map(sort(this.breakdown.eventScoreCounts), 'key');
      const chartData = {
        labels: keys,
        datasets: [
          {
            backgroundColor: mapColors(keys),
            data: _map(sort(this.breakdown.eventScoreCounts), 'value')
          }
        ]
      };

      this.renderChart(chartData, this.options)
    }
  }
</script>
