<template>
  <div class="content" :class="{'box-skinny': skinny, 'box-fat': !skinny}">

    <figure class="avatar" :style="{ 'background-image': 'url(' + coverImage + ')'}" style="background-size: cover;">

      <img class="is-rounded"
           v-lazy="{
            src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${artist.address}`,
            error: createAccountIcon(artist.address)
          }"
      >
    </figure>

    <section class="content">
      <h2>
        {{ artist.username }} <b-icon icon="check-decagram" size="is-small" class="is-size-5 is-size-6-mobile is-verified" v-if="artist && isTwitterVerified(artist.address.toLowerCase(), artist.twitter)"></b-icon>
      </h2>

    </section>

    <section class="content has-margin-7">
      <div>
        <artist-stats :account="artist.address" :skinny="skinny"></artist-stats>
      </div>
      <p v-if="!skinny" class="is-family-primary">
        {{ ((artist.bio || '').substring(0, 150)) }}
      </p>
    </section>
  </div>
</template>

<script>
  import {toDataUrl} from 'myetherwallet-blockies';
  import {mapGetters} from 'vuex';
  import {cleanUpUrl} from '../services/utils';
  import ArtistStats from './ArtistStats';

  export default {
    components: {ArtistStats},
    props: ['artist', 'skinny'],
    data() {
      return {
        coverImage: '/branding/cover-preview.jpg'
      };
    },
    computed: {
      ...mapGetters('userStore', [
        'isInstagramVerified',
        'isTwitterVerified',
        'isEitherProfileVerified'
      ])
    },
    async mounted() {
      try {
        this.setCoverImage();
      } catch (e) {
        console.log('Problem with cover image');
      }
    },
    methods: {
      cleanUpUrl,
      createAccountIcon(address) {
        return toDataUrl(address);
      },
      setCoverImage() {
        if (this.artist.coverImageUrl) {
          const highResImage = new Image();
          highResImage.onload = () => {
            this.coverImage = this.artist.coverImageUrl;
          };
          highResImage.src = this.artist.coverImageUrl;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../assets/colours";
  @import "~bulma/sass/utilities/initial-variables";

  .box-fat {
    margin-top: 5rem;
    height: 26rem;
  }

  .box-skinny {
    margin-top: 2rem;
    height: 23rem;
  }

  .avatar {
    margin-top: -70px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .avatar img {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
    box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
    width: 128px;
    height: 128px;
  }
</style>
