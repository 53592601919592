var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "is-hidden-mobile" },
    [
      _c(
        "b-navbar",
        {
          ref: "navbarMenu",
          staticClass: "container px-2 mb-1",
          attrs: { "fixed-top": "", "close-on-click": true, type: "is-white" },
        },
        [
          _c(
            "template",
            { slot: "brand" },
            [
              _c(
                "b-navbar-item",
                {
                  staticClass: "pl-1 is-flex is-align-items-center",
                  attrs: { tag: "router-link", to: { path: "/" } },
                },
                [
                  _c("img", {
                    attrs: { src: "/branding/Black-blue-ko-logo.svg" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "start" },
            [
              _c("omni-search", {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.awayWidescreen,
                    expression: "awayWidescreen",
                  },
                ],
                key: _vm.componentKey,
                staticClass:
                  "mt-0 pt-2 ml-7-widescreen searchbar-nav-desktop is-one-third column is-hidden-desktop-only margin-left-searchbar nav-searchbar",
                attrs: { "is-mobile": false },
                on: {
                  "show-search": function ($event) {
                    return _vm.toggleSearchBar(false)
                  },
                  "update-search-string": _vm.updateSearch,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.searchBarEnabled
            ? _c(
                "template",
                { slot: "end" },
                [
                  _c("omni-search", {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.awayDesktop,
                        expression: "awayDesktop",
                      },
                    ],
                    staticClass:
                      "mt-3 searchbar-nav-desktop is-half column is-hidden-widescreen nav-searchbar",
                    attrs: { "is-mobile": false },
                    on: {
                      "show-search": function ($event) {
                        return _vm.toggleSearchBar(false)
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "template",
                { slot: "end" },
                [
                  _c(
                    "b-navbar-item",
                    {
                      staticClass: "is-hidden-widescreen",
                      on: {
                        click: function ($event) {
                          return _vm.toggleSearchBar(true)
                        },
                      },
                    },
                    [_c("b-icon", { attrs: { icon: "magnify" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-item",
                    {
                      staticClass: "text-size is-family-tertiary",
                      attrs: {
                        "data-testid": "marketplaceNav",
                        tag: "router-link",
                        to: { name: "marketplace" },
                      },
                    },
                    [_vm._v("\n        Marketplace\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-item",
                    {
                      staticClass: "text-size is-family-tertiary",
                      attrs: {
                        "data-testid": "activityNav",
                        label: "Activity",
                        tag: "router-link",
                        to: { name: "activity" },
                      },
                    },
                    [_vm._v("\n        Activity\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-item",
                    {
                      staticClass: "text-size is-family-tertiary",
                      attrs: {
                        "data-testid": "applyNav",
                        tag: "router-link",
                        to: { name: "apply" },
                      },
                    },
                    [_vm._v("\n        Apply\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-item",
                    {
                      staticClass: "text-size is-family-tertiary",
                      attrs: {
                        "data-testid": "launchMessenger",
                        id: "launchMessenger",
                      },
                      on: { click: _vm.launchMessenger },
                    },
                    [_vm._v("\n        Help\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-dropdown",
                    {
                      staticClass:
                        "is-hidden-mobile is-right text-size is-family-tertiary",
                      attrs: {
                        right: "",
                        label: "Discover",
                        hoverable: "",
                        "data-testid": "discoverNav",
                      },
                    },
                    [
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size is-family-tertiary",
                          attrs: {
                            "data-testid": "creator-contracts_nav",
                            tag: "router-link",
                            to: { name: "creator-contracts" },
                          },
                        },
                        [_vm._v("\n          Creator Contracts\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size is-family-tertiary",
                          attrs: {
                            "data-testid": "trendingNav",
                            tag: "router-link",
                            to: { name: "trending" },
                          },
                        },
                        [_vm._v("\n          Trending\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size is-family-tertiary",
                          attrs: {
                            "data-testid": "hallofNav",
                            tag: "router-link",
                            to: { name: "halloffame" },
                          },
                        },
                        [_vm._v("\n          Hall of fame\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size is-family-tertiary",
                          attrs: {
                            "data-testid": "collectionsNav",
                            tag: "router-link",
                            to: { name: "collections" },
                          },
                        },
                        [_vm._v("\n          Collections\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size is-family-tertiary",
                          attrs: {
                            "data-testid": "journalNav",
                            tag: "router-link",
                            to: { name: "journal" },
                          },
                        },
                        [_vm._v("\n          Journal\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-navbar-item",
                        {
                          staticClass: "text-size",
                          attrs: {
                            "data-testid": "helpCentreNav",
                            href: "https://docs.knownorigin.io/en/",
                            target: "_blank",
                          },
                        },
                        [_vm._v("\n          Help Centre\n        ")]
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c("b-navbar-item", { attrs: { tag: "div" } }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "is-flex is-flex-direction-column is-align-items-center",
                            attrs: {
                              href: "https://discord.gg/knownorigin",
                              target: "_blank",
                              "data-testid": "discord_nav",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "ml-2",
                              attrs: { icon: _vm.faDiscord, size: "xs" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://twitter.com/knownorigin_io",
                              target: "_blank",
                              "data-testid": "twitterNav",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-6",
                              attrs: { icon: "twitter", size: "is-small" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.instagram.com/knownorigin.io/",
                              target: "_blank",
                              "data-testid": "instaNav",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-6",
                              attrs: { icon: "instagram", size: "is-small" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.reddit.com/r/knownorigin/",
                              target: "_blank",
                              "data-testid": "redditNav",
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "ml-6 mr-5",
                              attrs: { icon: "reddit", size: "is-small" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("notification-menu-list", {
                    staticClass: "is-hidden-mobile",
                  }),
                  _vm._v(" "),
                  !_vm.isWeb3Connected
                    ? _c(
                        "b-navbar-item",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                outlined: "",
                                "data-testid": "connectWalletnBtn",
                              },
                              on: { click: _vm.loginToWeb3 },
                            },
                            [_vm._v("Connect wallet\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isWeb3Connected
                    ? _c(
                        "b-navbar-dropdown",
                        {
                          staticClass:
                            "is-hidden-mobile text-size is-family-tertiary",
                          attrs: {
                            right: "",
                            label: "Profile",
                            hoverable: "",
                            "data-testid": "profileNav",
                          },
                        },
                        [
                          _c(
                            "b-navbar-item",
                            {
                              staticClass: "text-size",
                              attrs: {
                                tag: "router-link",
                                to: {
                                  name: "profile-id",
                                  params: { id: _vm.account },
                                },
                                "data-testid": "viewprofileNav",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.hideMenuOverlay.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("\n          View profile\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-navbar-item",
                            {
                              staticClass: "text-size",
                              attrs: { "data-testid": "logoutNav" },
                              on: { click: _vm.logoutWeb3 },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.hideMenuOverlay.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("\n          Logout\n        ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }