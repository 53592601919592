var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isValidGatedSale
    ? _c(
        "div",
        {
          staticClass:
            "column is-half has-padding-left-3 has-padding-top-0 is-family-tertiary",
        },
        [
          _vm.hasSalePrice
            ? _c("div", { staticClass: "has-text-weight-bold has-text-left" }, [
                _c(
                  "div",
                  { staticClass: "has-text-weight-bold has-text-left" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "has-text-left is-family-tertiary",
                        class: { "has-text-white": _vm.isCc },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.mapActivityEventTitle(
                                _vm.activityEvent.eventType,
                                _vm.activityEvent.edition.salesType
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "has-text-weight-bold has-text-left" },
                  [
                    _vm.activityEvent.eventValueInWei
                      ? _c("eth-with-fiat-price", {
                          staticClass: "has-text-weight-bold has-text-left",
                          class: { "has-text-white": _vm.isCc },
                          attrs: {
                            "price-in-wei": _vm.activityEvent.eventValueInWei,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "has-text-weight-bold has-text-left" }, [
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      {
                        class: {
                          "is-muted":
                            _vm.edition.gatedSale.phases[0].state ===
                              "pre-phase" && !_vm.isCc,
                          "has-text-white": _vm.isCc,
                        },
                      },
                      [_vm._v("\n        Early access\n      ")]
                    ),
                    _vm._v(" "),
                    _vm.edition.gatedSale.phases[0].priceInWei === "0"
                      ? _c(
                          "p",
                          {
                            staticClass: "has-text-weight-bold",
                            class: {
                              "is-muted":
                                _vm.edition.gatedSale.phases[0].state ===
                                  "pre-phase" && !_vm.isCc,
                              "has-text-white": _vm.isCc,
                            },
                          },
                          [_vm._v("\n        Free claim\n      ")]
                        )
                      : _c("eth-with-fiat-price", {
                          staticClass: "has-text-weight-bold",
                          class: {
                            "is-muted":
                              _vm.edition.gatedSale.phases[0].state ===
                                "pre-phase" && !_vm.isCc,
                            "has-text-white": _vm.isCc,
                          },
                          attrs: {
                            "price-in-wei":
                              _vm.edition.gatedSale.phases[0].priceInWei,
                          },
                        }),
                  ],
                  1
                ),
              ]),
        ]
      )
    : _vm.reserveAuctionCountdownInProgress
    ? _c(
        "div",
        { staticClass: "column is-half has-padding-left-3 has-padding-top-0" },
        [
          _c("div", { staticClass: "has-text-weight-bold has-text-left" }, [
            _c(
              "span",
              {
                staticClass: "has-text-left is-family-tertiary",
                class: {
                  "has-text-grey": !_vm.startDatePassed && !_vm.isCc,
                  "has-text-white": _vm.isCc,
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.mapActivityEventTitle(
                        _vm.activityEvent.eventType,
                        _vm.activityEvent.edition.salesType
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "has-text-weight-bold has-text-left" },
            [
              _vm.activityEvent.eventValueInWei
                ? _c("eth-with-fiat-price", {
                    staticClass: "has-text-weight-bold has-text-left",
                    class: { "has-text-white": _vm.isCc },
                    attrs: {
                      "price-in-wei": _vm.activityEvent.eventValueInWei,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _c(
        "footer-left-asset-card",
        { attrs: { edition: _vm.edition } },
        [
          _c("template", { slot: "asset-card-footer-left-title" }, [
            _vm.activityEvent.eventValueInWei === "0"
              ? _c(
                  "span",
                  {
                    staticClass: "has-text-left",
                    class: {
                      "has-text-grey": !_vm.startDatePassed && !_vm.isCc,
                      "has-text-white": _vm.isCc,
                    },
                  },
                  [_vm._v("\n      Claimed for\n    ")]
                )
              : _c(
                  "span",
                  {
                    staticClass: "has-text-left is-family-tertiary",
                    class: {
                      "has-text-grey": !_vm.startDatePassed && !_vm.isCc,
                      "has-text-white": _vm.isCc,
                    },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.mapActivityEventTitle(
                            _vm.activityEvent.eventType,
                            _vm.activityEvent.edition.salesType
                          )
                        ) +
                        "\n    "
                    ),
                  ]
                ),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "asset-card-footer-left" },
            [
              _vm.activityEvent.eventValueInWei === "0"
                ? _c(
                    "p",
                    {
                      staticClass: "has-text-weight-semibold",
                      class: { "has-text-white": _vm.isCc },
                    },
                    [_vm._v("\n      Free\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activityEvent.eventValueInWei
                ? _c("eth-with-fiat-price", {
                    staticClass: "has-text-weight-bold has-text-left",
                    class: { "has-text-white": _vm.isCc },
                    attrs: {
                      "price-in-wei": _vm.activityEvent.eventValueInWei,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }