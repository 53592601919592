var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "columns is-flex is-vcentered column has-text-left is-half",
      class: {
        "countdown-started":
          _vm.reserveAuctionCountdownStarted &&
          !_vm.reserveAuctionCountdownComplete &&
          !_vm.isCc &&
          !_vm.fromActivity,
        "pl-0": _vm.isCc,
        "is-clipped": !_vm.fromActivity,
      },
    },
    [
      _c(
        "n-link",
        {
          staticClass: "asset-card-wrapped-link",
          attrs: { to: _vm.handleMarketPlaceRouting },
        },
        [
          _vm.isCc
            ? _c("div", { staticClass: "column is-family-tertiary" }, [
                _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                  ? _c("section", [
                      !_vm.startDatePassed
                        ? _c(
                            "div",
                            { staticClass: "has-text-white" },
                            [
                              _c("p", [_vm._v("Starts in")]),
                              _vm._v(" "),
                              _c("clock-count-down-simple", {
                                attrs: {
                                  "start-date": _vm.edition.startDate,
                                  "white-text": true,
                                },
                              }),
                            ],
                            1
                          )
                        : !_vm.endDatePassed(_vm.edition) &&
                          !_vm.isNeverEndingEdition(_vm.edition)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "has-text-white has-text-weight-semibold",
                            },
                            [
                              _c("p", [_vm._v("Ends in")]),
                              _vm._v(" "),
                              _c("clock-count-down-simple", {
                                attrs: {
                                  "start-date": _vm.edition.endDate,
                                  "white-text": true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : !_vm.startDatePassed && !_vm.isSoldOutPrimary
                  ? _c("section", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "has-text-weight-semibold has-text-white",
                        },
                        [
                          _vm._v("\n        Starts in\n        "),
                          _c("clock-count-down-simple", {
                            attrs: {
                              "start-date": _vm.edition.startDate,
                              "white-text": true,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", [
                _c("div", { staticClass: "is-family-tertiary" }, [
                  _vm.phase &&
                  _vm.phase.state !== "public" &&
                  _vm.isNotSoldOutPrimary
                    ? _c("div", [
                        _vm.phase.state === "pre-phase"
                          ? _c(
                              "span",
                              { staticClass: "has-text-weight-semibold" },
                              [_vm._v("\n         Starts in\n      ")]
                            )
                          : _vm.phase.state === "active"
                          ? _c(
                              "span",
                              {
                                staticClass: "has-text-weight-semibold",
                                class: { "is-muted": !_vm.isCc },
                              },
                              [_vm._v("\n          Public price\n        ")]
                            )
                          : _vm._e(),
                      ])
                    : !_vm.startDatePassed && !_vm.isSoldOutPrimary
                    ? _c("span", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v("\n        Starts in\n      "),
                      ])
                    : _vm.isSteppedSalesType(_vm.edition.salesType) &&
                      _vm.isNotSoldOutPrimary(_vm.edition)
                    ? _c(
                        "span",
                        {
                          staticClass: "has-text-weight-semibold",
                          class: { "is-muted": !_vm.isCc },
                        },
                        [_vm._v("\n        Next price\n      ")]
                      )
                    : _vm.reserveAuctionCountdownStarted &&
                      !_vm.reserveAuctionCountdownComplete
                    ? _c("span", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v("\n        Auction ends in\n      "),
                      ])
                    : _vm.startDateJustPassed && _vm.edition.startDate > 0
                    ? _c("span", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v("\n           \n      "),
                      ])
                    : _c("span", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(" "),
                      ]),
                ]),
                _vm._v(" "),
                _vm.phase && _vm.phase.state !== "public"
                  ? _c(
                      "span",
                      [
                        _vm.phase.state === "pre-phase"
                          ? _c("clock-count-down-simple", {
                              staticClass: "has-text-weight-semibold",
                              attrs: {
                                "start-date": _vm.phase.startTime,
                                "white-text": false,
                              },
                            })
                          : _vm.phase.state === "active"
                          ? _c("eth-with-fiat-price", {
                              staticClass: "has-text-weight-semibold",
                              class: { "is-muted": !_vm.isCc },
                              attrs: { "price-in-wei": _vm.edition.priceInWei },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : !_vm.startDatePassed && !_vm.isSoldOutPrimary
                  ? _c(
                      "span",
                      [
                        _c("clock-count-down-simple", {
                          staticClass: "has-text-weight-semibold",
                          attrs: {
                            "start-date": _vm.edition.startDate,
                            "white-text": false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.isSteppedSalesType(_vm.edition.salesType) &&
                    _vm.isNotSoldOutPrimary(_vm.edition)
                  ? _c(
                      "span",
                      [
                        _c("eth-with-fiat-price", {
                          staticClass:
                            "has-text-danger has-text-weight-semibold",
                          attrs: {
                            "price-in-wei": _vm.getNextStep(
                              _vm.edition.priceInWei,
                              _vm.edition.stepSaleStepPrice
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.reserveAuctionCountdownStarted &&
                    !_vm.reserveAuctionCountdownComplete
                  ? _c(
                      "span",
                      { staticClass: "has-text-weight-semibold" },
                      [
                        _c("auction-count-down-simple", {
                          staticClass: "has-text-weight-semibold",
                          attrs: {
                            "end-date": _vm.edition.reserveAuctionEndTimestamp,
                            "white-text": false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }