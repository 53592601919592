var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.results && _vm.results.passed
    ? _c("div", [
        _c("li", [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column heading" }, [
              _c(
                "a",
                { attrs: { href: _vm.generateVerifyLink, target: "_blank" } },
                [
                  _vm._v("\n          Content Credentials\n          "),
                  _c("b-icon", {
                    staticClass: "is-size-7 is-size-6-mobile",
                    attrs: { icon: "check-decagram", size: "is-small" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "columns heading" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm._v("\n        Wallet signer:\n        "),
                _c("profile-name-link", {
                  attrs: { "eth-account": _vm.minter },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.results && _vm.results.claimSigned
          ? _c("li", [
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v(
                    "\n        Signed on: " +
                      _vm._s(
                        _vm._f("moment")(
                          _vm.results.claimSigned,
                          "Do MMM YYYY HH:mm:ss"
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.results && _vm.results.claimIssuer
          ? _c("li", [
              _c("div", { staticClass: "columns is-vcentered" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("\n        Signed by:\n        "),
                  _vm.isAdobeIssuer
                    ? _c("span", [
                        _c("img", {
                          staticStyle: { "max-height": "15px" },
                          attrs: {
                            src: "/partners/cai-issuer/adobe.svg",
                            alt: "adobe-logo",
                          },
                        }),
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.results.claimIssuer))]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.results && _vm.results.claimProducer
          ? _c("li", [
              _c("div", { staticClass: "columns is-vcentered" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("\n        Produced with:\n        "),
                  _vm.isPhotoshopProduced
                    ? _c("span", [
                        _c("img", {
                          staticStyle: { "max-height": "15px" },
                          attrs: {
                            src: "/partners/cai-issuer/Adobe_Photoshop_CC_icon.svg",
                            alt: "photoshop-logo",
                          },
                        }),
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.results.claimProducer))]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("hr"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column heading" }, [
          _c(
            "a",
            {
              staticClass: "is-linkable",
              attrs: {
                href: "/journal/platformupdate/content-authenticity-initiative",
                target: "_blank",
              },
            },
            [_vm._v("\n          Learn more\n        ")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }