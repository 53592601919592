var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shorter
    ? _c("span", [
        _vm._v(
          _vm._s(
            _vm._f("moment")(new Date(_vm.timestampInSecs * 1000), "MMM Do YY")
          )
        ),
      ])
    : _c("span", [
        _vm._v(
          _vm._s(
            _vm._f("moment")(
              new Date(_vm.timestampInSecs * 1000),
              "MMM Do YYYY"
            )
          )
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }