import first from 'lodash/first';
import truncate from 'lodash/truncate';
import slugify from '@sindresorhus/slugify';

// N.B: any changes to this library need to also be made in the API
export function usernameSlug(username) {
  return slugify(username, {decamelize: false})
}

export function collectionSlug(name) {
  try {
    return slugify(name, {lowercase: true, decamelize: false})
  } catch (err) {

  }
}

export function editionSlug(name, id) {
  if (!name) {
    return id;
  }

  // slugify it
  const nameSlug = slugify(name, {
    decamelize: true,
    lowercase: true,
    preserveLeadingUnderscore: false,
    separator: '-'
  });

  // take only full slugified words upto 50 characters
  const trimmedSlug = truncate(nameSlug, {
    length: 50, // max length
    separator: '-', // slug format
    omission: '' // dont show anything if broken
  });

  // always append on id
  return `${id}-${trimmedSlug}`;
}

export function unpackEditionId(editionIdSlug) {
  const editionId = parseInt(editionIdSlug);
  if (Number.isInteger(editionId)) {
    return editionId.toString(); // queries expect a string not an int
  }

  // Assume the last part is always the edition ID
  const explodedSlug = (editionIdSlug || '').split('-');

  // grab the first item assuming its the number
  return first(explodedSlug);
}

export function unpackCCEditionId(editionId, editionContract) {
  const editionSlug = [editionContract, editionId];
  return editionSlug.join('-');
}
