var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition.isOpenEdition
    ? _c(
        "div",
        { staticClass: "column content" },
        [
          _c(
            "b-button",
            {
              staticClass:
                "has-margin-bottom-7 is-medium is-danger is-fullwidth",
              attrs: { outlined: "" },
              on: { click: _vm.openEndEditionModal },
            },
            [_vm._v("End open edition")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }