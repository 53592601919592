<template>
  <span v-if="isIcon" class="is-flex is-justify-content-center hand-pointer copyAddress" @click="copyAddress">
  <b-tooltip :label="address | dotDotDot" class="copyAddress__copyBtn is-flex is-align-items-center is-justify-content-center" type="is-dark">
    <img src="/profile/eth.svg" class="copyAddress__icon" />
  </b-tooltip>
  </span>

  <span v-else class="hand-pointer" @click="copyAddress">
    <b-icon icon="ethereum" size="is-small"></b-icon>
    {{ address | dotDotDot }}
    &nbsp;
    <b-icon icon="content-copy" size="is-small" type="is-grey"></b-icon>
  </span>
</template>

<script>
import {copyText} from '../../services/fileUtils';

export default {
  props: ['address', 'isIcon'],
  methods: {
    copyAddress() {
      copyText(this.address);
      this.$buefy.toast.open({message: `<span class="is-family-tertiary">Address copied</span>`});
    }
  }
};
</script>
<styles lang="scss" scoped>
  .copyAddress {
    border: 1px solid #DBDBDB;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    &__copyBtn {
      width: 100%;
      height: 100%;

      .tooltip-trigger {
        width: 100%;
        height: 100%;
      }
    }

    &__icon {
      height: 20px;
    }
  }
</styles>
