var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "section",
              {
                staticClass:
                  "has-margin-7 has-padding-top-7 has-padding-bottom-7",
              },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "\n            Select cover image - max 5mb (jpg, png, gif)\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-field",
                  { staticClass: "file is-fullwidth", attrs: { expanded: "" } },
                  [
                    _c(
                      "b-upload",
                      {
                        attrs: {
                          expanded: "",
                          accept: "image/*",
                          disabled: _vm.saving,
                        },
                        on: { input: _vm.handleNewFile },
                        model: {
                          value: _vm.file,
                          callback: function ($$v) {
                            _vm.file = $$v
                          },
                          expression: "file",
                        },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "button is-primary" },
                          [
                            _c("b-icon", { attrs: { icon: "upload" } }),
                            _vm._v(" "),
                            _vm.file
                              ? _c("span", [_vm._v(_vm._s(_vm.file.name))])
                              : _c("span", [_vm._v("Click to upload")]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.file
                  ? _c(
                      "b-field",
                      [
                        _c(
                          "b-upload",
                          {
                            attrs: {
                              "drag-drop": "",
                              expanded: "",
                              accept: "image/*",
                              disabled: _vm.saving,
                            },
                            on: { input: _vm.handleNewFile },
                            model: {
                              value: _vm.file,
                              callback: function ($$v) {
                                _vm.file = $$v
                              },
                              expression: "file",
                            },
                          },
                          [
                            _c("section", { staticClass: "section" }, [
                              _c(
                                "div",
                                { staticClass: "content has-text-centered" },
                                [
                                  _c(
                                    "p",
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "upload",
                                          size: "is-large",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "Drop your cover image here or click to upload"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("section", [
                  _vm.file && _vm.file.thumb
                    ? _c("img", { attrs: { src: _vm.file.thumb } })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.error
                  ? _c("section", [
                      _c("div", { staticClass: "has-text-danger" }, [
                        _vm._v(_vm._s(_vm.error)),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { expanded: "", disabled: _vm.saving },
                    on: { click: _vm.close },
                  },
                  [_vm._v("\n            Nevermind\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-primary",
                    attrs: {
                      expanded: "",
                      disabled: !_vm.file,
                      loading: _vm.saving,
                    },
                    on: { click: _vm.uploadNewCoverImage },
                  },
                  [_vm._v("\n            Save\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }