<script>
  import {PolarArea} from 'vue-chartjs'
  import _map from 'lodash/map'
  import _sortBy from 'lodash/sortBy'

  export default {
    extends: PolarArea,
    props: ['breakdown'],
    data() {
      return {
        options: {
          responsive: false,
          maintainAspectRatio: true,
          animation: {
            animateRotate: true
          }
        }
      }
    },
    mounted() {
      const sort = data => _sortBy(_map(data, (value, key) => ({value, key})), ['key']);

      const chartData = {
        labels: _map(sort(this.breakdown.eventScoreCounts), 'key'),
        datasets: [
          {
            label: '% scores weight',
            borderWidth: 1,
            backgroundColor: 'rgba(250,1,49,0.2)',
            data: sort(this.breakdown.eventScoreCounts).map(({value}) => {
              return (value / this.breakdown.totalScores) * 100;
            })
          },
          {
            label: '% events weight',
            borderWidth: 1,
            backgroundColor: 'rgba(5,42,239,0.2)',
            data: sort(this.breakdown.eventCounts).map(({value}) => {
              return (value / this.breakdown.totalEvents) * 100;
            })
          }
        ]
      };

      this.renderChart(chartData, this.options)
    }
  }
</script>
