import gql from 'graphql-tag';

export const GET_COLLECTIVE_FOR_EDITION = gql`
  query GetCollectiveForEqition($editions: [String]) {
    collectives: collectives(where: {editions_contains: $editions} subgraphError: allow) {
      id
      baseHandler
      creator
      recipients
      splits
      createdTimestamp
      editions {
        id
      }
      isDeployed
      transactionHash
    }
  }
`;
