import {mapSalesType, reverseMapSearchSalesType} from './SaleTypes'

const indexName = 'mainnet-marketplace-index';
// When doing dev change to below
// const indexName = 'goerli-marketplace-index';

const PREFIX_SALE_TYPE = 'sale_type_'
const PREFIX_SIZE = 'size_'
const PREFIX_MEDIUM = 'medium_'
const PREFIX_ARTIST = 'artist_'
const PREFIX_TAG = 'tag_'
const PREFIX_FORMAT = 'format_'

export const convertStateToRoute = (uiState) => {
  const indexUiState = uiState[indexName];

  if (indexUiState) {
    let urlArgs = {};

    if (indexUiState.refinementList) {
      for (const [key, value] of Object.entries(indexUiState.refinementList)) {
        const convertedState = convertRefinementToState(key, value)
        urlArgs = {...urlArgs, ...convertedState}
      }
    }

    return {
      sortBy: convertSortBy(indexUiState.sortBy),
      ...urlArgs
    }
  }
}

const convertRefinementToState = (key, value) => {
  const obj = {}

  switch (key) {
    case 'filters.saleTypeSummary':
      value.forEach((v) => {
        const key = generateRouteObjKey(mapSalesType(+v), PREFIX_SALE_TYPE, true, true, false)
        obj[key] = true
      })

      return obj
    case 'filters.editionSizeSummary':
      value.forEach((v) => {
        const key = generateRouteObjKey(v, PREFIX_SIZE, true, true, false)
        obj[key] = true
      })

      return obj

    case 'filters.medium':
      value.forEach((v) => {
        const key = generateRouteObjKey(v, PREFIX_MEDIUM, true, true, false)
        obj[key] = true
      })

      return obj

    case 'filters.isGenesisEdition':
      return {genesis: true}

    case 'filters.isEnhancedEdition':
      return {enhanced: true}

    case 'filters.isCollaboration':
      return {collaboration: true}

    case 'external.unlockable':
      return {unlockable: true}

    case 'external.trending':
      return {trending: true}

    case 'external.drop':
      return {drop: true}

    case 'filters.isSoldOutPrimary':
      return {primary: false}

    case 'filters.isGatedEdition':
      return {earlyAccess: true}

    case 'filters.isCreatorContract':
      if (value[0] && value[0] === 'true') {
        return {creator: true}
      } else {
        return {shared: true}
      }

    case 'metadata.artistName':
      value.forEach((v) => {
        const key = generateRouteObjKey(v, PREFIX_ARTIST, false, false, true)
        obj[key] = true
      })

      return obj

    case 'filters.tags':
      value.forEach((v) => {
        const key = generateRouteObjKey(v, PREFIX_TAG, true, true, false)
        obj[key] = true
      })

      return obj

    case 'filters.format':
      value.forEach((v) => {
        const key = generateRouteObjKey(v, PREFIX_FORMAT, true, true, false)
        if (key) {
          obj[key] = true
        }
      })

      return obj
  }
}

export const convertRouteToState = (routeState) => {
  let sortByVal = 'mainnet-marketplace-index_filters_createdTimestamp_desc';

  if (routeState.sortBy) {
    sortByVal = convertSortBy(routeState.sortBy)
    delete routeState.sortBy
  }
  const refinementList = convertStateToRefinementList(routeState)

  return {
    [indexName]: {
      query: routeState.searchInput,
      configure: {filters: 'filters.active.true', hitsPerPage: 12},
      refinementList,
      sortBy: sortByVal
    }
  }
}

function convertStateToRefinementList(state) {
  const refinementList = {};

  for (const [key] of Object.entries(state)) {
    if (key.startsWith(PREFIX_SALE_TYPE)) {
      const saleType = reverseMapSearchSalesType(key.split(PREFIX_SALE_TYPE).pop())
      refinementList['filters.saleTypeSummary'] ? refinementList['filters.saleTypeSummary'].push(`${saleType}`) : refinementList['filters.saleTypeSummary'] = [`${saleType}`]
    }

    if (key.startsWith(PREFIX_SIZE)) {
      const sizeType = key.split(PREFIX_SIZE).pop() === '1_of_1' ? '1 of 1' : 'Multi-edition'
      refinementList['filters.editionSizeSummary'] ? refinementList['filters.editionSizeSummary'].push(sizeType) : refinementList['filters.editionSizeSummary'] = [sizeType]
    }

    if (key === 'genesis') {
      refinementList['filters.isGenesisEdition'] = [true]
    }
    if (key === 'enhanced') {
      refinementList['filters.isEnhancedEdition'] = [true]
    }
    if (key === 'collaboration') {
      refinementList['filters.isCollaboration'] = [true]
    }
    if (key === 'earlyAccess') {
      refinementList['filters.isGatedEdition'] = [true]
    }
    if (key === 'creator') {
      refinementList['filters.isCreatorContract'] = [true]
    }
    if (key === 'shared') {
      refinementList['filters.isCreatorContract'] = [false]
    }

    if (key === 'unlockable') {
      refinementList['external.unlockable'] = [true]
    }
    if (key === 'trending') {
      refinementList['external.trending'] = [true]
    }
    if (key === 'drop') {
      refinementList['external.drop'] = [true]
    }
    if (key === 'primary') {
      refinementList['filters.isSoldOutPrimary'] = [false]
    }

    if (key.startsWith(PREFIX_MEDIUM)) {
      let mediumType;
      const mediumKey = key.split(PREFIX_MEDIUM).pop()
      switch (mediumKey) {
        case 'image':
        case 'video':
        case '360_video':
          mediumType = (mediumKey.charAt(0).toUpperCase() + mediumKey.slice(1)).replace(/[_]/g, ' ')
          break

        case '3d_model':
          mediumType = '3D model'
          break
      }

      refinementList['filters.medium'] ? refinementList['filters.medium'].push(mediumType) : refinementList['filters.medium'] = [mediumType]
    }

    if (key.startsWith(PREFIX_ARTIST)) {
      const artistType = decodeURI(key.split(PREFIX_ARTIST).pop())
      refinementList['metadata.artistName'] ? refinementList['metadata.artistName'].push(artistType) : refinementList['metadata.artistName'] = [artistType]
    }

    if (key.startsWith(PREFIX_TAG)) {
      const tagType = key.split(PREFIX_TAG).pop().replace(/[_]/g, ' ')
      refinementList['filters.tags'] ? refinementList['filters.tags'].push(tagType) : refinementList['filters.tags'] = [tagType]
    }

    if (key.startsWith(PREFIX_FORMAT)) {
      const formatKey = key.split(PREFIX_FORMAT).pop()
      const formatType = (formatKey.charAt(0).toUpperCase() + formatKey.slice(1)).replace(/[_]/g, ' ')
      refinementList['filters.format'] ? refinementList['filters.format'].push(formatType) : refinementList['filters.format'] = [formatType]
    }
  }

  return refinementList
}

function generateRouteObjKey(value, prefix, replace, lowerCase, encode) {
  // TODO why is object Object getting in here?
  if (value !== '[object Object]') {
    if (prefix) {
      value = `${prefix}${value}`
    }
    if (encode) {
      value = encodeURI(value)
    }
    if (replace) {
      value = value.replace(/[ -]/g, '_')
    }
    if (lowerCase) {
      value = value.toLowerCase()
    }
    return value
  }
}

export const convertSortBy = (val) => {
  switch (val) {
    case 'mainnet-marketplace-index_extended_trendingScore_desc':
      return 'relevance'

    case 'relevance':
      return 'mainnet-marketplace-index_extended_trendingScore_desc'

    case 'mainnet-marketplace-index_filters_createdTimestamp_desc':
      return 'newest'

    case 'newest':
      return 'mainnet-marketplace-index_filters_createdTimestamp_desc'

    case 'mainnet-marketplace-index_filters_createdTimestamp_asc':
      return 'oldest'

    case 'oldest':
      return 'mainnet-marketplace-index_filters_createdTimestamp_asc'

    case 'mainnet-marketplace-index_filters_lowestListPriceInEth_asc':
      return 'lowest_price'

    case 'lowest_price':
      return 'mainnet-marketplace-index_filters_lowestListPriceInEth_asc'

    case 'mainnet-marketplace-index_filters_highestListPriceInEth_desc':
      return 'highest_price'

    case 'highest_price':
      return 'mainnet-marketplace-index_filters_highestListPriceInEth_desc'

    default:
      return undefined
  }
}

export const mapAttributeToName = (label) => {
  switch (label) {
    case 'filters.saleTypeSummary':
      return 'Sale type';
    case 'filters.editionSizeSummary':
      return 'Size';
    case 'filters.format':
      return 'Format';
    case 'filters.tags':
      return 'Tags';
    case 'filters.medium':
      return 'Medium';
    case 'filters.isOnSale':
      return 'On sale';
    case 'filters.isGenesisEdition':
      return 'Genesis';
    case 'filters.isEnhancedEdition':
      return 'Embedded';
    case 'filters.isCollaboration':
      return 'Collaboration';
    case 'filters.highestOfferInEth':
      return 'Price range';
    case 'external.unlockable':
      return 'Unlockable';
    case 'filters.isGatedEdition':
      return 'Early access'
    case 'filters.isCreatorContract':
      return 'Creator contract'
    case 'external.trending':
      return 'Trending';
    case 'external.drop':
      return 'Drops';
    case 'filters.isSoldOutPrimary':
      return 'Primary';
    case 'metadata.artistName':
      return 'Artists';
    default:
      return label;
  }
};

export const mapSearchButtonToFilter = (attribute, val) => {
  switch (attribute) {
    case 'filters.saleTypeSummary':

      switch (val) {
        case '1':
          return 'saleType:buyNow'

        case '3':
          return 'saleType:bidsOnly'

        case '4':
          return 'saleType:steppedSale'

        case '5':
          return 'saleType:reserveAuction'
      }
      break

    case 'filters.editionSizeSummary':

      switch (val) {
        case '1 of 1':
          return 'editionSize:1of1'

        case 'Multi-edition':
          return 'editionSize:multi'
      }

      break

    case 'filters.isGenesisEdition':
      return 'isGenesis:true'

    case 'filters.isEnhancedEdition':
      return 'isEnhanced:true'

    case 'filters.isCollaboration':
      return 'isCollaboration:true'

    case 'filters.isGatedEdition':
      return 'isEarlyAccess:true'

    case 'filters.isCreatorContract':
      return 'isCreatorContract'

    case 'external.unlockable':
      return 'unlockable:true'

    case 'external.drop':
      return 'drop:true'

    case 'filters.medium':

      switch (val) {
        case 'Image':
          return 'medium:image'

        case 'Video':
          return 'medium:video'

        case '3D Model':
          return 'medium:3dModel'

        case '360 Image':
          return 'medium:360Image'
      }
      break

    case 'index.sortBy':

      if (val.includes('createdTimestamp_desc')) {
        return 'sortBy:createdDesc'
      } else if (val.includes('createdTimestamp_asc')) {
        return 'sortBy:createdAsc'
      } else if (val.includes('lowestListPriceInEth_desc')) {
        return 'sortBy:priceDesc'
      } else if (val.includes('lowestListPriceInEth_asc')) {
        return 'sortBy:priceAsc'
      } else if (val.includes('trendingScore_desc')) {
        return 'sortBy:trendingDesc'
      }

      break

    case 'metadata.artistName':
      return `artist:${val}` // TODO does this need to be camel cased?

    case 'filters.format':
      return `format:${val}`

    case 'filters.tags':
      return `tags:${val}`

    case 'filters.isSoldOutPrimary':
      return 'primary:true'
  }
}
