var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "10px", "margin-right": "10px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _c("section", [
              _c("h2", [_vm._v("\n            Early access list\n          ")]),
              _vm._v(" "),
              _c("h5", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n            Accounts on the early access list for this edition\n          "
                ),
              ]),
              _vm._v(" "),
              _vm.addresses
                ? _c(
                    "section",
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            data: _vm.addresses,
                            "mobile-cards": false,
                            "debounce-search": 1000,
                            paginated: "",
                            "pagination-simple": "",
                            "per-page": 6,
                            striped: "",
                          },
                        },
                        [
                          _c("b-table-column", {
                            attrs: { field: "address", searchable: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "searchable",
                                  fn: function (props) {
                                    return [
                                      _c("b-input", {
                                        staticStyle: { width: "400px" },
                                        attrs: {
                                          placeholder:
                                            "Search by wallet address ... ",
                                          icon: "magnify",
                                        },
                                        model: {
                                          value: props.filters["address"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.filters,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.filters['address']",
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c("profile-name-link-and-avatar", {
                                        staticStyle: {
                                          "padding-bottom": "0rem !important",
                                        },
                                        attrs: {
                                          "eth-account": props.row.address,
                                          "disable-link": true,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1935793543
                            ),
                          }),
                          _vm._v(" "),
                          _c("template", { slot: "empty" }, [
                            _c("p", { staticClass: "has-text-weight-bold" }, [
                              _vm._v("Profile ineligible for Early Access"),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n            Close\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }