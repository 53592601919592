<template>
  <span v-if="workingMetadata" :class="customClass">
    {{ workingMetadata.name }} by {{ workingMetadata.artist }}
  </span>
</template>
<script>

import {mapState} from 'vuex';

export default {
  components: {},
  props: ['metadata', 'editionNumber', 'customClass'],
  data() {
    return {
      metadataFromKoCache: null
    }
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    workingMetadata() {
      if (this.metadata) {
        return this.metadata;
      }
      return this.metadataFromKoCache;
    }
  },
  async mounted() {
    if (!this.metadata) {
      await this.loadMetaData();
    }
  },
  methods: {
    async loadMetaData() {
      const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.editionNumber}`);
      if (metadata) {
        this.metadataFromKoCache = metadata;
      }
    }
  }
}
</script>
