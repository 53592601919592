var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _vm.resolution === "thumbnailSearch"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "lazy-container",
                rawName: "v-lazy-container",
                value: { selector: "img" },
                expression: "{ selector: 'img' }",
              },
            ],
          },
          [
            _c("img", {
              staticClass: "rounded-edge-image",
              class: { "card-image-cover": _vm.cover },
              attrs: {
                src: "/branding/bigloader-image.svg",
                "data-src": _vm.assetForResolution,
                "data-loading": "/branding/bigloader-image.svg",
                "data-error": "/branding/holding_image.jpg",
                id: `edition_image_${_vm.editionNumber}`,
                alt: `${_vm.workingMetadata && _vm.workingMetadata.name} by ${
                  _vm.workingMetadata && _vm.workingMetadata.artist
                }`,
              },
            }),
          ]
        )
      : _c("section", [
          _vm.isWebM || _vm.isMp4
            ? _c(
                "figure",
                [
                  _c(
                    "client-only",
                    [
                      _c("video-js-player", {
                        attrs: {
                          cover: _vm.cover,
                          "full-asset": _vm.fullAsset,
                          "card-asset": _vm.cardAsset,
                          metadata: _vm.workingMetadata,
                          "edition-number": _vm.editionNumber,
                          "video-mime-type": _vm.videoMimeType,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.isGlb
            ? _c(
                "figure",
                [
                  _c("interactive-player", {
                    class: { "card-image-cover": _vm.cover },
                    attrs: {
                      id: `edition_image_${_vm.editionNumber}`,
                      cover: _vm.cover,
                      "full-asset": _vm.fullAsset,
                      "card-asset": _vm.cardAsset,
                      metadata: _vm.workingMetadata,
                      "edition-number": _vm.editionNumber,
                    },
                  }),
                ],
                1
              )
            : _vm.is360Viewer
            ? _c("figure", [
                _c(
                  "div",
                  { staticClass: "mb-1" },
                  [
                    _c("image-sphere-viewer", {
                      class: {
                        "card-image-cover": _vm.cover,
                        "hand-pointer": _vm.cover,
                      },
                      attrs: {
                        id: `edition_image_${_vm.editionNumber}`,
                        cover: _vm.cover,
                        "full-asset": _vm.fullAsset,
                        "card-asset": _vm.cardAsset,
                        metadata: _vm.workingMetadata,
                        "edition-number": _vm.editionNumber,
                        "is-cc": _vm.isCc,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c("figure", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "lazy-container",
                        rawName: "v-lazy-container",
                        value: { selector: "img" },
                        expression: "{ selector: 'img' }",
                      },
                    ],
                  },
                  [
                    _c("img", {
                      class: { "card-image-cover": _vm.cover },
                      attrs: {
                        src: "/branding/bigloader-image.svg",
                        "data-src": _vm.assetForResolution,
                        "data-loading": "/branding/bigloader-image.svg",
                        "data-error": "/branding/holding_image.jpg",
                        id: `edition_image_${_vm.editionNumber}`,
                        alt: `${
                          _vm.workingMetadata && _vm.workingMetadata.name
                        } by ${
                          _vm.workingMetadata && _vm.workingMetadata.artist
                        }`,
                      },
                    }),
                  ]
                ),
              ]),
          _vm._v(" "),
          _vm.enableMagnify && _vm.supportsFullscreen
            ? _c(
                "span",
                {
                  staticClass: "is-clickable",
                  on: { click: _vm.toggleFullscreen },
                },
                [_c("b-icon", { attrs: { icon: "magnify" } })],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }