var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nuxt-link",
    {
      staticClass: "mt-1 is-flex m-0",
      attrs: {
        to: { name: "contract-contract", params: { contract: _vm.address } },
      },
      on: {
        click: function ($event) {
          return _vm.fireShowcaseEvent("contract")
        },
      },
    },
    [
      _vm.contractDetails
        ? _c(
            "div",
            {
              staticClass:
                "content box-fat columns is-mobile has-background-dark is-flex is-justify-content-flex-start is-align-items-center showcase-width is-family-tertiary",
            },
            [
              _c(
                "figure",
                { staticClass: "m-0 ml-3 is-flex is-justify-content-center" },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: {
                          src: _vm.contractThumbnail,
                          error: _vm.createAccountIcon(),
                        },
                        expression:
                          "{\n        src: contractThumbnail,\n        error: createAccountIcon()\n      }",
                      },
                    ],
                    staticClass: "img-cc",
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "column content is-three-quarters is-flex is-flex-direction-column",
                },
                [
                  _c("span", { staticClass: "is-size-7 has-text-white" }, [
                    _vm._v("CREATOR CONTRACT"),
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "content" }, [
                    _c("div", { staticClass: "level is-mobile mt-0" }, [
                      _c(
                        "div",
                        {
                          staticClass: "level-left content my-auto noOverflow",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "is-size-6 has-text-weight-normal has-text-white",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("truncate")(
                                    _vm.contractDetails.name,
                                    30
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }