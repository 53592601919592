var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "artistSkeleton" }, [
    _c(
      "div",
      { staticClass: "artistSkeleton__profile" },
      [
        _c("b-skeleton", {
          attrs: { animated: true, circle: "", width: "64px", height: "64px" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "artistSkeleton__content" },
      [
        _c("b-skeleton", {
          attrs: { animated: true, height: "32px", width: "160px" },
        }),
        _vm._v(" "),
        _c("b-skeleton", {
          attrs: { animated: true, height: "24px", width: "80px" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }