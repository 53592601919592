var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("figure", { class: _vm.customClass }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "lazy-container",
              rawName: "v-lazy-container",
              value: { selector: "img" },
              expression: "{ selector: 'img' }",
            },
          ],
        },
        [
          _c("img", {
            attrs: {
              "data-src": _vm.thumbnailAsset,
              "data-loading": "/branding/bigloader-image.svg",
              "data-error": "cardAsset",
              id: `edition_image_${_vm.editionNumber}`,
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }