var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", [
    _c(
      "section",
      { staticClass: "is-hidden-desktop fixed-top-mobile-menu-bar" },
      [
        _c("div", { staticClass: "nav-mobile" }),
        _vm._v(" "),
        _c(
          "b-sidebar",
          {
            attrs: {
              type: "is-white",
              fullheight: true,
              fullwidth: true,
              overlay: true,
              right: true,
            },
            model: {
              value: _vm.state.open,
              callback: function ($$v) {
                _vm.$set(_vm.state, "open", $$v)
              },
              expression: "state.open",
            },
          },
          [
            _c(
              "b-navbar-item",
              {
                staticClass: "nav-ko-logo",
                attrs: { tag: "router-link", to: { path: "/" } },
                nativeOn: {
                  click: function ($event) {
                    return _vm.closeMenu.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "has-text-dark is-size-3 fixed-top-mobile-logo nav-logo",
                  },
                  [_vm._v("\n          KnownOrigin\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "has-text-primary is-size-3 fixed-top-mobile-logo-dot",
                  },
                  [_vm._v(".")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "header-close-icon",
                on: { click: _vm.closeMenu },
              },
              [_vm._v("\n        X\n      ")]
            ),
            _vm._v(" "),
            _c(
              "b-menu",
              {
                staticClass: "is-size-5 is-family-tertiary",
                staticStyle: { "padding-top": "3em" },
              },
              [
                _c(
                  "b-menu-list",
                  { attrs: { label: "" } },
                  [
                    _vm.state.selected === "all"
                      ? _c(
                          "div",
                          [
                            _c("b-menu-item", {
                              attrs: {
                                label: "Marketplace",
                                tag: "router-link",
                                to: { name: "marketplace" },
                                "data-testid": "marketplaceNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Apply",
                                tag: "router-link",
                                to: { name: "apply" },
                                "data-testid": "applyNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Activity",
                                tag: "router-link",
                                to: { name: "activity" },
                                "data-testid": "activityNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Help",
                                "data-testid": "helpNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.launchMessenger.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Creator Contracts",
                                tag: "router-link",
                                to: { name: "creator-contracts" },
                                "data-testid": "creatorContractsNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Trending",
                                tag: "router-link",
                                to: { name: "trending" },
                                "data-testid": "trendingNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Hall of fame",
                                tag: "router-link",
                                to: { name: "halloffame" },
                                "data-testid": "hallOfFameNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Collections",
                                tag: "router-link",
                                to: { name: "collections" },
                                "data-testid": "collectionsNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Journal",
                                tag: "router-link",
                                to: { name: "journal" },
                                "data-testid": "journalNavMobile",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("b-menu-item", {
                              attrs: {
                                label: "Help Centre",
                                "data-testid": "helpCentreNavMobile",
                                href: "https://docs.knownorigin.io/en/",
                                target: "_blank",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeMenu.apply(null, arguments)
                                },
                              },
                            }),
                            _vm._v(" "),
                            !_vm.isWeb3Connected
                              ? _c(
                                  "b-button",
                                  {
                                    staticStyle: { margin: "10px" },
                                    attrs: { type: "is-primary", outlined: "" },
                                    on: { click: _vm.loginToWeb3 },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.closeMenu.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Connect wallet\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isWeb3Connected
                      ? _c("b-menu-item", {
                          staticClass: "is-family-tertiary",
                          class: {
                            "pb-2 bottom-border":
                              _vm.state.selected === "profile",
                          },
                          attrs: { "data-testid": "profileNavMobile" },
                          on: { click: _vm.toggleProfileMenuView },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n              Profile\n              "
                                    ),
                                    _c("b-icon", {
                                      class: {
                                        "is-pulled-right":
                                          _vm.state.selected === "all",
                                        "is-pulled-left":
                                          _vm.state.selected === "profile",
                                      },
                                      attrs: {
                                        icon:
                                          _vm.state.selected === "all"
                                            ? "menu-right"
                                            : "menu-left",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            715381017
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.state.selected === "profile",
                            expression: "state.selected === 'profile'",
                          },
                        ],
                        staticClass: "mt-2",
                      },
                      [
                        _c("b-menu-item", {
                          attrs: {
                            tag: "router-link",
                            label: "View Profile",
                            to: {
                              name: "profile-id",
                              params: { id: _vm.account },
                            },
                            "data-testid": "viewProfileNavMobile",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeMenu.apply(null, arguments)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("b-menu-item", {
                          attrs: {
                            label: "Logout",
                            "data-testid": "logoutNavMobile",
                          },
                          on: { click: _vm.logoutWeb3 },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeMenu.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-mobile mt-5" }, [
                  _c("div", { staticClass: "column" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hand-pointer",
                        attrs: {
                          href: "https://discord.gg/knownorigin",
                          target: "_blank",
                          "data-testid": "discordNavMobile",
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: _vm.faDiscord },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hand-pointer",
                        attrs: {
                          href: "https://twitter.com/knownorigin_io",
                          target: "_blank",
                          "data-testid": "twitterNavMobile",
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "twitter" } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hand-pointer",
                        attrs: {
                          href: "https://www.instagram.com/knownorigin.io/",
                          target: "_blank",
                          "data-testid": "instaNavMobile",
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "instagram" } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hand-pointer",
                        attrs: {
                          href: "https://www.reddit.com/r/knownorigin/",
                          target: "_blank",
                          "data-testid": "twitterNavMobile",
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "reddit" } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hand-pointer",
                        attrs: {
                          href: "https://www.youtube.com/channel/UCZBkUkm5SSXQqzxBPezd5gw",
                          target: "_blank",
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "youtube" } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column" }, [
                    _c("hr", { staticClass: "mt-0 mb-0 pt-0 pb-0" }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column is-small is-muted" }, [
                    _c("p", { staticClass: "pl-3 is-size-6" }, [
                      _vm._v(
                        "\n              © KnownOrigin 2022\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "pl-3 pt-4 is-size-6" }, [
                      _vm._v(
                        "\n              Discover rare digital art and collect NFTs\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "pl-3 pt-4 is-size-7 is-family-tertiary" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.knownorigin.io",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Documentation\n              "
                            ),
                          ]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.knownorigin.io/en/articles/7128351-terms-of-service",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Terms of Service\n              "
                            ),
                          ]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.knownorigin.io/en/articles/7128342-privacy-policy",
                              target: "_blank",
                            },
                          },
                          [_vm._v("\n                Privacy\n              ")]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.knownorigin.io/en/articles/6118953-code-of-conduct",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Code of Conduct\n              "
                            ),
                          ]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://discord.gg/knownorigin",
                              target: "_blank",
                            },
                          },
                          [_vm._v("\n                Support\n              ")]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:partnerships@knownorigin.io?subject=Partnership opportunity",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Partnership enquiry\n              "
                            ),
                          ]
                        ),
                        _vm._v("\n              •\n              "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://jobs.ebayinc.com/us/en/search-results?keywords=knownorigin",
                              target: "_blank",
                            },
                          },
                          [_vm._v("\n                Careers\n              ")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showHeader
          ? _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "header-ko-logo is-flex is-align-items-center",
                    attrs: { to: { path: "/" } },
                  },
                  [
                    _c("img", {
                      staticClass: "mobileNav__koLogo mt-2",
                      attrs: { src: "/branding/Black-blue-ko-logo.svg" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("omni-search", {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.awayTablet,
                          expression: "awayTablet",
                        },
                      ],
                      key: _vm.componentKey,
                      staticClass:
                        "fixed-top-tablet-search-bar column is-hidden-mobile",
                      attrs: { "is-mobile": true },
                      on: {
                        "show-search": _vm.closeSearch,
                        "update-search-string": _vm.updateSearch,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fixed-top-mobile-apply-button apply-button is-align-self-center is-hidden-tablet p-2 is-family-tertiary",
                        attrs: { type: "button", to: { path: "apply" } },
                      },
                      [_vm._v("\n          Apply\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "fixed-top-mobile-search-button is-align-self-center is-hidden-tablet",
                        on: { click: _vm.openSearch },
                      },
                      [_c("b-icon", { attrs: { icon: "magnify" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "fixed-top-mobile-menu-button header-style",
                        on: { click: _vm.openMenu },
                      },
                      [
                        _vm.state.open
                          ? _c("b-icon", {
                              staticClass: "is-vcentered",
                              attrs: { icon: "close" },
                            })
                          : _c("b-icon", {
                              staticClass: "is-vcentered",
                              attrs: { icon: "menu" },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("omni-search", {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.away,
                      expression: "away",
                    },
                  ],
                  staticClass:
                    "fixed-top-mobile-search-bar ml-1 mr-2 column is-full",
                  attrs: { "is-mobile": true },
                  on: { "show-search": _vm.closeSearch },
                }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }