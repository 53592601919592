<template>
  <modal-template :edition="offer.edition" :transaction-hash="transactionHash" v-if="!loading">

    <template slot="content-title">
      <span v-if="isApproved">
        Accept offer
      </span>
      <span v-else>
        Enable marketplace
      </span>
    </template>

    <template slot="content-balance" v-if="!isApproved">
      &nbsp;
    </template>

    <template slot="content-sub-title" v-if="isApproved">
      <artwork-name-and-artist :metadata="offer.edition.metadata" :edition-number="offer.edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <section class="has-padding-top-7 has-padding-bottom-7" v-if="isApproved">

        <highest-offer-label :wei-value="offer.weiValue" :bidder="offer.bidder.address">
        </highest-offer-label>

        <b-message class="is-size-7 mt-2" type="is-info" :closable="false" :duration="0">
          You will receive approximately
          <eth-with-fiat-price :price-in-eth="finalAmount" :override-dp="3"></eth-with-fiat-price>
          after royalties and platform fees
          <a href="https://knownorigin.io/journal/platformupdate/making-a-secondary-marketplace" target="_blank">
            Learn more
          </a>
        </b-message>
      </section>
      <section v-else>
        <p class="title">
          You will need to approve the new
          <a :href="marketplaceAddress" target="_blank">
            KnownOrigin marketplace smart contract
          </a>
        </p>
        <section>
          You only need to do this once. The smart contract allows you to resell your artworks, handle payments &amp;
          royalties and to move tokens on your behalf.
        </section>
      </section>
    </div>

    <template slot="action-feedback-label">
      <span v-if="isApproved">
        Offer accepted
      </span>
      <span v-else>
        Enable marketplace
      </span>
    </template>

    <div slot="action-button" class="card-footer-item">
      <b-button class="button is-primary"
                expanded
                :disabled="disableOfferButton"
                @click="acceptOffer"
                v-if="isApproved">
        Accept offer
      </b-button>
      <b-button v-else
                class="button is-primary"
                expanded
                :disabled="transactionHash"
                @click="approveMarketplace">
        Approve marketplace
      </b-button>
    </div>

  </modal-template>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import HighestOfferLabel from '../../offers/HighestOfferLabel';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    HighestOfferLabel,
    EthWithFiatPrice,
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['offer'],
  data() {
    return {
      loading: true,
      isApproved: false,
      artistRoyaltiesPercentage: 10,
      platformFeePercentage: 3,
      transactionHash: null,
      disableOfferButton: false
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async acceptOffer() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/acceptTokenOffer', {
          token: this.offer.token.id,
          offerAmount: this.offer.weiValue,
          version: this.offer.token.version
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    },
    async approveMarketplace() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/approveTokenMarketplace', {
          version: this.offer.token.version
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: {
    ...mapState('web3ActionsStore', [
      'marketplaceV2',
      'kodaV3SecondaryMarketplace'
    ]),
    ...mapState('web3Store', [
      'chainId',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'etherscanAddressLink'
    ]),
    artistFee() {
      return parseFloat((this.offer.ethValue / 100) * this.artistRoyaltiesPercentage).toFixed(4);
    },
    platformFees() {
      return parseFloat((this.offer.ethValue / 100) * this.platformFeePercentage).toFixed(4);
    },
    finalAmount() {
      const actual = this.offer.ethValue - (parseFloat(this.platformFees) + parseFloat(this.artistFee));
      return actual.toFixed(4);
    },
    marketplaceAddress() {
      const marketplace = parseInt(this.offer.token.version) === 2
        ? this.marketplaceV2.address
        : this.kodaV3SecondaryMarketplace.address;
      return this.etherscanAddressLink(marketplace);
    }
  },
  async beforeMount() {
    try {
      this.loading = true;

      const marketplace = parseInt(this.offer.token.version) === 2
        ? this.marketplaceV2.address
        : this.kodaV3SecondaryMarketplace.address;

      const {isApproved} = await this.$cacheApi.$get(`/network/${this.chainId}/approval/owner/${this.account}/operator/${marketplace}`);
      this.isApproved = isApproved;
    } catch (e) {
      console.log('Problem with approval API');
    } finally {
      this.loading = false;
    }
  }
};
</script>
