import gql from 'graphql-tag';

export const GET_USER_MINT_TOTAL = gql(`
  query phaseMintCount($account: String!, $phaseId: String!, $saleId: String!) {
    phaseMintCount: phaseMintCounts(where: {
    saleId: $saleId,
    phaseId: $phaseId,
    minter: $account
  } subgraphError: allow) {
    id
    count
    }
  }
`)

export const GET_PHASE_CAP_REMAINING_ALLOWANCE = gql(`
  query phaseCapCheck($phaseId: String!, $saleId: String!) {
    phaseCapCheck: phases(where: {
      saleId: $saleId,
      phaseId: $phaseId,
    } subgraphError: allow){
      id
      mintCap
      mintCount
    }
  }
`)

export const GET_CURRENT_AND_FUTURE_PHASES = gql(`
  query currentFuturePhases($endTimeGt: Int!) {
    currentFuturePhases: phases(where: {
    endTime_gt: $endTimeGt
  },
  first: 5
  orderBy: endTime,
  orderDirection: asc
  subgraphError: allow
  ){
      id
      editionId
      saleId
      endTime
      startTime
    }
  }
`)

export const EXPIRED_PHASES = gql(`
  query expiredPhases($endTimeGt: Int!) {
    expiredPhases: phases(where: {
    endTime_lt: $endTimeGt
  },
  first: 1
  orderBy: endTime,
  orderDirection: desc
  subgraphError: allow
  ){
      id
      editionId
      saleId
      endTime
      startTime
    }
  }
`)
