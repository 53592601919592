var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.hasAuthToken || !_vm.loadedProfile
      ? _c("div", { staticClass: "has-text-centered" }, [
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Loading your profile details..."),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-multiline mt-4" }, [
      _vm.hasAuthToken && _vm.loadedProfile
        ? _c(
            "section",
            { staticClass: "column is-three-fifths" },
            [
              _vm.loadedProfile.isArtist && _vm.loadedProfile.username
                ? _c(
                    "b-field",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        label: "Username *",
                        message: _vm.usernameInvalidMessage,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.loadedProfile.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loadedProfile, "username", $$v)
                          },
                          expression: "loadedProfile.username",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-field",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        label: "Username *",
                        type: _vm.usernameInvalid,
                        message: _vm.usernameInvalidMessage,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          minlength: "2",
                          maxlength: "50",
                          "has-counter": "",
                          "data-cy": "username_input",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "level has-background-light pt-1 pb-1 mb-6" },
                [
                  !_vm.profileImageThumb && _vm.form.imageUrl
                    ? _c("div", { staticClass: "level-item" }, [
                        _c("figure", { staticClass: "image is-128x128" }, [
                          _c("img", {
                            staticClass: "is-rounded",
                            attrs: { src: _vm.form.imageUrl },
                          }),
                        ]),
                      ])
                    : _vm.profileImageThumb
                    ? _c("div", { staticClass: "level-item" }, [
                        _c("figure", { staticClass: "image is-128x128" }, [
                          _c("img", {
                            staticClass: "is-rounded",
                            attrs: { src: _vm.profileImageThumb },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "is-muted",
                            on: { click: _vm.clearTempAvatar },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                type: "is-secondary",
                                icon: "delete-outline",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", { staticClass: "level-item" }, [
                        _c("figure", { staticClass: "image is-128x128" }, [
                          _c("img", {
                            staticClass: "is-rounded",
                            attrs: {
                              src: _vm.createAccountIcon(
                                _vm.loadedProfile.address
                              ),
                            },
                          }),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c(
                        "b-upload",
                        {
                          attrs: { accept: "image/*" },
                          on: { input: _vm.handleNewAvatar },
                          model: {
                            value: _vm.profileImage,
                            callback: function ($$v) {
                              _vm.profileImage = $$v
                            },
                            expression: "profileImage",
                          },
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "button is-secondary" },
                            [
                              _c("b-icon", { attrs: { icon: "upload" } }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Upload new profile image")]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formValidation.avatarError
                ? _c("section", [
                    _c("div", { staticClass: "has-text-danger" }, [
                      _vm._v(_vm._s(_vm.formValidation.avatarError)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-field",
                { staticClass: "mb-6", attrs: { label: "Bio" } },
                [
                  _c("b-input", {
                    attrs: {
                      "has-counter": "",
                      maxlength: "500",
                      rows: "4",
                      "data-cy": "bio_input",
                      type: "textarea",
                    },
                    on: { input: _vm.formIsValid },
                    model: {
                      value: _vm.form.bio,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bio", $$v)
                      },
                      expression: "form.bio",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-6" },
                [
                  _c(
                    "b-switch",
                    {
                      model: {
                        value: _vm.form.enableNotifications,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "enableNotifications", $$v)
                        },
                        expression: "form.enableNotifications",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.form.enableNotifications
                              ? "Notifications enabled"
                              : "Notifications disabled (not recommended)"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "b-field",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        label: "Website",
                        type: {
                          "is-danger": _vm.errors.website,
                          "is-success": _vm.form.website && !_vm.errors.website,
                        },
                        message: "For example, https://knownorigin.io",
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          type: "text",
                          icon: "web",
                          "has-counter": "",
                          "data-cy": "website_input",
                          minlength: "10",
                          maxlength: "250",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.website,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "website", $$v)
                          },
                          expression: "form.website",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        label: "Twitter handle",
                        type: {
                          "is-danger": _vm.errors.twitter,
                          "is-success": _vm.form.twitter && !_vm.errors.twitter,
                        },
                        message: "For example, KnownOrigin_io",
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          type: "text",
                          icon: "twitter",
                          "has-counter": "",
                          "data-cy": "twitter_input",
                          minlength: "1",
                          maxlength: "15",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.twitter,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "twitter", $$v)
                          },
                          expression: "form.twitter",
                        },
                      }),
                      _vm._v(" "),
                      _vm.isWeb3Connected &&
                      _vm.loadedProfile.twitter &&
                      !_vm.isTwitterVerified(
                        _vm.loadedProfile.address,
                        _vm.loadedProfile.twitter
                      )
                        ? _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { type: "is-grey", outlined: "" },
                              on: { click: _vm.verifyTwitter },
                            },
                            [_vm._v("\n            Verify Twitter\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        label: "Instagram name",
                        type: {
                          "is-danger": _vm.errors.instagram,
                          "is-success":
                            _vm.form.instagram && !_vm.errors.instagram,
                        },
                        message: "For example, KnownOrigin.io",
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          type: "text",
                          icon: "instagram",
                          "has-counter": "",
                          "data-cy": "insta_input",
                          minlength: "3",
                          maxlength: "30",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.instagram,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "instagram", $$v)
                          },
                          expression: "form.instagram",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                { staticClass: "mt-6", attrs: { label: "Location" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      icon: "map-marker",
                      "data-cy": "location_input",
                      min: "5",
                      max: "75",
                    },
                    on: { input: _vm.formIsValid },
                    model: {
                      value: _vm.form.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "location", $$v)
                      },
                      expression: "form.location",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "private-data mt-6":
                      _vm.$store.getters[
                        "approvedFields/canShowApprovedFields"
                      ],
                  },
                },
                [
                  _vm.$store.getters["approvedFields/canShowApprovedFields"]
                    ? _c("div", [
                        _c("h1", { staticClass: "mt-6" }, [
                          _vm._v("Personal Information"),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "We collect some personal information to help protect you and your collectors."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "We will NOT show any of this information on your public profile."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.getters["approvedFields/canShowApprovedFields"]
                    ? _c(
                        "section",
                        [
                          _c(
                            "b-field",
                            {
                              staticClass: "mt-6",
                              attrs: {
                                label: _vm.requiredFieldsList.includes("name")
                                  ? "Name *"
                                  : "Name",
                                message: _vm.invalidFullName,
                                type: {
                                  "is-danger": _vm.errors.fullName,
                                  "is-success":
                                    _vm.form.fullName &&
                                    _vm.form.fullName.length > 0 &&
                                    !_vm.errors.fullName,
                                },
                              },
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  placeholder: "Name",
                                  "has-counter": "",
                                  minlength: "2",
                                  maxlength: "100",
                                  type: "text",
                                  "data-cy": "fullname_input",
                                },
                                on: { input: _vm.formIsValid },
                                model: {
                                  value: _vm.form.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fullName", $$v)
                                  },
                                  expression: "form.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        label: _vm.requiredFieldsList.includes("country")
                          ? "Country of residence *"
                          : "Country of residence",
                        type: {
                          "is-danger": _vm.errors.country,
                          "is-success": _vm.form.country,
                        },
                        message: "",
                      },
                    },
                    [
                      _c("country-select", {
                        staticClass: "input",
                        class: {
                          "is-danger": _vm.errors.country,
                          "is-success": _vm.form.country && !_vm.errors.country,
                        },
                        attrs: {
                          country: _vm.form.country,
                          "top-country": "US",
                          "data-cy": "country_dropdown",
                          placeholder: "Select country",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "country", $$v)
                          },
                          expression: "form.country",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.getters["approvedFields/canShowApprovedFields"] &&
                  _vm.form.country
                    ? _c("div", { staticClass: "card mt-6" }, [
                        _c("div", { staticClass: "card-content" }, [
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c("AddressInput", {
                                attrs: {
                                  country: _vm.form.country,
                                  "address-street1": _vm.form.streetAddress1,
                                  "address-street2": _vm.form.streetAddress2,
                                  "address-city": _vm.form.city,
                                  "address-state": _vm.form.state,
                                  "address-postal": _vm.form.postal,
                                  "required-fields-list":
                                    _vm.requiredFieldsList,
                                },
                                on: {
                                  isAddressValid: _vm.updateAddressValidation,
                                  updatedAddress: _vm.updatedAddress,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.getters["approvedFields/canShowApprovedFields"]
                    ? _c("section", { staticClass: "mt-6" }, [
                        _c(
                          "div",
                          [
                            _c("DOBInput", {
                              attrs: {
                                dob: _vm.form.dob,
                                "required-fields-list": _vm.requiredFieldsList,
                              },
                              on: {
                                isDOBValid: _vm.updateDOBValidation,
                                updatedDOB: _vm.updateDOB,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        label: _vm.requiredFieldsList.includes("email")
                          ? "Email *"
                          : "",
                        type: {
                          "is-danger": _vm.errors.email,
                          "is-success":
                            _vm.form.email &&
                            _vm.form.email.length > 0 &&
                            !_vm.errors.email,
                        },
                        message: _vm.invalidEmail,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          placeholder: "Email",
                          "has-counter": "",
                          minlength: "8",
                          maxlength: "75",
                          type: "email",
                          "data-cy": "email_input",
                          icon: "email-outline",
                        },
                        on: { input: _vm.formIsValid },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "level has-margin-top-1" }, [
                _c("div", { staticClass: "level-left" }, [
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "is-primary",
                            loading: _vm.saving,
                            disabled: !_vm.canSave,
                            "data-cy": "save_btn",
                          },
                          on: { click: _vm.saveProfile },
                        },
                        [_vm._v("\n              Save profile\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-right" }, [
                  _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            outlined: "",
                            disabled: !_vm.canSave,
                            "data-cy": "cancel_btn",
                          },
                          on: { click: _vm.cancelEdit },
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-checkbox",
                        {
                          staticClass: "is-muted",
                          model: {
                            value: _vm.selectedDeletedProfile,
                            callback: function ($$v) {
                              _vm.selectedDeletedProfile = $$v
                            },
                            expression: "selectedDeletedProfile",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Need to delete your profile?\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectedDeletedProfile
                    ? _c(
                        "section",
                        { staticClass: "mb-4 content notification" },
                        [_vm._m(0)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedDeletedProfile
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { type: "is-danger", loading: _vm.saving },
                              on: { click: _vm.deleteProfile },
                            },
                            [_vm._v("\n            Delete profile\n          ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dl", [
      _c("li", [
        _vm._v("\n              This action "),
        _c("span", { staticClass: "has-text-weight-semibold" }, [
          _vm._v("cannot"),
        ]),
        _vm._v(" be undone\n            "),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n              All off-chain profile data will be removed\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }