var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      !_vm.hidden
        ? _c("vue-countdown", {
            attrs: {
              time: _vm.diff,
              transform: _vm.transform,
              "emit-events": true,
            },
            on: { progress: _vm.handleCountdownProgress },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "section",
                        [
                          _vm._t("countdown-title", function () {
                            return [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  "\n            Sale starts in\n          "
                                ),
                              ]),
                            ]
                          }),
                          _vm._v(" "),
                          _c(
                            "b-message",
                            {
                              staticClass: "mb-4",
                              class: {
                                "is-success": _vm.type === "success",
                                "is-danger": _vm.type === "danger",
                              },
                              attrs: { size: "is-small" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "level is-mobile is-family-tertiary",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.days))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Days"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.hours))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Hours"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.minutes))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Minutes"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.seconds))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Seconds"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }