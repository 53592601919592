var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "columns has-margin-top-1 is-multiline" },
    _vm._l(_vm.collectionItems, function (collection) {
      return _c(
        "aside",
        { key: collection.slug, staticClass: "column is-one-third" },
        [
          _c(
            "n-link",
            {
              attrs: {
                to: { name: "profile-id", params: { id: collection.slug } },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card is-shadowless has-margin-bottom-1" },
                [
                  _c("div", { staticClass: "card-image" }, [
                    collection.collectionMetadata &&
                    collection.collectionMetadata.coverEditionId
                      ? _c("figure", { staticClass: "image" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "lazy-container",
                                  rawName: "v-lazy-container",
                                  value: { selector: "img" },
                                  expression: "{ selector: 'img' }",
                                },
                              ],
                            },
                            [
                              _c("img", {
                                class: { "collection-image-cover": true },
                                attrs: {
                                  "data-src": `https://cdn.knownorigin.io/cdn/images/network/1/edition/${
                                    collection.collectionMetadata &&
                                    collection.collectionMetadata.coverEditionId
                                  }`,
                                  "data-loading":
                                    "/branding/bigloader-image.svg",
                                  "data-error":
                                    "/branding/tiles/Collections.jpg",
                                  id: `collection-${collection.slug}`,
                                },
                              }),
                            ]
                          ),
                        ])
                      : _c("figure", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "lazy-container",
                                  rawName: "v-lazy-container",
                                  value: { selector: "img" },
                                  expression: "{ selector: 'img' }",
                                },
                              ],
                            },
                            [
                              _c("img", {
                                class: { "collection-image-cover": true },
                                attrs: {
                                  "data-src": `${collection.coverImageUrl}`,
                                  "data-loading":
                                    "/branding/bigloader-image.svg",
                                  "data-error":
                                    "/branding/tiles/Collections.jpg",
                                  id: `collection-${collection.slug}`,
                                },
                              }),
                            ]
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content is-paddingless" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "is-family-tertiary has-text-weight-bold has-margin-top-6",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(collection.username) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-family-primary has-text-weight-normal",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(collection.bio) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }