<template>
  <div>
    <section class="content">
      <div v-if="editions && editions.length > 0">
        <div class="columns is-multiline">
          <div
            class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card"
            v-for="(edition, $index) in editions"
            :key="$index"
          >
            <edition-card
              :edition="edition"
              :cover="true"
              :address="profile.address"
              resolution="card"
              class="has-margin-bottom-4"
              @editions-refresh="refreshEditions">
            </edition-card>
          </div>
        </div>
      </div>
      <div
        v-else
        class="column is-fullwidth"
      >
        <empty-state
          :message="'No items for sale on primary'">
        </empty-state>
      </div>
    </section>
  </div>
</template>

<script>

import EditionCard from '../../EditionCard';
import EmptyState from '../../common/EmptyState'

export default {
  components: {
    EditionCard,
    EmptyState
  },
  props: ['profile', 'isLoggedInAccountViewingPage', 'editions'],
  data() {
    return {
      primarySecondarySwitch: 'Primary'
    }
  },
  methods: {
    refreshEditions() {
      this.$emit('editions-refresh')
    }
  }
}
</script>
