<template>
  <div>
    <div v-if="fileDimension">
      {{ fileDimension }} {{ fileSize }}
      <span v-if="exifData" @click="expanded = !expanded" class="is-clickable is-size-7">
        <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
      </span>
    </div>
    <div v-show="exifData && expanded" v-html="exifData" class="mb-2 mt-2">
    </div>
  </div>
</template>

<script>
import {editionUtils} from '@knownorigin/ko-token-uri-tools';
import _get from 'lodash/get';

export default {
  props: ['edition'],
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    fileDimension() {
      return editionUtils.fileDimension(this.edition);
    },
    fileSize() {
      return editionUtils.fileSize(this.edition);
    },
    exifData() {
      const Make = _get(this.edition, 'image_metadata.TIFF.Make');
      const Model = _get(this.edition, 'image_metadata.TIFF.Model');
      const LensModel = _get(this.edition, 'image_metadata.Exif.LensModel');
      let data = '';
      if (Make) {
        data += `<div>Make: ${Make}</div>`;
      }
      if (Model) {
        data += `<div>Model: ${Model}</div>`;
      }
      if (LensModel) {
        data += `<div>Lens: ${LensModel}</div>`;
      }
      return data;
    }
  }
};
</script>
