<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Embedded items
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id"></artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <template slot="secondary-content">
      <div v-if="workingERC721Token">
        <embedded-nft-tokens :edition="edition"
                             :child-token="workingERC721Token"
                             :next-token="token.id">
          <template slot="right-side-element">
            <b-button @click="unpackERC721Token(workingERC721Token)">Redeem</b-button>
          </template>
        </embedded-nft-tokens>
      </div>

      <div v-if="workingERC20Tokens.length > 0">
        <p class="title">Embedded Tokens</p>
        <ul>
          <li v-for="item in workingERC20Tokens" :key="item.id">
            <erc20-token-information :token="item">
              <template slot="middle">
              </template>
              <template slot="right">
                <b-button @click="unpackERC20Token(item)">
                  {{ item.clicked ? 'View Item' : 'Redeem' }}
                </b-button>
              </template>
            </erc20-token-information>
          </li>
        </ul>
      </div>

      <section class="mt-4 is-size-7">
        <p>
          Read more about how this work
          <a href="https://docs.knownorigin.io/en/articles/6554600-composing-other-tokens" target="_blank" class="is-linkable">
            here
          </a>
        </p>
      </section>
    </template>

    <template slot="action-feedback-label">
      Redeeming embedded item
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                @click="unpackAllItems">
        Redeem All
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import Erc20TokenInformation from '../../common/Erc20TokenInformation';
import EmbeddedNftTokens from '../../embedded-tokens/EmbeddedNftTokens';

export default {
  components: {
    EmbeddedNftTokens,
    ModalTemplate,
    ArtworkNameAndArtist,
    Erc20TokenInformation
  },
  props: [
    'token',
    'edition',
    'enhancedTokensERC20',
    'enhancedTokensERC721'
  ],
  computed: {
    ...mapGetters('web3Store', [
      'etherscanTxLink'
    ]),
    ...mapState('web3Store', [
      'account'
    ]),
    ...mapState('web3ActionsStore', [
      'kodaV3'
    ])
  },
  data() {
    return {
      transactionHash: null,
      populatedToken: null,
      workingERC20Tokens: [],
      workingERC721Token: null
    };
  },
  mounted() {
    if (this.enhancedTokensERC721) {
      this.workingERC721Token = {
        ...this.enhancedTokensERC721
      };
    }
    this.workingERC20Tokens = [
      ...this.enhancedTokensERC20
    ];
  },
  destroyed() {
    this.workingERC20Tokens = [];
    this.workingERC721Token = null;
  },
  methods: {
    async unpackERC20Token(item, closeModal = true) {
      if (item.clicked) {
        window.open(this.etherscanTxLink(item.transactionHash), '_blank');
        return;
      }

      try {
        item.clicked = true;
        const tx = await this.kodaV3.transferERC20(this.token.id, this.account, item.address, item.value);
        this.transactionHash = tx.hash;
        item.transactionHash = tx.hash;
        await tx.wait(1);
        if (closeModal) {
          this.$emit('close');
        }
      } catch (err) {
        item.clicked = false;
        console.error(err);
      }
      return item;
    },
    async unpackERC721Token(item, closeModal = true) {
      if (item.clicked) {
        window.open(this.etherscanTxLink(item.transactionHash), '_blank');
        return;
      }

      try {
        item.clicked = true;
        const tx = await this.kodaV3.transferChild(item.tokenID, this.account);
        this.transactionHash = tx.hash;
        item.transactionHash = tx.hash;
        await tx.wait(1);
        if (closeModal) {
          this.$emit('close');
        }
      } catch (err) {
        item.clicked = false;
        console.error(err);
      }
      return item;
    },
    async unpackAllItems() {
      this.workingERC20Tokens = await Promise.all(this.workingERC20Tokens.map((item) => {
        if (item.clicked) { return item; }
        return this.unpackERC20Token(item, false);
      }));

      if (!this.workingERC721Token.clicked) {
        this.workingERC721Token = await this.unpackERC721Token(this.workingERC721Token, false);
      }
    }
  }
};
</script>
