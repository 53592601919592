var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.topArtists
    ? _c(
        "div",
        { staticClass: "topArtists has-margin-top-7 mb-6 pb-6" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "b-table",
            {
              staticClass: "topArtists__table is-family-tertiary",
              attrs: {
                data: _vm.topArtists,
                "default-sort": "totalValueInEth",
                "sticky-header": "",
                height: "auto",
                striped: "",
                paginated: "",
                "pagination-simple": "",
                "per-page": 25,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "topArtists__label topArtists__label--mobile title mb-3 is-hidden-tablet has-text-weight-bold is-family-tertiary",
                },
                [_vm._v("\n      Top 250 creators by total ETH sales\n    ")]
              ),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "topArtist__artist",
                attrs: { field: "address", label: "Creator" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("user-profile-lookup", {
                            attrs: {
                              "no-subtitle": true,
                              "is-table": true,
                              "artist-account": props.row.address,
                              "metadata-artist": props.row.address,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2594212110
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile is-family-tertiary",
                attrs: {
                  field: "salesCount",
                  label: "Sales count",
                  width: "120",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("salesCount"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                label:
                                  "Total no. of sales (primary & secondary)",
                                position: "is-bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "is-family-tertiary" }, [
                            _vm._v(_vm._s(props.row.salesCount)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  178798964
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "highestSaleValueInEth",
                  label: "Highest sale",
                  width: "140",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("highestSaleValueInEth"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                label:
                                  "Biggest ever single sale (primary & secondary)",
                                position: "is-bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": props.row.highestSaleValueInEth,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2734948670
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalValueInEth",
                  label: "Total sales",
                  width: "140",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalValueInEth"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                label:
                                  "Total trade volume (primary & secondary)",
                                position: "is-left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": props.row.totalValueInEth,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2966299007
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalPrimarySales",
                  label: "Primary sales",
                  width: "140",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalPrimarySales"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                position: "is-left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "is-family-tertiary" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.row.totalPrimarySales) +
                                "\n        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1744836055
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalPrimarySalesInEth",
                  label: "Primary ETH",
                  width: "140",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalPrimarySalesInEth"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                position: "is-left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": props.row.totalPrimarySalesInEth,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3725742781
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalSecondarySales",
                  label: "Secondary sales",
                  width: "140",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalSecondarySales"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                position: "is-left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "is-family-tertiary" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.row.totalSecondarySales) +
                                "\n        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  840394273
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalSecondarySalesInEth",
                  label: "Secondary ETH",
                  width: "120",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalSecondarySalesInEth"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              staticStyle: { "pointer-events": "none" },
                              attrs: {
                                type: "is-dark",
                                "append-to-body": "",
                                position: "is-left",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(column.label) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth":
                                props.row.totalSecondarySalesInEth,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  198241355
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticClass:
          "topArtists__label is-hidden-mobile has-text-weight-bold is-family-tertiary",
      },
      [_c("div", {}, [_vm._v("Top 50 creators by total ETH sales")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }