var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition && _vm.edition.totalSupply > 0
    ? _c(
        "div",
        { staticClass: "mt-6 p-0", attrs: { id: "salesTable" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.listings && _vm.listings.length > 0
            ? _c(
                "b-table",
                {
                  attrs: {
                    data: _vm.listings,
                    "sticky-header": "",
                    "default-sort": "priceInEth",
                    "default-sort-direction": "desc",
                    striped: "",
                    "mobile-cards": false,
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: {
                      field: "id",
                      label: "ID",
                      centered: "",
                      sortable: "",
                      visible: _vm.$device.isDesktopOrTablet,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              props.row.type === "Token"
                                ? _c(
                                    "n-link",
                                    { attrs: { to: _vm.rerouteToken(props) } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "is-clickable" },
                                        [
                                          _vm._v(
                                            "#" +
                                              _vm._s(
                                                _vm.getEditionId(props.row)
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("span", { staticClass: "is-clickable" }, [
                                    _vm._v(" - "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3052752631
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: { field: "seller", label: "Owned By" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "n-link",
                                {
                                  staticClass: "is-flex is-align-items-center",
                                  attrs: {
                                    to: {
                                      name: "profile-id",
                                      params: {
                                        id: props.row.seller.toLowerCase(),
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("artist-name-and-image-lookup", {
                                    staticClass:
                                      "is-small small is-inline-block",
                                    attrs: {
                                      "artist-account":
                                        props.row.seller.toLowerCase(),
                                      size: "is-small",
                                      "custom-class": "m-0 p-0",
                                    },
                                  }),
                                  _vm._v(" "),
                                  props.row.seller.toLowerCase() ===
                                  _vm.edition.artistAccount.toLowerCase()
                                    ? _c(
                                        "b-tag",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { type: "is-dark" },
                                        },
                                        [_vm._v("CREATOR")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3805544805
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "lastSalePriceInEth",
                      label: "Last Price",
                      sortable: "",
                      numeric: "",
                      centered: "",
                      "custom-sort": _vm.sortableString("lastSalePriceInEth"),
                      visible: _vm.$device.isDesktopOrTablet,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c("eth-with-fiat-price", {
                                attrs: {
                                  "price-in-eth": props.row.lastSalePriceInEth,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1911618393
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "priceInEth",
                      label: "List Price",
                      sortable: "",
                      numeric: "",
                      centered: "",
                      "custom-sort": _vm.sortableString("priceInEth"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              props.row.priceInEth
                                ? _c("eth-with-fiat-price", {
                                    attrs: {
                                      "price-in-eth": props.row.priceInEth,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2163722656
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: { field: "actionButton", centered: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm.availableToBuy(props.row.saleType)
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "is-small is-fullwidth",
                                      attrs: {
                                        type: "is-primary",
                                        "data-testid": "buyWithETHBtn",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isCreatorAndTokensAvailable(
                                            props.row
                                          )
                                            ? _vm.routeToEdition()
                                            : _vm.launchBuyNowModal(props.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(
                                            _vm.isCreatorAndTokensAvailable(
                                              props.row
                                            )
                                              ? "Buy edition"
                                              : "Buy with ETH"
                                          ) +
                                          "\n      "
                                      ),
                                    ]
                                  )
                                : _vm.canPlaceBid(props.row)
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "is-small is-fullwidth",
                                      attrs: {
                                        type: "is-secondary",
                                        "data-testid": "placeABid",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.launchMakeOfferModel(
                                            props.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("\n        Place a bid\n      ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2971524284
                    ),
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "columns is-multiline" }, [
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column has-text-right is-one-third" },
                        [
                          _c("b-skeleton", {
                            attrs: {
                              circle: "",
                              width: "32px",
                              height: "32px",
                              animated: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("b-skeleton", {
                            attrs: { animated: true, width: "80%" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column has-text-right is-one-third" },
                        [
                          _c("b-skeleton", {
                            attrs: {
                              circle: "",
                              width: "32px",
                              height: "32px",
                              animated: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("b-skeleton", {
                            attrs: { animated: true, width: "80%" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column has-text-right is-one-third" },
                        [
                          _c("b-skeleton", {
                            attrs: {
                              circle: "",
                              width: "32px",
                              height: "32px",
                              animated: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c("b-skeleton", {
                            attrs: { animated: true, width: "80%" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "50%" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-one-fifth" },
                    [
                      _c("b-skeleton", {
                        attrs: { animated: true, width: "100%" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "content" }, [
      _c("h2", [_vm._v("Listings")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }