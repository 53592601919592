export const state = () => ({});

export const mutations = {};

export const actions = {

  async getHomepage() {
    console.log('loading homepage banners from CMS');
    try {
      return await this.$cacheApi.$get('/cms/homepage');
    } catch (e) {
      console.error(e);
      return Promise.resolve([]);
    }
  },

  async getHomepageV2({rootState}) {
    const status = parseInt(process.env.DEFAULT_CHAIN_ID) === 1 ? 'publish' : 'draft';
    console.log(`loading homepage CMS components that have ${status} status`);
    try {
      return await this.$cacheApi.$get(`/cms/homepagev2?status=${status}`);
    } catch (e) {
      console.error(e);
      return Promise.resolve([]);
    }
  },

  async getSpotlights({rootState, state, commit}) {
    const status = parseInt(process.env.DEFAULT_CHAIN_ID) === 1 ? 'publish' : 'draft'
    console.log(`loading spotlights from CMS that have ${status} status`);
    try {
      return await this.$cacheApi.$get(`/cms/${status}/spotlights`);
    } catch (e) {
      console.error(e);
      return Promise.resolve([]);
    }
  },

  async getSpotlight({rootState, state, commit}, id) {
    console.log('loading spotlight from CMS, ', id);
    try {
      const res = await this.$cacheApi.$get(`/cms/spotlights/${id}`);
      return res && res.spotlight;
    } catch (e) {
      console.error(e);
      return Promise.resolve(null);
    }
  },

  async getBlogs({rootState, state, commit}) {
    const status = parseInt(process.env.DEFAULT_CHAIN_ID) === 1 ? 'publish' : 'draft'
    console.log(`loading blogs from CMS that have ${status} status`);
    try {
      return await this.$cacheApi.$get(`/cms/${status}/blogs`);
    } catch (e) {
      console.error(e);
      return Promise.resolve([]);
    }
  },

  async getBlog({rootState, state, commit}, id) {
    console.log('loading blog from CMS, ', id);
    try {
      const res = await this.$cacheApi.$get(`/cms/blogs/${id}`);
      return res && res.blog;
    } catch (e) {
      console.error(e);
      return Promise.resolve(null);
    }
  },

  async getEditionCurationData({rootState, state, commit}, id) {
    console.log('loading curation data from CMS, ', id);
    try {
      return await this.$cacheApi.$get(`cms/curation/edition/${id}`);
    } catch (e) {
      console.error(e);
      return Promise.resolve(null);
    }
  }
};
