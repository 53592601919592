var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _vm.workingMetadata && _vm.token
      ? _c("div", { staticClass: "card", class: { ccToken: _vm.isCc } }, [
          _c(
            "div",
            { staticClass: "card-image" },
            [
              _vm.isVideoImageType
                ? _c(
                    "span",
                    [
                      _c("b-icon", {
                        staticStyle: {
                          position: "absolute",
                          top: "1rem",
                          right: "1rem",
                          "z-index": "10",
                        },
                        attrs: { icon: "video", type: "is-white" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.is3DImageType
                ? _c(
                    "span",
                    [
                      _c("b-icon", {
                        staticStyle: {
                          position: "absolute",
                          top: "1rem",
                          right: "1rem",
                          "z-index": "10",
                        },
                        attrs: { icon: "video-3d-variant", type: "is-white" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.is360Image
                ? _c(
                    "span",
                    [
                      _c("b-icon", {
                        staticStyle: {
                          position: "absolute",
                          top: "1rem",
                          right: "1rem",
                          "z-index": "10",
                        },
                        attrs: { icon: "panorama", type: "is-white" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "asset-card-wrapped-link hand-pointer" },
                [
                  _c(
                    "n-link",
                    {
                      attrs: {
                        to: _vm.handleRouting(
                          _vm.token,
                          _vm.canOverrideToEdition
                        ),
                      },
                    },
                    [
                      _c("image-asset", {
                        attrs: {
                          "edition-number": _vm.token.edition.id,
                          metadata: _vm.workingMetadata,
                          resolution: _vm.resolution,
                          cover: _vm.cover,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.token
                ? _c("unlockable-edition-icon", {
                    attrs: { "edition-number": _vm.token.editionNumber },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.token
                ? _c("enhanced-icon", { attrs: { "token-id": _vm.getTokenId } })
                : _vm._e(),
              _vm._v(" "),
              _c("creator-contract-icon", {
                attrs: { "is-creator-contract": _vm.isCc },
              }),
              _vm._v(" "),
              parseFloat(_vm.token.listPrice) > 0
                ? _c(
                    "b-tag",
                    {
                      staticClass: "is-uppercase is-family-primary",
                      staticStyle: {
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                        "z-index": "10",
                      },
                      attrs: { type: "is-light" },
                    },
                    [
                      _vm._v("\n        On Sale\n        "),
                      _c("eth-with-fiat-price", {
                        attrs: { "price-in-eth": _vm.token.listPrice },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-content is-paddingless" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "columns is-mobile is-multiline" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "column is-four-fifths has-padding-left-2 has-padding-top-4",
                    attrs: { "data-testid": "artworkName" },
                  },
                  [
                    _c(
                      "n-link",
                      {
                        staticClass: "asset-card-wrapped-link",
                        attrs: {
                          to: _vm.handleRouting(
                            _vm.token,
                            _vm.canOverrideToEdition
                          ),
                        },
                      },
                      [
                        _vm._t("asset-card-title-prefix"),
                        _vm._v(" "),
                        _vm._t("asset-card-content-top-left", function () {
                          return [
                            _vm.size === "is-large"
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "has-text-left is-family-tertiary has-text-weight-bold",
                                    class: {
                                      "has-text-dark": !_vm.isCc,
                                      "has-text-white": _vm.isCc,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("truncate")(
                                            _vm.workingMetadata.name,
                                            30
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "has-text-left is-family-tertiary has-text-weight-bold",
                                    class: {
                                      "has-text-dark": !_vm.isCc,
                                      "has-text-white": _vm.isCc,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("truncate")(
                                          _vm.workingMetadata.name,
                                          30
                                        )
                                      )
                                    ),
                                  ]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "column has-text-right is-one-fifth is-size-7 has-text-weight-bold has-padding-right-2 has-padding-top-4",
                    attrs: { "data-testid": "artworkName" },
                  },
                  [
                    _c(
                      "n-link",
                      {
                        staticClass: "asset-card-wrapped-link",
                        attrs: {
                          to: _vm.handleRouting(
                            _vm.token,
                            _vm.canOverrideToEdition
                          ),
                        },
                      },
                      [
                        _vm._t("asset-card-content-top-right", function () {
                          return [
                            _c("token-id-of-x", {
                              class: {
                                "has-text-dark": !_vm.isCc,
                                "has-text-white": _vm.isCc,
                              },
                              attrs: {
                                id: _vm.getTokenId,
                                "edition-number": _vm.token.editionNumber,
                                version: _vm.token.version,
                                "edition-total-available":
                                  _vm.token.edition.totalAvailable,
                              },
                            }),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "column is-four-fifths has-padding-left-2 has-padding-bottom-2",
                  },
                  [
                    _c(
                      "n-link",
                      {
                        class: {
                          "has-text-dark": !_vm.isCc,
                          "has-text-white": _vm.isCc,
                        },
                        attrs: {
                          to: {
                            name: "profile-id",
                            params: {
                              id: _vm.token.artistAccount.toLowerCase(),
                            },
                          },
                        },
                      },
                      [
                        _vm._t("asset-card-content-bottom-left", function () {
                          return [
                            _c("artist-name-and-image-lookup", {
                              attrs: {
                                "artist-account": _vm.token.artistAccount,
                                name: _vm.workingMetadata.artist,
                                collaborators:
                                  _vm.token.edition.collective &&
                                  _vm.token.edition.collective.recipients
                                    ? _vm.token.edition.collective.recipients
                                    : _vm.token.edition.collaborators,
                                size: _vm.size,
                                "is-cc": _vm.isCc,
                              },
                            }),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "column is-one-fifth has-text-right has-padding-right-2",
                  },
                  [
                    _vm._t("asset-card-content-bottom-right", function () {
                      return [
                        _c(
                          "b-dropdown",
                          {
                            staticClass:
                              "is-clickable has-text-left is-family-tertiary",
                            attrs: {
                              "aria-role": "list",
                              position: "is-top-left",
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                slot: "trigger",
                                icon: "dots-horizontal",
                              },
                              slot: "trigger",
                            }),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "aria-role": "listitem", custom: "" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "columns is-mobile" },
                                  [
                                    _c("div", { staticClass: "column" }, [
                                      _c("span", {}, [_vm._v("Token ID")]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "column has-text-right" },
                                      [
                                        _c("token-id", {
                                          attrs: { id: _vm.getTokenId },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "dropdown-divider" }),
                            _vm._v(" "),
                            _vm.canChangeBuyNowPrice && !_vm.isCc
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "list" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.listToken(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          !_vm.token.isListed
                                            ? "Sell as buy now"
                                            : "Change price"
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canChangeReservePrice && !_vm.isCc
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "list" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.listReserveAuction(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.isReserveAuctionSalesType(
                                            _vm.token.salesType
                                          )
                                            ? "Change reserve price"
                                            : "Start reserve auction"
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCancelSale && !_vm.isCc
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "delist token" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delistToken(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Cancel sale\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canSetMinimumBid && !_vm.isCc
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    ref: "setminimumbid",
                                    attrs: { "aria-role": "set minimum bid" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setMinimumBid(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Set minimum bid\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLoggedInAccountViewingPage &&
                            _vm.hasUnlockableContent(_vm.token.editionNumber) &&
                            !_vm.isCc
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    ref: "setminimumbid",
                                    attrs: { "aria-role": "set minimum bid" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.claimUnlockableContent(
                                          _vm.token
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  View unlockable\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canTransferToken
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "transfer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.transferToken(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Transfer\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLoggedInAccountViewingPage
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { "aria-role": "download" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadAsset(_vm.token)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Download\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "aria-role": "delist token" },
                                on: {
                                  click: function ($event) {
                                    return _vm.shareTokenModal(_vm.token)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Share\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "footer",
            { staticClass: "card-footer", class: { ccToken: _vm.isCc } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "column is-half has-padding-left-3 has-padding-top-0 is-flex is-align-items-center",
                  class: {
                    "countdown-started":
                      _vm.reserveAuctionCountdownStarted &&
                      !_vm.reserveAuctionCountdownComplete,
                  },
                  attrs: { "data-testid": "saleprice" },
                },
                [
                  _c(
                    "n-link",
                    {
                      staticClass: "asset-card-wrapped-link",
                      class: {
                        "has-text-dark": !_vm.isCc,
                        "has-text-white": _vm.isCc,
                      },
                      attrs: {
                        to: _vm.handleRouting(
                          _vm.token,
                          _vm.canOverrideToEdition
                        ),
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "has-text-weight-bold has-text-left is-family-tertiary",
                        },
                        [
                          _vm._t("asset-card-footer-left-title", function () {
                            return [
                              _vm.token.isListed &&
                              _vm.token.listing &&
                              _vm.reserveAuctionCountdownInProgress
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Current bid\n              "
                                    ),
                                  ])
                                : _vm.token.isListed &&
                                  _vm.token.listing &&
                                  _vm.isReserveAuctionSalesType(
                                    _vm.token.salesType
                                  )
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Reserve price\n              "
                                    ),
                                  ])
                                : _vm.token.isListed
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Buy now\n              "
                                    ),
                                  ])
                                : _vm.getTokenMinimumOffer(_vm.token) &&
                                  _vm.getTokenMinimumOffer(_vm.token)
                                    .eth_reserve_in_wei
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Place a bid\n               "
                                    ),
                                    _c(
                                      "span",
                                      { class: { "has-text-grey": !_vm.isCc } },
                                      [
                                        _vm._v(
                                          "\n                 (minimum)\n               "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm.token.lastSalePriceInEth &&
                                  parseFloat(_vm.token.lastSalePriceInEth) > 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Sold for\n              "
                                    ),
                                  ])
                                : _vm.token.lastSalePriceInEth &&
                                  parseFloat(_vm.token.lastSalePriceInEth) === 0
                                ? _c(
                                    "span",
                                    { class: { "has-text-grey": !_vm.isCc } },
                                    [
                                      _vm._v(
                                        "\n                \n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "has-text-weight-bold has-text-left" },
                        [
                          _vm._t("asset-card-footer-left", function () {
                            return [
                              (_vm.reserveAuctionCountdownInProgress ||
                                _vm.reserveAuctionCountdownComplete) &&
                              _vm.token.isListed &&
                              _vm.token.listing
                                ? _c(
                                    "span",
                                    [
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-wei":
                                            _vm.token.listing.reserveAuctionBid,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.isReserveAuctionSalesType(
                                    _vm.token.salesType
                                  ) &&
                                  _vm.token.isListed &&
                                  _vm.token.listing
                                ? _c(
                                    "span",
                                    [
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-wei":
                                            _vm.token.listing.reservePrice,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.token.isListed
                                ? _c(
                                    "span",
                                    [
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-eth": _vm.token.listPrice,
                                          "show-fiat": false,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.getTokenMinimumOffer(_vm.token) &&
                                  _vm.getTokenMinimumOffer(_vm.token)
                                    .eth_reserve_in_wei
                                ? _c(
                                    "span",
                                    [
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-wei":
                                            _vm.getTokenMinimumOffer(_vm.token)
                                              .eth_reserve_in_wei || 0,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.token.lastSalePriceInEth &&
                                  parseFloat(_vm.token.lastSalePriceInEth) > 0
                                ? _c(
                                    "span",
                                    [
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-eth":
                                            _vm.token.lastSalePriceInEth,
                                          "show-fiat": false,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.token.lastSalePriceInEth &&
                                  parseFloat(_vm.token.lastSalePriceInEth) === 0
                                ? _c("span", {}, [
                                    _vm._v(
                                      "\n               Gifted\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "column has-text-left is-half has-padding-right-3",
                  class: {
                    "countdown-started has-padding-bottom-4":
                      _vm.reserveAuctionCountdownStarted &&
                      !_vm.reserveAuctionCountdownComplete,
                  },
                },
                [
                  _c(
                    "n-link",
                    {
                      staticClass: "has-text-dark",
                      attrs: {
                        to: {
                          name: "profile-id",
                          params: { id: _vm.token.currentOwner.address },
                        },
                      },
                    },
                    [
                      _vm._t("asset-card-footer-right-title", function () {
                        return [
                          _c("div", [
                            _vm.reserveAuctionCountdownStarted &&
                            !_vm.reserveAuctionCountdownComplete
                              ? _c(
                                  "span",
                                  { staticClass: "has-text-weight-semibold" },
                                  [
                                    _vm._v(
                                      "\n                Auction ends in\n              "
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "is-size-7 mb-1 has-text-weight-bold is-family-tertiary",
                                    class: {
                                      "has-text-dark": !_vm.isCc,
                                      "has-text-white": _vm.isCc,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Owned by\n              "
                                    ),
                                  ]
                                ),
                          ]),
                        ]
                      }),
                      _vm._v(" "),
                      _vm._t("asset-card-footer-right", function () {
                        return [
                          _vm.reserveAuctionCountdownStarted &&
                          !_vm.reserveAuctionCountdownComplete
                            ? _c(
                                "span",
                                { staticClass: "has-text-weight-semibold" },
                                [
                                  _c("auction-count-down-simple", {
                                    staticClass: "has-text-weight-semibold",
                                    attrs: {
                                      "end-date":
                                        _vm.token.listing
                                          .reserveAuctionEndTimestamp,
                                      "white-text": false,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("owner-name-and-image-lookup", {
                                staticClass: "is-inline-block",
                                attrs: {
                                  account: _vm.token.currentOwner.address,
                                  "is-cc": _vm.isCc,
                                },
                              }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }