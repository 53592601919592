var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edition-image" },
    [
      _vm.edition
        ? _c("ApolloQuery", {
            attrs: {
              query: require("../../queries/editionQueries")
                .EDITION_BY_ID_WITH_TOKENS_QUERY,
              variables: { id: _vm.edition.id },
              "client-id": _vm.gqlClient,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ result: { loading, data } }) {
                    return [
                      !loading && data?.editionById.length > 0
                        ? _c(
                            "div",
                            { staticClass: "has-background-white pb-2" },
                            [
                              _c("image-for-asset-card", {
                                attrs: {
                                  edition: data.editionById[0],
                                  cover: "true",
                                  resolution: "card",
                                  "disable-icons": false,
                                  "is-cc": _vm.isCc,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "edition-image-artist px-3 is-size-7-tablet",
                                },
                                [
                                  _c("artist-name-and-image-lookup", {
                                    attrs: {
                                      "artist-account":
                                        data.editionById[0].artistAccount,
                                      name: data.editionById[0].metadata.artist,
                                      collaborators:
                                        data.editionById[0].collaborators,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("img", {
                                staticClass: "card-image-cover",
                                attrs: { src: "/branding/bigloader-image.svg" },
                              }),
                              _vm._v(" "),
                              _c("b-skeleton", {
                                attrs: {
                                  active: "",
                                  width: "140px",
                                  height: "12px",
                                },
                              }),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ],
              null,
              false,
              3575968230
            ),
          })
        : _c(
            "div",
            [
              _c("img", {
                staticClass: "card-image-cover",
                attrs: { src: "/branding/bigloader-image.svg" },
              }),
              _vm._v(" "),
              _c("b-skeleton", {
                attrs: { active: "", width: "140px", height: "12px" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }