var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content card m-auto column is-half p-5" },
    [
      _c("h4", [_vm._v("\n    Restricted marketplaces for this contract\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("hr", { staticClass: "m-0 mt-1" }),
          _vm._v(" "),
          _c("marketplace-tag", {
            attrs: {
              marketplace: "LooksRare",
              icon: require("../../assets/logos/looksrare.jpeg"),
              count: "1",
            },
          }),
          _vm._v(" "),
          _c("marketplace-tag", {
            attrs: {
              marketplace: "SudoSwap",
              icon: require("../../assets/logos/sudoswap.jpeg"),
              count: "2",
            },
          }),
          _vm._v(" "),
          _c("marketplace-tag", {
            attrs: {
              marketplace: "NFTX",
              icon: require("../../assets/logos/nftx.jpeg"),
              count: "3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("marketplace-blocker-message"),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column mt-2" },
          [
            _c(
              "b-button",
              {
                attrs: { expanded: "", outlined: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("\n        Close\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }