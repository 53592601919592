var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "animCta px-4 py-6 is-flex is-flex-direction-column is-align-items-center has-text-white has-text-centered",
    },
    [
      _c("h2", {
        staticClass:
          "animCta__header is-size-4 my-4 is-size-3-tablet is-family-secondary has-text-weight-black is-uppercase",
        domProps: { innerHTML: _vm._s(_vm.header) },
      }),
      _vm._v(" "),
      !_vm.external
        ? _c("NuxtLink", { staticClass: "mb-5", attrs: { to: _vm.link } }, [
            _c(
              "button",
              {
                staticClass:
                  "animCta__btn py-4 px-5 has-background-white is-size-6",
              },
              [_vm._v(_vm._s(_vm.buttonCta))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.external
        ? _c("a", { staticClass: "mb-5", attrs: { href: _vm.link } }, [
            _c(
              "button",
              {
                staticClass:
                  "animCta__btn py-4 px-5 has-background-white is-size-6",
              },
              [_vm._v(_vm._s(_vm.buttonCta))]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }