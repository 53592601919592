var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "journalSide" },
    [
      _vm.jumpto.length > 0
        ? _c("div", { staticClass: "journalSide__jumpTo is-hidden-touch" }, [
            _c(
              "p",
              {
                staticClass: "heading is-family-tertiary has-text-weight-bold",
              },
              [_vm._v("Jump to")]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "journalSide__items mt-3" },
              _vm._l(_vm.jumpto, function (el, i) {
                return _c("li", { key: i, staticClass: "journalSide__item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "journalSide__link",
                      attrs: { href: _vm.formatLink(el) },
                    },
                    [_vm._v(_vm._s(el))]
                  ),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.jumpto.length > 0 && _vm.artist ? _c("hr") : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.artist
        ? _c(
            "NuxtLink",
            {
              attrs: {
                to: { name: "profile-id", params: { id: _vm.artist.address } },
              },
            },
            [_c("ArtistCard", { attrs: { artist: _vm.artist } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }