var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level is-mobile is-vcentered", class: _vm.customClass },
    [
      _c(
        "div",
        { staticClass: "level-left" },
        [
          _vm.artistAccount && _vm.imageVisible
            ? _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("account-image", {
                    attrs: { address: _vm.artistAccount },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.splicedCollabs, function (collab, index) {
            return _vm.filteredCollabs.length > 0 && _vm.imageVisible
              ? _c(
                  "div",
                  {
                    key: index,
                    staticClass: "level-item",
                    staticStyle: { "margin-left": "-1.5rem" },
                  },
                  [_c("account-image", { attrs: { address: collab } })],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.filteredCollabs.length > 2
            ? _c(
                "div",
                {
                  staticClass: "level-item has-text-weight-semibold",
                  staticStyle: { "margin-left": "-0.5rem" },
                },
                [
                  _vm._v(
                    "\n      +" +
                      _vm._s(_vm.filteredCollabs.length - 2) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "level-item" }, [
            !_vm.loadingUser
              ? _c("section", [
                  _vm.name && _vm.size === "is-large"
                    ? _c("h4", { class: { ccArtworkInfoText: _vm.isCc } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.nameOrShortEth) +
                            "\n        "
                        ),
                      ])
                    : _c(
                        "h6",
                        {
                          staticClass:
                            "is-family-secondary has-text-weight-medium h6-width",
                          class: { ccArtworkInfoText: _vm.isCc },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.nameOrShortEth) +
                              "\n        "
                          ),
                        ]
                      ),
                ])
              : _c(
                  "div",
                  { staticClass: "pl-2" },
                  [_c("b-skeleton", { attrs: { width: "3rem" } })],
                  1
                ),
          ]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }