<template>
  <section>

    <section class="mb-2 mt-6 content">
      <div class="columns">
        <div class="column is-half-desktop">
          <b-field label="Name your collection *" class="mb-6"
                   :message="buildSlug" :type="{'is-danger': !isValidSlug}">
            <b-input minlength="3" maxlength="50" v-model="form.collectionName">
            </b-input>
          </b-field>
          <b-field label="Describe your collection *" class="mb-6">
            <b-input type="textarea" minlength="1" maxlength="1000" v-model="form.collectionDescription">
            </b-input>
          </b-field>
        </div>
      </div>
    </section>

    <nav class="columns has-margin-bottom-6 has-margin-top-6">
      <aside class="column is-half-desktop is-full-mobile content">
        <h2 class="is-family-secondary">
          Add artworks to your collection
        </h2>
      </aside>

      <aside class="column is-half-desktop is-full-mobile has-text-right-desktop">
        <div class="field-group">
          <div class="field field-body is-horizontal is-inline-block">
            <b-select placeholder="Filter by" v-model="selectedFilter" size="is-small"
                      class="has-margin-right-6 has-margin-left-7">
              <option
                v-for="option in filterOptions"
                :value="option"
                :key="option.key">
                {{ option.label }}
              </option>
            </b-select>
          </div>
          <div class="field field-body is-horizontal is-inline-block">
            <b-select placeholder="Sort by" v-model="selectedSortBy" size="is-small" class="has-margin-left-7">
              <option
                v-for="option in sortByOptions"
                :value="option"
                :key="option.key">
                {{ option.label }}
              </option>
            </b-select>
          </div>
        </div>
      </aside>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-one-third" v-for="(edition, $index) in filteredEditions" :key="$index">
        <span @click="addToCollection(edition.id)">

          <client-only>
            <div class="card" :class="{'selected-card': collectionContains(edition.id),'ccArtwork': isCc(edition)}">
              <div class="card-image">
                <image-asset :edition-number="edition.id"
                             :metadata="edition.metadata"
                             resolution="thumbnail"
                             :cover="true">
                </image-asset>
              </div>
              <div class="card-content is-paddingless pb-2">
                <div class="content">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-8 has-padding-left-2 has-padding-top-4">
                      <h6 class="has-text-left is-family-primary" :class="{'has-text-dark': !isCc(edition), 'has-text-white': isCc(edition)}">{{ edition.metadata.name }}</h6>
                    </div>
                    <div class="column has-text-right is-4 is-size-7 has-padding-right-2 has-padding-top-4" :class="{'has-text-dark': !isCc(edition), 'has-text-white': isCc(edition)}">
                      <availability :available="edition.totalAvailable" :supply="edition.totalSupply" :edition="edition"></availability>
                    </div>
                  </div>
                  <div class="column is-four-fifths has-padding-left-2 has-padding-bottom-2">&nbsp;</div>
                </div>
              </div>
              <footer class="card-footer columns">
                <div class="column">
                  <b-tag type="is-secondary" size="is-medium" class="mt-3 ml-5"
                         v-if="form.items.indexOf(edition.id) === 0">
                    Top
                  </b-tag>
                  <b-tag type="is-secondary" size="is-medium" class="mt-3 ml-5"
                         v-else-if="form.items.indexOf(edition.id) >= 0 && form.items.indexOf(edition.id) !== form.items.length - 1">
                    Position {{ form.items.indexOf(edition.id) + 1 }}
                  </b-tag>
                  <b-tag type="is-secondary" size="is-medium" class="mt-3 ml-5"
                         v-else-if="form.items.indexOf(edition.id) === form.items.length - 1 && form.items.indexOf(edition.id) >= 0 ">
                    Bottom
                  </b-tag>
                </div>
                <div class="column has-text-right has-padding-right-2">
                    <b-icon icon="checkbox-blank-circle-outline" class="is-clickable"
                            v-if="!collectionContains(edition.id)"
                            size="is-large" :type="isCc(edition) ? 'is-white' : 'is-dark'">
                    </b-icon>
                    <b-icon icon="check-circle-outline" class="is-clickable" v-else
                            size="is-large" :type="isCc(edition) ? 'is-white' : 'is-dark'">
                    </b-icon>
                  </div>
              </footer>
            </div>
          </client-only>
        </span>
      </div>
    </div>

    <div v-if="editions && editions.length === 0">
      <empty-state message="Empty collection"></empty-state>
    </div>

    <section class="content mb-6 mt-6">
      <div class="columns">
        <div class="column is-half-desktop">

          <h2 class="mb-4 is-family-secondary">Display options</h2>
          <b-field label="Select the number of artworks you wish to display on your profile page">
            <b-select v-model="form.frontPageRows">
              <option v-for="option in rowSelectionOptions" :value="option.value" :key="option.value">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <h2 class="mb-4 is-family-secondary">Visibility</h2>
          <b-field label="Make your collection private or public *" message="Only you can view this collection">
            <b-radio v-model="form.isPrivateCollection"
                     size="is-medium"
                     :native-value="true">
              Private
            </b-radio>
          </b-field>

          <b-field message="Everyone can view this collection">
            <b-radio v-model="form.isPrivateCollection"
                     size="is-medium"
                     :native-value="false">
              Public
            </b-radio>
          </b-field>
        </div>
      </div>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <b-button type="is-primary" @click="saveCollection"
                      :disabled="form.saving || (form.items.length === 0 && form.items.length <= 36) || !validCollectionMetadata || !isValidSlug">
              Save collection
            </b-button>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button @click="cancelEditFlow" :disabled="form.saving">
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </section>

  </section>
</template>
<script>

import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _unionBy from 'lodash/unionBy';
import {mapGetters, mapState} from 'vuex';
import Availability from '../Availability';
import {ACTIVE_EDITIONS, EDITIONS_BY_ARTIST_WITH_EXCLUDE, EDITIONS_BY_ARTIST_WITH_EXCLUDE_ONLY_CC_COLLABS} from '../../queries/editionQueries';
import EmptyState from '../common/EmptyState';
import {collectionSlug} from '../../services/slugs';
import ImageAsset from '../asset-renders/ImageAsset';
import {notInExclusions} from '../../services/utils';
import {isCcEditionId} from '@/services/editionUtils';
import {isCreatorContract} from '@/services/CreatorContractUtils';

const filterOptions = [
  {label: 'All', key: 'all'},
  {label: 'Sold out', key: 'sold-out'},
  {label: 'Available', key: 'available'}
];

const sortByOptions = [
  {label: 'Newest', key: 'newest', orderBy: 'createdTimestamp', orderDirection: 'desc'},
  {label: 'Oldest', key: 'oldest', orderBy: 'createdTimestamp', orderDirection: 'asc'},
  {label: 'Highest price', key: 'highest', orderBy: 'priceInWei', orderDirection: 'desc'},
  {label: 'Lowest price', key: 'lowest', orderBy: 'priceInWei', orderDirection: 'asc'}
];

const PAGE_SIZE = 999;

export default {
  components: {ImageAsset, EmptyState, Availability},
  props: ['profile', 'editCollectionId'],
  data: () => ({
    skip: 0,
    editions: [],
    ccCollabEditions: [],
    infiniteId: +new Date(),
    selectedFilter: filterOptions[0],
    filterOptions,
    selectedSortBy: sortByOptions[0],
    sortByOptions,
    rowSelectionOptions: [
      {name: 'Display 3', value: 1},
      {name: 'Display 6', value: 2}
    ],
    form: {
      saving: false,
      frontPageRows: 1,
      collectionName: null,
      collectionDescription: null,
      isPrivateCollection: false,
      items: []
    },
    itemsToExclude: [],
    profileCollections: [],
    currentlyEditingCollection: null
  }),
  computed: {
    ...mapState('web3Store', [
      'gqlClient'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount'
    ]),
    validCollectionMetadata() {
      return this.form.collectionName && this.form.collectionDescription &&
        this.form.collectionName.length >= 3 && this.form.collectionName.length <= 50 &&
        this.form.collectionDescription.length <= 1000;
    },
    buildSlug() {
      if (this.form.collectionName && collectionSlug(this.form.collectionName)) {
        return `https://knownorigin.io/collections/${this.profile.slug}/${collectionSlug(this.form.collectionName)}`;
      }
      return '';
    },
    isValidSlug() {
      return this.form.collectionName && collectionSlug(this.form.collectionName).length > 0;
    },
    isInEditMode() {
      return this.currentlyEditingCollection && this.currentlyEditingCollection.id;
    },
    filteredEditions() {
      return this.combinedEditions.filter(item => !this.itemsToExclude.includes(item.id))
    },
    combinedEditions() {
      return _unionBy(this.ccCollabEditions, this.editions, 'id')
    }
  },
  async mounted() {
    this.$apollo.addSmartQuery('editions', {
      client: this.gqlClient,
      fetchPolicy: 'cache-and-network',
      query() {
        return EDITIONS_BY_ARTIST_WITH_EXCLUDE(
          this.itemsToExclude.filter(item => !isCcEditionId(item)),
          notInExclusions(this.profile.address.toLowerCase())
        );
      },
      variables() {
        return this.buildEditionQueryParams();
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
    this.$apollo.addSmartQuery('ccCollabEditions', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return EDITIONS_BY_ARTIST_WITH_EXCLUDE_ONLY_CC_COLLABS(
            this.itemsToExclude.filter(item => !isCcEditionId(item)),
            notInExclusions(this.profile.address.toLowerCase())
          )
        },
        variables() {
          return this.buildEditionQueryParams();
        },
        error(error) {
          console.log('error', error);
        }
      });

    // If we are editing a collection, load it and set it on the form so its pre-selected
    if (this.editCollectionId) {
      const profileCollection = await this.$store.dispatch('profileCollectionsStore/getCollectionById', this.editCollectionId);
      this.currentlyEditingCollection = profileCollection;
      this.form.collectionName = profileCollection.name;
      this.form.collectionDescription = profileCollection.description;
      this.form.isPrivateCollection = profileCollection.private;
      this.form.items = profileCollection.items;
      this.form.frontPageRows = profileCollection.frontPageRows || 1;
    }

    await this.loadUserCollections();
  },
  methods: {
    async saveCollection() {
      this.form.saving = true;
      if (this.form.items && this.form.items.length > 0 && this.form.items.length <= 36) {
        // filter out in active items on save
        const ids = this.form.items.map(i => i.toString());
        let filteredOrderedArr;
        try {
          const items = await this.$apollo.query({
            client: this.gqlClient,
            query: ACTIVE_EDITIONS(ids),
            fetchPolicy: 'no-cache'
          }).then(({data}) => data.editions);
          const activeIds = _map(items, 'id');
          filteredOrderedArr = ids.filter(id => activeIds.includes(id));
        } catch (e) {
          console.error(e)
          filteredOrderedArr = ids
        }

        const newCollection = {
          owner: this.profile.address,
          collectionType: 'edition',
          name: this.form.collectionName,
          description: this.form.collectionDescription,
          private: this.form.isPrivateCollection,
          frontPageRows: this.form.frontPageRows || 1,
          position: 1, // this is the default position in the list
          cover: this.form.items[0],
          items: filteredOrderedArr
        };

        // When in the edit flow, ensure the collection ID is also sent
        if (this.isInEditMode) {
          newCollection.id = this.currentlyEditingCollection.id;
        }

        await this.$store.dispatch('profileCollectionsStore/addProfileCollection', newCollection);

        this.$buefy.toast.open(this.isInEditMode ? 'Collection updated' : 'Collection added');

        this.$emit('close-create-collection', true);
      }
      this.form.saving = true;
    },
    addToCollection(editionId) {
      if (this.form.items.length === 35) {
        this.form.items = _filter(this.form.items, item => item !== editionId);
        this.$buefy.toast.open({message: 'You can only have 35 artworks in a collection', type: 'is-warning'});
        return;
      }

      if (this.collectionContains(editionId)) {
        this.form.items = _filter(this.form.items, item => item !== editionId);
      } else {
        this.form.items.unshift(editionId);
      }
    },
    async loadUserCollections() {
      const {collections, allItems} = await this.$store.dispatch('profileCollectionsStore/getProfileCollections', {
        owner: this.profile.address,
        includePrivate: true,
        collectionType: 'edition'
      });

      this.profileCollections = collections;

      // Edit flow
      if (this.editCollectionId) {
        // ensure we take out the editing collection when in this flow
        const existingItems = _get(this.currentlyEditingCollection, 'items', []);

        // remove the current from the exclude
        this.itemsToExclude = _filter(allItems, item => _indexOf(existingItems, item) === -1);
      } else {
        // existing items so we cannot select them as they are already in an existing collection
        this.itemsToExclude = allItems
      }
    },
    collectionContains(editionId) {
      return this.form.items.includes(editionId);
    },
    cancelEditFlow() {
      this.$emit('close-create-collection');
    },
    buildEditionQueryParams(overrides = {}) {
      return {
        artist: [this.profile.address.toLowerCase()],
        first: PAGE_SIZE,
        skip: 0,
        orderBy: this.selectedSortBy.orderBy,
        orderDirection: this.selectedSortBy.orderDirection,
        remainingSupplyLte: this.selectedFilter.key !== 'sold-out' ? 10000 : 0,
        remainingSupplyGte: this.selectedFilter.key === 'available' ? 1 : 0,
        ...overrides
      };
    },
    isCc(edition) {
      return isCreatorContract(edition);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "assets/colours.scss";

.selected-card {
  border: 2px solid $dark;
}
</style>
