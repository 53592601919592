import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=067f37bc&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=js&"
export * from "./VideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=067f37bc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067f37bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('067f37bc')) {
      api.createRecord('067f37bc', component.options)
    } else {
      api.reload('067f37bc', component.options)
    }
    module.hot.accept("./VideoPlayer.vue?vue&type=template&id=067f37bc&scoped=true&", function () {
      api.rerender('067f37bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/asset-renders/VideoPlayer.vue"
export default component.exports