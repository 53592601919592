<template>
  <div v-if="editionData && editionId && !startDatePassedPlus">
    <client-only>
      <header class="header-image has-text-white has-background-grey-darker" v-lazy:background-image="imageUrl">
        <section class="hero">
          <div class="hero-body">
            <div class="container content">
              <h1 class="has-text-white has-margin-top-1 is-size-2-mobile">
                <n-link :to="{name:'gallery-id', params:{id: editionId }}" class="has-text-white has-padding-top-6">
                  <b-icon icon="video" v-if="isVideo" size="is-small" class="is-pulled-right" custom-class="is-size-3"></b-icon>
                  Drop by {{ editionData.metadata.artist }}
                  <span v-if="editionData.optionalCommissionAccount"> X <profile-name :eth-account="editionData.optionalCommissionAccount"></profile-name></span>
                  <span v-if="!startDatePassed"><br />coming {{ new Date(editionData.startDate * 1000) | moment('Do MMM') }}</span>
                  <span v-else-if="editionData.startDate > 0"><br />{{ new Date(editionData.startDate * 1000) | moment('Do MMM') }}</span>
                </n-link>
              </h1>
              <client-only v-if="editionData && !startDatePassed">
                <div class="columns has-text-white">
                  <div class="column is-one-third-desktop">
                    <edition-sale-count-down :edition="editionData" :white-text="true" :hide-label="true"></edition-sale-count-down>
                  </div>
                </div>
              </client-only>
              <div class="content">
                <div class="field is-grouped">
                  <p class="control">
                    <n-link :to="linkToArtwork" class="button is-white is-outlined">
                      View artwork
                    </n-link>
                  </p>
                </div>
              </div>
              <div class="content" v-if="editionData && editionId">
                <n-link :to="{name:'gallery-id', params:{id: editionId }}" class="is-size-10 has-text-white has-padding-top-6">
                  <artwork-name-and-artist style="overflow-wrap: anywhere;"
                                           :metadata="editionData.metadata"
                                           :edition-number="editionId">
                  </artwork-name-and-artist>
                </n-link>
              </div>
            </div>
          </div>
        </section>
      </header>
    </client-only>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {mimeTypeUtils, urlUtils} from '@knownorigin/ko-token-uri-tools';
  import {HEADER_BANNER_EDITION} from '../../queries/editionQueries';
  import EditionSaleCountDown from '../clocks/EditionSaleCountDown';
  import {editionStartDatePassed} from '../../services/utils';
  import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
  import ProfileName from '../avatar/ProfileName';
import { splitCreatorEditionId } from '../../services/CreatorContractUtils';

  const imageOverrides = {
    2881000: 'https://storage.googleapis.com/public-blog-asset/featured-drops/Arda_Yalkin_Drops.jpg',
    2841000: 'https://storage.googleapis.com/public-blog-asset/featured-drops/lisanne.png',
    5412000: 'https://storage.googleapis.com/public-blog-asset/featured-drops/5412000/Short%20Version%20-%20Use%20For%20Drop%20Page%20-%20XronoCircles.gif',
    10087000: 'https://storage.googleapis.com/public-blog-asset/featured-drops/azgour.jpg',
    10900000: 'https://storage.googleapis.com/public-blog-asset/featured-drops/huesader.png'
  };

  export default {
    components: {ProfileName, ArtworkNameAndArtist, EditionSaleCountDown},
    props: ['editionId'],
    computed: {
      ...mapState('web3Store', [
        'gqlClient'
      ]),
      startDatePassed() {
        return this.editionData && editionStartDatePassed(this.editionData.startDate);
      },
      startDatePassedPlus() {
        return this.editionData && editionStartDatePassed(parseInt(this.editionData.startDate) + 86400);
      },
      linkToArtwork() {
        if (this.editionId.includes('-')) {
          const { contract, editionId } = splitCreatorEditionId(this.editionId)
          return {name: 'contract-contract-id', params: {contract, id: editionId }}
        } else {
          return {name: 'gallery-id', params: {id: this.editionId }}
        }
      }
    },
    data() {
      return {
        editionData: '',
        isVideo: false,
        image: '',
        imageUrl: ''
      };
    },
    mounted() {
      this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        query: HEADER_BANNER_EDITION,
        variables() {
          return {
            id: this.editionId.toString()
          };
        },
        update({editions}) {
          this.editionData = editions[0];

          const isWebm = mimeTypeUtils.isWebM(editions[0].metadata.image_type) || urlUtils.isWebM(editions[0].metadata.image);
          const isMp4 = mimeTypeUtils.isMp4(editions[0].metadata.image_type) || urlUtils.isMp4(editions[0].metadata.image);
          const isGlb = !!editions[0].metadata.animation_url;

          this.setImage((isWebm || isMp4 || isGlb));
          this.isVideo = (isWebm || isMp4);
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
    },
    methods: {
      setImage(useCard = false) {
        this.imageUrl = `${this.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${this.editionId}${ useCard ? '/cover' : ''}`;

        if (imageOverrides[this.editionId]) {
          this.imageUrl = imageOverrides[this.editionId];
        }

        const highResImage = new Image();
        highResImage.onload = () => {
          this.image = this.imageUrl;
        };
        highResImage.src = this.imageUrl;
      }
    }
  };
</script>
