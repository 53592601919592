<template>
  <section v-if="!loading">
    <div class="content box-fat ccArtwork" :class="customClass" v-if="contract && name && symbol">
      <n-link :to="{name: 'contract-contract', params: {contract: contract.id}}">

        <figure class="avatar mb-4" v-if="contractImages"
                :style="{ 'background-image': 'url(' + (contractImages.banner ? contractImages.banner : coverImage) + ')'}"
                >
          <img
            v-lazy="{
                    src: contractImages.thumbnail,
                    error: createAccountIcon()
                }">
        </figure>

        <section class="content is-centered px-1 contract-card-format mb-2" v-if="name && symbol">
          <div class="has-text-centered is-size-4 has-text-white">
            <span class="is-family-tertiary has-text-weight-medium">{{ name }}</span>
          </div>
          <div class="has-text-centered mt-3">
            <b-tag class="is-family-tertiary has-text-weight-medium">
              {{ symbol }}
            </b-tag>
          </div>
        </section>

        <section class="content pl-4 pr-4 mb-2 description contract-card-format">
          <p class="has-text-centered has-text-white px-2">
            {{ contractDetails ? contractDetails.description : 'No Description'}}
          </p>
        </section>
      </n-link>
      <section class="px-2 py-2 border-grey" v-if="contract.id">
        <div class="level is-mobile">
          <div class="column level-left content creator-font m-0 p-0">
            <n-link :to="{name: 'profile-id', params: {id: contract.creator}}">
              <owner-name-and-image-lookup :account="contract.creator" :is-cc="true">
              </owner-name-and-image-lookup>
            </n-link>
          </div>
          <div v-if="contractDetails">
            <b-dropdown aria-role="list" class="is-clickable has-text-left mr-2 is-up is-right" :triggers="['click']" v-if="isLoggedInAccount(contract.creator) && parseInt(contract.transferState) !== 2">
                <b-icon icon="dots-horizontal" slot="trigger" type="is-white"></b-icon>
                <b-dropdown-item aria-role="list" @click="launchBurnContractModal()">
                  <a class="has-text-danger">Burn contract</a>
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item aria-role="list" :href="etherscanLink" target="_blank">
                  View on Etherscan
                </b-dropdown-item>
              </b-dropdown>
                <span v-else>
                  <a class="p-0 m-0" :href="etherscanLink" target="_blank">
                    <img class="etherscan" src="../../assets/logos/etherscan-logo-circle-white.svg" color="white">
                  </a>
                </span>
          </div>
        </div>
      </section>
    </div>
  </section>
  <section v-else>
    <div class="content box-fat ccArtwork" :class="customClass">

        <figure class="avatar mb-4"
                :style="{ 'background-image': 'url('+coverImage+')'}"
                >
          <img
            v-lazy="{
                    src: createAccountIcon()
                }">
        </figure>

        <section class="content is-centered px-6 contract-card-format mb-2">
          <div class="has-text-centered is-size-4 has-text-white px-1 is-flex is-align-items-center is-justify-content-center">
            <b-skeleton width="100%" />
          </div>
          <div class="has-text-centered mt-3 px-6">
            <div class="px-6"><b-skeleton width="100%" /></div>
          </div>
        </section>

        <section class="content px-4 pt-2 description contract-card-format is-flex flex-direction-column is-align-items-center">
          <b-skeleton width="100%" />
        </section>
      <section class="px-2 py-2 mb-1 border-grey">
        <div class="level is-mobile mt-2">
          <div class="column level-left content creator-font m-0 p-0">
            <b-skeleton width="50%" />
          </div>
          <div>
              <span>
                  <img class="etherscan" src="../../assets/logos/etherscan-logo-circle-white.svg" color="white">
              </span>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import {toDataUrl} from 'myetherwallet-blockies';
import {mapGetters} from 'vuex';
import {cleanUpUrl} from '../../services/utils';
import BurnCreatorContractModal from '../modal/BurnCreatorContractModal.vue'
import OwnerNameAndImageLookup from '../OwnerNameAndImageLookup';

export default {
  components: {OwnerNameAndImageLookup},
  props: ['contract', 'customClass'], // address is the contract address
  data() {
    return {
      profile: null,
      coverImage: '/branding/cover-preview-dark.jpg',
      contractImages: {
        thumbnail: '',
        banner: ''
      },
      name: this.contract ? this.contract.name : '',
      symbol: this.contract ? this.contract.symbol : '',
      contractDetails: null,
      loading: true
    };
  },
  computed: {
    ...mapGetters('userStore', []),
    ...mapGetters('web3Store', [
      'isLoggedInAccount'
    ]),
    nameOrShortEth() {
      if (this.profile && this.profile.username) {
        return this.profile.username;
      }
      return this.$options.filters.shortEth(this.profile.address);
    },
    etherscanLink() {
      return `https://etherscan.io/address/${this.cleanUpUrl(this.contract.id)}`
    }
  },
  async mounted() {
    try {
      this.loading = true
      this.contractDetails = await this.$store.dispatch('creatorContractStore/fetchContractDetails', {
        address: this.contract.id
      })
      const response = await this.$store.dispatch('creatorContractStore/fetchAllContractImages', {
        address: this.contract.id
      });
      this.contractImages.banner = response.images.banner ? response.images.banner : '';
      this.contractImages.thumbnail = response.images.thumbnail ? response.images.thumbnail : '';
    } catch (e) {
      console.error('Problem with creator contract details fetch', e);
      this.loading = false
    }
    try {
      this.profile = await this.$store.dispatch('userStore/getUser', this.contract.creator);
      this.loading = false
    } catch (e) {
      console.error('Problem with owner retrieval', e);
      this.loading = false
    }
  },
  methods: {
    cleanUpUrl,
    createAccountIcon() {
      return toDataUrl(this.contract ? this.contract.id : 'sample');
    },
    launchBurnContractModal() {
      this.$buefy.modal.open({
        parent: this,
        component: BurnCreatorContractModal,
        width: 600,
        props: {
          contract: this.contract,
          contractImages: this.contractImages
        },
        events: {
          close: () => {
            console.log('closed modal')
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";
.border-grey {
  border-top: 1px solid #3A3A3A;
}
.box-fat {
  margin-top: 1rem;
  height: 23.75rem;
  min-width: 10rem;
  border-radius: 0.5rem;
  transition: transform .2s ease;
  box-shadow: 0px 0px 10px 5px rgba(14, 14, 14, 0.08);
  width: 100%;

  &:hover {
    transform: translateY(-4px);
  }

}

.avatar {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  border-radius: 0.4rem 0.4rem 0 0
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 20%;
  -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  width: 128px;
  height: 128px;
}

.creator-font {
  font-size: 15px;
}

.description {
  height: 55px;
}

.contract-card-format {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.max-width-90 {
  max-width: 90%;
}

.etherscan {
  margin-top: 2px;
  margin-right: 2px;
  height: 20px;
  width: 20px;
}

</style>
