var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:tap",
          value: _vm.oneClick,
          expression: "oneClick",
          arg: "tap",
        },
      ],
      staticClass: "disable-dbl-tap-zoom",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }