<template>
  <client-only>
    <div class="card" v-if="workingMetadata && edition" :class="{ccArtwork: isCc}">

      <image-for-asset-card
        :algolia-info="algoliaInfo"
        :edition="edition"
        :cover="cover"
        :resolution="resolution"
        :metadata="workingMetadata"
        :click-handler="clickHandler"
        :is-cc="isCc"
      >
      </image-for-asset-card>

      <slot name="artwork-info-asset-card">
        <artwork-info
          :edition="edition"
          :size="size"
          :phase="phase"
          :click-handler="clickHandler">
        </artwork-info>
      </slot>

      <footer class="card-footer" :class="{borderGrey : isCc }">
        <slot name="search-footer-left-asset-card">
          <search-footer-left-asset-card :edition="edition"
                                         :working-token="workingToken"
                                         :phase="phase"
                                         :token-offer="tokenOffer"
                                         :min-bid="minBid"
                                         :click-handler="clickHandler">
          </search-footer-left-asset-card>
        </slot>
        <slot name="search-footer-right-asset-card">
          <search-footer-right-asset-card :edition="edition"
                                          :working-token="workingToken"
                                          :click-handler="clickHandler"
                                          :phase="phase">
          </search-footer-right-asset-card>
        </slot>
      </footer>
    </div>
  </client-only>
</template>

<script>
import _get from 'lodash/get';
import _head from 'lodash/head';
import {mapState} from 'vuex';
import _first from 'lodash/first';
import _filter from 'lodash/filter';
import {findCheapestToken} from '../services/salesStates';
import editionUtils from '../services/editionUtils';
import {GET_TOKEN_ACTIVE_OFFER} from '../queries/offersQueries';
import {getPhaseState, isOffersOnlySalesType, isOffersSalesType} from '../services/SaleTypes';
import {isCreatorContract} from '../services/CreatorContractUtils';
import ImageForAssetCard from './asset-card/ImageForAssetCard';
import ArtworkInfo from './asset-card/ArtworkInfo';
import SearchFooterLeftAssetCard from './asset-card/SearchFooterLeftAssetCard';
import SearchFooterRightAssetCard from './asset-card/SearchFooterRightAssetCard';

export default {
  components: {
    ImageForAssetCard,
    ArtworkInfo,
    SearchFooterLeftAssetCard,
    SearchFooterRightAssetCard
  },
  props: [
    'edition',
    'cover',
    'resolution',
    'size',
    'algoliaInfo',
    'clickHandler'
  ],
  data() {
    return {
      workingToken: null,
      tokenOffer: null,
      minBid: null,
      phase: null,
      metadataFromKoCache: null
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    workingMetadata() {
      if (editionUtils.doesMetadataExist(this.edition)) {
        return this.edition.metadata;
      } else {
        return this.metadataFromKoCache;
      }
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  },
  async mounted() {
    await this.loadMetaData()
    if (this.edition) {
      if (this.edition.gatedSale && this.edition.gatedSale.phases.length > 0) {
        const state = getPhaseState(this.edition.gatedSale.phases[0])
        if (state) {
          const phase = this.edition.gatedSale.phases[0]
          phase.state = state
          this.phase = phase
        }
      }

      if (this.isNotSoldOutPrimary) {
        await this.loadMinimumEditionBid();
      } else {
        await this.loadCheapestToken();
        if (this.workingToken) {
          if (isOffersOnlySalesType(this.workingToken.salesType)) {
            await this.loadMinimumTokenBid();
          }
          if (isOffersSalesType(this.workingToken.salesType)) {
            await this.loadTokenOffers();
          }
        }
      }
    }
  },
  methods: {
    loadCheapestToken() {
      // remove burnt tokens from the list
      const cleanTokens = _filter(this.edition.tokens, (token) => {
        return token.currentOwner.id !== '0x000000000000000000000000000000000000dead' &&
          token.currentOwner.id !== '0x0000000000000000000000000000000000000000';
      });
      this.workingToken = findCheapestToken(cleanTokens);

      if (!this.workingToken) {
        if (cleanTokens.length === 1) {
          this.workingToken = _first(cleanTokens);
        }
      }
    },
    async loadMinimumTokenBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.workingToken, 'currentOwner.id'),
        artwork_id: _get(this.workingToken, 'id'),
        entity_type: 'token'
      });
    },
    async loadMinimumEditionBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.edition, 'artistAccount'),
        artwork_id: _get(this.edition, 'id'),
        entity_type: 'edition'
      });
    },
    loadTokenOffers() {
      if (this.workingToken) {
        this.$apollo.getClient()
          .query({
            query: GET_TOKEN_ACTIVE_OFFER,
            variables: {
              token: `token-${this.workingToken.id}`
            }
          })
          .then(({data}) => {
            this.tokenOffer = _head(_get(data, 'tokenOffers'));
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },
    async loadMetaData() {
      if (!editionUtils.doesMetadataExist(this.edition)) {
        const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.edition.id}`);
        if (metadata) {
          this.metadataFromKoCache = metadata;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.borderGrey {
  border-top-color:#3A3A3A;
}
</style>
