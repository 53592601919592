<template>
  <span v-if="!isBuyNowAndOpenSalesType(edition.salesType)" :class="{'has-text-weight-semibold': !small, 'is-size-8 pl-1': small}" class="has-text-right is-family-tertiary">
    <span v-if="!isOneOfOne && availability === 1" class="has-text-danger">last one</span>
    <span v-else>1/{{ available }}</span>
  </span>
  <span v-else :class="{'has-text-weight-medium': !small, 'is-size-8 pl-1': small}" class="has-text-right is-family-tertiary">
    <span v-if="isSoldOutPrimary(edition)">{{ openEditionSoldOutSalesCounts }}</span>
    <span v-else-if="!startDatePassed(edition)">0 MINTED</span>
    <span v-else>{{ edition.totalSupply }}{{ openEditionSalesCount }} MINTED</span>
  </span>
</template>

<script>
import { isBuyNowAndOpenSalesType } from '../services/SaleTypes';
import { isSoldOutPrimary, startDatePassed } from '../services/editionUtils';

export default {
  props: ['supply', 'available', 'edition', 'small'],
  computed: {
    isOneOfOne() {
      return parseInt(this.available) === 1;
    },
    availability() {
      return parseInt(this.available) - parseInt(this.supply);
    },
    openEditionSoldOutSalesCounts() {
      return this.edition.totalSold > 0
        ? `1/${this.edition.totalSold}`
        : '0 MINTED';
    },
    openEditionSalesCount() {
      return this.edition.totalAvailable !== '100000'
        ? `/${this.edition.totalAvailable}`
        : '';
    }
  },
  methods: {
    isBuyNowAndOpenSalesType,
    isSoldOutPrimary,
    startDatePassed
  }
};
</script>

<style>
.is-size-8 {
  font-size: 10px;
}
</style>
