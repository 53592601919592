<template>
  <section class="columns has-margin-top-1 is-multiline">
    <aside class="column is-one-third" v-for="blogPost in allItemsSpliced" :key="blogPost.path">
      <n-link :to="blogPost.path">
        <div class="card is-shadowless has-margin-bottom-1">
          <div class="card-image">
            <figure class="image is-16by9">
              <img :src="buildImgixURL(blogPost.coverimage)" :alt="blogPost.artist" class="journal-image-cover">
            </figure>
          </div>
          <div class="card-content is-paddingless">
            <div class="content">
              <h3 class="is-family-tertiary has-margin-top-6 has-text-weight-bold">{{blogPost.title}}</h3>
              <p class="is-family-primary has-text-weight-normal">
                <b-tag class="is-uppercase is-family-tertiary has-text-weight-medium" type="is-secondary" v-if="blogPost.journalType">{{blogPost.journalType}}</b-tag>
                {{blogPost.description}}
                <span v-if="blogPost.youtube"><b-icon icon="youtube"></b-icon></span>
              </p>
              <time class="is-muted is-size-7 level is-mobile ">
                <div class="level-left is-family-tertiary has-text-weight-bold">
                  <b-icon icon="calendar" class="level-item" size="is-small"></b-icon>
                  <span class="level-item" v-if="blogPost.publishedDate || blogPost.date">{{getJournalDateFrom(blogPost)}}</span>
                </div>
              </time>
            </div>
          </div>
        </div>
      </n-link>
    </aside>

    <client-only>
      <infinite-loading @infinite="moreItems" :identifier="infiniteId">
        <template slot="spinner">&nbsp;</template>
        <template slot="no-results">&nbsp;</template>
      </infinite-loading>
    </client-only>
  </section>
</template>

<script>
  import _orderBy from 'lodash/orderBy';
  import {PUBLIC_BLOG_ASSET_BUCKET, getJournalDate, getJournalDateFrom} from '../services/utils'

  const STEP_INIT = 6;

  export default {
    components: {},
    props: ['journalItems', 'cmsItems'],
    data() {
      return {
        step: STEP_INIT,
        limit: STEP_INIT,
        infiniteId: +new Date()
      }
    },
    computed: {
      allItemsSpliced() {
        if (this.journalItems && this.cmsItems) {
          const allItems = this.journalItems.concat(this.cmsItems);
          return _orderBy(allItems, [blogPost => getJournalDate(blogPost)], ['desc']).splice(0, this.limit);
        }

        if (this.journalItems) {
          return _orderBy(this.journalItems, [blogPost => getJournalDate(blogPost)], ['desc']).splice(0, this.limit);
        }

        return [];
      }
    },
    methods: {
      moreItems($state = null) {
        if ($state) {
          this.infiniteId += 1;
        }

        this.limit = this.limit + this.step;
      },
      buildImgixURL(url) {
        if (this.$publicBlogAssetImgixClient && url && url.startsWith(PUBLIC_BLOG_ASSET_BUCKET)) {
          return this.$publicBlogAssetImgixClient.buildURL(url.replace(PUBLIC_BLOG_ASSET_BUCKET, ''), {h: 500, ar: '16:9', fit: 'cover'});
        }

        return url;
      },
      getJournalDateFrom
    }
  };
</script>
