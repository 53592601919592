var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Complete auction\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _c(
            "section",
            { staticClass: "has-margin-7" },
            [
              _c("winning-offer", {
                attrs: {
                  "wei-value": _vm.edition.reserveAuctionBid,
                  "bidder-address": _vm.edition.activeBid.bidder,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-message",
            {
              staticClass: "is-size-7",
              attrs: { type: "is-info", closable: false, duration: 0 },
            },
            [
              _c("p", [
                _vm._v(
                  "\n        Resulting an auction completes the trade between the seller and buyer.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        All parties can result the auction once it has successfully ended.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                      target: "_blank",
                      lass: "is-linkable",
                    },
                  },
                  [_vm._v("Learn how 24 hour auctions work")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Auction complete\n  "),
      ]),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: { expanded: "", disabled: _vm.transactionHash },
              on: { click: _vm.resultAuction },
            },
            [_vm._v("\n      Complete auction\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }