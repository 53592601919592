var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button is-fullwidth",
      class: {
        "is-primary": _vm.isRefinedForValue && !_vm.checkHasValue(),
        "is-light": !_vm.isRefinedForValue && !_vm.checkHasValue(),
      },
      attrs: {
        href: () => _vm.createUrlForValue(),
        disabled: _vm.checkHasValue(),
      },
      on: {
        click: [
          function ($event) {
            return _vm.sendInsightsEvent()
          },
          function ($event) {
            $event.preventDefault()
            return (() => _vm.refineForValue()).apply(null, arguments)
          },
        ],
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }