var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    [
      _c("div", {
        staticClass:
          "has-background-white is-fixed-white-background is-hidden-mobile height-colour",
      }),
      _vm._v(" "),
      _vm.chainError
        ? _c("div", { staticClass: "chain-error-overlay" })
        : _vm._e(),
      _vm._v(" "),
      _c("mobile-nav"),
      _vm._v(" "),
      _c("desktop-nav"),
      _vm._v(" "),
      _c(
        "section",
        { staticStyle: { "min-height": "600px" } },
        [_c("nuxt")],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "has-background-dark pt-6 pb-6" }, [
        _c("section", { staticClass: "container is-not-full-width" }, [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-half" }, [
              _c(
                "div",
                { staticClass: "has-margin-left-7 mt-2" },
                [
                  _c(
                    "n-link",
                    {
                      staticClass: "is-flex is-align-items-center",
                      attrs: { to: { path: "/" } },
                    },
                    [
                      _c("img", {
                        staticClass: "footer-ko-logo mb-1",
                        attrs: { src: "/branding/White-ko-logo.svg" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "is-size-6 has-margin-left-7" },
                [
                  _c(
                    "n-link",
                    {
                      staticClass:
                        "has-text-grey-light is-family-tertiary has-text-weight-medium",
                      attrs: { to: "/" },
                    },
                    [_vm._v("Discover, sell and collect NFT art")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "has-margin-top-1 has-padding-left-7" },
                [
                  _c("web3-cache-provider", {
                    staticClass: "is-muted is-size-7",
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "column" }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "has-margin-top-1 has-margin-left-7" }, [
      _c(
        "p",
        { staticClass: "heading has-text-grey-light is-family-monospace" },
        [
          _vm._v(
            "\n              KnownOrigin Labs LTD (company no. 13047869)\n            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "has-margin-left-7" }, [
      _c(
        "p",
        { staticClass: "heading has-text-grey-light is-family-monospace" },
        [
          _c(
            "a",
            { attrs: { href: "https://blockrocket.tech/", target: "_blank" } },
            [_vm._v("Built by BlockRocket")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("div", [
        _c(
          "p",
          {
            staticClass:
              "has-text-weight-black has-text-white has-padding-left-7 is-family-secondary is-uppercase",
          },
          [_vm._v("Follow Us")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-family-tertiary" }, [
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://twitter.com/knownorigin_io",
                target: "_blank",
              },
            },
            [_vm._v("\n                Twitter\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://discord.gg/knownorigin",
                target: "_blank",
              },
            },
            [_vm._v("\n                Discord\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://www.instagram.com/knownorigin.io/",
                target: "_blank",
              },
            },
            [_vm._v("\n                Instagram\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://www.reddit.com/r/knownorigin/",
                target: "_blank",
              },
            },
            [_vm._v("\n                Reddit\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://www.youtube.com/channel/UCZBkUkm5SSXQqzxBPezd5gw",
                target: "_blank",
              },
            },
            [_vm._v("\n                YouTube\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://linktr.ee/KnownOrigin.io",
                target: "_blank",
              },
            },
            [_vm._v("\n                Linktree\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://dune.com/knownorigin/KnownOrigin",
                target: "_blank",
              },
            },
            [_vm._v("\n                Dune Analytics\n              ")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column" }, [
      _c("div", [
        _c(
          "p",
          {
            staticClass:
              "has-text-weight-black has-text-white has-padding-left-7 is-family-secondary is-uppercase",
          },
          [_vm._v("Help")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-family-tertiary" }, [
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: { href: "https://docs.knownorigin.io", target: "_blank" },
            },
            [_vm._v("Documentation")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "has-text-primary-light" }, [_vm._v("/")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://docs.knownorigin.io/en/articles/6121943-press-kit",
                target: "_blank",
              },
            },
            [_vm._v("Press kit")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://docs.knownorigin.io/en/articles/7128351-terms-of-service",
                target: "_blank",
              },
            },
            [_vm._v("\n                Terms of Service\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://docs.knownorigin.io/en/articles/7128342-privacy-policy",
                target: "_blank",
              },
            },
            [_vm._v("\n                Privacy\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://docs.knownorigin.io/en/articles/6118953-code-of-conduct",
                target: "_blank",
              },
            },
            [_vm._v("Code of Conduct")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://docs.knownorigin.io/en/",
                target: "_blank",
              },
            },
            [_vm._v("Support")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "mailto:report@knownorigin.io?subject=Content Report",
                target: "_blank",
              },
            },
            [_vm._v("Report infringement")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "mailto:partnerships@knownorigin.io?subject=Partnership opportunity",
                target: "_blank",
              },
            },
            [_vm._v("Partnership enquiry")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://pages.ebay.com/securitycenter/security_researchers_eligible_domains.html",
                target: "_blank",
              },
            },
            [_vm._v("\n                Responsible Disclosure\n              ")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "has-margin-top-7 has-padding-left-7" }, [
          _c(
            "a",
            {
              staticClass: "has-text-light",
              attrs: {
                href: "https://jobs.ebayinc.com/us/en/search-results?keywords=knownorigin",
                target: "_blank",
              },
            },
            [_vm._v("\n                Careers\n              ")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }