<template>
  <client-only>
    <vue-countdown v-if="!hidden"
                   :time="diff"
                   :transform="transform"
                   :emit-events="true"
                   @progress="handleCountdownProgress"
                   class="mx-3"
    >
      <template slot-scope="props">
        <section>
          <p class="title" :class="{'has-text-white': whiteText === true}" v-if="!hideLabel">
            Auction ends in
          </p>
          <div class="level is-mobile box" :class="{'has-text-white': whiteText === true}">

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading is-marginless">{{ props.hours }}</p>
                <p class="heading">Hours</p>
              </div>
            </div>

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading is-marginless">{{ props.minutes }}</p>
                <p class="heading">Minutes</p>
              </div>
            </div>

            <div class="level-item has-text-centered">
              <div>
                <p class="is-size-3 heading is-marginless">{{ props.seconds }}</p>
                <p class="heading">Seconds</p>
              </div>
            </div>
          </div>
        </section>
      </template>
    </vue-countdown>
  </client-only>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import * as moment from 'moment';

  export default {
    components: {VueCountdown},
    props: ['listing', 'whiteText', 'hideLabel'],
    data() {
      return {
        hidden: false
      };
    },
    computed: {
      diff() {
        // stored in UTC, ensure local is also UTC'd
        const endDate = moment.unix(this.listing.reserveAuctionEndTimestamp).utc();
        const now = moment().local().utc();
        return endDate.diff(now, 'milliseconds');
      }
    },
    methods: {
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;

          props[key] = `${digits}`;
        });
        return props;
      },
      handleCountdownProgress(data) {
        if (data.totalSeconds <= 0) {
          console.log('ended');
        }
      }
    }
  };
</script>
