var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "media is-mobile mb-5",
      class: [{ hoverState: _vm.isHovered }],
    },
    [
      _vm.artistAccount && _vm.imageVisible
        ? _c(
            "figure",
            {
              staticClass: "media-left",
              on: {
                mouseenter: function ($event) {
                  return _vm.hovered(true)
                },
                mouseleave: function ($event) {
                  return _vm.hovered(false)
                },
              },
            },
            [
              _c(
                "n-link",
                {
                  attrs: {
                    to: {
                      name: "profile-id",
                      params: { id: _vm.artistAccount.toLowerCase() },
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "lazy-container",
                          rawName: "v-lazy-container",
                          value: { selector: "img" },
                          expression: "{ selector: 'img' }",
                        },
                      ],
                      staticClass: "image is-square is-64x64",
                      class: [
                        { "is-128x128": _vm.isLarge },
                        { "rounded-image": _vm.smallText },
                      ],
                    },
                    [
                      _c("img", {
                        staticClass: "is-rounded",
                        attrs: {
                          id: _vm.artistAccount,
                          "data-src": `${_vm.$userMediaApi.defaults.baseURL}/media/user/profile/${_vm.artistAccount}`,
                          "data-error": _vm.createAccountIcon,
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "media-content artist-name-container" }, [
        _vm.artistAccount
          ? _c("div", { staticClass: "content" }, [
              _vm.resolvedProfile && _vm.resolvedProfile.username
                ? _c(
                    "h2",
                    {
                      class: _vm.smallText
                        ? "is-size-6 is-size-6-mobile is-size-6-tablet m-0"
                        : "is-size-4-mobile is-size-4-tablet ml-2 mb-0",
                    },
                    [
                      _vm._t("artist-profile-header"),
                      _vm._v(" "),
                      _c(
                        "n-link",
                        {
                          staticClass: "artist-name-link",
                          attrs: {
                            to: {
                              name: "profile-id",
                              params: { id: _vm.artistAccount.toLowerCase() },
                            },
                            "data-cy": "artist_name",
                          },
                          nativeOn: {
                            mouseenter: function ($event) {
                              return _vm.hovered(true)
                            },
                            mouseleave: function ($event) {
                              return _vm.hovered(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("truncate")(
                                  _vm.resolvedProfile.username,
                                  20
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isEitherProfileVerified(_vm.resolvedProfile)
                        ? _c("b-icon", {
                            staticClass:
                              "is-size-5 is-size-6-mobile is-verified",
                            attrs: { icon: "check-decagram", size: "is-small" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      !_vm.removeSubtitle
                        ? _vm._t("artist-profile-subtitle", function () {
                            return [
                              _vm.resolvedProfile && _vm.resolvedProfile.twitter
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "is-size-5 is-size-6-mobile",
                                      attrs: {
                                        href: `https://twitter.com/${_vm.cleanUpUrl(
                                          _vm.resolvedProfile.twitter
                                        )}`,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "twitter",
                                          size: "is-small",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "@" +
                                            _vm._s(
                                              _vm.cleanUpUrl(
                                                _vm.resolvedProfile.twitter
                                              )
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm.resolvedProfile &&
                                  _vm.resolvedProfile.instagram
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "is-size-5 is-size-5-mobile",
                                      attrs: {
                                        href: `https://instagram.com/${_vm.cleanUpUrl(
                                          _vm.resolvedProfile.instagram
                                        )}`,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "instagram",
                                          size: "is-small",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "@" +
                                            _vm._s(
                                              _vm.cleanUpUrl(
                                                _vm.resolvedProfile.instagram
                                              )
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(
                    "h2",
                    {
                      class: _vm.smallText
                        ? "is-size-6 m-0"
                        : "is-size-6-mobile ml-2",
                    },
                    [
                      _vm._t("artist-profile-header"),
                      _vm._v(" "),
                      _c(
                        "n-link",
                        {
                          staticClass: "artist-name-link",
                          attrs: {
                            to: {
                              name: "profile-id",
                              params: { id: _vm.artistAccount.toLowerCase() },
                            },
                            "data-cy": "artist_name_mobile",
                          },
                          nativeOn: {
                            mouseenter: function ($event) {
                              return _vm.hovered(true)
                            },
                            mouseleave: function ($event) {
                              return _vm.hovered(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.nameOrShortEth) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._t("artist-profile-subtitle"),
                    ],
                    2
                  ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }