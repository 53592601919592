var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "20px", "margin-right": "20px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "columns is-centered mb-0" }, [
          _c("div", { staticClass: "card-content column is-11 pb-0" }, [
            _c("div", { staticClass: "columns is-mobile mt-2" }, [
              _c(
                "div",
                { staticClass: "column is-3" },
                [
                  _vm.editionNumber
                    ? _c("modal-image-asset", {
                        staticClass: "has-margin-top-7",
                        attrs: {
                          "custom-class": "image is-128x128",
                          "edition-number": _vm.editionNumber,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "column" }, [
                _c(
                  "section",
                  {
                    staticClass: "has-padding-top-7 has-padding-bottom-7 ml-3",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "h5",
                      { staticClass: "mb-5" },
                      [
                        _c("artwork-name-and-artist", {
                          attrs: {
                            metadata: _vm.metadata,
                            "edition-number": _vm.editionNumber,
                            "custom-class": "is-size-7",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content mb-1" }, [
              _c("hr", { staticClass: "p-0 m-0" }),
              _vm._v(" "),
              _vm.error
                ? _c("section", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-weight-medium is-size-5 mt-4 mb-0",
                      },
                      [
                        _vm._v(
                          "\n              The transaction has failed\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "has-text-weight-light pr-3 mb-5" },
                      [
                        _vm._v(
                          "\n              Please return to the edition page and try to set the listing up again.\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "is-flex mb-5" },
                      [
                        _c("b-icon", { attrs: { icon: "times" } }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("Transaction failed"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm.step === 0
                ? _c("section", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-weight-medium is-size-5 mt-4 mb-0",
                      },
                      [
                        _vm._v(
                          "\n              Are you sure you want to end this open edition?\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "has-text-weight-light pr-3 mb-5" },
                      [
                        _vm._v(
                          "\n              You'll be asked to sign a transaction that will end this sale,\n              and no more editions can be minted by collectors\n            "
                        ),
                      ]
                    ),
                  ])
                : _vm.step === 1
                ? _c("section", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "has-text-weight-medium is-size-5 mt-4 mb-0",
                      },
                      [
                        _vm._v(
                          "\n              Go to your wallet\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "has-text-weight-light pr-3 mb-5" },
                      [
                        _vm._v(
                          "\n              You'll be asked to approve the collection from your wallet.\n              You'll only need to approve each collection once.\n            "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.step > 0
                ? _c("section", [
                    _c(
                      "div",
                      { staticClass: "is-flex my-5" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: _vm.approved
                              ? "check-circle-outline has-text-success"
                              : "clock-outline has-text-warning-dark",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.approved
                                ? "Transaction confirmed"
                                : "Waiting for the transaction to be confirmed"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "div",
                { staticClass: "card-footer-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { expanded: "", type: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.step === 0
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "div",
                { staticClass: "card-footer-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { expanded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(" Nevermind ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-footer-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { expanded: "", type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.endListing()
                        },
                      },
                    },
                    [_vm._v("\n          End listing\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.step === 2
          ? _c("div", { staticClass: "card-footer" }, [
              _c(
                "div",
                { staticClass: "card-footer-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { expanded: "", type: "is-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "is-size-4 mb-1" }, [
      _c("div", [
        _vm._v("\n                  End open edition\n                "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }