<template>
  <modal-template :edition="token" :token="token" :transaction-hash="null">

    <template slot="main-content">
      <modal-image-asset v-if="!listTokenFlow"
                   :edition-number="token.editionNumber"
                   :metadata="token.metadata"
                   class="has-margin-top-7">
      </modal-image-asset>
      <div v-else>&nbsp;</div>
    </template>

    <template slot="content-title">
      {{ title }}
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="token.metadata" :edition-number="token.editionNumber">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <div slot="secondary-content">
        <p @click="copyURLWithToast" class="hand-pointer">
          <b-icon icon="content-copy" size="is-small"></b-icon> Copy share link
        </p>
        <p @click="copyInstaWithToast" class="hand-pointer">
          <b-icon icon="instagram" size="is-small"></b-icon> Copy for Instagram share
        </p>
        <p @click="copyTwitterWithToast" class="hand-pointer">
          <b-icon icon="twitter" size="is-small"></b-icon> Copy for Twitter share
        </p>
    </div>

    <template slot="action-feedback-label"></template>

    <template slot="action-button"></template>

    <template slot="action-button-nevermind">
      <b-button v-if="listTokenFlow" expanded @click="dontShowAgain">
        Do not show again
      </b-button>
      <b-button v-else expanded @click="close">
        Nevermind
      </b-button>
    </template>

  </modal-template>
</template>

<script>

import {copyText} from '../../services/fileUtils';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import ModalImageAsset from '../asset-renders/ModalImageAsset';
import ModalTemplate from './ModalTemplate';

export default {
  components: {ModalImageAsset, ArtworkNameAndArtist, ModalTemplate},
  props: ['profile', 'token', 'title', 'subtitle', 'listTokenFlow'],
  data() {
    return {}
  },
  computed: {
    shareTokenMessage() {
      return `${this.token.metadata.name} by ${this.token.metadata.artist}
      \n\nNow available for ${!this.token.isListed ? 'offers' : 'Ξ ' + this.token.listPrice}
      \n\nhttps://knownorigin.io/tokens/${this.token.id}`;
    }
  },
  methods: {
    copyURLWithToast() {
      copyText(`https://knownorigin.io/tokens/${this.token.id}`);
      this.$buefy.toast.open({message: `<span class="is-family-tertiary">Share link copied</span>`});
      this.close();
    },
    copyInstaWithToast() {
      copyText(`${this.shareTokenMessage}\n\n@KnownOrigin.io\n\n${(this.profile && this.profile.instagram) ? 'Art by @' + this.profile.instagram : ''}`);
      this.$buefy.toast.open('Instagram info copied');
      this.close();
    },
    copyTwitterWithToast() {
      copyText(`${this.shareTokenMessage}\n\n@KnownOrigin_io\n\n${(this.profile && this.profile.twitter) ? 'Art by @' + this.profile.twitter : ''}`);
      this.$buefy.toast.open('Twitter info copied');
      this.close();
    },
    close() {
      this.$emit('close');
    },
    dontShowAgain() {
      this.$nuxt.$store.dispatch('userStore/dismissShareTokenPromptModal')
        .finally(() => {
          this.close();
        });
    }
  }
};
</script>
