<template>
  <div v-if="!profileCollection.private || (profileCollection.private && isLoggedInAccountViewingPage && hasAuthToken)">
    <div class="level level-is-shrinkable  mb-0">
      <div class="level-left">
        <div class="level-item">
          <h1 class="is-family-secondary mb-0 is-block is-size-2-mobile">{{ profileCollection.name }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-tag type="is-dark" class="mr-4 is-uppercase" v-if="profileCollection.private">PRIVATE</b-tag>
          <b-tag type="is-light" class="mr-4 is-uppercase"
                 v-else-if="!profileCollection.private && isLoggedInAccountViewingPage && hasAuthToken">PUBLIC
          </b-tag>

          <b-dropdown aria-role="list" v-if="!profileCollection.private" position="is-bottom-left">
            <b-icon icon="share-variant" slot="trigger">Share</b-icon>
            <b-dropdown-item aria-role="listitem" ref="urlLink"
                             @click="copyLinkWithToast(profileCollection.slug)">
              Copy share link
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ref="instaLink"
                             @click="copyInstaWithToast(profileCollection.slug, profileCollection.name)">
              Copy for Instagram share
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ref="twitterLink"
                             @click="copyTwitterWithToast(profileCollection.slug, profileCollection.name)">
              Copy for Twitter share
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown aria-role="list" position="is-bottom-left">
            <b-icon icon="dots-horizontal" slot="trigger">Share</b-icon>
            <b-dropdown-item aria-role="viewcollection" @click="viewCollection" ref="viewCollection"
                             v-if="!profileCollection.private">
              View full collection
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="editCollection(profileCollection.id)"
                             ref="edit"
                             v-if="isLoggedInAccountViewingPage">
              Edit collection
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="pinCollection(profileCollection.id, !isCollectionPinned)"
                             ref="edit"
                             v-if="isLoggedInAccountViewingPage && !profileCollection.private">
              {{isCollectionPinned ? 'Unpin collection from the top' : 'Pin collection to top'}}
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="deleteCollection(profileCollection.id)"
                             ref="delete"
                             class="has-text-danger"
                             v-if="isLoggedInAccountViewingPage">
              Delete collection
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <p class="is-family-primary mb-6" v-if="isLoggedInAccountViewingPage && hasAuthToken">
      Last updated {{ new Date(profileCollection.lastUpdated) | moment('from') }}
    </p>
    <p class="is-family-primary mb-6 mt-2" v-else>
      <span v-for="(line, index) in (profileCollection.description || '').slice(0, descLength).split('\n')"
            :key="index">
        {{ line }}
      </span>
      <span
        class="is-clickable heading"
        @click="moreDesc"
        v-if="descLength === 150 && (profileCollection.description || '').length > 150">
        Expand Description <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
      </span>
    </p>

    <div class="columns is-multiline" v-if="tokens && tokens.length > 0">
      <div class="column is-one-third" v-for="(token, $index) in tokensInCollectionsOrderSlice" :key="$index">
        <token-card
          :token="token"
          :cover="true"
          :address="address"
          resolution="card"
          class="has-margin-bottom-4"
          @tokens-by-owner-refresh="refreshTokensWithInclude"
        >
        </token-card>
      </div>
    </div>

    <div class="has-text-centered" v-if="profile">
      <n-link :to="{name: 'collections-slug-id', params: { slug: profile.slug, id: profileCollection.slug}}"
              class="button is-primary is-outlined">
        View full collection
      </n-link>
    </div>

    <hr class="mt-6 mb-5" />

  </div>
</template>

<script>
  import _get from 'lodash/get';
  import _find from 'lodash/find';
  import _isUndefined from 'lodash/isUndefined';
  import _reject from 'lodash/reject';
  import {mapGetters, mapState} from 'vuex';
  import editionUtils from '../../services/editionUtils';
  import {copyText} from '../../services/fileUtils';
  import {getTweet, getInsta} from '../../services/socials';
  import {TOKENS_WITH_INCLUDE} from '../../queries/tokenQueries';
  import TokenCard from '../TokenCard';

  export default {
    components: {TokenCard},
    props: ['profileCollection', 'isLoggedInAccountViewingPage', 'address'],
    data: () => ({
      profile: null,
      descLength: 150,
      tokens: []
    }),
    computed: {
      ...mapState('web3Store', [
        'gqlClient'
      ]),
      ...mapGetters('web3Store', [
        'hasAuthToken'
      ]),
      tokensInCollectionsOrderSlice() {
        if (this.tokens) {
          // default 1 row if not found
          const frontPageRows = _get(this.profileCollection, 'frontPageRows', 1);
          // show 3 if 1 row, or 6 for all others
          const numberToShow = frontPageRows === 1 ? 3 : 6;
          const results = this.profileCollection.items
            .slice(0, numberToShow)
            .map(tokenId => _find(this.tokens, token => token.id === tokenId));
          return _reject(results, _isUndefined);
        }
        return [];
      },
      isCollectionPinned() {
        return this.profileCollection.position !== 1
      }
    },
    async mounted() {
      this.$apollo.addSmartQuery('tokens', {
        client: this.gqlClient,
        fetchPolicy: 'cache-and-network',
        query() {
          return TOKENS_WITH_INCLUDE([], false);
        },
        variables() {
          return this.buildTokenQueryParams();
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });

      this.profile = await this.$store.dispatch('userStore/getUserBySlugOrAddress', this.address);
    },
    methods: {
      async pinCollection(id, pin) {
        await this.runAuthedFunction(async () => {
          try {
            await this.$store.dispatch('profileCollectionsStore/pinProfileCollection', {id: id, pin: pin});
            this.$buefy.toast.open(`Collection ${pin ? '' : 'un'}pinned`);

            this.$emit('refresh-collection');
          } catch (e) {
            // something went wrong
          }
        });
      },
      async deleteCollection(id) {
        await this.runAuthedFunction(async () => {
          try {
            await this.$store.dispatch('profileCollectionsStore/deleteProfileCollection', id);
            this.$buefy.toast.open('Collection deleted');

            this.$emit('refresh-collection');
          } catch (e) {
            // something went wrong
          }
      });
      },
      async editCollection(id) {
        await this.runAuthedFunction(() => {
          this.$emit('edit-token-collection', id);
        });
      },
      moreDesc() {
        this.descLength = 5001;
      },
      viewCollection() {
        this.$router.push({
          name: 'collections-slug-id',
          params: {slug: this.profile.slug, id: this.profileCollection.slug}
        });
      },
      copyLinkWithToast(profileCollectionSlug) {
        const builtSlugUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
        copyText(builtSlugUrl);
        this.$buefy.toast.open('Share link copied');
      },
      copyInstaWithToast(profileCollectionSlug, collectionName) {
        const shareUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
        copyText(getInsta('COLLECTION', this.profile, shareUrl, collectionName));
        this.$buefy.toast.open('Instagram collection info copied');
      },
      copyTwitterWithToast(profileCollectionSlug, collectionName) {
        const shareUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
        copyText(getTweet('COLLECTION', this.profile, shareUrl, collectionName));
        this.$buefy.toast.open('Twitter collection info copied');
      },
      buildCollectionsSlug(profileSlug, profileCollectionSlug) {
        return `https://knownorigin.io/collections/${profileSlug}/${profileCollectionSlug}`;
      },
      buildTokenQueryParams(overrides = {}) {
        return {
          owner: this.profile && this.profile.address && this.profile.address.toLowerCase(),
          first: 999,
          skip: 0,
          ...overrides
        };
      },
      availability(edition) {
        return editionUtils.availability(edition);
      },
      refreshTokensWithInclude() {
        this.$apollo.queries.tokens.refetch(this.buildTokenQueryParams());
        this.$apollo.queries.tokens.startPolling(10000);
      },
      async runAuthedFunction(_function) {
      if (!this.hasAuthToken) {
        await this.$store.dispatch('web3Store/createJwtSession');
      }
      if (this.hasAuthToken) {
        await _function()
      }
    }
    }
  };
</script>
