<template>
  <aside :class="[{ hoverState: isHovered }]" class="media is-mobile mb-5">
    <figure
      class="media-left"
      v-if="artistAccount && imageVisible"
      @mouseenter="hovered(true)"
      @mouseleave="hovered(false)"
    >
      <n-link
        :to="{
          name: 'profile-id',
          params: { id: artistAccount.toLowerCase() },
        }"
      >
        <p
          class="image is-square is-64x64"
          :class="[{ 'is-128x128': isLarge }, { 'rounded-image': smallText }]"
          v-lazy-container="{ selector: 'img' }"
        >
          <img
            class="is-rounded"
            :id="artistAccount"
            :data-src="`${$userMediaApi.defaults.baseURL}/media/user/profile/${artistAccount}`"
            :data-error="createAccountIcon"
          />
        </p>
      </n-link>
    </figure>
    <div class="media-content artist-name-container">
      <div class="content" v-if="artistAccount">
        <h2
          v-if="resolvedProfile && resolvedProfile.username"
          :class="
            smallText
              ? 'is-size-6 is-size-6-mobile is-size-6-tablet m-0'
              : 'is-size-4-mobile is-size-4-tablet ml-2 mb-0'
          "
        >
          <slot name="artist-profile-header" />

          <n-link
            :to="{
              name: 'profile-id',
              params: { id: artistAccount.toLowerCase() },
            }"
            data-cy="artist_name"
            class="artist-name-link"
            @mouseenter.native="hovered(true)"
            @mouseleave.native="hovered(false)"
          >
            {{ resolvedProfile.username | truncate(20) }}
          </n-link>
          <b-icon
            icon="check-decagram"
            size="is-small"
            class="is-size-5 is-size-6-mobile is-verified"
            v-if="isEitherProfileVerified(resolvedProfile)"
          >
          </b-icon>
          <br />
          <slot v-if="!removeSubtitle" name="artist-profile-subtitle">
            <a
              v-if="resolvedProfile && resolvedProfile.twitter"
              :href="`https://twitter.com/${cleanUpUrl(
                resolvedProfile.twitter
              )}`"
              target="_blank"
              class="is-size-5 is-size-6-mobile"
            >
              <b-icon icon="twitter" size="is-small"></b-icon>
              <span>@{{ cleanUpUrl(resolvedProfile.twitter) }}</span>
            </a>
            <a
              v-else-if="resolvedProfile && resolvedProfile.instagram"
              :href="`https://instagram.com/${cleanUpUrl(
                resolvedProfile.instagram
              )}`"
              target="_blank"
              class="is-size-5 is-size-5-mobile"
            >
              <b-icon icon="instagram" size="is-small"></b-icon>
              <span>@{{ cleanUpUrl(resolvedProfile.instagram) }}</span>
            </a>
          </slot>
        </h2>
        <h2
          v-else
          :class="smallText ? 'is-size-6 m-0' : 'is-size-6-mobile ml-2'"
        >
          <slot name="artist-profile-header" />

          <n-link
            :to="{
              name: 'profile-id',
              params: { id: artistAccount.toLowerCase() },
            }"
            data-cy="artist_name_mobile"
            class="artist-name-link"
            @mouseenter.native="hovered(true)"
            @mouseleave.native="hovered(false)"
          >
            {{ nameOrShortEth }}
          </n-link>
          <slot name="artist-profile-subtitle"> </slot>
        </h2>
      </div>
    </div>
  </aside>
</template>

<script>
import { toDataUrl } from 'myetherwallet-blockies';
import { mapGetters, mapState } from 'vuex';
import { cleanUpUrl } from '../services/utils';

export default {
  props: [
    'profile',
    'artistAccount',
    'metadataArtist',
    'large',
    'isTable',
    'noSubtitle',
    'noImage'
  ],
  data: () => ({
    resolvedProfile: null,
    isHovered: false,
    isLarge: false,
    smallText: false,
    removeSubtitle: false,
    imageVisible: true
  }),
  computed: {
    ...mapState('web3Store', ['chainId']),
    ...mapGetters('userStore', ['isEitherProfileVerified']),
    createAccountIcon() {
      return toDataUrl(this.artistAccount);
    },
    nameOrShortEth() {
      if (this.artistAccount) {
        return this.$options.filters.shortEth(this.artistAccount);
      }
      if (this.metadataArtist && this.metadataArtist.startsWith('0x')) {
        return this.$options.filters.shortEth(this.metadataArtist);
      }
      return this.metadataArtist;
    }
  },
  watch: {
    artistAccount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(async () => {
          this.profile = await this.$store.dispatch(
            'userStore/getUser',
            this.artistAccount
          );
          this.$forceUpdate();
        });
      }
    }
  },
  async mounted() {
    if (!this.profile) {
      this.resolvedProfile = await this.$store.dispatch(
        'userStore/getUser',
        this.artistAccount
      );
    } else {
      this.resolvedProfile = this.profile;
    }
    this.largeImg();
    this.setSmallText();
    this.setSubtitle();
    this.setImageVisible();
  },
  methods: {
    cleanUpUrl,
    hovered(isHovered) {
      if (isHovered) {
        this.isHovered = true;
      } else {
        this.isHovered = false;
      }
    },
    largeImg() {
      if (this.large) {
        this.isLarge = true;
      } else {
        this.isLarge = false;
      }
    },
    setSmallText() {
      if (this.isTable) {
        this.smallText = true;
      } else {
        this.smallText = false;
      }
    },
    setSubtitle() {
      if (this.noSubtitle) {
        this.removeSubtitle = true;
      } else {
        this.removeSubtitle = false;
      }
    },
    setImageVisible() {
      if (this.noImage) {
        this.imageVisible = false;
      } else {
        this.imageVisible = true;
      }
    }
  }
};
</script>

<style scoped>
.artist-name-link {
  max-width: calc(100% - 26px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon.is-verified {
  width: 20px;
  height: 25px;
  margin-left: 6px;
}

.image,
.artist-name-link,
.artist-name-link + span {
  transition: transform 0.2s ease;
  display: inline-block;
  vertical-align: middle;
}

.artist-name-container {
  margin: auto 0;
}
.hoverState .image {
  transform: scale(1.1);
}
.hoverState .artist-name-link {
  transform: translateX(4px);
}
.hoverState .artist-name-link + span {
  transform: translateX(4px);
}
</style>
