<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">
      <div class="card-content has-padding-7">
        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <p class="title is-3">
              Delete your profile?
            </p>
          </section>
          <section class="mb-4 content notification">
            <dl>
              <li>
                This action <span class="has-text-weight-semibold">cannot</span> be undone
              </li>
              <li>
                All off-chain profile data will be removed
              </li>
            </dl>
          </section>
        </div>

        <div class="card-footer">
          <div class="card-footer-item">
            <b-button expanded @click="close" :disabled="saving">
              Nevermind
            </b-button>
          </div>
          <div class="card-footer-item">
            <b-button class="button is-danger" expanded @click="deleteAccount" :disabled="saving">
              Delete
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: ['account'],
  data() {
    return {
      saving: false
    }
  },
  methods: {
    deleteAccount() {
      this.saving = true;
      this.$store.dispatch('userStore/deleteProfile')
        .then(() => {
          this.$emit('close', true);
        })
        .finally(() => {
          this.saving = false;
        })
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
