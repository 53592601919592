<template>
  <div class="level is-mobile box is-family-tertiary">
    <div class="level-item has-text-centered">
      <n-link class="is-clickable" :to="{name:'gallery-id', params: { id: token.editionNumber}}">
        <p class="heading">Edition #</p>
        <p class="title">#{{ token.editionNumber }}</p>
      </n-link>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Token ID</p>
        <p class="title">#{{ token.id }}</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Issue</p>
        <p class="title">
          <token-id-of-x :id="token.id"
                         :edition-number="token.editionNumber"
                         :version="token.version"
                         :edition-total-available="token.edition.totalAvailable">
          </token-id-of-x>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import TokenIdOfX from '../TokenIdOfX';

export default {
  components: {
    TokenIdOfX
  },
  props: ['token']
}
</script>
