var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.parentTokenDetails && _vm.parentCollectionDetails
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "level is-mobile",
              class: { "box has-background-dark has-text-light": _vm.darkmode },
            },
            [
              _c(
                "div",
                { staticClass: "columns is-vcentered" },
                [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", [
                      _vm.parentTokenDetails.image_url
                        ? _c("img", {
                            staticClass: "thumbnail-image-cover",
                            attrs: {
                              src: _vm.parentTokenDetails.image_url,
                              alt: _vm.parentTokenDetails.token_id,
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._t("token-information", function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-6 is-family-primary",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.truncateName(
                                      _vm.parentTokenDetails.name,
                                      35
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-muted is-size-6 is-family-primary mt-1 mb-2",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.truncateName(
                                      _vm.parentTokenDetails.description,
                                      28
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._t("external-link", function () {
                            return [
                              _c("div", { staticClass: "level is-size-7" }, [
                                _c("div", { staticClass: "level-left" }, [
                                  _vm.isKOCollection(
                                    _vm.parentCollectionDetails.address
                                  )
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "image is-16x16 mr-1",
                                          attrs: {
                                            src: "/favicon.ico",
                                            alt: _vm.parentTokenDetails.name,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            class: {
                                              "has-text-light": _vm.darkmode,
                                            },
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.knownOriginTokenLink(
                                                _vm.parentTokenDetails.token_id,
                                                _vm.parentTokenDetails.linkType
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "open-in-new",
                                                size: "is-small",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                      View on KnownOrigin\n                    "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "image is-16x16 mr-1",
                                      attrs: {
                                        src: _vm.parentCollectionDetails
                                          .image_url
                                          ? _vm.parentCollectionDetails
                                              .image_url
                                          : "/opensea.png",
                                        alt: _vm.parentTokenDetails.name,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "has-text-light": _vm.darkmode,
                                        },
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.openSeaTokenLink(
                                            _vm.parentCollectionDetails.address,
                                            _vm.parentTokenDetails.token_id
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "open-in-new",
                                            size: "is-small",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                      View on OpenSea\n                    "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("right-side-element"),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.childTokenDetails && _vm.childCollectionDetails
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "level is-mobile",
              class: { "box has-background-dark has-text-light": _vm.darkmode },
            },
            [
              _c(
                "div",
                { staticClass: "columns is-vcentered" },
                [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c("div", [
                      _vm.childTokenDetails.image_url
                        ? _c("img", {
                            staticClass: "thumbnail-image-cover",
                            attrs: {
                              src: _vm.childTokenDetails.image_url,
                              alt: _vm.childTokenDetails.token_id,
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._t("token-information", function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "has-text-weight-medium is-size-6 is-family-primary",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.truncateName(
                                      _vm.childTokenDetails.name,
                                      35
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "is-muted is-size-6 is-family-primary mt-1 mb-2",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.truncateName(
                                      _vm.childTokenDetails.description,
                                      28
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._t("external-link", function () {
                            return [
                              _c("div", { staticClass: "level is-size-7" }, [
                                _c("div", { staticClass: "level-left" }, [
                                  _vm.isKOCollection(
                                    _vm.childCollectionDetails.address
                                  )
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "image is-16x16 mr-1",
                                          attrs: {
                                            src: "/favicon.ico",
                                            alt: _vm.childTokenDetails.name,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            class: {
                                              "has-text-light": _vm.darkmode,
                                            },
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.knownOriginTokenLink(
                                                _vm.childTokenDetails.token_id,
                                                _vm.childTokenDetails.linkType
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "open-in-new",
                                                size: "is-small",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                      View on KnownOrigin\n                    "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "image is-16x16 mr-1",
                                      attrs: {
                                        src: _vm.childCollectionDetails
                                          .image_url
                                          ? _vm.childCollectionDetails.image_url
                                          : "/opensea.png",
                                        alt: _vm.childTokenDetails.name,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "has-text-light": _vm.darkmode,
                                        },
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.openSeaTokenLink(
                                            _vm.childCollectionDetails.address,
                                            _vm.childTokenDetails.token_id
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "open-in-new",
                                            size: "is-small",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                      View on OpenSea\n                    "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("right-side-element"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }