<template>
  <div v-if="!profileCollection.private || (profileCollection.private && isLoggedInAccountViewingPage && hasAuthToken)">
    <div class="level mb-0  level-is-shrinkable">
      <div class="level-left">
        <div class="level-item">
          <h1 class="is-family-secondary mb-0 is-block is-size-3-mobile">{{ profileCollection.name }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-tag type="is-dark" class="mr-4 is-uppercase" v-if="profileCollection.private">PRIVATE</b-tag>
          <b-tag type="is-light" class="mr-4 is-uppercase" v-else-if="!profileCollection.private && isLoggedInAccountViewingPage && hasAuthToken">PUBLIC</b-tag>

          <b-dropdown aria-role="list" v-if="!profileCollection.private" position="is-bottom-left">
            <b-icon icon="share-variant" slot="trigger">Share</b-icon>
            <b-dropdown-item aria-role="listitem" ref="urlLink"
                             @click="copyLinkWithToast(profileCollection.slug)"
                             v-if="!profileCollection.private">
              Copy share link
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ref="instaLink"
                             @click="copyInstaWithToast(profileCollection.slug, profileCollection.name)"
                             v-if="!profileCollection.private">
              Copy for Instagram share
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" ref="twitterLink"
                             @click="copyTwitterWithToast(profileCollection.slug, profileCollection.name)"
                             v-if="!profileCollection.private">
              Copy for Twitter share
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown aria-role="list" position="is-bottom-left">
            <b-icon icon="dots-horizontal" slot="trigger">Share</b-icon>
            <b-dropdown-item aria-role="listitem" @click="viewCollection" ref="viewCollection" v-if="!profileCollection.private">
              View full collection
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="editCollection(profileCollection.id)"
                             ref="edit"
                             v-if="isLoggedInAccountViewingPage">
              Edit collection
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="pinCollection(profileCollection.id, !isCollectionPinned)"
                             ref="edit"
                             v-if="isLoggedInAccountViewingPage && !profileCollection.private">
              {{isCollectionPinned ? 'Unpin collection from the top' : 'Pin collection to top'}}
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
                             @click="deleteCollection(profileCollection.id)"
                             ref="delete"
                             class="has-text-danger"
                             v-if="isLoggedInAccountViewingPage">
              Delete collection
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <p class="is-family-primary mb-6" v-if="isLoggedInAccountViewingPage && hasAuthToken">Last updated {{ new Date(profileCollection.lastUpdated) | moment('from') }}</p>
    <p class="is-family-primary mb-6 mt-2" v-else>
      <span v-for="(line, index) in (profileCollection.description || '').slice(0, descLength).split('\n')" :key="index">{{ line }}</span>
      <span class="is-clickable heading" @click="moreDesc" v-if="descLength === 150 && (profileCollection.description || '').length > 150">
        Expand Description <b-icon icon="arrow-expand-down" size="is-small"></b-icon>
      </span>
    </p>

    <div class="columns is-multiline" v-if="editions && editions.length > 0">
      <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card" v-for="(edition, $index) in editionsInCollectionsOrderSlice" :key="$index">
        <edition-card
          :edition="edition"
          :cover="true"
          :address="address"
          resolution="card"
          class="has-margin-bottom-4">
        </edition-card>
      </div>
    </div>

    <div class="has-text-centered" v-if="profile">
      <n-link :to="{name: 'collections-slug-id', params: { slug: profileSlug, id: profileCollection.slug}}"
              class="button is-primary is-outlined">
        View full collection
      </n-link>
    </div>

    <hr class="mt-6 mb-5" />

  </div>
</template>

<script>
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import _reject from 'lodash/reject';
import {mapGetters, mapState} from 'vuex';
import {copyText} from '../../services/fileUtils';
import {getTweet, getInsta} from '../../services/socials';
import EditionCard from '../EditionCard';
import {isOffersOnlySalesType} from '../../services/SaleTypes';
import ConfirmModal from '../modal/ConfirmModal';

export default {
  components: {EditionCard},
  props: ['profileCollection', 'isLoggedInAccountViewingPage', 'profileSlug', 'address', 'editions'],
  data: () => ({
    profile: null,
    descLength: 150
  }),
  computed: {
    ...mapState('web3Store', [
      'gqlClient'
    ]),
    ...mapGetters('web3Store', [
      'hasAuthToken'
    ]),
    editionsInCollectionsOrderSlice() {
      if (this.editions) {
        // default 1 row if not found
        const frontPageRows = _get(this.profileCollection, 'frontPageRows', 1);
        // show 3 if 1 row, or 6 for all others
        const numberToShow = frontPageRows === 1 ? 3 : 6;
        const results = this.profileCollection.items
            .slice(0, numberToShow)
            .map(editionId => _find(this.editions, edition => edition.id === editionId));
        return _reject(results, _isUndefined);
      }
      return [];
    },
    isCollectionPinned() {
      return this.profileCollection.position !== 1
    }
  },
  async mounted() {
    this.profile = await this.$store.dispatch('userStore/getUserBySlugOrAddress', this.profileSlug);
  },
  methods: {
    async pinCollection(id, pin) {
        await this.runAuthedFunction(async () => {
          try {
            await this.$store.dispatch('profileCollectionsStore/pinProfileCollection', {id: id, pin: pin});
            this.$buefy.toast.open(`Collection ${pin ? '' : 'un'}pinned`);

            this.$emit('refresh-collection');
          } catch (e) {
            // something went wrong
          }
        });
      },
    async deleteCollection(id) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        width: 600,
        props: {
          title: 'Delete collection',
          description: `You are deleting collection ${this.profileCollection.name} from your profile`,
          actionButton: 'Delete'
        },
        events: {
          performAction: async () => {
            await this.runAuthedFunction(async () => {
              try {
                await this.$store.dispatch('profileCollectionsStore/deleteProfileCollection', id);
                this.$buefy.toast.open('Collection deleted');
                this.$emit('refresh-collection');
              } catch (e) {
                this.$buefy.toast.open('Something went wrong');
              }
            });
          }
        }
      });
    },
    async editCollection(id) {
      await this.runAuthedFunction(() => {
        this.$emit('edit-collection', id);
      });
    },
    moreDesc() {
      this.descLength = 5001;
    },
    viewCollection() {
      this.$router.push({name: 'collections-slug-id', params: { slug: this.profileSlug, id: this.profileCollection.slug}})
    },
    copyLinkWithToast(profileCollectionSlug) {
      const builtSlugUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
      copyText(builtSlugUrl);
      this.$buefy.toast.open('Share link copied');
    },
    copyInstaWithToast(profileCollectionSlug, collectionName) {
      const shareUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
      copyText(getInsta('COLLECTION', this.profile, shareUrl, collectionName));
      this.$buefy.toast.open('Instagram collection info copied');
    },
    copyTwitterWithToast(profileCollectionSlug, collectionName) {
      const shareUrl = this.buildCollectionsSlug(this.profile.slug, profileCollectionSlug);
      copyText(getTweet('COLLECTION', this.profile, shareUrl, collectionName));
      this.$buefy.toast.open('Twitter collection info copied');
    },
    buildCollectionsSlug(profileSlug, profileCollectionSlug) {
      return `https://knownorigin.io/collections/${profileSlug}/${profileCollectionSlug}`;
    },
    availability(edition) {
      return parseInt(edition.totalAvailable) - parseInt(edition.totalSupply);
    },
    async runAuthedFunction(_function) {
      if (!this.hasAuthToken) {
        await this.$store.dispatch('web3Store/createJwtSession');
      }
      if (this.hasAuthToken) {
        await _function()
      }
    },
    isOffersOnlySalesType
  }
};
</script>
