var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.metadata
    ? _c("div", [
        _c("div", { staticClass: "tile is-ancestor" }, [
          _c("div", { staticClass: "tile is-parent is-vertical is-12" }, [
            _c(
              "div",
              {
                staticClass: "tile is-child",
                staticStyle: { "margin-bottom": "-0.5rem !important" },
              },
              [
                _c("edition-creators", {
                  attrs: {
                    "edition-id": _vm.editionNumber,
                    "artist-account": _vm.artistAccount || _vm.metadata.artist,
                    collaborators: _vm.collaborators,
                    "show-edition-button": _vm.showEditionButton,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tile" }, [
              _c("div", { staticClass: "tile is-parent is-vertical is-8" }, [
                _c("div", { staticClass: "tile is-child" }, [
                  _c("div", { staticClass: "tile is-parent is-vertical" }, [
                    _c(
                      "div",
                      { staticClass: "tile is-child" },
                      [
                        _vm._t("single-asset-image", function () {
                          return [
                            _c("image-asset", {
                              attrs: {
                                metadata: _vm.metadata,
                                "edition-number": _vm.editionNumber,
                                cover: false,
                                "enable-magnify": true,
                                "is-cc": _vm.editionCreatorContract,
                                resolution: "default",
                              },
                            }),
                          ]
                        }),
                        _vm._v(" "),
                        _vm._t("single-asset-sales-table"),
                        _vm._v(" "),
                        _vm._t("single-asset-image-below"),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tile is-parent is-vertical" }, [
                _c("div", { staticClass: "tile is-child" }, [
                  _c("div", { staticClass: "cc" }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        { staticClass: "column is-three-quarters ml-3" },
                        [
                          _vm.editionCreatorContract
                            ? _vm._t(
                                "single-asset-creator-contract",
                                function () {
                                  return [
                                    _c(
                                      "article",
                                      [
                                        _c("creator-contract-showcase", {
                                          staticClass: "mb-5 ml-3",
                                          attrs: {
                                            address: _vm.editionCreatorContract,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.marketplacesBlocked
                                      ? _c(
                                          "b-tag",
                                          {
                                            staticClass:
                                              "is-dark is-uppercase hand-pointer is-family-tertiary",
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.openBlockerModal.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "is-underlined-hover",
                                              },
                                              [_vm._v("Protected marketplaces")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tile is-parent is-vertical" },
                    [
                      _c(
                        "article",
                        { staticClass: "tile is-child content" },
                        [
                          _vm._t("single-asset-name", function () {
                            return [
                              _c(
                                "h1",
                                {
                                  staticClass:
                                    "is-size-3-mobile artwork-title is-uppercase",
                                  staticStyle: {
                                    "overflow-wrap": "anywhere",
                                    "max-width": "400px",
                                    overflow: "hidden",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.metadata.name)
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._t("single-asset-primary-info"),
                      _vm._v(" "),
                      _vm._t("single-asset-primary-cta"),
                      _vm._v(" "),
                      _vm._t("purchasing"),
                      _vm._v(" "),
                      _vm.owner && !_vm.childOfEnhancedNFT
                        ? _vm._t("single-asset-owner", function () {
                            return [
                              _c(
                                "article",
                                { staticClass: "tile is-child" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "has-text-weight-bold title is-family-tertiary",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Owned by\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("user-profile-lookup", {
                                    attrs: {
                                      "artist-account": _vm.owner.address,
                                      "metadata-artist": _vm.owner.address,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "article",
                        { staticClass: "tile is-child content" },
                        [
                          _vm._t("single-asset-description", function () {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "title is-family-tertiary has-text-weight-bold",
                                },
                                [_vm._v("Description")]
                              ),
                              _vm._v(" "),
                              _c("asset-description", {
                                attrs: {
                                  description: _vm.metadata.description,
                                },
                              }),
                              _vm._v(" "),
                              _vm._t("single-asset-badges", function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "columns" },
                                    [
                                      _vm.hasUnlockableContent(
                                        _vm.editionNumber
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "column" },
                                            [
                                              _c("unlockable-edition", {
                                                attrs: {
                                                  "edition-number":
                                                    _vm.editionNumber,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.collaborators &&
                                      _vm.collaborators.length > 1
                                        ? _c(
                                            "div",
                                            { staticClass: "column" },
                                            [
                                              _c("collaboration-badge", {
                                                attrs: {
                                                  "edition-id":
                                                    _vm.editionNumber,
                                                  "artist-account":
                                                    _vm.artistAccount,
                                                  collaborators:
                                                    _vm.collaborators,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._t("single-asset-enhanced-badge"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "column" },
                                        [
                                          _c("content-auth-tag", {
                                            attrs: {
                                              "edition-id": _vm.editionNumber,
                                              "image-url": _vm.metadata.image,
                                              minter: _vm.artistAccount,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "article",
                        { staticClass: "tile is-child is-family-tertiary" },
                        [
                          _c("div", { staticClass: "level is-mobile" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c(
                                "div",
                                { staticClass: "level-item" },
                                [
                                  _vm._t("single-asset-share", function () {
                                    return [
                                      _c(
                                        "b-dropdown",
                                        { attrs: { "aria-role": "list" } },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              attrs: {
                                                slot: "trigger",
                                                icon: "share-variant",
                                              },
                                              slot: "trigger",
                                            },
                                            [_vm._v("Share")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              ref: "urlLink",
                                              attrs: {
                                                "aria-role": "listitem",
                                              },
                                              on: {
                                                click: _vm.copyURLWithToast,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Copy share\n                            link\n                          "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              ref: "instaLink",
                                              attrs: {
                                                "aria-role": "listitem",
                                              },
                                              on: {
                                                click: _vm.copyInstaWithToast,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Copy for\n                            Instagram share\n                          "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              ref: "instaLink",
                                              attrs: {
                                                "aria-role": "listitem",
                                              },
                                              on: {
                                                click: _vm.copyTwitterWithToast,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Copy\n                            for\n                            Twitter share\n                          "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "level-right" }, [
                              _c(
                                "div",
                                { staticClass: "level-item" },
                                [_vm._t("single-asset-like")],
                                2
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tile is-child" },
                        [
                          _vm._t("single-asset-stats-edition"),
                          _vm._v(" "),
                          _vm._t("single-asset-curation-badge"),
                          _vm._v(" "),
                          _vm._t("single-asset-stats-enhanced-nft"),
                          _vm._v(" "),
                          _vm._t("single-asset-stats-enhanced-token"),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "article",
                        { staticClass: "tile is-child" },
                        [
                          _vm._t("single-asset-tags", function () {
                            return [
                              _c(
                                "b-taglist",
                                { staticClass: "pt-4" },
                                [
                                  _vm._l(_vm.tags, function (tag, $index) {
                                    return _vm.tags
                                      ? _c(
                                          "b-tag",
                                          {
                                            key: $index,
                                            staticClass:
                                              "is-lowercase short-tag",
                                            attrs: { type: "is-secondary" },
                                          },
                                          [
                                            _c(
                                              "n-link",
                                              {
                                                staticClass: "has-tag-link",
                                                attrs: {
                                                  to: {
                                                    name: "marketplace",
                                                    query: {
                                                      [`tag_${tag.replace(
                                                        / /g,
                                                        "_"
                                                      )}`]: true,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        #" +
                                                    _vm._s(
                                                      _vm._f("truncate")(
                                                        tag.replace(/\s+/g, ""),
                                                        50
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _vm.format
                                    ? _c(
                                        "b-tag",
                                        {
                                          staticClass: "is-lowercase short-tag",
                                          attrs: { type: "is-secondary" },
                                        },
                                        [
                                          _c(
                                            "n-link",
                                            {
                                              staticClass: "has-tag-link",
                                              attrs: {
                                                to: {
                                                  name: "marketplace",
                                                  query: {
                                                    [`format_${_vm.format
                                                      .replace(/ /g, "_")
                                                      .toLowerCase()}`]:
                                                      _vm.format,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        #" +
                                                  _vm._s(_vm.metadata.format) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.tagsLength === 25 &&
                              _vm.metadata &&
                              _vm.metadata.tags &&
                              _vm.metadata.tags.length > 25
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "heading is-clickable is-uppercase",
                                      on: { click: _vm.moreTags },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    MORE TAGS "
                                      ),
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "arrow-expand-down",
                                          size: "is-small",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "article",
                        { staticClass: "tile is-child" },
                        [
                          _c(
                            "client-only",
                            [
                              _c(
                                "b-tabs",
                                {
                                  attrs: { animated: false },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [_vm._t("single-asset-tab-items")],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }