var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.artistStats
    ? _c("section", [
        parseInt(_vm.artistStats.salesCount) > 0
          ? _c(
              "div",
              { staticClass: "level is-mobile box is-family-tertiary" },
              [
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Sales")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.artistStats.salesCount)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Editions")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.artistStats.editionsCount)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.skinny
                  ? _c("div", { staticClass: "level-item has-text-centered" }, [
                      _c("div", [
                        _c("p", { staticClass: "heading" }, [_vm._v("Total")]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "title" },
                          [
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": _vm.artistStats.totalValueInEth,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            )
          : parseInt(_vm.artistStats.editionsCount) === 1
          ? _c(
              "div",
              { staticClass: "level is-mobile box is-family-tertiary" },
              [
                !_vm.skinny
                  ? _c("div", { staticClass: "level-item has-text-centered" }, [
                      _vm._m(0),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Editions")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.artistStats.editionsCount)),
                    ]),
                  ]),
                ]),
              ]
            )
          : parseInt(_vm.artistStats.editionsCount) === 0
          ? _c(
              "div",
              { staticClass: "level is-mobile box is-family-tertiary" },
              [_vm._m(1)]
            )
          : _c(
              "div",
              { staticClass: "level is-mobile box is-family-tertiary" },
              [
                !_vm.skinny
                  ? _c("div", { staticClass: "level-item has-text-centered" }, [
                      _vm._m(2),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Editions")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.artistStats.editionsCount)),
                    ]),
                  ]),
                ]),
              ]
            ),
      ])
    : _c("section", [_vm._m(3)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "heading" }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("p", { staticClass: "title" }, [_vm._v("Minted Genesis Edition")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "level-item has-text-centered" }, [
      _c("div", [
        _c("p", { staticClass: "heading" }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [_vm._v("Awaiting Genesis Edition")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "heading" }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("p", { staticClass: "title" }, [_vm._v("New Artist")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "level is-mobile box is-family-tertiary" },
      [
        _c("div", { staticClass: "level-item has-text-centered" }, [
          _c("div", [
            _c("p", { staticClass: "heading" }, [_vm._v("Status")]),
            _vm._v(" "),
            _c("p", { staticClass: "title" }, [
              _vm._v("Awaiting Genesis Edition"),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }