<template>
  <header class="header-image has-text-white has-background-grey-darker"
          v-lazy:background-image="`${$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${headerImage}/cover`">
    <section class="hero">
      <div class="hero-body">
        <div class="container content">
          <h2 class="title has-text-white has-margin-top-1 is-size-1">
            <n-link :to="collectionSlug" class="has-text-white has-padding-top-6" v-if="headerImage">
              {{ collection }}
            </n-link>
          </h2>
          <div class="content">
            <div class="field is-grouped">
              <p class="control">
                <n-link :to="collectionSlug" class="button is-white is-outlined">
                  View collection
                </n-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
  export default {
    props: ['collection', 'headerImage', 'collectionSlug']
  };
</script>
