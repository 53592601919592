<template>
  <div class="is-family-tertiary">
      <div v-if="isHallOfFame" class="is-inline-block mr-2">
        <p class="is-size-6 trending-tag">
        <span class="mr-1">🖼</span> Hall of Fame creator
        </p>
      </div>
      <div v-if="isTrendingArtist" class="is-inline-block">
        <p class="is-size-6 trending-tag">
          <span class="mr-1">🎨</span> Trending creator
        </p>
      </div>
      <div v-else-if="isTrendingCollector" class="is-inline-block">
        <p class="is-size-6 trending-tag">
          <span class="mr-1">🍱</span> Trending collector
        </p>
      </div>
  </div>
  </template>
  <script>
  import { mapState } from 'vuex';
  import _filter from 'lodash/filter';
  import _take from 'lodash/take';
  import { utils } from 'ethers';

  export default {
    props: ['profile', 'stats', 'isHallOfFame', 'isCollection'],
    data: () => ({
          isTrending: false,
          isTrendingArtist: false,
          isTrendingCollector: false
    }),
    computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
      async mounted() {
          this.loadTrending();
      },
    methods: {
      loadTrending() {
        this.$store.dispatch('trendingStore/getTrending')
          .then(({artists, collectors}) => {
            const foundInArtists = _filter(_take(artists, 21), {id: utils.getAddress(this.profile.address)}).length > 0;
            const foundInCollectors = _filter(_take(collectors, 21), {id: utils.getAddress(this.profile.address)}).length > 0;
            this.isTrendingArtist = foundInArtists;
            this.isTrendingCollector = foundInCollectors;
          });
      }
    }
  };
  </script>
  <style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

  .trending-tag {
    color: rgba(255, 255, 255, 1);
    padding: 5px 8px;
    background: #292929;
    border-radius: 8px;

    @include touch {
      text-shadow: unset;
      padding: unset;
      background: unset;
      border-radius: unset;
    }
  }
  </style>
