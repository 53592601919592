var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ApolloQuery", {
    attrs: {
      query: require("../queries/editionQueries").EDITION_BY_ID_QUERY,
      variables: { id: _vm.id },
      "client-id": _vm.gqlClient,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ result: { data } }) {
          return [
            data &&
            data.editionById &&
            data.editionById.length > 0 &&
            data.editionById[0] &&
            data.editionById[0].active
              ? _c("edition-card", {
                  staticClass: "has-margin-bottom-4",
                  attrs: {
                    edition: data.editionById[0],
                    cover: _vm.cover,
                    resolution: _vm.resolution,
                    size: _vm.size === "is-large" ? "is-large" : null,
                    address: _vm.address,
                  },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }