var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contentAuthenticityPassed
    ? _c(
        "a",
        { attrs: { href: _vm.generateVerifyLink, target: "_blank" } },
        [
          _c(
            "b-tag",
            { staticClass: "is-uppercase is-dark" },
            [
              _c("b-icon", {
                staticClass: "mr-1",
                attrs: { icon: "check-decagram", size: "is-small" },
              }),
              _vm._v("\n    Content Credentials\n  "),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }