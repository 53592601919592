<template>
  <section class="container section">
    <div class="tile is-child">
      <div class="content is-flex is-justify-content-space-between is-align-items-center is-vcentered">
        <h2 class="column title is-size-3-desktop is-size-5-mobile">
          {{ cmsComponent.title }}
        </h2>
        <div @click="handleViewMoreClick(cmsComponent.viewMoreLink)">
            <n-link
              :to="cmsComponent.viewMoreLink"
              class="is-outlined is-size-6-mobile component-view-more-link other-trending-artists-cta">
              <b-button type="is-secondary is-fullwidth">{{ cmsComponent.viewMoreLabel }}</b-button>
            </n-link>
          </div>
      </div>
    </div>

    <section class="artist1">
      <div class="columns is-multiline has-margin-top-7">
          <div class="top-trending-artists keen-slider">
            <div
              v-for="(artist, i) of topTrendingArtists"
              :key="'trending-artist' + i"
              class="top-trending-artists-slide is-flex keen-slider__slide px-2">
              <trending-artist-single
                :artist="artist"
                class="top-trending-artists-slide-item is-2" />
            </div>
          </div>

      </div>
    </section>
  </section>
</template>

<script>
import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';

export default {
  props: ['cmsComponent'],
  data() {
    return {
      topTrendingArtists: Array(5).fill({ editions: Array(1) }),
      otherTrendingArtists: Array(5),
      slider: null,
      isSliderReady: false
    };
  },
  mounted() {
    // when element is in view, load data once
    this.observer = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        const { artists, editions } = await this.$store.dispatch('trendingStore/getTrending')

        this.topTrendingArtists = artists.slice(0, 5)
        this.setArtistEditions(this.topTrendingArtists, editions)

        this.otherTrendingArtists = artists.slice(3, 8)

        this.slider = new KeenSlider(this.$el.querySelector('.top-trending-artists'), {
          slides: {
            perView: 5
          },
          breakpoints: {
            '(max-width: 768px)': {
              slides: {
                perView: 1
              }
            }
          },
          created: () => {
            this.isSliderReady = true

            setTimeout(() => {
              this.slider.container.addEventListener('wheel', this.eventWheel, {
                passive: false
              })
            }, 100)
          }
        })

        this.observer.unobserve(this.$el)
      }
    })
    this.observer.observe(this.$el)
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    setArtistEditions(artists, editions) {
      for (const artist of artists) {
        // Filter the editions by the artistAcount, to find all trending editions by this artist
        const featuredEditions = editions.find((edition) => {
          return edition?.artistAccount?.toLowerCase() === artist?.id?.toLowerCase()
        })

        artist.editions = [featuredEditions]
      }
    },
    async handleViewMoreClick(destination) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.viewAllClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artist1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]: this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      })
    },
    async handleArtistPillClicked(depth, username) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.pillClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.subject]: username,
          [AMPLITUDE_EVENT_PROPERTIES.subjectType]: 'artist',
          [AMPLITUDE_EVENT_PROPERTIES.depth]: depth,
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artist1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]: this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'profile'
        }
      })
    },
    dispatch(e, name) {
      this.position.x -= e.deltaX
      this.position.y -= e.deltaY
      this.slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: this.position.x,
            y: this.position.y
          }
        })
      )
    },
    wheelStart(e) {
      this.position = {
        x: e.pageX,
        y: e.pageY
      }
      this.dispatch(e, 'ksDragStart')
    },
    wheel(e) {
      this.dispatch(e, 'ksDrag')
    },
    wheelEnd(e) {
      this.dispatch(e, 'ksDragEnd')
    },
    eventWheel(e) {
      if (e.deltaX !== 0) {
        e.preventDefault()
      }
      if (!this.wheelActive) {
        this.wheelStart(e)
        this.wheelActive = true
      }
      this.wheel(e)
      clearTimeout(this.touchTimeout)
      this.touchTimeout = setTimeout(() => {
        this.wheelActive = false
        this.wheelEnd(e)
      }, 50)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/_all";

.artist1 {
  .column:nth-child(n+7) {
    display: none;
  }
}

.top-trending-artists,
.top-trending-artists-slide {
  overflow: visible !important;
}

@media screen and (max-width: 1024px) {
  .top-trending-artists {
    margin: 0 -0.5rem;
  }
}

@include tablet {
  .artist1 {
    .column:nth-child(n+7) {
      display: block;
    }
  }
}
</style>

<style lang="scss">
.top-trending-artists-slide-item,
.top-trending-artists-slide .media-content {
  min-width: 0;
}
</style>
