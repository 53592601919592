<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <template slot="secondary-content">
      <section>
        <b-field :label="'Enter new base price'"
                 :message="`Min. base price is ${minAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   v-model="form.basePriceInEth"
                   type="number"
                   :min="0"
                   :max="100000"
                   step="any">
          </b-input>
        </b-field>
        <b-field :label="'Enter new step price'"
                 :message="`Min. step is ${minAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   v-model="form.stepPriceInEth"
                   type="number"
                   :min="0"
                   :max="100000"
                   step="any">
          </b-input>
        </b-field>
        <div>
          <b-message type="is-info" :closable="false" :duration="0">
            If all the editions of your step sale are sold the total value will be {{ calculateTotalStepValue }} Ξ
          </b-message>
        </div>

      </section>
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                :disabled="disableButton"
                @click="changePrice">
        Change price
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth} from '../../../services/utils';
import {isSteppedSalesType} from '../../../services/SaleTypes';

export default {
  components: {
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition'],
  data() {
    return {
      minAmount: listOrOfferAmountFloorInEth,
      transactionHash: null,
      form: {
        basePriceInEth: null,
        stepPriceInEth: null
      }
    };
  },
  computed: {
    calculateTotalStepValue() {
      if (isNaN(parseFloat(this.form.basePriceInEth)) || isNaN(parseFloat(this.form.stepPriceInEth))) {
        return 0;
      }
      return this.edition.remainingSupply / 2 * (2 * parseFloat(this.form.basePriceInEth) + (this.edition.remainingSupply - 1) * parseFloat(this.form.stepPriceInEth));
    },
    disableButton() {
      if (this.transactionHash) {
        return true;
      }
      return (Number(this.form.basePriceInEth && this.form.stepPriceInEth || 0) < this.minAmount);
    }
  },
  methods: {
    isSteppedSalesType,
    close() {
      this.$emit('close');
    },
    async changePrice() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/changePrice', {
          edition: this.edition.id,
          version: this.edition.version,
          salesType: this.edition.salesType,
          priceInWei: ethers.utils.parseEther(this.form.basePriceInEth).toString(),
          stepPriceInWei: ethers.utils.parseEther(this.form.stepPriceInEth).toString()
        });

        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
