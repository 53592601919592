<template>
  <div v-if="edition && edition.totalSupply > 0" class="mt-6 p-0" id="salesTable">
    <span class="content"><h2>Listings</h2></span>
    <b-table
      v-if="listings && listings.length > 0"
      :data="listings"
      sticky-header
      default-sort="priceInEth"
      default-sort-direction="desc"
      striped
      :mobile-cards="false">
      <b-table-column
        field="id"
        label="ID"
        centered
        sortable
        v-slot="props"
        :visible="$device.isDesktopOrTablet">
        <n-link :to="rerouteToken(props)" v-if="props.row.type === 'Token'">
          <span class="is-clickable">#{{ getEditionId(props.row) }}</span>
        </n-link>
        <span v-else class="is-clickable"> - </span>
      </b-table-column>
      <b-table-column
        field="seller"
        label="Owned By"
        v-slot="props">
        <n-link :to="{name:'profile-id', params: { id: props.row.seller.toLowerCase()}}" class="is-flex is-align-items-center">
          <artist-name-and-image-lookup
            class="is-small small is-inline-block"
            :artist-account="props.row.seller.toLowerCase()"
            :size="'is-small'"
            custom-class="m-0 p-0">
          </artist-name-and-image-lookup>
          <b-tag type="is-dark" class="ml-2" v-if="props.row.seller.toLowerCase() === edition.artistAccount.toLowerCase()">CREATOR</b-tag>
        </n-link>
      </b-table-column>
      <b-table-column
        field="lastSalePriceInEth"
        label="Last Price"
        sortable
        numeric
        centered
        :custom-sort="sortableString('lastSalePriceInEth')"
        :visible="$device.isDesktopOrTablet"
        v-slot="props">
        <eth-with-fiat-price :price-in-eth="props.row.lastSalePriceInEth">
        </eth-with-fiat-price>
      </b-table-column>
      <b-table-column
        field="priceInEth"
        label="List Price"
        sortable
        numeric
        centered
        :custom-sort="sortableString('priceInEth')"
        v-slot="props">
        <eth-with-fiat-price :price-in-eth="props.row.priceInEth"
                             v-if="props.row.priceInEth">
        </eth-with-fiat-price>
      </b-table-column>
      <b-table-column
        field="actionButton"
        centered
        v-slot="props">
        <b-button
          v-if="availableToBuy(props.row.saleType)"
          @click="isCreatorAndTokensAvailable(props.row) ? routeToEdition() : launchBuyNowModal(props.row)"
          class="is-small is-fullwidth"
          type="is-primary"
          data-testid="buyWithETHBtn"
        >
          {{ isCreatorAndTokensAvailable(props.row) ? 'Buy edition' : 'Buy with ETH' }}
        </b-button>
        <b-button
          v-else-if="canPlaceBid(props.row)"
          @click="launchMakeOfferModel(props.row)"
          class="is-small is-fullwidth"
          type="is-secondary"
          data-testid="placeABid"
          >
          Place a bid
        </b-button>
      </b-table-column>
    </b-table>
    <div v-else class="p-3">

      <div class="columns is-multiline">
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column has-text-right is-one-third">
              <b-skeleton circle width="32px" height="32px" :animated="true"></b-skeleton>
            </div>
            <div class="column">
              <b-skeleton :animated="true" width="80%"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>

        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column has-text-right is-one-third">
              <b-skeleton circle width="32px" height="32px" :animated="true"></b-skeleton>
            </div>
            <div class="column">
              <b-skeleton :animated="true" width="80%"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>

        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>
        <div class="column">
          <div class="columns">
            <div class="column has-text-right is-one-third">
              <b-skeleton circle width="32px" height="32px" :animated="true"></b-skeleton>
            </div>
            <div class="column">
              <b-skeleton :animated="true" width="80%"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="50%"></b-skeleton>
        </div>
        <div class="column is-one-fifth">
          <b-skeleton :animated="true" width="100%"></b-skeleton>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {utils} from 'ethers';
import {mapState} from 'vuex';
import _head from 'lodash/head';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import ArtistNameAndImageLookup from '../avatar/ArtistNameAndImageLookup';
import PurchaseToken from '../modal/token/PurchaseToken';
import PlaceTokenOffer from '../modal/token/PlaceTokenOffer';

import {isBuyableSalesType, isOffersOnlySalesType} from '../../services/SaleTypes';
import {GET_EDITION_ACTIVE_OFFER, GET_TOKEN_ACTIVE_OFFER} from '../../queries/offersQueries';
import PurchaseEdition from '../modal/edition/PurchaseEdition';
import PlaceEditionOffer from '../modal/edition/PlaceEditionOffer';
import {isKoEscrow} from '../../services/utils';
import {calculateIterativeEditionId} from '../../services/editionUtils'
import {isCreatorContract} from '../../services/CreatorContractUtils';

export default {
  components: {
    ArtistNameAndImageLookup,
    EthWithFiatPrice
  },
  props: [
    'edition',
    'algoliaInfo'
  ],
  data() {
    return {
      listings: null
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ])
  },
  mounted() {
    if (this.edition) {
      this.$store.dispatch('marketplaceStore/getById', {chainId: this.chainId, entityId: this.edition.id})
        .then(async (result) => {
          if (result) {
            // remove burnt tokens
            const cleanListings = _filter(result.filters.listings, (listing) => {
              return listing.seller !== '0x000000000000000000000000000000000000dead' &&
                listing.seller !== '0x0000000000000000000000000000000000000000';
            });
            this.lookupMinimumOffer(cleanListings);
          }
        });
    }
  },
  methods: {
    rerouteToken(props) {
      if (isCreatorContract(props.row)) {
        return {};
      }
      return {name: 'tokens-id', params: { id: props.row.id}};
    },
    getLastPrice(priceInEth) {
      return utils.parseEther(priceInEth).toString();
    },
    getEditionId(listing) {
      return calculateIterativeEditionId(this.edition, listing.id)
    },
    availableToBuy(salesType) {
      return isBuyableSalesType(salesType);
    },
    canPlaceBid(listing) {
      return !isKoEscrow(_get(listing, 'seller'));
    },
    launchBuyNowModal(tokenListing) {
      if (tokenListing.type === 'Edition') {
        this.$buefy.modal.open({
          parent: this,
          component: PurchaseEdition,
          width: 600,
          props: {
            edition: this.edition,
            algoliaInfo: this.algoliaInfo
          },
          onCancel: () => {
            this.$emit('refresh-data');
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: PurchaseToken,
          width: 600,
          props: {
            token: {
              id: tokenListing.id,
              version: this.edition.version,
              listPrice: tokenListing.priceInEth,
              algoliaInfo: this.algoliaInfo
            },
            edition: this.edition
          },
          onCancel: () => {
            this.$emit('refresh-data');
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      }
    },
    async lookupMinimumOffer(listings) {
      this.listings = await Promise.all(listings.map(async (listing) => {
        if (isOffersOnlySalesType(listing.saleType) && !listing.priceInEth) {
          const result = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
            current_owner: listing.seller,
            artwork_id: listing.id,
            entity_type: 'token'
          });
          if (result && result.eth_reserve_in_wei) {
            listing.priceInEth = utils.formatEther(result.eth_reserve_in_wei);
            listing.minBid = result;
          }
        }

        // Patch on the salesType & salesType into the same place the Token/Edition so the modal work as expected
        listing.salesType = listing.saleType;
        listing.version = this.edition.version;

        return listing;
      }));
    },
    async launchMakeOfferModel(listing) {
      if (listing === 'Edition') {
        const existingOffer = await this.$apollo.query({
          client: this.gqlClient,
          query: GET_EDITION_ACTIVE_OFFER,
          variables: {
            edition: `edition-${this.edition ? this.edition.id : '0'}`
          }
        }).then(({data}) => _head(data.tokenOffers));

        this.$buefy.modal.open({
          parent: this,
          component: PlaceEditionOffer,
          width: 600,
          props: {
            edition: this.edition,
            existingOffer: _first(existingOffer),
            algoliaInfo: this.algoliaInfo
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      } else {
        const existingOffer = await this.$apollo.query({
          client: this.gqlClient,
          query: GET_TOKEN_ACTIVE_OFFER,
          variables: {
            token: `token-${listing.id}`
          }
        }).then(({data}) => _head(data.tokenOffers));

        this.$buefy.modal.open({
          parent: this,
          component: PlaceTokenOffer,
          width: 600,
          props: {
            token: listing,
            edition: this.edition,
            existingOffer,
            minBid: listing.minBid,
            algoliaInfo: this.algoliaInfo
          },
          onCancel: () => {
            this.$emit('refresh-data');
          },
          events: {
            close: () => {
              this.$emit('refresh-data');
            }
          }
        });
      }
    },
    sortableString(field) {
      return (a, b, isAsc) => {
        let aVal;
        let bVal;

        if (field === 'priceInEth') {
          aVal = (a.priceInEth || '0').toString();
          bVal = (b.priceInEth || '0').toString();
        } else if (field === 'lastSalePriceInEth') {
          aVal = this.getLastPrice((a.lastSalePriceInEth || '0').toString());
          bVal = this.getLastPrice((b.lastSalePriceInEth || '0').toString());
        }

        return isAsc
          ? this.parseSortVal(bVal, isAsc) - this.parseSortVal(aVal, isAsc)
          : this.parseSortVal(aVal, isAsc) - this.parseSortVal(bVal, isAsc);
      };
    },
    parseSortVal(val, isAsc) {
      const p = parseFloat(val);
      if (isAsc) {
        return p === 0 ? -Infinity : p;
      } else {
        return p === 0 ? Infinity : p;
      }
    },
    isCreatorAndTokensAvailable(listing) {
      return (listing.seller.toLowerCase() === this.edition.artistAccount.toLowerCase()) && (this.edition.totalAvailable - this.edition.totalSupply) > 0
    },
    async routeToEdition() {
      if (this.edition) {
          this.$router.push({ name: 'gallery-id', params: {id: this.edition.id }})
        }
    }
  }
};
</script>

<style lang="scss">
.button.is-small {
  border-radius: 6px !important;
}
</style>
