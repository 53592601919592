var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level is-mobile is-vcentered" }, [
    _c("div", { staticClass: "level-left" }, [
      _vm.ethAccount
        ? _c(
            "div",
            { staticClass: "level-item" },
            [
              _c(
                "n-link",
                {
                  attrs: {
                    to: { name: "profile-id", params: { id: _vm.ethAccount } },
                  },
                },
                [_c("account-image", { attrs: { address: _vm.ethAccount } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level-item" },
        [
          !_vm.disableLink
            ? _c(
                "n-link",
                {
                  attrs: {
                    to: { name: "profile-id", params: { id: _vm.ethAccount } },
                    target: _vm.target,
                  },
                },
                [
                  _vm.profile && _vm.profile.username
                    ? _c("span", { staticClass: "is-family-secondary" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.profile.username ||
                                _vm.profile.address | _vm.shortEth
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm.profile && _vm.profile.address
                    ? _c("span", { staticClass: "is-family-secondary" }, [
                        _vm._v(_vm._s(_vm._f("shortEth")(_vm.profile.address))),
                      ])
                    : _c("span", { staticClass: "is-family-secondary" }, [
                        _vm._v(_vm._s(_vm._f("shortEth")(_vm.ethAccount))),
                      ]),
                ]
              )
            : _c("div", [
                _vm.profile && _vm.profile.username
                  ? _c("span", { staticClass: "is-family-secondary" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.profile.username ||
                              _vm.profile.address | _vm.shortEth
                          ) +
                          "\n        "
                      ),
                    ])
                  : _vm.profile && _vm.profile.address
                  ? _c("span", { staticClass: "is-family-secondary" }, [
                      _vm._v(_vm._s(_vm._f("shortEth")(_vm.profile.address))),
                    ])
                  : _c("span", { staticClass: "is-family-secondary" }, [
                      _vm._v(_vm._s(_vm._f("shortEth")(_vm.ethAccount))),
                    ]),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }