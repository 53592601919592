import gql from 'graphql-tag';

const ENHANCED_DATA_FOR_EDITION_DEFINITION = `
  GetComposableDataForEdition($id: String!) {
    composableData: composables(where: {id: $id} subgraphError: allow) {
      id
      items {
        id
        value
        type
        address
        tokenID
      }
    }
  }
`

export const ENHANCED_DATA_FOR_EDITION = gql(`
  query ${ENHANCED_DATA_FOR_EDITION_DEFINITION}
`);

const EMBEDDED_DATA_FOR_TOKEN_DEFINITION = `
  GetEmbeddedDataForToken($id: String!) {
    embeddedData: composableItems(where: {value: $id} subgraphError: allow) {
      id
      address
      tokenID
      type
      value
    }
  }
 `
export const EMBEDDED_DATA_FOR_TOKEN = gql(`
  query ${EMBEDDED_DATA_FOR_TOKEN_DEFINITION}
`);
