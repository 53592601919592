var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "content nft-promo-image is-paddingless is-marginless is-clipped",
      staticStyle: { "border-radius": "8px" },
    },
    [
      _c(
        "h2",
        {
          staticClass:
            "has-text-centered is-uppercase is-family-primary has-text-weight-semibold mb-0 mt-5",
        },
        [_vm._v("\n    You're killing it\n  ")]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "title has-text-centered is-uppercase is-family-primary",
        },
        [_vm._v("\n    Now claim your Deadfellaz X KO POAP\n  ")]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "has-text-centered pt-2" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://kiosk.poap.xyz/#/event/4nqBGsVUmYuWylMKdGKn",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [
            _c(
              "b-button",
              {
                staticClass: "is-expanded has-text-white",
                attrs: { type: "is-primary" },
                on: {
                  click: function ($event) {
                    _vm.state.showPoapCode = true
                  },
                },
              },
              [_vm._v("\n        Claim your POAP\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns is-mobile mt-3" }, [
        _c("div", { staticClass: "column" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c(
              "b-button",
              {
                staticClass: "is-expanded",
                staticStyle: { color: "#49B373 !important" },
                attrs: { type: "is-dark" },
                on: { click: _vm.gotoPromo },
              },
              [
                _vm._v(
                  "\n        View the Draw the Undead 2022 collection\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "column" }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      {
        staticClass:
          "title has-text-centered is-uppercase is-family-primary pt-2",
      },
      [
        _c("img", {
          staticStyle: { "max-width": "150px" },
          attrs: { src: "/partners/promo/df_logo.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }