<template>
  <section>

    <nav class="columns mb-4 mt-4">
      <aside class="column is-half-desktop is-full-mobile">
        <b-button type="is-primary" @click="createCollection" outlined
                  v-if="isLoggedInAccountViewingPage">
          Create a collection
        </b-button>
        <b-button type="is-primary" @click="signInAndGetAuthToken" class="ml-1" outlined
                  v-if="isLoggedInAccountViewingPage && !hasAuthToken">
          View private collections
        </b-button>
      </aside>
    </nav>

    <nav class="columns has-margin-bottom-6 has-margin-top-6">
      <aside class="column is-full-mobile">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item is-family-tertiary has-text-weight-bold">
              <b-field>
                <b-switch
                  v-model="selectedView"
                  :false-value="viewCollections"
                  :true-value="viewOnSale">
                  {{ viewOnSale }}
                  <b-tooltip position="is-top" label="Items with a list or reserve price" class="ml-1" type="is-muted">
                    <b-icon icon="information-outline" size="is-small"></b-icon>
                  </b-tooltip>
                </b-switch>
              </b-field>
            </div>
          </div>
          <div
            class="level-right"
            v-if="selectedView !== viewCollections"
          >
            <div class="level-item">
              <b-select placeholder="Sort by" v-model="selectedSortBy" size="is-small" class="has-margin-left-7">
                <option
                  v-for="option in sortByOptions"
                  :value="option"
                  :key="option.key">
                  {{ option.label }}
                </option>
              </b-select>
            </div>
          </div>
        </div>
      </aside>
    </nav>

    <div v-if="selectedView === viewCollections">
      <collections-view
        :profile="profile"
        :is-logged-in-account-viewing-page="isLoggedInAccountViewingPage"
        :profile-collections="profileCollections"
        :extra-editions="combinedEditions"
        :all-collections-items="allCollectionsItems"
        :cc-editions="ccCollabEditions"
        @edit-collection="editCollection"
        @refresh-collection="loadUserCollections"
        @editions-refresh="refreshEditions"
      />
    </div>

    <div v-if="selectedView === viewOnSale">
      <on-sale-view
        :profile="profile"
        :is-logged-in-account-viewing-page="isLoggedInAccountViewingPage"
        :editions="editions"
        @editions-refresh="refreshEditions"
      />
    </div>

    <client-only>
      <infinite-loading @infinite="showMore" :identifier="infiniteId">
        <template slot="error">&nbsp;</template>
        <template slot="spinner">&nbsp;</template>
        <template slot="no-results">&nbsp;</template>
        <template slot="no-more">
          <empty-state message="No more tokens"></empty-state>
        </template>
      </infinite-loading>
    </client-only>
  </section>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import _unionBy from 'lodash/unionBy';
import {EDITIONS_BY_ARTIST_WITH_EXCLUDE, EDITIONS_BY_ON_SALE, EDITIONS_BY_ARTIST_WITH_EXCLUDE_ONLY_CC_COLLABS} from '../../queries/editionQueries';
import {notInExclusions} from '../../services/utils';
import {getNextStep, isOffersOnlySalesType, isSteppedSalesType} from '../../services/SaleTypes';
import EmptyState from '../common/EmptyState';
import CollectionsView from './views/CollectionsView'
import OnSaleView from './views/OnSaleView'
import {isCcEditionId} from '@/services/editionUtils';

const viewCollections = 'Collections'
const viewOnSale = 'Buy now'

const sortByOptions = [
  {label: 'Newest', key: 'newest', orderBy: 'createdTimestamp', orderDirection: 'desc'},
  {label: 'Oldest', key: 'oldest', orderBy: 'createdTimestamp', orderDirection: 'asc'},
  {label: 'Highest price', key: 'highest', orderBy: 'metadataPrice', orderDirection: 'desc'},
  {label: 'Lowest price', key: 'lowest', orderBy: 'metadataPrice', orderDirection: 'asc'}
];

const PAGE_SIZE = 6;

export default {
  components: {
    CollectionsView,
    OnSaleView,
    EmptyState
  },
  props: ['profile'],
  data: () => ({
    skip: 0,
    infiniteId: +new Date(),

    editions: [],
    ccCollabEditions: [],
    profileCollections: [],
    allCollectionsItems: [],
    hiddenItems: [],

    selectedSortBy: sortByOptions[0],
    sortByOptions,

    selectedView: viewCollections,
    viewCollections,
    viewOnSale
  }),
  computed: {
    ...mapState('web3Store', [
      'gqlClient',
      'authToken'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'hasAuthToken'
    ]),
    isLoggedInAccountViewingPage() {
      return this.profile && this.isLoggedInAccount(this.profile.address);
    },
    combinedEditions() {
      return _unionBy(this.ccCollabEditions, this.editions, 'id')
    }
  },
  watch: {
    selectedView(newVal) {
      this.skip = 0
      if (newVal === viewCollections) {
        this.fetchCollectionsView()
      }

      if (newVal === viewOnSale) {
        this.fetchOnSaleView()
      }
    },
    selectedSortBy(newVal) {
      this.skip = 0
      this.fetchOnSaleView(newVal)
    },
    // When auth token changed load collection to get private ones
    authToken(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadUserCollections();
      }
    }
  },
  async mounted() {
    // Load a users collections first so we can then filter out any private works
    await this.loadUserCollections();

    this.fetchCollectionsView()
  },
  methods: {
    getNextStep,
    isSteppedSalesType,
    isOffersOnlySalesType,
    showMore($state = null) {
      this.infiniteId += 1;
      if (this.$apollo.queries.editions && !this.$apollo.queries.editions.loading) {
        this.skip = this.skip + PAGE_SIZE;
      }
      if (this.$apollo.queries.editions && !this.$apollo.queries.editions.loading) {
        this.$apollo.queries.editions.fetchMore({
          variables: this.editionQueryParams({
            first: PAGE_SIZE,
            skip: this.skip,
            ...this.selectedSortBy
          }),
          updateQuery: (previousResult, {fetchMoreResult}) => {
            if (fetchMoreResult.editions && fetchMoreResult.editions.length > 0) {
              if ($state && $state.loaded) {
                $state.loaded();
              }
            } else if ($state && $state.complete) {
              $state.complete();
            }
            return {
              editions: previousResult.editions.concat(fetchMoreResult.editions)
            };
          }
        });
      }
    },
    async createCollection() {
      await this.signInAndGetAuthToken()
      if (this.hasAuthToken) {
        this.$emit('create-collection');
      }
    },
    editCollection(id) {
      this.$emit('edit-collection', id);
    },
    async loadUserCollections() {
      const {collections, allItems} = await this.$store.dispatch('profileCollectionsStore/getProfileCollections', {
        owner: this.profile.address,
        includePrivate: true,
        collectionType: 'edition'
      });

      this.profileCollections = collections;
      this.allCollectionsItems = allItems;
    },
    editionQueryParams(overrides = {}) {
      return {
        artist: [this.profile.address.toLowerCase()],
        first: PAGE_SIZE,
        skip: 0,
        orderBy: 'createdTimestamp',
        orderDirection: 'desc',
        remainingSupplyLte: 10000,
        remainingSupplyGte: 0,
        ...overrides
      };
    },
    refreshEditions() {
      console.log('Refresh Editions event seen');
      this.$apollo.queries.editions.refetch(this.editionQueryParams({
        first: this.skip + PAGE_SIZE,
        skip: 0
      }));
      this.$apollo.queries.editions.startPolling(10000);
    },
    fetchCollectionsView() {
      this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return EDITIONS_BY_ARTIST_WITH_EXCLUDE(
            this.allCollectionsItems.filter(item => !isCcEditionId(item)),
            notInExclusions(this.profile.address.toLowerCase())
          )
        },
        variables() {
          return this.editionQueryParams();
        },
        error(error) {
          console.log('error', error);
        }
      });
      this.$apollo.addSmartQuery('ccCollabEditions', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return EDITIONS_BY_ARTIST_WITH_EXCLUDE_ONLY_CC_COLLABS(
            this.allCollectionsItems.filter(item => !isCcEditionId(item)),
            true
          )
        },
        variables() {
          return this.editionQueryParams();
        },
        error(error) {
          console.log('error', error);
        }
      });
    },
    fetchOnSaleView(overrides) {
      this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        query() {
          return EDITIONS_BY_ON_SALE()
        },
        variables() {
          return this.editionQueryParams(overrides);
        },
        error(error) {
          console.log('error', error);
        }
      });
    },
    async signInAndGetAuthToken() {
      if (!this.hasAuthToken) {
        await this.$store.dispatch('web3Store/createJwtSession');
      }
    }
  }
}
</script>
