<template>
  <article>

    <!--    <span class="has-text-dark is-size-3 is-family-secondary is-hidden-desktop">KnownOrigin</span><span-->
    <!--    class="has-text-primary is-size-3 is-hidden-desktop">.</span>-->

    <div class="has-background-white is-fixed-white-background is-hidden-mobile height-colour"></div>

    <div class="chain-error-overlay" v-if="chainError"></div>

    <mobile-nav></mobile-nav>

    <desktop-nav></desktop-nav>

    <section style="min-height: 600px;">
      <nuxt />
    </section>

    <footer class="has-background-dark pt-6 pb-6">
      <section class="container is-not-full-width">
        <div class="columns">
          <div class="column is-half">

            <div class="has-margin-left-7 mt-2">
              <n-link :to="{ path: '/' }" class="is-flex is-align-items-center">
                <img src="/branding/White-ko-logo.svg" class="footer-ko-logo mb-1" />
              </n-link>
            </div>
            <div class=" is-size-6 has-margin-left-7">
              <n-link to="/" class="has-text-grey-light is-family-tertiary has-text-weight-medium">Discover, sell and collect NFT art</n-link>
            </div>

            <!--            <section class="container has-margin-bottom-1 has-margin-top-1">-->
            <!--              <p class="has-text-weight-bold has-text-white has-padding-left-7 has-margin-bottom-7">Stay in the picture, join our newsletter</p>-->
            <!--              &lt;!&ndash; Begin Mailchimp Signup Form &ndash;&gt;-->
            <!--              <form-->
            <!--                id="mc-embedded-subscribe-form"-->
            <!--                name="mc-embedded-subscribe-form"-->
            <!--                action="https://knownorigin.us19.list-manage.com/subscribe/post?u=84b0312927af7712ac2e6dd5a&amp;id=70433f1407"-->
            <!--                method="post"-->
            <!--                target="_blank"-->
            <!--                novalidate-->
            <!--                class="has-margin-left-7">-->

            <!--                <div class="field has-addons">-->
            <!--                  <div class="control">-->
            <!--                    <input class="input" type="email" value="" name="EMAIL" id="mce-EMAIL"-->
            <!--                           placeholder="arts@knownorigin.io"-->
            <!--                           required>-->
            <!--                  </div>-->
            <!--                  &lt;!&ndash; real people should not fill this in and expect good things - do not remove this or risk form bot signups&ndash;&gt;-->
            <!--                  <div style="position: absolute; left: -5000px;" aria-hidden="true">-->
            <!--                    <input type="text" name="b_84b0312927af7712ac2e6dd5a_ebee270c72" tabindex="-1" value="" hidden />-->
            <!--                  </div>-->
            <!--                  <div class="control">-->
            <!--                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"-->
            <!--                           class="button is-grey-darker" />-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </form>-->
            <!--              &lt;!&ndash;End mc_embed_signup&ndash;&gt;-->
            <!--            </section>-->

            <div class="has-margin-top-1 has-margin-left-7">
              <p class="heading has-text-grey-light is-family-monospace">
                KnownOrigin Labs LTD (company no. 13047869)
              </p>
            </div>
            <div class="has-margin-left-7">
              <p class="heading has-text-grey-light is-family-monospace">
                <a href="https://blockrocket.tech/" target="_blank">Built by BlockRocket</a>
              </p>
            </div>
            <p class="has-margin-top-1 has-padding-left-7">
              <web3-cache-provider class="is-muted is-size-7"></web3-cache-provider>
            </p>
          </div>
          <div class="column">
            <div>
              <p class="has-text-weight-black has-text-white has-padding-left-7 is-family-secondary is-uppercase">Follow Us</p>
            </div>
            <div class="is-family-tertiary">
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://twitter.com/knownorigin_io" target="_blank" class="has-text-light">
                  Twitter
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://discord.gg/knownorigin" target="_blank" class="has-text-light">
                  Discord
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://www.instagram.com/knownorigin.io/" target="_blank" class="has-text-light">
                  Instagram
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://www.reddit.com/r/knownorigin/" target="_blank" class="has-text-light">
                  Reddit
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://www.youtube.com/channel/UCZBkUkm5SSXQqzxBPezd5gw" target="_blank" class="has-text-light">
                  YouTube
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://linktr.ee/KnownOrigin.io" target="_blank" class="has-text-light">
                  Linktree
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://dune.com/knownorigin/KnownOrigin" target="_blank" class="has-text-light">
                  Dune Analytics
                </a>
              </p>
            </div>
          </div>
          <div class="column">
            <div>
              <p class="has-text-weight-black has-text-white has-padding-left-7 is-family-secondary is-uppercase">Help</p>
            </div>
            <div class="is-family-tertiary">
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://docs.knownorigin.io" target="_blank" class="has-text-light">Documentation</a>
                <span class="has-text-primary-light">/</span> <a href="https://docs.knownorigin.io/en/articles/6121943-press-kit" target="_blank" class="has-text-light">Press kit</a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://docs.knownorigin.io/en/articles/7128351-terms-of-service" target="_blank" class="has-text-light">
                  Terms of Service
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://docs.knownorigin.io/en/articles/7128342-privacy-policy" target="_blank" class="has-text-light">
                  Privacy
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://docs.knownorigin.io/en/articles/6118953-code-of-conduct" target="_blank" class="has-text-light">Code of Conduct</a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://docs.knownorigin.io/en/" target="_blank" class="has-text-light">Support</a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="mailto:report@knownorigin.io?subject=Content Report" target="_blank" class="has-text-light">Report infringement</a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="mailto:partnerships@knownorigin.io?subject=Partnership opportunity" target="_blank" class="has-text-light">Partnership enquiry</a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://pages.ebay.com/securitycenter/security_researchers_eligible_domains.html" target="_blank" class="has-text-light">
                  Responsible Disclosure
                </a>
              </p>
              <p class="has-margin-top-7 has-padding-left-7">
                <a href="https://jobs.ebayinc.com/us/en/search-results?keywords=knownorigin" target="_blank" class="has-text-light">
                  Careers
                </a>
              </p>
            </div>
          </div>
          <div class="column">
          </div>
        </div>
      </section>
    </footer>
  </article>
</template>

<script>

import {mapState, mapActions} from 'vuex';
import Web3CacheProvider from '../components/Web3CacheProvider';
import MobileNav from '../components/navbar/MobileNav';
import DesktopNav from '../components/navbar/DesktopNav';
import HasNFTPromo from '../components/modal/HasNFTPromo';

if (process.client) {
  // Polly fills ... unsure of direct impact of these yet ...
  require('resize-observer-polyfill');
  require('intersection-observer');
  require('focus-visible');
  require('@webcomponents/webcomponentsjs');

  // register model viewer web component
  require('@google/model-viewer');
}

  export default {
    components: {Web3CacheProvider, MobileNav, DesktopNav},
    data() {
      return {
        hasPromoNfts: null,
        chainErrorToast: null
      }
    },
    head() {
      return {
        title: 'KnownOrigin',
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'Discover, sell and collect NFT art.'
          }
        ]
      };
    },
    computed: {
      ...mapState('web3Store', ['account', 'chainError']),
      chainName() {
        return parseInt(process.env.DEFAULT_CHAIN_ID) === 5 ? 'Goerli Testnet' : 'Ethereum Mainnet'
      }
    },
    async mounted() {
      window.onNuxtReady(() => {
        console.log('App finished loading - onNuxtReady()');
      });

    this.$store.dispatch('getUsdPrice');

    await this.bootMessenger();

    // if (this.account) {
    //   this.checkForPromoNfts();
    // }
  },
  methods: {
    //  set up messenger actions here.
    ...mapActions('analytics/intercomStore', ['bootMessenger']),

    async checkForPromoNfts () {
      console.log('Check for promo NFTs');

      // Deadfellaz NFT check
      await this.$store.dispatch('userStore/checkIfOwnsNFTs', {tokenAddress: '0x2acAb3DEa77832C09420663b0E1cB386031bA17B'})
        .then((res) => {
          this.hasPromoNfts = !!(res && res.length > 0);
          this.popPromoNftsModal();

            // if no DF check frens!
            if (!this.hasPromoNfts) {
              this.$store.dispatch('userStore/checkIfOwnsNFTs', {tokenAddress: '0x920A8d9e9F0DefD6F86e4388a5503B04CaC83b57'})
                .then((res) => {
                  this.hasPromoNfts = !!(res && res.length > 0);
                  this.popPromoNftsModal();
                });
            }
          });
      },
      popPromoNftsModal() {
        if (this.hasPromoNfts) {
          this.$buefy.modal.open({
            parent: this,
            component: HasNFTPromo,
            width: 1080 * 0.75,
            trapFocus: true,
            events: {
              close: () => {
                // this.$store.dispatch('userStore/dismissNFTPromoModal');
              }
            },
            onCancel: () => {
              // this.$store.dispatch('userStore/dismissNFTPromoModal');
            }
          });
        }
      }
    },
    watch: {
      chainError: function (oldVal, newVal) {
        if (oldVal !== newVal && this.chainError) {
          this.chainErrorToast = this.$buefy.toast.open({
            indefinite: true,
            message: `<span class="is-family-tertiary">You are connected to the wrong network, please switch back to ${this.chainName} to continue</span>`,
            type: 'is-dark'
          })
        } else if (this.chainErrorToast) {
          this.chainErrorToast.close()
          this.chainErrorToast = null
        }
      }
    },
    // watch: {
    //   account: function (oldVal, newVal) {
    //     if (oldVal !== newVal) {
    //       this.checkForPromoNfts();
    //     }
    //   },
    // },
    errorCaptured(err, vm, info) {
      console.log('Global error found, logging', err, info);
    }
  };
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";
.is-fixed-white-background {
  left: 0;
  position: fixed;
  right: 0;
  top: -20px;
  z-index: 20;
}

.footer-ko-logo {
  max-width: 200px;
}

@include mobile {
  .footer-ko-icon {
    width: 32px !important;
  }
}

@include tablet {
  .footer-ko-icon {
    width: 32px !important;
  }
}

.height-colour {
  height: 76px;
  background-color: whitesmoke;
}
  .chain-error-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background: darkblue;
    z-index: 1000;
    top: 0;
    opacity: 0.5;
  }
</style>
