<template>
    <div class="edition-image card">

        <template>
          <div v-if="edition" :class="{ccArtwork: isCc, 'has-background-white': !isCc, 'pb-2': cardSize===2}">
            <image-for-asset-card
              :edition="edition"
              :cover="true"
              resolution="card"
              :metadata="workingMetadata"
              :click-handler="clickHandler"
              :disable-icons="false"
              :is-cc="isCc"
            >
            </image-for-asset-card>
            <div v-if="cardSize===3">
              <slot name="artwork-info-asset-card">
                <artwork-info
                  :edition="edition"
                  :size="size"
                  :phase="phase"
                  :click-handler="clickHandler">
                </artwork-info>
              </slot>
              <footer class="card-footer" :class="{borderGrey : isCc }">
                <slot name="search-footer-left-asset-card">
                  <search-footer-left-asset-card :edition="edition"
                                                :working-token="workingToken"
                                                :phase="phase"
                                                :token-offer="tokenOffer"
                                                :min-bid="minBid"
                                                :click-handler="clickHandler">
                  </search-footer-left-asset-card>
                </slot>
                <slot name="search-footer-right-asset-card">
                  <search-footer-right-asset-card :edition="edition"
                                                  :working-token="workingToken"
                                                  :click-handler="clickHandler"
                                                  :phase="phase">
                  </search-footer-right-asset-card>
                </slot>
              </footer>
            </div>
            <b-tooltip v-else class="column is-12 p-0 m-0 is-block cardTooltip" :type="!isCc ? 'is-light' : 'is-dark'" animated :position="cardSize < 2 ? 'is-bottom' : 'is-top'" :class="{'pb-2': cardSize === 1}">
              <div class="is-full-width">
                <div class="edition-image-artist px-3 is-size-7-tablet is-flex is-justify-content-space-between is-align-items-center" :class="{'has-text-white': isCc}">
                  <n-link :to="{name:'profile-id', params: { id: edition.artistAccount.toLowerCase()}}"
                  :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
                  <artist-name-and-image-lookup
                  :artist-account="edition.artistAccount "
                  :name="edition.metadata.artist"
                  :collaborators="edition.collaborators"
                  class="is-clipped"
                  :is-cc="isCc"
                  />
                  </n-link>
                  <availability :available="edition.totalAvailable" :supply="edition.totalSupply" :edition="edition" :small="true"></availability>
                </div>

                <footer class="card-footer mt-2 mb-0" :class="{borderGrey : isCc }" v-if="cardSize===2">
                  <search-footer-left-asset-card :edition="edition"
                                                :working-token="workingToken"
                                                :phase="phase"
                                                :token-offer="tokenOffer"
                                                :min-bid="minBid"
                                                :click-handler="clickHandler"
                                                :size="cardSize">
                  </search-footer-left-asset-card>
                </footer>
              </div>

              <template #content>
                <div class="is-flex is-align-items-start is-justify-content-center is-flex-direction-column">
                  <p class="my-1 is-size-7">{{edition.metadata.name}}</p>
                  <div class="column is-full py-2 px-0 flexbox" v-if="cardSize < 2">
                  <search-footer-left-asset-card class="column is-5"
                                                  :edition="edition"
                                                  :working-token="workingToken"
                                                  :phase="phase"
                                                  :token-offer="tokenOffer"
                                                  :min-bid="minBid"
                                                  :click-handler="clickHandler"
                                                  :size="cardSize">
                    </search-footer-left-asset-card>
                    <search-footer-right-asset-card class="column"
                                                    :edition="edition"
                                                    :working-token="workingToken"
                                                    :click-handler="clickHandler"
                                                    :phase="phase"
                                                    :size="cardSize">
                    </search-footer-right-asset-card>
                  </div>
                </div>
              </template>
            </b-tooltip>

          </div>

          <div v-else>
            <img src="/branding/bigloader-image.svg" class="card-image-cover" />
            <b-skeleton active width="140px" height="12px" />
          </div>

        </template>

    </div>
</template>

  <script>
  import { mapState } from 'vuex'
  import _get from 'lodash/get';
  import _head from 'lodash/head';
  import _first from 'lodash/first';
  import _filter from 'lodash/filter';
  import {findCheapestToken} from '../../services/salesStates';
  import editionUtils from '../../services/editionUtils';
  import {GET_TOKEN_ACTIVE_OFFER} from '../../queries/offersQueries';
  import {getPhaseState, isOffersOnlySalesType, isOffersSalesType} from '../../services/SaleTypes';
  import {isCreatorContract} from '../../services/CreatorContractUtils';

  export default {
    props: [
    'edition',
    'cover',
    'resolution',
    'size',
    'algoliaInfo',
    'clickHandler',
    'cardSize'
  ],
    data() {
    return {
      workingToken: null,
      tokenOffer: null,
      minBid: null,
      phase: null,
      metadataFromKoCache: null
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    ...mapState(['gqlClient']),
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    workingMetadata() {
      if (editionUtils.doesMetadataExist(this.edition)) {
        return this.edition.metadata;
      } else {
        return this.metadataFromKoCache;
      }
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  },
  async mounted() {
    await this.loadMetaData()
    if (this.edition) {
      if (this.edition.gatedSale && this.edition.gatedSale.phases.length > 0) {
        const state = getPhaseState(this.edition.gatedSale.phases[0])
        if (state) {
          const phase = this.edition.gatedSale.phases[0]
          phase.state = state
          this.phase = phase
        }
      }

      if (this.isNotSoldOutPrimary) {
        await this.loadMinimumEditionBid();
      } else {
        await this.loadCheapestToken();
        if (this.workingToken) {
          if (isOffersOnlySalesType(this.workingToken.salesType)) {
            await this.loadMinimumTokenBid();
          }
          if (isOffersSalesType(this.workingToken.salesType)) {
            await this.loadTokenOffers();
          }
        }
      }
    }
  },
  methods: {
    loadCheapestToken() {
      // remove burnt tokens from the list
      const cleanTokens = _filter(this.edition.tokens, (token) => {
        return token.currentOwner.id !== '0x000000000000000000000000000000000000dead' &&
          token.currentOwner.id !== '0x0000000000000000000000000000000000000000';
      });
      this.workingToken = findCheapestToken(cleanTokens);

      if (!this.workingToken) {
        if (cleanTokens.length === 1) {
          this.workingToken = _first(cleanTokens);
        }
      }
    },
    async loadMinimumTokenBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.workingToken, 'currentOwner.id'),
        artwork_id: _get(this.workingToken, 'id'),
        entity_type: 'token'
      });
    },
    async loadMinimumEditionBid() {
      this.minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: _get(this.edition, 'artistAccount'),
        artwork_id: _get(this.edition, 'id'),
        entity_type: 'edition'
      });
    },
    loadTokenOffers() {
      if (this.workingToken) {
        this.$apollo.getClient()
          .query({
            query: GET_TOKEN_ACTIVE_OFFER,
            variables: {
              token: `token-${this.workingToken.id}`
            }
          })
          .then(({data}) => {
            this.tokenOffer = _head(_get(data, 'tokenOffers'));
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },
    async loadMetaData() {
      if (!editionUtils.doesMetadataExist(this.edition)) {
        const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.edition.id}`);
        if (metadata) {
          this.metadataFromKoCache = metadata;
        }
      }
    }
  }
}
</script>

<style lang="scss">
.flexbox {
  display: flex;
  grid-gap: 30px;
  align-items: center;
  justify-content: space-evenly;
}
.edition-image {
display: block;

.card-image-cover {
    aspect-ratio: 1 / 1.15;
    height: auto !important;
}
}

.edition-image-card {
transition: transform .2s ease;
box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);

&:hover {
    transform: translateY(-4px);
}

img {
    height: auto !important;
    object-fit: cover !important;
    max-height: 500px !important;
    width: 100%;
}
}

.edition-image-title {
font-size: 13px;
opacity: 0.7;
}

.edition-image-artist {
* {
    margin: 0 !important;
}

figure {
    width: 24px !important;
    height: 24px !important;
    padding: 0;
}

h6 {
    padding-left: .25rem;
    font-weight: 500;
}
}
.borderGrey {
  border-top-color:#3A3A3A;
}
.is-full-width {
  width: 100%;
}
.cardTooltip {
  tooltip-trigger {
    justify-content: space-between;
  }
}
</style>
