var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "figure",
    { staticClass: "image is-24x24", class: [_vm.size, _vm.shape] },
    [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: {
              src: `${_vm.$userMediaApi.defaults.baseURL}/media/user/profile/${_vm.address}`,
              error: _vm.createAccountIcon,
            },
            expression:
              "{\n      src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${address}`,\n      error: createAccountIcon,\n    }",
          },
        ],
        staticClass: "is-rounded has-background-white enforce-aspect-ratio",
        class: {
          shape: _vm.shape,
          "height-restrict": !_vm.noHeightRestrict,
          size: _vm.size,
        },
        attrs: { id: _vm.address },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }