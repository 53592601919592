<template>
  <ApolloQuery :query="require('../queries/editionQueries').EDITION_BY_ID_QUERY" :variables="{ id }" :client-id="gqlClient">
    <template #default="{ result: { data } }">
        <edition-card
          v-if="data && data.editionById && data.editionById.length > 0 && data.editionById[0] && data.editionById[0].active"
          :edition="data.editionById[0]"
          :cover="cover"
          :resolution="resolution"
          class="has-margin-bottom-4"
          :size="(size === 'is-large') ? 'is-large' : null"
          :address="address"
        ></edition-card>
    </template>
  </ApolloQuery>
</template>

<script>
  import {mapState} from 'vuex';
  import EditionCard from './EditionCard';

  export default {
    components: {EditionCard},
    props: ['id', 'cover', 'resolution', 'size', 'score', 'address'],
    computed: {
      ...mapState('web3Store', ['gqlClient'])
    }
  };
</script>
