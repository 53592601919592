<template>
  <div>
    <section class="content">
      <div v-if="filterPrivateCollections && filterPrivateCollections.length > 0">
        <div
          v-for="profileCollection in slicedCollections"
          :key="profileCollection.name"
        >
          <edition-profile-collection
            :profile-collection="profileCollection"
            :profile-slug="profile.slug"
            :is-logged-in-account-viewing-page="isLoggedInAccountViewingPage"
            :address="profile.address"
            @edit-collection="editCollection"
            @refresh-collection="refreshCollection"
            :editions="combinedEditions"
          >
          </edition-profile-collection>
        </div>
        <div v-if="collectionsAvailable > 0" class="is-flex is-justify-content-center">
          <button class="button is-primary is-outlined" @click="viewMoreCollections">View {{collectionsAvailable}} more collection{{collectionsAvailable===1 ? '' : 's'}}</button>
        </div>
      </div>
    </section>

    <div
      class="columns is-multiline"
      v-if="filteredExtraEditions && filteredExtraEditions.length > 0"
    >
      <div
        class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card"
        v-for="(edition, $index) in filteredExtraEditions"
        :key="$index"
      >
        <edition-card
          :edition="edition"
          :cover="true"
          :address="profile.address"
          resolution="card"
          class="has-margin-bottom-4"
          @editions-refresh="() => $emit('editions-refresh')">
        </edition-card>
      </div>
    </div>

    <div class="level">
      <div
        class="level-item"
        v-if="(profileCollections && profileCollections.length === 0) && (filteredExtraEditions && filteredExtraEditions.length === 0)"
      >
        <empty-state message="No items for sale"></empty-state>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import _unionBy from 'lodash/unionBy';
import EditionCard from '../../EditionCard';
import EmptyState from '../../common/EmptyState';
import EditionProfileCollection from '../EditionProfileCollection';
import {EDITIONS_BY_ARTIST_WITH_INCLUDE} from '../../../queries/editionQueries';

export default {
  components: {
    EditionCard,
    EmptyState,
    EditionProfileCollection
  },
  props: ['profile', 'isLoggedInAccountViewingPage', 'profileCollections', 'extraEditions', 'ccEditions', 'allCollectionsItems'],
  data: () => ({
    editions: [],
    sliceCounter: 3
  }),
  computed: {
    ...mapGetters('web3Store', [
      'hasAuthToken'
    ]),
    filteredExtraEditions() {
      return this.extraEditions.filter(item => !this.allCollectionsItems.includes(item.id))
    },
    filterPrivateCollections() {
      return this.profileCollections.filter(i => (i.private ? this.isLoggedInAccountViewingPage && this.hasAuthToken : true))
    },
    slicedCollections() {
      return this.filterPrivateCollections.slice(0, this.sliceCounter)
    },
    collectionsAvailable() {
      return this.filterPrivateCollections.length - this.sliceCounter
    },
    combinedEditions() {
      return _unionBy(this.ccEditions, this.editions, 'id')
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        fetchPolicy: 'cache-and-network',
        query() {
          return EDITIONS_BY_ARTIST_WITH_INCLUDE([])
        },
        variables() {
          return this.buildEditionQueryParams();
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });
  },
  methods: {
    editCollection(id) {
      this.$emit('edit-collection', id);
    },
    refreshCollection() {
      this.$emit('refresh-collection');
    },
    buildEditionQueryParams(overrides = {}) {
      return {
        artist: [this.profile && this.profile.address && this.profile.address.toLowerCase()],
        first: 999,
        skip: 0,
        ...overrides
      };
    },
    viewMoreCollections() {
      const colLength = this.filterPrivateCollections.length
      const difference = colLength - this.sliceCounter
      if (difference > 2) {
        this.sliceCounter = this.sliceCounter + 3
      } else if (difference > 0) {
        this.sliceCounter = this.sliceCounter + difference
      }
    }
  }
}
</script>
