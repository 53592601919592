var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition && _vm.phase
    ? _c("div", [
        _c(
          "section",
          { staticClass: "columns is-mobile is-flex is-align-items-end" },
          [
            _c(
              "div",
              { staticClass: "column content mb-0 is-family-tertiary" },
              [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    class: { "is-muted": _vm.phaseState === "public" },
                  },
                  [_vm._v("\n        Early access price\n      ")]
                ),
                _vm._v(" "),
                _vm.phase.priceInWei === "0"
                  ? _c("div", [
                      _c("p", { staticClass: "title" }, [_vm._v("Free claim")]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("eth-with-fiat-price", {
                  attrs: {
                    size: "is-large",
                    type: _vm.phaseState === "public" ? "is-muted" : "",
                    "show-fiat": true,
                    "price-in-wei": _vm.phase.priceInWei,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column content is-family-tertiary" },
              [
                _c(
                  "p",
                  {
                    staticClass: "title mb-0",
                    class:
                      _vm.phaseState === "public" ? {} : { "is-muted": true },
                  },
                  [_vm._v("\n        Public price\n      ")]
                ),
                _vm._v(" "),
                _c("eth-with-fiat-price", {
                  attrs: {
                    size: "is-large",
                    type: _vm.phaseState === "public" ? "" : "is-muted",
                    "show-fiat": true,
                    "price-in-wei": _vm.edition.priceInWei,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isWeb3Connected
          ? _c("section", { staticClass: "columns is-mobile" }, [
              _vm.onEarlyAccessList && _vm.phaseState === "active"
                ? _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _vm.phase.priceInWei !== "0"
                        ? _c("buy-now-buttons", {
                            attrs: {
                              edition: _vm.edition,
                              phase: _vm.phase,
                              proofs: _vm.userMerkleProofs,
                              gated: true,
                              "hide-wert": true,
                            },
                          })
                        : _c("buy-now-buttons", {
                            attrs: {
                              edition: _vm.edition,
                              phase: _vm.phase,
                              proofs: _vm.userMerkleProofs,
                              free: true,
                              gated: true,
                              "hide-wert": true,
                            },
                          }),
                    ],
                    1
                  )
                : _vm.phaseState === "public"
                ? _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _c("buy-now-buttons", {
                        attrs: { edition: _vm.edition },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("section", { staticClass: "columns is-mobile is-family-tertiary" }, [
          !_vm.isWeb3Connected
            ? _c(
                "div",
                { staticClass: "column content" },
                [
                  _vm.phaseState !== "public"
                    ? _c(
                        "gated-message-countdown",
                        {
                          attrs: {
                            "start-date": _vm.phase.endTime,
                            type: "success",
                          },
                        },
                        [
                          _c("template", { slot: "countdown-title" }, [
                            _c("p", { staticClass: "title has-text-success" }, [
                              _vm._v(
                                "\n            Public sale starts in\n          "
                              ),
                            ]),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("early-access-section", {
                    attrs: { edition: _vm.edition },
                  }),
                ],
                1
              )
            : !_vm.initComplete
            ? _c(
                "div",
                [
                  _c("b-skeleton", { attrs: { width: "20%", animated: true } }),
                  _vm._v(" "),
                  _c("b-skeleton", { attrs: { width: "40%", animated: true } }),
                ],
                1
              )
            : _vm.onEarlyAccessList
            ? _c("div", { staticClass: "column content" }, [
                _vm.phaseState === "pre-phase"
                  ? _c(
                      "div",
                      [
                        _c(
                          "gated-message-countdown",
                          {
                            attrs: {
                              "start-date": _vm.phase.startTime,
                              type: "success",
                            },
                          },
                          [
                            _c("template", { slot: "countdown-title" }, [
                              _c(
                                "p",
                                { staticClass: "title has-text-success" },
                                [
                                  _vm._v(
                                    "\n              You're eligible for early access! Starts in\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("early-access-section", {
                          attrs: { edition: _vm.edition },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.phaseState === "active"
                  ? _c(
                      "div",
                      { staticClass: "column content" },
                      [
                        _c(
                          "gated-message-countdown",
                          {
                            attrs: {
                              "start-date": _vm.phase.endTime,
                              type: "success",
                            },
                          },
                          [
                            _c("template", { slot: "countdown-title" }, [
                              _c(
                                "p",
                                { staticClass: "title has-text-success" },
                                [
                                  _vm._v(
                                    "\n              You're eligible for early access! Ends in\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("early-access-section", {
                          attrs: { edition: _vm.edition },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : !_vm.onEarlyAccessList && _vm.phaseState !== "public"
            ? _c(
                "div",
                { staticClass: "column content" },
                [
                  _c(
                    "gated-message-countdown",
                    {
                      attrs: {
                        "start-date": _vm.phase.endTime,
                        type: "danger",
                      },
                    },
                    [
                      _c("template", { slot: "countdown-title" }, [
                        _c("p", { staticClass: "title has-text-danger" }, [
                          _vm._v(
                            "\n            You're ineligible for early access. Don't worry, public sale starts in\n          "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("early-access-section", {
                    attrs: { edition: _vm.edition },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }