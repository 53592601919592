<template>
  <div class="edition-image">
    <ApolloQuery
      v-if="edition"
      :query="require('../../queries/editionQueries').EDITION_BY_ID_WITH_TOKENS_QUERY"
      :variables="{ id: edition.id }"
      :client-id="gqlClient">

      <template #default="{ result: { loading, data } }">
        <div v-if="!loading && data?.editionById.length > 0" class="has-background-white pb-2">
          <image-for-asset-card
            :edition="data.editionById[0]"
            cover="true"
            resolution="card"
            :disable-icons="false"
            :is-cc="isCc"
          />
          <div class="edition-image-artist px-3 is-size-7-tablet">
            <artist-name-and-image-lookup
              :artist-account="data.editionById[0].artistAccount "
              :name="data.editionById[0].metadata.artist"
              :collaborators="data.editionById[0].collaborators" />
          </div>
        </div>

        <div v-else>
          <img src="/branding/bigloader-image.svg" class="card-image-cover" />
          <b-skeleton active width="140px" height="12px" />
        </div>
      </template>
    </ApolloQuery>

    <!-- Skeleton -->
    <div v-else>
      <img src="/branding/bigloader-image.svg" class="card-image-cover" />
      <b-skeleton active width="140px" height="12px" />
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isCreatorContract } from '../../services/CreatorContractUtils';

export default {
  props: ['edition'],
  computed: {
    ...mapState(['gqlClient']),
    isCc() {
      return isCreatorContract(this.edition);
    }
  }
}
</script>

<style lang="scss">
.edition-image {
  display: block;

  .card-image-cover {
    aspect-ratio: 1 / 1.15;
    height: auto !important;
  }
}

.edition-image-card {
  transition: transform .2s ease;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-4px);
  }

  img {
    height: auto !important;
    object-fit: cover !important;
    max-height: 500px !important;
    width: 100%;
  }
}

.edition-image-title {
  font-size: 13px;
  opacity: 0.7;
}

.edition-image-artist {
  * {
    margin: 0 !important;
  }

  figure {
    width: 24px !important;
    height: 24px !important;
    padding: 0;
  }

  h6 {
    padding-left: .25rem;
    font-weight: 500;
  }
}
</style>
