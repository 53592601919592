var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "is-full columns is-flex-mobile p-0 my-0 search-item-style",
      class: { "search-item-style-top": _vm.index === 0 },
      on: {
        click: function ($event) {
          return _vm.handleMarketPlaceRouting(
            _vm.item,
            {
              queryId: _vm.item.__queryID,
              index: _vm.sortBy,
              position: _vm.index + 1,
            },
            _vm.item
          )
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "is-flex is-align-items-center" },
        [
          _c("image-asset", {
            staticClass: "rounded-edge-image ml-3",
            attrs: {
              "edition-number": _vm.item.editionId,
              metadata: _vm.item.metadata,
              resolution: "thumbnailSearch",
              cover: false,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "column is-centered px-2 is-inline-flex is-justify-content-space-between",
        },
        [
          _c(
            "p",
            {
              staticClass:
                "has-text-left is-size-5 ml-1 has-text-weight-light is-family-secondary is-size-6",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.item.metadata.artworkName.length > _vm.textWrapCap
                      ? _vm.item.metadata.artworkName.substring(
                          0,
                          _vm.textWrapCap
                        ) + "..."
                      : _vm.item.metadata.artworkName
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("availability", {
                staticClass: "artwork-availability has-text-centered",
                attrs: {
                  available: _vm.item.filters.editionSize,
                  supply: _vm.item.filters.editionSize,
                  edition: _vm.item.filters,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }