var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Place a bid\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _c(
          "section",
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: "Enter your bid",
                  message: `Min. bid is Ξ ${_vm.minBidInEthCalculated}. Must increase in increments of Ξ ${_vm.minBidAmount}.`,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    placeholder: "1.23",
                    expanded: "",
                    autofocus: "",
                    min: _vm.minBidAmount,
                    type: "number",
                    disabled: _vm.transactionHash,
                    step: _vm.minBidAmount,
                  },
                  model: {
                    value: _vm.form.offerAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "offerAmount", $$v)
                    },
                    expression: "form.offerAmount",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldShowHighestOffer
              ? _c(
                  "p",
                  { staticClass: "has-text-weight-semibold" },
                  [
                    _vm._v("\n        Current highest bid\n        "),
                    _c("eth-with-fiat-price", {
                      attrs: {
                        size: "is-medium",
                        "show-fiat": false,
                        "price-in-wei": _vm.existingOffer.weiValue,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.shouldShowReservePrice
              ? _c(
                  "p",
                  { staticClass: "has-text-weight-semibold" },
                  [
                    _vm._v("\n        Reserve price\n        "),
                    _c("eth-with-fiat-price", {
                      attrs: {
                        size: "is-medium",
                        "show-fiat": false,
                        "price-in-wei": _vm.edition.reservePrice,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.isLockableEdition(_vm.edition) &&
              !_vm.isReserveAuctionSalesType(_vm.edition.salesType)
                ? _c(
                    "div",
                    {},
                    [
                      _c(
                        "b-message",
                        {
                          staticClass: "is-size-7",
                          attrs: {
                            type: "is-info",
                            closable: false,
                            duration: 0,
                          },
                        },
                        [
                          _vm._v(
                            "\n            You will not be able to withdraw your bid for " +
                              _vm._s(_vm.lockoutPeriodForOffers) +
                              ".\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                ? _c("div", [
                    _vm.auctionWillStart
                      ? _c(
                          "div",
                          {},
                          [
                            _c(
                              "b-message",
                              {
                                staticClass: "is-size-7",
                                attrs: {
                                  type: "is-success",
                                  closable: false,
                                  duration: 0,
                                },
                              },
                              [
                                _c("strong", [
                                  _vm._v("This bid will start the auction."),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "is-linkable",
                                    attrs: {
                                      href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Learn how 24 hour auctions work")]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm.reserveAuctionCountdownStarted && _vm.isOutBid
                      ? _c(
                          "div",
                          {},
                          [
                            _c(
                              "b-message",
                              {
                                staticClass: "is-size-7",
                                attrs: {
                                  type: "is-success",
                                  closable: false,
                                  duration: 0,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Reserve price met, auction will be extended if bid is made within the last 15 minutes.\n              "
                                ),
                                _c("a", { staticClass: "is-linkable" }, [
                                  _vm._v("Learn how 24 hour auctions work"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm.auctionWillNotStart
                      ? _c(
                          "div",
                          {},
                          [
                            _vm.reserveAuctionCountdownStarted
                              ? _c(
                                  "b-message",
                                  {
                                    staticClass: "is-size-7",
                                    attrs: {
                                      type: "is-warning",
                                      closable: false,
                                      duration: 0,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              You must outbid the highest bidder.\n              "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "is-linkable",
                                        attrs: {
                                          href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Learn how 24 hour auctions work"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-message",
                                  {
                                    staticClass: "is-size-7",
                                    attrs: {
                                      type: "is-warning",
                                      closable: false,
                                      duration: 0,
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "This bid will not start the auction."
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n              You can withdraw this bid at anytime.\n              "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "is-linkable",
                                        attrs: {
                                          href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Learn how 24 hour auctions work"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {},
                          [
                            _c(
                              "b-message",
                              {
                                staticClass: "is-size-7",
                                attrs: {
                                  type: "is-info",
                                  closable: false,
                                  duration: 0,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Bids placed during an auction cannot be withdrawn. By placing a bid you have indicated that you agree\n              to the\n              "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "is-linkable",
                                    attrs: {
                                      href: "https://docs.knownorigin.io/en/articles/7128351-terms-of-service",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                terms of service\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(".\n              "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "is-linkable",
                                    attrs: {
                                      href: "https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Learn how 24 hour auctions work")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Bid submitted\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: { expanded: "", disabled: _vm.disableOfferButton },
              on: { click: _vm.makeOffer },
            },
            [_vm._v("\n      Submit bid\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button-nevermind" },
        [
          _c(
            "b-button",
            {
              attrs: { expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.close(false)
                },
              },
            },
            [_vm._v("\n      Nevermind\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }