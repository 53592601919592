import Notify from 'bnc-notify'

export default (network = 1) => {
  return Notify({
    dappId: 'a09f6d6e-d38b-43af-9118-dd9b78a302a5',
    networkId: parseInt(network),
    darkMode: true,
    desktopPosition: 'bottomLeft'
  })
};
