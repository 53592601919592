var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition
    ? _c(
        "aside",
        { staticClass: "content scrollable-content is-family-tertiary" },
        [
          _c("ul", [
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [_vm._v("Sold")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [_vm._v(_vm._s(_vm.edition.totalSold))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("Gifted"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [_vm._v(_vm._s(_vm.gifted))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.hasSales
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Avg. sale price"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("eth-with-fiat-price", {
                          attrs: { "price-in-eth": _vm.avgSalePrice },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSales
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Total"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("eth-with-fiat-price", {
                          attrs: { "price-in-eth": _vm.totalEarned },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSoldOut
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Time to sellout"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.difference()) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("Owners"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [_vm._v(_vm._s((_vm.edition.allOwners || []).length))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("Created"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [
                    _c("date-format", {
                      attrs: {
                        "timestamp-in-secs": _vm.edition.createdTimestamp,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }