<template>
  <div class="column is-justify-content-start ml-0 pl-0 mt-0 pt-0">
    <div class=" is-inline-block mr-2 mt-2">
        <div class="share-container" @click="copyURLWithToast">
        <a
           data-cy="twitter_address" target="_blank" class="share-icon">
          <b-icon icon="content-copy"></b-icon>
          <span class="verified-icon">
    </span>
        </a>
        </div>
    </div>
  <div class="is-inline-block mr-2 mt-2">
    <div class="share-container" @click="launchTwitterIntent()">
      <a
        data-cy="twitter_address" target="_blank" class="share-icon">
        <b-icon class="twitter-icon" icon="twitter"></b-icon>
      </a>
    </div>
  </div>

    <div class="is-inline-block mr-2 mt-2">
      <div class="share-container" @click="launchFacebookIntent()">
        <a
          data-cy="facebook_address" target="_blank" class="share-icon">
          <b-icon class="facebook-icon" icon="facebook"></b-icon>
        </a>
      </div>
    </div>
    <div class="is-inline-block mr-2 mt-2">
      <div class="share-container" @click="launchRedditIntent()">
        <a
          data-cy="reddit_address" target="_blank" class="share-icon">
          <b-icon class="reddit-icon" icon="reddit"></b-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { copyText } from '../../services/fileUtils';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';
import { splitCreatorEditionId } from '../../services/CreatorContractUtils';
import { getChainName } from "../../services/utils";

export default {
  name: 'Share',
  props: ['type', 'data', 'marketplace'],
  data() {
    return {
      name: null,
      artist: null
    }
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    makeUrlShareable() {
      if (this.marketplace === 'OpenSea') {
        const {contract, editionId} = splitCreatorEditionId(this.data.id)
        return this.getOpenseaLink(contract, editionId)
      } else {
        const deconstructedUrl = window.location.href.split('?');
        return deconstructedUrl[0]
      }
    },
    OpenseaCopy() {
      if (this.marketplace === 'OpenSea') {
        return 'Buy now on OpenSea:'
      } else {
        return ''
      }
    }
  },
  async mounted() {
    await this.setDataBasedOnType();
    // check if data contains non suitable characters
    if (this.name.search(/[^a-zA-Z\d\s:]/g) !== -1 || (this.artist ? this.artist.search(/[^a-zA-Z%_\d\s:]/g) !== -1 : true)) {
      this.name = false;
      this.artist = false;
    }
  },
  methods: {
    getOpenseaLink(contract, edition) {
      return `https://${this.chainId !== 1 && 'testnets.'}opensea.io/assets/${getChainName(this.chainId)}/${contract}/${edition}`
    },
    async setDataBasedOnType () {
      if (this.data) {
        if (this.type === 'CC_EDITION' || this.type === 'EDITION') {
          // data = editionByID
          this.name = this.data.metadata.name;
          this.artist = await this.$store.dispatch('userStore/getUser', this.data.artistAccount).then((res) => {
            if (res.twitter) {
              return '%40' + res.twitter;
            }
            return res.username;
          });
        } else if (this.type === 'CONTRACT') {
          this.name = this.data.name + ' a creator contract';
          this.artist = await this.$store.dispatch('userStore/getUser', this.data.owner).then((res) => {
            if (res.twitter) {
              return '%40' + res.twitter;
            }
            return res.username;
          });
          // data = contractDetails
        }
      }
    },
    async copyURLWithToast() {
      copyText(this.makeUrlShareable);
      this.$buefy.toast.open({message: `<span class="is-family-tertiary">Share link copied</span>`, type: 'is-primary'});
      this.recordAmplitudeTracking('Copied');
    },
    async recordAmplitudeTracking(platform) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.shareClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.value]: this.name ? this.name : '',
          [AMPLITUDE_EVENT_PROPERTIES.subject]: this.artist ? this.artist : '',
          [AMPLITUDE_EVENT_PROPERTIES.platform]: platform,
          [AMPLITUDE_EVENT_PROPERTIES.type]: this.type
        }
      });
    },
    async launchTwitterIntent () {
      const navUrl =
        this.name && this.artist ? 'https://twitter.com/intent/tweet?text=' + this.name + ' by ' + this.artist + ' on KnownOrigin: \n ' + this.openseaCopy + this.makeUrlShareable : 'https://twitter.com/intent/tweet?text=' + this.marketplace === 'OpenSea' ? this.openseaCopy : 'Check this out on KnownOrigin: ' + this.makeUrlShareable

      this.recordAmplitudeTracking('Twitter');

      window.open(navUrl, '_blank');
    },
    async launchFacebookIntent () {
      const navUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.makeUrlShareable;
      this.recordAmplitudeTracking('Facebook');
      window.open(navUrl, '_blank');
    },
    async launchRedditIntent() {
      const navUrl =
        this.name && this.artist ? 'http://www.reddit.com/submit?url=' + this.makeUrlShareable + '&title=' + this.name + ' by ' + this.artist + ' on KnownOrigin:' + this.openseaCopy : 'http://www.reddit.com/submit?url=' + this.makeUrlShareable + '&title=' + this.marketplace === 'OpenSea' ? this.openseaCopy : 'Check this out on KnownOrigin: ';
      this.recordAmplitudeTracking('Reddit');
      window.open(navUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.share-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-container {
  border: 1px solid #DBDBDB;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: relative;
}

.twitter-icon {
  color: #00acee;
}

.facebook-icon {
  color: #3b5998;
  padding-left: 1px;
}

.reddit-icon {
  color: #FF5700;
}

.reddit-icon {
  color: #FF5700;
}

</style>
