export const state = () => ({
  transactionDetails: {}
});

export const getters = {
  findTransactionDetails: state => hash => state.transactionDetails[hash]
};

export const mutations = {
  storeDetail(state, detail) {
    state.transactionDetails[detail.transactionHash] = detail;
  }
};

export const actions = {
  async lookupTransactionDetail({commit, getters, rootState}, hash) {
    // Do we have it?
    const found = getters.findTransactionDetails(hash);
    if (found) {
      return found;
    }

    const chainId = rootState.web3Store.chainId;
    if (chainId) {
      try {
        // If not get and store for later
        const found = await this.$cacheApi.$get(`/network/${chainId}/event/transaction/${hash}`);
        if (found) {
          commit('storeDetail', found);
        }
        return found;
      } catch (e) {
        return null;
      }
    }
    return null;
  }
};
