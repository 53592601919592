var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.topTokens
    ? _c("div", { staticClass: "topArtworks has-margin-top-7 mb-6 pb-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view === "detailed",
                expression: "view === 'detailed'",
              },
            ],
          },
          [
            _c(
              "b-table",
              {
                staticClass: "topArtworks__table is-family-tertiary",
                attrs: {
                  data: _vm.topTokens,
                  "default-sort": "primaryValueInEth",
                  "sticky-header": "",
                  height: "auto",
                  striped: "",
                  paginated: "",
                  "pagination-simple": "",
                  "per-page": 25,
                },
              },
              [
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile has-margin-top-7",
                  attrs: { field: "index", label: "Artwork" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("article", { staticClass: "media" }, [
                              _c("div", { staticClass: "media-content" }, [
                                _c("div", { staticClass: "content" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "is-small is-flex is-align-items-center",
                                    },
                                    [
                                      _c(
                                        "n-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "profile-id",
                                              params: {
                                                id: props.row.edition.artistAccount.toLowerCase(),
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("account-image", {
                                            staticClass: "mr-3 p-0",
                                            attrs: {
                                              address:
                                                props.row.edition.artistAccount,
                                              shape: "rounded-edge-image",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "n-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "tokens-id",
                                              params: { id: props.row.id },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "has-text-weight-normal",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    props.row.metadata.name
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1314506610
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "artist",
                    label: "Creator",
                    sortable: "",
                    "custom-sort": _vm.sortableString("artist"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label: "Artist",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            props.row.edition.collaborators.length <= 1
                              ? _c("user-profile-lookup", {
                                  attrs: {
                                    "artist-account":
                                      props.row.edition.artistAccount,
                                    "metadata-artist":
                                      props.row.edition.artistAccount,
                                    size: "is-small",
                                    "no-image": true,
                                    "no-subtitle": true,
                                    "is-table": true,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            props.row.edition.collaborators &&
                            props.row.edition.collaborators.length > 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "is-italic is-size-6 m-0 is-family-tertiary",
                                  },
                                  [
                                    _vm._v(
                                      "\n            collaboration\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    346598255
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "totalPurchaseValue",
                    label: "Total sale ETH",
                    centered: "",
                    sortable: "",
                    "custom-sort": _vm.sortableString("totalPurchaseValue"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label:
                                    "Accumulative trade volume (primary & secondary)",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "is-hidden-touch",
                              attrs: {
                                src: "/interface/sort-arrow.png",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": props.row.totalPurchaseValue,
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2584797331
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "totalPurchaseCount",
                    label: "Sale count",
                    centered: "",
                    sortable: "",
                    "custom-sort": _vm.sortableString("totalPurchaseCount"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label:
                                    "Total sale count (primary & secondary)",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "is-hidden-touch",
                              attrs: {
                                src: "/interface/sort-arrow.png",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("span", { staticClass: "is-family-tertiary" }, [
                              _vm._v(_vm._s(props.row.totalPurchaseCount)),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1909874179
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "largestSalePriceEth",
                    label: "Largest sale ETH",
                    centered: "",
                    sortable: "",
                    "custom-sort": _vm.sortableString("largestSalePriceEth"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label:
                                    "Largest single sale (primary & secondary)",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "is-hidden-touch",
                              attrs: {
                                src: "/interface/sort-arrow.png",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": props.row.largestSalePriceEth,
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1159302110
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "primaryValueInEth",
                    label: "Primary sale ETH",
                    centered: "",
                    sortable: "",
                    "custom-sort": _vm.sortableString("primaryValueInEth"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label: "largest primary sale (ETH)",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "is-hidden-touch",
                              attrs: {
                                src: "/interface/sort-arrow.png",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": props.row.primaryValueInEth,
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2123346669
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-size-5 is-size-6-mobile",
                  attrs: {
                    field: "largestSecondaryValueInEth",
                    label: "Largest secondary ETH",
                    centered: "",
                    sortable: "",
                    "custom-sort": _vm.sortableString(
                      "largestSecondaryValueInEth"
                    ),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function ({ column }) {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  type: "is-dark",
                                  "append-to-body": "",
                                  label: "largest secondary sale (ETH)",
                                  position: "is-bottom",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.label) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "is-hidden-touch",
                              attrs: {
                                src: "/interface/sort-arrow.png",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth":
                                  props.row.largestSecondaryValueInEth,
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1304157975
                  ),
                }),
                _vm._v(" "),
                _c("b-table-column", {
                  staticClass: "is-hidden-mobile",
                  attrs: { width: "50", numeric: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "topArtworks__btnHolder is-flex is-align-items-center is-justify-content-space-between",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "topArtworks__btn p-0",
                                    class: {
                                      "topArtworks__btn--active":
                                        _vm.view === "detailed",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setView("detailed")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/interface/detailed-view.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "topArtworks__btn p-0",
                                    class: {
                                      "topArtworks__btn--active":
                                        _vm.view === "token",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setView("token")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/interface/token-view.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    26385689
                  ),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view === "token",
                expression: "view === 'token'",
              },
            ],
            staticClass:
              "columns is-multiline topArtworks__tokenView mt-0 pt-4",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "topArtworks__btnHolder--tokenView is-flex is-align-items-center is-justify-content-space-between is-hidden-mobile",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "topArtworks__btn p-0",
                    class: {
                      "topArtworks__btn--active": _vm.view === "detailed",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setView("detailed")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: "/interface/detailed-view.png", alt: "" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "topArtworks__btn p-0",
                    class: { "topArtworks__btn--active": _vm.view === "token" },
                    on: {
                      click: function ($event) {
                        return _vm.setView("token")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: "/interface/token-view.png", alt: "" },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.topTokens.slice(0, 24), function (topToken) {
              return _c(
                "div",
                {
                  key: topToken.tokenId,
                  staticClass:
                    "column is-one-quarter-widescreen is-one-third-desktop is-half-tablet is-full-mobile asset-card mt-3",
                },
                [
                  _c("artworks-card", {
                    staticClass: "has-margin-bottom-4 is-family-tertiary",
                    attrs: {
                      token: topToken,
                      cover: true,
                      resolution: "card",
                      "token-data": [
                        {
                          label: "Total Sale ETH",
                          valueETH: topToken.totalPurchaseValue,
                        },
                        {
                          label: "Sale Count",
                          value: topToken.totalPurchaseCount,
                        },
                        {
                          label: "Largest Sale ETH",
                          valueETH: topToken.largestSalePriceEth,
                        },
                        {
                          label: "Primary Sale ETH",
                          valueETH: topToken.primaryValueInEth,
                        },
                        {
                          label: "Largest Secondary ETH",
                          valueETH: topToken.largestSecondaryValueInEth,
                        },
                      ],
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c(
        "div",
        {
          staticClass:
            "topArtworks__label title mb-3 is-hidden-mobile has-text-weight-bold is-family-tertiary",
        },
        [_vm._v("\n      Top 50 artworks by ETH trade volume\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "topArtworks__label topArtworks__label--mobile title mb-3 is-hidden-tablet has-text-weight-bold is-family-tertiary",
        },
        [_vm._v("\n      Top 50 artworks by ETH trade volume\n    ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }