var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    { attrs: { edition: _vm.edition, "transaction-hash": null } },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    View unlockable content\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _vm.content
            ? _c("section", [
                _vm.content && _vm.content.content_message
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "max-height": "300px",
                          overflow: "scroll",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.content.content_message) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.content && _vm.content.content_link
                  ? _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "is-linkable",
                          attrs: {
                            href: _vm.content.content_link,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.content.content_link))]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("section", [
                _c(
                  "p",
                  { staticClass: "has-text-danger" },
                  [
                    _c("empty-state", {
                      attrs: { message: "Unable to find unlockable content" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: { expanded: "", loading: _vm.loading },
              on: { click: _vm.claimUnlockableContent },
            },
            [_vm._v("\n      Claim unlockable content\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }