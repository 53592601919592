var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { expanded: "", disabled: _vm.saving },
                    on: { click: _vm.close },
                  },
                  [_vm._v("\n            Nevermind\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-danger",
                    attrs: { expanded: "", disabled: _vm.saving },
                    on: { click: _vm.deleteAccount },
                  },
                  [_vm._v("\n            Delete\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c(
        "section",
        { staticClass: "has-margin-7 has-padding-top-7 has-padding-bottom-7" },
        [
          _c("p", { staticClass: "title is-3" }, [
            _vm._v("\n            Delete your profile?\n          "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "mb-4 content notification" }, [
        _c("dl", [
          _c("li", [
            _vm._v("\n              This action "),
            _c("span", { staticClass: "has-text-weight-semibold" }, [
              _vm._v("cannot"),
            ]),
            _vm._v(" be undone\n            "),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n              All off-chain profile data will be removed\n            "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }