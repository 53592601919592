<template>
  <div class="animCta px-4 py-6 is-flex is-flex-direction-column is-align-items-center has-text-white has-text-centered">
    <h2 class="animCta__header is-size-4 my-4  is-size-3-tablet is-family-secondary has-text-weight-black is-uppercase"
      v-html="header" />
    <NuxtLink v-if="!external" class="mb-5" :to="link">
      <button class="animCta__btn py-4 px-5 has-background-white is-size-6">{{ buttonCta }}</button>
    </NuxtLink>
    <a v-if="external" class="mb-5" :href="link">
      <button class="animCta__btn py-4 px-5 has-background-white is-size-6">{{ buttonCta }}</button>
    </a>
  </div>
</template>

<script>
export default {
  props: ['header', 'buttonCta', 'link', 'external'],
  data() {
    return {};
  }
};
</script>
<style lang="scss">
.animCta {
  background: url('/anim/blob-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  position: relative;
  z-index: 2;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.991353) 5.91%, rgba(0, 0, 0, 0.96449) 11.82%, rgba(0, 0, 0, 0.91834) 17.73%, rgba(0, 0, 0, 0.852589) 23.64%, rgba(0, 0, 0, 0.768225) 29.55%, rgba(0, 0, 0, 0.668116) 35.46%, rgba(0, 0, 0, 0.557309) 41.37%, rgba(0, 0, 0, 0.442691) 47.28%, rgba(0, 0, 0, 0.331884) 53.19%, rgba(0, 0, 0, 0.231775) 59.1%, rgba(0, 0, 0, 0.147411) 65.01%, rgba(0, 0, 0, 0.0816599) 70.92%, rgba(0, 0, 0, 0.03551) 76.83%, rgba(0, 0, 0, 0.0086472) 82.74%, rgba(0, 0, 0, 0) 88.65%);
    z-index: -1;
  }

  &__btn {
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }

  /* Tablet */
  @media screen and (max-width: 1216px) {
    background-position: 30% 0;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    background-position: 40% 0;
  }
}
</style>
