<template>
  <div v-if="topTokens" class="topArtworks has-margin-top-7 mb-6 pb-6">
    <section>
      <div
        class="topArtworks__label title mb-3 is-hidden-mobile has-text-weight-bold is-family-tertiary"
      >
        Top 50 artworks by ETH trade volume
      </div>
      <div
        class="topArtworks__label topArtworks__label--mobile title mb-3 is-hidden-tablet has-text-weight-bold is-family-tertiary"
      >
        Top 50 artworks by ETH trade volume
      </div>
      <!-- <b-switch v-model="view" true-value="detailed" false-value="token">
          {{ view === 'detailed' ? 'Detailed view' : 'Highest token sale' }}
        </b-switch> -->
    </section>

    <div v-show="view === 'detailed'">
      <b-table
        :data="topTokens"
        class="topArtworks__table is-family-tertiary"
        default-sort="primaryValueInEth"
        sticky-header
        height="auto"
        striped
        paginated
        pagination-simple
        :per-page="25"
      >
        <b-table-column
          field="index"
          label="Artwork"
          class="is-size-5 is-size-6-mobile has-margin-top-7"
          v-slot="props"
        >
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p class="is-small is-flex is-align-items-center">
                  <n-link
                    :to="{
                      name: 'profile-id',
                      params: {
                        id: props.row.edition.artistAccount.toLowerCase(),
                      },
                    }"
                  >
                    <account-image
                      :address="props.row.edition.artistAccount"
                      :shape="'rounded-edge-image'"
                      class="mr-3 p-0"
                    >
                    </account-image>
                  </n-link>
                  <n-link
                    :to="{ name: 'tokens-id', params: { id: props.row.id } }"
                  >
                    <p class="has-text-weight-normal">
                      {{ props.row.metadata.name }}
                    </p>
                  </n-link>
                </p>
              </div>
            </div>
          </article>
        </b-table-column>

        <b-table-column
          field="artist"
          label="Creator"
          sortable
          :custom-sort="sortableString('artist')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="Artist"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
          </template>
          <template #default="props">
            <user-profile-lookup
              v-if="props.row.edition.collaborators.length <= 1"
              :artist-account="props.row.edition.artistAccount"
              :metadata-artist="props.row.edition.artistAccount"
              :size="'is-small'"
              :no-image="true"
              :no-subtitle="true"
              :is-table="true"
            >
            </user-profile-lookup>
            <span
              v-if="
                props.row.edition.collaborators &&
                props.row.edition.collaborators.length > 1
              "
              class="is-italic is-size-6 m-0 is-family-tertiary"
            >
              collaboration
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="totalPurchaseValue"
          label="Total sale ETH"
          centered
          sortable
          :custom-sort="sortableString('totalPurchaseValue')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="Accumulative trade volume (primary & secondary)"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
            <img
              class="is-hidden-touch"
              src="/interface/sort-arrow.png"
              alt=""
            />
          </template>
          <template #default="props">
            <eth-with-fiat-price
              :price-in-eth="props.row.totalPurchaseValue"
            ></eth-with-fiat-price>
          </template>
        </b-table-column>

        <b-table-column
          field="totalPurchaseCount"
          label="Sale count"
          centered
          sortable
          :custom-sort="sortableString('totalPurchaseCount')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="Total sale count (primary & secondary)"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
            <img
              class="is-hidden-touch"
              src="/interface/sort-arrow.png"
              alt=""
            />
          </template>
          <template v-slot="props">
            <span class="is-family-tertiary">{{
              props.row.totalPurchaseCount
            }}</span>
          </template>
        </b-table-column>

        <b-table-column
          field="largestSalePriceEth"
          label="Largest sale ETH"
          centered
          sortable
          :custom-sort="sortableString('largestSalePriceEth')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="Largest single sale (primary & secondary)"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
            <img
              class="is-hidden-touch"
              src="/interface/sort-arrow.png"
              alt=""
            />
          </template>
          <template #default="props">
            <eth-with-fiat-price
              :price-in-eth="props.row.largestSalePriceEth"
            ></eth-with-fiat-price>
          </template>
        </b-table-column>

        <b-table-column
          field="primaryValueInEth"
          label="Primary sale ETH"
          centered
          sortable
          :custom-sort="sortableString('primaryValueInEth')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="largest primary sale (ETH)"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
            <img
              class="is-hidden-touch"
              src="/interface/sort-arrow.png"
              alt=""
            />
          </template>
          <template #default="props">
            <eth-with-fiat-price
              :price-in-eth="props.row.primaryValueInEth"
            ></eth-with-fiat-price>
          </template>
        </b-table-column>

        <b-table-column
          field="largestSecondaryValueInEth"
          label="Largest secondary ETH"
          centered
          sortable
          :custom-sort="sortableString('largestSecondaryValueInEth')"
          class="is-size-5 is-size-6-mobile"
        >
          <template #header="{ column }">
            <b-tooltip
              type="is-dark"
              append-to-body
              label="largest secondary sale (ETH)"
              position="is-bottom"
              style="pointer-events: none"
            >
              {{ column.label }}
            </b-tooltip>
            <img
              class="is-hidden-touch"
              src="/interface/sort-arrow.png"
              alt=""
            />
          </template>
          <template #default="props">
            <eth-with-fiat-price
              :price-in-eth="props.row.largestSecondaryValueInEth"
            ></eth-with-fiat-price>
          </template>
        </b-table-column>

        <b-table-column width="50" numeric class="is-hidden-mobile">
          <template #header>
            <div
              class="topArtworks__btnHolder is-flex is-align-items-center is-justify-content-space-between"
            >
              <button
                class="topArtworks__btn p-0"
                :class="{ 'topArtworks__btn--active': view === 'detailed' }"
                @click="setView('detailed')"
              >
                <img src="/interface/detailed-view.png" alt="" />
              </button>
              <button
                class="topArtworks__btn p-0"
                :class="{ 'topArtworks__btn--active': view === 'token' }"
                @click="setView('token')"
              >
                <img src="/interface/token-view.png" alt="" />
              </button>
            </div>
          </template>
        </b-table-column>
      </b-table>
    </div>

    <div
      v-show="view === 'token'"
      class="columns is-multiline topArtworks__tokenView mt-0 pt-4"
    >
      <div
        class="topArtworks__btnHolder--tokenView is-flex is-align-items-center is-justify-content-space-between is-hidden-mobile"
      >
        <button
          class="topArtworks__btn p-0"
          :class="{ 'topArtworks__btn--active': view === 'detailed' }"
          @click="setView('detailed')"
        >
          <img src="/interface/detailed-view.png" alt="" />
        </button>
        <button
          class="topArtworks__btn p-0"
          :class="{ 'topArtworks__btn--active': view === 'token' }"
          @click="setView('token')"
        >
          <img src="/interface/token-view.png" alt="" />
        </button>
      </div>
      <div
        class="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet is-full-mobile asset-card mt-3"
        v-for="topToken in topTokens.slice(0, 24)"
        :key="topToken.tokenId"
      >
        <artworks-card
          :token="topToken"
          :cover="true"
          resolution="card"
          class="has-margin-bottom-4 is-family-tertiary"
          :token-data="[
            { label: 'Total Sale ETH', valueETH: topToken.totalPurchaseValue },
            { label: 'Sale Count', value: topToken.totalPurchaseCount },
            {
              label: 'Largest Sale ETH',
              valueETH: topToken.largestSalePriceEth,
            },
            { label: 'Primary Sale ETH', valueETH: topToken.primaryValueInEth },
            {
              label: 'Largest Secondary ETH',
              valueETH: topToken.largestSecondaryValueInEth,
            },
          ]"
        >
        </artworks-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TOP_TOKENS } from "../../queries/hallOfFameQueries";
// import TokenCard from '../../components/TokenCard';
import ArtworksCard from "../ArtworksCard.vue";
import UserProfileLookup from "../../components/UserProfileLookup";
import {
  isCreatorContract,
  splitCreatorEditionId,
} from "../../services/CreatorContractUtils";
import { handleTokenCardRouting } from "../../services/routing";
import EthWithFiatPrice from "@/components/common/EthWithFiatPrice";

export default {
  components: {
    UserProfileLookup,
    ArtworksCard,
    EthWithFiatPrice,
  },
  data() {
    return {
      topTokens: [],
      view: "detailed",
    };
  },
  computed: {
    ...mapState("web3Store", ["gqlClient", "chainId"]),
  },
  mounted() {
    this.$apollo.addSmartQuery("topTokens", {
      client: this.gqlClient,
      query: TOP_TOKENS,
      error(error) {
        console.log("error", error);
      },
    });
    this.scrollTopOnPageChange();
  },
  methods: {
    isCreatorContract,
    splitCreatorEditionId,
    handleTokenCardRouting,
    sortableString(field) {
      return (a, b, isAsc) => {
        return isAsc
          ? parseFloat(b[field]) - parseFloat(a[field])
          : parseFloat(a[field]) - parseFloat(b[field]);
      };
    },
    setView(view) {
      this.view = view;
    },
    scrollTopOnPageChange() {
      const pageLinks = document.querySelectorAll(".pagination-link");

      for (const link of pageLinks) {
        link.addEventListener("click", () => {
          if (!link.getAttribute("disabled")) {
            // setTimeout makes this work on mobile
            setTimeout(() => {
              window.scroll({
                top: 0,
                behavior: "smooth",
              });
            }, 0);
            document.querySelector(".table-wrapper").scrollTop = 0;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.topArtworks {
  position: relative;

  // removing scrollbar
  .table-wrapper {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  &__label {
    position: absolute;
    top: -40px;
    right: 0;

    &--mobile {
      position: relative;
      top: unset;
    }
  }

  &__btnHolder {
    width: 42px;

    &--tokenView {
      position: absolute;
      top: 7px;
      right: 0;
      width: 42px;

      @media screen and (max-width: 768px) {
        top: 30px;
        right: unset;
        left: 0;
      }
    }
  }

  &__btn {
    border: none;
    background: none;
    z-index: 10;
    opacity: 0.3;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &--active {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__table {
    td,
    th {
      padding-left: 0 !important;

      &:last-of-type {
        padding-right: 0;
      }
    }

    thead {
      background: white !important;
    }

    th {
      &:last-of-type {
        opacity: 1;
      }

      &:first-of-type {
        span {
          opacity: 0.55 !important;
        }
      }

      span {
        font-weight: 500;
        white-space: nowrap;
        opacity: 0.7 !important;

        i {
          display: none;
        }
      }
    }

    td {
      /* white-space: nowrap; */
      vertical-align: middle !important;
    }
  }

  .level {
    justify-content: center;
  }

  .info {
    order: 2;
    margin: 0 20px;
  }

  // temp hide pagination until bug is fixed
  .top.level {
    display: none;
  }
}
</style>
