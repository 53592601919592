var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: `${_vm.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${_vm.headerImage}/cover`,
          expression:
            "`${$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${headerImage}/cover`",
          arg: "background-image",
        },
      ],
      staticClass: "header-image has-text-white has-background-grey-darker",
    },
    [
      _c("section", { staticClass: "hero" }, [
        _c("div", { staticClass: "hero-body" }, [
          _c("div", { staticClass: "container content" }, [
            _c(
              "h2",
              {
                staticClass: "title has-text-white has-margin-top-1 is-size-1",
              },
              [
                _vm.headerImage
                  ? _c(
                      "n-link",
                      {
                        staticClass: "has-text-white has-padding-top-6",
                        attrs: { to: _vm.collectionSlug },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.collection) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "field is-grouped" }, [
                _c(
                  "p",
                  { staticClass: "control" },
                  [
                    _c(
                      "n-link",
                      {
                        staticClass: "button is-white is-outlined",
                        attrs: { to: _vm.collectionSlug },
                      },
                      [
                        _vm._v(
                          "\n                View collection\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }