<template>
  <div class="level is-mobile box is-family-tertiary">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Edition #</p>
        <p class="title">#{{ formatId }}</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Editions</p>
        <p class="title" v-if="edition.isOpenEdition">{{ edition.totalSold }}</p>
        <p class="title" v-else>1 of {{ edition.totalAvailable }}</p>
      </div>
    </div>

    <div class="level-item has-text-centered" v-if="!isNeverEndingEdition && isBuyNowAndOpenSalesType">
      <div v-if="edition.isOpenEdition">
        <p class="heading">End date</p>
        <p class="title">
          <date-format :timestamp-in-secs="formatEndDate" :shorter="true"></date-format>
        </p>
      </div>
    </div>

    <div class="level-item has-text-centered" v-if="edition.remainingSupply > 0 && !editionEndDatePassed">
      <div>
        <p class="heading">Available</p>
        <p class="title">
          {{ edition.totalAvailable - edition.totalSupply }}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import {splitCreatorEditionId} from '../../services/CreatorContractUtils';
import { isNeverEndingEdition, endDatePassed } from '../../services/editionUtils';
import { isBuyNowAndOpenSalesType } from '../../services/SaleTypes';
import DateFormat from '../common/DateFormat'

export default {
  components: {DateFormat},
  props: ['edition'],
  computed: {
    formatId() {
      if (this.edition.id.includes('-')) {
        const split = splitCreatorEditionId(this.edition.id)
        return split.editionId
      } else {
        return this.edition.id
      }
    },
    isNeverEndingEdition() {
      return isNeverEndingEdition(this.edition)
    },
    formatEndDate() {
      return parseInt(this.edition.endDate)
    },
    isBuyNowAndOpenSalesType() {
      return isBuyNowAndOpenSalesType(this.edition.salesType)
    },
    editionEndDatePassed() {
      return endDatePassed(this.edition);
    }
  }
}
</script>
