var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isWeb3Connected && _vm.$device.isDesktopOrTablet
    ? _c(
        "b-navbar-dropdown",
        {
          attrs: { arrowless: true, right: true, "close-on-click": true },
          nativeOn: {
            click: function ($event) {
              return _vm.menuOpened.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _c("b-icon", { attrs: { icon: "bell-outline" } }),
              _vm._v(" "),
              _vm.newNotifications.length > 0
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        position: "relative",
                        top: "-8px",
                        left: "-5px",
                      },
                      on: { click: _vm.menuOpened },
                    },
                    [
                      _c("span", { staticClass: "pulse" }, [
                        _c("span", { staticClass: "pulse custom" }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "is-family-tertiary",
              staticStyle: {
                "max-height": "375px",
                "min-width": "300px",
                overflow: "scroll",
              },
            },
            _vm._l(_vm.shortList, function (event, $index) {
              return _c(
                "b-navbar-item",
                { key: $index },
                [
                  _c(
                    "n-link",
                    { attrs: { to: _vm.buildLink(event), tag: "span" } },
                    [
                      _c(
                        "div",
                        { staticClass: "columns is-mobile is-multiline" },
                        [
                          _c("div", { staticClass: "column is-full" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("truncate")(
                                    event.edition.metadataName,
                                    30
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-two-fifths paddding-top-0",
                              staticStyle: { "padding-top": "0" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "nav-item-heading has-text-weight-semibold",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.mapNotification(
                                          event.eventType,
                                          event.eventPrimaryUser
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "column is-one-fifth",
                            staticStyle: { "padding-top": "0" },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "column is-two-fifths",
                              staticStyle: { "padding-top": "0" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "nav-item-heading" },
                                [
                                  _c("date-from", {
                                    attrs: {
                                      "timestamp-in-secs": event.timestamp,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.shortList && _vm.shortList.length === 0
            ? _c("b-navbar-item", { staticClass: "is-family-tertiary" }, [
                _vm._v("\n    No activity\n  "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr", { staticClass: "navbar-divider" }),
          _vm._v(" "),
          _c(
            "n-link",
            {
              staticClass: "navbar-item is-family-tertiary",
              attrs: {
                to: { name: "activity-id", params: { id: _vm.account } },
              },
              on: { click: _vm.closeMenu },
            },
            [_vm._v("\n    My Activity\n  ")]
          ),
        ],
        2
      )
    : _vm.isWeb3Connected && _vm.$device.isMobile
    ? _c(
        "b-navbar-item",
        {
          staticClass: "is-family-tertiary",
          on: { click: _vm.visitMyActivity },
          nativeOn: {
            click: function ($event) {
              return _vm.visitMyActivity.apply(null, arguments)
            },
          },
        },
        [
          _vm._v("\n  My Activity\n  "),
          _vm.newNotifications.length === 0
            ? _c(
                "span",
                {
                  staticStyle: {
                    position: "relative",
                    top: "-25px",
                    left: "98px",
                  },
                  on: { click: _vm.visitMyActivity },
                },
                [
                  _c("span", { staticClass: "pulse" }, [
                    _c("span", { staticClass: "pulse custom" }),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }