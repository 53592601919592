<template>
  <div class="content card m-auto column is-half p-5">
    <h4>
      Restricted marketplaces for this contract
    </h4>
    <div class="mb-2">
      <hr class="m-0 mt-1">
      <marketplace-tag marketplace="LooksRare" :icon="require('../../assets/logos/looksrare.jpeg')" count="1"></marketplace-tag>
      <!--<marketplace-tag marketplace="Blur.io" :icon="require('../../assets/logos/blur.jpeg')" count="2"></marketplace-tag>-->
      <marketplace-tag marketplace="SudoSwap" :icon="require('../../assets/logos/sudoswap.jpeg')" count="2"></marketplace-tag>
      <marketplace-tag marketplace="NFTX" :icon="require('../../assets/logos/nftx.jpeg')" count="3"></marketplace-tag>
    </div>

    <marketplace-blocker-message />

    <div class="columns">
      <div class="column mt-2">
        <b-button expanded outlined @click="$emit('close')">
          Close
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import MarketplaceTag from '../MarketplaceTag';
import MarketplaceBlockerMessage from '../messages/MarketplaceBlockerMessage';
export default {
  components: { MarketplaceBlockerMessage, MarketplaceTag },
};
</script>
