var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.views
    ? _c(
        "span",
        [
          _c("b-icon", { attrs: { icon: "eye-outline", size: "is-small" } }),
          _vm._v(" "),
          _c("span", { staticClass: "has-padding-left-7" }, [
            _vm._v(_vm._s(_vm.views)),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }