<template>
  <n-link :to="{name:'profile-id', params: { id: ethAccount}}">
    <profile-name :eth-account="ethAccount"></profile-name>
  </n-link>
</template>

<script>
  import ProfileName from './ProfileName';
  export default {
    components: {ProfileName},
    props: ['ethAccount']
  };
</script>
