<template>
  <aside class="has-text-centered is-family-tertiary">
    <p class="has-margin-top-1">
      <b-icon icon="emoticon-cry-outline"></b-icon>
    </p>
    <p class="has-margin-bottom-1">{{message}}</p>
  </aside>
</template>

<script>
  export default {
    props: ['message']
  };
</script>
