import Vue from 'vue';
import _toLower from 'lodash/toLower';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import {ethers} from 'ethers';
const USER_SHARE_MODEL_DISMISSED_KEY = 'koda-share-token-modal-dismissed';
const NFT_PROMO_KEY = 'koda-nft-promo';

export const state = () => ({
  profiles: {},
  verifications: {
    empty: {
      instagram: {},
      twitter: {}
    }
  },
  artists: null,
  collections: null
});

export const mutations = {

  storeProfile(state, profile) {
    state.profiles[profile.address.toLowerCase()] = profile;
  },

  storeCollective(state, artists) {
    state.artists = artists;
  },

  storeCollections(state, collections) {
    state.collections = collections;
  },

  storeVerification(state, {account, verification}) {
    if (account) {
      // trigger reactivity of getters
      Vue.set(state.verifications, _toLower(account), verification);
    }
  }

};

export const getters = {
  isTwitterVerified: state => (account, currentHandle) => {
    const {
      verificationSuccessful,
      handle
    } = _get(state.verifications, `[${_toLower(account)}].twitter`, {verificationSuccessful: false});
    return verificationSuccessful && _toLower(handle) === _toLower(currentHandle);
  },
  isInstagramVerified: state => (account, currentHandle) => {
    const {
      verificationSuccessful,
      handle
    } = _get(state.verifications, `[${_toLower(account)}].instagram`, {verificationSuccessful: false});
    return verificationSuccessful && _toLower(handle) === _toLower(currentHandle);
  },
  profileIsFullyVerified: (state, getters) => (profile) => {
    let fullyVerified = false;

    // Enforce both if found
    if (_size(profile.twitter) > 0 && _size(profile.instagram) > 0) {
      fullyVerified = getters.isTwitterVerified(profile.address, profile.twitter) && getters.isInstagramVerified(profile.address, profile.instagram);
    }
    // Enforce only twitter if found
    else if (_size(profile.twitter) > 0) {
      fullyVerified = getters.isTwitterVerified(profile.address, profile.twitter);
    }
    // Enforce only instagram if found
    else if (_size(profile.instagram) > 0) {
      fullyVerified = getters.isInstagramVerified(profile.address, profile.instagram);
    }
    return fullyVerified;
  },
  isEitherProfileVerified: (state, getters) => (profile) => {
    // Enforce only twitter if found
    if (_size(profile.twitter) > 0 && getters.isTwitterVerified(profile.address, profile.twitter)) {
      return true;
    }
    // Enforce only instagram if found
    if (_size(profile.instagram) > 0 && getters.isInstagramVerified(profile.address, profile.instagram)) {
      return true;
    }
    return false;
  }
};

export const actions = {
  async getUser({state, commit, dispatch}, address) {
    address = encodeURIComponent(address)
    if (address && state.profiles[address.toLowerCase()]) {
      return state.profiles[address.toLowerCase()];
    }
    try {
      const profile = await this.$api.$get(`/network/1/accounts/${address}/profile/simple`);
      if (profile.address) {
        // store profile
        commit('storeProfile', profile);
        dispatch('getUserVerificationStatus', profile);
      }
      return profile;
    } catch (error) {
      console.error(error);
    }
    return address;
  },

  async getFullUser({state, rootState, commit, dispatch}, token) {
    if (!token) {
      return Promise.reject();
    }
    try {
      const profile = await this.$api.$get(`/network/1/accounts/${rootState.web3Store.account}/profile`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      });
      if (profile.address) {
        // store profile
        commit('storeProfile', profile);
        dispatch('getUserVerificationStatus', profile);
      }
      return profile;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  async getAuthenticatedUserProfile({rootState, commit, dispatch}) {
    try {
      await dispatch('web3Store/createJwtSession', {}, {root: true});

      if (!rootState.web3Store.account || !rootState.web3Store.authToken) {
        return Promise.reject();
      }
      const canProceed = await dispatch('loggedInUserCanProceedOnKO');
      if (canProceed) {
        const profile = await this.$api.$get(`/network/1/accounts/${rootState.web3Store.account}/profile`, {
          headers: {
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });
        const piiData = await this.$api.$get(`/network/1/accounts/${rootState.web3Store.account}/profilePii`, {
          headers: {
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });
        const mergedProfile = {
          ...profile,
          ...piiData
        }
        if (profile.address) {
          commit('storeProfile', mergedProfile);
          dispatch('getUserVerificationStatus', mergedProfile);
        }
        return mergedProfile;
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
    }
    return Promise.reject();
  },

  async saveProfile({rootState, commit, dispatch}, profile) {
    try {
      await dispatch('web3Store/createJwtSession', {}, {root: true});

      if (!rootState.web3Store.account || !rootState.web3Store.authToken) {
        return Promise.reject();
      }

      const cleanProfile = {
        ...profile
      }
      const piiFields = [
        'fullName',
        'dob',
        'location',
        'twitter',
        'instagram',
        'website',
        'country',
        'streetAddress1',
        'streetAddress2',
        'city',
        'state',
        'postal',
        'email'
      ]

      piiFields.forEach(field => delete cleanProfile[field]);

      const updatedProfile = await this.$api.$put(`/network/1/accounts/${rootState.web3Store.account}/profile`,
      cleanProfile, {
          crossDomain: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });

      const piiData = _pick(profile, piiFields);

      if (Object.keys(piiData).length > 0) {
        await this.$api.$put(`/network/1/accounts/${rootState.web3Store.account}/profilePii`,
        piiData, {
          crossDomain: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });
      }

      const isPiiComplete = [
        'fullName',
        'dob',
        'country',
        'streetAddress1',
        'city',
        'state',
        'postal',
        'email'
      ].reduce((b, f) => (b && piiData[f] && piiData[f].toString().trim() !== ''), true); // !
      await dispatch('analytics/intercomStore/updateUser', {
        email: piiData.email,
        username: profile.username,
        account: rootState.web3Store.account,
        customAttributes: {piiComplete: isPiiComplete}
      }, {root: true})
      return updatedProfile;
    } catch (error) {
      console.error(error);
    }
    return Promise.reject();
  },

  async deleteProfile({rootState, dispatch}) {
    try {
      await dispatch('web3Store/createJwtSession', {}, {root: true});

      if (!rootState.web3Store.account || !rootState.web3Store.authToken) {
        return Promise.reject();
      }

      // Delete image file
      await this.$userMediaApi.$delete(`/media/user/profile/${rootState.web3Store.account}/image`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${rootState.web3Store.authToken}`
        }
      });

      return this.$api.$delete(`/network/1/accounts/${rootState.web3Store.account}/profile`, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${rootState.web3Store.authToken}`
        }
      });
    } catch (error) {
      console.error(error);
    }
    return Promise.reject();
  },

  async saveProfileAvatar({rootState, commit, dispatch}, file) {
    try {
      await dispatch('web3Store/createJwtSession', {}, {root: true});
      const authToken = rootState.web3Store.authToken;

      const payload = new FormData();
      payload.append('avatar', file, file.name);
      payload.append('authToken', authToken);

      // Store file
      const results = await this.$userMediaApi.$post(`/media/user/profile/${rootState.web3Store.account}/image`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${authToken}`
        }
      });
      console.log('Stored profile image @', results.storageUrl);
      return results.storageUrl;
    } catch (e) {
      return null;
    }
  },

  async checkDuplicateUsername({rootState, commit}, username) {
    try {
      const account = rootState.web3Store.account;
      return await this.$api.$get(`/network/1/accounts/checkduplicateusername?username=${username}&address=${account}`);
    } catch (error) {
      console.error(error);
    }
    return false;
  },

  async startUserVerificationFlow({rootState, dispatch}, {provider, address, handle}) {
    await dispatch('web3Store/createJwtSession', {}, {root: true});

    const results = await this.$verificationApi.get(`/${provider}/auth_intent?publicKey=${address}&handle=${handle}`, {
      headers: {
        Authorization: `Bearer ${rootState.web3Store.authToken}`
      }
    });
    if (results && results.data && results.data.url) {
      window.open(results.data.url, '_blank');
    }
    return results;
  },

  async getUserVerificationStatus({commit}, profile) {
    // check verification status if found and the user is an artist
    if (profile.twitter || profile.instagram) {
      const results = await this.$api.get(`/verification/user/${profile.address}`);

      // only store verification results if we have some
      if (results.data && (_size(results.data.instagram) > 0 || _size(results.data.twitter) > 0)) {
        commit('storeVerification', {account: profile.address, verification: results.data});
      }
      return results;
    }
  },

  async getUserVerificationStatusForAccount({commit}, account) {
    const results = await this.$api.get(`/verification/user/${account}`);
    // only store verification results if we have some
    if (results.data && (_size(results.data.instagram) > 0 || _size(results.data.twitter) > 0)) {
      commit('storeVerification', {account, verification: results.data});
    }
    return results;
  },

  async forceReloadUser({state, commit}, address) {
    try {
      const profile = await this.$api.$get(`/network/1/accounts/${address}/profile/simple`);
      if (profile.address) {
        commit('storeProfile', profile);
      }
      return profile;
    } catch (error) {
      console.error(error);
    }
    return address;
  },

  async getUserBySlug({state, commit, dispatch}, slug) {
    if (!slug) {
      console.error('No slug found');
      return;
    }
    slug = encodeURIComponent(slug);
    const foundUser = _find(state.profiles, {slug});
    if (foundUser) {
      return foundUser;
    }
    try {
      const profile = await this.$api.$get(`/network/1/accounts/slug?slug=${slug.toLowerCase()}`);
      if (profile.address) {
        commit('storeProfile', profile);
        dispatch('getUserVerificationStatus', profile);
      }
      return profile;
    } catch (error) {
      console.error(error);
    }
    return slug;
  },

  async getUserBySlugOrAddress({state, dispatch}, addressOrSlug) {
    addressOrSlug = encodeURIComponent(addressOrSlug);
    const isEthAddress = ethers.utils.isAddress(addressOrSlug);
    return isEthAddress
      ? await dispatch('getUser', addressOrSlug)
      : await dispatch('getUserBySlug', addressOrSlug);
  },

  async loadAllArtist({state, commit}) {
    if (state.artists) {
      return state.artists;
    }
    const artists = await this.$cacheApi.$get('/artist/collective');
    commit('storeCollective', artists);
    return artists;
  },

  async getArtistBySlug({dispatch}, slug) {
    slug = encodeURIComponent(slug);
    const artists = await dispatch('loadAllArtist');
    /* eslint lodash/matches-shorthand: "off" */
    return _find(artists, artist => artist.slug === slug);
  },

  async loadAllCollections({state, commit}) {
    if (state.collections) {
      return state.collections;
    }
    const collections = await this.$api.$get('/network/1/accounts/collections');
    commit('storeCollections', collections);
    return collections;
  },

  async uploadCoverImage({state, rootState, commit, dispatch}, file) {
    await dispatch('web3Store/createJwtSession', {}, {root: true});
    const authToken = rootState.web3Store.authToken;

    const payload = new FormData();
    payload.append('cover', file, file.name);
    payload.append('authToken', authToken);

    // Store file
    const results = await this.$userMediaApi.$post(`/media/user/profile/${rootState.web3Store.account}/cover-image`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `bearer ${authToken}`
      }
    });

    // Update profile image
    return await this.$api.$put(`/network/${rootState.web3Store.chainId}/accounts/${rootState.web3Store.account}/profile-cover-image`, {
      coverImageUrl: results.storageUrl
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `bearer ${authToken}`
      }
    });
  },

  async checkUserHasKoProfile({rootState}, {account}) {
    return this.$api.$get(`/network/${rootState.web3Store.chainId}/accounts/profileExists?address=${account}`);
  },

  async isVerifiedArtistOnChain({rootState}, {account}) {
    return this.$api.$get(`/network/${rootState.web3Store.chainId}/merkleuseraccess/user-minting-access/v3/${account}`);
  },

  /* eslint-disable-next-line no-empty-pattern */
  async dismissShareTokenPromptModal({}) {
    console.log('Disable user share token prompt');
    localStorage.setItem(USER_SHARE_MODEL_DISMISSED_KEY, 'true');
  },

  /* eslint-disable-next-line no-empty-pattern */
  async hasUserDismissedShareTokenPromptModal({}) {
    const value = localStorage.getItem(USER_SHARE_MODEL_DISMISSED_KEY);
    return value === 'true'; // string only in local storage
  },

  /* eslint-disable-next-line no-empty-pattern */
  async dismissNFTPromoModal({}) {
    console.log('Disable NFT promo modal');
    localStorage.setItem(NFT_PROMO_KEY, 'true');
  },

  /* eslint-disable-next-line no-empty-pattern */
  async hasUserDismissedNFTPromoModal({}) {
    const value = localStorage.getItem(NFT_PROMO_KEY);
    return value === 'true'; // string only in local storage
  },

  async checkpointLastSeenEventForUser({rootState, dispatch}, {account, event}) {
    if (account) {
      return this.$api.$post(`/network/${rootState.web3Store.chainId}/accounts/${account}/notification/checkpoint`, {
        eventId: event.id,
        eventName: event.eventType,
        eventType: event.type,
        eventTimestamp: event.timestamp,
        transactionHash: event.transactionHash
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'bearer null'
        }
      });
    }
  },

  async getLastSeenEventForUser({rootState}, {account}) {
    return this.$api.$get(`/network/${rootState.web3Store.chainId}/accounts/${account}/notification/checkpoint`);
  },

  async toggleUserEditionLike({rootState, dispatch}, {account, editionNumber, authToken}) {
    if (!authToken) {
      await dispatch('web3Store/createJwtSession', {}, {root: true});
      authToken = rootState.web3Store.authToken;
    }

    if (account && authToken) {
      const res = await this.$api.$post(`/network/${rootState.web3Store.chainId}/likes/`, {
        edition: editionNumber,
        address: account
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${rootState.web3Store.authToken}`
        }
      });
      return {
        like: res.like,
        totalEditionLikes: res.totalEditionLikes ? res.totalEditionLikes : 0
      };
    }
    return {like: false, totalEditionLikes: 0};
  },

  async getUserEditionLike({rootState}, {account, editionNumber}) {
    try {
      if (account && editionNumber) {
        const res = await this.$api.$get(`/network/${rootState.web3Store.chainId}/likes/edition/${editionNumber}/address/${account}`);
        return {
          like: res.like,
          totalEditionLikes: res.totalEditionLikes ? res.totalEditionLikes : 0
        };
      }
    } catch (e) {
      console.error(`Unable to load likes for ${editionNumber}`, e);
    }
    return {like: false, totalEditionLikes: 0};
  },

  // TODO this should not be here
  async getEditionLikes({rootState}, {editionNumber}) {
    try {
      const res = await this.$api.$get(`/network/${rootState.web3Store.chainId}/likes/edition/${editionNumber}`);
      return res.likes ? res.likes : 0;
    } catch (e) {
      console.warn(`Unable to load likes for ${editionNumber}`);
    }
    return 0;
  },

  async claimDonationBadge({rootState}, {transactionLink}) {
    try {
      return await this.$api.$post(`/network/1/accounts/${rootState.web3Store.account}/donations`,
        {transactionLink},
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });
    } catch (error) {
      console.warn('Failed to claim donation', error.response);
      return _get(error, 'response.data', {success: false});
    }
  },

  async checkOpenSeaRoyalties({rootState}) {
    try {
      const res = await this.$api.$get(`/network/${rootState.web3Store.chainId}/merklevault/metadata/${rootState.web3Store.account}`);
      return res;
    } catch (error) {
      console.error('Failed to check royalties', error.response);
      return _get(error, 'response.data', {success: false});
    }
  },

  async checkIfOwnsNFTs({rootState}, {tokenAddress}) {
    try {
      if (rootState.web3Store.account) {
        const res = await this.$api.$get(`/network/${rootState.web3Store.chainId}/erc721/tokens/${tokenAddress}/owned/account/${rootState.web3Store.account}`);
        return res;
      }
    } catch (error) {
      console.error('Failed to check if owns NFTs', error.response);
      return [];
    }
    return [];
  },

  async loggedInUserCanProceedOnKO({rootState}) {
    try {
      if (rootState.web3Store.account) {
        const {canProceed} = await this.$cacheApi.$get(`/sanctions/validate/evm/address/${rootState.web3Store.account}`);
        console.log(`Wallet [${rootState.web3Store.account}] can proceed [${canProceed}]`);
        return canProceed;
      }
    } catch (error) {
      console.error('Failed to check if logged in account can proceed', error.response);
      return false;
    }
  },

  async loggedInUserOnChainValidation({ rootState }, { reason }) {
    try {
      if (rootState.web3Store.account) {
        const payload = new FormData();
        payload.append('reason', reason);

        await this.$cacheApi.$post(`/sanctions/validate/onchain/evm/address/${rootState.web3Store.account}`, payload);
        console.log(`Wallet [${rootState.web3Store.account}] on chain validation sent`);
        return true;
      }
    } catch (error) {
      console.error('Failed to perform on chain validation for logged account ', error.response);
    }
    return false;
  },

  async getContractAddress({rootState}, {chainId, version}) {
    chainId = parseInt(chainId);
    version = parseInt(version);
    if (version === 1) {
      switch (chainId) {
        case 1: // mainnet
          return '0xdde2d979e8d39bb8416eafcfc1758f3cab2c9c72';
        default:
          return false; // invalid chainId
      }
    } else if (version === 2) {
      switch (chainId) {
        case 1: // mainnet
          return '0xfbeef911dc5821886e1dda71586d90ed28174b7d';
        default:
          return false; // invalid chainId
      }
    } else if (version === 3) {
      switch (chainId) {
        case 1: // mainnet
          return '0xABB3738f04Dc2Ec20f4AE4462c3d069d02AE045B';
        default:
          return false; // invalid chainId
      }
    }
  }
};
