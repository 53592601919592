<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">

      <div class="card-content has-padding-7">

        <div class="media">
          <div class="media-content">
            <section v-for="(charity, i) in charities" :key="i"
                     class="level item-container is-mobile hand-pointer"
                     style="height: 80px"
                     :class="{'selected-item': form.selectedCharity && form.selectedCharity.name === charity.name}"
                     @click="selectCharity(charity)">
              <div class="level-left">
                <div class="level-item">
                  <img class="image is-hidden-mobile "
                       style="max-width: 75px; max-height: 75px;"
                       :src="charity.image"
                       :alt="charity.name" />

                  <div v-if="$device.isMobile">
                    <div>
                      <img class="image"
                           style="max-width: 50px; max-height: 50px;"
                           :src="charity.image"
                           :alt="charity.name" />
                    </div>
                    <a :href="charity.website" class="is-link has-text-primary"
                       target="_blank">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <a :href="charity.website" class="is-hidden-mobile is-link has-text-primary pr-3"
                     target="_blank">
                    Learn more
                  </a>
                  <b-icon icon="check-circle-outline" size="is-medium"
                          :class="{
                            'has-text-success': form.selectedCharity && form.selectedCharity.name === charity.name,
                            'has-text-grey': !form.selectedCharity || form.selectedCharity.name !== charity.name,
                          }">
                  </b-icon>
                </div>
              </div>
            </section>

            <section>
              <a href="https://knownorigin.io/journal/platformupdate/a-step-toward-sustainability" class="is-linkable is-primary"
                 target="_blank">
                More information about these charities
              </a>
            </section>
          </div>
        </div>

        <div class="content">
          <section class="has-padding-top-7 has-padding-bottom-7">
            <h2 style="margin-bottom: 5px">
              Donate to sustainable causes
            </h2>
            <h5 class="mb-5">
              Select the charity and amount you wish to donate
            </h5>

            <section class="mt-2">
              <b-field v-for="(amounts, i) in donationsAmount" :key="i">
                <b-radio v-model="form.selectedAmount" :value="amounts.amount" :native-value="amounts.amount">
                  <eth-with-fiat-price :price-in-eth="amounts.amount"
                                       :show-fiat="true"
                                       v-if="amounts.amount !== 'other'">
                  </eth-with-fiat-price>
                  <span v-else>Other</span>
                </b-radio>
              </b-field>
              <b-field label="Enter ETH amount" v-if="form.selectedAmount === 'other'">
                <b-input v-model="form.otherAmount"
                         type="number"
                         placeholder="0.25"
                         min="0.01"
                         step="0.01"
                         expanded
                         autofocus>
                </b-input>
              </b-field>
            </section>

            <section v-if="transactionHash" class="level is-mobile pt-2">
              <div class="level-left">
                <div class="level-item">
                  <b-icon icon="check-circle-outline" size="is-small"></b-icon>
                  &nbsp;
                  <span class="is-uppercase is-size-7">
                    Donation sent
                </span>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <a class="is-muted-7 is-family-tertiary" :href="etherscanTxLink(transactionHash)" target="_blank">
                    [view tx]
                  </a>
                </div>
              </div>
            </section>

          </section>

          <section>
            Get your
            <DonationBadge></DonationBadge>
            badge. <a @click="openDonationClaimModal" class="is-linkable">Claim now</a>
          </section>
        </div>

        <div class="card-footer">
          <div class="card-footer-item">
            <b-button expanded
                      @click="close">
              Nevermind
            </b-button>
          </div>

<!--          <div class="card-footer-item">-->
<!--            <b-button class="button is-primary" expanded-->
<!--                      @click="donate"-->
<!--                      :disabled="!hasCharitySelected || !hasChosenAmount">-->
<!--              Donate-->
<!--            </b-button>-->
<!--          </div>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import DonationBadge from '../badges/DonationBadge';
import DonationClaimModal from './DonationClaimModal';

export default {
  components: {DonationBadge, EthWithFiatPrice},
  data() {
    return {
      // N:B: if adding new charities into here, also please update the API for donation claim flow
      charities: [
        // {
        //   name: 'GreenNFT Grants',
        //   website: 'https://www.artnome.com/greennfts',
        //   description: 'Reward people for trying to improve the energy efficiency of the NFT economy.',
        //   image: '/partners/charities/GreenNFT.png',
        //   address: '0x08022324ACda4d552Dcde677723Ea5F77BFA59AB'
        // },
        {
          name: '350.org',
          website: 'https://350.org',
          description: '350 stand up to the fossil fuel industry to stop new coal, oil and gas projects. Building a clean energy future for all.',
          image: '/partners/charities/350Org.png',
          address: '0x50990F09d4f0cb864b8e046e7edC749dE410916b'
        },
        {
          name: 'GiveDirectly',
          website: 'https://www.givedirectly.org',
          description: 'GiveDirectly is the leading global NGO specialized in delivering digital cash transfers',
          image: '/partners/charities/GiveDirectly_logo.png',
          address: '0x750EF1D7a0b4Ab1c97B7A623D7917CcEb5ea779C'
        },
        {
          name: 'Choose Love',
          website: 'https://chooselove.org/',
          description: 'Choose Love, a restricted charitable fund under the auspices of Prism the Gift Fund (a UK registered charity, charity number 1099682), does whatever it takes to provide refugees and displaced people with everything from lifesaving search and rescue boats to food and legal advice.',
          image: '/partners/charities/choose-love-logo.png',
          address: '0x4f724d44c7789457aff0dc7a9cee3c3fe12928a1'
        }
        // https://freedom.press/donate/cryptocurrency
        // https://shop.wikileaks.org/donate#db10
      ],
      donationsAmount: [
        {amount: '0.01'},
        {amount: '0.03'},
        {amount: '0.05'},
        {amount: 'other'}
      ],
      transactionHash: null,
      form: {
        selectedAmount: null,
        otherAmount: null,
        selectedCharity: null
      }
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'etherscanTxLink',
      'etherscanAddressLink'
    ]),
    hasCharitySelected() {
      return this.form.selectedCharity && this.form.selectedCharity.address;
    },
    hasChosenAmount() {
      return this.form.selectedAmount === 'other'
        ? parseFloat(this.form.otherAmount || '0') >= 0.01
        : this.form.selectedAmount > 0;
    }
  },
  methods: {
    openDonationClaimModal() {
      this.$buefy.modal.open({
        parent: this,
        component: DonationClaimModal,
        width: 600
      });
    },
    close() {
      this.$emit('close');
    },
    selectCharity(charity) {
      if (this.form.selectedCharity === charity) {
        this.form.selectedCharity = null;
      } else {
        this.form.selectedCharity = charity;
      }
    },
    async donate() {
      try {
        if (this.hasCharitySelected && this.hasChosenAmount) {
          const amount = this.form.selectedAmount === 'other'
            ? this.form.otherAmount
            : this.form.selectedAmount;

          const tx = await this.$store.dispatch('web3ActionsStore/launchDonationFlow', {
            amountInEth: amount,
            charity: this.form.selectedCharity.address
          });
          this.transactionHash = tx.hash;

          // wait for the transaction to be mined
          await tx.wait(1);

          this.openDonationClaimModal(); // launch claim modal
          this.close(); // close the model
        }
        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.item-container {
  padding: 1.25rem;
  border-color: $grey;
  border-style: solid;
  border-width: thin;
}

.selected-item {
  border-color: $green;
  border-style: solid;
  border-width: thin;
}
</style>
