var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "column is-justify-content-start ml-0 pl-0 mt-0 pt-0" },
    [
      _c("div", { staticClass: "is-inline-block mr-2 mt-2" }, [
        _c(
          "div",
          {
            staticClass: "share-container",
            on: { click: _vm.copyURLWithToast },
          },
          [
            _c(
              "a",
              {
                staticClass: "share-icon",
                attrs: { "data-cy": "twitter_address", target: "_blank" },
              },
              [
                _c("b-icon", { attrs: { icon: "content-copy" } }),
                _vm._v(" "),
                _c("span", { staticClass: "verified-icon" }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-inline-block mr-2 mt-2" }, [
        _c(
          "div",
          {
            staticClass: "share-container",
            on: {
              click: function ($event) {
                return _vm.launchTwitterIntent()
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "share-icon",
                attrs: { "data-cy": "twitter_address", target: "_blank" },
              },
              [
                _c("b-icon", {
                  staticClass: "twitter-icon",
                  attrs: { icon: "twitter" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-inline-block mr-2 mt-2" }, [
        _c(
          "div",
          {
            staticClass: "share-container",
            on: {
              click: function ($event) {
                return _vm.launchFacebookIntent()
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "share-icon",
                attrs: { "data-cy": "facebook_address", target: "_blank" },
              },
              [
                _c("b-icon", {
                  staticClass: "facebook-icon",
                  attrs: { icon: "facebook" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-inline-block mr-2 mt-2" }, [
        _c(
          "div",
          {
            staticClass: "share-container",
            on: {
              click: function ($event) {
                return _vm.launchRedditIntent()
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "share-icon",
                attrs: { "data-cy": "reddit_address", target: "_blank" },
              },
              [
                _c("b-icon", {
                  staticClass: "reddit-icon",
                  attrs: { icon: "reddit" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }