<template>
  <eth-with-fiat-price v-if="priceInWei" :price-in-wei="priceInWei"></eth-with-fiat-price>
</template>
<script>
  import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

  export default {
    components: {EthWithFiatPrice},
    data() {
      return {
        priceInWei: null
      }
    },
    mounted() {
      this.$parent.$store.dispatch('web3Store/getAccountBalance')
        .then((balance) => {
          this.priceInWei = balance;
        })
    }
  }
</script>
