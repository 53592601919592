<template>
  <div v-touch:tap="oneClick" class="disable-dbl-tap-zoom">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: [],
      delay: 400,
      clicks: 0,
      timer: null
    };
  },
  methods: {
    oneClick (e) {
      this.clicks++;
      if (this.clicks === 1) {
        // handle ctrl click for open new tab
        const newWindow = e.altKey || e.ctrlKey || e.shiftKey || e.metaKey;

        const self = this;
        this.timer = setTimeout(() => {
          this.$emit('single-click', newWindow);
          self.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.$emit('double-click');
        this.clicks = 0;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.disable-dbl-tap-zoom {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
</style>
