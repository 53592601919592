var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.item
      ? _c(
          "div",
          {
            staticClass:
              "is-full columns is-flex-mobile p-0 my-0 search-item-style",
            class: { "search-item-style-top": _vm.item.__position === 1 },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "is-flex is-align-items-center search-rounded-image-container",
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: {
                        src: `${_vm.$userMediaApi.defaults.baseURL}/media/user/profile/${_vm.userAddress}`,
                        error: _vm.createAccountIcon(),
                      },
                      expression:
                        "{\n          src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${userAddress}`,\n          error: createAccountIcon(),\n        }",
                    },
                  ],
                  staticClass: "rounded-image",
                }),
              ]
            ),
            _vm._v(" "),
            _vm.profile
              ? _c("section", { staticClass: "column is-centered px-2 py-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "has-text-left is-size-6 ml-1 is-flex is-align-items-center",
                    },
                    [
                      _c("span", { staticClass: "is-family-secondary" }, [
                        _vm._v(
                          _vm._s(
                            _vm.nameOrShortEth.length > _vm.textWrapCap
                              ? _vm.nameOrShortEth.substring(
                                  0,
                                  _vm.textWrapCap
                                ) + "..."
                              : _vm.nameOrShortEth
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.profile &&
                      _vm.isTwitterVerified(
                        _vm.userAddress.toLowerCase(),
                        _vm.profile.twitter
                      )
                        ? _c("b-icon", {
                            staticClass:
                              "ml-2 is-size-5 is-size-6-mobile is-verified",
                            attrs: { icon: "check-decagram", size: "is-small" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }