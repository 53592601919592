<template>
  <div
    @click="
      handleContractRouting(
        item,
      )
    "
    v-if="item && (!contractBlocked && transferState !== 1)"
    class="is-full columns is-flex-mobile my-0 search-item-style__invert rounded-box"
    :class="{ 'search-item-style-top': index === 0 }"
  >
  <div class="is-flex is-align-items-center search-rounded-image-container">
    <img
      class="rounded-image"
      v-lazy="{
          src: imageUrl,
          error: createContractImage(),
        }"
    />
  </div>
    <section
      class="column is-centered px-3 pr-0 is-inline-flex is-justify-content-space-between "
    >
      <div class="is-flex is-align-items-center">
        <p
          class="has-text-left is-size-6 ml-1 has-text-weight-light is-family-secondary"
        >
          {{
            item.name.length >
            textWrapCap
              ? item.name.substring(
              0,
              textWrapCap
            ) + '...'
              : item.name
          }}
        </p>
        <p v-if="owner && owner.username" class="is-size-7 ml-3 has-text-weight-light is-family-tertiary contract-owner-text">
          {{ owner.username }}
        </p>
      </div>
      <div>
        <span v-if="contractStats"
              class="has-text-weight-medium is-family-tertiary">{{
            contractStats.totalNumOfEditions
          }} Edition{{ parseInt(contractStats.totalNumOfEditions) > 1 || parseInt(contractStats.totalNumOfEditions) === 0 ? 's' : '' }}</span>
      </div>
    </section>
  </div>
</template>

<script>
import {toDataUrl} from 'myetherwallet-blockies';
import {mapState} from 'vuex';
import {CONTRACT_BLOCKED_AND_TRANSFER_STATE, CONTRACT_EDITIONS} from '../../queries/creatorContracts';

export default {
  name: 'ContractSearchResultRow',
  props: ['item', 'index', 'textWrapCap', 'handleContractRouting', 'sortBy'],
  data() {
    return {
      owner: null,
      imageUrl: null,
      contractStats: null,
      contractBlocked: false,
      transferState: 0
    }
  },
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  mounted() {
    this.getOwnerData()
    this.getContractImage()
    this.getContractStats()
  },
  methods: {
    async getOwnerData() {
      if (this.item?.owner) {
        this.owner = await this.$store.dispatch('userStore/getUser', this.item.owner);
      }
    },
    async getContractImage() {
      this.imageUrl = await this.$store.dispatch('creatorContractStore/fetchContractImage', {
        address: this.item.address,
        imageType: 'thumbnail'
      })
    },
    getContractStats() {
      this.$apollo.addSmartQuery('contractStats', {
        client: this.gqlClient,
        query: CONTRACT_EDITIONS,
        variables() {
          return {
            id: this.item.address
          };
        },
        result({data}) {
          if (data && data.contractStats) {
            this.contractStats = data.contractStats
          }
        },
        error(error) {
          console.log('error', error);
        }
      });
      this.$apollo.addSmartQuery('contractStats', {
        client: this.gqlClient,
        query: CONTRACT_BLOCKED_AND_TRANSFER_STATE,
        variables() {
          return {
            id: this.item.address
          };
        },
        result({data}) {
          if (data.contracts && data.contracts[0]) {
            if (data.contracts[0].isHidden) {
              this.contractBlocked = true
            }
            if (data.contracts[0].transferState) {
              this.transferState = parseInt(data.contracts[0].transferState)
            }
          }
        },
        error(error) {
          console.log('error', error);
        }
      });
    },
    createContractImage() {
      return toDataUrl(this.item.address);
    }
  }
};
</script>

<style lang="scss" scoped>
.contract-owner-text {
  line-height: 16px;
}

</style>
