<template>
  <div class="trending-image">
    <ApolloQuery
      v-if="edition && edition.id"
      :query="require('../../queries/editionQueries').EDITION_BY_ID_WITH_TOKENS_QUERY"
      :variables="{ id: edition.id }"
      :client-id="gqlClient">
      <template #default="{ result: { loading, data } }">
        <!-- Edition Image -->
        <div v-if="!loading && data && data.editionById[0]" class="trending-image-card">
          <image-for-asset-card
            :edition="data.editionById[0]"
            cover="true"
            resolution="card"
            :disable-icons="true" />

          <p class="trending-image-title has-text-weight-bold txt-overflow is-family-tertiary">{{ data.editionById[0].metadata.name }}</p>
        </div>

        <!-- Skeleton -->
        <div v-else>
          <img src="/branding/bigloader-image.svg" :class="{'card-image-cover': true}" />
          <b-skeleton active width="200px" height="12px" />
        </div>
      </template>
    </ApolloQuery>

    <!-- Skeleton -->
    <div v-else>
      <img src="/branding/bigloader-image.svg" :class="{'card-image-cover': true}" />
      <b-skeleton active width="200px" height="12px" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['edition'],
  computed: {
    ...mapState(['gqlClient'])
  }
}
</script>

<style lang="scss">
.trending-image {
  display: block;
  margin-top: auto;

  .card-image-cover {
    height: auto !important;
    aspect-ratio: 1 / 1.15;
  }
}

.trending-image-card {
  transition: transform .2s ease;

  &:hover {
    transform: translateY(-8px);
  }
}

.trending-image-title {
  font-size: 13px;
  opacity: 0.7;
}
.txt-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
