const KO_TWITTER = '@KnownOrigin_io';
const KO_INSTA = '@KnownOrigin.io';

const COMMON_TAGS = '#cryptoart #NFT #cryptoartist #ETH';

const getProfileTwitterOrUsername = (profile) => {
  if (profile && profile.twitter) {
    return `@${profile.twitter}`;
  }

  if (profile && profile.username) {
    return `${profile.username}`;
  }

  return profile.address;
};

const getProfileInstaOrUsername = (profile) => {
  if (profile && profile.instagram) {
    return `@${profile.instagram}`;
  }

  if (profile && profile.username) {
    return `${profile.username}`;
  }

  return profile.address;
};

const getTweet = (id, profile, shareUrl, ...args) => {
  switch (id) {
    case 'COLLECTION':
      return `${args[0]} collection by ${getProfileTwitterOrUsername(profile)} on ${KO_TWITTER}

${shareUrl}

Curated by ${getProfileTwitterOrUsername(profile)}

#KOllections ${COMMON_TAGS}
`;

    case 'KO_COLLECTION':
      return `${args[1]}\n\n${args[0]} collection on ${KO_TWITTER}

${shareUrl}

#KOllections ${COMMON_TAGS}
`;

    case 'GALLERY_ID':
      return `${args[0]}

${shareUrl}

${KO_TWITTER}

Art by ${getProfileTwitterOrUsername(profile)}

${COMMON_TAGS}
`;

    case 'CONTRACT':
      return `${args[0].name}

${shareUrl}

${KO_TWITTER}

Contract by ${getProfileTwitterOrUsername(profile)}

${COMMON_TAGS}
`;

    case 'CC_TOKEN':
      return `${args[0].metadata.name}

${shareUrl}

${KO_TWITTER}

Art by ${getProfileTwitterOrUsername(profile)}

${COMMON_TAGS}
`;

    default:
      return 'Check out KnownOrigin.io';
  }
};

const getInsta = (id, profile, shareUrl, ...args) => {
  switch (id) {
    case 'COLLECTION':
      return `${args[0]} collection by ${getProfileInstaOrUsername(profile)} on ${KO_INSTA}

${shareUrl}

Curated by ${getProfileInstaOrUsername(profile)}

#KOllections ${COMMON_TAGS}
`;

    case 'KO_COLLECTION':
      return `${args[1]}\n\n${args[0]} collection on ${KO_INSTA}

${shareUrl}

#KOllections ${COMMON_TAGS}
`;

    case 'GALLERY_ID':
      return `

${shareUrl}

${KO_INSTA}

Art by ${getProfileInstaOrUsername(profile)}

${COMMON_TAGS}
`;

    case 'CONTRACT':
      return `${args[0].name}


${shareUrl}

${KO_INSTA}

Contract by ${getProfileInstaOrUsername(profile)}

${COMMON_TAGS}
`;
    case 'CC_TOKEN':
      return `${args[0].metadata.name}

Art by ${getProfileInstaOrUsername(profile)}

${shareUrl}

${KO_INSTA}

${COMMON_TAGS}
      `
    default:
      return 'Check out KnownOrigin.io';
  }
};

export {
  getTweet,
  getInsta,
  KO_INSTA,
  KO_TWITTER
};
