<template>
  <modal-template :edition="token" :token="token" :transaction-hash="transactionHash">

    <template slot="content-title">
      Transfer
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="token.metadata" :edition-number="token.editionNumber">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <div slot="secondary-content">
      <b-field label="Wallet address or ENS name">
        <b-input v-model="addressOrEns"
                 placeholder="0x123 or .eth"
                 @change="isValid = false"
                 autofocus
                 @input="validateAddressOrEns"></b-input>
      </b-field>
      <div class="has-margin-top-7 is-small is-muted" v-if="resolvedLabel">
        <b-icon icon="check-circle-outline" size="is-small"></b-icon>
        {{ resolvedLabel }}
      </div>
    </div>

    <template slot="action-feedback-label">
      Transferring token
    </template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="!isValid || transactionHash"
                @click="transferToken">
        Transfer token
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import _endsWith from 'lodash/endsWith';
import _debounce from 'lodash/debounce';
import _trim from 'lodash/trim';
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';

export default {
  components: {ArtworkNameAndArtist, ModalTemplate},
  props: ['token'],
  data() {
    return {
      transactionHash: null,
      addressOrEns: null,
      resolvedLabel: null,
      resolvedAddress: null,
      isValid: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    validateAddressOrEns: _debounce(
      async function () {
        this.isValid = false;
        this.resolvedAddress = null;
        this.resolvedLabel = null;

        const workingAddressOrEns = _trim(this.addressOrEns);

        if (ethers.utils.isAddress(workingAddressOrEns)) {
          this.resolvedAddress = workingAddressOrEns;
          const ensName = await this.$cacheApi.$get(`/network/1/ens/reverse/${workingAddressOrEns}`);
          if (ensName) {
            this.resolvedLabel = ensName;
          } else {
            this.resolvedLabel = workingAddressOrEns;
          }
          this.isValid = true;
        }
        if (workingAddressOrEns && _endsWith(workingAddressOrEns, '.eth')) {
          const ensName = await this.$cacheApi.$get(`/network/1/ens/resolve/${workingAddressOrEns}`);
          if (ensName) {
            this.resolvedAddress = ensName;
            this.resolvedLabel = ensName;
            this.isValid = true;
          }
        }
        return true;
      },
      1000 // 1 second
    ),
    async transferToken() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/transferToken', {
          token: this.token,
          address: this.resolvedAddress,
          version: this.token.version
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    // On any change assume invalid
    addressOrEns() {
      this.isValid = false;
    }
  }
}
</script>
