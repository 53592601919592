import gql from 'graphql-tag';
import {COLLECTOR, LISTING_FIELDS, METADATA_FIELDS} from './_commonModels';
import {EDITION_FIELDS} from './editionQueries';

export const ACTIVITY = gql(`
  query activityEvents ($first: Int!, $skip: Int!, $eventTypes: [String!]) {
    activityEvents(first: $first, skip: $skip, orderBy: timestamp, orderDirection:desc, subgraphError: allow,
    where: {
        eventType_in: $eventTypes
    }) {
      id
      type
      eventType
      edition {
        ${EDITION_FIELDS}
      }
      token {
        id
        version
        primaryValueInEth
        birthTimestamp
        editionNumber
        tokenURI
        artistAccount
        edition {
          id
          collaborators
          totalSupply
          totalAvailable
          collective {
            recipients
          }
        }
        ${COLLECTOR('currentOwner')}
        ${METADATA_FIELDS}
        ${LISTING_FIELDS}
      }
      timestamp
      eventValueInWei
      creator
      creatorCommission
      collaborator
      collaboratorCommission
      transactionHash
      triggeredBy
      buyer
      seller
    }
  }
`);

export const EDITION_HISTORY = gql(
  `
query activityEvents ($edition: String!) {
  activityEvents(where: {
  edition: $edition,
  eventType_in: ["EditionCreated","Purchase", "Transfer", "BidPlaced", "BidAccepted", "BidIncreased", "BidRejected", "BidWithdrawn", "ReserveExtended", "ReserveCountdownStarted", "ReserveBidWithdrawn", "ReserveBidPlaced", "ComposableAdded", "ComposableClaimed"]
  }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
    id
    type
    eventType
    timestamp
    eventValueInWei
    creator
    creatorCommission
    collaborator
    collaboratorCommission
    transactionHash
    triggeredBy
    buyer
    seller
  }
}
`);

export const EDITION_CREATION_EVENT = gql(
  `
query creationEvent ($edition: String!) {
  creationEvent: activityEvents(
  where: {
      edition: $edition,
      eventType: "EditionCreated"
  }, orderBy: timestamp, orderDirection: desc, limit: 1, subgraphError: allow) {
    id
    type
    eventType
    timestamp
    eventValueInWei
    creator
    creatorCommission
    collaborator
    collaboratorCommission
    transactionHash
    triggeredBy
    buyer
    seller
  }
}
`);

export const TOKEN_HISTORY = gql(`
query activityEvents ($token: String!) {
  activityEvents(where: {
  token: $token,
  eventType_in: ["EditionCreated", "Transfer", "Purchase", "BidPlaced", "BidAccepted", "BidIncreased", "BidRejected", "BidWithdrawn", "TokenListed", "TokenDeListed", "ReserveExtended", "ReserveCountdownStarted", "ReserveBidWithdrawn", "ReserveBidPlaced"]
  }, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
    id
    type
    eventType
    timestamp
    eventValueInWei
    creator
    creatorCommission
    collaborator
    collaboratorCommission
    transactionHash
    triggeredBy
    buyer
    seller
  }
}
`);

export const GET_CREATOR_HISTORY = gql`
  query GetCreatorActivity($creator:String!){
    activity: activityEvents(
      where: {
        creator: $creator,
        eventType_in: ["Purchase", "BidAccepted"]
      } first: 1000, subgraphError: allow) {
      id
      edition {
        id
      }
      token {
        id
      }
      type
      buyer
      eventType
      eventValueInWei
      transactionHash
      creator
      creatorCommission
      timestamp
    }
  }
`

export const GET_COLLABORATOR_HISTORY = gql`
  query GetCollaboratorActivity($collaborator:String!){
    activity: activityEvents(
      where: {
        collaborator: $collaborator,
        eventType_in: ["Purchase", "BidAccepted"]
      } first: 1000, subgraphError: allow) {
      id
      edition {
        id
      }
      token {
        id
      }
      type
      buyer
      eventType
      eventValueInWei
      transactionHash
      collaborator
      collaboratorCommission
      timestamp
    }
  }
`

export const GET_BUYER_HISTORY = gql`
  query GetBuyerActivity($buyer:String!){
    activity: activityEvents(
      where:{
        buyer: $buyer,
        eventType_in: ["Purchase", "BidAccepted"]
      } first: 1000, subgraphError: allow){
      id
      edition{
        id
      }
      token {
        id
      }
      buyer
      type
      eventType
      eventValueInWei
      transactionHash
      timestamp
    }
  }
`

export const ACTIVITY_EVENT_FIELDS = `
id
      type
      eventType
      edition {
        creatorContract {
          isHidden
        }
        id
        isOpenEdition
        metadataName
        metadataArtist
        artistAccount
        collaborators
        version
        salesType
        priceInWei
        totalSupply
        totalAvailable
        totalSold
        endDate
        ${METADATA_FIELDS}
      }
      token {
        id
        version
        primaryValueInEth
        birthTimestamp
        editionNumber
        tokenURI
        artistAccount
        edition {
          id
          collaborators
          totalSupply
          totalAvailable
        }
        ${COLLECTOR('currentOwner')}
        ${METADATA_FIELDS}
      }
      timestamp
      eventValueInWei
      creator
      creatorCommission
      collaborator
      collaboratorCommission
      transactionHash
      triggeredBy
      buyer
      seller`;

export const GET_BUYER_NOTIFICATION_EVENTS = gql(`
  query GetBuyerActivity($address:String!){
    activity: activityEvents(
      where:{
        buyer: $address,
        eventType_in: ["Purchase", "BidPlaced", "BidAccepted", "BidIncreased", "BidRejected", "BidWithdrawn", "TokenDeListed", "TokenListed", "ReserveExtended", "ReserveCountdownStarted", "ReserveBidWithdrawn", "ReserveBidPlaced"]
      }
      orderBy: timestamp
      orderDirection: desc
      first: 20
      subgraphError: allow) {
      ${ACTIVITY_EVENT_FIELDS}
    }
  }
`);

export const GET_CREATOR_NOTIFICATION_EVENTS = gql(`
  query GetBuyerActivity($address:String!){
    activity: activityEvents(
      where:{
        creator: $address,
        eventType_in: ["EditionCreated", "EditionGifted", "PriceChanged", "Purchase", "BidPlaced", "BidAccepted", "BidIncreased", "BidRejected", "BidWithdrawn"]
      }
      orderBy: timestamp
      orderDirection: desc
      first: 20
      subgraphError: allow) {
      ${ACTIVITY_EVENT_FIELDS}
    }
  }
`)

export const GET_SELLER_NOTIFICATION_EVENTS = gql(`
  query GetSellerActivity($address:String!){
    activity: activityEvents(
      where:{
        seller: $address,
        eventType_in: ["Purchase", "BidAccepted"]
      }
      orderBy: timestamp
      orderDirection: desc
      first: 20
      subgraphError: allow) {
      ${ACTIVITY_EVENT_FIELDS}
    }
  }
`)
