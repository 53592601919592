var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-taglist", { attrs: { attached: "" } }, [
        _c(
          "div",
          [
            _vm.flames === 3
              ? _c(
                  "b-tag",
                  {
                    staticClass: "is-family-primary",
                    attrs: { type: "is-secondary" },
                  },
                  [_vm._v("\n        🔥🔥🔥\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.flames === 2
              ? _c(
                  "b-tag",
                  {
                    staticClass: "is-family-primary",
                    attrs: { type: "is-secondary" },
                  },
                  [_vm._v("\n        🔥🔥\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.flames === 1
              ? _c(
                  "b-tag",
                  {
                    staticClass: "is-family-primary",
                    attrs: { type: "is-secondary" },
                  },
                  [_vm._v("\n        🔥\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.artistStats
          ? _c(
              "div",
              {
                staticClass: "is-family-tertiary has-text-weight-medium",
                class: _vm.noPadding ? "pl-0" : "pl-3",
              },
              [
                _vm.artistStats.salesCount === "1"
                  ? _c("b-tag", { class: _vm.strong ? "tag--strong" : "tag" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.artistStats.salesCount) +
                          " Sale\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.artistStats.salesCount === "0"
                  ? _c(
                      "b-tag",
                      {
                        staticClass: "mb-0",
                        class: _vm.strong ? "tag--strong" : "tag",
                      },
                      [_vm._v("\n        New Artist\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.artistStats.salesCount !== "0" &&
                _vm.artistStats.salesCount !== "1"
                  ? _c(
                      "b-tag",
                      {
                        staticClass: "mb-0",
                        class: _vm.strong ? "tag--strong" : "tag",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.artistStats.salesCount) +
                            " Sales\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }