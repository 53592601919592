var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "has-text-centered is-family-tertiary" }, [
    _c(
      "p",
      { staticClass: "has-margin-top-1" },
      [_c("b-icon", { attrs: { icon: "emoticon-cry-outline" } })],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "has-margin-bottom-1" }, [
      _vm._v(_vm._s(_vm.message)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }