<template>
  <section class="journalHeader">
    <NuxtLink class="journalHeader__back mb-3 is-size-6 has-text-weight-bold is-family-tertiary" to="/journal">Back to Journals</NuxtLink>
    <div class="mb-5">
      <b-tag class="is-uppercase is-family-tertiary has-text-weight-medium mr-3" type="is-secondary">
        {{ content.journalType }}
      </b-tag>
      <time class="is-vcentered">
        <b-icon icon="calendar" size="is-small"></b-icon>
        <span class="is-size-7 is-family-tertiary has-text-weight-bold">
          {{ new Date(content.date) | moment("from") }}
        </span>
      </time>
    </div>
    <h1 class="journalHeader__header is-family-secondary has-text-weight-black is-size-4 is-size-3-tablet is-size-2-desktop">
      {{ content.title }}
    </h1>
    <img :src="content.leadimage" class="journalHeader__image" />
    <p
      v-if="content.description"
      class="journalHeader__description is-family-secondary has-text-weight-light is-size-6 is-size-4-tablet pb-6">
      {{ content.description }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'JournalHeader',
  props: {
    content: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/utilities/mixins";

.journalHeader {
  border-bottom: 1px solid #dadada;

  &__back {
    display: inline-block;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__header {
    letter-spacing: -0.01em;
    max-width: 860px;
    line-height: 1.4;
  }

  &__image {
    width: 100%;
    display: block;
    margin: 20px 0 28px;
    max-width: none;
  }
}
/*----------------------------------------*/

// > 1024px
@include desktop {
  .journalHeader {
    &__image {
      margin: 40px 0 48px;
    }
  }
}
</style>
