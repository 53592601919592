var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _vm.workingMetadata && _vm.edition
      ? _c(
          "div",
          { staticClass: "card", class: { ccArtwork: _vm.isCc } },
          [
            _c("image-for-asset-card", {
              attrs: {
                edition: _vm.edition,
                cover: _vm.cover,
                resolution: _vm.resolution,
                metadata: _vm.workingMetadata,
                "disable-icons": !!_vm.disableIcons,
                "is-cc": _vm.isCc,
              },
            }),
            _vm._v(" "),
            _vm._t("artwork-info-asset-card", function () {
              return [
                _c("artwork-info", {
                  attrs: {
                    edition: _vm.edition,
                    size: _vm.size,
                    phase: _vm.gatedSalePhase,
                  },
                  on: { "editions-refresh": _vm.editionsRefresh },
                }),
              ]
            }),
            _vm._v(" "),
            _c(
              "footer",
              {
                staticClass: "card-footer is-clipped min-height-90",
                class: { borderGrey: _vm.isCc },
              },
              [
                _vm._t("footer-left-asset-card", function () {
                  return [
                    _c("footer-left-asset-card", {
                      attrs: {
                        edition: _vm.edition,
                        phase: _vm.gatedSalePhase,
                      },
                    }),
                  ]
                }),
                _vm._v(" "),
                _vm._t("footer-right-asset-card", function () {
                  return [
                    _c("footer-right-asset-card", {
                      attrs: {
                        edition: _vm.edition,
                        phase: _vm.gatedSalePhase,
                      },
                    }),
                  ]
                }),
              ],
              2
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }