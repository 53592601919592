var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.likes && _vm.likes.length > 0
    ? _c(
        "div",
        { staticClass: "columns is-multiline" },
        [
          _vm._l(_vm.likes, function (like, $index) {
            return _c(
              "div",
              {
                key: $index,
                staticClass:
                  "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card",
              },
              [
                _c("edition-wrapper", {
                  attrs: {
                    id: like.edition,
                    cover: true,
                    resolution: "card",
                    size: "is-small",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.likes.length < _vm.totalAvailable
            ? _c(
                "div",
                { staticClass: "column is-fullwidth has-text-centered" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", outlined: "" },
                      on: { click: _vm.loadFavourites },
                    },
                    [_vm._v("Show more")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _c("div", [_c("empty-state", { attrs: { message: "No favourites" } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }