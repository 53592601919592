<template>
  <div class="column is-half has-padding-left-3 has-padding-top-0 is-flex is-align-items-center"
       :class="{'countdown-started': reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete && !isCc}">
    <div>
      <div class="has-text-weight-bold has-text-left">
        <slot name="asset-card-footer-left-title"
        data-testid="cardFooter">
          <n-link :to="handleMarketPlaceRouting" :class="{ccAssetText: isCc}" class="asset-card-wrapped-link is-family-tertiary">
            <div v-if="phase && phase.state !== 'public' && isNotSoldOutPrimary" data-testid="saleType">
              <div>
                <p :class="{'is-muted': phase.state === 'pre-phase' && !isCc, 'has-text-white': isCc}"
                >Early access</p>
              </div>
            </div>
            <span v-else-if="!isSoldOutPrimary" :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
              <span v-if="isOffersOnlySalesType(edition.salesType) && editionOffers && editionOffers.length > 0">
                Current bid
              </span>
              <span v-else-if="isOffersOnlySalesType(edition.salesType)" :class="{'has-text-grey': !startDatePassed && !isCc}">
                Place a bid
              <span v-if="getEditionMinimumOffer(edition)" :class="{'has-text-grey': !isCc}">
                (minimum)
              </span>
              </span>
              <span v-else-if="isBuyNowAndOpenSalesType(edition.salesType)"
                    :class="{'has-text-grey': !startDatePassed && !isCc}">
                Open edition
              </span>
              <span v-else-if="isBuyableSalesType(edition.salesType)"
                    :class="{'has-text-grey': !startDatePassed && !isCc}" data-testid="saleType">
                Buy now
              </span>
              <span v-else-if="reserveAuctionCountdownInProgress" :class="{ccAssetText: isCc}">
                Current bid
              </span>
              <span v-else-if="reserveAuctionCountdownComplete" :class="{ccAssetText: isCc}">
                Sold for
              </span>
              <span v-else-if="isReserveAuctionSalesType(edition.salesType)"
                    :class="{'has-text-grey': !startDatePassed && !isCc}">
                Reserve price
              </span>
            </span>
            <span v-else>
              <span v-if="lowestListedToken && isBuyNowSalesType(lowestListedToken.salesType)"
              data-testid="saleType">
                Buy now
              </span>
              <span v-else-if="lowestListedToken && isReserveAuctionSalesType(lowestListedToken.salesType)">
                Reserve price
              </span>
              <span v-else-if="parseInt(edition.totalAvailable) === 1 && parseFloat(edition.totalEthSpentOnEdition) > 0">
                Sold for
              </span>
              <span v-else>{{ isBuyNowAndOpenSalesType(edition.salesType) ? edition.totalSold : edition.totalAvailable }} Editions</span>
            </span>
          </n-link>
        </slot>
      </div>
      <div class="has-text-weight-semibold has-text-left">
        <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link is-family-tertiary" :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
          <slot name="asset-card-footer-left">
            <div v-if="phase && phase.state !== 'public'" :class="{'has-text-white': isCc}">
              <p v-if="phase.priceInWei === '0'"
                class="has-text-weight-semibold"
                :class="{'is-muted': phase.state === 'pre-phase' && !isCc}">
                Free claim
              </p>
              <eth-with-fiat-price
                v-else
                :price-in-wei="phase.priceInWei"
                class="has-text-weight-semibold"
                :class="{'is-muted': phase.state === 'pre-phase' && isCc}"
              >
              </eth-with-fiat-price>
            </div>
            <span v-else-if="!isSoldOutPrimary">
              <span v-if="!isOffersSalesType(edition.salesType)">
                <edition-price :edition="edition"
                              :class="{'has-text-grey': !startDatePassed && !isCc, 'has-text-white': isCc}">
                </edition-price>
              </span>
              <span v-else-if="isOffersOnlySalesType(edition.salesType) && editionOffers && editionOffers.length > 0">
                  <eth-with-fiat-price :price-in-wei="editionOffers[0].weiValue" :class="{'has-text-white': isCc}"></eth-with-fiat-price>
              </span>
              <span
                v-else-if="getEditionMinimumOffer(edition) && getEditionMinimumOffer(edition).eth_reserve_in_wei && getEditionMinimumOffer(edition).eth_reserve_in_wei > 0"
                :class="{'has-text-grey': !startDatePassed && !isCc}">
                <eth-with-fiat-price
                  :price-in-wei="getEditionMinimumOffer(edition).eth_reserve_in_wei" :class="{'has-text-white': isCc}"></eth-with-fiat-price>
              </span>
              <span
                v-else-if="(reserveAuctionCountdownInProgress || reserveAuctionCountdownComplete) && edition.activeBid">
                <eth-with-fiat-price :price-in-eth="edition.activeBid.ethValue" :class="{'has-text-white': isCc}"></eth-with-fiat-price>
              </span>
              <span v-else-if="isReserveAuctionSalesType(edition.salesType)"
                    :class="{'has-text-grey': !startDatePassed && !isCc}">
                <eth-with-fiat-price :price-in-wei="edition.reservePrice" :class="{'has-text-white': isCc}"></eth-with-fiat-price>
              </span>
              <span v-else class="is-family-tertiary" :class="{'has-text-grey': !startDatePassed && !isCc, 'has-text-white': isCc}">
                  No reserve
                </span>
            </span>
            <span v-else class="has-text-weight-semibold">
              <span v-if="lowestListedToken">
                <eth-with-fiat-price v-if="isBuyNowSalesType(lowestListedToken.salesType)" :class="{'has-text-white': isCc}"
                                      :price-in-eth="lowestListedToken.listPrice">
                </eth-with-fiat-price>
                <eth-with-fiat-price v-else-if="isReserveAuctionSalesType(lowestListedToken.salesType)" :class="{'has-text-white': isCc}"
                                      :price-in-wei="lowestListedToken.listing.reservePrice">
                </eth-with-fiat-price>
              </span>
              <span v-else-if="parseInt(edition.totalAvailable) === 1 && parseFloat(edition.totalEthSpentOnEdition) > 0">
                <eth-with-fiat-price :price-in-eth="edition.totalEthSpentOnEdition || 0" :class="{'has-text-white': isCc}"></eth-with-fiat-price>
              </span>
              <span class="has-text-danger" v-else-if="edition.totalSold > 0">Sold out</span>
            </span>
          </slot>
        </n-link>
      </div>
    </div>
  </div>
</template>

<script>
import _head from 'lodash/head';
import {mapGetters, mapState} from 'vuex';
import {
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isSteppedSalesType,
  isBuyNowAndOffersSalesType,
  isBuyableSalesType,
  isReserveAuctionSalesType,
  isOffersSalesType,
  isBuyNowAndOpenSalesType,
  getNextStep, AVAILABLE_SALES_TYPES
} from '../../services/SaleTypes';
import editionUtils from '../../services/editionUtils';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import EditionPrice from '../EditionPrice';
import EthWithFiatPrice from '../../components/common/EthWithFiatPrice';
import {editionStartDatePassed, editionStartDateJustPassed} from '../../services/utils';
import {GET_EDITION_ACTIVE_OFFER} from '../../queries/offersQueries';
import {LISTED_TOKENS_FOR_EDITION} from '../../queries/tokenQueries';
import {isCreatorContract} from '../../services/CreatorContractUtils'
import {handleGalleryRouting} from '../../services/routing';

export default {
  components: {
    EditionPrice,
    EthWithFiatPrice
  },
  props: [
    'edition',
    'phase'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      like: false,
      editionOffers: null,
      tokens: null
    };
  },
  computed: {
    ...mapGetters('offerStore', [
      'getEditionMinimumOffer'
    ]),
    ...mapState('web3Store', [
      'chainId',
      'gqlClient',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'isLoggedInAccount'
    ]),
    isCc() {
      return isCreatorContract(this.edition);
    },
    workingMetadata() {
      if (this.edition.metadata) {
        return this.edition.metadata;
      }
      return this.metadataFromKoCache;
    },
    startDatePassed() {
      return editionStartDatePassed(this.edition.startDate);
    },
    startDateJustPassed() {
      return editionStartDateJustPassed(this.edition.startDate);
    },
    scheduledSale() {
      return this.edition.startDate && this.edition.startDate > 0;
    },
    isSoldOutPrimary() {
      return editionUtils.isSoldOutPrimary(this.edition);
    },
    isLoggedInAccountViewingPage() {
      return this.isLoggedInAccount(this.account);
    },
    reserveAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.edition);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    },
    canChangeBuyNowPrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        (isBuyNowAndOffersSalesType(this.edition.salesType) || isBuyNowSalesType(this.edition.salesType)) &&
        !this.edition.offersOnly;
    },
    canChangeSteppedToBuyNowPrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isSteppedSalesType(this.edition.salesType) &&
        this.edition.remainingSupply === this.edition.totalAvailable &&
        !this.edition.offersOnly;
    },
    canChangeReservePrice() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isReserveAuctionSalesType(this.edition.salesType) &&
        (!this.reserveAuctionCountdownInProgress && !this.reserveAuctionCountdownComplete);
    },
    canSetOffchainMinimumBid() {
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        isOffersOnlySalesType(this.edition.salesType);
    },
    canResultReserveAuction() {
      return isReserveAuctionSalesType(this.edition.salesType) &&
        this.isNotSoldOutPrimary(this.edition) &&
        this.reserveAuctionCountdownComplete &&
        ReserveAuctionUtils.canResultEditionReserveAuction(this.edition, this.account);
    },
    canTransferToken() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        return this.canChangeReservePrice;
      }
      return this.isLoggedInAccount(this.edition.artistAccount) && this.isNotSoldOutPrimary(this.edition);
    },
    canChangeSalesListing() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        return this.canChangeReservePrice;
      }
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition) &&
        AVAILABLE_SALES_TYPES[this.edition.version][this.edition.salesType].length > 0;
    },
    canBurnEdition() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        return this.canChangeReservePrice;
      }
      return this.isLoggedInAccount(this.edition.artistAccount) &&
        this.isNotSoldOutPrimary(this.edition);
    },
    lowestListedToken() {
      if (!this.tokens || this.tokens.length === 0) {
        return null;
      }
      return this.tokens.length > 0 ? _head(this.tokens) : null;
    },
    handleMarketPlaceRouting() {
      return handleGalleryRouting(this.edition)
    }
  },
  async beforeMount() {
    if (!this.edition.metadata) {
      const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.edition.id}`);
      if (metadata) {
        this.metadataFromKoCache = metadata;
      }
    }
  },
  async mounted() {
    if (!isCreatorContract(this.edition)) {
      // load latest min bid
      if (isOffersOnlySalesType(this.edition.salesType)) {
        await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
          current_owner: this.edition.artistAccount,
          artwork_id: this.edition.id,
          entity_type: 'edition'
        });
      }

      this.$apollo.addSmartQuery('editionOffers', {
        deep: false,
        query: GET_EDITION_ACTIVE_OFFER,
        client: this.gqlClient,
        variables() {
          return {
            edition: `edition-${this.edition.id ? this.edition.id : '0'}`
          };
        },
        error(error) {
          console.log('error', error);
        }
      });
      this.$apollo.addSmartQuery('tokens', {
        client: this.gqlClient,
        query: LISTED_TOKENS_FOR_EDITION,
        variables() {
          return {
            editionNumber: this.edition.id
          };
        },
        error(error) {
          console.log('error', error);
        }
      });
    }
  },
  methods: {
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isSteppedSalesType,
    isBuyNowAndOffersSalesType,
    isBuyableSalesType,
    isReserveAuctionSalesType,
    isOffersSalesType,
    isBuyNowAndOpenSalesType,
    getNextStep,
    isNotSoldOutPrimary(edition) {
      return editionUtils.isNotSoldOutPrimary(edition);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.countdown-started {
  background-color: $black;
  color: $white !important;

  div > * {
    background-color: $black;
    color: $white !important;
  }

  span > * {
    background-color: $black;
    color: $white !important;
  }
}

.ccAssetText {
  color: white;
}
</style>
