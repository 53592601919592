var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "video",
      {
        ref: "videoPlayer",
        class: { "card-image-cover": _vm.cover },
        attrs: {
          id: `edition_video_${_vm.editionNumber}`,
          width: "100%",
          height: "100%",
          autoplay: !_vm.cover || !_vm.poster,
          controls: !_vm.cover || !_vm.poster,
          loop: "loop",
          muted: "muted",
          playsinline: "playsinline",
          disablePictureInPicture: "",
          controlsList: "nodownload",
          poster: _vm.cardAsset,
        },
        domProps: { muted: true },
        on: { error: _vm.onVideoError },
      },
      [
        _c("source", {
          attrs: { src: _vm.fullAsset, type: _vm.videoMimeType },
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Sorry your browser doesn't support this type of HTML5 video.\n    "
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }