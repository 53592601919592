var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.artist && _vm.artist.id
      ? _c("div", { staticClass: "columns is-multiline is-vcentered" }, [
          _c(
            "div",
            { staticClass: "column is-12 is-5-desktop is-two-fifths-fullhd" },
            [
              _c(
                "user-profile-lookup",
                {
                  attrs: {
                    large: _vm.isMostTrending,
                    "artist-account": _vm.artist.id,
                    "metadata-artist": _vm.artist.id,
                  },
                },
                [
                  _c("template", { slot: "artist-profile-header" }, [
                    _c(
                      "p",
                      {
                        staticClass: "artist-header-text mb-1 is-uppercase",
                        class: [_vm.isMostTrending ? "is-size-6" : "is-size-7"],
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.isMostTrending
                                ? "Top trending artist"
                                : "Trending artist"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "artist-profile-subtitle" },
                    [
                      _c("trending-score", {
                        attrs: {
                          score: _vm.artist.score,
                          address: _vm.artist.id,
                          "no-padding": true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-12 is-7-desktop is-three-fifths-fullhd" },
            [
              _vm.bio !== null
                ? _c("p", { staticClass: "trending-header-paragraph" }, [
                    _vm._v(_vm._s(_vm.bio)),
                  ])
                : _c("b-skeleton", { attrs: { active: "", height: "81px" } }),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "columns is-multiline is-vcentered" }, [
          _c(
            "div",
            { staticClass: "column is-12 is-5-desktop is-two-fifths-fullhd" },
            [
              _c("div", { staticClass: "media is-align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "media-left" },
                  [
                    _vm.isMostTrending
                      ? _c("b-skeleton", {
                          attrs: {
                            circle: "",
                            active: "",
                            width: "128px",
                            height: "128px",
                          },
                        })
                      : _c("b-skeleton", {
                          attrs: {
                            circle: "",
                            active: "",
                            width: "64px",
                            height: "64px",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "media-content" }, [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.isMostTrending
                        ? _c("b-skeleton", {
                            attrs: { active: "", width: "185px" },
                          })
                        : _c("b-skeleton", {
                            attrs: {
                              active: "",
                              width: "100px",
                              height: "12px",
                            },
                          }),
                      _vm._v(" "),
                      _c("b-skeleton", {
                        attrs: { active: "", width: "232px", height: "24px" },
                      }),
                      _vm._v(" "),
                      _c("b-skeleton", {
                        attrs: { active: "", width: "75px", height: "24px" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-12 is-7-desktop is-three-fifths-fullhd" },
            [_c("b-skeleton", { attrs: { active: "", height: "81px" } })],
            1
          ),
        ]),
    _vm._v(" "),
    _vm.artist && _vm.artist.editions
      ? _c(
          "div",
          {
            staticClass: "trending-artist-editions keen-slider",
            class: { "is-slider-loaded": _vm.isSliderReady },
          },
          _vm._l(_vm.artist.editions, function (edition, i) {
            return _c(
              "div",
              {
                key: "edition" + i,
                staticClass:
                  "trending-artist-edition-slide keen-slider__slide px-2 pt-5",
              },
              [_c("trending-image", { attrs: { edition: edition } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }