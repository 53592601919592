var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.resolved && _vm.isIcon
        ? _c(
            "b-tooltip",
            {
              staticClass:
                "ensResolver is-flex is-align-items-center is-justify-content-center",
              attrs: { label: _vm.resolved, type: "is-dark" },
            },
            [
              _c("li", { staticClass: "is-flex is-justify-content-center" }, [
                _c("img", {
                  attrs: { src: "/profile/verified-eth-colour.svg" },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.resolved && !_vm.isIcon
        ? _c(
            "li",
            [
              _c("b-icon", {
                attrs: { icon: "account-check-outline", size: "is-small" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.resolved))]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }