<template>
  <div>
    <header class="header-image has-text-white push-up-homepage-mobile" v-if="cmsComponent" v-lazy:background-image="imgObj">
      <section class="hero">
        <div class="hero-body">
          <div class="container content">
            <h1 class="title has-text-white has-margin-top-1 is-size-2-mobile">
              <n-link :to="cmsComponent.buttonLink" class="has-text-white has-padding-top-6">
                {{ cmsComponent.title }}
                <br />
                {{ cmsComponent.subtitle }}
              </n-link>
            </h1>
            <div class="content">
              <div @click="handleClickEventTracking(cmsComponent.buttonLabel, cmsComponent.buttonLink.name)" class="field is-grouped">
                <p class="control">
                  <a :href="cmsComponent.buttonLink.name" class="button is-white is-outlined" target="_blank">
                    {{ cmsComponent.buttonLabel }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  </div>
</template>\

<script>
  import {PUBLIC_BLOG_ASSET_BUCKET} from '../../services/utils';
  import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

  export default {
    props: ['cmsComponent'],
    computed: {
      imgObj() {
        if (this.cmsComponent.backgroundImage && this.cmsComponent.backgroundImage.startsWith(PUBLIC_BLOG_ASSET_BUCKET)) {
          return this.$publicBlogAssetImgixClient.buildURL(
            this.cmsComponent.backgroundImage.replace(PUBLIC_BLOG_ASSET_BUCKET, ''),
            {h: 800, ar: '16:9', fit: 'cover'}
          );
        }

        return this.cmsComponent.backgroundImage;
      }
    },
    methods: {
      handleClickEventTracking(value, destination) {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.buttonClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.destination]: destination,
            [AMPLITUDE_EVENT_PROPERTIES.value]: value,
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: 'cms banner'
          }
        })
      }
    }
  };
</script>
