<template>
  <section>
    <div v-if="!hasAuthToken || !loadedProfile" class="has-text-centered">
      <p class="mt-4">Loading your profile details...</p>
    </div>
    <div class="columns is-multiline mt-4">
      <section class="column is-three-fifths" v-if="hasAuthToken && loadedProfile">
        <b-field label="Username *" class="mb-6" v-if="loadedProfile.isArtist && loadedProfile.username"
          :message="usernameInvalidMessage">
          <b-input :disabled="true" v-model="loadedProfile.username">
          </b-input>
        </b-field>

        <b-field label="Username *" class="mb-6" :type="usernameInvalid" :message="usernameInvalidMessage" v-else>
          <b-input minlength="2" maxlength="50" has-counter @input="formIsValid" data-cy="username_input"
            v-model="form.username">
          </b-input>
        </b-field>

        <section class="level has-background-light pt-1 pb-1 mb-6">
          <div class="level-item" v-if="!profileImageThumb && form.imageUrl">
            <figure class="image is-128x128">
              <img class="is-rounded" :src="form.imageUrl">
            </figure>
          </div>
          <div class="level-item" v-else-if="profileImageThumb">
            <figure class="image is-128x128">
              <img class="is-rounded" :src="profileImageThumb">
            </figure>
            <a @click="clearTempAvatar" class="is-muted">
              <b-icon type="is-secondary" icon="delete-outline"></b-icon>
            </a>
          </div>
          <div class="level-item" v-else>
            <figure class="image is-128x128">
              <img class="is-rounded" :src="createAccountIcon(loadedProfile.address)" />
            </figure>
          </div>

          <div class="level-item">
            <b-upload v-model="profileImage" accept="image/*" @input="handleNewAvatar">
              <a class="button is-secondary">
                <b-icon icon="upload"></b-icon>
                <span>Upload new profile image</span>
              </a>
            </b-upload>
          </div>
        </section>

        <section v-if="formValidation.avatarError">
          <div class="has-text-danger">{{ formValidation.avatarError }}</div>
        </section>

        <b-field label="Bio" class="mb-6">
          <b-input v-model="form.bio" has-counter @input="formIsValid" maxlength="500" rows="4" data-cy="bio_input"
            type="textarea">
          </b-input>
        </b-field>

        <div class="mb-6">
          <b-switch v-model="form.enableNotifications">
            {{ form.enableNotifications ? 'Notifications enabled' : 'Notifications disabled (not recommended)' }}
          </b-switch>
        </div>

        <section>
          <b-field class="mb-6" label="Website"
            :type="{ 'is-danger': errors.website, 'is-success': form.website && !errors.website }"
            message="For example, https://knownorigin.io">
            <b-input v-model="form.website" type="text" @input="formIsValid" icon="web" has-counter
              data-cy="website_input" minlength="10" maxlength="250">
            </b-input>
          </b-field>

          <b-field class="mb-6" label="Twitter handle"
            :type="{ 'is-danger': errors.twitter, 'is-success': form.twitter && !errors.twitter }"
            message="For example, KnownOrigin_io">
            <b-input v-model="form.twitter" type="text" icon="twitter" @input="formIsValid" has-counter
              data-cy="twitter_input" minlength="1" maxlength="15">
            </b-input>
            <b-button type="is-grey" class="ml-2" outlined
              v-if="isWeb3Connected && loadedProfile.twitter && !isTwitterVerified(loadedProfile.address, loadedProfile.twitter)"
              @click="verifyTwitter">
              Verify Twitter
            </b-button>
          </b-field>

          <b-field class="mb-6" label="Instagram name"
            :type="{ 'is-danger': errors.instagram, 'is-success': form.instagram && !errors.instagram }"
            message="For example, KnownOrigin.io">
            <b-input v-model="form.instagram" type="text" icon="instagram" @input="formIsValid" has-counter
              data-cy="insta_input" minlength="3" maxlength="30">
            </b-input>
          </b-field>
        </section>

        <b-field label="Location" class="mt-6">
          <b-input type="text" v-model="form.location" icon="map-marker" @input="formIsValid" data-cy="location_input"
            min="5" max="75">
          </b-input>
        </b-field>

        <div :class="{ 'private-data mt-6': $store.getters['approvedFields/canShowApprovedFields'] }">
          <div v-if="$store.getters['approvedFields/canShowApprovedFields']">
            <h1 class="mt-6">Personal Information</h1>
            <p>We collect some personal information to help protect you and your collectors.</p>
            <p>We will NOT show any of this information on your public profile.</p>
          </div>

          <section v-if="$store.getters['approvedFields/canShowApprovedFields']">
            <b-field :label="requiredFieldsList.includes('name') ? 'Name *' : 'Name'" class="mt-6"
              :message="invalidFullName"
              :type="{ 'is-danger': errors.fullName, 'is-success': form.fullName && form.fullName.length > 0 && !errors.fullName }">
              <b-input placeholder="Name" v-model="form.fullName" @input="formIsValid" has-counter minlength="2"
                maxlength="100" type="text" data-cy="fullname_input">
              </b-input>
            </b-field>
          </section>

          <b-field :label="requiredFieldsList.includes('country') ? 'Country of residence *' : 'Country of residence'"
            class="mt-6" :type="{ 'is-danger': errors.country, 'is-success': form.country }" message="">
            <country-select v-model="form.country" :country="form.country" top-country="US" class="input"
              @input="formIsValid" data-cy="country_dropdown" placeholder="Select country"
              :class="{ 'is-danger': errors.country, 'is-success': form.country && !errors.country }"></country-select>
          </b-field>

          <div class="card mt-6" v-if="$store.getters['approvedFields/canShowApprovedFields'] && form.country">
            <div class="card-content">
              <div class="content">
                <AddressInput :country="form.country" :address-street1="form.streetAddress1"
                  :address-street2="form.streetAddress2" :address-city="form.city" :address-state="form.state"
                  :address-postal="form.postal" @isAddressValid="updateAddressValidation" @updatedAddress="updatedAddress"
                  :required-fields-list="requiredFieldsList" />
              </div>
            </div>
          </div>

          <section v-if="$store.getters['approvedFields/canShowApprovedFields']" class="mt-6">
            <div>
              <DOBInput :dob="form.dob" :required-fields-list="requiredFieldsList" @isDOBValid="updateDOBValidation"
                @updatedDOB="updateDOB" />
            </div>
          </section>


          <b-field :label="requiredFieldsList.includes('email') ? 'Email *' : ''" class="mt-6"
            :type="{ 'is-danger': errors.email, 'is-success': form.email && form.email.length > 0 && !errors.email }"
            :message="invalidEmail">
            <b-input placeholder="Email" v-model="form.email" @input="formIsValid" has-counter minlength="8"
              maxlength="75" type="email" data-cy="email_input" icon="email-outline">
            </b-input>
          </b-field>
        </div>

        <section class="level has-margin-top-1">
          <div class="level-left">
            <div class="level-item">
              <b-button type="is-primary" :loading="saving" @click="saveProfile" :disabled="!canSave" data-cy="save_btn">
                Save profile
              </b-button>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button outlined @click="cancelEdit" :disabled="!canSave" data-cy="cancel_btn">
                Cancel
              </b-button>
            </div>
          </div>
        </section>

        <section>
          <hr />
          <b-field>
            <b-checkbox v-model="selectedDeletedProfile" class="is-muted">
              Need to delete your profile?
            </b-checkbox>
          </b-field>

          <section class="mb-4 content notification" v-if="selectedDeletedProfile">
            <dl>
              <li>
                This action <span class="has-text-weight-semibold">cannot</span> be undone
              </li>
              <li>
                All off-chain profile data will be removed
              </li>
            </dl>
          </section>

          <div v-if="selectedDeletedProfile">
            <b-button type="is-danger" :loading="saving" @click="deleteProfile">
              Delete profile
            </b-button>
          </div>
        </section>

      </section>
    </div>
  </section>
</template>
<script>
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _every from 'lodash/every';
import _includes from 'lodash/includes';
import { mapState, mapGetters } from 'vuex';
import urlRegex from 'url-regex';
import { toDataUrl } from 'myetherwallet-blockies';
import { cleanUpUrl } from '../../services/utils';
import { usernameSlug } from '../../services/slugs';
import DeleteProfile from '../modal/DeleteProfile';
import { AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES } from '../../services/analyticsEvents';
import AddressInput from './AddressInput'
import DOBInput from './DOBInput.vue';

const predefinedUsernames = [
  'boards', 'featured', 'darkness',
  'covid', 'dao-osaka', 'assets',
  'token', 'edition', 'offers',
  'activity', 'artists', 'contracts',
  'gallery', 'debug', 'feed', 'home',
  'the-art-of-darkness', 'collection',
  'account', 'profile', 'debug', 'login',
  'trending', 'halloffame', 'journal',
  'black-history-month',
  'artist-application', 'search', 'discord', 'apply'
];

const _2mb = 2 * 1024 * 1024;

export default {
  components: {
    AddressInput,
    DOBInput
  },
  props: ['address'],
  data() {
    return {
      loadedProfile: null,
      selectedDeletedProfile: false,
      saving: false,
      profileImage: null,
      profileImageThumb: null,
      canSave: false,
      datePickerDate: new Date(),
      form: {
        username: null,
        email: null,
        enableNotifications: true,
        bio: null,
        website: null,
        twitter: null,
        instagram: null,
        location: null,
        country: null,
        fullName: null,
        day: null,
        month: null,
        year: null,
        streetAddress1: null,
        streetAddress2: null,
        city: null,
        state: null,
        postal: null,
        dob: null
      },
      errors: {},
      formValidation: {
        avatarError: null,
        exceedsMaxImageSize: false,
        duplicateName: false,
        invalidUsername: false,
        invalidUsernameLength: false
      }
    };
  },
  computed: {
    ...mapState('web3Store', [
      'authToken',
      'account'
    ]),
    ...mapGetters('web3Store', [
      'hasAuthToken',
      'isWeb3Connected'
    ]),
    ...mapGetters('userStore', [
      'isTwitterVerified'
    ]),
    buildClaimUrl() {
      if (this.form.username) {
        return `https://knownorigin.io/${usernameSlug(this.form.username)}`;
      }
      return '';
    },
    usernameInvalid() {
      if (this.formValidation.duplicateName) {
        return 'is-danger';
      }
      if (this.formValidation.invalidUsername) {
        return 'is-danger';
      }
      if (this.formValidation.invalidUsernameLength) {
        return 'is-danger';
      }
      return this.form.username && this.form.username.length > 0 && 'is-success';
    },
    usernameInvalidMessage() {
      if (this.formValidation.duplicateName) {
        return 'Username already taken';
      }
      if (this.formValidation.invalidUsername) {
        return 'Invalid username';
      }
      if (this.formValidation.invalidUsernameLength) {
        return 'Invalid username';
      }
      return this.buildClaimUrl;
    },
    websiteInvalidMessage() {
      if (this.errors.website) {
        return 'Please provide the full website, for example, https://knownorigin.io';
      }
      return '';
    },
    invalidFullName() {
      if (this.errors.fullName && this.form.fullName && this.form.fullName.length === 0 && this.$store.getters['approvedFields/canShowApprovedFields']) {
        return 'required field'
      }
      return null;
    },
    invalidEmail() {
      if (this.errors.email && this.form.email && this.form.email.length === 0 && this.$store.getters['approvedFields/canShowApprovedFields']) {
        return 'required field'
      }
      return null;
    },
    requiredFieldsList() {
      if (this.loadedProfile.requiredFieldsType === 'creator') {
        return [
          'streetAddress1',
          'streetAddress2',
          'city',
          'state',
          'postal',
          'dob',
          'email',
          'country',
          'name'
        ]
      }
      return []
    }
  },
  watch: {
    async 'form.username'(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Validate - 0-9, a-z, A-Z, (space), (dash)
        this.formValidation.invalidUsername = !/^[a-zA-Z0-9 -]+$/.test(newVal);

        // Validate length
        this.formValidation.invalidUsernameLength = newVal && (newVal.length < 2 || newVal.length > 50);

        // Replace double space with single
        if (newVal) {
          this.form.username = newVal.replace(/\s\s+/g, ' ');
        }

        await this.checkDuplicateUsername()
      }
    },
    authToken(newVal) {
      if (newVal) {
        this.loadAuthenticatedUser();
      }
    }
  },
  mounted() {
    this.loadAuthenticatedUser();
  },
  methods: {
    async login() {
      await this.$store.dispatch('web3Store/createJwtSession');
      this.loadAuthenticatedUser();
    },
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
    updatedAddress(streetAddress1, streetAddress2, city, state, postal) {
      this.form.streetAddress1 = streetAddress1;
      this.form.streetAddress2 = streetAddress2;
      this.form.city = city;
      this.form.state = state;
      this.form.postal = postal;
    },
    updateDOB(DOB) {
      this.form.dob = DOB;
    },
    loadAuthenticatedUser() {
      if (this.hasAuthToken) {
        this.$store.dispatch('userStore/getAuthenticatedUserProfile')
          .then((profile) => {
            this.loadedProfile = profile;

            this.form = {
              ...profile
            };

            if (profile && profile.dob) {
              const result = Date.parse(profile.dob);
              if (!isNaN(result)) {
                this.form.dob = new Date(result);
              }
            }

            // Default to true if a new profile and has never set this field before
            if (profile && !Object.prototype.hasOwnProperty.call(profile, 'enableNotifications')) {
              this.form.enableNotifications = true;
            }

            this.formIsValid();

            // KOKYC-53 Added for temp whitelist for PII fields
            if (profile && profile.address) {
              this.$store.dispatch('approvedFields/checkIfUserIsApproved', profile.address)
            }
          });
      }
    },
    createAccountIcon(address) {
      return toDataUrl(address);
    },
    validateName() {
      this.errors.username = _size(_trim(this.form.username)) < 2 || _size(_trim(this.form.username)) > 50;
    },
    validateFullName() {
      if ((this.requiredFieldsList.includes('name') || _size(this.form.fullName) > 0) && this.requiredFieldsList.includes('name')) {
        this.errors.fullName = _size(this.form.fullName) < 2 || _size(this.form.fullName) > 100;
      }
    },
    validateEmail() {
      /* eslint no-useless-escape: "off" */
      const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.errors.email = this.form.email && !emailRegEx.test(this.form.email.toLowerCase());
    },
    validateWebsite() {
      const value = _trim(this.form.website);
      if (value === '') {
        this.errors.website = false;
      } else {
        this.errors.website = !urlRegex({ exact: true }).test(value);
      }
    },
    validateTwitter() {
      /* eslint no-useless-escape: "off" */
      const twitterRegEx = /^[a-zA-Z0-9_]{1,15}$/;
      this.errors.twitter = this.form.twitter && !twitterRegEx.test(this.form.twitter.toLowerCase());
    },
    async verifyTwitter() {
      await this.$store.dispatch('userStore/startUserVerificationFlow', {
        provider: 'twitter',
        address: this.loadedProfile.address,
        handle: this.loadedProfile.twitter
      });
    },
    validateInstagram() {
      /* eslint no-useless-escape: "off" */
      const instagramRegEx = /^([A-Za-z0-9._](?:(?:[A-Za-z0-9._]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9._]))?)$/;
      this.errors.instagram = this.form.instagram && !instagramRegEx.test(this.form.instagram.toLowerCase());
    },
    validateCountry() {
      this.errors.country = !this.form.country && this.requiredFieldsList.includes('country') && this.$store.getters['approvedFields/canShowApprovedFields'];
    },
    updateAddressValidation(isAddressInvalid) {
      this.errors.postalAddress = isAddressInvalid;
      this.formIsValid();
    },
    updateDOBValidation(isDOBValid) {
      this.errors.dob = !isDOBValid;
      this.formIsValid();
    },
    async formIsValid() {
      // Some basic validation
      this.validateName();
      this.validateFullName();
      this.validateEmail();
      this.validateWebsite();
      this.validateTwitter();
      this.validateInstagram();
      this.validateCountry();

      const formIsValid = _every(this.errors, value => !value);
      const allChecks = formIsValid &&
        !this.formValidation.invalidUsername &&
        !this.formValidation.invalidUsernameLength &&
        !this.formValidation.duplicateName &&
        !this.formValidation.avatarError;

      console.log(`Form is valid [${allChecks}]`, this.errors);

      this.canSave = allChecks;
      return allChecks;
    },
    clearTempAvatar() {
      this.formValidation.avatarError = null;
      this.profileImageThumb = null;
    },
    handleNewAvatar(event) {
      this.clearTempAvatar();
      if (event.size > _2mb) {
        this.formValidation.avatarError = 'File to big - max 2mb';
      } else {
        this.profileImageThumb = URL.createObjectURL(this.profileImage);
      }
    },
    async saveAvatar() {
      if (this.profileImage) {
        return this.$store.dispatch('userStore/saveProfileAvatar', this.profileImage)
          .then((storageUrl) => {
            return storageUrl || this.loadedProfile.imageUrl;
          });
      }
      return Promise.resolve(this.loadedProfile.imageUrl);
    },
    async saveProfile() {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'manage profile',
          [AMPLITUDE_EVENT_PROPERTIES.value]: 'Save profile'
        }
      });
      if (this.form && this.form.username) {
        await this.checkDuplicateUsername();
        await this.formIsValid();
        if (this.hasAuthToken && this.canSave) {
          this.saving = true;
          const imageUrl = await this.saveAvatar();

          const profile = {
            username: this.form.username.trim(),
            email: this.form.email,
            imageUrl,
            bio: this.form.bio,
            website: this.form.website,
            instagram: cleanUpUrl(this.form.instagram),
            twitter: cleanUpUrl(this.form.twitter),
            location: this.form.location,
            country: this.form.country,
            // Check string and boolean
            enableNotifications: (this.form.enableNotifications === 'true' || this.form.enableNotifications === true),
            fullName: this.form.fullName || null,
            dob: this.form.dob ? this.form.dob.toISOString().split('T')[0] : null,
            streetAddress1: this.form.streetAddress1,
            streetAddress2: this.form.streetAddress2,
            city: this.form.city,
            state: this.form.state,
            postal: this.form.postal
          };

          this.$store.dispatch('userStore/saveProfile', profile)
            .then(() => {
              this.loadAuthenticatedUser();
              this.$emit('profile-saved');
            })
            .finally(() => {
              this.saving = false;
            });
        }
      }
    },
    async deleteProfile() {
      if (this.hasAuthToken) {
        this.$buefy.modal.open({
          parent: this,
          component: DeleteProfile,
          width: 600,
          events: {
            close: (deleted = false) => {
              if (deleted) {
                this.loadAuthenticatedUser();
                this.$emit('profile-saved');
              }
            }
          }
        });
      }
    },
    async checkDuplicateUsername() {
      // Enforce this list of know mapped URLs in the app
      if (_includes(predefinedUsernames, (this.form.username || '').toLowerCase())) {
        this.formValidation.duplicateName = true;
        return Promise.resolve();
      }
      this.$store.dispatch('userStore/checkDuplicateUsername', this.form.username)
        .then(({ success }) => {
          this.formValidation.duplicateName = success !== true;
        });
    },
    cancelEdit() {
      this.$emit('profile-saved');
    }
  }
};
</script>

<style lang="scss">
.private-data {
  border-top: 1px solid black;
}
</style>
