var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.walletDisabled
    ? _c(
        "div",
        {
          staticClass: "is-flex userProfileStats",
          staticStyle: { "font-weight": "400", "font-size": "0.85rem" },
        },
        [
          _c("div", { staticClass: "level is-mobile is-uppercase mb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "userProfileStats__holder level-left is-flex is-align-items-start is-full is-mobile column pb-0 pl-0",
              },
              [
                _vm.artistStats && _vm.artistStats.editionsCount !== "0"
                  ? _c(
                      "div",
                      {
                        staticClass: "userProfileStats__userDetails level-item",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "level is-mobile is-uppercase mb-0" },
                          [
                            _c("div", { staticClass: "level-left" }, [
                              _vm.artistEditions || _vm.collectorStats
                                ? _c("div", { staticClass: "level-item" }, [
                                    _c("div", { staticClass: "mr-3 mb-4" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "has-text-left is-size-7 mb-1 is-muted is-uppercase is-spaced-1 is-family-tertiary has-text-weight-bold",
                                        },
                                        [_vm._v("JOINED")]
                                      ),
                                      _vm._v(" "),
                                      _vm.collectorStats &&
                                      _vm.artistEditions &&
                                      _vm.collectorStats.firstSeen <
                                        _vm.artistEditions.createdTimestamp
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-1" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "level-item",
                                                    attrs: {
                                                      icon: "calendar",
                                                      size: "is-small",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.collectorStats
                                                        .firstSeen * 1000,
                                                      "MMM Do YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm.artistEditions
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-1" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "level-item",
                                                    attrs: {
                                                      icon: "calendar",
                                                      size: "is-small",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.artistEditions
                                                        .createdTimestamp *
                                                        1000,
                                                      "MMM Do YYYY"
                                                    )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm.collectorStats
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-1" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "level-item",
                                                    attrs: {
                                                      icon: "calendar",
                                                      size: "is-small",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.collectorStats
                                                        .firstSeen * 1000,
                                                      "MMM Do YYYY"
                                                    )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "mr-3 mb-4 pr-2" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 is-family-tertiary has-text-weight-bold",
                    },
                    [_vm._v("LOCATION")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "is-flex is-align-items-center is-famliy-tertiary",
                      attrs: { "data-cy": "location_address" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-1 userProfileStats__locationIcon",
                        attrs: { icon: "map-marker", size: "is-small" },
                      }),
                      _vm._v(" "),
                      _vm.location && _vm.location.trim()
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-semibold is-size-6 is-capitalized is-family-tertiary",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.location.slice(0, 20) +
                                    (_vm.location.length > 20 ? "..." : "")
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.location || !_vm.location.trim()
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "has-text-weight-semibold is-size-6 is-capitalized is-family-tertiary",
                            },
                            [_vm._v("Metaverse")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "level is-flex is-flex-direction-column mr-3 pr-2 mb-4",
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "is-family-tertiary" },
                      [
                        _c("page-views-profile", {
                          attrs: { address: _vm.profile.address },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                (_vm.artistStats && _vm.artistStats.salesCount !== "0") ||
                (_vm.collectorStats &&
                  _vm.collectorStats.totalPurchaseEthSpent !== "0")
                  ? _c("div", { staticClass: "userProfileStats__userStats" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 is-family-tertiary has-text-weight-bold",
                        },
                        [_vm._v("STATS")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "userProfileStats__statsHolder is-flex is-mobile",
                        },
                        [
                          _vm.artistStats && _vm.artistStats.salesCount !== "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "userProfileStats__statsHolder--item level-item mr-1",
                                },
                                [
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass:
                                        "is-dark has-text-weight-medium",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          attrs: {
                                            "data-cy": "Editions_count",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.artistStats.editionsCount
                                            ) + " EDITIONS"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.artistStats && _vm.artistStats.salesCount !== "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "userProfileStats__statsHolder--item level-item mr-1",
                                },
                                [
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass:
                                        "is-dark has-text-weight-medium",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { attrs: { "data-cy": "sales_count" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.artistStats.salesCount) +
                                              " SALES"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.artistStats &&
                          _vm.artistStats.totalValueInEth !== "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "userProfileStats__statsHolder--item level-item mr-1",
                                },
                                [
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass:
                                        "is-dark has-text-weight-medium",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { attrs: { "data-cy": "total_count" } },
                                        [
                                          _c("eth-with-fiat-price", {
                                            attrs: {
                                              "price-in-eth":
                                                _vm.artistStats.totalValueInEth,
                                            },
                                          }),
                                          _vm._v(" TOTAL\n          "),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collectorStats &&
                          _vm.collectorStats.totalPurchaseCount !== "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "userProfileStats__statsHolder--item level-item mr-1",
                                },
                                [
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass:
                                        "is-dark has-text-weight-medium",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { attrs: { "data-cy": "NFT_count" } },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.collectorStats
                                                  .totalPurchaseCount
                                              ) +
                                              " NFTS\n          "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collectorStats &&
                          _vm.collectorStats.totalPurchaseEthSpent !== "0"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "userProfileStats__statsHolder--item level-item mr-1",
                                },
                                [
                                  _c(
                                    "b-tag",
                                    {
                                      staticClass:
                                        "is-dark has-text-weight-medium",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { attrs: { "data-cy": "spent_count" } },
                                        [
                                          _c("eth-with-fiat-price", {
                                            attrs: {
                                              "price-in-eth":
                                                _vm.collectorStats
                                                  .totalPurchaseEthSpent,
                                            },
                                          }),
                                          _vm._v(
                                            "\n            SPENT\n          "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "level is-mobile userProfileStats__labels" },
            [
              _c("div", { staticClass: "level-left" }, [
                _c("div", { staticClass: "level-item has-padding-right-7" }, [
                  _c("div", [
                    _c(
                      "p",
                      [
                        _c("profile-labels", {
                          attrs: {
                            profile: _vm.profile,
                            stats: _vm.rawStats,
                            "is-hall-of-fame": _vm.isHallOfFame,
                            "is-collection": _vm.isCollection,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }