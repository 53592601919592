<template>
  <client-only>
    <v-pannellum
      ref="panellum"
      :src="reFormatUrl"
      :auto-rotate="true"
      :draggable="isCover"
      :show-fullscreen="isCover"
      :compass="isCover"
      :show-zoom="isCover"
      :mouse-zoom="isCover"
      @load="onLoaded"
      :hfov="120"
      :class="{'full-size': isCover}">
    </v-pannellum>
  </client-only>
</template>

<script>

export default {
  props: ['editionNumber', 'cover', 'cardAsset', 'fullAsset', 'metadata', 'isCc'],
  data() {
    return {
      assetUrl: ''
    }
  },
  computed: {
    isCover() {
      return this.isCc ? true : !this.cover
    },
    reFormatUrl() {
      if (this.isCc && this.assetUrl) {
        return this.assetUrl.slice(0, this.assetUrl.length - 9) + 'full.jpeg'
      } else {
        return this.assetUrl
      }
    }
  },
  async mounted() {
    try {
      // dont redirect to the item but load the location via JSON
      const {data} = await this.$axios.get(`${this.isCc ? this.cardAsset : this.cover ? this.cardAsset : this.fullAsset}?noDownload=true`);
      this.assetUrl = data.location;
    } catch (e) {
      this.assetUrl = this.metadata.image;
    }
  },
  methods: {
    onLoaded() {
      this.$refs.panellum.viewer.resize();
    }
  }
};
</script>
<style scoped>
.full-size {
  height: 500px;
}
</style>
