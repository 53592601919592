import isString from 'lodash/isString'
import {ACTIVITY} from '../queries/activityQueries';
import {EXPIRED_PHASES, GET_CURRENT_AND_FUTURE_PHASES} from '../queries/gatedQueries';
import {
  BUY_NOW_UNDER_POINT_ONE,
  EDITION_BY_ID_QUERY,
  LAST_ONE_NEWEST,
  RECENT_1OF1_EDITIONS, RECENT_GENESIS_EDITIONS,
  CREATOR_CONTRACTS, OPEN_EDITIONS
} from '../queries/editionQueries';
import {JUST_MISSED} from '../queries/tokenQueries';
import {RECENT_WHALE_PURCHASES} from '../queries/collectorQueries';

const Artist1CardCount = 12

export const state = () => ({});

export const mutations = {};

export const actions = {
  async fetchComponentData({dispatch, rootState}, cmsData) {
    const {web3Store} = rootState;
    const gqlProvider = this.app.apolloProvider.clients[web3Store.gqlClient]

    // Format any links
    if (cmsData.buttonLink && isString(cmsData.buttonLink)) {
      cmsData.buttonLink = await dispatch('formatUrlToNLink', cmsData.buttonLink)
    }

    if (cmsData.viewMoreLink && isString(cmsData.viewMoreLink)) {
      cmsData.viewMoreLink = await dispatch('formatUrlToNLink', cmsData.viewMoreLink)
    }

    try {
      switch (cmsData.query) {
        case 'trendingArtists': {
          const {artists} = await this.$cacheApi.$get('/trending/artists/12');

          return artists
        }

        case 'earlyAccessFeatured': {
          const {data} = await gqlProvider.query({
            query: GET_CURRENT_AND_FUTURE_PHASES,
            variables: {
              endTimeGt: Math.floor(Date.now() / 1000)
            }
          })
          // eslint-disable-next-line no-constant-condition
          if (data.currentFuturePhases.length > 0) {
            for (const phase of data.currentFuturePhases) {
              const res = await this.app.apolloProvider.defaultClient.query({
                query: EDITION_BY_ID_QUERY,
                variables: {
                  id: phase.editionId
                }
              })

              if (res.data.editionById && res.data.editionById[0]) {
                return res.data.editionById[0]
              }
            }
          } else {
            // Fallback for no current early access pieces
            const {data} = await gqlProvider.query({
              query: EXPIRED_PHASES,
              variables: {
                endTimeGt: Math.floor(Date.now() / 1000)
              }
            })

            const edition = await this.app.apolloProvider.defaultClient.query({
              query: EDITION_BY_ID_QUERY,
              variables: {
                id: data.expiredPhases[0].editionId
              }
            })

            return edition.data.editionById[0]
          }

          return []
        }

        case 'whaleWatching' : {
          const res = await gqlProvider.query({
            query: RECENT_WHALE_PURCHASES,
            variables: {
              first: Artist1CardCount
            }
          })

          return res.data && res.data.recentWhalePurchases ? res.data.recentWhalePurchases : []
        }

        case 'newCreations': {
          const res = await gqlProvider.query({
            query: ACTIVITY,
            variables: {
              first: Artist1CardCount,
              skip: 0,
              eventTypes: ['EditionCreated']
            }
          })

          return res.data && res.data.activityEvents ? res.data.activityEvents : []
        }

        case 'genesisPieces' : {
          const res = await gqlProvider.query({
            query: RECENT_GENESIS_EDITIONS,
            variables: {
              first: Artist1CardCount
            }
          })
          return res.data && res.data.recentGenesisEditions ? res.data.recentGenesisEditions : []
        }

        case 'buyNowUnderPointOne' : {
          const res = await gqlProvider.query({
            query: BUY_NOW_UNDER_POINT_ONE,
            variables: {
              first: Artist1CardCount
            }
          })
          return res.data && res.data.buyNowUnderPointOne ? res.data.buyNowUnderPointOne : []
        }

        case 'justMissed' : {
          const res = await gqlProvider.query({
            query: JUST_MISSED,
            variables: {
              first: Artist1CardCount
            }
          })
          return res.data && res.data.justMissed ? res.data.justMissed : []
        }

        case 'recentlySold': {
          const res = await gqlProvider.query({
            query: ACTIVITY,
            variables: {
              first: Artist1CardCount,
              skip: 0,
              eventTypes: ['Purchase', 'BidAccepted']
            }
          })

          return res.data && res.data.activityEvents ? res.data.activityEvents : []
        }

        case 'recent1of1': {
          const res = await gqlProvider.query({
            query: RECENT_1OF1_EDITIONS,
            variables: {
              first: Artist1CardCount
            }
          })
          return res.data && res.data.editions ? res.data.editions : []
        }

        case 'lastOneNewest': {
          const res = await gqlProvider.query({
            query: LAST_ONE_NEWEST,
            variables: {
              first: Artist1CardCount
            }
          })

          return res.data && res.data.editions ? res.data.editions : []
        }

        case 'openEditions': {
          const res = await gqlProvider.query({
            query: OPEN_EDITIONS,
            variables: {
              first: Artist1CardCount,
              skip: 0
            }
          })

          return res.data && res.data.editions ? res.data.editions : []
        }

        case 'featureCollectionShowcase' : {
          const res = await gqlProvider.query({
            query: EDITION_BY_ID_QUERY,
            variables: {
              id: cmsData.editionId
            }
          })

          const collectionParams = cmsData.buttonLink.name.split('/');

          cmsData.buttonLink = {name: 'collections-slug-id', params: {slug: collectionParams[1], id: collectionParams[2]}};

          return res.data && res.data.editionById ? res.data.editionById[0] : []
        }

        case 'creatorContracts': {
          const res = await gqlProvider.query({
            query: CREATOR_CONTRACTS,
            variables: {
              first: Artist1CardCount
            }
          })

          return res.data && res.data.editions ? res.data.editions : []
        }

        default:
          return []
      }
    } catch (err) {
      console.error(`Error fetching data for component - ${err}`)
      return []
    }
  },

  formatUrlToNLink({state}, link) {
    const profileRegex = /^profile\/0x[a-fA-F0-9]{40}/g

    if (link) {
      // Strip any trailing slash
      if (link.startsWith('/')) {
        link = link.slice(1)
      }

      let linkObj = {name: link};

      // If we have a profile link then update
      if (link.match(profileRegex)) {
        return {name: 'profile-id', params: {id: link.split('/')[1]}}
      }

      // If there are no search or query params then return the link
      if (link.includes('?')) {
        const splitLink = link.split('?')

        let query = {}

        splitLink[1].split('&').forEach((item) => {
          item = item.split('=')
          query = {...query, [`${item[0]}`]: item[1]}
        })

        linkObj = {name: splitLink[0], query}
      }

      return linkObj
    }
  }
}
