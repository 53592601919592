<template>
  <div class="container is-flex is-align-items-center p-2" :class="count % 2 === 0 ? 'has-background-white-ter' : ''">
    <figure class="image is-48x48 mb-0">
      <img class="is-rounded" :src="icon">
    </figure>
    <p class="ml-3"><span class="has-text-weight-normal has-text-black">{{ marketplace }}</span></p>
  </div>
</template>

<script>
export default {
  props: {
    marketplace: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    count: {
      type: String,
      required: true
    }
  }
};
</script>
