<template>
  <span>
    <span v-if="valueInEth === 0">
      <span
        :class="{
          'is-family-tertiary': true,
          'subtitle is-2': size === 'is-large',
          'is-size-7': size === 'is-medium',
          'is-size-7': size === 'is-small',
          'has-text-weight-semibold': strong,
        }"
      >
        <span
          :class="{
            'has-text-danger': type === 'is-danger',
            'has-text-grey': type === 'is-muted',
          }"
          >Ξ 0</span
        >
      </span>
      <span v-if="showFiat && usdPrice" class="is-muted is-small is-family-tertiary">
        / {{ convertUsdPrice }}
      </span>
      <span
        v-if="showHistoricalFiat && historicalUsdPrice"
        class="is-muted is-small"
      >
        / {{ convertHistoricalUsdPrice }}
      </span>
    </span>
    <!--  -->
    <span v-else-if="valueInEth">
      <span
        :class="{
          'is-family-tertiary': true,
          'subtitle is-2': size === 'is-large',
          'is-size-7': size === 'is-medium',
          'is-size-7': size === 'is-small',
          'has-text-weight-bold': strong,
        }"
      >
        <span
          :class="{
            'has-text-danger': type === 'is-danger',
            'has-text-grey': type === 'is-muted',
          }"
          >Ξ {{ valueInEth }}</span
        >
      </span>
      <span v-if="showFiat && usdPrice" class="is-muted is-small is-family-tertiary">
        / {{ convertUsdPrice }}
      </span>
      <span
        v-if="showHistoricalFiat && historicalUsdPrice"
        class="is-muted is-small"
      >
        / {{ convertHistoricalUsdPrice }}
      </span>
    </span>
  </span>
</template>

<script>
import _get from "lodash/get";
import { mapState } from "vuex";

export default {
  props: [
    "priceInWei",
    "priceInEth",
    "showFiat",
    "showHistoricalFiat",
    "size",
    "strong",
    "transactionHash",
    "overrideDp",
    "type",
  ],
  data() {
    return {
      historicalUsdPrice: null,
    };
  },
  computed: {
    ...mapState(["usdPrice"]),
    valueInEth() {
      if (this.priceInWei) {
        const formattedPrice = parseFloat(
          this.$options.filters.toEth(this.priceInWei)
        );

        // Note the plus sign that drops any "extra" zeroes at the end.
        // It changes the result (which is a string) into a number again (think "0 + foo"),
        // which means that it uses only as many digits as necessary.
        return +this.$options.filters.to3Dp(formattedPrice);
      }

      if (this.priceInEth) {
        const formattedPrice = parseFloat(this.priceInEth);

        // Note the plus sign that drops any "extra" zeroes at the end.
        // It changes the result (which is a string) into a number again (think "0 + foo"),
        // which means that it uses only as many digits as necessary.
        return +this.$options.filters.to3Dp(formattedPrice);
      }

      return 0;
    },
    convertUsdPrice() {
      if (this.usdPrice && this.valueInEth) {
        const usdValue = this.usdPrice * this.valueInEth;
        return usdValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
      }
      if (this.usdPrice) {
        return "$0";
      }
      return null;
    },
    convertHistoricalUsdPrice() {
      if (this.historicalUsdPrice && this.valueInEth) {
        const usdValue = this.historicalUsdPrice * this.valueInEth;
        return usdValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
      }
      if (this.usdPrice) {
        return "$0";
      }
      return null;
    },
  },
  async mounted() {
    if (this.transactionHash && this.priceInWei) {
      const transactionDetail = await this.$store.dispatch(
        "transactionLookupStore/lookupTransactionDetail",
        this.transactionHash
      );
      this.historicalUsdPrice = _get(transactionDetail, "exchangeRate.usd");
    }
  },
};
</script>
