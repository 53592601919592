<template>
  <section class="container section">
    <div class="has-text-centered">
      <h1 v-if="error && error.statusCode === 404" class="is-uppercase has-text-danger">
        Oops...You're lost or somethings went bang!
      </h1>

      <h1 v-else-if="error && error.statusCode" class="is-uppercase has-text-danger is-size-3">
        We do not know the origin of this page!
        Error status code: {{error.statusCode}}
      </h1>

      <h1 v-else class="is-uppercase has-text-danger">
        Oops...You're lost or somethings went bang!
      </h1>

      <div class="content" style="margin-top: 50px">
        <nuxt-link to="/">Return to KnownOrigin</nuxt-link>
      </div>

      <div class="container" v-if="!loadingError">
        <div class="tile is-ancestor has-margin-top-1">
          <div class="tile is-parent is-vertical">
            <section>
              <div class="tile is-child">
                <div class="content has-text-left">
                  <h2 class="title is-size-3">Recent</h2>
                </div>
              </div>
              <div class="tile is-child">
                <div v-if="!loading">
                  <div class="columns is-multiline">
                    <div class="column is-one-third" v-for="i in [1,2,3]" :key="i">
                      <asset-card-holder></asset-card-holder>
                    </div>
                  </div>
                </div>
                <div class="columns is-multiline" v-else-if="editions">
                  <div class="column is-one-third" v-for="edition in editions" :key="edition.id">
                    <edition-card
                      :edition="edition"
                      :cover="true"
                      resolution="card"
                      class="has-margin-bottom-4"
                    ></edition-card>
                  </div>
                </div>
                <div class="has-text-centered has-margin-bottom-1">
                  <n-link :to="{ name: 'marketplace' }" class="button is-primary is-outlined">
                    View marketplace
                  </n-link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import _get from 'lodash/get';
import {RECENT_EDITIONS} from '../queries/editionQueries';
import AssetCardHolder from '../components/AssetCardHolder';
import EditionCard from '../components/EditionCard';
import {buildTitleAndMeta} from '../services/utils';

export default {
  components: {EditionCard, AssetCardHolder},
  layout: 'blog',
  props: ['error'], // you can set a custom layout for the error page
  data() {
    return {
      loading: false,
      loadingError: false,
      editions: []
    }
  },
  head() {
    return buildTitleAndMeta({
      title: 'KnownOrigin | Oops',
      description: 'Oops...You\'re lost or somethings went bang!',
      image: 'https://knownorigin.io/branding/KO-logo-169ratio.jpg',
      url: 'https://knownorigin.io/'
    });
  },
  mounted() {
    this.loading = true;
    try {
      console.error('error', this.error);
      this.$apollo.getClient()
        .query({
          query: RECENT_EDITIONS,
          variables: {
            first: 6,
            skip: 0,
            orderBy: 'createdTimestamp',
            orderDirection: 'desc'
          }
        })
        .then((result) => {
          this.editions = _get(result, 'data.editions');
        })
        .catch((error) => {
          this.loadingError = error;
          this.loading = false;
          this.loadingError = true;
        })
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }
}
</script>
