<template>
  <user-profile-lookup
    v-if="user && user.id"
    :artist-account="user.id"
    :metadata-artist="user.id"
  >
    <template slot="artist-profile-subtitle">
      <trending-score v-if="user && user.score && user.id" :score="user.score" :address="user.id" :no-padding="true" :strong="strong"></trending-score>
    </template>
  </user-profile-lookup>

  <div v-else class="media is-align-items-center">
    <div class="media-left">
      <b-skeleton circle active width="64px" height="64px" />
    </div>

    <div class="media-content">
      <div class="content is-flex is-flex-wrap-wrap">
        <b-skeleton active width="232px" height="24px" />
        <b-skeleton active width="75px" height="24px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user', 'strong']
}
</script>

<style lang="scss">

</style>
