<template>
  <modal-template :edition="edition" :transaction-hash="null">

    <template slot="content-title">
      <p>
        Set minimum bid
      </p>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">&nbsp;</template>

    <template slot="secondary-content">
      <section>
        <b-field label="Enter minimum bid price" :message="`Min. sale price is ${minAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   v-model="form.priceInWei"
                   type="number"
                   step="any">
          </b-input>
        </b-field>
        <p v-if="minBid && minBid.eth_reserve_in_wei">
          Current minimum bid:
          <eth-with-fiat-price :price-in-wei="minBid.eth_reserve_in_wei" :show-fiat="true"></eth-with-fiat-price>
        </p>
        <p>
          Setting an minimum bid <span class="has-text-weight-semibold">for free</span> is a way to signal to buyers that there is a minimum price which you will consider
          before accepting.
        </p>
        <p class="has-text-weight-semibold">
          You are still responsible for resulting any auction.
        </p>
        <p v-if="response && response.success">
          Minimum bid set
          <b-icon icon="check-circle-outline" size="is-small"></b-icon>
        </p>
        <p class="has-text-danger" v-if="error">
          Minimum bid not set!
        </p>
      </section>
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                :disabled="disableButton"
                :loading="loading"
                @click="changePrice">
        Set minimum bid
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {ethers} from 'ethers';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth} from '../../services/utils';
import ModalTemplate from './ModalTemplate';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    EthWithFiatPrice,
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition', 'token', 'type'],
  data() {
    return {
      minAmount: listOrOfferAmountFloorInEth,
      response: null,
      minBid: null,
      loading: false,
      error: false,
      form: {
        priceInWei: null
      }
    };
  },
  computed: {
    disableButton() {
      if (Number(this.form.priceInWei) === 0) {
        return false;
      }

      return (Number(this.form.priceInWei || 0) < this.minAmount);
    }
  },
  mounted() {
    this.getLatestReserve();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async getLatestReserve() {
      const minBid = await this.$store.dispatch('offerStore/getOffchainMinimumBid', {
        current_owner: this.currentOwner(),
        artwork_id: this.artworkId(),
        entity_type: this.type
      });
      console.log('minBid', minBid);
      this.minBid = minBid;
    },
    currentOwner() {
      return this.type === 'edition'
        ? this.edition.artistAccount
        : this.token.currentOwner.id;
    },
    artworkId() {
      return this.type === 'edition'
        ? this.edition.id
        : this.token.id;
    },
    async changePrice() {
      this.loading = true;
      this.error = false;
      try {
        const salesType = this.type === 'edition'
          ? this.edition.salesType
          : this.token.salesType;

        const kodaVersion = this.type === 'edition'
          ? this.edition.version
          : this.token.version;

        this.response = await this.$store.dispatch('offerStore/setOffchainMinimumBid', {
          koda_version: kodaVersion,
          sale_type: salesType,
          current_owner: this.currentOwner(),
          eth_reserve_in_wei: ethers.utils.parseEther(this.form.priceInWei || 0).toString(),
          artwork_id: this.artworkId(),
          entity_type: this.type,
          date_signed: Date.now().toString()
        });

        if (this.response && this.response.success) {
          await this.getLatestReserve();
          // after a few seconds, close the modal
          setTimeout(this.close, 1500);
        } else {
          this.error = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
