var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "container section" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content has-text-centered",
        staticStyle: { "margin-top": "50px" },
      },
      [
        _c("nuxt-link", { attrs: { to: "/" + _vm.path } }, [
          _vm._v("Return to " + _vm._s(_vm.path)),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "has-text-centered" }, [
      _c("h1", { staticClass: "is-uppercase has-text-danger" }, [
        _vm._v("\n      Oops...You're lost or somethings went bang!\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }