<template>
  <div class="is-flex is-align-items-center">
    <div v-if="isValidGatedSale"
         class="column has-text-left has-padding-right-3">
      <div v-if="hasSalePrice && activityEvent.buyer">
        <n-link :to="{name: 'profile-id', params: {id: activityEvent.buyer}}"
                :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
          <div class="is-size-7 mb-1" v-if="activityEvent.buyer">
            {{ mapActivityEventPersonaLabel(activityEvent.eventType) }}
          </div>
          <owner-name-and-image-lookup
            :account="activityEvent.buyer"
            is-cc="isCc"
            class="is-inline-block">
          </owner-name-and-image-lookup>
        </n-link>
      </div>
      <div class="has-text-weight-semibold" v-else-if="edition.gatedSale.phases[0].state === 'pre-phase'"
           :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
        <p>Starts in</p>
        <clock-count-down-simple :start-date="edition.gatedSale.phases[0].startTime" :white-text="false">
        </clock-count-down-simple>
      </div>
      <div v-else-if="edition.gatedSale.phases[0].state === 'active'"
           :class="{'is-muted': !isCc, 'has-text-white': isCc}">
        <p class="has-text-weight-semibold">Public price</p>
        <eth-with-fiat-price :price-in-wei="edition.priceInWei"
                             class="has-text-weight-semibold">
        </eth-with-fiat-price>
      </div>
    </div>

    <div v-else-if="!startDatePassed && !isValidGatedSale"
         class="column has-text-left has-padding-right-3 is-family-tertiary">
      <div class="has-text-weight-semibold" :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
        Starts in
        <clock-count-down-simple :start-date="edition.startDate"
                                 :class="{'has-text-dark': !isCc, 'has-text-white': isCc}"
                                 :white-text="false"
                                 class="has-text-weight-semibold">
        </clock-count-down-simple>
      </div>
    </div>

    <div v-else-if="activityEvent.buyer !== '0x0000000000000000000000000000000000000000'"
         class="column has-text-left has-padding-right-3">
      <n-link v-if="activityEvent.buyer === null && isCc" :to="handleMarketPlaceRouting">
      </n-link>
      <n-link v-else :to="{name: 'profile-id', params: {id: activityEvent.buyer}}">
        <div class="is-size-7 mb-1 is-family-tertiary has-text-weight-bold" v-if="activityEvent.buyer"
             :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
          {{ mapActivityEventPersonaLabel(activityEvent.eventType) }}
        </div>
        <owner-name-and-image-lookup
          :account="activityEvent.buyer" :is-cc="isCc"
          class="is-inline-block">
        </owner-name-and-image-lookup>
      </n-link>
    </div>
    <div v-else class="columns is-flex is-vcentered">
      <footer-right-asset-card :edition="edition" :from-activity="true" class="column"
                               :class="{'has-text-dark': !isCc, 'has-text-white': isCc}">
      </footer-right-asset-card>
    </div>
  </div>
</template>
<script>
import OwnerNameAndImageLookup from '../OwnerNameAndImageLookup';
import {editionStartDatePassed, mapActivityEventPersonaLabel} from '../../services/utils';
import ClockCountDownSimple from '../clocks/ClockCountDownSimple';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import EthWithFiatPrice from '../../components/common/EthWithFiatPrice';
import editionUtils from '../../services/editionUtils';
import {handleGalleryRouting} from '../../services/routing';
import {isCreatorContract} from '../../services/CreatorContractUtils';
import FooterRightAssetCard from './FooterRightAssetCard';

export default {
  components: {
    EthWithFiatPrice,
    OwnerNameAndImageLookup,
    FooterRightAssetCard,
    ClockCountDownSimple
  },
  props: ['edition', 'activityEvent'],
  computed: {
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    startDatePassed() {
      return editionStartDatePassed(this.edition.startDate);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    isValidGatedSale() {
      return this.edition.gatedSale && this.edition.gatedSale.phases.length > 0 && this.edition.gatedSale.phases[0].state !== 'public';
    },
    hasSalePrice() {
      return this.activityEvent.eventValueInWei && parseInt(this.activityEvent.eventValueInWei) > 0;
    },
    isCc() {
      return isCreatorContract(this.edition);
    },
    handleMarketPlaceRouting() {
      return handleGalleryRouting(this.edition, false);
    }
  },
  methods: {
    mapActivityEventPersonaLabel
  }
};
</script>
