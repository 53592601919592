var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isWertPurchaseInFlight
        ? _c(
            "modal-template",
            {
              attrs: {
                edition: _vm.edition,
                "transaction-hash": _vm.transactionHash,
              },
            },
            [
              _c(
                "template",
                { slot: "content-title" },
                [
                  _vm._v("\n      Buy with card for\n      "),
                  _c("eth-with-fiat-price", {
                    attrs: { "price-in-eth": _vm.itemPrice },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "content-sub-title" },
                [
                  _c("artwork-name-and-artist", {
                    attrs: {
                      metadata: _vm.edition.metadata,
                      "edition-number": _vm.edition.id,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "secondary-content" }, [
                _c(
                  "section",
                  { staticClass: "has-padding-bottom-7" },
                  [
                    _c("p", [
                      _vm._v("\n          Read more about making\n          "),
                      _c(
                        "a",
                        {
                          staticClass: "is-linkable",
                          attrs: {
                            href: "https://knownorigin.io/journal/platformupdate/card-purchases",
                            target: "_blank",
                          },
                        },
                        [_vm._v("\n            card purchases\n          ")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("b-notification", { attrs: { closable: false } }, [
                      _c("p", { staticClass: "has-text-weight-semibold" }, [
                        _vm._v(
                          "\n            The first time you make a purchase you will need to provide proof-of-identity details.\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            After your initial card purchase you will not need to do it again.\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            Once the payment has been triggered, you may close the popup as it will complete automatically.\n          "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("\n          Payments are done by\n          "),
                      _c("strong", [
                        _c(
                          "a",
                          {
                            staticClass: "is-linkable",
                            attrs: {
                              href: "https://wert.io/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Wert.io")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.marketplacesBlocked
                      ? _c("section", [_c("marketplace-blocker-message")], 1)
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "action-feedback-label" }, [
                _vm._v("\n      Buying\n    "),
              ]),
              _vm._v(" "),
              _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "action-button" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary", expanded: "" },
                      on: { click: _vm.startWertFlow },
                    },
                    [_vm._v("\n        Continue\n      ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button-nevermind" },
        [
          _c(
            "b-button",
            {
              attrs: { expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.close(false)
                },
              },
            },
            [_vm._v("\n      Nevermind\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "wert-widget" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }