var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      class: { "box-skinny": _vm.skinny, "box-fat": !_vm.skinny },
    },
    [
      _c(
        "figure",
        {
          staticClass: "avatar",
          staticStyle: { "background-size": "cover" },
          style: { "background-image": "url(" + _vm.coverImage + ")" },
        },
        [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: {
                  src: `${_vm.$userMediaApi.defaults.baseURL}/media/user/profile/${_vm.artist.address}`,
                  error: _vm.createAccountIcon(_vm.artist.address),
                },
                expression:
                  "{\n          src: `${$userMediaApi.defaults.baseURL}/media/user/profile/${artist.address}`,\n          error: createAccountIcon(artist.address)\n        }",
              },
            ],
            staticClass: "is-rounded",
          }),
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "content" }, [
        _c(
          "h2",
          [
            _vm._v("\n      " + _vm._s(_vm.artist.username) + " "),
            _vm.artist &&
            _vm.isTwitterVerified(
              _vm.artist.address.toLowerCase(),
              _vm.artist.twitter
            )
              ? _c("b-icon", {
                  staticClass: "is-size-5 is-size-6-mobile is-verified",
                  attrs: { icon: "check-decagram", size: "is-small" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content has-margin-7" }, [
        _c(
          "div",
          [
            _c("artist-stats", {
              attrs: { account: _vm.artist.address, skinny: _vm.skinny },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.skinny
          ? _c("p", { staticClass: "is-family-primary" }, [
              _vm._v(
                "\n      " +
                  _vm._s((_vm.artist.bio || "").substring(0, 150)) +
                  "\n    "
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }