<template>
  <div v-if="views" class="profileViews">
    <p class="is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 has-text-weight-bold is-family-tertiary">PROFILE VIEWS</p>
    <span class="is-flex is-align-items-center has-text-weight-semibold is-size-6">
    <b-icon icon="eye-outline" size="is-small" class="mr-1"></b-icon>
    <span>{{ views }}</span>
    </span>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: ['address'],
  computed: {
    ...mapState('web3Store', ['chainId'])
  },
  data() {
    return {
      views: null
    };
  },
  async mounted() {
    try {
      if (this.address) {
        const {views} = await this.$cacheApi.$get(`/network/${this.chainId}/pagecount/profile/${this.address}`);
        this.views = views;
      }
    } catch (e) {
      console.log('Problem with API', e);
    }
  }
};
</script>
<style lang="scss">
.profileViews {
  p {
    white-space: nowrap !important;
  }
}
</style>
