<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      <div v-if="isSteppedSalesType(edition.salesType)">
        Buy next step
      </div>
      <div v-else>
        Buy now for
        <eth-with-fiat-price :price-in-wei="edition.priceInWei" />
      </div>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id"></artwork-name-and-artist>
    </template>

    <template slot="action-feedback-label">
      Buying
    </template>

    <template slot="secondary-content" v-if="isSteppedSalesType(edition.salesType)">
      <section class="has-padding-bottom-7">

        <b-field>
          <b-radio v-model="stepX" native-value="1" :disabled="transactionHash != null">
            Buy current step
            <eth-with-fiat-price :price-in-wei="edition.priceInWei">
            </eth-with-fiat-price>
          </b-radio>
        </b-field>

        <b-field v-if="parseInt(edition.remainingSupply) > 1">
          <b-radio v-model="stepX" native-value="2" :disabled="transactionHash != null">
            Buy next step
            <eth-with-fiat-price :price-in-wei="getNextStep(edition.priceInWei, edition.stepSaleStepPrice)">
            </eth-with-fiat-price>
          </b-radio>
        </b-field>

        <b-field v-if="parseInt(edition.remainingSupply) > 2">
          <b-radio v-model="stepX" native-value="3" :disabled="transactionHash != null">
            Custom step (advanced)
            <eth-with-fiat-price
              v-if="stepX === '3'"
              :price-in-wei="getStepSalePriceAt(edition.priceInWei, edition.stepSaleStepPrice, selectedStep)">
            </eth-with-fiat-price>
          </b-radio>
        </b-field>

        <b-field v-if="stepX === '3'" label="Choose a step">
          <b-slider :min="1" :max="parseInt(edition.remainingSupply)" :step="1"
                    rounded
                    ticks
                    :disabled="transactionHash != null"
                    v-model="selectedStep">
          </b-slider>
        </b-field>

        <span>
          <b-message type="is-info" :closable="false" :duration="0" class="is-size-7">
            <p>
              Each sale increases the price, choose the maximum price you are willing to pay to secure this artwork.
            </p>
            <strong>
              You only pay for the first step you secure, you will be refunded any overpayment in the same transaction.
            </strong>
            <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types"
               target="_blank"
               class="is-linkable">Learn more</a>
          </b-message>
        </span>
      </section>
    </template>

    <template slot="secondary-content" v-if="marketplacesBlocked">
      <section>
        <marketplace-blocker-message />
      </section>
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                :disabled="disableBuyButton"
                @click="buyNow"
                v-if="isBuyableSalesType(edition.salesType)"
                data-testid="buynowbtn">
        Buy now
      </b-button>
    </template>

    <template slot="action-button-nevermind">
      <b-button expanded @click="close(false)">
        Nevermind
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {mapState} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import EthWithFiatPrice from '../../common/EthWithFiatPrice';
import {
  isBuyNowSalesType,
  isSteppedSalesType,
  isBuyableSalesType,
  getNextStep,
  getStepSalePriceAt
} from '../../../services/SaleTypes';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import MarketplaceBlockerMessage from '../../messages/MarketplaceBlockerMessage';

export default {
  components: {
    MarketplaceBlockerMessage,
    ArtworkNameAndArtist,
    ModalTemplate,
    EthWithFiatPrice
  },
  props: ['edition', 'algoliaInfo'],
  data() {
    return {
      transactionHash: null,
      stepX: null,
      selectedStep: 0,
      purchasePrice: 0,
      marketplacesBlocked: false
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    disableBuyButton() {
      // disable the button is the its a step and not selected
      if (isSteppedSalesType(this.edition.salesType) && this.stepX === null) {
        return true;
      }
      return this.transactionHash != null;
    }
  },
  async mounted() {
    if (this.edition.creatorContract && this.edition.creatorContract.id) {
      const contractDetails = await this.$store.dispatch('creatorContractStore/fetchContractDetails', {
        address: this.edition.creatorContract.id
      })
      if (contractDetails.filterRegistry?.supported) { // check filter registry for blocked marketplaces
        this.marketplacesBlocked = true;
      }
    }
  },
  methods: {
    getNextStep,
    getStepSalePriceAt,
    close(complete) {
      this.$emit('close', {purchaseComplete: complete});
    },
    async buyNow() {
      try {
        let tx;
        if (this.edition.version === '4') {
          tx = await this.buyNowV4()
        } else if (isSteppedSalesType(this.edition.salesType)) {
          tx = await this.buyNowStep()
        } else {
          tx = await this.buyNowFixed()
        }

        // wait for the transaction to be mined
        await tx.wait(1);

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.$options.filters.toEth(this.purchasePrice),
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isSteppedSalesType(this.edition.salesType) ? 'stepped sale' : this.edition.gatedSale ? 'early access public' : 'buy now',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.edition.startDate,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.$options.filters.toEth(this.purchasePrice)),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.txHash]: tx.hash,
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: isSteppedSalesType(this.edition.salesType) ? 'stepped sale' : this.edition.gatedSale ? 'early access public' : 'buy now',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
              [AMPLITUDE_EVENT_PROPERTIES.contractVersion]: this.edition.version,
              [AMPLITUDE_EVENT_PROPERTIES.contractAddress]: this.edition.creatorContract ? this.edition.creatorContract.id : null
            }
          }
        });
        await this.$store.dispatch('userStore/getContractAddress', {
          chainId: this.chainId,
          version: this.edition.version
        }).then((contractAddress) => {
          if (contractAddress) {
            this.$gtm.push({
              event: 'Purchase',
              value: this.$options.filters.toEth(this.purchasePrice),
              editiontitle: this.edition.metadata.name,
              tokenid: this.edition.id,
              artist: this.edition.artistAccount,
              purchasetype: 'BuywithEth',
              marketplace: 'Primary',
              chain: this.chainId,
              contracttype: this.edition.version,
              contractaddress: contractAddress
            })
          }
        })

        this.close(true);
      } catch (e) {
        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseFailed,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id,
            [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseFailed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isSteppedSalesType(this.edition.salesType) ? 'stepped sale' : 'buy now',
            [AMPLITUDE_EVENT_PROPERTIES.contractVersion]: this.edition.version
          }
        });

        console.error(e);
      }
    },
    async buyNowFixed() {
      const tx = await this.$store.dispatch('web3ActionsStore/purchaseEdition', {
        edition: this.edition.id,
        priceInWei: this.edition.priceInWei,
        version: this.edition.version
      });
      this.purchasePrice = this.edition.priceInWei
      this.transactionHash = tx.hash;
      return tx;
    },
    async buyNowV4() {
      const tx = await this.$store.dispatch('web3ActionsStore/purchaseEditionV4', {
        edition: this.edition
      });

      this.purchasePrice = this.edition.priceInWei
      this.transactionHash = tx.hash;
      return tx;
    },
    async buyNowStep() {
      try {
        const getPrice = () => {
          if (this.stepX === '2') {
            return getNextStep(this.edition.priceInWei, this.edition.stepSaleStepPrice);
          }
          if (this.stepX === '3') {
            return getStepSalePriceAt(this.edition.priceInWei, this.edition.stepSaleStepPrice, this.selectedStep);
          }
          // if no step is at current price
          return this.edition.priceInWei;
        };

        const tx = await this.$store.dispatch('web3ActionsStore/purchaseEditionStep', {
          edition: this.edition.id,
          priceInWei: getPrice().toString(),
          version: this.edition.version
        });
        this.purchasePrice = getPrice().toString()
        this.transactionHash = tx.hash;
        return tx;
      } catch (e) {
        console.error(e);
      }
    },
    isBuyNowSalesType,
    isSteppedSalesType,
    isBuyableSalesType
  }
};
</script>
