var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _c(
          "div",
          [
            _vm._v("\n      Buy now for\n      "),
            _c("eth-with-fiat-price", {
              attrs: { "price-in-wei": _vm.totalPrice },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "total-picker" }, [
        !_vm.fetchesComplete
          ? _c(
              "div",
              [
                _c("b-skeleton", { attrs: { width: "20%", animated: true } }),
                _vm._v(" "),
                _c("b-skeleton", { attrs: { width: "40%", animated: true } }),
              ],
              1
            )
          : _c(
              "div",
              [
                _vm.maxMints > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-field",
                          [
                            _c("b-numberinput", {
                              attrs: {
                                type: "is-light",
                                min: "1",
                                max: _vm.maxMints,
                              },
                              model: {
                                value: _vm.mintTotal,
                                callback: function ($$v) {
                                  _vm.mintTotal = $$v
                                },
                                expression: "mintTotal",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "b-message",
                      { staticClass: "is-danger", attrs: { size: "is-small" } },
                      [
                        _vm._v(
                          "\n        You have exceeded your total amount of mints for this phase\n      "
                        ),
                      ]
                    ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "content-body" }, [
        _vm.maxMints > 0
          ? _c(
              "span",
              { staticClass: "p-3" },
              [
                _c("b-message", { staticClass: "is-info" }, [
                  _c("p", [
                    _vm._v(
                      "You have minted " +
                        _vm._s(_vm.userTotalMints) +
                        " pieces"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "You have " +
                        _vm._s(_vm.maxMints) +
                        " pieces still available to mint"
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Buying\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                expanded: "",
                disabled: _vm.disableBuyButton,
              },
              on: { click: _vm.buyNow },
            },
            [_vm._v("\n      Buy now\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }