import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e57ad8bc = () => interopDefault(import('../pages/activity/index.vue' /* webpackChunkName: "pages/activity/index" */))
const _09c9909d = () => interopDefault(import('../pages/artist-application/index.vue' /* webpackChunkName: "pages/artist-application/index" */))
const _b0842a22 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _13994b38 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _55eb79ba = () => interopDefault(import('../pages/creator-contracts/index.vue' /* webpackChunkName: "pages/creator-contracts/index" */))
const _f89293e2 = () => interopDefault(import('../pages/discord/index.vue' /* webpackChunkName: "pages/discord/index" */))
const _12f52cf6 = () => interopDefault(import('../pages/halloffame/index.vue' /* webpackChunkName: "pages/halloffame/index" */))
const _52ad534c = () => interopDefault(import('../pages/journal/index.vue' /* webpackChunkName: "pages/journal/index" */))
const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _ae9a7724 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _77f2a339 = () => interopDefault(import('../pages/nfclisbon/index.vue' /* webpackChunkName: "pages/nfclisbon/index" */))
const _596c08fb = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _401e5b90 = () => interopDefault(import('../pages/trending/index.vue' /* webpackChunkName: "pages/trending/index" */))
const _a6a053dc = () => interopDefault(import('../pages/trending-old/index.vue' /* webpackChunkName: "pages/trending-old/index" */))
const _0ead5e41 = () => interopDefault(import('../pages/journal/cms/spotlight/_id.vue' /* webpackChunkName: "pages/journal/cms/spotlight/_id" */))
const _3303e4c0 = () => interopDefault(import('../pages/journal/cms/stories/_id.vue' /* webpackChunkName: "pages/journal/cms/stories/_id" */))
const _4b75d2ec = () => interopDefault(import('../pages/activity/_id.vue' /* webpackChunkName: "pages/activity/_id" */))
const _7c87da07 = () => interopDefault(import('../pages/contract/_contract/index.vue' /* webpackChunkName: "pages/contract/_contract/index" */))
const _4d76b746 = () => interopDefault(import('../pages/gallery/_id.vue' /* webpackChunkName: "pages/gallery/_id" */))
const _b8d32098 = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _4c88ff55 = () => interopDefault(import('../pages/specials/_id.vue' /* webpackChunkName: "pages/specials/_id" */))
const _1993c7d6 = () => interopDefault(import('../pages/tokens/_id.vue' /* webpackChunkName: "pages/tokens/_id" */))
const _3e5c7f9b = () => interopDefault(import('../pages/collections/_slug/_id.vue' /* webpackChunkName: "pages/collections/_slug/_id" */))
const _3afa9ba2 = () => interopDefault(import('../pages/contract/_contract/_id.vue' /* webpackChunkName: "pages/contract/_contract/_id" */))
const _f275d132 = () => interopDefault(import('../pages/journal/_.vue' /* webpackChunkName: "pages/journal/_" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3fdf212d = () => interopDefault(import('../layouts/error.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activity",
    component: _e57ad8bc,
    name: "activity"
  }, {
    path: "/artist-application",
    component: _09c9909d,
    name: "artist-application"
  }, {
    path: "/artists",
    component: _b0842a22,
    name: "artists"
  }, {
    path: "/collections",
    component: _13994b38,
    name: "collections"
  }, {
    path: "/creator-contracts",
    component: _55eb79ba,
    name: "creator-contracts"
  }, {
    path: "/discord",
    component: _f89293e2,
    name: "discord"
  }, {
    path: "/halloffame",
    component: _12f52cf6,
    name: "halloffame"
  }, {
    path: "/journal",
    component: _52ad534c,
    name: "journal"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login"
  }, {
    path: "/marketplace",
    component: _ae9a7724,
    name: "marketplace"
  }, {
    path: "/nfclisbon",
    component: _77f2a339,
    name: "nfclisbon"
  }, {
    path: "/search",
    component: _596c08fb,
    name: "search"
  }, {
    path: "/trending",
    component: _401e5b90,
    name: "trending"
  }, {
    path: "/trending-old",
    component: _a6a053dc,
    name: "trending-old"
  }, {
    path: "/journal/cms/spotlight/:id?",
    component: _0ead5e41,
    name: "journal-cms-spotlight-id"
  }, {
    path: "/journal/cms/stories/:id?",
    component: _3303e4c0,
    name: "journal-cms-stories-id"
  }, {
    path: "/activity/:id",
    component: _4b75d2ec,
    name: "activity-id"
  }, {
    path: "/contract/:contract",
    component: _7c87da07,
    name: "contract-contract"
  }, {
    path: "/gallery/:id?",
    component: _4d76b746,
    name: "gallery-id"
  }, {
    path: "/profile/:id?",
    component: _b8d32098,
    name: "profile-id"
  }, {
    path: "/specials/:id?",
    component: _4c88ff55,
    name: "specials-id"
  }, {
    path: "/tokens/:id?",
    component: _1993c7d6,
    name: "tokens-id"
  }, {
    path: "/collections/:slug/:id?",
    component: _3e5c7f9b,
    name: "collections-slug-id"
  }, {
    path: "/contract/:contract?/:id",
    component: _3afa9ba2,
    name: "contract-contract-id"
  }, {
    path: "/journal/*",
    component: _f275d132,
    name: "journal-all"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/apply",
    component: _09c9909d,
    name: "apply"
  }, {
    path: "/edition/:id",
    component: _4d76b746,
    name: "/edition/:id"
  }, {
    path: "/token/:id",
    component: _1993c7d6,
    name: "/token/:id"
  }, {
    path: "/account/:id",
    component: _b8d32098,
    name: "/account/:id"
  }, {
    path: "/artists",
    component: _596c08fb,
    name: "/artists"
  }, {
    path: "/artists/:id",
    component: _b8d32098,
    name: "/artists/:id"
  }, {
    path: "/:id",
    component: _b8d32098,
    name: "/:id"
  }, {
    path: "/gallery",
    component: _ae9a7724,
    name: "/gallery"
  }, {
    path: "/drops",
    component: _ae9a7724,
    name: "/drops"
  }, {
    path: "*",
    component: _3fdf212d,
    name: "error"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
