var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card is-shadowless",
      staticStyle: { "min-height": "24rem" },
    },
    [
      _c(
        "div",
        { staticClass: "card-image" },
        [_c("b-skeleton", { attrs: { height: "25rem" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "columns is-mobile is-multiline" }, [
            _c(
              "div",
              { staticClass: "column is-four-fifths is-paddingless" },
              [
                _vm._t("asset-card-content-top-left", function () {
                  return [
                    _c("b-skeleton", {
                      attrs: { width: "60%", animated: true },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "column has-text-right is-one-fifth is-paddingless is-size-7",
              },
              [
                _vm._t("asset-card-content-top-right", function () {
                  return [
                    _c("b-skeleton", {
                      attrs: { width: "20%", animated: true },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "column is-three-fifths is-paddingless has-margin-top-7",
              },
              [
                _vm._t("asset-card-content-bottom-left", function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "image is-32x32" },
                      [
                        _c("b-skeleton", {
                          attrs: { circle: "", width: "32px", height: "32px" },
                        }),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "column has-text-right is-two-fifths is-paddingless has-margin-top-7",
              },
              [
                _vm._t("asset-card-content-bottom-right", function () {
                  return [
                    _c("b-skeleton", {
                      attrs: { width: "40%", animated: true },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }