<template>
  <div>
    <div v-if="parentTokenDetails && parentCollectionDetails">
      <div class="level is-mobile"
           :class="{'box has-background-dark has-text-light': darkmode}"
           v-cloak>
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <div>
              <img class="thumbnail-image-cover"
                   :src="parentTokenDetails.image_url"
                   :alt="parentTokenDetails.token_id"
                   v-if="parentTokenDetails.image_url">
            </div>
          </div>
          <slot name="token-information">
            <div class="column">
              <div class="has-text-weight-medium is-size-6 is-family-primary">
                {{ truncateName(parentTokenDetails.name, 35) }}
              </div>
              <div class="is-muted is-size-6 is-family-primary mt-1 mb-2">
                {{ truncateName(parentTokenDetails.description, 28) }}
              </div>
              <slot name="external-link">
                <div class="level is-size-7">
                  <div class="level-left">
                    <div v-if="isKOCollection(parentCollectionDetails.address)">
                      <img src="/favicon.ico" class="image is-16x16 mr-1" :alt="parentTokenDetails.name">
                      <a target="_blank"
                         :class="{'has-text-light': darkmode}"
                         :href="knownOriginTokenLink(parentTokenDetails.token_id, parentTokenDetails.linkType)">
                        <b-icon icon="open-in-new" size="is-small"></b-icon>
                        View on KnownOrigin
                      </a>
                    </div>
                    <div>
                      <img :src="parentCollectionDetails.image_url ? parentCollectionDetails.image_url : '/opensea.png'"
                           class="image is-16x16 mr-1"
                           :alt="parentTokenDetails.name">
                      <a target="_blank" :class="{'has-text-light': darkmode}"
                         :href="openSeaTokenLink(parentCollectionDetails.address, parentTokenDetails.token_id)">
                        <b-icon icon="open-in-new" size="is-small"></b-icon>
                        View on OpenSea
                      </a>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
          </slot>
        </div>
        <slot name="right-side-element" class="is-pulled-right">
        </slot>
      </div>
    </div>
    <div v-if="childTokenDetails && childCollectionDetails">
      <div class="level is-mobile"
           :class="{'box has-background-dark has-text-light': darkmode}"
           v-cloak>
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <div>
              <img class="thumbnail-image-cover"
                   :src="childTokenDetails.image_url"
                   :alt="childTokenDetails.token_id"
                   v-if="childTokenDetails.image_url">
            </div>
          </div>
          <slot name="token-information">
            <div class="column">
              <div class="has-text-weight-medium is-size-6 is-family-primary">
                {{ truncateName(childTokenDetails.name, 35) }}
              </div>
              <div class="is-muted is-size-6 is-family-primary mt-1 mb-2">
                {{ truncateName(childTokenDetails.description, 28) }}
              </div>
              <slot name="external-link">
                <div class="level is-size-7">
                  <div class="level-left">
                    <div v-if="isKOCollection(childCollectionDetails.address)">
                      <img src="/favicon.ico" class="image is-16x16 mr-1" :alt="childTokenDetails.name">
                      <a target="_blank"
                         :class="{'has-text-light': darkmode}"
                         :href="knownOriginTokenLink(childTokenDetails.token_id, childTokenDetails.linkType)">
                        <b-icon icon="open-in-new" size="is-small"></b-icon>
                        View on KnownOrigin
                      </a>
                    </div>
                    <div>
                      <img :src="childCollectionDetails.image_url ? childCollectionDetails.image_url : '/opensea.png'"
                           class="image is-16x16 mr-1"
                           :alt="childTokenDetails.name">
                      <a target="_blank" :class="{'has-text-light': darkmode}"
                         :href="openSeaTokenLink(childCollectionDetails.address, childTokenDetails.token_id)">
                        <b-icon icon="open-in-new" size="is-small"></b-icon>
                        View on OpenSea
                      </a>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
          </slot>
        </div>
        <slot name="right-side-element" class="is-pulled-right">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import _get from 'lodash/get';
import {TOKEN_BY_ID_QUERY} from '../../queries/tokenQueries';

export default {
  props: [
    'darkmode',
    'parentToken',
    'childToken',
    'edition',
    'collection'
  ],
  data() {
    return {
      parentTokenDetails: null,
      parentCollectionDetails: null,
      childTokenDetails: null,
      childCollectionDetails: null
    }
  },
  computed: {
    ...mapGetters('web3Store', [
      'isKOCollection',
      'knownOriginTokenLink',
      'openSeaTokenLink'
    ])
  },
  async mounted() {
    try {
      const {parentToken, parentCollection} = await this.$store.dispatch('enhancedTokensStore/getNftTokenMetadata', {
        contractAddress: this.parentToken.address,
        tokenId: this.parentToken.value
      });
      this.parentTokenDetails = parentToken;
      this.parentCollectionDetails = parentCollection;

      console.log(this.parentCollectionDetails, 'parent collection details')

      console.log(this.parentTokenDetails, 'parent token details')

      if (this.parentToken.linkType) {
        this.parentTokenDetails.linkType = this.parentToken.linkType;
      }

      // Populate missing image data for PARENT if not found and a KO asset
      const isMissingParentImageUrl = _get(this.parentTokenDetails, 'image_url.length', 0) === 0;
      if (isMissingParentImageUrl && this.isKOCollection(this.parentToken.address)) {
        const result = await this.$apollo
          .query({
            client: this.gqlClient,
            query: TOKEN_BY_ID_QUERY,
            variables: {id: this.parentToken.value},
            fetchPolicy: 'no-cache'
          })
          .then(({data}) => data && data.tokenById);
        this.parentTokenDetails.image_url = this.thumbnailAsset(result.editionNumber);
      }

      const {childToken, childCollection} = await this.$store.dispatch('enhancedTokensStore/getNftTokenMetadata', {
        contractAddress: this.childToken.address,
        tokenId: this.childToken.value
      });
      this.childTokenDetails = childToken;
      this.childCollectionDetails = childCollection;

      console.log(this.childCollectionDetails, 'child collection details')

      console.log(this.childTokenDetails, 'child token details')

      if (this.childToken.linkType) {
        this.childTokenDetails.linkType = this.childToken.linkType;
      }

      // Populate missing image data for CHILD if not found and a KO asset
      const isMissingChildImageUrl = _get(this.childTokenDetails, 'image_url.length', 0) === 0;
      if (isMissingChildImageUrl && this.isKOCollection(this.childToken.address)) {
        const result = await this.$apollo
          .query({
            client: this.gqlClient,
            query: TOKEN_BY_ID_QUERY,
            variables: {id: this.childToken.value},
            fetchPolicy: 'no-cache'
          })
          .then(({data}) => data && data.tokenById);
        this.childTokenDetails.image_url = this.thumbnailAsset(result.editionNumber);
      }
    } catch (e) {
      console.log('Unable to load parent');
    }
  },
  methods: {
    truncateName(name, max) {
      return name.length > max ? name.substring(0, max - 3) + '...' : name;
    }
  }
};
</script>
