var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Embedded items\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _vm.workingERC721Token
          ? _c(
              "div",
              [
                _c(
                  "embedded-nft-tokens",
                  {
                    attrs: {
                      edition: _vm.edition,
                      "child-token": _vm.workingERC721Token,
                      "next-token": _vm.token.id,
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "right-side-element" },
                      [
                        _c(
                          "b-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.unpackERC721Token(
                                  _vm.workingERC721Token
                                )
                              },
                            },
                          },
                          [_vm._v("Redeem")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.workingERC20Tokens.length > 0
          ? _c("div", [
              _c("p", { staticClass: "title" }, [_vm._v("Embedded Tokens")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.workingERC20Tokens, function (item) {
                  return _c(
                    "li",
                    { key: item.id },
                    [
                      _c(
                        "erc20-token-information",
                        { attrs: { token: item } },
                        [
                          _c("template", { slot: "middle" }),
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "right" },
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.unpackERC20Token(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        item.clicked ? "View Item" : "Redeem"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("section", { staticClass: "mt-4 is-size-7" }, [
          _c("p", [
            _vm._v("\n        Read more about how this work\n        "),
            _c(
              "a",
              {
                staticClass: "is-linkable",
                attrs: {
                  href: "https://docs.knownorigin.io/en/articles/6554600-composing-other-tokens",
                  target: "_blank",
                },
              },
              [_vm._v("\n          here\n        ")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Redeeming embedded item\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", expanded: "" },
              on: { click: _vm.unpackAllItems },
            },
            [_vm._v("\n      Redeem All\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }