<template>
  <article class="tile is-child pt-5" v-if="parentOfChildNft || childToken">
    <div v-if="parentOfChildNft">
      <p class="title">Contained within</p>
      <single-embedded-nft :token="parentOfChildNft" :darkmode="darkmode">
      </single-embedded-nft>
    </div>
    <div v-if="childToken" :class="{ 'pt-5': parentOfChildNft }">
      <p class="title">Contains</p>
      <single-embedded-nft :token="childToken" :darkmode="darkmode">
        <template slot="right-side-element">
          <slot name="right-side-element" class="is-pulled-right">

          </slot>
        </template>
      </single-embedded-nft>
    </div>
  </article>
</template>

<script>
import _get from 'lodash/get';
import SingleEmbeddedNft from './SingleEmbeddedNft';
import {EMBEDDED_DATA_FOR_TOKEN} from '@/queries/enhancedTokenQueries';

export default {
  components: {
    SingleEmbeddedNft
  },
  props: [
    'darkmode',
    'edition',
    'parentToken',
    'childToken',
    'nextToken'
  ],
  data() {
    return {
      editionById: null,
      enhancedTokensERC721: null,
      nextTokenID: null,
      parentOfChildNft: null,
      embeddedData: null
    };
  },
  watch: {
    nextToken(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkCurrentTokenIsNotComposed();
      }
    }
  },
  async mounted() {
    await this.checkCurrentTokenIsNotComposed();
  },
  methods: {
    async checkCurrentTokenIsNotComposed() {
      this.$apollo.addSmartQuery('embeddedData', {
        query: EMBEDDED_DATA_FOR_TOKEN,
        client: this.gqlClient,
        variables() {
          return {
            id: this.nextToken ? this.nextToken.toString() : _get(this.edition, 'id', '0')
          };
        },
        result({data, loading}) {
          if (!loading && data && data.embeddedData && data.embeddedData[0]) {
            this.parentOfChildNft = {
              address: data.embeddedData[0].address,
              value: data.embeddedData[0].tokenID,
              linkType: 'edition'
            };
          }
        },
        error(error) {
          console.log('embedded data error : ', error);
        }
      });
    }
  }
};
</script>
