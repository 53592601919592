var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.cover
      ? _c(
          "video",
          {
            ref: "videoPlayer",
            staticClass: "video-js forced-white",
            attrs: {
              loop: "loop",
              muted: "muted",
              autoplay: "autoplay",
              playsinline: "playsinline",
              disablePictureInPicture: "",
              controlsList: "nodownload",
              poster: _vm.cardAsset,
              id: `edition_video_${_vm.editionNumber}`,
            },
            domProps: { muted: true },
          },
          [
            _c("p", [
              _vm._v(
                "\n      Sorry your browser doesn't seem to like playing this type of media.\n    "
              ),
            ]),
          ]
        )
      : _c(
          "div",
          {
            directives: [
              {
                name: "lazy-container",
                rawName: "v-lazy-container",
                value: { selector: "img" },
                expression: "{ selector: 'img' }",
              },
            ],
          },
          [
            !_vm.coverImageLoadError
              ? _c("img", {
                  class: { "card-image-cover": _vm.cover },
                  attrs: {
                    id: `edition_image_${_vm.editionNumber}`,
                    "data-src": _vm.cardAsset,
                    "data-loading": "/branding/bigloader-image.svg",
                    "data-error": "/branding/holding_image.jpg",
                  },
                  on: { error: _vm.onCoverImageLoadError },
                })
              : _c(
                  "video",
                  {
                    ref: "videoPlayer",
                    class: { "card-image-cover": _vm.cover },
                    attrs: {
                      id: `edition_video_${_vm.editionNumber}`,
                      width: "100%",
                      height: "100%",
                      loop: "loop",
                      muted: "muted",
                      autoplay: "autoplay",
                      playsinline: "playsinline",
                      disablePictureInPicture: "",
                      controlsList: "nodownload",
                      poster: "/branding/bigloader-image.svg",
                    },
                    domProps: { muted: true },
                  },
                  [
                    _c("source", {
                      attrs: { src: _vm.fullAsset, type: _vm.videoMimeType },
                    }),
                    _vm._v(" "),
                    _c("source", {
                      attrs: {
                        src: _vm.hlsStream,
                        type: "application/x-mpegURL",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n        Sorry your browser doesn't seem to like playing this type of media.\n      "
                      ),
                    ]),
                  ]
                ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }