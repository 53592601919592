var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "content scrollable-content is-family-tertiary" },
    [
      _c(
        "ul",
        [
          _vm.editionData && _vm.editionData.lowResImg
            ? _c("content-auth-label", {
                attrs: {
                  "edition-id": _vm.editionId,
                  "image-url": _vm.editionData.lowResImg,
                  minter: _vm.editionData.artistAccount,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editionData
            ? _c("li", [
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column heading" },
                    [
                      _c("image-metadata", {
                        attrs: { edition: _vm.editionData },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.mappedTokenMetadata
            ? _c("li", [
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column heading" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.mappedTokenMetadata,
                          target: "_blank",
                        },
                      },
                      [_vm._v("View Metadata")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.viewTokenOnIPFS
            ? _c("li", [
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column heading" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.viewTokenOnIPFS, target: "_blank" },
                      },
                      [_vm._v("View media on IPFS")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.version
            ? _c("li", [
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column heading" }, [
                    _vm._v("Version " + _vm._s(_vm.version)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.salesType
            ? _c("li", [
                _c("div", { staticClass: "columns is-mobile" }, [
                  _c("div", { staticClass: "column heading" }, [
                    _vm._v(_vm._s(_vm.mapSalesType(_vm.salesType))),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "columns is-mobile" }, [
        _c("div", { staticClass: "column heading" }, [
          _vm._v("ERC721 token standard"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }