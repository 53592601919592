var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    { attrs: { edition: _vm.edition } },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n      Make an offer\n    "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "secondary-content" },
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                "aria-role": "list",
                "append-to-body": true,
                scrollable: true,
                "max-height": 350,
              },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function ({ active }) {
                    return _c(
                      "button",
                      { staticClass: "button" },
                      [
                        !_vm.selectedToken
                          ? _c("span", [
                              _c("span", [_vm._v("Select an edition no.")]),
                            ])
                          : _c("span", [
                              _c(
                                "span",
                                { staticClass: "has-text-weight-bold" },
                                [
                                  _vm._v(
                                    "\n              #" +
                                      _vm._s(_vm.selectedToken.id) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.selectedToken.isListed
                                ? _c(
                                    "span",
                                    [
                                      _vm._v("\n              | Listed for "),
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          "price-in-eth":
                                            _vm.selectedToken.listPrice,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedToken.openOffer
                                ? _c(
                                    "span",
                                    { staticClass: "has-text-weight-bold" },
                                    [
                                      _vm._v(
                                        "\n              | Current offer "
                                      ),
                                      _c("eth-with-fiat-price", {
                                        attrs: {
                                          size: "is-small",
                                          "price-in-eth":
                                            _vm.selectedToken.openOffer
                                              .ethValue,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "has-text-weight-semibold" },
                                    [
                                      _vm._v(
                                        "\n              | No open offers\n            "
                                      ),
                                    ]
                                  ),
                            ]),
                        _vm._v(" "),
                        !_vm.selectedToken
                          ? _c("b-icon", {
                              attrs: { icon: active ? "sort-up" : "sort-down" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  },
                },
              ]),
              model: {
                value: _vm.selectedToken,
                callback: function ($$v) {
                  _vm.selectedToken = $$v
                },
                expression: "selectedToken",
              },
            },
            [
              _vm._v(" "),
              _vm._l(_vm.tokens, function (token, idx) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: idx,
                    attrs: { "aria-role": "listitem", value: token },
                  },
                  [
                    _c("span", { staticClass: "has-text-weight-bold" }, [
                      _vm._v(
                        "\n            #" + _vm._s(idx + 1) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    token.isListed
                      ? _c(
                          "span",
                          [
                            _vm._v("\n            | Listed for "),
                            _c("eth-with-fiat-price", {
                              attrs: { "price-in-eth": token.listPrice },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _vm._v("\n            | Previous "),
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": token.lastSalePriceInEth,
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    token.openOffer
                      ? _c(
                          "span",
                          { staticClass: "has-text-weight-bold" },
                          [
                            _vm._v("\n            | Current offer "),
                            _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth": token.openOffer.ethValue,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "has-text-weight-semibold" },
                          [_vm._v("\n            | No open offers\n          ")]
                        ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: { expanded: "", disabled: !_vm.selectedToken },
              on: {
                click: function ($event) {
                  return _vm.launchMakeOfferModel(_vm.selectedToken)
                },
              },
            },
            [_vm._v("\n        Make offer\n      ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }