var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "editorial-one has-background-light mb-6" },
    [
      _c(
        "div",
        { staticClass: "editorial-one-mobile-image is-hidden-tablet" },
        [
          _c("img", {
            attrs: {
              src: _vm.cmsComponent.backgroundImage,
              "aria-hidden": "true",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "container section py-0" }, [
        _c(
          "div",
          { staticClass: "editorial-one-columns columns content is-vcentered" },
          [
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("h2", { staticClass: "remove-margin title is-size-7" }, [
                  _c("img", {
                    staticClass: "editorial-one-logo",
                    attrs: { src: "/branding/KO-Icon-Blue.svg" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "editorial-one-subtitle has-text-weight-light",
                    },
                    [_vm._v(_vm._s(_vm.cmsComponent.subtitle))]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    staticClass:
                      "remove-margin title is-size-3 has-text-weight-medium",
                  },
                  [_vm._v(_vm._s(_vm.cmsComponent.title))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "editorial-one-text-holder" }, [
                  _c(
                    "p",
                    {
                      staticClass: "editorial-one-text is-family-primary py-0",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cmsComponent.description) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "NuxtLink",
                  {
                    staticClass: "editorial-one-button button",
                    attrs: { to: _vm.cmsComponent.buttonLink },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleButtonClickedEvent(
                          _vm.cmsComponent.buttonLink
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cmsComponent.buttonLabel) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  _vm._l(_vm.cmsComponent.editorial2Fields, function (credit) {
                    return _c(
                      "p",
                      { key: credit.title, staticClass: "is-size-7" },
                      [
                        _c("a", { attrs: { href: credit.link } }, [
                          _vm._v("\n              " + _vm._s(credit.title)),
                        ]),
                        _c("br"),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "editorial-one is-hidden-mobile",
              attrs: {
                src: _vm.cmsComponent.backgroundImage,
                "aria-hidden": "true",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }