var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "client-only",
        [
          _vm.lessThanAnHour
            ? _c("vue-countdown", {
                attrs: {
                  time: _vm.diff,
                  transform: _vm.transform,
                  "emit-events": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(props.hours) +
                              ":" +
                              _vm._s(props.minutes) +
                              ":" +
                              _vm._s(props.seconds) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  537603124
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }