<template>
  <n-link :to="{name:'gallery-id', params: { id: editionId}}">
    <figure v-lazy-container="{ selector: 'img' }">
      <img
        :data-src="`${$cdnApi.defaults.baseURL}/cdn/images/network/${chainId}/edition/${editionId}/thumbnail`"
        data-loading="/branding/ko-load.svg"
      >
    </figure>
  </n-link>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    props: ['editionId'],
    computed: {
      ...mapState('web3Store', ['chainId'])
    }
  };
</script>
