<template>
  <b-tag class="is-dark is-uppercase hand-pointer" ref="unlockable-label"
         @click.native="openUnlockableDocs">
    <b-icon icon="lock" type="is-light" size="is-small"></b-icon>
    UNLOCKABLE
  </b-tag>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  components: {},
  props: ['editionNumber'],
  computed: {
    ...mapGetters('unlockableContentStore', [
      'hasUnlockableContent'
    ])
  },
  methods: {
    openUnlockableDocs() {
      window.open('https://docs.knownorigin.io/en/articles/6118698-unlockable-content', '_blank');
    }
  }
};
</script>
