var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("section", { staticClass: "mb-2 mt-6 content" }, [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-half-desktop" },
          [
            _c(
              "b-field",
              {
                staticClass: "mb-6",
                attrs: {
                  label: "Name your collection *",
                  message: _vm.buildSlug,
                  type: { "is-danger": !_vm.isValidSlug },
                },
              },
              [
                _c("b-input", {
                  attrs: { minlength: "3", maxlength: "50" },
                  model: {
                    value: _vm.form.collectionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "collectionName", $$v)
                    },
                    expression: "form.collectionName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "mb-6",
                attrs: { label: "Describe your collection *" },
              },
              [
                _c("b-input", {
                  attrs: {
                    type: "textarea",
                    minlength: "1",
                    maxlength: "1000",
                  },
                  model: {
                    value: _vm.form.collectionDescription,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "collectionDescription", $$v)
                    },
                    expression: "form.collectionDescription",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("nav", { staticClass: "columns has-margin-bottom-6 has-margin-top-6" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "aside",
        {
          staticClass:
            "column is-half-desktop is-full-mobile has-text-right-desktop",
        },
        [
          _c("div", { staticClass: "field-group" }, [
            _c(
              "div",
              { staticClass: "field field-body is-horizontal is-inline-block" },
              [
                _c(
                  "b-select",
                  {
                    staticClass: "has-margin-right-6 has-margin-left-7",
                    attrs: { placeholder: "Filter by", size: "is-small" },
                    model: {
                      value: _vm.selectedFilter,
                      callback: function ($$v) {
                        _vm.selectedFilter = $$v
                      },
                      expression: "selectedFilter",
                    },
                  },
                  _vm._l(_vm.filterOptions, function (option) {
                    return _c(
                      "option",
                      { key: option.key, domProps: { value: option } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(option.label) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field field-body is-horizontal is-inline-block" },
              [
                _c(
                  "b-select",
                  {
                    staticClass: "has-margin-left-7",
                    attrs: { placeholder: "Sort by", size: "is-small" },
                    model: {
                      value: _vm.selectedSortBy,
                      callback: function ($$v) {
                        _vm.selectedSortBy = $$v
                      },
                      expression: "selectedSortBy",
                    },
                  },
                  _vm._l(_vm.sortByOptions, function (option) {
                    return _c(
                      "option",
                      { key: option.key, domProps: { value: option } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(option.label) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "columns is-multiline" },
      _vm._l(_vm.tokensByOwner, function (token, $index) {
        return _c("div", { key: $index, staticClass: "column is-one-third" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.addToCollection(token.id)
                },
              },
            },
            [
              _c("client-only", [
                _c(
                  "div",
                  {
                    staticClass: "card",
                    class: {
                      "selected-card": _vm.collectionContains(token.id),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-image" },
                      [
                        _c("image-asset", {
                          attrs: {
                            "edition-number": token.edition.id,
                            metadata: token.metadata,
                            resolution: "thumbnail",
                            cover: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-content is-paddingless pb-2" },
                      [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            { staticClass: "columns is-mobile is-multiline" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column is-four-fifths has-padding-left-2 has-padding-top-4",
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "has-text-left is-family-primary",
                                    },
                                    [_vm._v(_vm._s(token.metadata.name))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column has-text-right is-one-fifth is-size-7 has-padding-right-2 has-padding-top-4",
                                },
                                [
                                  _c("availability", {
                                    attrs: {
                                      available: token.edition.totalAvailable,
                                      supply: token.edition.totalSupply,
                                      edition: token,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column is-four-fifths has-padding-left-2 has-padding-bottom-2",
                                },
                                [
                                  _c("artist-name-and-image-lookup", {
                                    attrs: {
                                      "artist-account": token.artistAccount,
                                      name: token.metadata.artist,
                                      collaborators:
                                        token.edition.collaborators,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("footer", { staticClass: "card-footer columns" }, [
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _vm.form.items.indexOf(token.id) === 0
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "mt-3 ml-5",
                                  attrs: {
                                    type: "is-secondary",
                                    size: "is-medium",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Top\n                "
                                  ),
                                ]
                              )
                            : _vm.form.items.indexOf(token.id) >= 0 &&
                              _vm.form.items.indexOf(token.id) !==
                                _vm.form.items.length - 1
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "mt-3 ml-5",
                                  attrs: {
                                    type: "is-secondary",
                                    size: "is-medium",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Position " +
                                      _vm._s(
                                        _vm.form.items.indexOf(token.id) + 1
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm.form.items.indexOf(token.id) ===
                                _vm.form.items.length - 1 &&
                              _vm.form.items.indexOf(token.id) >= 0
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "mt-3 ml-5",
                                  attrs: {
                                    type: "is-secondary",
                                    size: "is-medium",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Bottom\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "column has-text-right has-padding-right-2",
                        },
                        [
                          !_vm.collectionContains(token.id)
                            ? _c("b-icon", {
                                staticClass: "is-clickable",
                                attrs: {
                                  icon: "checkbox-blank-circle-outline",
                                  size: "is-large",
                                  type: "is-dark",
                                },
                              })
                            : _c("b-icon", {
                                staticClass: "is-clickable",
                                attrs: {
                                  icon: "check-circle-outline",
                                  size: "is-large",
                                  type: "is-dark",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.tokensByOwner && _vm.tokensByOwner.length === 0
      ? _c(
          "div",
          [_c("empty-state", { attrs: { message: "Empty collection" } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "content mb-6" }, [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-half-desktop" },
          [
            _c("h2", { staticClass: "mb-4 is-family-secondary" }, [
              _vm._v("Display options"),
            ]),
            _vm._v(" "),
            _c(
              "b-field",
              {
                attrs: {
                  label:
                    "Select the number of artworks you wish to display on your profile page",
                },
              },
              [
                _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.form.frontPageRows,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "frontPageRows", $$v)
                      },
                      expression: "form.frontPageRows",
                    },
                  },
                  _vm._l(_vm.rowSelectionOptions, function (option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(option.name) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h2", { staticClass: "mb-4 is-family-secondary" }, [
              _vm._v("Visibility"),
            ]),
            _vm._v(" "),
            _c(
              "b-field",
              {
                attrs: {
                  label: "Make your collection private or public *",
                  message: "Only you can view this collection",
                },
              },
              [
                _c(
                  "b-radio",
                  {
                    attrs: { size: "is-medium", "native-value": true },
                    model: {
                      value: _vm.form.isPrivateCollection,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isPrivateCollection", $$v)
                      },
                      expression: "form.isPrivateCollection",
                    },
                  },
                  [_vm._v("\n            Private\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { message: "Everyone can view this collection" } },
              [
                _c(
                  "b-radio",
                  {
                    attrs: { size: "is-medium", "native-value": false },
                    model: {
                      value: _vm.form.isPrivateCollection,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isPrivateCollection", $$v)
                      },
                      expression: "form.isPrivateCollection",
                    },
                  },
                  [_vm._v("\n            Public\n          ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    type: "is-primary",
                    disabled:
                      _vm.form.saving ||
                      (_vm.form.items.length === 0 &&
                        _vm.form.items.length <= 36) ||
                      !_vm.validCollectionMetadata ||
                      !_vm.isValidSlug,
                  },
                  on: { click: _vm.saveCollection },
                },
                [_vm._v("\n            Save collection\n          ")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.form.saving },
                  on: { click: _vm.cancelEditFlow },
                },
                [_vm._v("\n            Cancel\n          ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "aside",
      { staticClass: "column is-half-desktop is-full-mobile content" },
      [
        _c("h2", { staticClass: "is-family-secondary" }, [
          _vm._v("\n        Add artworks to your collection\n      "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }