<template>
  <div v-if="!free" class="column content">
    <b-button type="is-primary" size="is-medium"
              class="has-margin-bottom-7"
              expanded
              @click="launchBuyNowEditionModal"
              data-testid="buyWithETHBtn">
      Buy with ETH
    </b-button>
    <b-button
      type="is-secondary"
      size="is-medium"
      class="has-margin-bottom-7"
      expanded
      @click="launchBuyNowEditionWertModal"
      v-if="showWert">
      Buy with card
    </b-button>
    <wert-topup-link></wert-topup-link>
  </div>
  <div v-else>
    <b-button type="is-primary" size="is-medium"
              class="has-margin-bottom-7"
              expanded
              @click="launchBuyNowEditionModal">
      Free claim
    </b-button>
  </div>
</template>
<script>
import WertTopupLink from '../WertTopupLink';
import PurchaseEdition from '../modal/edition/PurchaseEdition';
import PurchaseWithWert from '../modal/wert/PurchaseWithWert';
import PurchaseGated from '../modal/purchasing/PurchaseGated';

export default {
  components: {
    WertTopupLink
  },
  props: ['edition', 'free', 'gated', 'phase', 'proofs', 'algoliaInfo', 'hideWert'],
  computed: {
    showWert () {
      return (this.edition.version === '3' || this.edition.version === '4') && !this.hideWert
    }
  },
  methods: {
    launchBuyNowEditionModal() {
      this.$buefy.modal.open({
        parent: this.$parent,
        component: this.gated ? PurchaseGated : PurchaseEdition,
        width: 600,
        props: {
          edition: this.edition,
          phase: this.phase,
          proofs: this.proofs,
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          this.$emit('refresh-data');
        },
        events: {
          close: () => {
            this.$emit('refresh-data');
          }
        }
      });
    },
    launchBuyNowEditionWertModal() {
      this.$buefy.modal.open({
        parent: this.$parent,
        component: PurchaseWithWert,
        canCancel: ['x'],
        width: this.$device.isDesktopOrTablet ? 1000 : 600,
        props: {
          edition: this.edition,
          entityType: 'edition',
          algoliaInfo: this.algoliaInfo
        },
        onCancel: () => {
          this.$emit('refresh-data');
        },
        events: {
          close: () => {
            this.$emit('refresh-data');
          }
        }
      });
    }
  }
};
</script>
