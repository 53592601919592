<template>
  <div class="content" style="margin-left: 10px; margin-right: 10px;">
    <div class="card">
      <div class="card-content has-padding-7">
        <div class="content">
          <section>
            <h2>
              Early access list
            </h2>
            <h5 class="mb-0">
              Accounts on the early access list for this edition
            </h5>
            <section v-if="addresses">
              <b-table :data="addresses"
                       :mobile-cards="false"
                       :debounce-search="1000"
                       paginated
                       pagination-simple
                       :per-page="6"
                       striped>
                <b-table-column field="address" searchable>
                  <template #searchable="props">
                    <b-input
                      v-model="props.filters['address']"
                      placeholder="Search by wallet address ... "
                      icon="magnify"
                      style="width: 400px" />
                  </template>
                  <template #default="props">
                    <profile-name-link-and-avatar
                      :eth-account="props.row.address"
                      :disable-link="true"
                      style="padding-bottom: 0rem !important;">
                    </profile-name-link-and-avatar>
                  </template>
                </b-table-column>
                <template slot="empty">
                  <p class="has-text-weight-bold">Profile ineligible for Early Access</p>
                </template>
              </b-table>
            </section>
          </section>
        </div>

        <div class="card-footer">
          <div class="card-footer-item">
            <b-button expanded @click="close">
              Close
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProfileNameLinkAndAvatar from '../avatar/ProfileNameLinkAndAvatar';

export default {
  components: {
    ProfileNameLinkAndAvatar
  },
  props: ['edition', 'addresses'],
  data() {
    return {};
  },
  computed: {
    ...mapState('web3Store', ['chainId'])
  },
  mounted() {

  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
