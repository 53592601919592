<template>
  <div class="columns">
  <div class="column is-justify-content-start pt-5 v-offset">
    <div @click="toggleLike" class="is-clickable is-inline-block pt-2" @mouseover="hovering = true" @mouseleave="hovering = false">
      <b-icon
        :icon="iconToShow"
        :class="{'liked-heart': like || hovering && !like, 'hover-heart': hovering && like}"
        custom-size="mdi-36px">
      </b-icon>
    </div>
    <div class="is-inline-block has-padding-left-7 is-size-4 has-text-weight-light like-count">{{ displayTotalEditionLikes }}</div>
  </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  props: ['editionNumber'],
  data() {
    return {
      like: null,
      totalEditionLikes: null,
      hovering: false
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId',
      'account',
      'authToken'
    ]),
    iconToShow() {
      return this.like || this.hovering ? 'heart' : 'heart-outline'
    },
    displayTotalEditionLikes () {
      return this.totalEditionLikes;
    }
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadLikes();
      }
    }
  },
  async mounted() {
    this.loadLikes();
  },
  methods: {
    loginToWeb3() {
      this.$store.dispatch('web3Store/connectToWeb3');
    },
    async toggleLike() {
      if (this.account) {
        const {like, totalEditionLikes} = await this.$store.dispatch('userStore/toggleUserEditionLike', {
          account: this.account,
          editionNumber: this.editionNumber,
          authToken: this.authToken ? this.authToken : null
        });
        this.like = like;
        this.totalEditionLikes = totalEditionLikes;
        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.likeClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: 'editionNumber',
            [AMPLITUDE_EVENT_PROPERTIES.value]: totalEditionLikes
          }
        });
      } else {
        this.loginToWeb3();
      }
    },
    async loadLikesForAccountAndEdition() {
      const {like, totalEditionLikes} = await this.$store.dispatch('userStore/getUserEditionLike', {
        account: this.account,
        editionNumber: this.editionNumber
      });
      this.like = like;
      this.totalEditionLikes = totalEditionLikes;
    },
    async loadLikesForEdition() {
      this.totalEditionLikes = await this.$store.dispatch('userStore/getEditionLikes', {
        editionNumber: this.editionNumber
      });
    },
    async loadLikes() {
      try {
        if (this.account && this.editionNumber) {
          await this.loadLikesForAccountAndEdition();
        } else {
          await this.loadLikesForEdition();
        }
      } catch (e) {
        console.error(`Unable to load likes for ${this.editionNumber}`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";
.liked-heart {
  color: red;
}

.hover-heart {
  color: pink;
}

.like-count {
  vertical-align: top;
  padding-top: 3px;
 }

.v-offset {
    top:20px;
 }
</style>
