var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.headerImageUrl
      ? _c(
          "header",
          {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: _vm.imgObj,
                expression: "imgObj",
                arg: "background-image",
              },
            ],
            staticClass: "header-image has-text-white",
          },
          [
            _c("section", { staticClass: "hero" }, [
              _c("div", { staticClass: "hero-body" }, [
                _c("div", { staticClass: "container content" }, [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "title has-text-white has-margin-top-1 is-size-2-mobile",
                    },
                    [
                      _vm.headerImageUrl
                        ? _c(
                            "n-link",
                            {
                              staticClass: "has-text-white has-padding-top-6",
                              attrs: { to: _vm.linkToJournal },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.title) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.subtitle) +
                                  "\n              "
                              ),
                              _vm.more
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.more) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "field is-grouped" }, [
                      _c(
                        "p",
                        { staticClass: "control" },
                        [
                          !_vm.newTab
                            ? _c(
                                "n-link",
                                {
                                  staticClass: "button is-white is-outlined",
                                  attrs: { to: _vm.linkToJournal },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.buttonLabel) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "button is-white is-outlined",
                                  attrs: {
                                    href: _vm.linkToJournal,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.buttonLabel) +
                                      "\n                "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }