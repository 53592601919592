<template>
  <section class="header-editorial-banner editorial-background-mobile section ">
    <section class="editorial1-desktop p-5">
      <div class="columns content is-centered is-vcentered ">
        <div class="column has-text-left is-two-fifths pr-3">
          <h2 class="remove-margin title is-size-5 has-text-weight-light">
            {{ cmsComponent.subtitle }}
          </h2>
          <h2 class="remove-margin title is-size-1">{{ cmsComponent.title }}</h2>
          <div class="columns">
            <p class="column is-four-fifths pt-2">
              {{ cmsComponent.description }}
            </p>
          </div>
          <div class="pt-2" @click="handleButtonClickedEvent(cmsComponent.buttonLink)">
            <n-link :to="cmsComponent.buttonLink" class="button is-primary">
              {{ cmsComponent.buttonLabel }}
            </n-link>
          </div>
        </div>
        <div class="column is-two-fifths-desktop" style="padding-left: 6rem;">
          <edition-card
            v-if="componentData"
            :edition="componentData"
            :cover="true"
            resolution="card"
            @click.native="handleCardClickedEvent" />
          <EditionCardSkeleton v-else />
        </div>
      </div>
    </section>

    <section class="editorial1-mobile">
      <div class="columns content is-centered is-vcentered ">
        <div class="column has-text-left is-two-fifths pr-3">
          <h2 class="remove-margin title is-size-6 has-text-weight-light has-text-centered">
            {{ cmsComponent.subtitle }}
          </h2>
          <h2 class="remove-margin title is-size-2 has-text-centered">
            {{ cmsComponent.title }}
          </h2>
          <div class="column has-text-centered is-one-third">
            <edition-card
              v-if="componentData"
              :edition="componentData"
              :cover="true"
              resolution="card"
              @click.native="handleCardClickedEvent" />
            <EditionCardSkeleton v-else />
          </div>
          <p class="has-text-centered pt-2">
            {{ cmsComponent.description }}
          </p>
          <div class="has-text-centered pt-2" @click="handleButtonClickedEvent(cmsComponent.buttonLink)">
            <n-link :to="cmsComponent.buttonLink" class="button is-primary">
              {{ cmsComponent.buttonLabel }}
            </n-link>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import get from 'lodash/get';

import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import EditionCard from '../../EditionCard';
import EditionCardSkeleton from '../../EditionCardSkeleton';

export default {
  components: {
    EditionCard,
    EditionCardSkeleton
  },
  props: ['cmsComponent'],
  data() {
    return {
      componentData: null
    }
  },
  mounted() {
    // when element is in view, load data once
    this.observer = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        const data = await this.$store.dispatch('componentDataStore/fetchComponentData', this.cmsComponent)
        this.componentData = data
        this.observer.unobserve(this.$el)
      }
    })
    this.observer.observe(this.$el)
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    async handleButtonClickedEvent(destination) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial1',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      })
    },
    async handleCardClickedEvent() {
      const subject = get(this.cmsComponent, 'data.metadata.artist');

      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.cardClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]: this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.depth]: 0,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.subject]: subject,
          [AMPLITUDE_EVENT_PROPERTIES.subjectType]: 'artist',
          [AMPLITUDE_EVENT_PROPERTIES.contractVersion]: this.cmsComponent.data.version
        }
      })
    }
  }
}
</script>
