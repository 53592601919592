export function isCreatorContract(editionOrToken) {
  if (editionOrToken && editionOrToken.version) {
    return editionOrToken.version === '4';
  } else if (editionOrToken && editionOrToken.id) {
    return editionOrToken.id.includes('-')
  } else {
    return false
  }
}

export function splitCreatorEditionId(id) {
  const split = id.split('-')
  return {
    contract: split[0],
    editionId: split[1]
  }
}
