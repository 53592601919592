var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "section",
      [
        _c(
          "b-field",
          {
            attrs: {
              label: _vm.requiredFieldsList.includes("streetAddress1")
                ? "Address Line 1 *"
                : "Address Line 1",
              type: {
                "is-danger":
                  _vm.errors.streetAddress1 && _vm.isDirty.streetAddress1,
                "is-success":
                  _vm.streetAddress1 &&
                  _vm.streetAddress1.length > 0 &&
                  !_vm.errors.streetAddress1,
              },
              message: _vm.invalidStreetAddress1,
            },
          },
          [
            _c("b-input", {
              attrs: { type: "text", "data-cy": "address1_input", max: "100" },
              on: { input: _vm.updateFields },
              model: {
                value: _vm.streetAddress1,
                callback: function ($$v) {
                  _vm.streetAddress1 = $$v
                },
                expression: "streetAddress1",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "mt-6",
            attrs: {
              label: "Address Line 2",
              type: {
                "is-danger":
                  _vm.errors.streetAddress2 && _vm.isDirty.streetAddress2,
                "is-success":
                  _vm.streetAddress2 &&
                  _vm.streetAddress2.length > 0 &&
                  !_vm.errors.streetAddress2,
              },
            },
          },
          [
            _c("b-input", {
              attrs: { type: "text", "data-cy": "address2_input", max: "100" },
              on: { input: _vm.updateFields },
              model: {
                value: _vm.streetAddress2,
                callback: function ($$v) {
                  _vm.streetAddress2 = $$v
                },
                expression: "streetAddress2",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "mt-6",
            attrs: {
              label: _vm.requiredFieldsList.includes("city")
                ? "City *"
                : "City",
              type: {
                "is-danger": _vm.errors.city && _vm.isDirty.city,
                "is-success":
                  _vm.city && _vm.city.length > 0 && !_vm.errors.city,
              },
              message: _vm.invalidCity,
            },
          },
          [
            _c("b-input", {
              attrs: { type: "text", "data-cy": "city_input", max: "50" },
              on: { input: _vm.updateFields },
              model: {
                value: _vm.city,
                callback: function ($$v) {
                  _vm.city = $$v
                },
                expression: "city",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.country === "US" || _vm.country === "CA"
          ? _c(
              "b-field",
              {
                staticClass: "mt-6",
                attrs: {
                  label: _vm.requiredFieldsList.includes("state")
                    ? "State *"
                    : "State",
                  type: {
                    "is-danger": _vm.errors.state && _vm.isDirty.state,
                    "is-success":
                      _vm.state && _vm.state.length > 0 && !_vm.errors.state,
                  },
                  message: _vm.invalidState,
                },
              },
              [
                _c("region-select", {
                  staticClass: "input",
                  attrs: { country: _vm.country, "data-cy": "state_input" },
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "mt-6",
            attrs: {
              label: _vm.requiredFieldsList.includes("postal")
                ? `${_vm.postcodeName} *`
                : _vm.postcodeName,
              type: {
                "is-danger": _vm.errors.postal && _vm.isDirty.postal,
                "is-success":
                  _vm.postal && _vm.postal.length > 0 && !_vm.errors.postal,
              },
              message: _vm.invalidPostal,
            },
          },
          [
            _c("b-input", {
              attrs: {
                type: "text",
                "data-cy": "postal_input",
                min: "2",
                max: "20",
              },
              on: { input: _vm.updateFields },
              model: {
                value: _vm.postal,
                callback: function ($$v) {
                  _vm.postal = $$v
                },
                expression: "postal",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }