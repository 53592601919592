<template>
    <div class="artworksCard">
        <div class="asset-card-wrapped-link hand-pointer">
          <n-link :to="{name:'tokens-id', params: { id: token.id }}">
            <image-asset :edition-number="token.editionNumber"
                         :metadata="token.metadata"
                         :resolution="resolution"
                         :cover="cover">
            </image-asset>
          </n-link>
          <div class="px-5">
            <p class="artworksCard__title has-text-weight-bold is-family-tertiary py-3">{{ token.metadata.name }}</p>
<user-profile-lookup :artist-account="token.artistAccount" :size="size" :no-subtitle="true" :is-table="true" class="artworksCard__account is-align-items-center"></user-profile-lookup>
          </div>
          <div class="artworksCard__dataHolder mt-4 py-4">
            <div v-for="(token, i) in tokenData" :key="token + i">
              <div class="is-flex is-align-items-center is-justify-content-space-between pb-1 px-5">
                <p class="artworksCard__label is-size-7 has-text-weight-medium is-family-tertiary">{{ token.label }}</p>
                <eth-with-fiat-price v-if="token.valueETH" :price-in-eth="token.valueETH"></eth-with-fiat-price>
                <p v-else>{{ token.value }}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import ImageAsset from './asset-renders/ImageAsset';
import UserProfileLookup from './UserProfileLookup.vue';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
    components: {
        ImageAsset,
    UserProfileLookup,
    EthWithFiatPrice
  },
  props: [
    'token',
    'cover',
    'resolution',
    'size',
    'address',
    'tokenData'
    ]
}
</script>
<style lang="scss">
.artworksCard {
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__dataHolder {
    border-top: 1px solid #EDEDED;
  }

  &__label {
    opacity: 0.5;
    text-transform: uppercase;
  }

  &__account {
    .media-left {
      margin-right: 0.5rem !important;
    }
  }
}
</style>
