import { render, staticRenderFns } from "./FooterRightAssetCard.vue?vue&type=template&id=70c6e816&scoped=true&"
import script from "./FooterRightAssetCard.vue?vue&type=script&lang=js&"
export * from "./FooterRightAssetCard.vue?vue&type=script&lang=js&"
import style0 from "./FooterRightAssetCard.vue?vue&type=style&index=0&id=70c6e816&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c6e816",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70c6e816')) {
      api.createRecord('70c6e816', component.options)
    } else {
      api.reload('70c6e816', component.options)
    }
    module.hot.accept("./FooterRightAssetCard.vue?vue&type=template&id=70c6e816&scoped=true&", function () {
      api.rerender('70c6e816', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/asset-card/FooterRightAssetCard.vue"
export default component.exports