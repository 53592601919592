var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toggle-favorite" },
    [
      _vm.liked
        ? _c("heart-icon", {
            staticClass: "toggle-favorite__icon",
            class: _vm.iconClasses,
            nativeOn: {
              animationend: function ($event) {
                return _vm.onIconAnimationEnds.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "favorite-particles-transition" } }, [
        _vm.animating
          ? _c("div", { staticClass: "toggle-favorite__particles" })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }