<template>
  <client-only>
    <section v-if="!isLargeTimeDifference">
    <vue-countdown v-if="!hidden"
                   :time="diff"
                   :transform="transform"
                   :emit-events="true"
                   @progress="handleCountdownProgress">
      <template slot-scope="props">
        <section :class="{'has-text-white': whiteText === true}">
          <span v-if="props.days > 0">
             {{ props.days }}d&nbsp;&nbsp;{{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m
          </span>
          <span v-else-if="props.hours > 0">
             {{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m&nbsp;&nbsp;{{ props.seconds }}s
          </span>
          <span v-else-if="props.minutes > 0">
             {{ props.hours }}h&nbsp;&nbsp;{{ props.minutes }}m&nbsp;&nbsp;{{ props.seconds }}s
          </span>
        </section>
      </template>
    </vue-countdown>
  </section>
  <section v-else>
    <section :class="{'has-text-white': whiteText === true}">
          <span>
             More than a year
          </span>
    </section>
  </section>
  </client-only>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import * as moment from 'moment';

  export default {
    components: {VueCountdown},
    props: ['startDate', 'whiteText'],
    data() {
      return {
        hidden: false
      };
    },
    computed: {
      diff() {
        // stored in UTC, ensure local is also UTC'd
        const startDate = moment.unix(this.startDate).utc();
        const now = moment().local().utc();
        return startDate.diff(now, 'milliseconds'); ;
      },
      lessThanAnHour() {
        // stored in UTC, ensure local is also UTC'd
        const startDate = moment.unix(this.startDate).utc();
        const now = moment().local().utc();
        return startDate.diff(now, 'hours') < 24;
      },
      isLargeTimeDifference() {
        return (((new Date(this.startDate * 1000)).valueOf() - Date.now()) > 31556926000)
      }
    },
    mounted() {
      const ONE_SECOND = 1000; // millis
      const TIMEOUT = 20000; // millis

      // In an attempt to catch browsers "Waking" up
      let lastTime = (new Date()).getTime();
      this.interval = setInterval(function () {
        const currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + TIMEOUT + 2000)) {
          console.log('tab woken up', this.diff);
          if (this.diff <= ONE_SECOND) {
            this.clockFinished();
          }
        }
        lastTime = currentTime;
      }, TIMEOUT);
    },
    destroyed() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    methods: {
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;

          // // uses singular form when the value is less than 2
          // const word = value < 2 ? key.replace(/s$/, '') : key;

          props[key] = `${digits}`;
        });
        return props;
      },
      handleCountdownProgress(data) {
        if (data.totalSeconds <= 0) {
          console.log('trigger sale');
          this.clockFinished();
        }
      },
      clockFinished() {
        console.log('timer called');

        this.hidden = true;

        // update UI
        this.$emit('countdown-complete');
      }
    }
  };
</script>
