<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Place a bid
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="secondary-content">
      <section>
        <b-field label="Enter your bid"
                 :message="`Min. bid is Ξ ${minBidInEthCalculated}. Must increase in increments of Ξ ${minBidAmount}.`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   :min="minBidAmount"
                   v-model="form.offerAmount"
                   type="number"
                   :disabled="transactionHash"
                   :step="minBidAmount">
          </b-input>
        </b-field>

        <p v-if="shouldShowHighestOffer" class="has-text-weight-semibold">
          Current highest bid
          <eth-with-fiat-price
            size="is-medium"
            :show-fiat="false"
            :price-in-wei="existingOffer.weiValue">
          </eth-with-fiat-price>
        </p>

        <p v-if="shouldShowReservePrice" class="has-text-weight-semibold">
          Reserve price
          <eth-with-fiat-price
            size="is-medium"
            :show-fiat="false"
            :price-in-wei="edition.reservePrice">
          </eth-with-fiat-price>
        </p>

        <div>

          <div v-if="isLockableEdition(edition) && !isReserveAuctionSalesType(edition.salesType)" v-cloak>
            <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
              You will not be able to withdraw your bid for {{ lockoutPeriodForOffers }}.
            </b-message>
          </div>

          <div v-if="isReserveAuctionSalesType(edition.salesType)">

            <!-- No offers but offer which will start the auction - show start notification -->
            <div v-if="auctionWillStart" v-cloak>
              <b-message type="is-success" :closable="false" :duration="0" class="is-size-7">
                <strong>This bid will start the auction.</strong>
                <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                   class="is-linkable">Learn how 24 hour auctions work</a>
              </b-message>
            </div>

            <!-- Auction started and above highest offer -->
            <div v-else-if="reserveAuctionCountdownStarted && isOutBid" v-cloak>
              <b-message type="is-success" class="is-size-7" :closable="false" :duration="0">
                Reserve price met, auction will be extended if bid is made within the last 15 minutes.
                <a class="is-linkable">Learn how 24 hour auctions work</a>
              </b-message>
            </div>

            <!-- Bid entered is above highest but will not start countdown -->
            <div v-else-if="auctionWillNotStart" v-cloak>
              <b-message type="is-warning" class="is-size-7" :closable="false" :duration="0"
                         v-if="reserveAuctionCountdownStarted">
                You must outbid the highest bidder.
                <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                   class="is-linkable">Learn how 24 hour auctions work</a>
              </b-message>
              <b-message type="is-warning" class="is-size-7" :closable="false" :duration="0" v-else>
                <strong>This bid will not start the auction.</strong>
                You can withdraw this bid at anytime.
                <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                   class="is-linkable">Learn how 24 hour auctions work</a>
              </b-message>
            </div>

            <!-- Default message - show info links to docs -->
            <div v-else v-cloak>
              <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
                Bids placed during an auction cannot be withdrawn. By placing a bid you have indicated that you agree
                to the
                <a href="https://docs.knownorigin.io/en/articles/7128351-terms-of-service" class="is-linkable">
                  terms of service
                </a>.
                <a href="https://docs.knownorigin.io/en/articles/6124916-what-are-the-different-auction-types" target="_blank"
                   class="is-linkable">Learn how 24 hour auctions work</a>
              </b-message>
            </div>

          </div>
        </div>

      </section>
    </template>

    <template slot="action-feedback-label">
      Bid submitted
    </template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="disableOfferButton"
                @click="makeOffer">
        Submit bid
      </b-button>
    </template>

    <template slot="action-button-nevermind">
      <b-button expanded @click="close(false)">
        Nevermind
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {mapState} from 'vuex';

import _get from 'lodash/get';
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth, lockoutPeriodForOffers} from '../../../services/utils';
import {
  isReserveAuctionSalesType,
  isOffersOnlySalesType,
  isLockableEdition
} from '../../../services/SaleTypes';

import ReserveAuctionUtils from '../../../services/ReserveAuctionUtils';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    ArtworkNameAndArtist,
    EthWithFiatPrice,
    ModalTemplate
  },
  props: ['edition', 'existingOffer', 'minBid', 'algoliaInfo'],
  data() {
    return {
      lockoutPeriodForOffers,
      minBidAmount: listOrOfferAmountFloorInEth,
      transactionHash: null,
      form: {
        offerAmount: null
      }
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    auctionWillNotStart() {
      if (!this.form.offerAmount) {
        return false;
      }
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));

      const reservePrice = parseFloat(ethers.utils.formatEther(this.edition.reservePrice));

      if (hasOffer && currentOfferInEth >= reservePrice) {
        return parseFloat(this.form.offerAmount) < (currentOfferInEth + listOrOfferAmountFloorInEth);
      }

      return parseFloat(this.form.offerAmount) < reservePrice;
    },
    auctionWillStart() {
      return !this.reserveAuctionCountdownStarted && this.bidAboveCurrentReserve;
    },
    shouldShowHighestOffer() {
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;

      if (isReserveAuctionSalesType(this.edition.salesType) && hasOffer) {
        const reservePrice = parseFloat(ethers.utils.formatEther(this.edition.reservePrice));
        const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
        return currentOfferInEth >= reservePrice;
      }

      return hasOffer;
    },
    shouldShowReservePrice() {
      if (isReserveAuctionSalesType(this.edition.salesType)) {
        const hasOffer = this.existingOffer && this.existingOffer.ethValue;
        if (hasOffer) {
          const reservePrice = parseFloat(ethers.utils.formatEther(this.edition.reservePrice));
          const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
          return currentOfferInEth < reservePrice;
        }
        return true;
      }
      return false;
    },
    isOutBid() {
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      if (!hasOffer || !this.form.offerAmount) {
        return false;
      }
      const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
      return parseFloat(this.form.offerAmount) >= (currentOfferInEth + listOrOfferAmountFloorInEth);
    },
    disableOfferButton() {
      if (this.transactionHash) {
        return true;
      }

      if (this.form.offerAmount < this.minBidAmount) {
        return true;
      }

      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      if (hasOffer) {
        return parseFloat(this.form.offerAmount) < (parseFloat(this.existingOffer.ethValue) + parseFloat(this.minBidAmount)).toFixed(6);
      }

      const offerEntered = parseFloat(this.form.offerAmount || 0);

      if (isReserveAuctionSalesType(this.edition.salesType)) {
        const hasOffer = this.existingOffer && this.existingOffer.ethValue;
        if (hasOffer) {
          const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
          return offerEntered < currentOfferInEth;
        }
        return offerEntered === 0;
      }
      return offerEntered < parseFloat(this.minBidInEthCalculated);
    },
    minBidInEthCalculated() {
      const hasOffer = this.existingOffer && this.existingOffer.ethValue;
      const offchainReserve = parseFloat(ethers.utils.formatEther(_get(this.minBid, 'eth_reserve_in_we', 0)));
      const hasOffchainReservePrice = this.minBid && this.minBid.eth_reserve_in_wei && ethers.utils.formatEther(this.minBid.eth_reserve_in_wei) > 0;
      const onchainReserve = parseFloat(ethers.utils.formatEther(_get(this.edition, 'reservePrice', 0)));
      const hasOnchainReservePrice = this.edition.reservePrice && ethers.utils.formatEther(this.edition.reservePrice) && ethers.utils.formatEther(this.edition.reservePrice) > 0;
      const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));

      // Has an open offer and has defined a reserve in some form
      if (hasOffer && (hasOffchainReservePrice || hasOnchainReservePrice)) {
        // if we have an on-chain reserve and its great than current offer
        if (hasOnchainReservePrice && (currentOfferInEth >= onchainReserve)) {
          // return current offer + minimum bib
          return parseFloat(currentOfferInEth + this.minBidAmount).toFixed(2);
        }

        // if we have an off-chain reserve and its great than current offer
        if (hasOffchainReservePrice && (currentOfferInEth >= offchainReserve)) {
          // return current offer + minimum bib
          return parseFloat(currentOfferInEth + this.minBidAmount).toFixed(2);
        }
      }

      if (hasOnchainReservePrice) {
        return onchainReserve;
      }

      if (hasOffchainReservePrice) {
        return ethers.utils.formatEther(this.minBid.eth_reserve_in_wei);
      }

      return this.minBidAmount;
    },
    bidAboveCurrentReserve() {
      const offerToPlace = parseFloat(this.form.offerAmount || 0);
      const reservePrice = parseFloat(ethers.utils.formatEther(this.edition.reservePrice));

      if (this.reserveAuctionCountdownInProgress) {
        const currentOfferInEth = parseFloat(_get(this.existingOffer, 'ethValue', 0));
        return Math.floor(offerToPlace.toFixed(2)) > Math.floor(currentOfferInEth.toFixed(2));
      }

      return offerToPlace >= reservePrice;
    },
    reserveAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.edition);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    }
  },
  methods: {
    isLockableEdition,
    close(complete) {
      this.$emit('close', {purchaseComplete: complete});
    },
    async makeOffer() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/makeEditionOffer', {
          edition: this.edition.id,
          offerAmount: ethers.utils.parseEther(this.form.offerAmount).toString(),
          salesType: this.edition.salesType,
          version: this.edition.version
        });
        this.transactionHash = tx.hash;

        await tx.wait(1);

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.offerCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.offerCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.form.offerAmount,
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.edition.salesType) ? 'reserve auction' : 'bids only',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.edition.startDate,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.form.offerAmount),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.edition.salesType) ? 'reserve auction' : 'bids only',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary'
            }
          }
        });
        await this.$store.dispatch('userStore/getContractAddress', {
          chainId: this.chainId,
          version: this.edition.version
        }).then((contractAddress) => {
          if (contractAddress) {
            this.$gtm.push({
              event: 'Offer',
            value: this.form.offerAmount,
            editiontitle: this.edition.metadata.name,
              tokenid: this.edition.id,
              artist: this.edition.artistAccount,
              purchasetype: 'BuywithEth',
              marketplace: 'Primary',
              chain: this.chainId,
              contracttype: this.edition.version,
              contractaddress: contractAddress
            })
          }
        })
        this.close(true);
      } catch (e) {
        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.offerFailed,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.edition.id,
            [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.offerFailed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'primary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: isReserveAuctionSalesType(this.edition.salesType) ? 'reserve auctions' : 'bids only'
          }
        });

        console.error(e);
      }
    },
    isReserveAuctionSalesType,
    isOffersOnlySalesType
  }
};
</script>
