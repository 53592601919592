export const state = () => ({});

export const mutations = {};

export const actions = {

  async getProfileCollections({rootState, state, commit}, {owner, includePrivate, collectionType}) {
    console.log('loading profile collections for', {owner, includePrivate, collectionType});
    const {collections, allItems, hiddenItems} = await this.$api.$get(`/network/${rootState.web3Store.chainId}/collections/account/${owner}?includePrivate=${includePrivate}&collectionType=${collectionType}`);
    return {collections, allItems, hiddenItems};
  },

  async getPrivateEditions({rootState, state, commit}, {address}) {
    console.log(`finding tokens from private profile collections for ${address}`);
    return await this.$api.$get(`/network/${rootState.web3Store.chainId}/collections/account/${address.toLowerCase()}/private`);
  },

  async getProfileCollection({rootState, state, commit}, {address, collectionSlug}) {
    console.log(`loading profile collections for ${address} and ${collectionSlug}`);
    return await this.$api.$get(`/network/${rootState.web3Store.chainId}/collections/account/${address}/${collectionSlug}`);
  },

  async deleteProfileCollection({rootState, state, commit}, id) {
    console.log(`delete profile collections for ${id}`);
    await this.$api.$delete(`/network/${rootState.web3Store.chainId}/collections/${id}`);
    return true;
  },

  async pinProfileCollection({rootState, state, commit}, {id, pin}) {
    console.log(`pin profile collections for ${id} to ${pin}`);
    await this.$api.$put(`/network/${rootState.web3Store.chainId}/collections/${id}?pin=${pin}`);
    return true;
  },

  async getCollectionById({rootState, state, commit}, id) {
    console.log(`get collections for ${id}`);
    return await this.$api.$get(`/network/${rootState.web3Store.chainId}/collections/${id}`);
  },

  async addProfileCollection({rootState, commit, dispatch}, collection) {
    console.log('adding new profile collections');
    try {
      await dispatch('web3Store/createJwtSession', {}, {root: true});

      if (!rootState.web3Store.account || !rootState.web3Store.authToken || !rootState.web3Store.chainId) {
        return Promise.reject();
      }

      return await this.$api.$post(
        `/network/${rootState.web3Store.chainId}/collections`,
        collection,
        {
          crossDomain: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `bearer ${rootState.web3Store.authToken}`
          }
        });
    } catch (error) {
      console.error(error);
    }
    return Promise.reject();
  }
};
