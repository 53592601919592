var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.populatedToken
    ? _c("div", { staticClass: "columns level" }, [
        _c(
          "div",
          { staticClass: "column is-one-fifth level-left" },
          [
            _vm._t("left", function () {
              return [
                _c("span", { staticClass: "image is-64x64" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.populatedToken.logo
                        ? _vm.populatedToken.logo
                        : "/erc20.png",
                      alt: _vm.populatedToken.symbol,
                    },
                  }),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column level-item" },
          [
            _vm._t("middle", function () {
              return [
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.populatedToken.count) +
                      " " +
                      _vm._s(_vm.populatedToken.name) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "is-muted is-size-7",
                    attrs: {
                      href: _vm.etherscanAddressLink(
                        _vm.populatedToken.address
                      ),
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n        [view contract]\n      ")]
                ),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column level-right has-text-right" },
          [
            _vm._t("right", function () {
              return [
                _c("span", [
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.populatedToken.count) +
                        " " +
                        _vm._s(_vm.populatedToken.symbol) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.populatedToken.usdPrice
                    ? _c("span", { staticClass: "is-muted is-size-7" }, [
                        _vm._v(
                          "\n        / $" +
                            _vm._s(
                              _vm._f("to2Dp")(_vm.populatedToken.usdPrice)
                            ) +
                            "\n      "
                        ),
                      ])
                    : _c("span", { staticClass: "is-muted is-size-7" }, [
                        _vm._v("/ n/a"),
                      ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }