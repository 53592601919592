<template>
  <header class="header-image has-text-white has-background-black" v-lazy:background-image="imageUrl">
    <section class="hero">
      <div class="hero-body">
        <div class="container content">
          <h1 class="has-text-white has-margin-top-1">
            <b-icon icon="video" v-if="isVideo" size="is-medium" class="is-pulled-right"
                    custom-class="is-size-3"></b-icon>

            Discover<br />
            rare digital art<br />
            and collect NFTs
            <br />
          </h1>
          <div class="content">
            <div @click="handleClickEventTracking(true,'View marketplace', 'marketplace')" class="field is-grouped">
              <p class="control">
                <n-link :to="{ name: 'marketplace' }" class="button is-white is-outlined" @click.native="handleItemClickInsights('button','', 'header banner')">
                  View marketplace
                </n-link>
              </p>
            </div>
          </div>
          <div
            class="content"
            v-if="headerImage"
            @click="handleClickEventTracking(false,`gallery/${headerImage.id}`, 'gallery')">
            <n-link :to="{name:'gallery-id', params:{id: headerImage.id }}"
                    class="is-size-10 has-text-white has-padding-top-6"
                    @click.native="handleItemClickInsights('link', headerImage.id, 'header banner', 'token page')">
              <artwork-name-and-artist style="overflow-wrap: anywhere;"
                                       :metadata="headerImage.metadata"
                                       :edition-number="headerImage.id">
              </artwork-name-and-artist>
            </n-link>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import {mapState} from 'vuex';
import _sample from 'lodash/sample';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import {mimeTypeUtils, urlUtils} from '@knownorigin/ko-token-uri-tools';
import ArtworkNameAndArtist from '../ArtworkNameAndArtist';
import {HEADER_BANNER_EDITION} from '../../queries/editionQueries';
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  components: {
    ArtworkNameAndArtist
  },
  computed: {
    ...mapState('web3Store', [
      'gqlClient',
      'chainId'
    ]),
    ...mapState('userStore', [
      'artists'
    ])
  },
  data() {
    return {
      stats: null,
      duration: 2000,
      headerImage: null,
      editionId: '',
      image: '',
      imageUrl: '',
      isVideo: false
    };
  },
  async mounted() {
    try {
      this.$apollo.addSmartQuery('editions', {
        client: this.gqlClient,
        query: HEADER_BANNER_EDITION,
        fetchPolicy: 'network-only',
        variables() {
          return {
            id: this.editionId
          };
        },
        update({editions}) {
          this.headerImage = _sample(_filter(editions, ({metadata}) => {
            return metadata && !_includes(metadata.image_type, 'svg') && !_includes(metadata.image_type, 'glb');
          }));

          if (this.headerImage && this.headerImage.metadata) {
            this.isVideo = mimeTypeUtils.isVideoType(this.headerImage.metadata.image_type) || urlUtils.isSupportedVideo(this.headerImage.metadata.image);
            this.setImage(this.isVideo);
          }
        },
        error(error, vm, key, type, options) {
          console.log('error', error);
        }
      });

      this.loadFavourites();
    } catch (e) {
      console.log('Problem with API', e);
    }
  },
  methods: {
    setImage(useCard = false) {
      this.imageUrl = `${this.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${this.editionId}${useCard ? '/cover' : ''}`;
    },
    loadFavourites() {
      return this.$api.$get(`/network/${this.chainId}/likes/address/0x2049d51AeFBaF0175ffA5c1a00362991EBa6A518?limit=100&offset=0`)
        .then(({likes}) => {
          this.editionId = likes && likes.length > 0 ? _sample(likes).edition : '190075';
          console.log('picked Edition for header', this.editionId);
        });
    },
    handleClickEventTracking(button, value, destination) {
      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: button ? EVENT_NAMES.buttonClicked : EVENT_NAMES.linkClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination,
          [AMPLITUDE_EVENT_PROPERTIES.value]: value,
          [AMPLITUDE_EVENT_PROPERTIES.promoSection]: 'header banner'
        }
      })
    },
    handleItemClickInsights(type, item, promoSection, destination) {
      if (type === 'button') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.buttonClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.id]: item,
            [AMPLITUDE_EVENT_PROPERTIES.promoSection]: promoSection,
            [AMPLITUDE_EVENT_PROPERTIES.destination]: destination
          }
        });
      } else if (type === 'link') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.linkClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
            [AMPLITUDE_EVENT_PROPERTIES.id]: item,
            [AMPLITUDE_EVENT_PROPERTIES.destination]: destination
          }
        });
      }
    }
  }
};
</script>
