import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import portisModule from '@web3-onboard/portis';
import torusModule from '@web3-onboard/torus';
import walletConnectModule from '@web3-onboard/walletconnect';
import coinbaseWalletModule from '@web3-onboard/coinbase';

const INFURA_ID = 'f081a32b30bd439891818968bda7e4f6';

const injected = injectedModule();
const torus = torusModule({
  apiKey: process.env.DEFAULT_CHAIN_ID === '5'
    ? 'BFADRnStEBIsq4QKrsRo11MjrvaEmEn2GZlQhXkQYE86ItsIq-yH5DPE74DtBs0StWiC-i2UghpBoTRrdULxYEk'
    : 'BMUV_-u6WNBN9qs85j7qffg0xKZMTIUrRK2T-1nj_Y5K-XIabFawg_ALjv4rWDOc1mFab8hSPURCewB6X6o65tA'
});
const portis = portisModule({
  apiKey: '3980ff61-9d97-4cf2-85d1-31f00aa92af5'
});
const walletConnect = walletConnectModule({
  qrcodeModalOptions: {
    mobileLinks: ['rainbow', 'metamask', 'trust'],
  },
  projectId: 'd137ef693d2c5e40cc396f140b5ad89c'
});
const coinbase = coinbaseWalletModule({
  darkMode: true
});

export const onboard = Onboard({
  wallets: [
    injected,
    portis,
    torus,
    walletConnect,
    coinbase
  ],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`
    },
    {
      id: '0x5',
      token: 'goETH',
      label: 'Ethereum Goerli Testnet',
      rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
    }
  ],
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false
    }
  },
  appMetadata: {
    // app name
    name: 'KnownOrigin',
    // SVG icon string, with height or width (whichever is larger) set to 100% or a valid image URL
    // note: if using an emoji make sure to send base64 string
    icon: '/branding/KO-Icon.svg',
    // Optional wide format logo (ie icon and text) to be displayed in the sidebar of connect modal. Defaults to icon if not provided
    logo: '/branding/KO-Icon.svg',
    // description of app
    description: 'KnownOrigin.io - An artist-driven NFT Digital Art Marketplace',
    // url to a getting started guide for app
    gettingStartedGuide: 'https://docs.knownorigin.io/en/',
    // url that points to more information about app
    explore: 'https://knownorigin.io/marketplace',
    // if your app only supports injected wallets and when no injected wallets detected, recommend the user to install some
    recommendedInjectedWallets: [
      {name: 'MetaMask', url: 'https://metamask.io'},
      {name: 'Trust Wallet', url: 'https://trustwallet.com'},
      {name: 'Coinbase Wallet', url: 'https://wallet.coinbase.com'}
    ],
    agreement: {
      version: '21.04.23',
      termsUrl: 'https://docs.knownorigin.io/en/articles/7128351-terms-of-service',
      privacyUrl: 'https://docs.knownorigin.io/en/articles/7128342-privacy-policy'
    }
  }
});
