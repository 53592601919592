var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "artCarousel" }, [
    _c("div", { staticClass: "section container" }, [
      _c("div", { staticClass: "tile is-child" }, [
        _c(
          "div",
          { staticClass: "columns content is-flex is-vcentered is-mobile" },
          [
            _c(
              "div",
              {
                staticClass:
                  "column is-half-mobile is-two-thirds-tablet is-flex is-align-items-center",
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "artCarousel__title title is-size-3-desktop is-size-5-mobile mr-5",
                  },
                  [_vm._v(_vm._s(_vm.cmsComponent.title))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "artCarousel__btn is-hidden-mobile",
                    on: { click: _vm.slidePrev },
                  },
                  [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "artCarousel__btn is-hidden-mobile",
                    on: { click: _vm.slideNext },
                  },
                  [_c("b-icon", { attrs: { icon: "arrow-right" } })],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "column is-half-mobile is-one-third-tablet has-text-right",
                on: {
                  click: function ($event) {
                    return _vm.handleViewMoreClick(
                      _vm.cmsComponent.viewMoreLink
                    )
                  },
                },
              },
              [
                _c(
                  "NuxtLink",
                  {
                    staticClass:
                      "is-outlined is-size-6-mobile component-view-more-link",
                    attrs: { to: _vm.cmsComponent.viewMoreLink },
                  },
                  [
                    _c("b-button", { attrs: { type: "is-secondary" } }, [
                      _vm._v(_vm._s(_vm.cmsComponent.viewMoreLabel)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "artCarousel__sliderHolder mt-3" }, [
        _vm.componentData
          ? _c(
              "div",
              {
                staticClass: "artCarousel__slider",
                class: { "artCarousel__slider--loaded": _vm.sliderLoaded },
              },
              _vm._l(
                Object.entries(_vm.componentData),
                function ([key, edition]) {
                  return _c(
                    "div",
                    {
                      key: edition.timestamp + key,
                      staticClass: "artCarousel__slide",
                    },
                    [
                      _vm.cmsComponent.cardHeader
                        ? _c("div", { staticClass: "level is-mobile mb-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "level-left has-text-weight-bold is-family-tertiary",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.mapEvent(edition.eventType)) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "level-right has-text-weight-medium is-family-tertiary is-size-6",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "is-uppercase" },
                                  [
                                    _c("date-from", {
                                      attrs: {
                                        "timestamp-in-secs": edition.timestamp,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "artCarousel__card" },
                        [
                          _vm.checkIsToken(edition)
                            ? _c(
                                "token-card",
                                {
                                  attrs: {
                                    "route-edition": true,
                                    token: edition.token
                                      ? edition.token
                                      : edition,
                                    cover: true,
                                    resolution: "card",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleCardClickedEvent(edition)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "asset-card-footer-left-title" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "has-text-left" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.mapActivityEventTitle(
                                                  edition.eventType
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    { slot: "asset-card-footer-left" },
                                    [
                                      edition.eventValueInWei
                                        ? _c("eth-with-fiat-price", {
                                            staticClass:
                                              "has-text-weight-semibold has-text-left",
                                            attrs: {
                                              "price-in-wei":
                                                edition.eventValueInWei,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              )
                            : _c("edition-card", {
                                staticClass: "mb-0",
                                attrs: {
                                  edition: edition.edition
                                    ? edition.edition
                                    : edition,
                                  "disable-icons": true,
                                  cover: true,
                                  resolution: "card",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleCardClickedEvent(edition)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.sliderLoaded
          ? _c(
              "div",
              {
                staticClass: "carouselSkeleton",
                style: {
                  gridTemplateColumns: `repeat(${_vm.slidesToChange}, 1fr)`,
                },
              },
              _vm._l(_vm.slidesToChange, function (el) {
                return _c(
                  "div",
                  { key: el, staticClass: "carouselSkeleton__item" },
                  [_c("EditionCardSkeleton")],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }