<script>
  import {Radar} from 'vue-chartjs'
  import _map from 'lodash/map'
  import _sortBy from 'lodash/sortBy'

  export default {
    extends: Radar,
    props: ['breakdown'],
    data() {
      return {
        options: {
          responsive: false,
          maintainAspectRatio: true,
          scale: {
            angleLines: {
              display: false
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 50
            }
          }
        }
      }
    },
    mounted() {
      /* eslint-disable arrow-parens */
      const sort = (data) => _sortBy(_map(data, (value, key) => ({value, key})), ['key']);

      const chartData = {
        labels: _map(sort(this.breakdown.eventScoreCounts), 'key'),
        datasets: [
          {
            label: '% score allocations',
            borderWidth: 1,
            backgroundColor: 'rgba(68,239,84,0.5)',
            data: sort(this.breakdown.eventScoreCounts).map(({value}) => {
              return ((value / this.breakdown.totalScores) * 100).toFixed(2);
            })
          },
          {
            label: '% event triggers',
            borderWidth: 1,
            backgroundColor: 'rgba(81,101,255,0.5)',
            data: sort(this.breakdown.eventCounts).map(({value}) => {
              return ((value / this.breakdown.totalEvents) * 100).toFixed(2);
            })
          }
        ]
      };

      this.renderChart(chartData, this.options)
    }
  }
</script>
