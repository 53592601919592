<template>
  <client-only>
    <div class="card" v-if="workingMetadata && edition" :class="{ccArtwork: isCc}">

      <image-for-asset-card :edition="edition" :cover="cover" :resolution="resolution" :metadata="workingMetadata"
                            :disable-icons="!!disableIcons" :is-cc="isCc">
      </image-for-asset-card>

      <slot name="artwork-info-asset-card">
        <artwork-info :edition="edition"
                      :size="size"
                      @editions-refresh="editionsRefresh"
                      :phase="gatedSalePhase"
        >
        </artwork-info>
      </slot>

      <footer class="card-footer is-clipped min-height-90" :class="{borderGrey: isCc}">
        <slot name="footer-left-asset-card">
          <footer-left-asset-card :edition="edition" :phase="gatedSalePhase">
          </footer-left-asset-card>
        </slot>
        <slot name="footer-right-asset-card">
          <footer-right-asset-card :edition="edition" :phase="gatedSalePhase">
          </footer-right-asset-card>
        </slot>
      </footer>
    </div>
  </client-only>
</template>

<script>
import {mapState} from 'vuex';
import {getPhaseState} from '../services/SaleTypes';
import {isCreatorContract} from '../services/CreatorContractUtils';
import {doesMetadataExist} from '../services/editionUtils';
import ImageForAssetCard from './asset-card/ImageForAssetCard';
import ArtworkInfo from './asset-card/ArtworkInfo';
import FooterLeftAssetCard from './asset-card/FooterLeftAssetCard';
import FooterRightAssetCard from './asset-card/FooterRightAssetCard';

export default {
  components: {
    ImageForAssetCard,
    ArtworkInfo,
    FooterLeftAssetCard,
    FooterRightAssetCard
  },
  props: [
    'edition',
    'cover',
    'resolution',
    'size',
    'disableIcons'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      gatedSalePhase: null
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    workingMetadata() {
      if (doesMetadataExist(this.edition)) {
        return this.edition.metadata;
      } else {
        return this.metadataFromKoCache;
      }
    },
    isGatedSale() {
      return this.edition.gatedSale && this.edition.gatedSale.phases.length > 0
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  },
  async mounted() {
    await this.loadMetaData()
    if (this.isGatedSale) {
      this.gatedSalePhase = this.edition.gatedSale.phases[0]
      this.gatedSalePhase.state = getPhaseState(this.edition.gatedSale.phases[0])
    }
  },
  methods: {
    editionsRefresh() {
      this.$emit('editions-refresh');
    },
    async loadMetaData() {
      if (!doesMetadataExist(this.edition)) {
        const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.edition.id}`);
        if (metadata) {
          this.metadataFromKoCache = metadata;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";
.borderGrey {
  border-top-color:#3A3A3A;
}
.min-height-90 {
  min-height: 90px;
}
</style>
