var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.valueInEth === 0
      ? _c("span", [
          _c(
            "span",
            {
              class: {
                "is-family-tertiary": true,
                "subtitle is-2": _vm.size === "is-large",
                "is-size-7": _vm.size === "is-medium",
                "is-size-7": _vm.size === "is-small",
                "has-text-weight-semibold": _vm.strong,
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "has-text-danger": _vm.type === "is-danger",
                    "has-text-grey": _vm.type === "is-muted",
                  },
                },
                [_vm._v("Ξ 0")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFiat && _vm.usdPrice
            ? _c(
                "span",
                { staticClass: "is-muted is-small is-family-tertiary" },
                [_vm._v("\n      / " + _vm._s(_vm.convertUsdPrice) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showHistoricalFiat && _vm.historicalUsdPrice
            ? _c("span", { staticClass: "is-muted is-small" }, [
                _vm._v(
                  "\n      / " +
                    _vm._s(_vm.convertHistoricalUsdPrice) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ])
      : _vm.valueInEth
      ? _c("span", [
          _c(
            "span",
            {
              class: {
                "is-family-tertiary": true,
                "subtitle is-2": _vm.size === "is-large",
                "is-size-7": _vm.size === "is-medium",
                "is-size-7": _vm.size === "is-small",
                "has-text-weight-bold": _vm.strong,
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "has-text-danger": _vm.type === "is-danger",
                    "has-text-grey": _vm.type === "is-muted",
                  },
                },
                [_vm._v("Ξ " + _vm._s(_vm.valueInEth))]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFiat && _vm.usdPrice
            ? _c(
                "span",
                { staticClass: "is-muted is-small is-family-tertiary" },
                [_vm._v("\n      / " + _vm._s(_vm.convertUsdPrice) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showHistoricalFiat && _vm.historicalUsdPrice
            ? _c("span", { staticClass: "is-muted is-small" }, [
                _vm._v(
                  "\n      / " +
                    _vm._s(_vm.convertHistoricalUsdPrice) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }