<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">

      <div class="card-content has-padding-7">

        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <h2 class="mb-0">
              Add a donation badge to your profile
            </h2>
            <p class="title">
              Paste in the <strong>etherscan link</strong> from a donation you have made.
            </p>
            <section class="mt-2">
              <b-input v-model="form.transactionLink"
                       type="text"
                       placeholder="http://etherscan.io/tx/0x0123...."
                       expanded
                       autofocus>
              </b-input>
            </section>

            <p class="mt-2">
              Donations must come from your registered Ethereum address, sent to a listed KO charity, and be
              at least 0.01 ETH.
            </p>
            <p class="">
              Once confirmed you will see a new
              <DonationBadge></DonationBadge>
              badge on your profile.
            </p>

            <p class="has-text-danger mt-2" v-if="response && !response.success && response.message">
              {{ response.message }}
            </p>
            <p class="mt-2 has-text-success" v-if="response && response.success">
              Donation Registered
              <b-icon icon="check-circle-outline" size="is-small"></b-icon>
            </p>
          </section>

        </div>

        <div class="card-footer">

          <div class="card-footer-item">
            <b-button expanded
                      @click="close">
              Nevermind
            </b-button>
          </div>

          <div class="card-footer-item">
            <b-button class="button is-primary"
                      expanded
                      @click="claimBadge"
                      :loading="loading"
                      :disabled="!form.transactionLink">
              Claim
            </b-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import DonationBadge from '../badges/DonationBadge';

export default {
  components: {DonationBadge},
  data() {
    return {
      form: {
        transactionLink: null
      },
      loading: false,
      response: {}
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'etherscanTxLink',
      'etherscanAddressLink'
    ])
  },
  methods: {
    close() {
      this.$emit('close');
    },
    selectCharity(charity) {
      this.form.selectedCharity = charity;
    },
    async claimBadge() {
      try {
        this.loading = true;
        this.response = await this.$store.dispatch('userStore/claimDonationBadge', {transactionLink: this.form.transactionLink});

        if (this.response && this.response.success) {
          // close the modal after showing the success message for 3 seconds
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
