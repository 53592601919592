<template>
  <client-only>
    <div v-if="resolution === 'thumbnailSearch'" v-lazy-container="{ selector: 'img' }">
          <img class="rounded-edge-image"
            src="/branding/bigloader-image.svg"
            :data-src="assetForResolution"
            data-loading="/branding/bigloader-image.svg"
            data-error="/branding/holding_image.jpg"
            :id="`edition_image_${editionNumber}`"
            :class="{'card-image-cover': cover}"
            :alt="`${workingMetadata && workingMetadata.name} by ${workingMetadata && workingMetadata.artist}`"
          />

        </div>
    <section v-else>
      <figure v-if="isWebM || isMp4">
        <client-only>
          <video-js-player
            :cover="cover"
            :full-asset="fullAsset"
            :card-asset="cardAsset"
            :metadata="workingMetadata"
            :edition-number="editionNumber"
            :video-mime-type="videoMimeType">
          </video-js-player>
        </client-only>
      </figure>
      <figure v-else-if="isGlb">
        <interactive-player
          :id="`edition_image_${editionNumber}`"
          :cover="cover"
          :full-asset="fullAsset"
          :card-asset="cardAsset"
          :metadata="workingMetadata"
          :edition-number="editionNumber"
          :class="{'card-image-cover': cover}">
        </interactive-player>
      </figure>
      <figure v-else-if="is360Viewer">
        <div class="mb-1">
        <image-sphere-viewer
          :id="`edition_image_${editionNumber}`"
          :cover="cover"
          :full-asset="fullAsset"
          :card-asset="cardAsset"
          :metadata="workingMetadata"
          :edition-number="editionNumber"
          :is-cc="isCc"
          :class="{
          'card-image-cover': cover,
          'hand-pointer': cover,
        }">
        </image-sphere-viewer>
        </div>
      </figure>
      <figure v-else>
        <div v-lazy-container="{ selector: 'img' }">
          <img
            src="/branding/bigloader-image.svg"
            :data-src="assetForResolution"
            data-loading="/branding/bigloader-image.svg"
            data-error="/branding/holding_image.jpg"
            :id="`edition_image_${editionNumber}`"
            :class="{'card-image-cover': cover}"
            :alt="`${workingMetadata && workingMetadata.name} by ${workingMetadata && workingMetadata.artist}`"
          />
        </div>
      </figure>
      <span class="is-clickable"
            v-if="enableMagnify && supportsFullscreen"
            @click="toggleFullscreen">
        <b-icon icon="magnify"></b-icon>
      </span>
    </section>
  </client-only>
</template>

<script>
import {mimeTypeUtils, urlUtils} from '@knownorigin/ko-token-uri-tools';
import {mapState} from 'vuex';
import InteractivePlayer from './InteractivePlayer';
import ImageSphereViewer from './ImageSphereViewer';
import VideoJsPlayer from './VideoJsPlayer';

// props
// cover if true will crop the image and cover it (showing the zoomed in centre generally)
// resolution can be "thumbnail", "default", "card", or "full"

export default {
  components: {VideoJsPlayer, ImageSphereViewer, InteractivePlayer},
  props: ['metadata', 'editionNumber', 'cover', 'resolution', 'enableMagnify', 'isCc'],
  data() {
    return {
      metadataFromKoCache: null
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    isWebM() {
      if (this.workingMetadata) {
        return mimeTypeUtils.isWebM(this.workingMetadata.image_type) || urlUtils.isWebM(this.workingMetadata.image);
      }
      return false;
    },
    isMp4() {
      if (this.workingMetadata) {
        return mimeTypeUtils.isMp4(this.workingMetadata.image_type) || urlUtils.isMp4(this.workingMetadata.image);
      }
      return false;
    },
    isGlb() {
      if (this.workingMetadata) {
        return urlUtils.isSupported3DImage(this.workingMetadata.animation_url) ||
          mimeTypeUtils.is3D(this.workingMetadata.image_type) ||
          (this.workingMetadata.animation_url || '').endsWith('.gltf');
      }
      return false;
    },
    is360Viewer() {
      if (this.workingMetadata) {
        return this.workingMetadata.image_sphere === true;
      }
      return false;
    },
    isImage() {
      if (this.workingMetadata) {
        return urlUtils.isSupportedImage(this.workingMetadata.image);
      }
      return false;
    },
    isVideo() {
      if (this.workingMetadata) {
        return urlUtils.isSupportedVideo(this.workingMetadata.animation_url);
      }
      return false;
    },
    videoMimeType() {
      return this.isWebM ? 'video/webm' : 'video/mp4';
    },
    thumbnailAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/thumbnail`;
    },
    fullAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/full`;
    },
    cardAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/card`;
    },
    coverAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/cover`;
    },
    defaultAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}`;
    },
    assetForResolution() {
      if (this.resolution === 'full') {
        return this.fullAsset;
      } else if (this.resolution === 'card') {
        return this.cardAsset;
      } else if (this.resolution === 'thumbnail' || this.resolution === 'thumbnailSearch') {
        return this.thumbnailAsset;
      }
      return this.defaultAsset;
    },
    workingMetadata() {
      if (this.metadata) {
        return this.metadata;
      }
      return this.metadataFromKoCache;
    },
    supportsFullscreen() {
      return process.client && !this.isVideo && (window.document && window.document.fullscreenEnabled);
    }
  },
  async mounted() {
    if (!this.metadata) {
      await this.loadMetaData();
    }
  },
  methods: {
    toggleFullscreen() {
      if (process.client && window.document) {
        const requestFullscreen = (elem) => {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
        };

        const exitFullscreen = () => {
          if (window.document.exitFullscreen) {
            window.document.exitFullscreen();
          } else if (document.webkitExitFullscreen) { /* Safari */
            window.document.webkitExitFullscreen();
          } else if (window.document.msExitFullscreen) { /* IE11 */
            window.document.msExitFullscreen();
          }
        };

        const imageContainer = window.document.getElementById(`edition_image_${this.editionNumber}`);
        requestFullscreen(imageContainer);

        const fullScreenHandler = () => {
          if (window.document.fullscreenElement) {
            exitFullscreen();
          } else {
            requestFullscreen(imageContainer);
          }
        };

        imageContainer.addEventListener('click', fullScreenHandler);
      }
    },
    async loadMetaData() {
      const metadata = await this.$metadataApi.$get(`/metadata/network/${this.chainId}/edition/${this.editionNumber}`);
      if (metadata) {
        this.metadataFromKoCache = metadata;
      }
    }
  }
};
</script>
