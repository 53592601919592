<template>
  <section class="columns has-margin-top-1 is-multiline">
    <aside
      class="column is-one-third"
      v-for="collection in collectionItems"
      :key="collection.slug"
    >
      <n-link :to="{name: 'profile-id', params: {id: collection.slug}}">
        <div class="card is-shadowless has-margin-bottom-1">
          <div class="card-image">
            <figure class="image" v-if="collection.collectionMetadata && collection.collectionMetadata.coverEditionId">
              <div v-lazy-container="{ selector: 'img' }">
                <img
                  :data-src="`https://cdn.knownorigin.io/cdn/images/network/1/edition/${collection.collectionMetadata && collection.collectionMetadata.coverEditionId}`"
                  data-loading="/branding/bigloader-image.svg"
                  data-error="/branding/tiles/Collections.jpg"
                  :id="`collection-${collection.slug}`"
                  :class="{'collection-image-cover': true}"
                />
              </div>
            </figure>
            <figure v-else>
              <div v-lazy-container="{ selector: 'img' }">
                <img
                  :data-src="`${collection.coverImageUrl}`"
                  data-loading="/branding/bigloader-image.svg"
                  data-error="/branding/tiles/Collections.jpg"
                  :id="`collection-${collection.slug}`"
                  :class="{'collection-image-cover': true}"
                />
              </div>
            </figure>
          </div>
          <div class="card-content is-paddingless">
            <div class="content">
              <h3 class="is-family-tertiary has-text-weight-bold has-margin-top-6">
                {{ collection.username }}
              </h3>
              <p class="is-family-primary has-text-weight-normal">
                {{ collection.bio }}
              </p>
            </div>
          </div>
        </div>
      </n-link>
    </aside>
  </section>
</template>

<script>
  export default {
    props: ['collectionItems']
  };
</script>
