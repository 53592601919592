var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.panelType === "editorial2"
        ? _c("Editorial2", {
            attrs: {
              "cms-component": _vm.cmsComponent,
              "is-application": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }