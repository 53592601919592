var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.views
    ? _c("div", { staticClass: "profileViews" }, [
        _c(
          "p",
          {
            staticClass:
              "is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 has-text-weight-bold is-family-tertiary",
          },
          [_vm._v("PROFILE VIEWS")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "is-flex is-align-items-center has-text-weight-semibold is-size-6",
          },
          [
            _c("b-icon", {
              staticClass: "mr-1",
              attrs: { icon: "eye-outline", size: "is-small" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.views))]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }