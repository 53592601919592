import {ContentAuth} from '@contentauth/sdk/dist/cai-sdk.esm';
import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import {isCaiApplicable, validateCai} from './cai.service';

async function fetchProvenance(version, chainId, editionId, imageUrl, minter) {
    try {
    // Only applicable for jpg and png
    if (isCaiApplicable(chainId, editionId, imageUrl)) {
        // Initialize the SDK with URLs of the WASM binary and worker - these need to instantiated in the component
        const sdk = new ContentAuth({
            wasmSrc: `/content-auth-sdk/assets/wasm/toolkit_bg_v${version}.wasm`,
            workerSrc: `/content-auth-sdk/cai-sdk-v${version}.worker.js`
        });

        const fullImageUri = ipfsUtils.buildPinataLookupUri(imageUrl);
        console.log('Attempting to process content auth for', fullImageUri);

        // Pass into the tool the raw image URL
        const provenance = await sdk.processImage(fullImageUri)

        console.log(provenance)

        return validateCai(version, provenance, minter);
    } else {
        console.log('CAI is not applicable')
    }
    } catch (error) {
        if (error.name === 'C2pa(PrereleaseError)') {
            console.log('Legacy 0.8 Beta CAI image found')
            return fetchProvenance(1, chainId, editionId, imageUrl, minter)
        }
        console.log(error)
    }
}
export function caiProvenance(chainId, editionId, imageUrl, minter) {
    return fetchProvenance(2, chainId, editionId, imageUrl, minter)
}
