/* eslint-disable-next-line lodash/import-scope */
import _ from 'lodash';
import * as moment from 'moment';
import {isBuyNowAndOpenSalesType, isOffersOnlySalesType, isReserveAuctionSalesType} from './SaleTypes';

const mapNotification = (event, eventPrimaryUser) => {
  const eventStr = event.toString();
  if (eventStr === 'EditionCreated') {
    return 'Minted';
  }
  if (eventStr === 'TokenListed') {
    return 'Marketplace list';
  }
  if (eventStr === 'TokenDeListed') {
    return 'Sale cancelled';
  }
  if (eventStr === 'Purchase') {
    if (eventPrimaryUser === 'creator') {
      return 'Sold';
    }
    if (eventPrimaryUser === 'seller') {
      return 'Sold';
    }
    return 'Purchase';
  }
  if (eventStr === 'BidPlaced') {
    if (eventPrimaryUser === 'creator') {
      return 'New bid';
    }
    return 'Bid placed';
  }
  if (eventStr === 'BidIncreased') {
    if (eventPrimaryUser === 'creator') {
      return 'Bid Increased';
    }
    return 'Bid increased';
  }
  if (eventStr === 'BidAccepted') {
    if (eventPrimaryUser === 'creator') {
      return 'Bid accepted';
    }
    if (eventPrimaryUser === 'seller') {
      return 'Bid Accepted';
    }
    return 'Bid accepted';
  }
  if (eventStr === 'BidRejected') {
    if (eventPrimaryUser === 'creator') {
      return 'Bid rejected';
    }
    return 'Bid rejected';
  }
  if (eventStr === 'BidWithdrawn') {
    if (eventPrimaryUser === 'creator') {
      return 'Bid withdrawn';
    }
    return 'Bid withdrawn';
  }
  return mapEvent(eventStr);
};

const mapEvent = (event) => {
  const eventStr = event.toString();
  if (eventStr === 'EditionCreated') {
    return 'New creation';
  }
  if (eventStr === 'Purchase') {
    return 'Sold';
  }
  if (eventStr === 'TokenListed') {
    return 'Marketplace list';
  }
  if (eventStr === 'TokenDeListed') {
    return 'Marketplace delisted';
  }
  if (eventStr === 'EditionGifted') {
    return 'Gifted️';
  }
  if (eventStr === 'Minted') {
    return 'Token birth';
  }
  if (eventStr === 'BidPlaced') {
    return 'Bid placed';
  }
  if (eventStr === 'BidIncreased') {
    return 'Bid increased';
  }
  if (eventStr === 'BidAccepted') {
    return 'Bid accepted';
  }
  if (eventStr === 'BidderRefunded') {
    return 'Bid refunded';
  }
  if (eventStr === 'BidRejected') {
    return 'Bid rejected';
  }
  if (eventStr === 'PriceChanged') {
    return 'Price changed';
  }
  if (eventStr === 'BidWithdrawn') {
    return 'Bid withdrawn';
  }
  if (eventStr === 'ReserveExtended') {
    return 'Reserve extended';
  }
  if (eventStr === 'ReserveCountdownStarted') {
    return 'Bid Placed';
  }
  if (eventStr === 'ReservePriceChanged') {
    return 'Reserve price changed';
  }
  if (eventStr === 'ReserveBidWithdrawn') {
    return 'Bid Withdrawn';
  }
  if (eventStr === 'ReserveBidPlaced') {
    return 'Bid Placed';
  }
  if (eventStr === 'ComposableAdded') {
    return 'Enhanced';
  }
  if (eventStr === 'ComposableClaimed') {
    return 'Redeemed';
  }
  return eventStr;
};

const mapEventTitle = (event) => {
  const eventStr = event.toString();
  if (eventStr === 'EditionCreated') {
    return '';
  }
  if (eventStr === 'Purchase') {
    return 'Owned by';
  }
  if (eventStr === 'TokenListed') {
    return 'Owned by';
  }
  if (eventStr === 'TokenDeListed') {
    return 'Owned by';
  }
  if (eventStr === 'EditionGifted') {
    return 'Owned by';
  }
  if (eventStr === 'Minted') {
    return '';
  }
  if (eventStr === 'BidPlaced') {
    return 'Placed by';
  }
  if (eventStr === 'BidIncreased') {
    return 'Placed by';
  }
  if (eventStr === 'BidAccepted') {
    return 'Owned by';
  }
  if (eventStr === 'BidderRefunded') {
    return 'Placed by';
  }
  if (eventStr === 'BidRejected') {
    return 'Placed by';
  }
  if (eventStr === 'PriceChanged') {
    return 'Owned by';
  }
  if (eventStr === 'BidWithdrawn') {
    return 'Placed by';
  }
  if (eventStr === 'SteppedListed') {
    return 'Stepped sale listed';
  }
  if (eventStr === 'ReserveListed') {
    return 'Reserve auction listed';
  }
  if (eventStr === 'ReserveBidPlaced') {
    return 'Bid';
  }
  if (eventStr === 'ReserveBidWithdrawn') {
    return 'Bid Withdrawn';
  }
  if (eventStr === 'ReserveCountdownStarted') {
    return 'Bid';
  }
  if (eventStr === 'ReservePriceChanged') {
    return 'Reserve price changed';
  }
  if (eventStr === 'ReserveExtended') {
    return 'Price changed';
  }
  return eventStr;
};

const mapActivityEventTitle = (event, salesType) => {
  const eventStr = event.toString();
  if (eventStr === 'EditionCreated' && isOffersOnlySalesType(salesType)) {
    return 'Place a bid';
  }
  if (eventStr === 'EditionCreated' && isReserveAuctionSalesType(salesType)) {
    return 'Reserve price';
  }
  if (eventStr === 'EditionCreated' && isBuyNowAndOpenSalesType(salesType)) {
    return 'Open edition';
  }
  if (eventStr === 'EditionCreated') {
    return 'Buy now';
  }
  if (eventStr === 'Purchase') {
    return 'Sold for';
  }
  if (eventStr === 'TokenListed') {
    return 'Buy now';
  }
  if (eventStr === 'TokenDeListed') {
    return 'List removed';
  }
  if (eventStr === 'EditionGifted') {
    return 'Transfer';
  }
  if (eventStr === 'Minted') {
    return 'Buy now';
  }
  if (eventStr === 'BidPlaced') {
    return 'Bid placed';
  }
  if (eventStr === 'BidIncreased') {
    return 'Bid placed';
  }
  if (eventStr === 'BidAccepted') {
    return 'Sold for';
  }
  if (eventStr === 'BidderRefunded') {
    return 'Bid refunded';
  }
  if (eventStr === 'BidRejected') {
    return 'Bid rejected';
  }
  if (eventStr === 'PriceChanged') {
    return 'Buy now';
  }
  if (eventStr === 'BidWithdrawn') {
    return 'Bid withdrawn';
  }
  if (eventStr === 'SteppedListed') {
    return 'Stepped sale listed';
  }
  if (eventStr === 'ReserveListed') {
    return 'Reserve auction listed';
  }
  if (eventStr === 'ReserveBidPlaced') {
    return 'Bid placed';
  }
  if (eventStr === 'ReserveBidWithdrawn') {
    return 'Bid Withdrawn';
  }
  if (eventStr === 'ReserveCountdownStarted') {
    return 'Bid placed';
  }
  if (eventStr === 'ReservePriceChanged') {
    return 'Reserve price changed';
  }
  if (eventStr === 'ReserveExtended') {
    return 'Price changed';
  }
  return eventStr;
};

const mapActivityEventPersonaLabel = (event) => {
  const eventStr = event.toString();
  if (eventStr === 'Purchase') {
    return 'Owned by';
  }
  if (eventStr === 'TokenListed') {
    return 'Owned by';
  }
  if (eventStr === 'TokenDeListed') {
    return 'Owned by';
  }
  if (eventStr === 'EditionGifted') {
    return 'Owned by';
  }
  if (eventStr === 'BidPlaced') {
    return 'Placed by';
  }
  if (eventStr === 'BidIncreased') {
    return 'Placed by';
  }
  if (eventStr === 'BidAccepted') {
    return 'Owned by';
  }
  if (eventStr === 'BidderRefunded') {
    return 'Placed by';
  }
  if (eventStr === 'BidRejected') {
    return 'Placed by';
  }
  if (eventStr === 'PriceChanged') {
    return 'Owned by';
  }
  if (eventStr === 'BidWithdrawn') {
    return 'Placed by';
  }
  if (eventStr === 'SteppedListed') {
    return 'Owned by';
  }
  if (eventStr === 'ReserveListed') {
    return 'Owned by';
  }
  if (eventStr === 'ReserveBidPlaced') {
    return 'Placed by';
  }
  if (eventStr === 'ReserveBidWithdrawn') {
    return 'Placed by';
  }
  if (eventStr === 'ReserveCountdownStarted') {
    return 'Placed by';
  }
  if (eventStr === 'ReservePriceChanged') {
    return 'Owned by';
  }
  if (eventStr === 'ReserveExtended') {
    return 'Placed by';
  }
  return eventStr;
};

const buildTitleAndMeta = (
  {
    author,
    title = 'KnownOrigin | Digital Art Marketplace | NFT Crypto Art',
    description = 'KnownOrigin is a digital art marketplace powered by Ethereum. Discover, collect and invest in rare, limited edition digital artworks from some of the world\'s leading artists and creatives.',
    image = 'https://knownorigin.io/branding/KO-logo-169ratio.jpg',
    url = 'https://knownorigin.io/',
    canonicalBaseUrl = 'https://knownorigin.io',
    canonicalExt = null
  }
) => {
  const meta = [
    {
      hid: 'title',
      name: 'title',
      content: title
    },
    {
      hid: 'description',
      name: 'description',
      content: description
    },
    {
      hid: 'image',
      name: 'image',
      content: image
    },
    {
      hid: 'og:title',
      name: 'og:title',
      content: title
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: description
    },
    {
      hid: 'og:image',
      name: 'og:image',
      content: image
    },
    {
      hid: 'og:url',
      name: 'og:url',
      content: url
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      content: 'KnownOrigin'
    },
    {
      hid: 'og:type',
      name: 'og:type',
      content: 'article' // good for things like pinterest
    }
  ];
  if (author) {
    meta.push({
      hid: 'article:author',
      name: 'article:author',
      content: author // good for things like pinterest
    });
  }

  const headMetadata = {
    title,
    meta
  };

  if (canonicalExt) {
    headMetadata.link = [
      {
        rel: 'canonical',
        href: `${canonicalBaseUrl}${canonicalExt || ''}`
      }
    ];
  }

  return headMetadata;
};

const cleanUpUrl = (url) => {
  if (url) {
    const splits = url.split('/');
    if (splits.length === 1) {
      return splits[0];
    }
    return splits[splits.length - 1];
  }
  return url;
};

const providerName = (selectedCacheProvider) => {
  if (_.toLower(selectedCacheProvider) === 'injected') {
    return 'Injected';
  } else if (_.toLower(selectedCacheProvider) === 'portis') {
    return 'Portis';
  } else if (_.toLower(selectedCacheProvider) === 'walletconnect') {
    return 'Wallet Connect';
  } else if (_.toLower(selectedCacheProvider) === 'torus') {
    return 'Torus';
  }
  return 'No provider';
};

const getChainName = (chainId) => {
  switch (chainId) {
    case 11155111:
      return 'sepolia'
    case 5:
      return 'goerli'
    default:
      return 'ethereum'
  }
}

const determineRank = (minTotalScore, maxTotalScore, currentScore, currentMinScore, currentMaxScore) => {
  return (maxTotalScore - minTotalScore) * (currentScore - currentMinScore) / (currentMaxScore - currentMinScore) + minTotalScore;
};

const setFlameRating = (artists, lowestScore, highestScore, flameRank) => {
  for (const artist of artists) {
    flameRank = determineRank(0, 100, artist.score, lowestScore, highestScore);
    if (flameRank >= 50) {
      artist.flameRating = 3;
    } else if (flameRank < 50 && flameRank >= 10) {
      artist.flameRating = 2;
    } else {
      artist.flameRating = 1;
    }
  }
};

const highestScore = (topArtists) => {
  return Math.max(...topArtists.map(artist => artist.score));
}

const lowestScore = (topArtists) => {
  return Math.min(...topArtists.map(artist => artist.score));
};

const excludedCollabs = [
  '0x2ce780d7c743a57791b835a9d6f998b15bbba5a4'.toLowerCase(),
  '0x6c6e93874216112ef12a0d04e2679ecc6c3625cc'.toLowerCase(),
  '0x21316e6a4f0af45e5f1503984e83b10c53b177d8'.toLowerCase(),
  '0xdbc522931928f8ff7784c84e5fcec4ffdcd6e9eb'.toLowerCase()
];

const notInExclusions = (collaborator) => {
  if (collaborator) {
    return !_.includes(excludedCollabs, collaborator.toLowerCase());
  }
  return true;
};

const editionStartDatePassed = (editionStartDate) => {
  if (!editionStartDate) {
    return true;
  }
  // Zero means the its already available from the point of being minted
  if (editionStartDate === 0) {
    return true;
  }
  const now = moment().local().utc().unix();
  return _.toNumber(editionStartDate) <= now;
};

const editionStartDateJustPassed = (editionStartDate) => {
  if (!editionStartDate) {
    return true;
  }
  // Zero means the its already available from the point of being minted
  if (editionStartDate === 0) {
    return true;
  }
  const now = moment().local().utc().unix();
  return now <= _.toNumber(editionStartDate) + 86400;
};

const listOrOfferAmountFloorInEth = 0.001;

const lockoutPeriodForOffers = '12 hours';

const specialDrops = [
  {
    featured: true,
    editionId: '383700',
    slug: 'wuki',
    leadContent: `
Kris Barman, known professionally as Wuki, is an American musician, Grammy nominated record producer, and DJ.

After the March 2021 release of his sonically diverse debut album WukiWorld via HARD Recs, Wuki has teamed up with acclaimed animator Chadwickmak to drop his genesis NFT’s on KnownOrigin.
`,
    artistContent: `
Barman got his start in the industry as the synth player and guitarist for electronic rock band Innerpartysystem. After a major label deal and few hits in the underground, the band fell apart, at which point he took the name Wuki and started a dance music project of his own.

Since 2012, Barman has built a reputation as a producer's producer, handling any and all party vibes.`,
    artistQuote: 'Wuki stated, "The more I learn about this space the more excited I get, I think it brings power back to artists which is so important. It also gives me a chance to collaborate with visual artists I love in a more meaningful way.”'
  }
];

const featuredDrops = [
//   {
//     featured: true,
//     editionId: '495600',
//     coverImage: 'https://storage.googleapis.com/public-blog-asset/featured-drops/Are%20you%20there%20cover.jpg',
//     slug: 'calvyn-justus',
//     leadContent: `
//     This 1/1 piece was created for those who have experienced the loss of a loved one.
//
//     Inspiring a feeling of comfort and a moment of reminiscing as we see the hand of our loved one slowly emerge, reaching out to us, pressing into the membrane separating life and death.
//
//     With Euphoric & heavenly colors, this subtle experience is a reminder that they are still with us, a helping hand when you need it. Ready for in home display on a 4k screen.
// `,
//     artistContent: `Zach Murray
//     A science fantasy digital artist who creates fantasy dreamscapes, digital collages, photo manipulations, glitch art; using and building on known techniques in order to find new combinations. I therefore like to describe my approach as experimental as most of what I do is a result of trial and error. I rarely return to the same project unless the first time brought real enjoyment!
//
//     HMK
//     A colorblind digital artist who creates 3D artworks, Digital Paintings and Concept Art around feelings and emotions. Almost all of my artworks are interpretations of what I feel, and sometimes, when I'm lucky; people relate with the same feeling and that hues up my day with all the colors, even the ones I can't see.`,
//     // artistQuote: `this is me`,
//   },
];

const journalMap = {
  592200: '/journal/drops/UTB'
};

const getJournalDate = (blogPost) => {
  return new Date(blogPost.publishedDate ? blogPost.publishedDate : blogPost.date).getTime()
}

const getJournalDateFrom = (blogPost) => {
  return moment(getJournalDate(blogPost)).fromNow()
}

const PUBLIC_BLOG_ASSET_BUCKET = 'https://storage.googleapis.com/public-blog-asset/';

const isKoEscrow = (seller) => {
  return _.toLower(seller) === '0x3abc8c65a9516d5b487a9f7423ae2c4c6a3adf51' || _.toLower(seller) === '0xabb3738f04dc2ec20f4ae4462c3d069d02ae045b';
}

export {
  mapEvent,
  mapEventTitle,
  isKoEscrow,
  mapActivityEventTitle,
  mapActivityEventPersonaLabel,
  mapNotification,
  buildTitleAndMeta,
  cleanUpUrl,
  providerName,
  notInExclusions,
  excludedCollabs,
  editionStartDatePassed,
  editionStartDateJustPassed,
  listOrOfferAmountFloorInEth,
  featuredDrops,
  specialDrops,
  journalMap,
  lockoutPeriodForOffers,
  PUBLIC_BLOG_ASSET_BUCKET,
  determineRank,
  setFlameRating,
  highestScore,
  lowestScore,
  getChainName,
  getJournalDate,
  getJournalDateFrom
};
