<template>
  <div class="columns is-flex is-vcentered column has-text-left is-half"
       :class="{'countdown-started': reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete && !isCc && !fromActivity, 'pl-0': isCc, 'is-clipped': !fromActivity}">

    <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link">
      <div v-if="isCc" class="column is-family-tertiary">
        <section v-if="isBuyNowAndOpenSalesType(edition.salesType)">
        <div v-if="!startDatePassed" class="has-text-white">
          <p>Starts in</p>
          <clock-count-down-simple :start-date="edition.startDate" :white-text="true">
          </clock-count-down-simple>
        </div>
        <div v-else-if="!endDatePassed(edition) && !isNeverEndingEdition(edition)" class="has-text-white has-text-weight-semibold">
          <p>Ends in</p>
          <clock-count-down-simple :start-date="edition.endDate" :white-text="true">
          </clock-count-down-simple>
        </div>
      </section>
      <section v-else-if="!startDatePassed && !isSoldOutPrimary">
        <span class="has-text-weight-semibold has-text-white">
          Starts in
          <clock-count-down-simple :start-date="edition.startDate" :white-text="true">
          </clock-count-down-simple>
        </span>
      </section>
      </div>
      <div v-else>
        <div class="is-family-tertiary">
          <div v-if="phase && phase.state !== 'public' && isNotSoldOutPrimary">
        <span v-if="phase.state === 'pre-phase'" class="has-text-weight-semibold">
           Starts in
        </span>
            <span v-else-if="phase.state === 'active'" class="has-text-weight-semibold" :class="{'is-muted': !isCc}">
            Public price
          </span>
          </div>
          <span v-else-if="!startDatePassed && !isSoldOutPrimary" class="has-text-weight-semibold">
          Starts in
        </span>
          <span v-else-if="isSteppedSalesType(edition.salesType) && isNotSoldOutPrimary(edition)"
                class="has-text-weight-semibold" :class="{'is-muted': !isCc}">
          Next price
        </span>
          <span v-else-if="reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete"
                class="has-text-weight-semibold">
          Auction ends in
        </span>
          <span v-else-if="startDateJustPassed && edition.startDate > 0" class="has-text-weight-semibold">
            &nbsp;
        </span>
          <span v-else class="has-text-weight-semibold">&nbsp;</span>
        </div>
        <span v-if="phase && phase.state !== 'public'">
        <clock-count-down-simple
          v-if="phase.state === 'pre-phase'"
          class="has-text-weight-semibold"
          :start-date="phase.startTime"
          :white-text="false">
        </clock-count-down-simple>
        <eth-with-fiat-price
          v-else-if="phase.state === 'active'"
          :price-in-wei="edition.priceInWei"
          class="has-text-weight-semibold" :class="{'is-muted': !isCc}">
        </eth-with-fiat-price>
      </span>
        <span v-else-if="!startDatePassed && !isSoldOutPrimary">
          <clock-count-down-simple :start-date="edition.startDate" :white-text="false"
                                   class="has-text-weight-semibold">
          </clock-count-down-simple>
        </span>
        <span v-else-if="isSteppedSalesType(edition.salesType) && isNotSoldOutPrimary(edition)">
          <eth-with-fiat-price :price-in-wei="getNextStep(edition.priceInWei, edition.stepSaleStepPrice)"
                               class="has-text-danger has-text-weight-semibold">
          </eth-with-fiat-price>
        </span>
        <span v-else-if="reserveAuctionCountdownStarted && !reserveAuctionCountdownComplete"
              class="has-text-weight-semibold">
            <auction-count-down-simple :end-date="edition.reserveAuctionEndTimestamp" :white-text="false"
                                       class="has-text-weight-semibold">
            </auction-count-down-simple>
        </span>
      </div>
      <!-- <span v-else-if="startDateJustPassed && edition.startDate > 0" class="has-text-weight-semibold">-->
      <!--   On sale now-->
      <!-- </span>-->
    </n-link>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {
  isSteppedSalesType,
  isReserveAuctionSalesType,
  isBuyNowAndOpenSalesType,
  getNextStep
} from '../../services/SaleTypes';
import EthWithFiatPrice from '../../components/common/EthWithFiatPrice';
import ClockCountDownSimple from '../clocks/ClockCountDownSimple';
import AuctionCountDownSimple from '../clocks/AuctionCountDownSimple';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import {editionStartDateJustPassed, editionStartDatePassed} from '../../services/utils';
import editionUtils from '../../services/editionUtils';
import {handleGalleryRouting} from '../../services/routing';
import {isCreatorContract} from '../../services/CreatorContractUtils';

export default {
  components: {
    AuctionCountDownSimple,
    ClockCountDownSimple,
    EthWithFiatPrice
  },
  props: [
    'edition',
    'phase',
    'fromActivity'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      like: false,
      editionOffers: null,
      tokens: null
    };
  },
  computed: {
    ...mapState('web3Store', [
      'gqlClient'
    ]),
    isCc() {
      return isCreatorContract(this.edition);
    },
    isSoldOutPrimary() {
      return editionUtils.isSoldOutPrimary(this.edition);
    },
    startDatePassed() {
      return editionStartDatePassed(this.edition.startDate);
    },
    startDateJustPassed() {
      return editionStartDateJustPassed(this.edition.startDate);
    },
    reserveAuctionCountdownStarted() {
      return ReserveAuctionUtils.reserveAuctionCountdownStarted(this.edition);
    },
    reserveAuctionCountdownComplete() {
      return ReserveAuctionUtils.reserveAuctionCountdownComplete(this.edition);
    },
    handleMarketPlaceRouting() {
      return handleGalleryRouting(this.edition)
    }
  },
  methods: {
    isSteppedSalesType,
    isReserveAuctionSalesType,
    isBuyNowAndOpenSalesType,
    getNextStep,
    isNotSoldOutPrimary(edition) {
      return editionUtils.isNotSoldOutPrimary(edition);
    },
    endDatePassed(edition) {
      return editionUtils.endDatePassed(edition);
    },
    isNeverEndingEdition(edition) {
      return editionUtils.isNeverEndingEdition(edition)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.countdown-started {
  background-color: $black;
  color: $white !important;

  div > * {
    background-color: $black;
    color: $white !important;
  }

  span > * {
    background-color: $black;
    color: $white !important;
  }
}

</style>
