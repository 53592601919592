<template>
  <a v-if="contentAuthenticityPassed" :href="generateVerifyLink" target="_blank">
    <b-tag class="is-uppercase is-dark">
      <b-icon icon="check-decagram" size="is-small" class="mr-1"></b-icon>
      Content Credentials
    </b-tag>
  </a>
</template>
<script>

import {ContentAuth} from '@contentauth/sdk/dist/cai-sdk.esm';
import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import {mapState} from 'vuex';
import {caiProvenance} from '../../../services/caiProvenance';

export default {
  props: ['editionId', 'imageUrl', 'minter'],
  data() {
    return {
      contentAuthenticityPassed: false
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    generateVerifyLink() {
      return ContentAuth.generateVerifyUrl(
          ipfsUtils.buildPinataLookupUri(this.imageUrl)
      );
    }
  },
  async mounted() {
        const results = await caiProvenance(this.chainId, this.editionId, this.imageUrl, this.minter);
        this.contentAuthenticityPassed = results ? results.passed : false;
  }
};

</script>
