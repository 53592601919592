var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level is-mobile box is-family-tertiary" }, [
    _c(
      "div",
      { staticClass: "level-item has-text-centered" },
      [
        _c(
          "n-link",
          {
            staticClass: "is-clickable",
            attrs: {
              to: {
                name: "gallery-id",
                params: { id: _vm.token.editionNumber },
              },
            },
          },
          [
            _c("p", { staticClass: "heading" }, [_vm._v("Edition #")]),
            _vm._v(" "),
            _c("p", { staticClass: "title" }, [
              _vm._v("#" + _vm._s(_vm.token.editionNumber)),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "level-item has-text-centered" }, [
      _c("div", [
        _c("p", { staticClass: "heading" }, [_vm._v("Token ID")]),
        _vm._v(" "),
        _c("p", { staticClass: "title" }, [_vm._v("#" + _vm._s(_vm.token.id))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "level-item has-text-centered" }, [
      _c("div", [
        _c("p", { staticClass: "heading" }, [_vm._v("Issue")]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "title" },
          [
            _c("token-id-of-x", {
              attrs: {
                id: _vm.token.id,
                "edition-number": _vm.token.editionNumber,
                version: _vm.token.version,
                "edition-total-available": _vm.token.edition.totalAvailable,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }