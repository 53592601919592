<template>
  <section class="mb-4">
    <article class="tile is-child content">

      <div class="is-family-tertiary">
        <section v-if="isSoldOutPrimary">
          <div class="columns is-mobile" v-if="isCreator && hasOpenEditionEndedWithNoSales">
            <div class="column content">
              <manage-c-c-edition :edition="edition" />
            </div>
          </div>
          <section v-if="ownedTokens.length > 0">
            <a target="_blank"
               class="mb-5 button is-medium is-fullwidth is-primary is-outlined"
               :href="viewOnOpenSeaHref">
              View on OpenSea
            </a>

            <a href="#salesTable" v-smooth-scroll="{ duration: 500, updateHistory: false, offset: -150 }"
               class="mb-5 button is-medium is-fullwidth is-secondary">
              View listings
            </a>

          </section>
          <section v-else>
            <div class="has-text-weight-semibold title has-text-danger">
              {{ isBuyNowAndOpenSalesType(edition.salesType) ? 'Sale ended' : 'Sold out'}}
            </div>

            <a target="_blank"
               class="mb-5 button is-medium is-fullwidth is-primary is-outlined"
               :href="viewOnOpenSeaHref">
              View on OpenSea
            </a>
          </section>
        </section>
        <section v-else>

          <section v-if="revokedApproval">
            <p class="has-text-danger">
              Not for sale - seller has revoked approval
            </p>
          </section>

          <!-- Buy Now -->
          <div class="columns is-mobile" v-else-if="isBuyNowSalesType(edition.salesType)">
            <div class="column content">
              <p class="title">Buy now</p>
              <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="edition.priceInWei">
              </eth-with-fiat-price>
            </div>
          </div>

          <div class="columns is-mobile" v-else-if="isBuyNowAndOpenSalesType(edition.salesType)">
            <div class="column content">
              <p class="title">Open edition</p>
              <eth-with-fiat-price size="is-large" :strong="true" :show-fiat="true" :price-in-wei="edition.priceInWei">
              </eth-with-fiat-price>
            </div>
            <div class="column content">
              <p class="title">Pieces minted</p>
              <span><span class="subtitle is-2">{{ edition.totalSold }}</span></span>
              <span v-if="edition.totalAvailable!=='100000'" class="is-muted is-small">
                / {{ edition.totalAvailable }}
              </span>
            </div>
          </div>

          <div class="columns is-mobile" v-if="isCreator">
            <div class="column content">
              <manage-c-c-edition :edition="edition" />
            </div>
          </div>

          <!-- Show start date countdown -->
          <div class="columns is-mobile" v-if="!editionStartDatePassed">
            <div class="column content">
              <edition-sale-count-down :edition="edition" @start-sale="onSaleStarted">
              </edition-sale-count-down>
            </div>
          </div>

          <!-- Show end date countdown -->
          <div class="columns is-mobile" v-if="editionStartDatePassed && isBuyNowAndOpenSalesType(edition.salesType) && !editionEndDatePassed && !isNeverEndingEdition">
            <div class="column content">
              <edition-sale-count-down :edition="edition" @end-sale="onSaleEnded" :is-end-date="true">
              </edition-sale-count-down>
            </div>
          </div>
          <div v-else-if="isBuyNowAndOpenSalesType(edition.salesType) && (editionEndDatePassed || edition.totalAvailable===edition.totalSold )">
            <div class="has-text-weight-semibold title has-text-danger">
              {{edition.salesType===6 ? 'Sale ended' : 'Sold out'}}
            </div>
            <a target="_blank"
               class="mb-5 button is-medium is-fullwidth is-primary is-outlined"
               :href="viewOnOpenSeaHref">
              View on OpenSea
            </a>
          </div>

          <!-- Primary sale CTAs -->
          <section class="columns is-mobile" v-if="!revokedApproval && editionStartDatePassed && isWeb3Connected && !editionEndDatePassed"
                   v-cloak>
            <div class="column content" v-if="isBuyNowSalesType(edition.salesType) || isBuyNowAndOpenSalesType(edition.salesType)">
              <buy-now-buttons :edition="edition" :algolia-info="algoliaInfo"></buy-now-buttons>
            </div>
          </section>

        </section>
      </div>

      <!-- Part 2 - Ownership -->
      <div>
        <section v-if="ownedTokens.length > 0">
          <article class="tile is-child">
            <div class="has-text-weight-semibold title">
              {{ ownedTokens.length }} owned by
            </div>
            <user-profile-lookup
              :artist-account="account"
              :metadata-artist="account">
            </user-profile-lookup>
          </article>

          <b-button type="is-primary" size="is-medium"
                    expanded outlined @click="downloadArtwork">
            View artwork
          </b-button>
        </section>

        <section v-if="!isWeb3Connected">
          <b-button data-testid="connectWalletnBtn" type="is-primary" size="is-medium" expanded outlined class="has-margin-bottom-7 has-margin-top-7"
                    @click="loginToWeb3">
            <span>Connect wallet</span>
          </b-button>
        </section>
      </div>
    </article>
  </section>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {ipfsUtils} from '@knownorigin/ko-token-uri-tools';
import editionUtils from '../../services/editionUtils';
import {isBuyNowSalesType, isBuyNowAndOpenSalesType} from '../../services/SaleTypes';
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import EditionSaleCountDown from '../clocks/EditionSaleCountDown';
import UserProfileLookup from '../UserProfileLookup';
import {splitCreatorEditionId} from '../../services/CreatorContractUtils';
import {generateOpenSeaCollectionLink} from '../../services/OpenSea';
import ManageCCEdition from '../manage/ManageCCEdition';
import BuyNowButtons from './BuyNowButtons';

export default {
  components: {
    ManageCCEdition,
    EthWithFiatPrice,
    EditionSaleCountDown,
    BuyNowButtons,
    UserProfileLookup
  },
  props: ['edition', 'algoliaInfo', 'ownedTokens'],
  data() {
    return {
      revokedApproval: false,
      openSeaDetails: null
    }
  },
  computed: {
    ...mapGetters('web3Store', [
      'isLoggedInAccount',
      'isWeb3Connected',
      'openSeaTokenLink'
    ]),
    ...mapState('web3Store', [
      'gqlClient',
      'chainId',
      'account'
    ]),
    editionStartDatePassed() {
      return editionUtils.startDatePassed(this.edition);
    },
    editionEndDatePassed() {
      return editionUtils.endDatePassed(this.edition);
    },
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    isSoldOutPrimary() {
      return editionUtils.isSoldOutPrimary(this.edition);
    },
    hasOpenEditionEndedWithNoSales() {
      return editionUtils.hasOpenEditionEndedWithNoSales(this.edition)
    },
    viewOnOpenSeaHref() {
      // If we fetched the details from opensea then go to the collection
      if (this.openSeaDetails && this.openSeaDetails.collection && this.openSeaDetails.collection.slug) {
        return generateOpenSeaCollectionLink(this.chainId, this.openSeaDetails.collection.slug)
      } else {
        // Otherwise default to the asset link
        const editionSplit = splitCreatorEditionId(this.edition.id)
        return this.openSeaTokenLink(editionSplit.contract, editionSplit.editionId)
      }
    },
    isNeverEndingEdition() {
      return editionUtils.isNeverEndingEdition(this.edition)
    },
    isCreator() {
      return this.edition.artistAccount === this.account
    }
  },
  async mounted() {
    // TODO check revoked approval status
    // TODO eventually make calls here to check aggregator listing status'
    if (this.edition) {
      // TODO is this better of being fetched at page level and passed down?
      await this.fetchOpenSeaDetails()
    }
  },
  methods: {
    isBuyNowSalesType,
    isBuyNowAndOpenSalesType,
    loginToWeb3() {
      this.$store.dispatch('web3Store/connectToWeb3');
    },
    onSaleStarted() {
      // TODO change to key re-render
      // Tweak the edition to force re-render
      this.edition.startDate = this.edition.startDate - 1;
    },
    onSaleEnded() {
      // TODO change to key re-render
      // Tweak the edition to force re-render
      this.edition.endDate = this.edition.endDate - 1;
    },
    downloadArtwork() {
      if (this.edition && this.edition.metadata) {
        const ipfsAsset = this.edition.metadata.animation_url || this.edition.metadata.image;
        window.open(`https://knownorigin.mypinata.cloud/ipfs/${ipfsUtils.extractFullIpfsPathFromUri(ipfsAsset)}`, '_blank');
      }
    },
    async fetchOpenSeaDetails() {
      try {
        if (this.edition) {
          const {contract} = splitCreatorEditionId(this.edition.id)
          const details = await this.$api.$get(`/network/${this.chainId}/contract/opensea/${contract}`)
          if (details) {
            this.openSeaDetails = details
          }
        }
      } catch (err) {
        console.error('Unable to fetch opensea details')
      }
    },
    listOnOpenSea() {
      // TODO needs to be plugged into reservoir tools
    }
  }
}
</script>
