<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">

      <div class="card-content has-padding-7">

        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <p class="title">
              Select cover image - max 5mb (jpg, png, gif)
            </p>

            <b-field class="file is-fullwidth" expanded>
              <b-upload v-model="file" expanded accept="image/*" @input="handleNewFile" :disabled="saving">
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span v-if="file">{{ file.name }}</span>
                  <span v-else>Click to upload</span>
                </a>
              </b-upload>
            </b-field>
            <b-field v-if="!file">
              <b-upload v-model="file" drag-drop expanded accept="image/*" @input="handleNewFile"
                        :disabled="saving">
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Drop your cover image here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <section>
              <img v-if="file && file.thumb" :src="file.thumb" />
            </section>

            <section v-if="error">
              <div class="has-text-danger">{{ error }}</div>
            </section>

          </section>
        </div>

        <div class="card-footer">

          <div class="card-footer-item">
            <b-button expanded
                      :disabled="saving"
                      @click="close">
              Nevermind
            </b-button>
          </div>

          <div class="card-footer-item">
            <b-button class="button is-primary"
                      expanded
                      :disabled="!file"
                      :loading="saving"
                      @click="uploadNewCoverImage">
              Save
            </b-button>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
const _5mb = 5 * 1024 * 1024;

export default {
  components: {},
  props: [],
  data() {
    return {
      file: null,
      error: null,
      saving: false
    }
  },
  methods: {
    handleNewFile(event) {
      // console.log("handleNewFile", event);
      this.error = null;
      if (event.size > _5mb) {
        this.error = 'File to big - max 5mb';
      } else {
        this.file.thumb = URL.createObjectURL(this.file);
      }
    },
    async uploadNewCoverImage() {
      try {
        this.saving = true;
        this.error = null;
        const isAuthenticated = await this.$store.dispatch('web3Store/createJwtSession');
        if (isAuthenticated) {
          await this.$store.dispatch('userStore/uploadCoverImage', this.file);
          this.$emit('close', {refresh: true});
        }
      } catch (e) {
        this.error = 'Unable to save file';
        console.error(e);
      } finally {
        this.saving = false;
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
