<template>
  <section class="mt-6 mb-6 title-font pt-4 pb-4" :class="{'rounded-container': isApplication}">
    <div class="container content section">
      <h2 class="column title is-size-3-tablet is-size-4-mobile has-text-centered mb-5 has-text-weight-black">{{ cmsComponent.title }}</h2>
      <div class="columns">
        <n-link v-for="blogPost in cmsComponent.editorial2Fields" :key="blogPost.title" :to="blogPost.link" class="column is-one-third mb-4" :target="newTabDecider">
          <div class="card-image">
            <figure class="image is-16by9">
              <img :src="blogPost.imageLink" class="journal-image-cover">
            </figure>
          </div>
          <div class="card-content is-paddingless">
            <div class="content">
              <h3 class="is-family-tertiary has-margin-top-6 is-size-4 has-text-weight-bold">{{ blogPost.title }}</h3>
              <p>
                <b-tag v-if="blogPost.journalType" class="is-uppercase is-family-primary" type="is-secondary">{{ blogPost.journalType }}</b-tag>
                <template v-if="blogPost.description">{{ blogPost.description }}</template>
                <span v-if="blogPost.youtube">
                  <b-icon icon="youtube" />
                </span>
              </p>
              <time v-if="blogPost.date" class="is-muted is-size-7 level is-mobile">
                <div class="level-left is-family-tertiary has-text-weight-bold">
                  <b-icon icon="calendar" class="level-item" size="is-small" />
                  <span class="level-item">{{ new Date(blogPost.date) | moment('from') }}</span>
                </div>
              </time>
            </div>
          </div>
        </n-link>
      </div>
    </div>
  </section>
</template>

<script>
import {AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';

export default {
  props: ['cmsComponent', 'isApplication'],
  computed: {
    newTabDecider() { // opens in a new tab if its the application page
      return this.isApplication ? '_blank' : '';
    }
  },
  methods: {
    async handleButtonClickedEvent(destination, externalLink) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.buttonClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'editorial2',
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  background: #f0f0f0;
}

.image {
  overflow: hidden;
}

.journal-image-cover {
  transition: transform .3s ease;

  &:hover {
    transform: scale(1.02);
  }
}

.is-family-secondary {
  transition: transform .3s ease;

  &:hover {
    transform: translateX(4px);
  }
}

.tag {
  background: #fff;
}
</style>
