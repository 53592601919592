<template>
  <header class="header-image has-text-white has-background-grey-darker" v-lazy:background-image="image">
    <section class="hero">
      <div class="hero-body">
        <div class="container content">
          <h2 class="title has-text-white has-margin-top-1 is-size-1">
            <n-link :to="linkToJournal" class="has-text-white has-padding-top-6" v-if="headerImage">
              Guest editor <br /> {{ editor }}
            </n-link>
          </h2>
          <div class="content">
            <div class="field is-grouped">
              <p class="control">
                <n-link :to="linkToJournal" class="button is-white is-outlined">
                  View full collection
                </n-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
  export default {
    props: ['editor', 'headerImage', 'linkToJournal'],
    computed: {
      image() {
        return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${this.headerImage[0]}/cover`;
      }
    }
  };
</script>
