var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container is-flex is-align-items-center p-2",
      class: _vm.count % 2 === 0 ? "has-background-white-ter" : "",
    },
    [
      _c("figure", { staticClass: "image is-48x48 mb-0" }, [
        _c("img", { staticClass: "is-rounded", attrs: { src: _vm.icon } }),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "ml-3" }, [
        _c("span", { staticClass: "has-text-weight-normal has-text-black" }, [
          _vm._v(_vm._s(_vm.marketplace)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }