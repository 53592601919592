var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edition-image card" },
    [
      [
        _vm.edition
          ? _c(
              "div",
              {
                class: {
                  ccArtwork: _vm.isCc,
                  "has-background-white": !_vm.isCc,
                  "pb-2": _vm.cardSize === 2,
                },
              },
              [
                _c("image-for-asset-card", {
                  attrs: {
                    edition: _vm.edition,
                    cover: true,
                    resolution: "card",
                    metadata: _vm.workingMetadata,
                    "click-handler": _vm.clickHandler,
                    "disable-icons": false,
                    "is-cc": _vm.isCc,
                  },
                }),
                _vm._v(" "),
                _vm.cardSize === 3
                  ? _c(
                      "div",
                      [
                        _vm._t("artwork-info-asset-card", function () {
                          return [
                            _c("artwork-info", {
                              attrs: {
                                edition: _vm.edition,
                                size: _vm.size,
                                phase: _vm.phase,
                                "click-handler": _vm.clickHandler,
                              },
                            }),
                          ]
                        }),
                        _vm._v(" "),
                        _c(
                          "footer",
                          {
                            staticClass: "card-footer",
                            class: { borderGrey: _vm.isCc },
                          },
                          [
                            _vm._t(
                              "search-footer-left-asset-card",
                              function () {
                                return [
                                  _c("search-footer-left-asset-card", {
                                    attrs: {
                                      edition: _vm.edition,
                                      "working-token": _vm.workingToken,
                                      phase: _vm.phase,
                                      "token-offer": _vm.tokenOffer,
                                      "min-bid": _vm.minBid,
                                      "click-handler": _vm.clickHandler,
                                    },
                                  }),
                                ]
                              }
                            ),
                            _vm._v(" "),
                            _vm._t(
                              "search-footer-right-asset-card",
                              function () {
                                return [
                                  _c("search-footer-right-asset-card", {
                                    attrs: {
                                      edition: _vm.edition,
                                      "working-token": _vm.workingToken,
                                      "click-handler": _vm.clickHandler,
                                      phase: _vm.phase,
                                    },
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    )
                  : _c(
                      "b-tooltip",
                      {
                        staticClass:
                          "column is-12 p-0 m-0 is-block cardTooltip",
                        class: { "pb-2": _vm.cardSize === 1 },
                        attrs: {
                          type: !_vm.isCc ? "is-light" : "is-dark",
                          animated: "",
                          position: _vm.cardSize < 2 ? "is-bottom" : "is-top",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "is-flex is-align-items-start is-justify-content-center is-flex-direction-column",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "my-1 is-size-7" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.edition.metadata.name)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.cardSize < 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-full py-2 px-0 flexbox",
                                            },
                                            [
                                              _c(
                                                "search-footer-left-asset-card",
                                                {
                                                  staticClass: "column is-5",
                                                  attrs: {
                                                    edition: _vm.edition,
                                                    "working-token":
                                                      _vm.workingToken,
                                                    phase: _vm.phase,
                                                    "token-offer":
                                                      _vm.tokenOffer,
                                                    "min-bid": _vm.minBid,
                                                    "click-handler":
                                                      _vm.clickHandler,
                                                    size: _vm.cardSize,
                                                  },
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "search-footer-right-asset-card",
                                                {
                                                  staticClass: "column",
                                                  attrs: {
                                                    edition: _vm.edition,
                                                    "working-token":
                                                      _vm.workingToken,
                                                    "click-handler":
                                                      _vm.clickHandler,
                                                    phase: _vm.phase,
                                                    size: _vm.cardSize,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2487665189
                        ),
                      },
                      [
                        _c("div", { staticClass: "is-full-width" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "edition-image-artist px-3 is-size-7-tablet is-flex is-justify-content-space-between is-align-items-center",
                              class: { "has-text-white": _vm.isCc },
                            },
                            [
                              _c(
                                "n-link",
                                {
                                  class: {
                                    "has-text-dark": !_vm.isCc,
                                    "has-text-white": _vm.isCc,
                                  },
                                  attrs: {
                                    to: {
                                      name: "profile-id",
                                      params: {
                                        id: _vm.edition.artistAccount.toLowerCase(),
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("artist-name-and-image-lookup", {
                                    staticClass: "is-clipped",
                                    attrs: {
                                      "artist-account":
                                        _vm.edition.artistAccount,
                                      name: _vm.edition.metadata.artist,
                                      collaborators: _vm.edition.collaborators,
                                      "is-cc": _vm.isCc,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("availability", {
                                attrs: {
                                  available: _vm.edition.totalAvailable,
                                  supply: _vm.edition.totalSupply,
                                  edition: _vm.edition,
                                  small: true,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.cardSize === 2
                            ? _c(
                                "footer",
                                {
                                  staticClass: "card-footer mt-2 mb-0",
                                  class: { borderGrey: _vm.isCc },
                                },
                                [
                                  _c("search-footer-left-asset-card", {
                                    attrs: {
                                      edition: _vm.edition,
                                      "working-token": _vm.workingToken,
                                      phase: _vm.phase,
                                      "token-offer": _vm.tokenOffer,
                                      "min-bid": _vm.minBid,
                                      "click-handler": _vm.clickHandler,
                                      size: _vm.cardSize,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("img", {
                  staticClass: "card-image-cover",
                  attrs: { src: "/branding/bigloader-image.svg" },
                }),
                _vm._v(" "),
                _c("b-skeleton", {
                  attrs: { active: "", width: "140px", height: "12px" },
                }),
              ],
              1
            ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }