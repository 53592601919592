var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isIcon
    ? _c(
        "span",
        {
          staticClass:
            "is-flex is-justify-content-center hand-pointer copyAddress",
          on: { click: _vm.copyAddress },
        },
        [
          _c(
            "b-tooltip",
            {
              staticClass:
                "copyAddress__copyBtn is-flex is-align-items-center is-justify-content-center",
              attrs: {
                label: _vm._f("dotDotDot")(_vm.address),
                type: "is-dark",
              },
            },
            [
              _c("img", {
                staticClass: "copyAddress__icon",
                attrs: { src: "/profile/eth.svg" },
              }),
            ]
          ),
        ],
        1
      )
    : _c(
        "span",
        { staticClass: "hand-pointer", on: { click: _vm.copyAddress } },
        [
          _c("b-icon", { attrs: { icon: "ethereum", size: "is-small" } }),
          _vm._v(
            "\n  " + _vm._s(_vm._f("dotDotDot")(_vm.address)) + "\n   \n  "
          ),
          _c("b-icon", {
            attrs: { icon: "content-copy", size: "is-small", type: "is-grey" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }