var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tokens && _vm.tokens.length > 0
    ? _c(
        "div",
        { staticClass: "mt-6 p-0", attrs: { id: "salesTable" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.tokens,
                "sticky-header": "",
                "default-sort": "priceInEth",
                "default-sort-direction": "desc",
                striped: "",
                "mobile-cards": false,
              },
            },
            [
              _c("b-table-column", {
                attrs: {
                  field: "id",
                  label: "ID",
                  centered: "",
                  sortable: "",
                  visible: _vm.$device.isDesktopOrTablet,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "is-clickable" }, [
                            _vm._v("#" + _vm._s(props.row.tableData.id)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3134929654
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "seller", label: "Owned By" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.tableData.owner
                            ? _c(
                                "n-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "profile-id",
                                      params: { id: props.row.tableData.owner },
                                    },
                                  },
                                },
                                [
                                  _c("artist-name-and-image-lookup", {
                                    staticClass:
                                      "is-small small is-inline-block",
                                    attrs: {
                                      "artist-account":
                                        props.row.tableData.owner,
                                      size: "is-small",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3662886897
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-flex is-align-items-center",
                attrs: {
                  field: "lastSalePriceInEth",
                  label: "Last Price",
                  sortable: "",
                  numeric: "",
                  centered: "",
                  "custom-sort": _vm.sortableString("lastSalePriceInEth"),
                  visible: _vm.$device.isDesktopOrTablet,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.tableData.lastPrice
                            ? _c("eth-with-fiat-price", {
                                attrs: {
                                  "price-in-eth": props.row.tableData.lastPrice,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1901508928
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "priceInEth",
                  label: "List Price",
                  sortable: "",
                  numeric: "",
                  centered: "",
                  "custom-sort": _vm.sortableString("priceInEth"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.tableData.listPrice
                            ? _c("eth-with-fiat-price", {
                                attrs: {
                                  "price-in-eth": props.row.tableData.listPrice,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2260111424
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: {
                  field: "expiration",
                  label: "Expiration",
                  sortable: "",
                  numeric: "",
                  centered: "",
                  "custom-sort": _vm.sortableString("expiration"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.tableData.expiration
                            ? _c("p", [
                                _vm._v(_vm._s(props.row.tableData.expiration)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2279190401
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "actionButton", centered: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          props.row.tableData.editFlow
                            ? _c(
                                "b-dropdown",
                                {
                                  staticClass: "is-clickable has-text-left",
                                  attrs: {
                                    "aria-role": "list",
                                    position: "is-bottom-left",
                                    triggers: ["hover"],
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      slot: "trigger",
                                      icon: "dots-horizontal",
                                    },
                                    slot: "trigger",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        "aria-role": "list",
                                        target: "_blank",
                                        href: _vm.getOpenseaLink(
                                          props.row.tableData.id
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                View on OpenSea\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("hr", { staticClass: "dropdown-divider" }),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { "aria-role": "list" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.launchSecondaryListingModal(
                                            props.row.tableData,
                                            "cancel"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Cancel listing\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  staticClass: "is-small is-fullwidth",
                                  attrs: { type: "is-primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.launchSecondaryListingModal(
                                        props.row.tableData,
                                        "list"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n             Sell this item\n           "
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4142241637
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "content" }, [
      _c("h2", [_vm._v("Your tokens")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }