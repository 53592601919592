var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activityEvents
    ? _c("aside", { staticClass: "content scrollable-content" }, [
        _c(
          "ul",
          [
            _vm._l(_vm.activityEvents, function (activityEvent) {
              return _c("li", { key: activityEvent.id }, [
                _c("div", { staticClass: "columns is-mobile is-multiline" }, [
                  _c("div", { staticClass: "column is-half" }, [
                    _c(
                      "span",
                      { staticClass: "heading has-text-weight-semibold" },
                      [_vm._v(_vm._s(_vm.mapEvent(activityEvent.eventType)))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "heading" },
                      [
                        _c("profile-name-link", {
                          attrs: { "eth-account": activityEvent.buyer },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "is-muted-7",
                        attrs: {
                          href: _vm.etherscanTxLink(
                            activityEvent.transactionHash
                          ),
                          target: "_blank",
                        },
                      },
                      [_vm._v("[view tx]")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column is-half" }, [
                    _c(
                      "span",
                      { staticClass: "heading" },
                      [
                        _c("date-from", {
                          attrs: {
                            "timestamp-in-secs": activityEvent.timestamp,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "heading" },
                      [
                        activityEvent.eventValueInWei
                          ? _c("eth-with-fiat-price", {
                              attrs: {
                                size: "is-small",
                                "show-historical-fiat": "true",
                                "price-in-wei": activityEvent.eventValueInWei,
                                "transaction-hash":
                                  activityEvent.transactionHash,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.creationEvent && _vm.creationEvent.length === 1
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile is-multiline" }, [
                    _c("div", { staticClass: "column is-half" }, [
                      _c(
                        "span",
                        { staticClass: "heading has-text-weight-semibold" },
                        [
                          _vm._v(
                            _vm._s(_vm.mapEvent(_vm.creationEvent[0].eventType))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "is-muted-7",
                          attrs: {
                            href: _vm.etherscanTxLink(
                              _vm.creationEvent[0].transactionHash
                            ),
                            target: "_blank",
                          },
                        },
                        [_vm._v("[view tx]")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-half" }, [
                      _c(
                        "span",
                        { staticClass: "heading" },
                        [
                          _c("date-from", {
                            attrs: {
                              "timestamp-in-secs":
                                _vm.creationEvent[0].timestamp,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }