<template>
  <div v-if="edition.totalAvailable !== edition.totalSupply">
    <eth-with-fiat-price :price-in-wei="edition.priceInWei" v-if="!edition.offersOnly"></eth-with-fiat-price>
    <eth-with-fiat-price :price-in-eth="edition.activeBid.ethValue" v-else-if="edition.activeBid && edition.activeBid.ethValue"></eth-with-fiat-price>
    <eth-with-fiat-price :price-in-wei="edition.priceInWei" v-else></eth-with-fiat-price>

<!--    <span class="has-text-warning" v-if="edition.activeBid">-->
<!--      {{ edition.activeBid.bidder }}-->
<!--      {{ edition.activeBid.ethValue }}-->
<!--    </span>-->
  </div>
  <div class="has-text-danger" v-else>
    Sold out
  </div>
</template>
<script>
  import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

  export default {
    components: {EthWithFiatPrice},
    props: ['edition']
  };
</script>
