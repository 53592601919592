<template>
  <aside class="content">
    <div class="card is-shadowless">
      <div class="card-content has-padding-7">
        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <h2 class="mb-0">
              Collectors network for {{ profile.username }}
            </h2>
            <p class="mb-5">
              <b-progress show-value class="progress-small" type="is-primary" v-if="loading">
                Loading network graph
              </b-progress>
            </p>
            <section>
              <network class="wrapper"
                       ref="network"
                       :nodes="nodes"
                       :edges="edges"
                       :options="options"
                       @deselect-node="onDeselectNode"
                       @select-node="onSelectNode"
                       @double-click="onDoubleClick">
              </network>
              <p class="mt-5">
                Circles represent editions, diamonds represent collectors. Data is king and blockchain enables this!
              </p>
              <p class="is-muted">
                Double click on each item to view the artwork or profile, colours are randomly generated.
              </p>
            </section>
          </section>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>

import _uniqBy from 'lodash/uniqBy';
import _flatten from 'lodash/flatten';
import {mapState} from 'vuex';
import {ethers} from 'ethers';
import {ALL_TRANSFER_FOR_ARTIST_EDITIONS} from '../../queries/artistQueries';
import {splitCreatorEditionId} from '../../services/CreatorContractUtils';

export default {
  components: {},
  props: ['profile'],
  data() {
    return {
      nodes: [],
      edges: [],
      editions: [],
      loading: false,
      options: {
        nodes: {
          borderWidth: 2
        },
        edges: {
          color: 'lightgray'
        },
        physics: {
          barnesHut: {
            springLength: 100,
            springConstant: 0.04,
            avoidOverlap: 0.2
          }
        }
      }
    };
  },
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  mounted() {
    this.$apollo.addSmartQuery('editionTransferHistory', {
      client: this.gqlClient,
      query: ALL_TRANSFER_FOR_ARTIST_EDITIONS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          artistAccount: this.profile.address
        };
      },
      update({editions}) {
        this.editions = editions;
        this.buildGraph();
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    splitCreatorEditionId,
    close() {
      this.$emit('close');
    },
    onDoubleClick(node) {
      if (node.nodes && node.nodes.length === 1) {
        const ccDetails = this.splitCreatorEditionId(node.nodes[0]);
        if (ccDetails.editionId && ccDetails.contract) {
          this.$nuxt.$router.push({name: 'contract-contract-id', params: {id: ccDetails.editionId, contract: ccDetails.contract}});
        } else if (ethers.utils.isAddress(node.nodes[0])) {
          this.$nuxt.$router.push({name: 'profile-id', params: {id: node.nodes[0]}});
        } else {
          this.$nuxt.$router.push({name: 'gallery-id', params: {id: node.nodes[0]}});
        }
      } else {
        const edge = this.$refs.network.getEdge(node.edges[0]);
        this.$nuxt.$router.push({name: 'tokens-id', params: {id: edge.label.replace('#', '')}});
      }
    },
    onSelectNode(params) {
      console.log('onSelectNode', params);
    },
    onDeselectNode(node) {
      console.log('onDeselectNode', node);
    },
    async buildGraph () {
      this.loading = true;

      const shortEth = value => `${value.substr(0, 4)}...${value.substr(value.length - 4, value.length)}`;

      const nameResolver = async (address) => {
        const user = await this.$store.dispatch('userStore/getUser', address);
        return user.username ? user.username : shortEth(address);
      }

      const edgeLabel = transfer => `#${transfer.tokenId}`;

      const nodeColour = (address) => {
        if (address === '0x0000000000000000000000000000000000000000') {
          return '#a4adf8';
        }
        return stringToColour(address);
      }

      const stringToColour = (str) => {
        let i;
        let hash = 0;
        for (i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
      }

      // Add ever edition as node
      const editionNodes = this.editions.map((edition) => {
        return {
          id: edition.id,
          label: `#${edition.id}`,
          shape: 'circle',
          color: stringToColour(edition.id),
          cid: edition.id // cid = cluster ID
        };
      });

      // Add all accounts as nodes as well
      const allAddresses = await this.editions.map((edition) => {
        return edition.transfers
          .filter(({to}) => to !== '0x0000000000000000000000000000000000000000') // remove burns
          .map((transfer) => {
            return {
              id: transfer.to,
              shape: 'diamond',
              color: nodeColour(transfer.to),
              cid: transfer.tokenId
            };
          });
      });

      // Get unique address
      const addresses = _uniqBy(_flatten(allAddresses), 'id');

      // Decorate them with real usernames
      const decoratedAddressNodes = await Promise.all(addresses.map(async (node) => {
        return {
          ...node,
          label: await nameResolver(node.id)
        }
      }));

      // join the nodes
      this.nodes = [
        ...editionNodes,
        ...decoratedAddressNodes
      ];

      // Draw all lines between editions and buyers
      const allEdges = this.editions.map((edition) => {
        return edition.transfers.map(async (transfer) => {
          // map all the transfer to, to the edition
          return {
            to: edition.id,
            from: transfer.to,
            label: edgeLabel(transfer),
            cid: edition.id
          };
        });
      });
      this.edges = await Promise.all(_flatten(allEdges));

      this.loading = false;
    }
  }
};

</script>
<style scoped lang="scss">
.wrapper {
  max-height: 800px;
  padding: 10px;
  height: 100vh;
}

.progress-small {
  max-width: 400px;
}
</style>
