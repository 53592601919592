var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content" }, [
      _vm.contracts && _vm.contracts.length > 0
        ? _c(
            "div",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.contracts, function (contract) {
              return _c(
                "div",
                {
                  key: contract.id,
                  staticClass:
                    "column is-half-tablet is-4-desktop is-3-widescreen is-one-fifth-fullhd",
                },
                [
                  _c("creator-contract-card", {
                    attrs: { contract: contract },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            [_c("empty-state", { attrs: { message: "No contracts created" } })],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }