var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      !_vm.hidden
        ? _c("vue-countdown", {
            attrs: {
              time: _vm.diff,
              transform: _vm.transform,
              "emit-events": true,
            },
            on: { progress: _vm.handleCountdownProgress },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c(
                        "section",
                        [
                          _vm._t("countdown-title", function () {
                            return [
                              !_vm.hideLabel
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "title is-family-tertiary has-font-weight-bold",
                                      class: {
                                        "has-text-white":
                                          _vm.whiteText === true,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.isEndDate
                                              ? "Closes in"
                                              : "Sale starts in"
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm._v(" "),
                          _vm._t("countdown-content", function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "level is-mobile box is-family-tertiary has-text-weight-normal",
                                  class: {
                                    "has-text-white": _vm.whiteText === true,
                                    "has-text-success": _vm.type === "success",
                                    "has-text-danger": _vm.type === "danger",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.days))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Days"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.hours))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Hours"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.minutes))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Minutes"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "level-item has-text-centered",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "is-size-3 heading mb-0 has-text-weight-bold",
                                          },
                                          [_vm._v(_vm._s(props.seconds))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "heading" }, [
                                          _vm._v("Seconds"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }