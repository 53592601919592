<template>
  <div class="content" style="margin-left: 20px; margin-right: 20px">
    <div class="card">
      <div class="columns is-centered mb-0">
        <div class="card-content column is-11 pb-0">
          <div class="columns is-mobile mt-2">
            <div class="column is-3">
              <modal-image-asset
                custom-class="image is-128x128"
                :edition-number="editionNumber"
                class="has-margin-top-7"
                v-if="editionNumber"
              >
              </modal-image-asset>
            </div>
            <div class="column">
              <section class="has-padding-top-7 has-padding-bottom-7 ml-3">
                <h2 class="is-size-4 mb-1">
                  <div>
                    End open edition
                  </div>
                </h2>
                <h5 class="mb-5">
                  <artwork-name-and-artist
                    :metadata="metadata"
                    :edition-number="editionNumber"
                    custom-class="is-size-7"
                  >
                  </artwork-name-and-artist>
                </h5>
              </section>
            </div>
          </div>

          <div class="content mb-1">
            <hr class="p-0 m-0" />
            <!--  Error -->
            <section v-if="error">
              <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                The transaction has failed
              </p>
              <p class="has-text-weight-light pr-3 mb-5">
                Please return to the edition page and try to set the listing up again.
              </p>
              <div class="is-flex mb-5">
                <b-icon
                  icon="times"
                ></b-icon>
                <span class="ml-3">Transaction failed</span>
              </div>
            </section>
            <!--  Content -->

            <section v-else-if="step === 0">
              <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                Are you sure you want to end this open edition?
              </p>
              <p class="has-text-weight-light pr-3 mb-5">
                You'll be asked to sign a transaction that will end this sale,
                and no more editions can be minted by collectors
              </p>
            </section>
            <section v-else-if="step === 1">
              <p class="has-text-weight-medium is-size-5 mt-4 mb-0">
                Go to your wallet
              </p>
              <p class="has-text-weight-light pr-3 mb-5">
                You'll be asked to approve the collection from your wallet.
                You'll only need to approve each collection once.
              </p>
            </section>
            <section v-if="step > 0">
              <div class="is-flex my-5">
                <b-icon
                  :icon="
                    approved
                      ? 'check-circle-outline has-text-success'
                      : 'clock-outline has-text-warning-dark'
                  "
                ></b-icon>
                <span class="ml-3">{{
                    approved
                      ? "Transaction confirmed"
                      : "Waiting for the transaction to be confirmed"
                  }}</span>
              </div>
            </section>
          </div>
        </div>
      </div>

      <!--- Buttons  -->
      <div class="card-footer" v-if="error">
        <div class="card-footer-item">
          <b-button expanded type="" @click="close()">
            Close
          </b-button>
        </div>
      </div>
      <div class="card-footer" v-else-if="step === 0">
        <div class="card-footer-item">
          <b-button expanded @click="close()"> Nevermind </b-button>
        </div>
        <div class="card-footer-item">
          <b-button expanded type="is-primary" @click="endListing()">
            End listing
          </b-button>
        </div>
      </div>
      <div class="card-footer" v-else-if="step === 2">
        <div class="card-footer-item">
          <b-button expanded type="is-primary" @click="close()">
            Close
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EndOpenEdition',
  props: ['editionNumber', 'metadata'],
  computed: {
    ...mapActions(['web3ActionsStore/toggleEditionSalesDisabled'])
  },
  data() {
    return {
      step: 0,
      error: false,
      approved: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async endListing() {
      console.log('end listing')
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/toggleEditionSalesDisabled', {
          edition: this.editionNumber
        })

        this.transactionHash = tx.hash;
        this.step = 1
        // wait for the transaction to be mined
        await tx.wait(1);

        this.approved = true
        this.step = 2
        this.$emit('close');
      } catch (e) {
        this.step = 1
        this.error = true
        console.log(e)
      }
    }
  }
};
</script>

<style scoped>

</style>
