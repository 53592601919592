import axios from 'axios';
import { ethers } from 'ethers';
import Qs from 'qs';

export function SecondaryService() {
  const secondaryServiceClient = axios.create({
    baseURL: process.env.SECONDARY_SERVICE_API_URL
  });
  return {
    async createListing(createListingParams) {
      const { token, creator, price, endDate } = createListingParams;
      const weiPrice = ethers.utils.parseEther(price).toString(); // base 18
      const body = {
            listings: [
                {
                  weiPrice: weiPrice,
                  token: token,
                  expirationTime: endDate.toString()
                }
              ],
              walletAddress: creator
            }
            console.log(body)
      try {
        const { data } = await secondaryServiceClient.post(
          'v1/listings',
          body
        );
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async cancelListing(orderIds) {
      try {
        const { data } = await secondaryServiceClient.delete('v1/listings', {
          data: { orderIds }
        });
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async getSecondaryListing(tokenId) {
      try {
        const { data } = await secondaryServiceClient.get('v1/tokens', {
          params: { tokenIds: [tokenId] },
          paramsSerializer: function (params) {
            return Qs.stringify(params, { arrayFormat: 'repeat', encode: false });
          }
        });
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async getSecondaryListings(tokenArray) {
      try {
        const { data } = await secondaryServiceClient.get('v1/tokens', {
          params: { tokenIds: tokenArray },
          paramsSerializer: function (params) {
            return Qs.stringify(params, { arrayFormat: 'repeat', encode: false });
          }
        });
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async submitSignedOrder(signedOrderData) {
      try {
        const {orderData, signature} = signedOrderData
        const { data } = await secondaryServiceClient.post(
          `v1/orders/`,
          { items: [orderData], signature: signature, source: 'known-origin-discovery' }
        );
        return data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    handleSteps(data) {
      return data.steps.map((step) => {
        const transaction = handleTransactionStep(step);
        const signature = handleSignatureStep(step);

        if (transaction) {
          return transaction;
        }
        if (signature) {
          return signature;
        }

        return false;
      });
    }
  };
}

function handleTransactionStep(step) {
  if (step.kind === 'transaction' && step.items[0].status === 'incomplete') {
    return {
      from: step.items[0].data.from,
      to: step.items[0].data.to,
      data: step.items[0].data.data,
      gasLimit: '0x1000000',
      maxPriorityFeePerGas: ethers.utils.parseUnits('5', 'gwei'),
      maxFeePerGas: ethers.utils.parseUnits('20', 'gwei')
    };
  }
  return false;
}

function handleSignatureStep(step) {
  if (step.id === 'order-signature' && step.items[0].status === 'incomplete') {
    const { domain, types, value } = step.items[0].data.sign;
    const { body } = step.items[0].data.post;
    return { domain, types, value, body };
  }
  return false;
}
