<template>
  <div class="columns level" v-if="populatedToken">
    <div class="column is-one-fifth level-left">
      <slot name="left">
        <span class="image is-64x64">
          <img :src="populatedToken.logo ? populatedToken.logo : '/erc20.png'" :alt="populatedToken.symbol">
        </span>
      </slot>
    </div>
    <div class="column level-item">
      <slot name="middle">
        <p>
          {{ populatedToken.count }} {{ populatedToken.name }}
        </p>
        <a :href="etherscanAddressLink(populatedToken.address)" target="_blank" class="is-muted is-size-7">
          [view contract]
        </a>
      </slot>
    </div>
    <div class="column level-right has-text-right">
      <slot name="right">
      <span>
        <span>
          {{ populatedToken.count }} {{ populatedToken.symbol }}
        </span>
        <span v-if="populatedToken.usdPrice" class="is-muted is-size-7">
          / ${{ populatedToken.usdPrice | to2Dp }}
        </span>
        <span v-else class="is-muted is-size-7">/ n/a</span>
      </span>

      </slot>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  props: ['token'],
  data() {
    return {
      populatedToken: null
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'etherscanAddressLink'
    ])
  },
  async mounted() {
    this.populatedToken = await this.$store.dispatch('enhancedTokensStore/getErc20TokenDetailsAndPrice', {item: this.token});
  }
};
</script>
