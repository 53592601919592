var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns has-padding-left-3" }, [
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("user-profile-lookup", {
          attrs: {
            "artist-account": _vm.artistAccount,
            "metadata-artist": _vm.artistAccount,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.filteredCollabs && _vm.filteredCollabs.length >= 1
      ? _c("div", {
          staticClass: "is-divider-vertical",
          attrs: { "data-content": "X" },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.filteredCollabs &&
    _vm.filteredCollabs.length > 1 &&
    _vm.filteredCollabs.length <= 4
      ? _c("div", { staticClass: "column is-vcentered" }, [
          _c("div", { staticClass: "level is-mobile" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              _vm._l(_vm.filteredCollabs, function (collab, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "level-item is-clickable",
                    staticStyle: { "margin-left": "-1.5rem" },
                    on: { click: _vm.openCollectiveModal },
                  },
                  [
                    _c("account-image", {
                      attrs: { address: collab, size: "is-64x64" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ])
      : _vm.filteredCollabs && _vm.filteredCollabs.length === 1
      ? _c("div", { staticClass: "column is-vcentered" }, [
          _c("div", { staticClass: "level" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _c("user-profile-lookup", {
                  attrs: {
                    "artist-account": _vm.filteredCollabs[0],
                    "metadata-artist": _vm.filteredCollabs[0],
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.filteredCollabs && _vm.filteredCollabs.length > 4
      ? _c("div", { staticClass: "column is-vcentered" }, [
          _c("div", { staticClass: "level is-mobile" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _vm._l(_vm.splicedCollabs, function (collab, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "level-item is-clickable",
                      staticStyle: { "margin-left": "-1.5rem" },
                      on: { click: _vm.openCollectiveModal },
                    },
                    [
                      _c("account-image", {
                        attrs: { address: collab, size: "is-64x64" },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "level-item" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "is-size-4-desktop is-clickable",
                      on: { click: _vm.openCollectiveModal },
                    },
                    [_vm._v("+" + _vm._s(_vm.filteredCollabs.length - 4))]
                  ),
                ]),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showEditionButton
      ? _c(
          "div",
          {
            staticClass: "is-flex is-align-items-center is-justify-content-end",
          },
          [
            _c(
              "b-button",
              {
                attrs: { type: "is-primary", outlined: "" },
                on: { click: _vm.routeToEdition },
              },
              [_vm._v("\n      Go to edition\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }