<template>
  <section class="container section">
    <div class="has-text-centered">
      <h1 class="is-uppercase has-text-danger">
        Oops...You're lost or somethings went bang!
      </h1>
    </div>
    <div class="content has-text-centered" style="margin-top: 50px">
      <nuxt-link :to="'/' + path">Return to {{ path }}</nuxt-link>
    </div>
  </section>
</template>

<script>
export default {
  props: ['path']
}
</script>
