export const state = () => ({
    showApprovedFields: false
});

export const getters = {
    canShowApprovedFields: (state) => {
        return state.showApprovedFields;
    }
};

export const mutations = {
    UPDATE_APPROVED_FIELDS(state, payload) {
        state.showApprovedFields = payload
    }
};

export const actions = {
    checkIfUserIsApproved(context, payload) {
        const approvedWallets = [
            '0xe2765d68363a8398a077c9fff44b19378b63adc2',
            '0x21749491edacb011f33e6b69e8ffb3c27569a86f',
            '0x0e8fa894969f98646f762c77b4a77205fc2b2510',
            '0x35ab400ceb753e1a7abf51944674e856a589e923',
            '0x0f48669b1681d41357eac232f516b77d0c10f0f1',
            '0x6b8db42e4607b5e9928c0eda2a838aa838b64714',
            '0xd966f2fcdae6e00bfbc8d11521944df141199fe9',
            '0xf6c08c1b7d0729bd24298c839e90f56cf5b5dd4f', // Raz
            '0x4544b5e851e1ecb2be566860eb429be02a3c8794', // Chris
            '0x90008cddcc83109b61669a47f8ca8bf90e1cb778', // Feds
            '0xd1036e3a85fd8a08672c44ba57ec4f07cedeadc4' // Matt new
        ]
        context.commit('UPDATE_APPROVED_FIELDS', approvedWallets.includes(payload.toLowerCase()))
    }
};
