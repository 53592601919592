var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level is-mobile" }, [
    _c("div", { staticClass: "level-left" }, [
      _c(
        "div",
        [
          _c(
            "n-link",
            {
              attrs: {
                to: { name: "profile-id", params: { id: _vm.address } },
              },
            },
            [
              _c("h3", { staticClass: "is-family-secondary" }, [
                _vm._v(_vm._s(_vm.nameOrShortEth)),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.message ? _c("small", [_vm._v(_vm._s(_vm.message))]) : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "level-right mb-2" },
      [
        _c(
          "n-link",
          {
            attrs: { to: { name: "profile-id", params: { id: _vm.address } } },
          },
          [_c("account-image", { attrs: { address: _vm.address } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }