<template>
  <ApolloQuery :query="require('../queries/editionQueries').EDITION_BY_ID_WITH_TOKENS_QUERY" :variables="{ id }"
               :client-id="gqlClient">
    <template #default="{ result: { loading, data } }">

      <div
        v-if="data && data.editionById && data.editionById.length > 0 && data.editionById[0] && data.editionById[0].active && !loading">
        <n-link :to="handleMarketPlaceRouting(data.editionById[0])">
          <search-edition-card-resizeable
            :card-size="cardSize"
            :algolia-info="algoliaInfo"
            :edition="data.editionById[0]"
            :cover="cover"
            :resolution="resolution"
            :size="(size === 'is-large') ? 'is-large' : null"
            :address="address"
            :click-handler="handleMarketPlaceRouting(data.editionById[0])" />
        </n-link>
      </div>

      <div class="card mb-4" v-else>
        <div class="card-image">
          <img src="/branding/bigloader-image.svg" :class="{'card-image-cover': cardSize > 2, 'skeleton-small': cardSize === 1, 'skeleton-medium': cardSize === 2}" />
        </div>
        <div class="card-content is-paddingless">
          <div class="content">
            <div class="columns is-mobile is-multiline">
              <div class="column is-9 has-padding-left-2 has-padding-top-2" :class="{'has-padding-top-4': cardSize > 2}">
                <b-skeleton width="50%"></b-skeleton>
              </div>
              <div class="column has-text-right is-3 is-size-7 has-padding-right-2 has-padding-top-2" :class="{'has-padding-top-4': cardSize > 2}">
                <b-skeleton width="100%"></b-skeleton>
              </div>
              <div class="column is-12 has-padding-left-2" :class="{'has-padding-bottom-2': cardSize > 2, 'pt-0': cardSize < 3}" v-if="cardSize > 1">
                <div class="columns is-mobile">
                  <div class="column is-one-fifth" v-if="cardSize > 2">
                    <b-skeleton circle width="32px" height="32px" :animated="true"></b-skeleton>
                  </div>
                  <div class="column" :class="{'pt-1': cardSize < 3}">
                    <b-skeleton width="60%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
  </ApolloQuery>
</template>

<script>
import {mapState} from 'vuex';
import {handleGalleryRouting} from '../services/routing';

export default {
  props: ['id', 'cover', 'resolution', 'size', 'score', 'address', 'algoliaInfo', 'cardSize'],
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  methods: {
    handleMarketPlaceRouting(edition) {
      const route = handleGalleryRouting(edition)

      if (this.algoliaInfo) {
        const query = {}
        if (this.algoliaInfo.queryId) {
          query.query_id = this.algoliaInfo.queryId
        }
        if (this.algoliaInfo.index) {
          query.index = this.algoliaInfo.index
        }
        if (this.algoliaInfo.position) {
          query.position = this.algoliaInfo.position
        }

        route.query = query
      }

      return route
    }
  }
};
</script>

<style lang="scss">

.skeleton-small {
  height: 13rem;
}

.skeleton-medium {
  height: 18rem;
}

</style>
