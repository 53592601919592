var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition.totalAvailable !== _vm.edition.totalSupply
    ? _c(
        "div",
        [
          !_vm.edition.offersOnly
            ? _c("eth-with-fiat-price", {
                attrs: { "price-in-wei": _vm.edition.priceInWei },
              })
            : _vm.edition.activeBid && _vm.edition.activeBid.ethValue
            ? _c("eth-with-fiat-price", {
                attrs: { "price-in-eth": _vm.edition.activeBid.ethValue },
              })
            : _c("eth-with-fiat-price", {
                attrs: { "price-in-wei": _vm.edition.priceInWei },
              }),
        ],
        1
      )
    : _c("div", { staticClass: "has-text-danger" }, [
        _vm._v("\n    Sold out\n  "),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }