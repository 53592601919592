<template>
	<div class="animBanner py-6 px-5 px-6">
		<div class="animBanner__container container columns is-centered mx-auto is-flex pl-5 is-align-items-center">
			<div class="animBanner__left column is-half-desktop is-full-tablet p-6 has-text-white">
				<h3 v-if="content.pretitle"
					class="animBanner__pretitle is-family-secondary has-text-grey-lighter is-size-7 is-uppercase is-flex is-align-items-center mb-4">
					<img class="animBanner__pretitleLogo mr-2" src="/anim/logo.png" />
					{{ content.pretitle }}
				</h3>
				<h2 v-if="content.title" class="animBanner__title is-family-secondary has-text-weight-black is-size-3 is-size-2-widescreen">{{
					content.title }}
					<!-- <span v-if="content.subheader"
						class="animBanner__subheader is-family-secondary is-size-5 is-size-4-widescreen is-block mb-5">{{
							content.subheader }}
					</span> -->
				</h2>
				<h2 v-if="content.largeText" class="animBanner__largeText is-size-6 is-size-5-widescreen mb-4">{{
					content.largeText }}</h2>
				<div v-if="content.mainText" class="animBanner__mainText has-text-grey-lighter" v-html="content.mainText" />
				<a class="mb-5" href="https://portal.knownorigin.io/" target="_blank" rel="noreferrer">
					<button class="ctaButton mt-3 py-4 px-5 has-background-white is-size-6">Create a contract</button>
				</a>
			</div>

			<div class="animBanner__right column is-half-desktop is-full-tablet p-0">
				<!--  -->
				<div v-if="content.image === 'creator-contracts'" class="animBanner__cc">

					<div class="animBanner__ccCards">
						<div v-for="(el, i) in 4" :key="el + i" :class="'animBanner__ccCard--' + (i + 1)" class="animBanner__ccCard">
							<img :src="ccAssets['card' + (i + 1)]" class="animBanner__ccCardImg">
							<img class="animBanner__ccCardBg" src="/anim/banner/creator-contracts/card.svg">
						</div>
					</div>

					<div class="animBanner__ccBg is-flex is-align-items-center is-justify-content-space-between">
						<div class="animBanner__ccContractHolder">
							<img :src="ccAssets.profile" class="animBanner__ccContractUser">
							<img class="animBanner__ccContract" src="/anim/banner/creator-contracts/contract.svg">
						</div>
						<img class="animBanner__ccArrows" src="/anim/banner/creator-contracts/arrows.svg">
						<img class="animBanner__ccMarketplaces" src="/anim/banner/creator-contracts/marketplaces.svg">
					</div>

				</div>
				<!--  -->
			</div>

		</div>
	</div>
</template>

<script>
export default {
	props: ['content'],
	data() {
		return {
			ccAssets: {
				profile: '/anim/banner/creator-contracts/profile.jpg',
				card1: '/anim/banner/creator-contracts/card1.jpg',
				card2: '/anim/banner/creator-contracts/card2.jpg',
				card3: '/anim/banner/creator-contracts/card3.jpg',
				card4: '/anim/banner/creator-contracts/card4.jpg'
			}
		}
	}
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/mixins";

.ctaButton {
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }

@keyframes ccCardAnim {
	0% {
		opacity: 0;
		left: 0%;
	}

	10%,
	80% {
		opacity: 1
	}

	90%,
	100% {
		opacity: 0;
		left: 100%;
	}
}

.animBanner {
	background: url('/anim/gradient-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;

	&__pretitleLogo {
		width: 20px;
	}

	&__title {
		line-height: 1.3;
		font-weight: 700;
		text-transform: uppercase;

		span {
			text-transform: capitalize;
			font-weight: 500;
		}
	}

	&__mainText {
		line-height: 1.75;
		max-width: 500px;

		p {
			margin-bottom: 0.75rem;
		}
	}

	// creator contract animation
	&__cc {
		position: relative;
	}

	&__ccCards {
		position: absolute;
		z-index: 2;
		top: 0;
		bottom: 0;
		left: 200px;
		right: 160px;
	}

	&__ccCard {
		position: absolute;
		width: 96px;
		height: 120px;
		opacity: 0;
		animation: ccCardAnim 5s infinite linear;

		&--1 {
			top: 0;
		}

		&--2 {
			top: 23%;
			animation-delay: 3s;
		}

		&--3 {
			top: 46%;
			animation-delay: 0.75s;
		}

		&--4 {
			bottom: 0;
			animation-delay: 4s;
		}
	}

	&__ccCardImg {
		width: 100%;
		height: 80%;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		background: #D7D7D7;
		position: absolute;
		z-index: 2;
		display: block;
	}

	&__ccCardBg {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: block;
	}

	//
	&__ccContractHolder {
		width: 224px;
		height: 320px;
		position: relative;
	}

	&__ccContract {
		width: 100%;
		height: 100%;
		position: relative;
		display: block;
		z-index: 1;
		mix-blend-mode: overlay;
	}

	&__ccContractUser {
		background: #D7D7D7;
		top: calc(50% - 24px);
		left: calc(50% - 24px);
		width: 48px;
		height: 48px;
		border-radius: 50%;
		position: absolute;
		z-index: 2;
		border: 0;
	}

	&__ccArrows {
		width: 106px;
		height: 200px;
		mix-blend-mode: overlay;
		display: block;
		left: 20px;
		position: relative;
	}

	&__ccMarketplaces {
		width: 120px;
		height: 392px;
		mix-blend-mode: overlay;
		display: block;
	}
}

/*----------------------------------------*/

// < 1216px
@include until-widescreen {
	.animBanner {
		&__left {
			padding-left: 0 !important;
		}

		//
		&__ccCards {
			left: 140px;
			right: 120px;
		}

		&__ccCard {
			width: 72px;
			height: 91px;
		}

		&__ccCardImg {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		&__ccContractHolder {
			width: 180px;
			height: 258px;
		}

		&__ccContractUser {
			top: calc(50% - 19px);
			left: calc(50% - 19px);
			width: 38px;
			height: 38px;
		}

		&__ccMarketplaces {
			width: 100px;
			height: 326px;
		}
	}
}

/*----------------------------------------*/

// < 1023px
@include touch {
	.animBanner {
		padding: 1.25rem !important;
		background-position: 60% 0%;

		&__container {
			flex-direction: column;
			align-items: center;
			padding: 48px 0 !important;
			max-width: 500px !important;
		}

		&__left {
			padding: 0 !important;
			order: 2;
		}

		&__right {
			order: 1;
			padding: 1rem 0;
			width: 100%;
		}
	}
}

/*----------------------------------------*/

// < 768px
@include mobile {
	.animBanner {
		background-position: 65% 0%;

		&__left {
			margin-top: 24px;
		}

		&__ccCard {
			width: 52px;
			height: 66px;
			right: 80px;
			animation-duration: 2.5s;

			&--2 {
				animation-delay: 1.5s;
			}

			&--3 {
				animation-delay: 0.375s;
			}

			&--4 {
				animation-delay: 2s;
			}
		}

		&__ccArrows {
			width: 74px;
			height: 140px;
			left: 10px;
		}

		&__ccMarketplaces {
			width: 80px;
			height: 262px;
		}
	}
}

/*----------------------------------------*/

// < 400px
@media screen and (max-width: 400px) {
	.animBanner {
		&__ccContractHolder {
			width: 120px;
			height: 172px;
		}

		&__ccCards {
			left: 80px;
			right: 80px;
		}
	}
}
</style>
