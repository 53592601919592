import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/approvedFields.js'), 'approvedFields.js')
  resolveStoreModules(require('../store/cmsStore.js'), 'cmsStore.js')
  resolveStoreModules(require('../store/componentDataStore.js'), 'componentDataStore.js')
  resolveStoreModules(require('../store/creatorContractStore.js'), 'creatorContractStore.js')
  resolveStoreModules(require('../store/enhancedTokensStore.js'), 'enhancedTokensStore.js')
  resolveStoreModules(require('../store/marketplaceStore.js'), 'marketplaceStore.js')
  resolveStoreModules(require('../store/offerStore.js'), 'offerStore.js')
  resolveStoreModules(require('../store/profileCollectionsStore.js'), 'profileCollectionsStore.js')
  resolveStoreModules(require('../store/transactionLookupStore.js'), 'transactionLookupStore.js')
  resolveStoreModules(require('../store/trendingStore.js'), 'trendingStore.js')
  resolveStoreModules(require('../store/unlockableContentStore.js'), 'unlockableContentStore.js')
  resolveStoreModules(require('../store/userStore.js'), 'userStore.js')
  resolveStoreModules(require('../store/web3ActionsStore.js'), 'web3ActionsStore.js')
  resolveStoreModules(require('../store/web3Store.js'), 'web3Store.js')
  resolveStoreModules(require('../store/712sigs/index.js'), '712sigs/index.js')
  resolveStoreModules(require('../store/analytics/algoliaInsightsStore.js'), 'analytics/algoliaInsightsStore.js')
  resolveStoreModules(require('../store/analytics/amplitudeStore.js'), 'analytics/amplitudeStore.js')
  resolveStoreModules(require('../store/analytics/intercomStore.js'), 'analytics/intercomStore.js')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/approvedFields.js',
      '../store/cmsStore.js',
      '../store/componentDataStore.js',
      '../store/creatorContractStore.js',
      '../store/enhancedTokensStore.js',
      '../store/index.js',
      '../store/marketplaceStore.js',
      '../store/offerStore.js',
      '../store/profileCollectionsStore.js',
      '../store/transactionLookupStore.js',
      '../store/trendingStore.js',
      '../store/unlockableContentStore.js',
      '../store/userStore.js',
      '../store/web3ActionsStore.js',
      '../store/web3Store.js',
      '../store/712sigs/index.js',
      '../store/analytics/algoliaInsightsStore.js',
      '../store/analytics/amplitudeStore.js',
      '../store/analytics/intercomStore.js',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
