var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tag",
    {
      ref: "unlockable-label",
      staticClass: "is-dark is-uppercase hand-pointer",
      nativeOn: {
        click: function ($event) {
          return _vm.openUnlockableDocs.apply(null, arguments)
        },
      },
    },
    [
      _c("b-icon", {
        attrs: { icon: "lock", type: "is-light", size: "is-small" },
      }),
      _vm._v("\n  UNLOCKABLE\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }