<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">
      <div class="card-content has-padding-7">
        <div id="wert-widget"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import WertWidget from '@wert-io/widget-initializer';
import {startNotificationFlow} from './wertNotificationService';

export default {
  computed: {
    ...mapState('web3Store', [
      'notifyService'
    ])
  },
  data() {
    return {
      dismissNotification: null
    };
  },
  async mounted() {
    try {
      const wertConfig = await this.$store.dispatch('web3ActionsStore/startWertEthTopUp', {
        amount: '0.1'
      });

      const wertwidg = new WertWidget({
        container_id: 'wert-widget',
        height: 600,
        ...wertConfig
      });

      const {click_id} = wertConfig;

      let updateNotification = null;

      const sentNotification = (notificationObject) => {
        if (updateNotification) {
          updateNotification(notificationObject);
        } else {
          const {update, dismiss} = this.notifyService.notification(notificationObject);
          updateNotification = update;
          this.dismissNotification = dismiss;
        }
      };

      const stopTimer = () => {
        if (this.timer) { clearInterval(this.timer); }
      };

      wertwidg.mount();

      this.time = startNotificationFlow(this.$store, sentNotification, click_id, stopTimer);
    } catch (e) {
      console.error(e);
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.dismissNotification) {
      this.dismissNotification();
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
@import "assets/colours";
@import "node_modules/bulma/sass/utilities/initial-variables";

.item-container {
  padding: 1.25rem;
  border-color: $grey;
  border-style: solid;
  border-width: thin;
}

.selected-item {
  border-color: $green;
  border-style: solid;
  border-width: thin;
}
</style>
