var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.panelType === "artist1"
        ? _c("Artist1", { attrs: { "cms-component": _vm.cmsComponent } })
        : _vm.panelType === "artwork1"
        ? _c("ArtCarousel", { attrs: { "cms-component": _vm.cmsComponent } })
        : _vm.panelType === "editorial1"
        ? _c("EarlyAccess", { attrs: { "cms-component": _vm.cmsComponent } })
        : _vm.panelType === "editorial2"
        ? _c("Editorial2", {
            attrs: {
              "cms-component": _vm.cmsComponent,
              "is-application": false,
            },
          })
        : _vm.panelType === "editorial3"
        ? _c("CollectionHero", { attrs: { "cms-component": _vm.cmsComponent } })
        : _vm.panelType === "creatorContractBanner"
        ? _c("AnimBanner", { attrs: { content: _vm.ccContent } })
        : _vm.panelType === "contract1"
        ? _c("Contract1", { attrs: { "cms-component": _vm.cmsComponent } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }