var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.collaborators && _vm.collaborators.length >= 2
    ? _c(
        "b-tag",
        {
          staticClass: "is-dark is-uppercase hand-pointer",
          nativeOn: {
            click: function ($event) {
              return _vm.openCollectiveModal.apply(null, arguments)
            },
          },
        },
        [
          _c("b-icon", {
            staticClass: "mr-1",
            attrs: {
              icon: "account-group-outline",
              type: "is-light",
              size: "is-small",
            },
          }),
          _vm._v("COLLABORATION\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }