<template>
  <div>
    <div v-if="artist && artist.id" class="columns is-multiline is-vcentered">
      <div class="column is-12 is-5-desktop is-two-fifths-fullhd">
        <user-profile-lookup
          :large="isMostTrending"
          :artist-account="artist.id"
          :metadata-artist="artist.id"
        >
          <template slot="artist-profile-header">
            <p
              class="artist-header-text mb-1 is-uppercase"
              :class="[isMostTrending ? 'is-size-6' : 'is-size-7']"
            >
              <span>{{
                isMostTrending ? "Top trending artist" : "Trending artist"
              }}</span>
            </p>
          </template>

          <template slot="artist-profile-subtitle">
            <trending-score
              :score="artist.score"
              :address="artist.id"
              :no-padding="true"
            ></trending-score>
          </template>
        </user-profile-lookup>
      </div>
      <div class="column is-12 is-7-desktop is-three-fifths-fullhd">
        <p v-if="bio !== null" class="trending-header-paragraph">{{ bio }}</p>

        <b-skeleton v-else active height="81px" />
      </div>
    </div>

    <!-- Loading Skeleton -->
    <div v-else class="columns is-multiline is-vcentered">
      <div class="column is-12 is-5-desktop is-two-fifths-fullhd">
        <div class="media is-align-items-center">
          <div class="media-left">
            <b-skeleton
              v-if="isMostTrending"
              circle
              active
              width="128px"
              height="128px"
            />

            <b-skeleton v-else circle active width="64px" height="64px" />
          </div>

          <div class="media-content">
            <div class="content">
              <b-skeleton v-if="isMostTrending" active width="185px" />
              <b-skeleton v-else active width="100px" height="12px" />

              <b-skeleton active width="232px" height="24px" />
              <b-skeleton active width="75px" height="24px" />
            </div>
          </div>
        </div>
      </div>

      <div class="column is-12 is-7-desktop is-three-fifths-fullhd">
        <b-skeleton active height="81px" />
      </div>
    </div>

    <!-- Editions -->
    <div
      v-if="artist && artist.editions"
      class="trending-artist-editions keen-slider"
      :class="{ 'is-slider-loaded': isSliderReady }"
    >
      <div
        v-for="(edition, i) of artist.editions"
        :key="'edition' + i"
        class="trending-artist-edition-slide keen-slider__slide px-2 pt-5"
      >
        <trending-image :edition="edition" />
      </div>
    </div>
  </div>
</template>

<script>
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';

export default {
  props: ['isMostTrending', 'artist'],
  data() {
    return {
      bio: null,
      slider: null,
      isSliderReady: false
    };
  },
  watch: {
    // eslint-disable-next-line
    artist: function () {
      this.setBio();
    }
  },
  mounted() {
    this.setBio();

    this.slider = new KeenSlider(
      this.$el.querySelector('.trending-artist-editions'),
      {
        slides: {
          perView: 3
        },
        breakpoints: {
          '(max-width: 768px)': {
            slides: {
              perView: 1
            }
          }
        },
        created: () => {
          this.isSliderReady = true;

          setTimeout(() => {
            this.slider.container.addEventListener('wheel', this.eventWheel, {
              passive: false
            });
          }, 100);
        }
      }
    );
  },
  methods: {
    setBio() {
      if (!this.artist) {
        return;
      }

      this.$store
        .dispatch('userStore/getUserBySlugOrAddress', this.artist.id)
        .then((profile) => {
          const bio = profile.bio;
          if (bio) {
            this.bio = bio;
          }
        });
    },
    dispatch(e, name) {
      this.position.x -= e.deltaX;
      this.position.y -= e.deltaY;
      this.slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: this.position.x,
            y: this.position.y
          }
        })
      );
    },
    wheelStart(e) {
      this.position = {
        x: e.pageX,
        y: e.pageY
      };
      this.dispatch(e, 'ksDragStart');
    },
    wheel(e) {
      this.dispatch(e, 'ksDrag');
    },
    wheelEnd(e) {
      this.dispatch(e, 'ksDragEnd');
    },
    eventWheel(e) {
      if (e.deltaX !== 0) {
        e.preventDefault();
      }
      if (!this.wheelActive) {
        this.wheelStart(e);
        this.wheelActive = true;
      }
      this.wheel(e);
      clearTimeout(this.touchTimeout);
      this.touchTimeout = setTimeout(() => {
        this.wheelActive = false;
        this.wheelEnd(e);
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.trending-header-paragraph {
  min-height: 81px;
  font-size: 1.125rem;
  opacity: 0.7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.artist-header-text {
  line-height: 1 !important;
  opacity: 0.5;
}

.trending-artist-editions {
  width: calc(100% + 1rem) !important;
  margin: 0 -0.5rem;
}

@media screen and (max-width: 1024px) {
  .trending-header-paragraph {
    min-height: 0;
  }
}

@media screen and (max-width: 768px) {
  .trending-artist-editions {
    width: 100% !important;
    overflow: visible !important;

    &:not(.is-slider-loaded) .trending-artist-edition-slide:not(:first-child) {
      display: none;
    }
  }
}
</style>
