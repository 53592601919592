<template>
  <div class="columns is-multiline" v-if="tokens && tokens.length > 0">
    <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card" v-for="token in filteredTokens" :key="token.id">
      <token-card
        :token="token"
        :cover="true"
        resolution="card"
        class="has-margin-bottom-4"
      ></token-card>
    </div>
  </div>

  <div v-else>
    <empty-state message="No collection"></empty-state>
  </div>
</template>
<script>

import {mapState} from 'vuex';
import EmptyState from '../common/EmptyState';
import {TOKENS_WITH_INCLUDE} from '../../queries/tokenQueries';
import TokenCard from '../TokenCard';

export default {
  components: {TokenCard, EmptyState},
  props: ['ids', 'profile'],
  data() {
    return {
      tokens: []
    };
  },
  computed: {
    ...mapState('web3Store', ['gqlClient']),
    filteredTokens() {
      return this.tokens.filter(item => this.ids.includes(item.id))
    }
  },
  async mounted() {
    this.$apollo.addSmartQuery('tokens', {
      client: this.gqlClient,
      fetchPolicy: 'cache-and-network',
      query() {
        return TOKENS_WITH_INCLUDE([], false);
      },
      variables() {
        return this.buildTokenQueryParams();
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    buildTokenQueryParams(overrides = {}) {
      return {
        owner: this.profile && this.profile.address && this.profile.address.toLowerCase(),
        first: 999,
        skip: 0,
        ...overrides
      };
    }
  }
}
</script>
