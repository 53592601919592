<template>
  <div class="columns is-multiline" v-if="likes && likes.length > 0">
    <div class="column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card" v-for="(like, $index) in likes" :key="$index">
      <edition-wrapper :id="like.edition" :cover="true" resolution="card" size="is-small"></edition-wrapper>
    </div>

    <div class="column is-fullwidth has-text-centered" v-if="likes.length < totalAvailable">
      <b-button type="is-primary" outlined @click="loadFavourites">Show more</b-button>
    </div>
  </div>

  <div v-else>
    <empty-state message="No favourites"></empty-state>
  </div>
</template>
<script>

import {mapState} from 'vuex';
import EditionWrapper from '../EditionWrapper';
import EmptyState from '../common/EmptyState';

export default {
  components: {EmptyState, EditionWrapper},
  data() {
    return {
      likes: [],
      limit: 50,
      offset: 0,
      resultsSize: 0,
      totalAvailable: 0
    };
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ])
  },
  props: ['address'],
  async mounted() {
    this.loadFavourites();
  },
  methods: {
    loadFavourites() {
      return this.$api.$get(`/network/${this.chainId}/likes/address/${this.address}?limit=${this.limit}&offset=${this.offset}`)
        .then(({likes, resultsSize, totalAvailable, limit, offset}) => {
          this.limit = limit;
          this.offset = offset + limit;
          this.totalAvailable = totalAvailable;
          this.resultsSize = resultsSize;
          this.likes = this.likes.concat(likes);
        });
    }
  }
}
</script>
