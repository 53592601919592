<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">

      <div class="card-content has-padding-7">

        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <h2 style="margin-bottom: 5px">
              Claim Royalties
            </h2>
            <h5 class="mb-5">
              Have you received any royalties from OpenSea sales?
            </h5>

            <section class="mt-4 mb-4"
                     v-if="royalties && royalties.merkleProofAndIndex && parseFloat(royalties.amountInEth) > 0.0 && !royalties.fundsClaimed">

              <b-message type="is-info" :closable="false" :duration="0">
                You have
                <eth-with-fiat-price :price-in-eth="royalties.amountInEth" :show-fiat="true" :override-dp="4"
                                     class="has-text-weight-bold">
                </eth-with-fiat-price>
                of unclaimed royalties.
              </b-message>
            </section>

            <section class="mt-4 mb-4"
                     v-else-if="royalties && royalties.merkleProofAndIndex && parseFloat(royalties.amountInEth) > 0.0 && royalties.fundsClaimed">
              <b-message type="is-light" :closable="false" :duration="0">
                You have already claimed
                <eth-with-fiat-price :price-in-eth="royalties.amountInEth" :show-fiat="true" :override-dp="4"
                                     class="has-text-weight-bold">
                </eth-with-fiat-price>
                of royalties.
              </b-message>
            </section>

            <section class="mt-4 mb-4" v-else-if="royalties">
              <b-message type="is-info" :closable="false" :duration="0">
                You have no unclaimed royalties.
              </b-message>
            </section>

            <section class="mt-4 mb-4" v-else>
              <b-message type="is-info" :closable="false" :duration="0">
                Loading royalties...
              </b-message>
            </section>

            <section class="mt-4 is-size-7">
              <p>
                You can claim existing funds from the Vault, but as of 12th of May 2023 we no longer capture any new royalties.
                <a href="https://knownorigin.io/journal/cms/stories/vault-update-and-our-policy-on-royalties" class="is-linkable" target="_blank">
                  Learn more.
                </a>
              </p>
              <p>
                Note: You will need to perform an on-chain transaction to claim OpenSea royalties.
              </p>
            </section>
          </section>

          <section v-if="transactionHash" class="level is-mobile pt-2">
            <div class="level-left">
              <div class="level-item">
                <b-icon icon="check-circle-outline" size="is-small"></b-icon>
                &nbsp;
                <span class="is-uppercase is-size-7">
                    Claiming royalties
                </span>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <a class="is-muted-7 is-family-tertiary" :href="etherscanTxLink(transactionHash)" target="_blank">
                  [view tx]
                </a>
              </div>
            </div>
          </section>

        </div>

        <div class="card-footer">

          <div class="card-footer-item">
            <b-button expanded
                      @click="close">
              Nevermind
            </b-button>
          </div>

          <div class="card-footer-item">
            <b-button class="button is-primary"
                      expanded
                      @click="claim"
                      :disabled="!royalties || !royalties.merkleProofAndIndex || parseFloat(royalties.amountInEth) === 0.0 || royalties.fundsClaimed || transactionHash">
              Claim
            </b-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import EthWithFiatPrice from '../common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice},
  props: ['profile'],
  data() {
    return {
      transactionHash: null,
      royalties: null
    };
  },
  computed: {
    ...mapGetters('web3Store', [
      'etherscanTxLink',
      'etherscanAddressLink'
    ])
  },
  async mounted() {
    this.royalties = await this.$store.dispatch('userStore/checkOpenSeaRoyalties');
  },
  methods: {
    close() {
      this.$emit('close');
    },
    selectCharity(charity) {
      this.form.selectedCharity = charity;
    },
    async claim() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/claimRoyaltiesFromMerkleVault', this.royalties.merkleProofAndIndex);

        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        this.royalties = await this.$store.dispatch('userStore/checkOpenSeaRoyalties');
        this.close(); // close the model
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
