import gql from 'graphql-tag';
import {METADATA_FIELDS} from './_commonModels';
import {EDITION_FIELDS} from './editionQueries';

export const ARTIST_FIELDS = `
  address
  totalValueInEth
  highestSaleValueInEth
  salesCount
  issuedCount
  supply
  firstEditionTimestamp
  lastEditionTimestamp
  editionsCount
  firstEditionTimestamp
`;

export const ARTIST_STATS = gql(`
  query artistStats($address: ID!) {
    artistStats: artist(id: $address subgraphError: allow) {
      address
      totalValueInEth
      highestSaleValueInEth
      salesCount
      issuedCount
      supply
      firstEditionTimestamp
      lastEditionTimestamp
      editionsCount
      firstEditionTimestamp
    }
  }
`);

export const GET_ARTIST_SALES_HISTORY = gql`
  query GetCreatorActivity($artist:String!){
    salesHistory: activityEvents(
      where: {
        creator: $artist,
        eventType_in: ["Purchase", "BidAccepted"]
      }
      orderBy: timestamp,
      orderDirection:"desc"
      first: 1000
      subgraphError: allow) {
      id
      edition {
        id
        artistAccount
        collaborators
        ${METADATA_FIELDS}
      }
      token {
        id
      }
      type
      eventType
      eventValueInWei
      transactionHash
      creator
      creatorCommission
      buyer
      timestamp
    }
  }
`

export const USER_STATS = gql`
  query artistStats($address: ID!) {
    artistStats: artist(id: $address subgraphError: allow) {
      address
      totalValueInEth
      highestSaleValueInEth
      firstEditionTimestamp
      salesCount
      issuedCount
      supply
      editionsCount
    },
    artistEditions: editions(where:{artistAccount: $address} orderBy:createdTimestamp orderDirection:asc first: 1 subgraphError: allow){
      id
      createdTimestamp
    }
    collectorStats: collectors(where:{id:$address} subgraphError: allow) {
      id
      totalPurchaseCount
      totalPurchaseEthSpent
      firstSeen
    },
    ownedTokens: tokens(where:{currentOwner:$address}, first: 1000 subgraphError: allow){
      id
    }
  }
`;

export const ALL_TRANSFER_FOR_ARTIST_EDITIONS = gql(`
  query allArtistEditionTransfers($artistAccount:String!) {
    editions(where:{artistAccount:$artistAccount} subgraphError: allow) {
      id
      createdTimestamp
      transfers {
        id
        from
        to
        tokenId
        timestamp
        transactionHash
      }
      ${METADATA_FIELDS}
    }
  }`);

export const LATEST_ARTWORK_OG_ARTISTS = gql(`query latestArtworkOgArtists($first: Int!) {
    latestArtworkOgArtists: artists(first: $first, orderBy: lastEditionTimestamp, orderDirection: desc, where: {firstEditionTimestamp_lt: 1629691256} subgraphError: allow) {
    address
    name
    editions (orderBy: createdTimestamp, orderDirection: desc) {
      ${EDITION_FIELDS}
    }
  }
}`);

export const OG_ARTISTS = gql(`query ogArtists($first: Int!) {
    ogArtists: artists(first: $first, orderBy: lastEditionTimestamp, orderDirection: desc, where: {firstEditionTimestamp_lte: 1629691256, editionsCount_gt: 0} subgraphError: allow) {
    ${ARTIST_FIELDS}
  }
}`);
