var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section p-0 mb-6" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content has-background-light mb-6" }, [
      _c(
        "div",
        { staticClass: "section container" },
        [
          _c(
            "div",
            { staticClass: "columns is-hidden-mobile" },
            [
              _vm.editions
                ? _vm._l(_vm.editions, function (el, i) {
                    return _c(
                      "div",
                      {
                        key: el + i + 100,
                        staticClass: "editorial-cards column p-1",
                      },
                      [
                        _c("EditionImage", {
                          attrs: { edition: { id: el.toString() } },
                        }),
                      ],
                      1
                    )
                  })
                : _vm._l(6, function (el, i) {
                    return _c(
                      "div",
                      {
                        key: el + i,
                        staticClass: "editorial-cards column p-1",
                      },
                      [
                        _c("img", {
                          attrs: { src: "/branding/bigloader-image.svg" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          attrs: {
                            animated: true,
                            height: "12px",
                            width: "140px",
                          },
                        }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "container editorial-cards editorial-cards-slider keen-slider is-hidden-tablet",
              class: { "is-slider-loaded": _vm.isSliderReady },
            },
            _vm._l(_vm.editions, function (el, i) {
              return _c(
                "div",
                {
                  key: el + i + 300,
                  staticClass: "slider-card py-0 keen-slider__slide",
                },
                [
                  _c("EditionImage", {
                    attrs: { edition: { id: el.toString() } },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.isSliderReady
            ? [
                _c(
                  "div",
                  {
                    staticClass: "editorial-cards column p-1 is-hidden-tablet",
                  },
                  [
                    _c("img", {
                      attrs: { src: "/branding/bigloader-image.svg" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      attrs: { animated: true, height: "12px", width: "140px" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "editorial-content content px-0 pt-5 mt-1 columns is-multiline has-text-centered",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "columns is-multiline is-12 is-flex is-flex-direction-column is-align-items-center",
                },
                [
                  _c("div", {}, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "is-size-7 p-0 has-text-weight-light column is-uppercase",
                      },
                      [
                        _c("img", {
                          staticClass: "editorial-one-logo",
                          attrs: { src: "/branding/KO-Icon-Blue.svg" },
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.cmsComponent.subtitle) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "title is-size-3 has-text-weight-black mt-2 mb-0",
                      },
                      [_vm._v(_vm._s(_vm.cmsComponent.title))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _c("p", { staticClass: "editorial-description pt-0" }, [
                      _vm._v(_vm._s(_vm.cmsComponent.description)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.cmsComponent.buttonLink
                    ? _c(
                        "div",
                        {
                          staticClass: "pt-2 mb-5",
                          on: {
                            click: function ($event) {
                              return _vm.handleButtonClickedEvent(
                                _vm.cmsComponent.buttonLink
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "NuxtLink",
                            {
                              staticClass: "button is-primary p-5",
                              attrs: { to: _vm.cmsComponent.buttonLink },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.cmsComponent.buttonLabel) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "editorial-top-banner content p-5" }, [
      _c(
        "h1",
        {
          staticClass:
            "editorial-header py-6 has-text-white is-size-3-tablet is-size-5-mobile has-text-centered is-family-secondary",
        },
        [_vm._v("\n      Discover, sell and collect NFT art\n    ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }