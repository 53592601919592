<template>
  <div class="level is-mobile box has-background-dark has-text-light">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Token</p>
        <span v-for="(token, i) in populatedDetails" :key="i">
          <p class="has-text-weight-bold">{{ token.symbol }}</p>
          <br v-if="i !== tokens.length -1">
        </span>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Per edition</p>
        <span class="has-text-weight-bold" v-for="(token, i) in populatedDetails" :key="i">
          <p>{{ token.count }}</p>
          <br v-if="i !== populatedDetails.length -1">
        </span>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">USD Value</p>
        <span class="has-text-weight-bold" v-for="(token, i) in populatedDetails" :key="i">
          <p v-if="token.usdPrice">
          ${{ token.usdPrice | to2Dp }}
        </p>
        <p v-else>n/a</p>
          <br v-if="i !== populatedDetails.length -1">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _map from 'lodash/map';

export default {
  props: ['tokens'],
  data() {
    return {
      populatedDetails: []
    };
  },
  watch: {
    tokens(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.populateDetails();
      }
    }
  },
  async mounted() {
    await this.populateDetails();
  },
  methods: {
    async populateDetails() {
      try {
        this.populatedDetails = await Promise.all(_map(this.tokens, async (token) => {
          const details = await this.$store.dispatch('enhancedTokensStore/getErc20TokenDetailsAndPrice', { item: token });
          return { ...token, ...details };
        }));
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
