<template>
  <div>
    <section class="content">
      <div v-if="contracts && contracts.length > 0" class="columns is-multiline">
        <div
          v-for="contract in contracts"
          :key="contract.id"
          class="column is-half-tablet is-4-desktop is-3-widescreen is-one-fifth-fullhd"
        >
          <creator-contract-card :contract="contract"></creator-contract-card>
        </div>
      </div>
      <div v-else>
        <empty-state message="No contracts created"></empty-state>
      </div>
    </section>
  </div>
</template>

<script>
import CreatorContractCard from '../../avatar/CreatorContractCard';
import EmptyState from '../../common/EmptyState';

export default {
  components: {
    CreatorContractCard,
    EmptyState
  },
  props: ['contracts']
}
</script>
