<template>
  <modal-template :edition="edition" :transaction-hash="null">

    <template slot="content-title">
      Create unlockable content for your artwork
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <section>
        <b-field label="Unlockable link"
                 message="You are responsible for maintaining the unlockable content, please ensure it is accessible.">
          <b-input v-model="form.link"
                   placeholder="https://..."
                   type="text"
                   expanded
                   autofocus
                   required>
          </b-input>
        </b-field>
        <b-field label="Message" message="">
          <b-input maxlength="1000"
                   type="textarea"
                   v-model="form.content"
                   required>
          </b-input>
        </b-field>
        <p>
          <strong>Unlockable content can only be accessed by token holders of this edition.</strong>
        </p>
        <p>
          Further details on how the unlockable feature works can be found
          <a href="https://docs.knownorigin.io/en/articles/6118698-unlockable-content" target="_blank" class="is-linkable">
            here
          </a>
        </p>
        <p v-if="response && response.success">
          Unlockable content saved
          <b-icon icon="check-circle-outline" size="is-small"></b-icon>
        </p>
        <p class="has-text-danger" v-if="error">
          Content not saved!
        </p>
      </section>
    </div>

    <template slot="content-balance">&nbsp;</template>

    <template slot="action-feedback-label">
      Unlockable content set!
    </template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :loading="loading"
                :disabled="!formComplete"
                @click="setUnlockableContent">
        Confirm
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import _size from 'lodash/size';
import ModalTemplate from '../../ModalTemplate';
import ArtworkNameAndArtist from '../../../ArtworkNameAndArtist';
const xss = require('xss');

function isValidHttpsUrl(string) {
  try {
    return new URL(string).protocol === 'https:';
  } catch (_) {
    return false;
  }
}

export default {
  components: {ArtworkNameAndArtist, ModalTemplate},
  props: ['edition'],
  data() {
    return {
      response: null,
      error: null,
      loading: false,
      form: {
        content: null,
        link: null
      }
    };
  },
  computed: {
    formComplete() {
      return _size(this.form.content) >= 5 && isValidHttpsUrl(this.form.link);
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async setUnlockableContent() {
      this.loading = true;
      this.error = false;
      try {
        this.response = await this.$store.dispatch('unlockableContentStore/setUnlockableContent', {
          koda_version: this.edition.version,
          artwork_id: this.edition.id,
          content_message: xss(this.form.content),
          content_link: xss(this.form.link),
          date_signed: Date.now().toString()
        });

        if (this.response && this.response.success) {
          await this.$store.dispatch('unlockableContentStore/hasUnlockableContent', {
            editionId: this.edition.id,
            refresh: true
          });
          setTimeout(this.close, 1500);
        } else {
          this.error = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
