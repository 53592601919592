var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c(
        "template",
        { slot: "content-title" },
        [
          _vm._v("\n    Buy now for\n    "),
          _c("eth-with-fiat-price", {
            attrs: { "price-in-eth": _vm.token.listPrice },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.listAmountToArtist
        ? _c(
            "template",
            { slot: "secondary-content" },
            [
              _c(
                "b-message",
                {
                  staticClass: "is-size-7",
                  attrs: { type: "is-info", closable: false, duration: 0 },
                },
                [
                  _vm._v("\n      12.5% (\n      "),
                  _c("eth-with-fiat-price", {
                    attrs: { "price-in-eth": _vm.listAmountToArtist },
                  }),
                  _vm._v(
                    "\n      ) of this sale will be paid to the original artist(s)\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Buying\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary", expanded: "" },
              on: { click: _vm.buyNow },
            },
            [_vm._v("\n      Buy now\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button-nevermind" },
        [
          _c(
            "b-button",
            {
              attrs: { expanded: "" },
              on: {
                click: function ($event) {
                  return _vm.close(false)
                },
              },
            },
            [_vm._v("\n      Nevermind\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }