<template>
  <span v-if="hasUnlockableContent(editionNumber)">
    <span style="position: absolute; bottom: 1rem; left: 1rem; z-index: 10;" class="hand-pointer">
      <b-tooltip position="is-top" class="ml-1" type="is-dark" label="Includes unlockable content">
        <b-tag class="is-dark is-uppercase hand-pointer" ref="unlockable-label">
          <b-icon icon="lock" type="is-light" size="is-small"></b-icon>
        </b-tag>
      </b-tooltip>
    </span>
  </span>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  components: {},
  props: ['editionNumber'],
  computed: {
    ...mapGetters('unlockableContentStore', [
      'hasUnlockableContent'
    ])
  },
  mounted() {
    this.loadUnlockableContent();
  },
  methods: {
    async loadUnlockableContent() {
      // load any unlockable content
      await this.$store.dispatch('unlockableContentStore/hasUnlockableContent', {
        editionId: this.editionNumber
      });
    }
  }
};
</script>
