<template>
  <section class="artCarousel">
    <div class="section container">
      <div class="tile is-child">
        <div class="columns content is-flex is-vcentered is-mobile">
          <div class="column is-half-mobile is-two-thirds-tablet is-flex is-align-items-center">
            <h2 class="artCarousel__title title is-size-3-desktop is-size-5-mobile mr-5">{{ cmsComponent.title }}</h2>
            <button class="artCarousel__btn is-hidden-mobile" @click="slidePrev" v-if="trendingContracts">
              <b-icon icon="arrow-left" />
            </button>
            <button class="artCarousel__btn is-hidden-mobile" @click="slideNext" v-if="trendingContracts">
              <b-icon icon="arrow-right" />
            </button>
          </div>
          <div v-if="cmsComponent.viewMoreLabel && cmsComponent.viewMoreLink" class="column is-half-mobile is-one-third-tablet has-text-right" @click="handleViewMoreClick(cmsComponent.viewMoreLink)">
            <NuxtLink :to="cmsComponent.viewMoreLink" class="is-outlined is-size-6-mobile component-view-more-link">
              <b-button type="is-secondary">{{ cmsComponent.viewMoreLabel }}</b-button>
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="artCarousel__sliderHolder mt-3">
        <div v-if="trendingContracts" :class="{ 'artCarousel__slider--loaded': sliderLoaded }" class="artCarousel__slider">
          <div v-for="contract in trendingContracts" :key="contract.id" class="artCarousel__slide">
            <div class="artCarousel__card">
                <creator-contract-card :contract="contract" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
import KeenSlider from 'keen-slider'

import { GET_LATEST_CONTRACTS, GET_MOST_SOLD_CONTRACTS, GET_MOST_ETH_SOLD_CONTRACTS } from '../../../queries/creatorContracts'

export default {
    props: ['cmsComponent'],
    data() {
      return {
      slider: null,
      sliderLoaded: false,
      totalSlides: 0,
      slidesToChange: 1,
      trendingContracts: null,
      touchTimeout: null,
      position: null,
      wheelActive: null
    }
    },
    computed: {
        parseQuery() {
            if (this.cmsComponent.query === 'topSoldEthContracts') {
                return GET_MOST_ETH_SOLD_CONTRACTS
            } else if (this.cmsComponent.query === 'topSoldContracts') {
                return GET_MOST_SOLD_CONTRACTS
            } else {
                return GET_LATEST_CONTRACTS
            }
        }
    },
     mounted() {
      const THAT = this
      // when element is in view, load data once
      this.observer = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          const contracts = await this.loadCreatorContracts(this.cmsComponent.query)
          this.totalSlides = contracts.length
          this.trendingContracts = contracts
          // hacky way of loading the data and then the carousel
          setTimeout(() => { THAT.createSlider() }, 10)
          this.observer.unobserve(this.$el)
        }
      })
      this.observer.observe(this.$el)

      //
      this.updateSlidesToChange()

      //
      this.handleResizeListener = debounce(this.updateSlidesToChange.bind(this), 500)
      window.addEventListener('resize', this.handleResizeListener)
  },
  destroyed() {
    if (this.slider) {
      this.slider.destroy()
      this.slider.container.removeEventListener('wheel', this.eventWheel)
    }
    window.removeEventListener('resize', this.handleResizeListener)
  },
    methods: {
        async loadCreatorContracts(query) {
          const data = await this.$apollo.query({
          query: this.parseQuery,
          variables: {
                first: 8
            }
        });
        return data.data.trendingContracts
        },
        dispatch(e, name) {
      this.position.x -= e.deltaX
      this.position.y -= e.deltaY
      this.slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: this.position.x,
            y: this.position.y
          }
        })
      )
    },
    wheelStart(e) {
      this.position = {
        x: e.pageX,
        y: e.pageY
      }
      this.dispatch(e, 'ksDragStart')
    },
    wheel(e) {
      this.dispatch(e, 'ksDrag')
    },
    wheelEnd(e) {
      this.dispatch(e, 'ksDragEnd')
    },
    eventWheel(e) {
      if (e.deltaX !== 0) {
        e.preventDefault()
      }
      if (!this.wheelActive) {
        this.wheelStart(e)
        this.wheelActive = true
      }
      this.wheel(e)
      clearTimeout(this.touchTimeout)
      this.touchTimeout = setTimeout(() => {
        this.wheelActive = false
        this.wheelEnd(e)
      }, 50)
    },
    createSlider() {
      const THAT = this
      this.slider = new KeenSlider(this.$el.querySelector('.artCarousel__slider'), {
        selector: '.artCarousel__slide',
        slides: {
          perView: 4,
          spacing: 20
        },
        breakpoints: {
            '(max-width: 1280px)': {
              slides: {
                perView: 3,
                spacing: 20
              }
            },
            '(max-width: 868px)': {
              slides: {
                perView: 2,
                spacing: 20
              }
            },
            '(max-width: 576px)': {
              slides: {
                perView: 1,
                spacing: 20
              }
            }
          },
        created() {
          THAT.sliderLoaded = true
          // add scroll wheel transitions, once this.slider exists
          setTimeout(() => {
            THAT.slider.container.addEventListener('wheel', THAT.eventWheel, {
              passive: false
            })
          }, 100)
        }
      })
    },
    updateSlidesToChange() {
      this.slidesToChange = 1
      if (window.innerWidth >= 576 && window.innerWidth < 868) {
        this.slidesToChange = 2
      } else if (window.innerWidth >= 868 && window.innerWidth < 1280) {
        this.slidesToChange = 3
      } else if (window.innerWidth >= 1280) {
        this.slidesToChange = 4
      }
    },
    slidePrev() {
      const current = this.slider.track.details.abs
      let moveTo = current - this.slidesToChange

      if (current < this.slidesToChange) {
        moveTo = 0
      }

      this.slider.moveToIdx(moveTo)
    },
    slideNext() {
      const current = this.slider.track.details.abs
      const threshold = this.totalSlides - this.slidesToChange - (this.slidesToChange - 1)
      let moveTo = current + this.slidesToChange

      if (current >= threshold) {
        moveTo = this.totalSlides - this.slidesToChange
      }

      this.slider.moveToIdx(moveTo)
    }
  }
}
</script>

<style lang="scss">
.artCarousel {
  overflow: hidden;

  .tile {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin-bottom: 0 !important;
  }

  &__btn {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    padding: 0;
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    outline: 0;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;

    &:hover {
      border-color: #b5b5b5;
    }

    &:active {
      border-color: #4a4a4a;
    }
  }

  &__sliderHolder {
    position: relative;
    z-index: 1;
  }

  &__slider {
    display: flex;
    align-content: flex-start;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 56px 0;
    margin-top: -56px;
    visibility: hidden;
    position: absolute;

    &--loaded {
      visibility: visible;
      position: relative;
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 20px);
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    min-height: 100%;
  }

  &__card {
    transition: transform .2s ease;

    &:hover {
      transform: translateY(-8px);
    }
  }
}

.carouselSkeleton {
  display: grid;
  gap: 40px;
  padding: 0 20px 56px;
  position: relative;
  margin: 0 -20px;

  &::after {
    content: '';
    position: absolute;
    left: calc(100% + 20px);
    width: 33.333%;
    display: block;
    top: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0em 2.5em 0.75em rgba(10, 10, 10, 0.1);
  }
}
</style>
