var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "container section push-up-homepage-mobile" },
      [
        _c("div", { staticClass: "tile is-child" }, [
          _c("div", { staticClass: "columns content is-flex is-vcentered" }, [
            _c(
              "h2",
              {
                staticClass:
                  "column title is-size-3-desktop is-size-5-mobile is-quarter-tablet is-half-mobile",
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.cmsComponent.title) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-mobile is-centered" }, [
              _vm.componentData
                ? _c(
                    "div",
                    {
                      staticClass:
                        "column is-half is-justify-content-center is-flex",
                    },
                    [
                      _c("b-button", {
                        staticClass:
                          "header-style arrow-buttons is-hidden-mobile is-hidden-tablet-only",
                        attrs: {
                          disabled: _vm.disableDecrement,
                          "icon-left": "arrow-left-circle-outline ",
                        },
                        on: { click: _vm.decrementCarousel },
                      }),
                      _vm._v(" "),
                      _c("b-button", {
                        staticClass:
                          "header-style arrow-buttons is-hidden-mobile is-hidden-tablet-only",
                        attrs: {
                          disabled: _vm.disableIncrement,
                          "icon-left": "arrow-right-circle-outline",
                        },
                        on: { click: _vm.incrementCarousel },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "column is-quarter-tablet is-half-mobile has-text-right mb-5",
                on: {
                  click: function ($event) {
                    return _vm.handleViewMoreClick(
                      _vm.cmsComponent.viewMoreLink
                    )
                  },
                },
              },
              [
                _c(
                  "n-link",
                  {
                    staticClass:
                      "is-outlined is-size-6-mobile component-view-more-link",
                    attrs: { to: _vm.cmsComponent.viewMoreLink },
                  },
                  [
                    _c("b-button", { attrs: { type: "is-secondary" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.cmsComponent.viewMoreLabel) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.componentData
          ? _c("b-carousel-list", {
              staticClass: "carousel",
              attrs: {
                data: _vm.componentData,
                "items-to-show": _vm.perSlide,
                "items-to-list": _vm.perSlide,
                arrow: false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item",
                    fn: function (edition) {
                      return [
                        _vm.cmsComponent.cardHeader
                          ? _c(
                              "div",
                              {
                                staticClass: "level is-mobile tighten-heading",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "level-left has-text-weight-semibold ml-5",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.mapEvent(edition.eventType)
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "level-right has-text-weight-light is-size-6 mr-5",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "uppercase" },
                                      [
                                        _c("date-from", {
                                          attrs: {
                                            "timestamp-in-secs":
                                              edition.timestamp,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "carousel-card" },
                          [
                            _vm.checkIsToken(edition)
                              ? _c(
                                  "token-card",
                                  {
                                    attrs: {
                                      token: edition.token
                                        ? edition.token
                                        : edition,
                                      cover: true,
                                      resolution: "card",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleCardClickedEvent(
                                          edition
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "asset-card-footer-left-title" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "has-text-left" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.mapActivityEventTitle(
                                                    edition.eventType
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "asset-card-footer-left" },
                                      [
                                        edition.eventValueInWei
                                          ? _c("eth-with-fiat-price", {
                                              staticClass:
                                                "has-text-weight-semibold has-text-left",
                                              attrs: {
                                                "price-in-wei":
                                                  edition.eventValueInWei,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                )
                              : _c("edition-card", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    edition: edition.edition
                                      ? edition.edition
                                      : edition,
                                    "disable-icons": true,
                                    cover: true,
                                    resolution: "card",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleCardClickedEvent(edition)
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2620783553
              ),
              model: {
                value: _vm.carouselValue,
                callback: function ($$v) {
                  _vm.carouselValue = $$v
                },
                expression: "carouselValue",
              },
            })
          : _c(
              "div",
              {
                staticClass: "carouselSkeleton",
                style: { gridTemplateColumns: `repeat(${_vm.perSlide}, 1fr)` },
              },
              _vm._l(_vm.perSlide, function (el) {
                return _c(
                  "div",
                  { key: el, staticClass: "carouselSkeleton__item" },
                  [_c("EditionCardSkeleton")],
                  1
                )
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }