<template>
  <aside class="content scrollable-content is-family-tertiary">
    <ul>
      <content-auth-label v-if="editionData && editionData.lowResImg"
                          :edition-id="editionId"
                          :image-url="editionData.lowResImg"
                          :minter="editionData.artistAccount">
      </content-auth-label>
      <li v-if="editionData">
        <div class="columns is-mobile">
          <div class="column heading">
            <image-metadata :edition="editionData"></image-metadata>
          </div>
        </div>
      </li>
      <li>
        <div class="columns is-mobile">
          <div class="column heading">ERC721 token standard</div>
        </div>
      </li>
      <li v-if="mappedTokenMetadata">
        <div class="columns is-mobile">
          <div class="column heading"><a :href="mappedTokenMetadata" target="_blank">View Metadata</a></div>
        </div>
      </li>
      <li v-if="viewTokenOnIPFS">
        <div class="columns is-mobile">
          <div class="column heading"><a :href="viewTokenOnIPFS" target="_blank">View media on IPFS</a></div>
        </div>
      </li>
      <li v-if="version">
        <div class="columns is-mobile">
          <div class="column heading">Version {{ version }}</div>
        </div>
      </li>
      <li v-if="salesType">
        <div class="columns is-mobile">
          <div class="column heading">{{ mapSalesType(salesType) }}</div>
        </div>
      </li>

    </ul>
  </aside>
</template>

<script>
import {mapState} from 'vuex';
import ImageMetadata from '../ImageMetadata';
import {mapSalesType} from '../../services/SaleTypes';
import ContentAuthLabel from '../common/cai/ContentAuthLabel';

const {ipfsUtils} = require('@knownorigin/ko-token-uri-tools');

export default {
  components: {ContentAuthLabel, ImageMetadata},
  props: ['editionId', 'tokenURI', 'version', 'salesType', 'metadata'],
  data() {
    return {
      editionData: null
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    mappedTokenMetadata() {
      if (this.tokenURI) {
        return ipfsUtils.buildPinataLookupUri(this.tokenURI);
      }
      return false;
    },
    viewTokenOnIPFS() {
      if (this.metadata.animation_url || this.metadata.image) {
        return ipfsUtils.buildPinataLookupUri(this.metadata.animation_url || this.metadata.image);
      }
      return false;
    }
  },
  async mounted() {
    try {
      this.editionData = await this.$cacheApi.$get(`/network/${this.chainId}/edition/${this.editionId}/lookup/image/metadata`);
    } catch (e) {
      console.log('Problem with API', e);
    }
  },
  methods: {
    mapSalesType
  }
};
</script>
