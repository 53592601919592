<template>
    <section>
        <section>
            <h3></h3>
            <b-field :type="{ 'is-danger': dobError, 'is-success': dobSuccess }" :message="errorMessage"
                :label="requiredFieldsList.includes('dob') ? 'Date of birth *' : 'Date of birth'">
                <b-input :value="formattedDOB" @input="inputChanged" placeholder="DD/MM/YYYY" />
                <b-datepicker v-model="dobData" :mobile-native="false" class="manage-profile__picker"
                    :years-range="[-100, 30]" @input="datePickerChanged" :max-date="maxDate">
                    <template #trigger>
                        <b-button icon-left="calendar-today" type="is-primary" />
                    </template>
                </b-datepicker>
            </b-field>
        </section>
    </section>
</template>

<script>

export default {
    props: {
        dob: Date,
        requiredFieldsList: Array
    },
    data() {
        return {
            dobData: this.dob,
            dobError: false,
            dobSuccess: false,
            errorMessage: ''
        }
    },
    computed: {
        formattedDOB() {
            if (this.dobData) {
                const day = this.dobData.getDate();
                const month = this.dobData.getMonth() + 1;
                const year = this.dobData.getFullYear();
                return `${day}/${month}/${year}`
            } else {
                return null;
            }
        },
        maxDate() {
            return new Date()
        }
    },
    methods: {
        inputChanged(val) {
            const date = this.parseDate(val);
            this.valueInputted(date, val)
        },
        datePickerChanged(date) {
            this.valueInputted(date, true)
        },
        valueInputted(date, val) {
            if (date === null && this.requiredFieldsList.includes('dob')) {
                this.dobError = true;
                this.dobSuccess = false;
                this.isValid(false)
                this.errorMessage = 'Date of birth is required, please use the format DD/MM/YYYY'
            } else if (date === null && !val && !this.requiredFieldsList.includes('dob')) {
                this.dobError = false;
                this.dobSuccess = false;
                this.isValid(true)
                this.errorMessage = ''
            } else if (date === null && val && !this.requiredFieldsList.includes('dob')) {
                this.dobError = true;
                this.dobSuccess = false;
                this.isValid(false)
                this.errorMessage = 'Please use the format DD/MM/YYYY'
            } else if (!this.isUserOver18(date)) {
                this.dobError = true;
                this.dobSuccess = false;
                this.isValid(false)
                this.errorMessage = 'You must be over 18 to use Known Origin'
            } else {
                this.isValid(true)
                this.dobData = date;
                this.dobSuccess = true;
                this.dobError = false;
                this.errorMessage = '';
            }
        },
        isValid(isDOBValid) {
            this.$emit('isDOBValid', isDOBValid)
        },
        isUserOver18(date) {
            const diff = Date.now() - date.getTime();
            const diffDate = new Date(diff);
            return (diffDate.getFullYear() - 1970) >= 18
        },
        parseDate(input) {
            const splitValues = input.match('([0-9]+)/([0-9]+)/([12][0-9]{3})$');
            if (!splitValues) {
                return null;
            }
            const [d, m, y] = splitValues.slice(1, 4).map((i) => {
                return parseInt(i)
            });
            const isValid = this.isValidDate(y, m, d);
            if (!isValid) {
                return null;
            } else {
                return new Date(y, m - 1, d, 0, 0, 0);
            }
        },
        isValidDate(year, month, day) {
            const ts = new Date(year, month - 1, day, 0, 0, 0);
            const y = ts.getYear() + 1900;
            const m = ts.getMonth() + 1;
            const d = ts.getDate();
            return (y === year && m === month && d === day);
        }
    },
    watch: {
        dobData: function (dob) {
            this.$emit('updatedDOB', dob)
        }
    }
}
</script>
