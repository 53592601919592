<template>
  <modal-template :edition="edition">

    <template slot="content-title">
      Buy now
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="secondary-content">
      <div v-if="listedTokens && listedTokens.length > 0">
        <b-dropdown aria-role="list"
                    :append-to-body="true"
                    :scrollable="true"
                    :max-height="350"
                    v-model="selectedToken">

          <button class="button" slot="trigger" slot-scope="{ active }">
          <span v-if="!selectedToken">
            <span>Select an edition no.</span>
          </span>
            <span v-else>
            <span class="has-text-weight-bold">
              #{{ selectedToken.id }}
            </span>
            <span v-if="selectedToken && selectedToken.isListed">
              | Listed for <eth-price :price-in-eth="selectedToken.listPrice"></eth-price>
            </span>
          </span>
            <b-icon v-if="!selectedToken" :icon="active ? 'sort-up' : 'sort-down'"></b-icon>
          </button>

          <b-dropdown-item aria-role="listitem" v-for="(token, idx) in listedTokens" :key="idx" :value="token">
          <span class="has-text-weight-bold">
            #{{ idx + 1 }}
          </span>
            <span v-if="token.isListed">
              | Listed for <eth-with-fiat-price :price-in-eth="token.listPrice"></eth-with-fiat-price>
          </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div v-else>
        No editions have been listed yet, try make an offer instead
      </div>
    </template>

    <template slot="content-balance">&nbsp;</template>

    <template v-if="selectedToken && selectedToken.isListed" slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="!selectedToken"
                @click="launchBuyNowModal(selectedToken)">
        Buy Now
      </b-button>
    </template>

  </modal-template>
</template>
<script>

import {mapState} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import PurchaseToken from '../token/PurchaseToken';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import EthWithFiatPrice from '../../common/EthWithFiatPrice';
import {TOKEN_OWNERS_FOR_EDITION} from '@/queries/tokenQueries';

export default {
  components: {EthWithFiatPrice, ArtworkNameAndArtist, ModalTemplate},
  props: ['edition'],
  computed: {
    ...mapState('web3Store', ['gqlClient']),
    listedTokens() {
      return (this.tokens || []).filter(token => token.isListed);
    }
  },
  data() {
    return {
      selectedToken: null
    }
  },
  mounted() {
    this.$apollo.addSmartQuery('tokens', {
      client: this.gqlClient,
      query: TOKEN_OWNERS_FOR_EDITION,
      variables() {
        return {
          editionNumber: this.edition.id
        };
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async launchBuyNowModal(token) {
      this.close();
      this.$buefy.modal.open({
        parent: this,
        component: PurchaseToken,
        width: 600,
        props: {
          token,
          edition: this.edition
        }
      });
    }
  }
}
</script>
