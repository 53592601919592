var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.priceInWei
    ? _c("eth-with-fiat-price", { attrs: { "price-in-wei": _vm.priceInWei } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }