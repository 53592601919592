var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _c(
          "section",
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: "Enter new base price",
                  message: `Min. base price is ${_vm.minAmount}`,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    placeholder: "1.23",
                    expanded: "",
                    autofocus: "",
                    type: "number",
                    min: 0,
                    max: 100000,
                    step: "any",
                  },
                  model: {
                    value: _vm.form.basePriceInEth,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "basePriceInEth", $$v)
                    },
                    expression: "form.basePriceInEth",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                attrs: {
                  label: "Enter new step price",
                  message: `Min. step is ${_vm.minAmount}`,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    placeholder: "1.23",
                    expanded: "",
                    autofocus: "",
                    type: "number",
                    min: 0,
                    max: 100000,
                    step: "any",
                  },
                  model: {
                    value: _vm.form.stepPriceInEth,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "stepPriceInEth", $$v)
                    },
                    expression: "form.stepPriceInEth",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "b-message",
                  { attrs: { type: "is-info", closable: false, duration: 0 } },
                  [
                    _vm._v(
                      "\n          If all the editions of your step sale are sold the total value will be " +
                        _vm._s(_vm.calculateTotalStepValue) +
                        " Ξ\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                expanded: "",
                disabled: _vm.disableButton,
              },
              on: { click: _vm.changePrice },
            },
            [_vm._v("\n      Change price\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }