var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("client-only", [
        _c(
          "header",
          {
            staticClass:
              "header-image has-text-white has-background-grey-darker",
            style: { backgroundImage: `url(${_vm.image})` },
          },
          [
            _c("section", { staticClass: "hero" }, [
              _c("div", { staticClass: "hero-body" }, [
                _vm.headerImage
                  ? _c("div", { staticClass: "container content" }, [
                      _c(
                        "h1",
                        { staticClass: "has-text-white has-margin-top-1" },
                        [
                          _vm.headerImage
                            ? _c(
                                "n-link",
                                {
                                  staticClass:
                                    "is-size-10 has-text-white has-padding-top-6",
                                  attrs: {
                                    to: {
                                      name: "gallery-id",
                                      params: { id: _vm.headerImage.id },
                                    },
                                  },
                                },
                                [
                                  _vm.isVideo
                                    ? _c("b-icon", {
                                        staticClass: "is-pulled-right",
                                        attrs: {
                                          icon: "video",
                                          size: "is-small",
                                          "custom-class": "is-size-3",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.editionId
                                    ? _c("span", [_vm._v("New release by")])
                                    : _c("span", [_vm._v("Featured artist")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.headerImage.metadata.artist) +
                                      "\n              "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "field is-grouped" }, [
                          _c(
                            "p",
                            { staticClass: "control" },
                            [
                              _c(
                                "n-link",
                                {
                                  staticClass: "button is-white is-outlined",
                                  attrs: {
                                    to: {
                                      name: "gallery-id",
                                      params: { id: _vm.headerImage.id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    View artwork\n                  "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }