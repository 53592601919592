var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "artCarousel" }, [
    _c("div", { staticClass: "section container" }, [
      _c("div", { staticClass: "tile is-child" }, [
        _c(
          "div",
          { staticClass: "columns content is-flex is-vcentered is-mobile" },
          [
            _c(
              "div",
              {
                staticClass:
                  "column is-half-mobile is-two-thirds-tablet is-flex is-align-items-center",
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "artCarousel__title title is-size-3-desktop is-size-5-mobile mr-5",
                  },
                  [_vm._v(_vm._s(_vm.cmsComponent.title))]
                ),
                _vm._v(" "),
                _vm.trendingContracts
                  ? _c(
                      "button",
                      {
                        staticClass: "artCarousel__btn is-hidden-mobile",
                        on: { click: _vm.slidePrev },
                      },
                      [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.trendingContracts
                  ? _c(
                      "button",
                      {
                        staticClass: "artCarousel__btn is-hidden-mobile",
                        on: { click: _vm.slideNext },
                      },
                      [_c("b-icon", { attrs: { icon: "arrow-right" } })],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.cmsComponent.viewMoreLabel && _vm.cmsComponent.viewMoreLink
              ? _c(
                  "div",
                  {
                    staticClass:
                      "column is-half-mobile is-one-third-tablet has-text-right",
                    on: {
                      click: function ($event) {
                        return _vm.handleViewMoreClick(
                          _vm.cmsComponent.viewMoreLink
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass:
                          "is-outlined is-size-6-mobile component-view-more-link",
                        attrs: { to: _vm.cmsComponent.viewMoreLink },
                      },
                      [
                        _c("b-button", { attrs: { type: "is-secondary" } }, [
                          _vm._v(_vm._s(_vm.cmsComponent.viewMoreLabel)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "artCarousel__sliderHolder mt-3" }, [
        _vm.trendingContracts
          ? _c(
              "div",
              {
                staticClass: "artCarousel__slider",
                class: { "artCarousel__slider--loaded": _vm.sliderLoaded },
              },
              _vm._l(_vm.trendingContracts, function (contract) {
                return _c(
                  "div",
                  { key: contract.id, staticClass: "artCarousel__slide" },
                  [
                    _c(
                      "div",
                      { staticClass: "artCarousel__card" },
                      [
                        _c("creator-contract-card", {
                          attrs: { contract: contract },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }