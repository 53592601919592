<template>
  <section>
    <figure :class="customClass">
      <div v-lazy-container="{ selector: 'img' }">
        <img
          :data-src="thumbnailAsset"
          data-loading="/branding/bigloader-image.svg"
          data-error="cardAsset"
          :id="`edition_image_${editionNumber}`"
        />
      </div>
    </figure>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  components: {},
  props: ['editionNumber', 'customClass'],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    thumbnailAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/thumbnail`;
    },
    coverAsset() {
      return `${this.$cdnApi.defaults.baseURL}/cdn/images/network/${this.chainId}/edition/${this.editionNumber}/cover`;
    }
  },
  async mounted() {
  },
  methods: {
  }
};
</script>
