var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edition
    ? _c(
        "aside",
        { staticClass: "content scrollable-content is-family-tertiary" },
        [
          _c("ul", [
            _c("li", [
              _vm.tokenData
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Avg. sale price"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _vm.tokenData.totalPurchaseValue !== "0"
                          ? _c("eth-with-fiat-price", {
                              attrs: {
                                "price-in-eth":
                                  _vm.tokenData.totalPurchaseValue /
                                  _vm.tokenData.totalPurchaseCount,
                              },
                            })
                          : _c("eth-with-fiat-price", {
                              attrs: { "price-in-eth": "0" },
                            }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm.tokenData
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Total Sales"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("eth-with-fiat-price", {
                          attrs: {
                            "price-in-eth": _vm.tokenData.totalPurchaseValue,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm.tokenData
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Sale Count"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.tokenData.totalPurchaseCount) +
                            "\n        "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.tokenData
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Last Sale Price"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("eth-with-fiat-price", {
                          attrs: {
                            "price-in-eth": _vm.tokenData.lastSalePriceInEth,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _vm.tokenData
                ? _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Last transferred"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("date-format", {
                          attrs: {
                            "timestamp-in-secs":
                              _vm.tokenData.lastTransferTimestamp,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm.tokenData
              ? _c("li", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c("div", { staticClass: "column heading" }, [
                      _vm._v("Primary Sale Price"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column heading has-text-weight-semibold",
                      },
                      [
                        _c("eth-with-fiat-price", {
                          attrs: {
                            "price-in-eth": _vm.tokenData.primaryValueInEth,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("Owners Count"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [_vm._v(_vm._s(_vm.edition.allOwners.length))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("div", { staticClass: "columns is-mobile" }, [
                _c("div", { staticClass: "column heading" }, [
                  _vm._v("Edition created"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column heading has-text-weight-semibold" },
                  [
                    _c("date-format", {
                      attrs: {
                        "timestamp-in-secs": _vm.edition.createdTimestamp,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }