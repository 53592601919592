export const COLLECTOR = name => `
${name} {
  id
  address
}
`

export const ACTIVE_EDITION_BID = `
activeBid {
  id
  ethValue
  bidder
}`

export const TOKEN_OFFER = `
openOffer {
  id
  version
  ethValue
  timestamp
  ${COLLECTOR('ownerAtTimeOfBid')}
  ${COLLECTOR('bidder')}
}
`
export const RESERVE_AUCTION_FIELDS = `
reserveAuctionSeller
reserveAuctionBidder
reservePrice
reserveAuctionBid
reserveAuctionStartDate
previousReserveAuctionEndTimestamp
reserveAuctionEndTimestamp
reserveAuctionNumTimesExtended
isReserveAuctionInSuddenDeath
reserveAuctionTotalExtensionLengthInSeconds
isReserveAuctionResulted
isReserveAuctionResultedDateTime
reserveAuctionResulter
reserveAuctionCanEmergencyExit
`;

export const LISTING_FIELDS = `
listing {
  id
  ${RESERVE_AUCTION_FIELDS}
}`

export const METADATA_FIELDS = `
metadata {
  id
  name
  description
  image
  image_type
  artist
  cover_image
  cover_image_type
  image_sphere
  animation_url
  scarcity
  tags
  format
  theme
}
`;

export const STEP_SALE_FIELDS = `
  stepSaleBasePrice
  stepSaleStepPrice
`;

export const GATED_SALE_FIELDS = `
gatedSale {
  id
  paused
  phases {
    id
    saleId
    phaseId
    startTime
    endTime
    priceInWei
    walletMintLimit
    mintCount
    mintCap
  }
}
`;

export const CREATOR_CONTRACT_FIELDS = `
  id
  blockNumber
  timestamp
  implementation
  deployer
  creator
  paused
  owner
  secondaryRoyaltyPercentage
  minter
  defaultFundsHandler
  defaultFundsRecipients
  defaultFundsShares
  ERC165InterfaceID
  isBatchBuyItNow
  isHidden
  totalNumOfEditions
  totalNumOfTokensSold
  totalEthValueOfSales
  totalNumOfTransfers
`;
