var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "container section" }, [
    _c("div", { staticClass: "has-text-centered" }, [
      _vm.error && _vm.error.statusCode === 404
        ? _c("h1", { staticClass: "is-uppercase has-text-danger" }, [
            _vm._v("\n      Oops...You're lost or somethings went bang!\n    "),
          ])
        : _vm.error && _vm.error.statusCode
        ? _c("h1", { staticClass: "is-uppercase has-text-danger is-size-3" }, [
            _vm._v(
              "\n      We do not know the origin of this page!\n      Error status code: " +
                _vm._s(_vm.error.statusCode) +
                "\n    "
            ),
          ])
        : _c("h1", { staticClass: "is-uppercase has-text-danger" }, [
            _vm._v("\n      Oops...You're lost or somethings went bang!\n    "),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content", staticStyle: { "margin-top": "50px" } },
        [
          _c("nuxt-link", { attrs: { to: "/" } }, [
            _vm._v("Return to KnownOrigin"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.loadingError
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "tile is-ancestor has-margin-top-1" }, [
              _c("div", { staticClass: "tile is-parent is-vertical" }, [
                _c("section", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "tile is-child" }, [
                    !_vm.loading
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "columns is-multiline" },
                            _vm._l([1, 2, 3], function (i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "column is-one-third" },
                                [_c("asset-card-holder")],
                                1
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm.editions
                      ? _c(
                          "div",
                          { staticClass: "columns is-multiline" },
                          _vm._l(_vm.editions, function (edition) {
                            return _c(
                              "div",
                              {
                                key: edition.id,
                                staticClass: "column is-one-third",
                              },
                              [
                                _c("edition-card", {
                                  staticClass: "has-margin-bottom-4",
                                  attrs: {
                                    edition: edition,
                                    cover: true,
                                    resolution: "card",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "has-text-centered has-margin-bottom-1" },
                      [
                        _c(
                          "n-link",
                          {
                            staticClass: "button is-primary is-outlined",
                            attrs: { to: { name: "marketplace" } },
                          },
                          [
                            _vm._v(
                              "\n                  View marketplace\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tile is-child" }, [
      _c("div", { staticClass: "content has-text-left" }, [
        _c("h2", { staticClass: "title is-size-3" }, [_vm._v("Recent")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }