<template>
  <modal-template :edition="edition" :transaction-hash="null">

    <template slot="content-title">
      View unlockable content
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <div slot="secondary-content">
      <section v-if="content">
        <p v-if="content && content.content_message" style="max-height: 300px; overflow: scroll">
          {{content.content_message}}
        </p>
        <p v-if="content && content.content_link">
          <a :href="content.content_link" target="_blank" class="is-linkable">{{ content.content_link }}</a>
        </p>
      </section>
      <section v-if="error">
        <p class="has-text-danger">
          <empty-state message="Unable to find unlockable content"></empty-state>
        </p>
      </section>
    </div>

    <template slot="content-balance">&nbsp;</template>

    <template slot="action-feedback-label">&nbsp;</template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :loading="loading"
                @click="claimUnlockableContent">
        Claim unlockable content
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import ModalTemplate from '../../ModalTemplate';
import ArtworkNameAndArtist from '../../../ArtworkNameAndArtist';
import EmptyState from '../../../common/EmptyState';

export default {
  components: {EmptyState, ArtworkNameAndArtist, ModalTemplate},
  props: ['edition', 'token'],
  data() {
    return {
      response: null,
      error: null,
      loading: false,
      content: null
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async claimUnlockableContent() {
      this.loading = true;
      this.error = false;
      try {
        this.content = await this.$store.dispatch('unlockableContentStore/claimUnlockableContent', {
          koda_version: this.edition.version,
          artwork_id: this.edition.id,
          current_owner: this.token.currentOwner.id,
          token_id: this.token.id,
          date_signed: Date.now().toString()
        });
      } catch (e) {
        console.error(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
