export const EVENT_NAMES = {
  walletConnected: 'Wallet connected',
  walletDisconnected: 'Wallet disconnected',

  pageViewed: 'Page viewed',
  tabClicked: 'Tab clicked',
  artInteraction: 'Art interaction',
  componentScroll: 'Component scroll',
  componentScrollExhausted: 'Component scroll exhausted',
  viewAllClicked: 'View all clicked',
  viewPreferenceClicked: 'View preference clicked',
  cardClicked: 'Card clicked',
  pillClicked: 'Pill clicked',

  buttonClicked: 'Button clicked',
  linkClicked: 'Link clicked',
  likeClicked: 'Like Clicked',
  filterClicked: 'Filter clicked',
  checkboxClicked: 'Checkbox clicked',
  searchPerformed: 'Search performed',
  sortChanged: 'Sort changed',
  shareClicked: 'Share clicked',

  loadMoreTriggered: 'Load more triggered',

  purchaseCompleted: 'Purchase completed',
  purchaseFailed: 'Purchase failed',
  offerCompleted: 'Offer completed',
  offerFailed: 'Offer failed',

  omniClicked: 'Omni search clicked',
  omniResultClicked: 'Omni search result clicked',
  omniCategoryClicked: 'Omni search category clicked',
  omniSearchPerformed: 'Search performed'
}

export const AMPLITUDE_EVENT_PROPERTIES = {
  id: 'id',
  subject: 'subject',
  subjectType: 'subjectType',
  source: 'source',
  category: 'category',
  subCategory: 'subCategory',
  promoSection: 'promoSection',
  currency: 'currency',
  ownership: 'ownership',
  reason: 'reason',
  destination: 'destination',
  value: 'value',
  query: 'query',
  artworkType: 'artworkType',
  editionSize: 'editionSize',
  salesType: 'salesType',
  medium: 'medium',
  theme: 'theme',
  saleSchedule: 'saleSchedule',
  collabCount: 'collabCount',
  price: 'price',
  purchaseCount: 'purchaseCount',
  txHash: 'txHash',
  depth: 'depth',
  panelType: 'panelType',
  panelPosition: 'panelPosition',
  scrollDirection: 'scrollDirection',
  contractVersion: 'contractVersion',
  contractAddress: 'contractAddress',
  platform: 'platform',
  type: 'type',
  cardsPerRow: 'cardsPerRow',
  // Revenue Properties
  revenuePrice: '$price',
  revenueProductId: '$productId',
  revenueQuantity: '$quantity',
  revenueType: '$revenueType',
  revenueEventProperties: '$eventProperties'
}

export const ALGOLIA_EVENT_PROPERTIES = {
  source: 'source',
  index: 'index',
  eventName: 'eventName',
  queryId: 'queryId',
  objectId: 'objectId',
  positions: 'positions',
  filters: 'filters'
}
