var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.address
    ? _c(
        "figure",
        {
          directives: [
            {
              name: "lazy-container",
              rawName: "v-lazy-container",
              value: { selector: "img" },
              expression: "{ selector: 'img' }",
            },
          ],
        },
        [
          _c("img", {
            attrs: {
              "data-src": _vm.imageUrl,
              "data-error": _vm.createAccountIcon,
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }