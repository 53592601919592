var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "column has-padding-left-3 has-padding-top-0",
      class: {
        "countdown-started":
          _vm.reserveEditionAuctionCountdownInProgress && !_vm.isCc,
        "is-size-7 pl-3 mb-0 pb-0 pt-2 is-full": _vm.size === 2,
        "is-half": _vm.size === 3,
        "is-size-7 p-0 m-0": _vm.size === 1,
      },
    },
    [
      _c(
        "div",
        { staticClass: "has-text-weight-semibold has-text-left" },
        [
          _c(
            "n-link",
            {
              staticClass: "asset-card-wrapped-link",
              attrs: { to: _vm.handleMarketPlaceRouting },
            },
            [
              _vm.phase &&
              _vm.phase.state !== "public" &&
              _vm.isNotSoldOutPrimary
                ? _c(
                    "div",
                    {
                      class: {
                        "is-flex is-justify-content-space-between":
                          _vm.size === 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: { "has-text-white": _vm.isCc } },
                        [
                          _c(
                            "p",
                            {
                              class: {
                                "is-muted":
                                  _vm.phase.state === "pre-phase" && !_vm.isCc,
                              },
                            },
                            [_vm._v("\n            Early access\n          ")]
                          ),
                          _vm._v(" "),
                          _vm.phase.priceInWei === "0"
                            ? _c(
                                "p",
                                {
                                  staticClass: "has-text-weight-semibold",
                                  class: {
                                    "is-muted":
                                      _vm.phase.state === "pre-phase" &&
                                      !_vm.isCc,
                                  },
                                },
                                [_vm._v("\n            Free claim\n          ")]
                              )
                            : _c("eth-with-fiat-price", {
                                staticClass: "has-text-weight-semibold",
                                class: {
                                  "is-muted":
                                    _vm.phase.state === "pre-phase" &&
                                    !_vm.isCc,
                                  "ml-2": _vm.size === 2,
                                },
                                attrs: { "price-in-wei": _vm.phase.priceInWei },
                              }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm.isNotSoldOutPrimary
                ? _c(
                    "div",
                    {
                      staticClass: "is-family-tertiary",
                      class: {
                        "has-text-grey": !_vm.startDatePassed && !_vm.isCc,
                        "has-text-white": _vm.isCc,
                        "is-flex is-justify-content-space-between":
                          _vm.size === 2,
                      },
                    },
                    [
                      _c("section", [
                        _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                          ? _c("p", [
                              _vm._v("\n            Open edition\n          "),
                            ])
                          : _vm.isBuyableSalesType(_vm.edition.salesType)
                          ? _c("p", [_vm._v("Buy now")])
                          : _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                          ? _c("p", [
                              _vm._v(
                                "\n            Reserve auction\n          "
                              ),
                            ])
                          : _vm.isOffersOnlySalesType(_vm.edition.salesType)
                          ? _c("p", [
                              _vm.edition.activeBid &&
                              _vm.edition.activeBid.ethValue
                                ? _c("span", [
                                    _vm._v(
                                      "\n              Current bid\n            "
                                    ),
                                  ])
                                : _c("span", [_vm._v(" Place a bid ")]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.isBuyableSalesType(_vm.edition.salesType)
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: {
                                  "has-text-white": _vm.isCc,
                                  "ml-2": _vm.size === 2,
                                },
                                attrs: {
                                  "price-in-wei": _vm.edition.priceInWei,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.reserveEditionAuctionCountdownInProgress &&
                          _vm.edition.activeBid
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: { "ml-2": _vm.siz === 2 },
                                attrs: {
                                  "price-in-eth":
                                    _vm.edition.activeBid.ethValue,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.isReserveAuctionSalesType(_vm.edition.salesType)
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: {
                                  "has-text-white ml-2": _vm.isCc,
                                  "ml-2": _vm.size === 2,
                                },
                                attrs: {
                                  "price-in-wei": _vm.edition.reservePrice,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.edition.activeBid &&
                          _vm.edition.activeBid.ethValue
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: {
                                  "has-text-white": _vm.isCc,
                                  "ml-2": _vm.size === 2,
                                },
                                attrs: {
                                  "price-in-eth":
                                    _vm.edition.activeBid.ethValue,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.minBid && _vm.minBid.eth_reserve_in_wei > 0
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: {
                                  "has-text-white": _vm.isCc,
                                  "ml-2": _vm.size === 2,
                                },
                                attrs: {
                                  "price-in-wei": _vm.minBid.eth_reserve_in_wei,
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "p",
                            {
                              class: {
                                "has-text-grey": !_vm.isCc,
                                "has-text-white": _vm.isCc,
                                "ml-2": _vm.size === 2,
                              },
                            },
                            [_vm._v("\n          No reserve\n        ")]
                          ),
                    ]
                  )
                : _vm.workingToken
                ? _c(
                    "div",
                    {
                      staticClass: "is-family-tertiary",
                      class: {
                        "is-flex is-justify-content-space-between":
                          _vm.size === 2,
                      },
                    },
                    [
                      _c("section", { class: { "has-text-white": _vm.isCc } }, [
                        _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                          ? _c("p", [
                              _vm._v(
                                _vm._s(_vm.edition.totalSold) +
                                  " Edition" +
                                  _vm._s(_vm.edition.totalSold > 1 ? "s" : "")
                              ),
                            ])
                          : _vm.isBuyableSalesType(_vm.workingToken.salesType)
                          ? _c("p", [
                              _vm._v("\n            Buy now\n          "),
                            ])
                          : _vm.isReserveAuctionSalesType(
                              _vm.workingToken.salesType
                            )
                          ? _c("p", [
                              _vm._v(
                                "\n            Reserve auction\n          "
                              ),
                            ])
                          : _vm.isOffersOnlySalesType(
                              _vm.workingToken.salesType
                            )
                          ? _c("p", [
                              _vm.tokenOffer && _vm.tokenOffer.ethValue > 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n              Current bid\n            "
                                    ),
                                  ])
                                : _c("span", [_vm._v(" Place a bid ")]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.isBuyableSalesType(_vm.workingToken.salesType)
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: { "has-text-white": _vm.isCc },
                                attrs: {
                                  "price-in-eth": _vm.workingToken.listPrice,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.tokenOffer && _vm.tokenOffer.ethValue > 0
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: { "has-text-white": _vm.isCc },
                                attrs: {
                                  "price-in-eth": _vm.tokenOffer.ethValue,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.isReserveAuctionSalesType(
                            _vm.workingToken.salesType
                          ) &&
                          _vm.workingToken.listing &&
                          _vm.workingToken.listing.reservePrice
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: { "has-text-white": _vm.isCc },
                                attrs: {
                                  "price-in-wei":
                                    _vm.workingToken.listing.reservePrice,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.minBid && _vm.minBid.eth_reserve_in_wei > 0
                        ? _c(
                            "p",
                            [
                              _c("eth-with-fiat-price", {
                                class: { "has-text-white": _vm.isCc },
                                attrs: {
                                  "price-in-wei": _vm.minBid.eth_reserve_in_wei,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType) &&
                          _vm.edition.totalSold > 0
                        ? _c(
                            "p",
                            {
                              staticClass: "has-text-danger",
                              class: { "ml-2": _vm.size === 2 },
                            },
                            [_vm._v("\n          Sold out\n        ")]
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "is-family-tertiary",
                              class: {
                                "has-text-grey ml-2": !_vm.isCc,
                                "has-text-white ml-2": _vm.isCc,
                              },
                            },
                            [_vm._v("\n          No reserve\n        ")]
                          ),
                    ]
                  )
                : _vm.isBuyNowAndOpenSalesType(_vm.edition.salesType)
                ? _c(
                    "div",
                    {
                      staticClass:
                        "has-text-white is-flex is-justify-content-space-between is-family-tertiary",
                      class: { "is-flex-direction-column": _vm.size !== 2 },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.edition.totalSold) +
                            " Edition" +
                            _vm._s(_vm.edition.totalSold > 1 ? "s" : "")
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.edition.totalSold > 0
                        ? _c(
                            "span",
                            {
                              staticClass: "has-text-danger",
                              class: { "ml-2": _vm.size === 2 },
                            },
                            [_vm._v("Sold out")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "is-family-tertiary",
                      class: {
                        "has-text-white": _vm.isCc,
                        "is-flex is-justify-content-space-between":
                          _vm.size === 2,
                      },
                    },
                    [
                      _c("p", { staticClass: "mb-0" }, [_vm._v("Place a bid")]),
                      _vm._v(" "),
                      _c("p", { class: { "has-text-grey": !_vm.isCc } }, [
                        _vm._v("No reserve"),
                      ]),
                    ]
                  ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }