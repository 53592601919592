<template>
  <b-tag class="is-dark is-uppercase hand-pointer"
         v-if="collaborators && collaborators.length >= 2"
         @click.native="openCollectiveModal">
    <b-icon icon="account-group-outline" type="is-light" size="is-small" class="mr-1"></b-icon>COLLABORATION
  </b-tag>
</template>

<script>
import {mapState} from 'vuex';
import MultiCollabBreakdownModal from '../modal/MultiCollabBreakdownModal';

export default {
  props: ['editionId', 'artistAccount', 'collaborators'],
  computed: {
    ...mapState('web3Store', ['chainId'])
  },
  methods: {
    openCollectiveModal() {
      this.$buefy.modal.open({
        parent: this,
        component: MultiCollabBreakdownModal,
        width: 600,
        props: {
          editionId: this.editionId,
          artistAccount: this.artistAccount
        }
      });
    }
  }
};
</script>
