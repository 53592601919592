<template>
  <span>
    {{ walletProviderName }} {{ networkName }}
  </span>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('web3Store', [
        'networkName',
        'walletProviderName'
      ])
    }
  };
</script>

<style scoped>
</style>
