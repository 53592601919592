<template>
  <div style="font-weight: 400; font-size: 0.85rem" v-if="!walletDisabled" class="is-flex userProfileStats">

    <div class="level is-mobile is-uppercase mb-0">
      <div class="userProfileStats__holder level-left is-flex is-align-items-start is-full is-mobile column pb-0 pl-0">
        <div class="userProfileStats__userDetails level-item" v-if="artistStats && artistStats.editionsCount !== '0'">
          <div class="level is-mobile is-uppercase mb-0">
      <div class="level-left">
        <div class="level-item" v-if="artistEditions || collectorStats">

          <div class="mr-3 mb-4">
            <p class="has-text-left is-size-7 mb-1 is-muted is-uppercase is-spaced-1 is-family-tertiary has-text-weight-bold">JOINED</p>
              <p v-if="(collectorStats && artistEditions) && collectorStats.firstSeen < artistEditions.createdTimestamp" class="is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary">
                <span class="mr-1"><b-icon icon="calendar" class="level-item" size="is-small"></b-icon></span> {{ (collectorStats.firstSeen * 1000) | moment('MMM Do YYYY') }}
                </p>
              <p v-else-if="artistEditions" class="is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary"><span class="mr-1"><b-icon icon="calendar" class="level-item" size="is-small"></b-icon></span> {{ (artistEditions.createdTimestamp * 1000) | moment('MMM Do YYYY') }}</p>
              <p v-else-if="collectorStats" class="is-flex is-align-items-center is-capitalized has-text-weight-semibold is-size-6 is-family-tertiary"><span class="mr-1"><b-icon icon="calendar" class="level-item" size="is-small"></b-icon></span> {{ (collectorStats.firstSeen * 1000) | moment('MMM Do YYYY') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div class="mr-3 mb-4 pr-2">
        <p class="is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 is-family-tertiary has-text-weight-bold">LOCATION</p>
          <div data-cy="location_address" class="is-flex is-align-items-center is-famliy-tertiary">
                  <b-icon icon="map-marker" size="is-small" class="mr-1 userProfileStats__locationIcon"></b-icon>
                  <p v-if="location && location.trim()" class="has-text-weight-semibold is-size-6 is-capitalized is-family-tertiary">{{ location.slice(0, 20) + (location.length > 20 ? "..." : '') }}</p>
                  <p v-if="!location || !location.trim()" class="has-text-weight-semibold is-size-6 is-capitalized is-family-tertiary">Metaverse</p>
        </div>
      </div>
          <div class="level is-flex is-flex-direction-column mr-3 pr-2 mb-4">
          <p class="is-family-tertiary">
            <page-views-profile :address="profile.address"></page-views-profile>
          </p>
        </div>

        <div v-if="(artistStats && artistStats.salesCount !== '0') || (collectorStats && collectorStats.totalPurchaseEthSpent !== '0')" class="userProfileStats__userStats">
        <p class="is-uppercase has-text-left is-size-7 mb-1 is-muted is-spaced-1 is-family-tertiary has-text-weight-bold">STATS</p>
        <div class="userProfileStats__statsHolder is-flex is-mobile">
        <div class="userProfileStats__statsHolder--item level-item mr-1" v-if="artistStats && artistStats.salesCount !== '0'">
          <b-tag class="is-dark has-text-weight-medium">
            <p data-cy="Editions_count">{{ artistStats.editionsCount }} EDITIONS</p>
          </b-tag>
          </div>
        <div class="userProfileStats__statsHolder--item level-item mr-1" v-if="artistStats && artistStats.salesCount !== '0'">
          <b-tag class="is-dark has-text-weight-medium">
            <p data-cy="sales_count">{{ artistStats.salesCount }} SALES</p>
          </b-tag>
        </div>
        <div class="userProfileStats__statsHolder--item level-item mr-1" v-if="artistStats && artistStats.totalValueInEth !== '0'">
          <b-tag class="is-dark has-text-weight-medium">
            <p data-cy="total_count">
              <eth-with-fiat-price :price-in-eth="artistStats.totalValueInEth" /> TOTAL
            </p>
          </b-tag>
        </div>
        <div class="userProfileStats__statsHolder--item level-item mr-1" v-if="collectorStats && collectorStats.totalPurchaseCount !== '0'">
          <b-tag class="is-dark has-text-weight-medium">
            <p data-cy="NFT_count">
              {{collectorStats.totalPurchaseCount}} NFTS
            </p>
          </b-tag>
        </div>
        <div class="userProfileStats__statsHolder--item level-item mr-1" v-if="collectorStats && collectorStats.totalPurchaseEthSpent !== '0'">
          <b-tag class="is-dark has-text-weight-medium">
            <p data-cy="spent_count">
              <eth-with-fiat-price :price-in-eth="collectorStats.totalPurchaseEthSpent" />
              SPENT
            </p>
          </b-tag>
        </div>
      </div>
    </div>
      </div>
    </div>

    <div class="level is-mobile userProfileStats__labels">
      <div class="level-left">
        <div class="level-item has-padding-right-7">
          <div>
            <p>
              <!-- Still needs data to determine 'OG' profiles -->
              <profile-labels :profile="profile" :stats="rawStats" :is-hall-of-fame="isHallOfFame" :is-collection="isCollection" />
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import _get from 'lodash/get';
import _first from 'lodash/first';
import _indexOf from 'lodash/indexOf';
import {mapState} from 'vuex';
import { USER_STATS } from '../../queries/artistQueries';
import { TOP_ARTISTS } from '../../queries/hallOfFameQueries';
import PageViewsProfile from '../PageViewsProfile';
import ProfileLabels from './ProfileLabels'
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

const DISABLED_WALLET_STATS = [
  '0xd26502d8c1b9f09e1e0ca41342f5e0fb33f4ea2d'.toLowerCase()
];

export default {
  components: {PageViewsProfile, EthWithFiatPrice, ProfileLabels},
  props: ['profile', 'isArtist', 'location', 'isCollection'],
  computed: {
    ...mapState('web3Store', ['gqlClient']),
    walletDisabled() {
      return _indexOf(DISABLED_WALLET_STATS, this.profile.address.toLowerCase()) > -1;
    }
  },
  data() {
    return {
      rawStats: null,
      isHallOfFame: null,
      artistStats: null,
      artistEditions: null,
      collectorStats: null,
      ownedTokens: null
    };
  },
  mounted() {
    this.$apollo.addSmartQuery('artistStats', {
      client: this.gqlClient,
      query: USER_STATS,
      variables() {
        return {
          address: this.profile.address.toLowerCase()
        };
      },
      result({data}) {
        this.rawStats = data;
        this.artistStats = _get(data, 'artistStats');
        this.artistEditions = _first(_get(data, 'artistEditions'));
        this.collectorStats = _first(_get(data, 'collectorStats'));
        this.ownedTokens = _get(data, 'ownedTokens', []);
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
    this.$apollo.addSmartQuery('topArtists', {
      client: this.gqlClient,
      query: TOP_ARTISTS,
      variables() {
        return {
          address: this.profile.address.toLowerCase()
        };
      },
      result({data}) {
        this.rawStats = data;
        this.isHallOfFame = _get(data, 'topArtists').some(obj => obj.address.includes(this.profile.address))
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  }
};
</script>
<styles lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.userProfileStats {
  position: relative;
  flex-wrap: wrap;

  &__labels {
    position: absolute;
    top: -150px;
    left: 162px;

    @include touch {
      position: absolute;
      top: -65px;
      left: 0;
      p {
        color: rgba(0,0,0, 0.6) !important;
      }
    }
  }

  &__holder {
    flex-wrap: wrap;

    @include touch {
      flex-wrap: unset;
    }

    @include mobile {
    flex-wrap: wrap;
    }
  }

  &__userDetails {
    flex-grow: unset !important;
    justify-content: flex-start !important;
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__userStats {
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__statsHolder {
    @include touch {
      flex-wrap: wrap;

      &--item {
        flex-grow: unset !important;
        margin-bottom: 4px !important;
      }
    }
  }

  &__locationIcon {
    margin-left: -4px;
  }
}
</styles>
