<template>
  <div>
    <p class="title">
      Winning account
    </p>
    <p>
      <user-profile-lookup
        :artist-account="bidderAddress"
        :metadata-artist="bidderAddress">
      </user-profile-lookup>
    </p>
    <p>
      <eth-with-fiat-price
        size="is-large"
        show-fiat="true"
        :price-in-wei="weiValue">
      </eth-with-fiat-price>
    </p>
  </div>
</template>
<script>
  import UserProfileLookup from '../UserProfileLookup';
  import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

  export default {
    components: {UserProfileLookup, EthWithFiatPrice},
    props: ['bidderAddress', 'weiValue']
  };
</script>
<style>
</style>
