<template>
  <div v-if="topArtists" class="topArtists has-margin-top-7 mb-6 pb-6">
    <section
      class="topArtists__label is-hidden-mobile has-text-weight-bold is-family-tertiary"
    >
      <div class="">Top 50 creators by total ETH sales</div>
    </section>
    <b-table
      class="topArtists__table is-family-tertiary"
      :data="topArtists"
      default-sort="totalValueInEth"
      sticky-header
      height="auto"
      striped
      paginated
      pagination-simple
      :per-page="25"
    >
      <div
        class="topArtists__label topArtists__label--mobile title mb-3 is-hidden-tablet has-text-weight-bold is-family-tertiary"
      >
        Top 250 creators by total ETH sales
      </div>

      <!--        <b-table-column field="index" label="#" centered class="is-size-5 is-size-6-mobile">-->
      <!--          {{ props.index + 1}}-->
      <!--        </b-table-column>-->

      <b-table-column
        class="topArtist__artist"
        field="address"
        label="Creator"
        v-slot="props"
      >
        <user-profile-lookup
          :no-subtitle="true"
          :is-table="true"
          :artist-account="props.row.address"
          :metadata-artist="props.row.address"
        >
        </user-profile-lookup>
      </b-table-column>

      <b-table-column
        field="salesCount"
        label="Sales count"
        width="120"
        centered
        sortable
        :custom-sort="sortableString('salesCount')"
        class="is-size-5 is-size-6-mobile is-family-tertiary"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            label="Total no. of sales (primary & secondary)"
            position="is-bottom"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template v-slot="props">
          <span class="is-family-tertiary">{{ props.row.salesCount }}</span>
        </template>
      </b-table-column>

      <b-table-column
        field="highestSaleValueInEth"
        label="Highest sale"
        width="140"
        centered
        sortable
        :custom-sort="sortableString('highestSaleValueInEth')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            label="Biggest ever single sale (primary & secondary)"
            position="is-bottom"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.highestSaleValueInEth"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>

      <b-table-column
        field="totalValueInEth"
        label="Total sales"
        width="140"
        centered
        sortable
        :custom-sort="sortableString('totalValueInEth')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            label="Total trade volume (primary & secondary)"
            position="is-left"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.totalValueInEth"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>

      <b-table-column
        field="totalPrimarySales"
        label="Primary sales"
        width="140"
        centered
        sortable
        :custom-sort="sortableString('totalPrimarySales')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            position="is-left"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template v-slot="props">
          <div class="is-family-tertiary">
            {{ props.row.totalPrimarySales }}
          </div>
        </template>
      </b-table-column>

      <b-table-column
        field="totalPrimarySalesInEth"
        label="Primary ETH"
        width="140"
        centered
        sortable
        :custom-sort="sortableString('totalPrimarySalesInEth')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            position="is-left"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.totalPrimarySalesInEth"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>

      <b-table-column
        field="totalSecondarySales"
        label="Secondary sales"
        width="140"
        centered
        sortable
        :custom-sort="sortableString('totalSecondarySales')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            position="is-left"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template v-slot="props">
          <div class="is-family-tertiary">
            {{ props.row.totalSecondarySales }}
          </div>
        </template>
      </b-table-column>

      <b-table-column
        field="totalSecondarySalesInEth"
        label="Secondary ETH"
        width="120"
        centered
        sortable
        :custom-sort="sortableString('totalSecondarySalesInEth')"
        class="is-size-5 is-size-6-mobile"
      >
        <template #header="{ column }">
          <b-tooltip
            type="is-dark"
            append-to-body
            position="is-left"
            style="pointer-events: none"
          >
            {{ column.label }}
          </b-tooltip>
          <img class="is-hidden-touch" src="/interface/sort-arrow.png" alt="" />
        </template>
        <template #default="props">
          <eth-with-fiat-price
            :price-in-eth="props.row.totalSecondarySalesInEth"
          ></eth-with-fiat-price>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { TOP_ARTISTS } from "../../queries/hallOfFameQueries";
import UserProfileLookup from "../../components/UserProfileLookup";
import EthWithFiatPrice from "@/components/common/EthWithFiatPrice";

export default {
  components: {
    EthWithFiatPrice,
    UserProfileLookup,
  },
  data() {
    return {
      topArtists: [],
    };
  },
  computed: {
    ...mapState("web3Store", ["gqlClient"]),
  },
  mounted() {
    this.$apollo.addSmartQuery("topArtists", {
      client: this.gqlClient,
      query: TOP_ARTISTS,
      error(error) {
        console.log("error", error);
      },
    });
    this.scrollTopOnPageChange();
  },
  methods: {
    sortableString(field) {
      return (a, b, isAsc) => {
        return isAsc
          ? parseFloat(b[field]) - parseFloat(a[field])
          : parseFloat(a[field]) - parseFloat(b[field]);
      };
    },
    scrollTopOnPageChange() {
      const pageLinks = document.querySelectorAll(".pagination-link");

      for (const link of pageLinks) {
        link.addEventListener("click", () => {
          if (!link.getAttribute("disabled")) {
            // setTimeout makes this work on mobile
            setTimeout(() => {
              window.scroll({
                top: 0,
                behavior: "smooth",
              });
            }, 0);
            document.querySelector(".table-wrapper").scrollTop = 0;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.topArtists {
  position: relative;

  &__label {
    position: absolute;
    top: -40px;
    right: 0;

    &--mobile {
      position: relative;
      top: unset;
    }
  }

  &__table {
    td,
    th {
      padding-left: 0 !important;

      &:last-of-type {
        padding-right: 0;
      }
    }

    thead {
      background: white !important;
    }

    th {
      span {
        font-weight: 500 !important;
        white-space: nowrap;
        opacity: 0.7 !important;
      }

      &:first-of-type {
        span {
          opacity: 0.55 !important;
        }
      }
    }

    .media-left {
      margin-right: 0.5rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle !important;
    }

    td > aside {
      align-items: center !important;
    }
  }

  .level {
    justify-content: center;
  }

  .info {
    order: 2;
    margin: 0 20px;
  }

  &__artist {
    text-align: left !important;

    td {
      text-align: left !important;
    }

    th {
      text-align: left !important;
    }
  }

  // temp hide pagination until bug is fixed
  .top.level {
    display: none;
  }
}
</style>
