var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-family-tertiary" }, [
    _vm.isHallOfFame
      ? _c("div", { staticClass: "is-inline-block mr-2" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.isTrendingArtist
      ? _c("div", { staticClass: "is-inline-block" }, [_vm._m(1)])
      : _vm.isTrendingCollector
      ? _c("div", { staticClass: "is-inline-block" }, [_vm._m(2)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "is-size-6 trending-tag" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("🖼")]),
      _vm._v(" Hall of Fame creator\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "is-size-6 trending-tag" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("🎨")]),
      _vm._v(" Trending creator\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "is-size-6 trending-tag" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("🍱")]),
      _vm._v(" Trending collector\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }