var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._v("\n  Current highest offer\n  "),
      _c("eth-with-fiat-price", { attrs: { "price-in-wei": _vm.weiValue } }),
      _vm._v("\n  from\n  "),
      _c("profile-name-link", { attrs: { "eth-account": _vm.bidder } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }