<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      Buy now for
      <eth-with-fiat-price :price-in-eth="token.listPrice" />
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="secondary-content" v-if="listAmountToArtist">
      <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
        12.5% (
        <eth-with-fiat-price :price-in-eth="listAmountToArtist"></eth-with-fiat-price>
        ) of this sale will be paid to the original artist(s)
      </b-message>
    </template>

    <template slot="action-feedback-label">
      Buying
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                @click="buyNow">
        Buy now
      </b-button>
    </template>

    <template slot="action-button-nevermind">
      <b-button expanded @click="close(false)">
        Nevermind
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import {mapState} from 'vuex';
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {ALGOLIA_EVENT_PROPERTIES, AMPLITUDE_EVENT_PROPERTIES, EVENT_NAMES} from '../../../services/analyticsEvents';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {
    EthWithFiatPrice,
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['token', 'edition', 'algoliaInfo'],
  data() {
    return {
      transactionHash: null
    }
  },
  computed: {
    ...mapState('web3Store', ['chainId']),
    listAmountToArtist() {
      if (this.token.listPrice) {
        return Number(this.token.listPrice) * 0.125;
      }
      return false;
    }
  },
  methods: {
    close(complete) {
      this.$emit('close', {purchaseComplete: complete});
    },
    async buyNow() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/purchaseToken', {
          token: this.token.id,
          version: this.token.version,
          listPrice: ethers.utils.parseEther(this.token.listPrice).toString()
        });
        this.transactionHash = tx.hash;

        // wait for the transaction to be mined
        await tx.wait(1);

        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/convertedObjectIDsAfterSearch', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseCompleted,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.token.id
          });
        }
        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseCompleted,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.price]: this.token.listPrice,
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now',
            [AMPLITUDE_EVENT_PROPERTIES.editionSize]: this.edition.totalAvailable,
            [AMPLITUDE_EVENT_PROPERTIES.medium]: this.edition.metadata.format,
            [AMPLITUDE_EVENT_PROPERTIES.theme]: this.edition.metadata.theme,
            [AMPLITUDE_EVENT_PROPERTIES.saleSchedule]: this.token.listingTimestamp ? this.token.listingTimestamp : null,
            [AMPLITUDE_EVENT_PROPERTIES.collabCount]: this.edition.collaborators ? this.edition.collaborators.length - 1 : 0,
            [AMPLITUDE_EVENT_PROPERTIES.purchaseCount]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenuePrice]: parseFloat(this.token.listPrice),
            [AMPLITUDE_EVENT_PROPERTIES.revenueProductId]: this.edition.id,
            [AMPLITUDE_EVENT_PROPERTIES.revenueQuantity]: 1,
            [AMPLITUDE_EVENT_PROPERTIES.revenueType]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.txHash]: tx.hash,
            [AMPLITUDE_EVENT_PROPERTIES.revenueEventProperties]: {
              [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now',
              [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
              [AMPLITUDE_EVENT_PROPERTIES.contractVersion]: this.edition.version,
              [AMPLITUDE_EVENT_PROPERTIES.contractAddress]: this.edition.creatorContract ? this.edition.creatorContract.id : null
            }
          }
        });
        await this.$store.dispatch('userStore/getContractAddress', {
          chainId: this.chainId,
          version: this.edition.version
        }).then((contractAddress) => {
          if (contractAddress) {
              this.$gtm.push({
              event: 'Purchase',
            value: this.token.listPrice,
            editiontitle: this.edition.metadata.name,
              tokenid: this.edition.id,
              artist: this.edition.artistAccount,
              purchasetype: 'BuywithEth',
              marketplace: 'Secondary',
              chain: this.chainId,
              contracttype: this.edition.version,
              contractaddress: contractAddress
            })
          }
        });

        this.close(true);
      } catch (e) {
        if (this.algoliaInfo) {
          await this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
            [ALGOLIA_EVENT_PROPERTIES.index]: this.algoliaInfo.index,
            [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.purchaseFailed,
            [ALGOLIA_EVENT_PROPERTIES.queryId]: this.algoliaInfo.queryId,
            [ALGOLIA_EVENT_PROPERTIES.objectId]: this.token.id,
            [ALGOLIA_EVENT_PROPERTIES.positions]: this.algoliaInfo.position
          });
        }

        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.purchaseFailed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.id]: this.token.id,
            [AMPLITUDE_EVENT_PROPERTIES.reason]: 'rejected',
            [AMPLITUDE_EVENT_PROPERTIES.currency]: 'eth',
            [AMPLITUDE_EVENT_PROPERTIES.ownership]: 'secondary',
            [AMPLITUDE_EVENT_PROPERTIES.salesType]: 'buy now'
          }
        });

        console.error(e);
      }
    }
  }
};
</script>
