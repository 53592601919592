<template>
  <div
    @click="
      handleMarketPlaceRouting(
        item,
        {
          queryId: item.__queryID,
          index: sortBy,
          position: index + 1,
        },
        item
      )
    "

    class="is-full columns is-flex-mobile p-0 my-0 search-item-style"
    :class="{ 'search-item-style-top': index === 0 }"
  >
    <div class="is-flex is-align-items-center">
    <image-asset
      class="rounded-edge-image ml-3"
      :edition-number="item.editionId"
      :metadata="item.metadata"
      resolution="thumbnailSearch"
      :cover="false"
    >
    </image-asset></div>
    <section
      class="column is-centered px-2 is-inline-flex is-justify-content-space-between"
    >
      <p
        class="has-text-left is-size-5 ml-1 has-text-weight-light is-family-secondary is-size-6"
      >
        {{
          item.metadata.artworkName.length >
          textWrapCap
            ? item.metadata.artworkName.substring(
            0,
            textWrapCap
          ) + "..."
            : item.metadata.artworkName
        }}
      </p>
      <div>
        <availability class="artwork-availability has-text-centered" :available="item.filters.editionSize"
                      :supply="item.filters.editionSize" :edition="item.filters"></availability>
      </div>
    </section>
  </div>
</template>

<script>
import Availability from '../Availability';
import ImageAsset from '../asset-renders/ImageAsset';
import {handleGalleryRouting} from '../../services/routing';
import {ALGOLIA_EVENT_PROPERTIES, EVENT_NAMES} from '../../services/analyticsEvents';

export default {
  name: 'ArtworkSearchResultRow',
  components: {
    Availability,
    ImageAsset
  },
  props: ['item', 'index', 'textWrapCap', 'sortBy', 'handleAmplitudeClick'],
  mounted() {
    console.log('item: ', this.item);
  },
  methods: {
    handleMarketPlaceRouting(item, algoliaInfo) {
      const route = handleGalleryRouting(item)
      if (algoliaInfo) {
        const query = {};
        if (algoliaInfo.queryId) {
          query.query_id = algoliaInfo.queryId;
        }
        if (algoliaInfo.index) {
          query.index = algoliaInfo.index;
        }
        if (algoliaInfo.position) {
          query.position = algoliaInfo.position;
        }
        route.query = query;
      }
      this.handleAmplitudeClick(
        'omniResultClicked',
        item.metadata.artworkName,
        this.searchInput,
        'Artworks'
      );
      this.handleAmplitudeClick('omniSearchPerformed', '', this.searchInput);
      this.searchInput = '';
      this.$router.push(route);
      this.$emit('show-search', false);

      this.$store.dispatch('analytics/algoliaInsightsStore/sendObjectClickedEvent', {
        [ALGOLIA_EVENT_PROPERTIES.index]: this.sortBy,
        [ALGOLIA_EVENT_PROPERTIES.eventName]: EVENT_NAMES.omniResultClicked,
        [ALGOLIA_EVENT_PROPERTIES.queryId]: algoliaInfo.queryID,
        [ALGOLIA_EVENT_PROPERTIES.objectId]: item.editionId,
        [ALGOLIA_EVENT_PROPERTIES.positions]: algoliaInfo.index + 1
      });
    }
  }
};
</script>

<style scoped>
.artwork-availability {
    font-weight: 400 !important;
}

.left-adjust-art {
  margin-left: 10px;
}
</style>
