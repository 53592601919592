var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "tile is-ancestor" }, [
      _c("div", { staticClass: "tile is-parent is-vertical" }, [
        _c("section", { staticClass: "section" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "tile is-child" }, [
            _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(_vm.displayList, function (edition) {
                return _c(
                  "div",
                  {
                    key: edition.id,
                    staticClass:
                      "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd asset-card",
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleCardClickedEvent(
                              "24hr Reserve Auctions"
                            )
                          },
                        },
                      },
                      [
                        _c("edition-card", {
                          staticClass: "has-margin-bottom-4",
                          attrs: {
                            edition: edition,
                            cover: true,
                            resolution: "card",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleItemClickInsights(
                                "card",
                                edition.id,
                                "24hr reserve auctions",
                                "token page"
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "has-text-centered has-margin-bottom-1",
                on: {
                  click: function ($event) {
                    return _vm.handleButtonClickedEvent(
                      "View all 24hr Reserve Auctions",
                      "24hr Reserve Auctions",
                      "marketplace"
                    )
                  },
                },
              },
              [
                _c(
                  "n-link",
                  {
                    staticClass: "button is-primary is-outlined",
                    attrs: {
                      to: {
                        name: "marketplace",
                        query: {
                          ["sale_type_reserve_countdown_auction"]: true,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleItemClickInsights(
                          "button",
                          "",
                          "24hr reserve auctions",
                          "marketplace"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              View all 24hr reserve auctions\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tile is-child" }, [
      _c("div", { staticClass: "content" }, [
        _c("h2", { staticClass: "title is-size-3 is-size-4-mobile" }, [
          _vm._v("24hr Reserve Auctions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }