<template>
  <modal-template :edition="edition" :transaction-hash="transactionHash">

    <template slot="content-title">
      <p>
        Change listing
      </p>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="edition.metadata" :edition-number="edition.id">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <template slot="secondary-content">
      <section>

        <b-field label="Listing type" v-if="availableChanges && availableChanges.length > 0">
          <b-select placeholder="Select a new sales type" expanded
                    v-model="form.selectedSaleType"
                    :disabled="transactionHash">
            <option v-for="option in availableChanges" :value="option.id" :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Enter new buy now price"
                 v-if="isBuyableSalesType(form.selectedSaleType)"
                 :message="`Min. sale price is ${minAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   v-model="form.priceInEth"
                   :disabled="transactionHash"
                   type="number"
                   :step="minAmount">
          </b-input>
        </b-field>

        <div v-else-if="isOffersOnlySalesType(form.selectedSaleType)">
          <b-field label="Enter minimum bid price"
                   :message="`Min. sale price is ${minAmount}`">
            <b-input placeholder="1.23"
                     expanded
                     autofocus
                     v-model="form.priceInEth"
                     type="number"
                     :min="0"
                     :max="100000"
                     :disabled="transactionHash"
                     :step="minAmount">
            </b-input>
          </b-field>

          <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
            <p>
              Setting an minimum bid <span class="has-text-weight-semibold">for free</span>
              is a way to signal to buyers that there is a minimum price which you will
              consider before accepting.
            </p>
            <p class="has-text-weight-semibold">
              You are still responsible for resulting any auction.
            </p>
          </b-message>
        </div>

        <b-message type="is-warning" class="is-size-7" :closable="false" :duration="0"
                   v-if="isReserveAuctionSalesType(edition.salesType) || isSteppedSalesType(edition.salesType)">
            Converting from a reserve auction or step sale is a one way process, you cannot move back into these sales types.
        </b-message>

        <b-message type="is-info" class="is-size-7" :closable="false" :duration="0"
                   v-if="availableChanges && availableChanges.length === 0">
            Unfortunately we cannot change this artwork as it was originally uploaded as an unchangeable listing type.
        </b-message>

      </section>

    </template>

    <template slot="action-feedback-label">
      Listing updated
    </template>

    <template slot="action-button">
      <b-button type="is-primary"
                expanded
                @click="changeSaleType"
                :disabled="!availableChanges || availableChanges.length === 0 || form.priceInEth >= 100000">
        Change listing
      </b-button>
    </template>

  </modal-template>
</template>

<script>
import _map from 'lodash/map';
import {ethers} from 'ethers';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth} from '../../../services/utils';
import {
  AVAILABLE_SALES_TYPES,
  SaleTypeMappings,
  isBuyableSalesType,
  isOffersOnlySalesType,
  isReserveAuctionSalesType,
  isSteppedSalesType
} from '../../../services/SaleTypes';
import * as SaleType from '../../../services/SaleTypes';

export default {
  components: {
    ArtworkNameAndArtist,
    ModalTemplate
  },
  props: ['edition'],
  data() {
    return {
      SaleType,
      minAmount: listOrOfferAmountFloorInEth,
      maxUint256: ethers.constants.MaxUint256,
      transactionHash: null,
      form: {
        selectedSaleType: null,
        priceInEth: null
      },
      availableChanges: []
    };
  },
  computed: {},
  mounted() {
    const availableChanges = AVAILABLE_SALES_TYPES[this.edition.version][this.edition.salesType];

    this.availableChanges = _map(availableChanges, (id) => {
      return {
        id,
        ...SaleTypeMappings[id]
      };
    });

    // Set selected to entity saleType
    this.form.selectedSaleType = this.availableChanges && this.availableChanges.length > 0 ? this.availableChanges[0].id : null;
    this.form.priceInEth = this.edition.priceInWei > 0 && this.edition.priceInWei.toString() !== ethers.constants.MaxUint256.toString()
      ? ethers.utils.formatEther(this.edition.priceInWei)
      : listOrOfferAmountFloorInEth;

    console.log(`Available sales types for KODA [${this.edition.version}] and type [${this.edition.salesType}] for ID [${this.edition.id}]`, {
      availableChanges,
      mappedChanges: this.availableChanges
    });
  },
  methods: {
    isReserveAuctionSalesType,
    isSteppedSalesType,
    isOffersOnlySalesType,
    isBuyableSalesType,
    close() {
      this.$emit('close');
    },
    async changeSaleType() {
      try {
        let tx;

        if (parseInt(this.edition.version) === 3) {
          tx = await this.$store.dispatch('web3ActionsStore/changeEditionSalesType', {
            edition: this.edition.id,
            currentSaleType: this.edition.salesType,
            newSaleType: this.form.selectedSaleType,
            priceInWei: this.form.selectedSaleType === SaleType.BUY_NOW
              ? ethers.utils.parseEther(this.form.priceInEth.toString()).toString()
              : ethers.constants.MaxUint256
          });
        } else if (parseInt(this.edition.version) === 2) {
          tx = await this.$store.dispatch('web3ActionsStore/changePrice', {
            edition: this.edition.id,
            version: this.edition.version,
            salesType: this.edition.salesType,
            priceInWei: (this.form.selectedSaleType === SaleType.BUY_NOW || this.form.selectedSaleType === SaleType.BUY_NOW_AND_OFFERS)
              ? ethers.utils.parseEther(this.form.priceInEth.toString()).toString()
              : ethers.constants.MaxUint256
          });
        }

        if (tx) {
          this.transactionHash = tx.hash;

          // wait for the transaction to be mined
          await tx.wait(1);

          // set minimum bid too
          if (this.isOffersOnlySalesType(this.form.selectedSaleType) && this.form.priceInEth > 0) {
            await this.$store.dispatch('minimumOfferStore/setOffchainMinimumBid', {
              koda_version: this.edition.version,
              sale_type: this.edition.salesType,
              current_owner: this.edition.artistAccount,
              eth_reserve_in_wei: ethers.utils.parseEther(this.form.priceInEth.toString()).toString(),
              artwork_id: this.edition.id,
              entity_type: 'edition',
              date_signed: Date.now().toString()
            });
          }

          this.close();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
