var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.breakdown
    ? _c(
        "section",
        [
          _c("b-collapse", { attrs: { open: false } }, [
            _c(
              "p",
              {
                staticClass: "is-muted-7 has-text-centered",
                attrs: { slot: "trigger" },
                slot: "trigger",
              },
              [
                _c("span", {}, [
                  _vm._v("Analysed "),
                  _c("span", { staticClass: "is-bold" }, [
                    _vm._v(_vm._s(_vm.breakdown.totalEvents)),
                  ]),
                  _vm._v(" events over a "),
                  _c("span", { staticClass: "is-bold" }, [_vm._v("14")]),
                  _vm._v("\n      day period."),
                ]),
                _vm._v("\n      Show more "),
                _c("b-icon", {
                  attrs: { icon: "arrow-expand-down", size: "is-small" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "is-flex is-flex-direction-column is-align-items-center",
              },
              [
                _c(
                  "p",
                  [
                    _c("breakdown-radar-chart", {
                      attrs: { breakdown: _vm.breakdown },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "is-muted-7" }, [
                  _vm._v(
                    "\n        Last updated " +
                      _vm._s(_vm._f("moment")(new Date(_vm.created), "from")) +
                      "\n      "
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }