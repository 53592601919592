var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.version !== "4"
    ? _c("span", { staticClass: "is-family-tertiary" }, [
        _vm._v(
          "\n    #" +
            _vm._s(
              parseInt(_vm.id) -
                parseInt(_vm.editionNumber) +
                parseInt(_vm.version) -
                2
            ) +
            "/" +
            _vm._s(_vm.editionTotalAvailable) +
            "\n"
        ),
      ])
    : _c("span", { staticClass: "is-family-tertiary" }, [
        _vm._v(
          "#" +
            _vm._s(
              Math.abs(
                parseInt(_vm.id) -
                  parseInt(_vm.editionNumber) -
                  _vm.editionTotalAvailable
              )
            ) +
            "/" +
            _vm._s(_vm.editionTotalAvailable)
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }