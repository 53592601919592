<template>
  <section>
    <Editorial2 v-if="panelType === 'editorial2'" :cms-component="cmsComponent" :is-application="true" />
  </section>
</template>
<script>

export default {
  props: ['cmsComponent'],
  computed: {
    panelType() {
      return this.cmsComponent && this.cmsComponent.panelType
    }
  }
}
</script>
