var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      _c("v-pannellum", {
        ref: "panellum",
        class: { "full-size": _vm.isCover },
        attrs: {
          src: _vm.reFormatUrl,
          "auto-rotate": true,
          draggable: _vm.isCover,
          "show-fullscreen": _vm.isCover,
          compass: _vm.isCover,
          "show-zoom": _vm.isCover,
          "mouse-zoom": _vm.isCover,
          hfov: 120,
        },
        on: { load: _vm.onLoaded },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }