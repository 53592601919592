var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.headerImage
      ? _c(
          "header",
          {
            staticClass: "header-image has-text-white",
            style: {
              backgroundImage: `url(https://cdn.knownorigin.io/cdn/images/network/1/edition/${_vm.headerImage}/card`,
            },
          },
          [
            _c("section", { staticClass: "hero" }, [
              _c("div", { staticClass: "hero-body" }, [
                _c("div", { staticClass: "container content" }, [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "title has-text-white has-margin-top-1 is-size-1",
                    },
                    [
                      _vm.headerImage
                        ? _c(
                            "n-link",
                            {
                              staticClass: "has-text-white has-padding-top-6",
                              attrs: { to: _vm.linkToJournal },
                            },
                            [
                              _vm._v("\n              Artist spotlight "),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(_vm.artist) + "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "field is-grouped" }, [
                      _c(
                        "p",
                        { staticClass: "control" },
                        [
                          _c(
                            "n-link",
                            {
                              staticClass: "button is-white is-outlined",
                              attrs: { to: _vm.linkToJournal },
                            },
                            [
                              _vm._v(
                                "\n                  Read the article\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }