<template>
  <div>
<b-tooltip v-if="resolved && isIcon" :label="resolved" class="ensResolver is-flex is-align-items-center is-justify-content-center" type="is-dark">
  <li class="is-flex is-justify-content-center">
   <img src="/profile/verified-eth-colour.svg" />
  </li>
</b-tooltip>

  <li v-if="resolved && !isIcon">
    <b-icon icon="account-check-outline" size="is-small"></b-icon>
    <span>{{ resolved }}</span>
  </li>
</div>
</template>

<script>
export default {
  props: ['address', 'isIcon'],
  data() {
    return {
      resolved: false
    };
  },
  async mounted() {
    try {
      const ensName = await this.$cacheApi.$get(`/network/1/ens/reverse/${this.address}`);
      // console.log("ensName", ensName);
      if (ensName) {
        this.resolved = ensName;
      }
    } catch (e) {
      console.log('Problem with API', e);
    }
  }
};
</script>
<styles lang="scss" scoped>
.ensResolver {
    border: 1px solid #DBDBDB;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-bottom: 10px;

  .tooltip-trigger {
    width: 100%;
    height: 100%;
  }
}
</styles>
