var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    { attrs: { edition: _vm.edition, "transaction-hash": null } },
    [
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    Create unlockable content for your artwork\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _c(
            "section",
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Unlockable link",
                    message:
                      "You are responsible for maintaining the unlockable content, please ensure it is accessible.",
                  },
                },
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: "https://...",
                      type: "text",
                      expanded: "",
                      autofocus: "",
                      required: "",
                    },
                    model: {
                      value: _vm.form.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "link", $$v)
                      },
                      expression: "form.link",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                { attrs: { label: "Message", message: "" } },
                [
                  _c("b-input", {
                    attrs: {
                      maxlength: "1000",
                      type: "textarea",
                      required: "",
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Unlockable content can only be accessed by token holders of this edition."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Further details on how the unlockable feature works can be found\n        "
                ),
                _c(
                  "a",
                  {
                    staticClass: "is-linkable",
                    attrs: {
                      href: "https://docs.knownorigin.io/en/articles/6118698-unlockable-content",
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n          here\n        ")]
                ),
              ]),
              _vm._v(" "),
              _vm.response && _vm.response.success
                ? _c(
                    "p",
                    [
                      _vm._v("\n        Unlockable content saved\n        "),
                      _c("b-icon", {
                        attrs: {
                          icon: "check-circle-outline",
                          size: "is-small",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.error
                ? _c("p", { staticClass: "has-text-danger" }, [
                    _vm._v("\n        Content not saved!\n      "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Unlockable content set!\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              attrs: {
                expanded: "",
                loading: _vm.loading,
                disabled: !_vm.formComplete,
              },
              on: { click: _vm.setUnlockableContent },
            },
            [_vm._v("\n      Confirm\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }