<template>

  <div class="column is-half has-padding-left-3 has-padding-top-0 is-family-tertiary"
       v-if="isValidGatedSale">
    <div class="has-text-weight-bold has-text-left" v-if="hasSalePrice">
      <div class="has-text-weight-bold has-text-left">
        <span class="has-text-left is-family-tertiary" :class="{'has-text-white': isCc}">
          {{ mapActivityEventTitle(activityEvent.eventType, activityEvent.edition.salesType) }}
        </span>
      </div>
      <div class="has-text-weight-bold has-text-left">
        <eth-with-fiat-price class="has-text-weight-bold has-text-left" :class="{'has-text-white': isCc}"
                             :price-in-wei="activityEvent.eventValueInWei"
                             v-if="activityEvent.eventValueInWei">
        </eth-with-fiat-price>
      </div>
    </div>
    <div v-else class="has-text-weight-bold has-text-left">
      <div>
        <p :class="{'is-muted': edition.gatedSale.phases[0].state === 'pre-phase' && !isCc , 'has-text-white': isCc}">
          Early access
        </p>
        <p v-if="edition.gatedSale.phases[0].priceInWei === '0'"
           class="has-text-weight-bold"
           :class="{'is-muted': edition.gatedSale.phases[0].state === 'pre-phase' && !isCc , 'has-text-white': isCc}">
          Free claim
        </p>
        <eth-with-fiat-price
          v-else
          :price-in-wei="edition.gatedSale.phases[0].priceInWei"
          class="has-text-weight-bold"
          :class="{'is-muted': edition.gatedSale.phases[0].state === 'pre-phase' && !isCc , 'has-text-white': isCc}">
        </eth-with-fiat-price>
      </div>
    </div>
  </div>

  <div class="column is-half has-padding-left-3 has-padding-top-0"
       v-else-if="reserveAuctionCountdownInProgress">
    <div class="has-text-weight-bold has-text-left">
      <span class="has-text-left is-family-tertiary" :class="{'has-text-grey': !startDatePassed && !isCc , 'has-text-white': isCc}">
        {{ mapActivityEventTitle(activityEvent.eventType, activityEvent.edition.salesType) }}
      </span>
    </div>
    <div class="has-text-weight-bold has-text-left">
      <eth-with-fiat-price class="has-text-weight-bold has-text-left" :class="{'has-text-white': isCc}"
                           :price-in-wei="activityEvent.eventValueInWei"
                           v-if="activityEvent.eventValueInWei">
      </eth-with-fiat-price>
    </div>
  </div>

  <footer-left-asset-card :edition="edition" v-else>
    <template slot="asset-card-footer-left-title">
      <span v-if="activityEvent.eventValueInWei === '0'" class="has-text-left"
            :class="{'has-text-grey': !startDatePassed && !isCc , 'has-text-white': isCc}">
        Claimed for
      </span>
      <span v-else class="has-text-left is-family-tertiary" :class="{'has-text-grey': !startDatePassed && !isCc, 'has-text-white': isCc}">
        {{ mapActivityEventTitle(activityEvent.eventType, activityEvent.edition.salesType) }}
      </span>
    </template>
    <template slot="asset-card-footer-left">
      <p v-if="activityEvent.eventValueInWei === '0'" :class="{'has-text-white': isCc}"
         class="has-text-weight-semibold">
        Free
      </p>
      <eth-with-fiat-price class="has-text-weight-bold has-text-left" :class="{'has-text-white': isCc}"
                           :price-in-wei="activityEvent.eventValueInWei"
                           v-if="activityEvent.eventValueInWei">
      </eth-with-fiat-price>
    </template>
  </footer-left-asset-card>
</template>
<script>
import {
  isOffersOnlySalesType,
  isReserveAuctionSalesType
} from '../../services/SaleTypes';
import {GET_EDITION_ACTIVE_OFFER} from '../../queries/offersQueries';
import {editionStartDatePassed, mapActivityEventTitle} from '../../services/utils';
import EthWithFiatPrice from '../../components/common/EthWithFiatPrice';
import ReserveAuctionUtils from '../../services/ReserveAuctionUtils';
import editionUtils from '../../services/editionUtils';
import {isCreatorContract} from '../../services/CreatorContractUtils';
import FooterLeftAssetCard from './FooterLeftAssetCard';

export default {
  components: {FooterLeftAssetCard, EthWithFiatPrice},
  props: ['edition', 'activityEvent'],
  data() {
    return {
      editionOffers: null
    };
  },
  computed: {
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    startDatePassed() {
      return editionStartDatePassed(this.edition.startDate);
    },
    reserveAuctionCountdownInProgress() {
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(this.edition);
    },
    isValidGatedSale() {
      return this.edition.gatedSale && this.edition.gatedSale.phases.length > 0 && this.edition.gatedSale.phases[0].state !== 'public';
    },
    hasSalePrice() {
      return this.activityEvent.eventValueInWei && parseInt(this.activityEvent.eventValueInWei) > 0;
    },
    isCc() {
      return isCreatorContract(this.edition);
    }
  },
  async mounted() {
    this.$apollo.addSmartQuery('editionOffers', {
      deep: false,
      query: GET_EDITION_ACTIVE_OFFER,
      client: this.gqlClient,
      variables() {
        return {
          edition: `edition-${this.edition.id ? this.edition.id : '0'}`
        };
      },
      error(error) {
        console.log('error', error);
      }
    });
  },
  methods: {
    isOffersOnlySalesType,
    mapActivityEventTitle,
    isReserveAuctionSalesType
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";
</style>
