var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.token, token: _vm.token, "transaction-hash": null },
    },
    [
      _c(
        "template",
        { slot: "main-content" },
        [
          !_vm.listTokenFlow
            ? _c("modal-image-asset", {
                staticClass: "has-margin-top-7",
                attrs: {
                  "edition-number": _vm.token.editionNumber,
                  metadata: _vm.token.metadata,
                },
              })
            : _c("div", [_vm._v(" ")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.token.metadata,
              "edition-number": _vm.token.editionNumber,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "secondary-content" }, slot: "secondary-content" },
        [
          _c(
            "p",
            {
              staticClass: "hand-pointer",
              on: { click: _vm.copyURLWithToast },
            },
            [
              _c("b-icon", {
                attrs: { icon: "content-copy", size: "is-small" },
              }),
              _vm._v(" Copy share link\n      "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "hand-pointer",
              on: { click: _vm.copyInstaWithToast },
            },
            [
              _c("b-icon", { attrs: { icon: "instagram", size: "is-small" } }),
              _vm._v(" Copy for Instagram share\n      "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "hand-pointer",
              on: { click: _vm.copyTwitterWithToast },
            },
            [
              _c("b-icon", { attrs: { icon: "twitter", size: "is-small" } }),
              _vm._v(" Copy for Twitter share\n      "),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }),
      _vm._v(" "),
      _c("template", { slot: "action-button" }),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button-nevermind" },
        [
          _vm.listTokenFlow
            ? _c(
                "b-button",
                { attrs: { expanded: "" }, on: { click: _vm.dontShowAgain } },
                [_vm._v("\n      Do not show again\n    ")]
              )
            : _c(
                "b-button",
                { attrs: { expanded: "" }, on: { click: _vm.close } },
                [_vm._v("\n      Nevermind\n    ")]
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }