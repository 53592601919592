<template>
  <div
    class="column has-padding-left-3 has-padding-top-0"
    :class="{
      'countdown-started': reserveEditionAuctionCountdownInProgress && !isCc,
      'is-size-7 pl-3 mb-0 pb-0 pt-2 is-full': size === 2,
      'is-half': size === 3,
      'is-size-7 p-0 m-0': size === 1,
    }"
  >
    <div class="has-text-weight-semibold has-text-left">
      <n-link :to="handleMarketPlaceRouting" class="asset-card-wrapped-link">
        <div
          v-if="phase && phase.state !== 'public' && isNotSoldOutPrimary"
          :class="{ 'is-flex is-justify-content-space-between': size === 2 }"
        >
          <div :class="{ 'has-text-white': isCc }">
            <p :class="{ 'is-muted': phase.state === 'pre-phase' && !isCc }">
              Early access
            </p>
            <p
              v-if="phase.priceInWei === '0'"
              class="has-text-weight-semibold"
              :class="{ 'is-muted': phase.state === 'pre-phase' && !isCc }"
            >
              Free claim
            </p>
            <eth-with-fiat-price
              v-else
              :price-in-wei="phase.priceInWei"
              class="has-text-weight-semibold"
              :class="{
                'is-muted': phase.state === 'pre-phase' && !isCc,
                'ml-2': size === 2,
              }"
            >
            </eth-with-fiat-price>
          </div>
        </div>
        <div
          v-else-if="isNotSoldOutPrimary"
          :class="{
            'has-text-grey': !startDatePassed && !isCc,
            'has-text-white': isCc,
            'is-flex is-justify-content-space-between': size === 2,
          }"
          class="is-family-tertiary"
        >
          <section>
            <p v-if="isBuyNowAndOpenSalesType(edition.salesType)">
              Open edition
            </p>
            <p v-else-if="isBuyableSalesType(edition.salesType)">Buy now</p>
            <p v-else-if="isReserveAuctionSalesType(edition.salesType)">
              Reserve auction
            </p>
            <p v-else-if="isOffersOnlySalesType(edition.salesType)">
              <span v-if="edition.activeBid && edition.activeBid.ethValue">
                Current bid
              </span>
              <span v-else> Place a bid </span>
            </p>
          </section>

          <p v-if="isBuyableSalesType(edition.salesType)">
            <eth-with-fiat-price
              :price-in-wei="edition.priceInWei"
              :class="{ 'has-text-white': isCc, 'ml-2': size === 2 }"
            >
            </eth-with-fiat-price>
          </p>
          <p
            v-else-if="
              reserveEditionAuctionCountdownInProgress && edition.activeBid
            "
          >
            <eth-with-fiat-price
              :price-in-eth="edition.activeBid.ethValue"
              :class="{ 'ml-2': siz === 2 }"
            >
            </eth-with-fiat-price>
          </p>
          <p v-else-if="isReserveAuctionSalesType(edition.salesType)">
            <eth-with-fiat-price
              :price-in-wei="edition.reservePrice"
              :class="{ 'has-text-white ml-2': isCc, 'ml-2': size === 2 }"
            >
            </eth-with-fiat-price>
          </p>
          <p v-else-if="edition.activeBid && edition.activeBid.ethValue">
            <eth-with-fiat-price
              :price-in-eth="edition.activeBid.ethValue"
              :class="{ 'has-text-white': isCc, 'ml-2': size === 2 }"
            >
            </eth-with-fiat-price>
          </p>
          <p v-else-if="minBid && minBid.eth_reserve_in_wei > 0">
            <eth-with-fiat-price
              :price-in-wei="minBid.eth_reserve_in_wei"
              :class="{ 'has-text-white': isCc, 'ml-2': size === 2 }"
            >
            </eth-with-fiat-price>
          </p>
          <p
            v-else
            :class="{
              'has-text-grey': !isCc,
              'has-text-white': isCc,
              'ml-2': size === 2,
            }"
          >
            No reserve
          </p>
        </div>

        <div v-else-if="workingToken" class="is-family-tertiary" :class="{ 'is-flex is-justify-content-space-between': size === 2 }">
          <section :class="{'has-text-white': isCc}">
            <p v-if="isBuyNowAndOpenSalesType(edition.salesType)">{{ edition.totalSold }} Edition{{ edition.totalSold > 1 ? 's' : ''}}</p>
            <p v-else-if="isBuyableSalesType(workingToken.salesType)">
              Buy now
            </p>
            <p v-else-if="isReserveAuctionSalesType(workingToken.salesType)">
              Reserve auction
            </p>
            <p v-else-if="isOffersOnlySalesType(workingToken.salesType)">
              <span v-if="tokenOffer && tokenOffer.ethValue > 0">
                Current bid
              </span>
              <span v-else> Place a bid </span>
            </p>
          </section>

          <p v-if="isBuyableSalesType(workingToken.salesType)">
            <eth-with-fiat-price
              :price-in-eth="workingToken.listPrice"
              :class="{ 'has-text-white': isCc }"
            >
            </eth-with-fiat-price>
          </p>
          <p v-else-if="tokenOffer && tokenOffer.ethValue > 0">
            <eth-with-fiat-price
              :price-in-eth="tokenOffer.ethValue"
              :class="{ 'has-text-white': isCc }"
            >
            </eth-with-fiat-price>
          </p>
          <p
            v-else-if="
              isReserveAuctionSalesType(workingToken.salesType) &&
              workingToken.listing &&
              workingToken.listing.reservePrice
            "
          >
            <eth-with-fiat-price
              :price-in-wei="workingToken.listing.reservePrice"
              :class="{ 'has-text-white': isCc }"
            >
            </eth-with-fiat-price>
          </p>
          <p v-else-if="minBid && minBid.eth_reserve_in_wei > 0">
            <eth-with-fiat-price
              :price-in-wei="minBid.eth_reserve_in_wei"
              :class="{ 'has-text-white': isCc }"
            >
            </eth-with-fiat-price>
          </p>
          <p
            v-else-if="
              isBuyNowAndOpenSalesType(edition.salesType) &&
              edition.totalSold > 0
            "
            class="has-text-danger"
            :class="{ 'ml-2': size === 2 }"
          >
            Sold out
          </p>
          <p
            v-else
            class="is-family-tertiary"
            :class="{
              'has-text-grey ml-2': !isCc,
              'has-text-white ml-2': isCc,
            }"
          >
            No reserve
          </p>
        </div>

        <div
          v-else-if="isBuyNowAndOpenSalesType(edition.salesType)"
          class="has-text-white is-flex is-justify-content-space-between is-family-tertiary"
          :class="{ 'is-flex-direction-column': size !== 2 }"
        >
          <span
            >{{ edition.totalSold }} Edition{{
              edition.totalSold > 1 ? "s" : ""
            }}</span
          >
          <span
            class="has-text-danger"
            v-if="edition.totalSold > 0"
            :class="{ 'ml-2': size === 2 }"
            >Sold out</span
          >
        </div>

        <!-- No primary or secondary listing - assume open offers -->
        <div
          v-else
          class="is-family-tertiary"
          :class="{
            'has-text-white': isCc,
            'is-flex is-justify-content-space-between': size === 2,
          }"
        >
          <p class="mb-0">Place a bid</p>
          <p :class="{ 'has-text-grey': !isCc }">No reserve</p>
        </div>
      </n-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EthWithFiatPrice from "../../components/common/EthWithFiatPrice";
import editionUtils from "../../services/editionUtils";
import ReserveAuctionUtils from "../../services/ReserveAuctionUtils";
import { editionStartDatePassed } from "../../services/utils";
import {
  getNextStep,
  isBuyableSalesType,
  isBuyNowSalesType,
  isOffersOnlySalesType,
  isOffersSalesType,
  isReserveAuctionSalesType,
  isSteppedSalesType,
  isBuyNowAndOpenSalesType,
} from "../../services/SaleTypes";
import { handleGalleryRouting } from "../../services/routing";
import { isCreatorContract } from "../../services/CreatorContractUtils";

export default {
  components: {
    EthWithFiatPrice,
  },
  props: [
    "edition",
    "workingToken",
    "tokenOffer",
    "minBid",
    "phase",
    "clickHandler",
    "size",
  ],
  computed: {
    ...mapState("web3Store", ["chainId", "gqlClient", "account"]),
    isCc() {
      return isCreatorContract(this.edition);
    },
    reserveEditionAuctionCountdownInProgress() {
      if (this.workingToken) {
        return ReserveAuctionUtils.reserveAuctionCountdownInProgress(
          this.workingToken
        );
      }
      return ReserveAuctionUtils.reserveAuctionCountdownInProgress(
        this.edition
      );
    },
    isNotSoldOutPrimary() {
      return editionUtils.isNotSoldOutPrimary(this.edition);
    },
    startDatePassed() {
      if (this.workingToken) {
        return true;
      }
      return editionStartDatePassed(this.edition.startDate);
    },
    handleMarketPlaceRouting() {
      if (this.isNotSoldOutPrimary || isCreatorContract(this.edition)) {
        return handleGalleryRouting(this.edition, this.clickHandler);
      }
      if (this.workingToken) {
        return { name: "tokens-id", params: { id: this.workingToken.id } };
      }
      return {};
    },
  },
  methods: {
    getNextStep,
    isBuyNowSalesType,
    isOffersOnlySalesType,
    isOffersSalesType,
    isSteppedSalesType,
    isReserveAuctionSalesType,
    isBuyableSalesType,
    isBuyNowAndOpenSalesType,
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/colours";
@import "~bulma/sass/utilities/initial-variables";

.countdown-started {
  background-color: $black;
  color: $white !important;

  div > * {
    background-color: $black;
    color: $white !important;
  }

  span > * {
    background-color: $black;
    color: $white !important;
  }
}
</style>
