<template>
  <div class="columns">
    <div
      class="column p-0 search-background"
      :class="{ 'search-shadow': computeFocus }"
      @keyup.enter="onEnter"
    >
      <client-only>
        <ais-instant-search
          :search-client="searchClient"
          :index-name="'user-profile-index'"
          :stalled-search-delay="200"
          :routing="routingUser"
        >
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="columns is-mobile">
                <div class="column is-full">
                  <ais-search-box placeholder="Search..." ref="search">
                    <template #default="{ isSearchStalled, refine }">
                      <b-field>
                        <b-input
                        class="nav-input"
                          :autofocus="true"
                          @click.native="handleAmplitudeClick('omniClicked')"
                          :placeholder="computedPlaceholder"
                          type="search"
                          icon="magnify"
                          :loading="isSearchStalled"
                          v-model="searchInput"
                          @input="debounceSearch(refine)"
                          icon-right="close-circle"
                          icon-right-clickable
                          @icon-right-click="clearSearch(refine)"
                          @click="enableFocus()"
                        ></b-input>
                      </b-field>
                    </template>
                  </ais-search-box>
                  <div
                    v-if="
                      searchInput != null &&
                      typeof searchInput === 'string' &&
                      searchInput.length > 0 &&
                      omniToggle
                    "
                    class="pl-4 pr-4 pb-2 scrollable-content-omni"
                  >
                    <p
                      class="is-size-7 has-text-weight-medium is-flex is-align-items-center input-cta is-family-tertiary"
                    >
                      <span
                        ><img
                          class="image is-16x16 mr-2"
                          src="/interface/enter-icon.png"
                          alt="" /></span
                      >Search marketplace
                    </p>
                    <h5
                      class="mt-3 mb-15 has-text-weight-bold is-family-tertiary"
                      v-if="!noArtists"
                      @click="() => route('Artists')"
                      @mouseover="highlightArtists = true"
                      @mouseleave="highlightArtists = false"
                      :class="{ 'artist-hover': highlightArtists }"
                    >
                      Artists
                    </h5>

                    <ais-index
                      :index-name="'user-profile-index'"
                      :routing="routingUser"
                      :index-id="'artworks-user-profile-index'"
                    >
                      <ais-configure :hits-per-page.camel="3" />
                      <ais-state-results>
                        <ais-hits :transform-items="transformArtists">
                          <template #default="{ items }">
                            <div
                              v-for="item in items"
                              :key="item.address"
                              class="custom-user-item search-list-item p-0"
                              @click="handleClickProfile(item, 'Artists')"
                            >
                              <search-result-row
                                :address="item.address"
                                style="cursor: pointer"
                                type="user"
                                :item="item"
                                :is-mobile="isMobile"
                              >
                              </search-result-row>
                            </div>
                          </template>
                        </ais-hits>
                      </ais-state-results>
                    </ais-index>
                    <ais-index
                      :index-name="getIndexName.creatorContracts"
                      :routing="routing"
                      :middlewares="middlewares"
                    >
                      <ais-configure
                      :hits-per-page.camel="4"
                      filters="enabled:true"
                      ></ais-configure>
                      <h5
                        v-if="!noContracts"
                        class="mt-2 mb-15 has-text-weight-bold is-family-tertiary"
                        @click="() => route('Contracts')"
                        @mouseover="highlightContracts = true"
                        @mouseleave="highlightContracts = false"
                        :class="{ 'artist-hover': highlightContracts }"
                      >
                        Contracts
                      </h5>
                      <ais-state-results>
                        <ais-hits
                          :class-names="{
                            'ais-Hits': '',
                            'ais-Hits-item':
                              'custom-user-item search-list-item p-0',
                            'ais-Hits-list': '',
                          }"
                          :transform-items="transformContracts"
                        >
                          <template #item="{ item, index }">
                            <contract-search-result-row
                              :item="item"
                              :index="index"
                              :text-wrap-cap="textWrapCapArtworks"
                              :handle-contract-routing="handleContractRouting"
                              :sort-by="sortBy"
                            />
                          </template>
                        </ais-hits>
                      </ais-state-results>
                    </ais-index>
                    <ais-index
                      :index-name="getIndexName.marketplace"
                      :routing="routing"
                      :middlewares="middlewares"
                    >
                      <ais-configure
                        :hits-per-page.camel="3"
                        filters="filters.active:true"
                      ></ais-configure>
                      <h5
                        v-if="!noArtworks"
                        class="mt-2 mb-15 has-text-weight-bold is-family-tertiary"
                        @click="() => route('Artworks')"
                        @mouseover="highlightArtworks = true"
                        @mouseleave="highlightArtworks = false"
                        :class="{ 'artist-hover': highlightArtworks }"
                      >
                        Artworks
                      </h5>
                      <ais-state-results>
                        <ais-hits
                          :class-names="{
                            'ais-Hits': '',
                            'ais-Hits-item':
                              'custom-user-item search-list-item p-0',
                            'ais-Hits-list': '',
                          }"
                          :transform-items="transformArtworks"
                        >
                          <template #item="{ item, index }">
                            <artwork-search-result-row
                              :class="
                                item.filters && item.filters.isCreatorContract
                                  ? 'search-item-style__invert'
                                  : 'search-item-style'
                              "
                              :item="item"
                              :index="index"
                              :text-wrap-cap="textWrapCapArtworks"
                              :handle-amplitude-click="handleAmplitudeClick"
                              :sort-by="sortBy"
                            />
                          </template>
                        </ais-hits>
                      </ais-state-results>
                    </ais-index>
                    <ais-index
                      :index-name="'user-profile-index'"
                      :routing="routingUser"
                      :index-id="'accounts-user-profile-index'"
                    >
                      <h5
                        v-if="!noAccounts"
                        class="mt-2 mb-15 has-text-weight-bold is-family-tertiary"
                        @click="() => route('Accounts')"
                        @mouseover="highlightAccounts = true"
                        @mouseleave="highlightAccounts = false"
                        :class="{ 'artist-hover': highlightAccounts }"
                      >
                        Accounts
                      </h5>
                      <!-- using toggle refinement to set filter and then just not rendering the toggle (other methods would not work-->
                      <ais-toggle-refinement
                        attribute="isArtist"
                        label="Artists"
                        :off="false"
                      >
                        <template #default="{ value, refine, createURL }">
                          <a
                            v-show="false"
                            :href="createURL(value)"
                            @click.prevent="refine(value)"
                          >
                            <b-switch
                              :value="value.isRefined"
                              class="is-primary is-light is-outlined pr-4"
                            >
                            </b-switch>
                          </a>
                        </template>
                      </ais-toggle-refinement>
                      <ais-configure :hits-per-page.camel="3" />
                      <ais-state-results>
                        <ais-hits
                          :class-names="{ 'ais-Hits': '', 'ais-Hits-item': '' }"
                          :transform-items="transformAccounts"
                        >
                          <template #default="{ items }">
                            <div
                              v-for="item in items"
                              :key="item.address"
                              class="custom-user-item search-list-item p-0"
                              @click="handleClickProfile(item, 'Accounts')"
                            >
                              <search-result-row
                                :address="item.address"
                                style="cursor: pointer"
                                type="user"
                                :item="item"
                              >
                              </search-result-row>
                            </div>
                          </template>
                        </ais-hits>
                      </ais-state-results>
                    </ais-index>
                    <h5
                      v-if="
                        noArtists && noArtworks && noAccounts && noContracts
                      "
                      class="mt-3 mb-1 has-text-weight-bold is-family-tertiary"
                    >
                      No search results found
                    </h5>
                    <!-- <h5
                      v-else
                      class="mt-1 mb-3"
                      @click="onEnter(searchInput)"
                      @mouseover="highlightEnter = true"
                      @mouseleave="highlightEnter = false"
                      :class="{ 'artist-hover': highlightEnter }"
                    >
                      Press Enter to search the marketplace
                    </h5> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ais-instant-search>
      </client-only>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';

import 'instantsearch.css/themes/satellite-min.css';
import {createInsightsMiddleware} from 'instantsearch.js/cjs/middlewares/createInsightsMiddleware';
import aa from 'search-insights';
import {mapState} from 'vuex';
import {history as historyRouter} from 'instantsearch.js/cjs/lib/routers';
import _debounce from 'lodash/debounce';

import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
import { buildTitleAndMeta } from '../../services/utils';
import {
  EVENT_NAMES,
  AMPLITUDE_EVENT_PROPERTIES
} from '../../services/analyticsEvents';
import {
  convertRouteToState,
  convertSortBy,
  convertStateToRoute
} from '../../services/search';
import { mapSalesType } from '../../services/SaleTypes';
import SearchResultRow from '../../components/search/SearchResultRow';
import { handleGalleryRouting } from '../../services/routing';
import ContractSearchResultRow from './ContractSearchResultRow';
import ArtworkSearchResultRow from './ArtworkSearchResultRow';

export default {
  components: {
    ArtworkSearchResultRow,
    ContractSearchResultRow,
    SearchResultRow
  },
  props: ['isMobile'],
  data() {
    const self = this;
    let sortBy = 'mainnet-marketplace-index_extended_trendingScore_desc';

    function manuallySetSortBy(routeState) {
      if (routeState.sortBy) {
        sortBy = convertSortBy(routeState.sortBy);
        self.$set(self, 'sortBy', sortBy);
      }
    }

    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
      onEvent: (event, aa) => {
        const {insightsMethod, payload} = event;

        aa(insightsMethod, payload);
      }
    });

    return {
      searchClient: algoliasearch(
        `${process.env.ALGOLIA_APP_ID}`,
        `${process.env.ALGOLIA_API_KEY}`
      ),
      routingUser: {
        router: historyRouter(),
        stateMapping: singleIndex('user-profile-index')
      },
      routing: {
        router: historyRouter(),
        stateMapping: {
          stateToRoute(uiState) {
            return convertStateToRoute(uiState);
          },
          routeToState(routeState) {
            manuallySetSortBy(routeState);
            return convertRouteToState(routeState);
          }
        }
      },
      middlewares: [insightsMiddleware],
      sortBy,
      filterDrawer: false,
      artistNameSearch: false,
      searchInput: null,
      insightsUserToken: null,
      noArtists: true,
      noArtworks: true,
      noContracts: true,
      noAccounts: true,
      textWrapCap: this.isMobile ? 20 : 34,
      textWrapCapArtworks: this.isMobile ? 15 : 31,
      highlightArtists: false,
      highlightContracts: false,
      highlightAccounts: false,
      highlightArtworks: false,
      omniToggle: false,
      highlightEnter: false,
      focus: false,
      onMarketplace: null
    };
  },
  computed: {
    ...mapState('web3Store', ['account', 'chainId']),
    getIndexName() {
      switch (`${this.chainId}`) {
        case '5':
          return {
            marketplace: 'goerli-marketplace-index',
            creatorContracts: 'creator-contracts-goerli'
          };
        default:
          return {
            marketplace: 'mainnet-marketplace-index',
            creatorContracts: 'creator-contracts-mainnet'
          };
      }
    },
    computeFocus() {
      return this.focus;
    },
    computedPlaceholder() {
      return this.isMarketplace()
        ? 'Search marketplace...'
        : 'Search artists, art and accounts...';
    }
  },
  mounted() {
    this.isMarketplace();
  },
  methods: {
    handleContractRouting(item) {
      this.handleAmplitudeClick(
        'omniResultClicked',
        item.name,
        this.searchInput,
        'Contracts'
      );
      this.handleAmplitudeClick('omniSearchPerformed', '', this.searchInput);
      this.searchInput = '';
      this.$router.push(`/contract/${item.address}`);
      this.$emit('show-search', false);
    },
    isMarketplace() {
      //  return this.$route.name === 'marketplace';
      //  override but leaving functionality in for further search bar advancement
      return false;
    },
    enableFocus() {
      this.focus = true;
    },
    route(category) {
      this.omniToggle = false;
      this.handleAmplitudeClick(
        'omniCategoryClicked',
        '',
        this.searchInput,
        category
      );
      const query = {
        searchInput: this.searchInput,
        isArtist: category === 'Artists'
      };
      // Returns the correct URL and query
      if (category !== 'Artworks' && category !== 'Contracts') {
        this.$router.push({ name: 'search', query });
      } else if (category === 'Artworks' && category !== 'Contracts') {
        delete query.isArtist;
        this.$router.push({ name: 'marketplace', query });
      } else {
        this.$router.push({ name: 'marketplace', query: { creator: true } });
      }
      setTimeout(() => (this.omniToggle = false), 1000);
    },
    onEnter() {
      this.omniToggle = false;
      this.handleAmplitudeClick(
        'omniCategoryClicked',
        '',
        this.searchInput,
        'Artworks'
      );
      this.handleAmplitudeClick('omniSearchPerformed', '', this.searchInput);
      this.$router.push({
        name: 'marketplace',
        query: {searchInput: this.searchInput}
      });
      setTimeout(() => (this.omniToggle = false), 1000);
    },
    removeAccountsFilter(items) {
      return items.filter(item => item.isArtist);
    },
    transformArtworks(items) {
      if (items.length === 0) {
        this.noArtworks = true;
      } else {
        this.noArtworks = false;
      }
      return items;
    },
    transformContracts(items) {
      if (items.length === 0) {
        this.noContracts = true;
      } else {
        this.noContracts = false;
      }
      return items;
    },
    transformArtists(items) {
      items = items.filter(item => item.isArtist);
      if (items.length > 4) {
        items = items.slice(0, 4);
      }
      if (items.length === 0) {
        this.noArtists = true;
      } else {
        this.noArtists = false;
      }
      return items;
    },
    transformAccounts(items) {
      if (items.length === 0) {
        this.noAccounts = true;
      } else {
        this.noAccounts = false;
      }
      return items;
    },
    handleClickProfile(item, category) {
      this.handleAmplitudeClick(
        'omniResultClicked',
        item.username,
        this.searchInput,
        category
      );
      this.searchInput = '';
      this.$router.push({
        name: 'profile-id',
        params: {id: item.address.toLowerCase()}
      });
      this.$emit('show-search', false);
    },
    handleMarketPlaceRouting(item, algoliaInfo) {
      const route = handleGalleryRouting(item);
      if (algoliaInfo) {
        const query = {};
        if (algoliaInfo.queryId) {
          query.query_id = algoliaInfo.queryId;
        }
        if (algoliaInfo.index) {
          query.index = algoliaInfo.index;
        }
        if (algoliaInfo.position) {
          query.position = algoliaInfo.position;
        }
        route.query = query;
      }

      this.handleAmplitudeClick(
        'omniResultClicked',
        item.name,
        this.searchInput,
        'Contracts'
      );
      this.handleAmplitudeClick('omniSearchPerformed', '', this.searchInput);
      this.searchInput = '';
      this.$router.push(`/contract/${item.address}`);
      this.$emit('show-search', false);
    },
    visibilityChanged(isVisible, entry, refineNext) {
      if (isVisible) {
        refineNext();
      }
    },
    async handleAmplitudeClick(
      type,
      value = null,
      query = null,
      category = null
    ) {
      const source = this.$route.name;
      if (type === 'omniClicked') {
        await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.omniClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: source
          }
        });
      } else if (type === 'omniResultClicked') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.omniResultClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.value]: value,
            [AMPLITUDE_EVENT_PROPERTIES.query]: query,
            [AMPLITUDE_EVENT_PROPERTIES.category]: category
          }
        });
      } else if (type === 'omniCategoryClicked') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.omniCategoryClicked,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.query]: query,
            [AMPLITUDE_EVENT_PROPERTIES.category]: category
          }
        });
      } else if (type === 'omniSearchPerformed') {
        this.$store.dispatch('analytics/amplitudeStore/logEvent', {
          name: EVENT_NAMES.omniSearchPerformed,
          properties: {
            [AMPLITUDE_EVENT_PROPERTIES.source]: source,
            [AMPLITUDE_EVENT_PROPERTIES.query]: query
          }
        });
      }
    },
    toggleArtistNameSearch() {
      this.artistNameSearch = !this.artistNameSearch;
    },
    debounceSearch: _debounce(function (refine) {
      refine(this.searchInput);
      this.$emit('update-search-string', this.searchInput);
      this.omniToggle = !this.isMarketplace();
    }, 500),
    clearSearch(refine) {
      this.searchInput = '';
      refine(this.searchInput);
      this.$emit('show-search', false);
    },
    transformSaleTypeItems(items) {
      return items.map(item => ({
        ...item,
        label: mapSalesType(item.label.toUpperCase())
      }));
    }
  },
  head() {
    return buildTitleAndMeta({
      title: 'NFT Search | KnownOrigin',
      description: 'KnownOrigin marketplace',
      url: 'https://knownorigin.io/marketplace/',
      canonicalExt: '/marketplace'
    });
  }
};
</script>
<style lang="scss">
.custom-user-item {
  box-shadow: none !important;
  display: inline !important;
  background-color: transparent;
}

.b-checkbox.checkbox input[type="checkbox"] + .check {
  border-radius: 0.2rem !important;
}

.input-cta {
  position: absolute;
  right: 40px;
  top: 12px;
  opacity: 0.4;
  color: #363636;
  user-select: none;

  img {
    pointer-events: none;
  }
}

.artwork-availability {
  * {
    font-weight: 400 !important;
  }
}

.mb-15 {
  margin-bottom: 0.37rem;
}

@media screen and (max-width: 1023px) {
  .input-cta {
    top: 25px;
    right: 55px;
  }
}

@media screen and (max-width: 500px) {
  .input-cta {
    display: none !important;
  }
}
</style>
