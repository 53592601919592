<template>
  <div>
    <client-only>
      <header class="header-image has-text-white has-background-grey-darker"
              :style="{ backgroundImage: `url(${image})`}">
        <section class="hero">
          <div class="hero-body">
            <div class="container content" v-if="headerImage">
              <h2 class="title has-text-white has-margin-top-1 is-size-1">
                <n-link :to="{name:'gallery-id', params:{id: headerImage.id }}"
                        class="is-size-10 has-text-white has-padding-top-6"
                        v-if="headerImage">
                  <b-icon icon="video" v-if="isVideo" size="is-small" class="is-pulled-right" custom-class="is-size-3">
                  </b-icon>
                  <span v-if="msg">{{ msg }}</span>
                    <br /> {{ headerImage.metadata.artist }}
                </n-link>
              </h2>
              <div class="content">
                <div class="field is-grouped">
                  <p class="control">
                    <n-link :to="{name:'gallery-id', params:{id: headerImage.id }}" class="button is-white is-outlined">
                      View artwork
                    </n-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    </client-only>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import _shuffle from 'lodash/shuffle';
  import _sample from 'lodash/sample';
  import _filter from 'lodash/filter';
  import _includes from 'lodash/includes';
  import {mimeTypeUtils, urlUtils} from '@knownorigin/ko-token-uri-tools';
  import {HEADER_BANNER_EDITION} from '../../queries/editionQueries';

  export default {
    props: ['likeAddress', 'msg'],
    computed: {
      ...mapState('web3Store', [
        'gqlClient',
        'chainId'
      ])
    },
    data() {
      return {
        headerImage: null,
        image: '',
        imageUrl: '',
        isVideo: false,
        editionId: ''
      };
    },
    async mounted() {
      await this.loadFavourites();

      if (this.editionId) {
        this.$apollo.addSmartQuery('editions', {
          client: this.gqlClient,
          query: HEADER_BANNER_EDITION,
          fetchPolicy: 'network-only',
          variables() {
            return {
              id: this.editionId
            };
          },
          update({editions}) {
            this.headerImage = _sample(_filter(editions, ({metadata}) => {
              return metadata && !_includes(metadata.image_type, 'svg') && !_includes(metadata.image_type, 'glb');
            }));

            if (this.headerImage && this.headerImage.metadata) {
              this.isVideo = mimeTypeUtils.isVideoType(this.headerImage.metadata.image_type) || urlUtils.isSupportedVideo(this.headerImage.metadata.image);

              this.setImage(this.isVideo);
            }
          },
          error(error, vm, key, type, options) {
            console.log('error', error);
          }
        });
      }
    },
    methods: {
      setImage(useCard = false) {
        this.imageUrl = `${this.$cdnApi.defaults.baseURL}/cdn/images/network/1/edition/${this.headerImage.id}${useCard ? '/cover' : ''}`;
        const highResImage = new Image();
        highResImage.onload = () => {
          this.image = this.imageUrl;
        };
        highResImage.src = this.imageUrl;
      },
      async loadFavourites() {
        return this.$api.$get(`/network/${this.chainId}/likes/address/${this.likeAddress}?limit=100&offset=0`)
          .then(({likes}) => {
            console.log('featured list banner', likes);

            this.editionId = likes && likes.length > 0 ? _shuffle(likes)[0].edition : null;
          });
      }
    }
  };
</script>
