var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.imageUrl,
          expression: "imageUrl",
          arg: "background-image",
        },
      ],
      staticClass: "header-image has-text-white has-background-black",
    },
    [
      _c("section", { staticClass: "hero" }, [
        _c("div", { staticClass: "hero-body" }, [
          _c("div", { staticClass: "container content" }, [
            _c(
              "h1",
              { staticClass: "has-text-white has-margin-top-1" },
              [
                _vm.isVideo
                  ? _c("b-icon", {
                      staticClass: "is-pulled-right",
                      attrs: {
                        icon: "video",
                        size: "is-medium",
                        "custom-class": "is-size-3",
                      },
                    })
                  : _vm._e(),
                _vm._v("\n\n          Discover"),
                _c("br"),
                _vm._v("\n          rare digital art"),
                _c("br"),
                _vm._v("\n          and collect NFTs\n          "),
                _c("br"),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                {
                  staticClass: "field is-grouped",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickEventTracking(
                        true,
                        "View marketplace",
                        "marketplace"
                      )
                    },
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "control" },
                    [
                      _c(
                        "n-link",
                        {
                          staticClass: "button is-white is-outlined",
                          attrs: { to: { name: "marketplace" } },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleItemClickInsights(
                                "button",
                                "",
                                "header banner"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                View marketplace\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.headerImage
              ? _c(
                  "div",
                  {
                    staticClass: "content",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickEventTracking(
                          false,
                          `gallery/${_vm.headerImage.id}`,
                          "gallery"
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "n-link",
                      {
                        staticClass:
                          "is-size-10 has-text-white has-padding-top-6",
                        attrs: {
                          to: {
                            name: "gallery-id",
                            params: { id: _vm.headerImage.id },
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleItemClickInsights(
                              "link",
                              _vm.headerImage.id,
                              "header banner",
                              "token page"
                            )
                          },
                        },
                      },
                      [
                        _c("artwork-name-and-artist", {
                          staticStyle: { "overflow-wrap": "anywhere" },
                          attrs: {
                            metadata: _vm.headerImage.metadata,
                            "edition-number": _vm.headerImage.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }