import gql from 'graphql-tag';
import {
  ACTIVE_EDITION_BID,
  COLLECTOR,
  METADATA_FIELDS,
  RESERVE_AUCTION_FIELDS,
  STEP_SALE_FIELDS,
  GATED_SALE_FIELDS,
  CREATOR_CONTRACT_FIELDS
} from './_commonModels';
import {
  TOKEN_FIELDS
} from './tokenQueries';

const moment = require('moment');

export const EDITION_FIELDS = `
  id
  version
  salesType
  editionNmber
  priceInWei
  totalSupply
  totalAvailable
  totalSold
  totalBurnt
  tokenURI
  collective {
    recipients
  }
  tokenIds
  createdTimestamp
  creatorContract {
  ${CREATOR_CONTRACT_FIELDS}
  }
  artistAccount
  artistCommission
  optionalCommissionAccount
  optionalCommissionRate
  auctionEnabled
  collaborators
  totalEthSpentOnEdition
  offersOnly
  isGenesisEdition
  isEnhancedEdition
  isOpenEdition
  remainingSupply
  startDate
  endDate
  currentStep
  active
  metadataPrice
  ${ACTIVE_EDITION_BID}
  sales {
    transferCount
    primaryValueInEth
    birthTimestamp
    lastTransferTimestamp
  }
  transfers {
    id
    tokenId
  }
  ${COLLECTOR('allOwners')}
  ${METADATA_FIELDS}
  ${RESERVE_AUCTION_FIELDS}
  ${STEP_SALE_FIELDS}
  ${GATED_SALE_FIELDS}
`;

const EDITION_BY_ID_WITH_TOKENS_QUERY_DEFINITION = `
  editionById($id: String!) {
    editionById: editions(first: 1, where:{ id: $id, active: true} subgraphError: allow)  {
      ${EDITION_FIELDS}
      tokens {
        ${TOKEN_FIELDS}
        transfers(first:1, orderBy:timestamp, orderDirection:"desc" subgraphError: allow){
        id
        transactionHash
        from
        to
        timestamp
      }
      }
    }
  }
`;

export const EDITION_BY_ID_WITH_TOKENS_QUERY = gql(`
  query ${EDITION_BY_ID_WITH_TOKENS_QUERY_DEFINITION}
`);

const EDITION_BY_ID_DEFINITION = `
  editionById($id: String!) {
    editionById: editions(first: 1, where:{ id: $id, active: true} subgraphError: allow)  {
      ${EDITION_FIELDS}
    }
  }
`;

export const EDITION_BY_ID_QUERY = gql(`
  query ${EDITION_BY_ID_DEFINITION}
`);

export const FRONT_PAGE_EDITIONS = gql(`{
  editions(where: {
     active: true
  },
    orderBy: createdTimestamp
    orderDirection:"desc"
    subgraphError: allow
  first: 30){
    id
    version
    ${METADATA_FIELDS}
  }
}`);

export const HEADER_BANNER_EDITION = gql(`
query bannerQuery ($id: String!) {
  editions(where: {
     id: $id
  },
  first: 1
  subgraphError: allow){
    id
    version
    startDate
    collaborators
    artistAccount
    ${METADATA_FIELDS}
  }
}`);

export const EDITIONS_BY_ARTIST = gql(`
query editionsQuery ($first: Int!, $skip: Int!, $artist: String!, $orderBy: String!, $orderDirection: String!, $remainingSupplyLte: Int!, $remainingSupplyGte: Int!) {
 editions: editions(
 first: $first, skip: $skip,
 orderBy: $orderBy,
 orderDirection: $orderDirection,
 subgraphError: allow,
 where: {
  artistAccount: $artist,
  active: true,
  remainingSupply_lte: $remainingSupplyLte
  remainingSupply_gte: $remainingSupplyGte
  }) {
    ${EDITION_FIELDS}
 }
}`);

export const EDITIONS_BY_ARTIST_WITH_EXCLUDE = (excludedEditions, notInExcludes = true, showV4 = true) => {
  const whereQuery = `{
      ${showV4 ? '' : 'version_not: 4,'}
      ${excludedEditions && excludedEditions.length > 0 ? `id_not_in: [${excludedEditions}],` : ''}
      ${notInExcludes ? 'collaborators_contains: $artist,' : 'artistAccount_in: $artist,'}
      active: true,
      remainingSupply_lte: $remainingSupplyLte,
      remainingSupply_gte: $remainingSupplyGte
    }`;

  return gql(`
query editionsQuery (
      $first: Int!,
      $skip: Int!,
      $artist: [String!],
      $orderBy: String!,
      $orderDirection: String!,
      $remainingSupplyLte: Int!,
      $remainingSupplyGte: Int!
  ) {
 editions: editions(
 first: $first,
 skip: $skip,
 orderBy: $orderBy,
 orderDirection: $orderDirection,
 subgraphError: allow,
 where: ${whereQuery}) {
    ${EDITION_FIELDS}
 }
}`);
};

export const EDITIONS_BY_ARTIST_WITH_EXCLUDE_ONLY_CC_COLLABS = (excludedEditions, notInExcludes = true) => {
  const whereQuery = `{
    ${excludedEditions && excludedEditions.length > 0 ? `id_not_in: [${excludedEditions}],` : ''}
    ${notInExcludes ? 'collective_: {recipients_contains: $artist},' : ''}
    version: 4,
    active: true,
    remainingSupply_lte: $remainingSupplyLte,
    remainingSupply_gte: $remainingSupplyGte
  }`;

return gql(`
query editionsQuery (
    $first: Int!,
    $skip: Int!,
    $artist: [String!],
    $orderBy: String!,
    $orderDirection: String!,
    $remainingSupplyLte: Int!,
    $remainingSupplyGte: Int!
) {
ccCollabEditions: editions(
first: $first,
skip: $skip,
orderBy: $orderBy,
orderDirection: $orderDirection,
subgraphError: allow,
where: ${whereQuery}) {
  ${EDITION_FIELDS}
}
}`);
}

export const EDITIONS_BY_ARTIST_WITH_INCLUDE = (includedEditions) => {
  const whereQuery = `{
      ${includedEditions && includedEditions.length > 0 ? `id_in: [${includedEditions}],` : ''}
      collaborators_contains: $artist,
      active: true,
    }`;

  return gql(`
query editionsQuery ($first: Int!, $skip: Int!, $artist: [String!]) {
 editions: editions(
 first: $first,
 skip: $skip,
 subgraphError: allow,
 where: ${whereQuery}) {
    ${EDITION_FIELDS}
 }
}`);
};

export const EDITIONS_BY_ON_SALE = (showV4 = true) => {
  const whereQuery = `{
      ${showV4 ? '' : 'version_not: 4,'}
      collaborators_contains: $artist,
      active: true,
      salesType_in: ["1", "2", "4", "5"],
      remainingSupply_gt: "0",
      metadataPrice_lt: "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  }`

  return gql(`
  query editionsQuery ($first: Int!, $skip: Int!, $artist: [String!], $orderBy: String, $orderDirection: String) {
  editions: editions(
  orderBy: $orderBy,
  orderDirection: $orderDirection,
  subgraphError: allow,
  first: $first,
  skip: $skip,
  where: ${whereQuery}) {
    ${EDITION_FIELDS}
 }
  }`)
}

export const FEATURED_EDITIONS = gql(`query editionsQuery ($first: Int!, $skip: Int!) {
  featured: editions (first: $first, skip: $skip,  orderBy: totalEthSpentOnEdition, orderDirection: desc, where: {active: true} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const OPEN_EDITIONS = gql(`query editionsQuery ($first: Int!, $skip: Int!) {
  editions (first: $first, skip: $skip, orderBy: createdTimestamp, orderDirection: desc, where: {salesType: "6"} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const EDITIONS_BY_TAG_SEARCH = gql(`query editionsQuery ($first: Int!, $skip: Int!, $search: String!) {
 editions: editions(first: $first, skip: $skip, orderBy: createdTimestamp, orderDirection: desc, where: {metadataTagString_contains: $search, active: true} subgraphError: allow) {
    ${EDITION_FIELDS}
 }
}`);

export const MORE_FROM_THIS_ARTIST = gql(`
query editionsQuery ($first: Int!, $skip: Int!, $search: String!, $orderBy: String!, $orderDirection: String!, $edition: String!) {
 editions: editions(
 first: $first,
 skip: $skip,
 orderBy: $orderBy,
 orderDirection: $orderDirection,
 subgraphError: allow,
 where: {
  artistAccount: $search,
  id_not: $edition,
  active: true
  }) {
    ${EDITION_FIELDS}
 }
}`);

export const EDITIONS_FREE_TEXT_SEARCH = gql(`query editionsQuery ($first: Int!, $skip: Int!, $search: String!, $orderBy: String!, $orderDirection: String!) {
  editions: editionSearch(text: $search, first: $first, skip: $skip, where: {remainingSupply_gte: 1} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const RECENT_EDITIONS = gql(`query editionsQuery ($first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!) {
  editions (first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: {active: true, startDate: 0, remainingSupply_gte: 1} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const RECENT_1OF1_EDITIONS = gql(`query editionsQuery ($first: Int!) {
  editions (first: $first, orderBy: createdTimestamp, orderDirection: desc, where: {active: true, totalAvailable: 1, startDate: 0, remainingSupply_gte: 1 } subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const LAST_ONE_NEWEST = gql(`query editionsQuery ($first: Int!) {
  editions (first: $first, orderBy: createdTimestamp, orderDirection: desc, where: {active: true, totalAvailable_gt: 1, remainingSupply: 1} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const RECENT_SCHEDULED_ON_SALE_EDITIONS = gql(`query editionsQuery ($first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!, $startGt: Int!, $startLt: Int!) {
  recentScheduledEditions: editions (first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: {active: true, startDate_gt: $startGt, startDate_lt: $startLt, remainingSupply_gte: 1, reserveAuctionEndTimestamp: "0"} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const RECENT_SCHEDULED_EDITIONS = gql(`query editionsQuery ($first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!, $startGt: Int!, $startLt: Int!) {
  soonScheduledEditions: editions (first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: {active: true, startDate_gt: $startGt, startDate_lt: $startLt} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const RECENT_EDITIONS_GALLERY_SEARCH = ({excludeOffersOnly = false}) => {
  const whereQuery = `
      totalAvailable_lte: $totalAvailable,
      totalSupply_gte: $totalSupply,
      ${excludeOffersOnly ? 'offersOnly: false' : ''}
      active: true
    `;
  return gql(`
  query editionsQuery ($first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!, $totalSupply: Int!, $totalAvailable: Int!) {
    editions (first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection,
    where: {${whereQuery}} subgraphError: allow) {
      ${EDITION_FIELDS}
    }
  }`);
};

export const EDITIONS_GALLERY_SEARCH = ({
                                          remainingSupply = 0,
                                          totalAvailable = 1000,
                                          isGenesisEdition = false,
                                          offersOnly = false,
                                          showPrimaryOnly = true,
                                          excludeOffersOnly = false,
                                          reserveAuctions = false,
                                          onSaleSoon = false
                                        }) => {
  const now_minus_1_day = moment().add(-1, 'days').local().unix();
  const now_plus_1_day = moment().add(1, 'days').local().unix();
  const now_plus_10_days = moment().add(10, 'days').local().unix();
  const whereOptions = `
    revokedApproval: false
    ${isGenesisEdition ? 'isGenesisEdition: true,' : ''}
    ${totalAvailable === 1 ? 'totalAvailable: 1' : `totalAvailable_lte: ${totalAvailable}`},
    remainingSupply_gte: ${remainingSupply},
    ${offersOnly ? 'offersOnly: true,' : excludeOffersOnly ? 'offersOnly: false,' : ''}
    ${reserveAuctions ? 'salesType: "5", reserveAuctionEndTimestamp_not: "0"' : ''}
    ${showPrimaryOnly ? 'remainingSupply_gte: 1,' : ''}
    ${onSaleSoon ? `startDate_gte:${now_minus_1_day}, startDate_lte:${now_plus_10_days},` : `startDate_lte:${now_plus_1_day}`}
    active: true
  `;

  return gql(`
      query editionsQuery (
        $first: Int!, $skip: Int!, $orderBy: String!, $orderDirection: String!) {
        editions (first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, subgraphError: allow,
        where: {
          ${whereOptions}
        }) {
          ${EDITION_FIELDS}
        }
      }`);
};

export const ARTIST_EDITION_INSIGHTS = gql`
  query GetEditionInsights($address: String!) {
    editions(where: {artistAccount: $address, active: true, totalSold_gt: 0}, orderBy: createdTimestamp, orderDirection: desc subgraphError: allow) {
      ${EDITION_FIELDS}
    }
  }
`;

export const ACTIVE_EDITIONS = (ids) => {
  const whereClause = `where: {id_in: [${ids}] active: true}`;
  return gql(`
  query activeEditions {
     editions(${whereClause} subgraphError: allow) {
        id
      }
  }
`);
};

export const RESERVE_AUCTIONS_ENDING_SOON = gql`
  query ReserveAuctionsEndingSoonQuery($timestamp: String!) {
    reserveAuctionsEndingSoon: editions(where:
    {
      active: true,
      salesType: 5,
      reserveAuctionEndTimestamp_gt: $timestamp,
      reserveAuctionStartDate_lt: $timestamp
    },
      orderBy: reserveAuctionEndTimestamp,
      orderDirection: desc,
      first: 6,
      subgraphError: allow)
    {
      ${EDITION_FIELDS}
    }
  }
`;

export const RESERVE_AUCTIONS_STARTING_SOON = gql`
  query reserveAuctionsStartingSoonQuery($timestamp: String!, $first: Int!) {
    reserveAuctionsStartingSoon: editions(where:
    {
      active: true,
      salesType: 5,
      reserveAuctionStartDate_gt: $timestamp,
    },
      orderBy: reserveAuctionStartDate,
      orderDirection: asc,
      first: $first,
      subgraphError: allow)
    {
      ${EDITION_FIELDS}
    }
  }
`;

export const RECENT_GENESIS_EDITIONS = gql(`query mostRecentGenesis($first: Int!) {
    recentGenesisEditions: editions(first: $first, orderBy: createdTimestamp, orderDirection: desc, where: {active: true, isGenesisEdition: true, remainingSupply_gt: 0}, subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

// Not all edition fields will contain editions so would be wise to fetch a higher number of artists -- is there a way to skip empty fields?
export const UNSOLD_OG_ART = gql(`query unsoldOgArt($first: Int!) {
    unsoldOgArt: artists (where: {active: true, firstEditionTimestamp_lte: 1629691256, editionsCount_gt: 0}, subgraphError: allow) {
    editions (where: {version_lte: 2, remainingSupply_gt: 0} subgraphError: allow) {
      ${EDITION_FIELDS}
    }
  }
}`);

export const BUY_NOW_UNDER_POINT_ONE = gql(`query buyNowUnderPointOne($first: Int!) {
    buyNowUnderPointOne: editions (first: $first, orderBy: createdTimestamp, orderDirection: desc, where: {active: true, salesType: 1, priceInWei_gt : 0, priceInWei_lt : 100000000000000000} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const CREATOR_CONTRACTS = gql(`query editionsQuery ($first: Int!) {
  editions (first: $first, orderBy: createdTimestamp, orderDirection: desc, where: {active: true, remainingSupply_gte: 1, version: 4} subgraphError: allow) {
    ${EDITION_FIELDS}
  }
}`);

export const UNSOLD_CREATOR_EDITIONS = gql(`query unsoldEditions($id: ID!) {
  edition(id: $id) {
    id
    totalSold
    totalAvailable
    tokenIds
    originalEditionSize
  }
}`);
