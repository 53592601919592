var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.topCollectors
    ? _c(
        "div",
        { staticClass: "has-margin-top-7 mb-6 pb-6" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "b-table",
            {
              staticClass: "topCollectors__table is-family-tertiary",
              attrs: {
                data: _vm.topCollectors,
                "default-sort": "totalPurchaseEthSpent",
                "sticky-header": "",
                height: "auto",
                striped: "",
                paginated: "",
                "pagination-simple": "",
                "per-page": 25,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "topCollectors__label topCollectors__label--mobile title mb-3 is-hidden-tablet is-text-weight-bold is-family-tertiary",
                },
                [
                  _vm._v(
                    "\n      Top 250 collectors by total ETH purchased\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("b-table-column", {
                attrs: { field: "address", label: "Collector" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("user-profile-lookup", {
                            attrs: {
                              "artist-account": props.row.address,
                              "metadata-artist": props.row.address,
                              "is-table": true,
                              "no-subtitle": true,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1940512014
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "firstSeen",
                  label: "First purchase",
                  centered: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "is-family-tertiary" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm._f("moment")(
                                    props.row.firstSeen * 1000,
                                    "MMM Do YYYY"
                                  )
                                ) +
                                "\n      "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3544513366
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalPurchaseEthSpent",
                  label: "Spend",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalPurchaseEthSpent"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(column.label) + "\n        "
                          ),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": props.row.totalPurchaseEthSpent,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3211025833
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "totalPurchaseCount",
                  label: "Purchases",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("totalPurchaseCount"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(column.label) + "\n        "
                          ),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "is-family-tertiary" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.row.totalPurchaseCount) +
                                "\n        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2588766555
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "secondarySaleEthTotal",
                  label: "Sales ETH",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("secondarySaleEthTotal"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(column.label) + "\n        "
                          ),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("eth-with-fiat-price", {
                            attrs: {
                              "price-in-eth": props.row.secondarySaleEthTotal,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1205556095
                ),
              }),
              _vm._v(" "),
              _c("b-table-column", {
                staticClass: "is-size-5 is-size-6-mobile",
                attrs: {
                  field: "secondarySaleCount",
                  label: "Sales",
                  centered: "",
                  sortable: "",
                  "custom-sort": _vm.sortableString("secondarySaleCount"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ column }) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(column.label) + "\n        "
                          ),
                          _c("img", {
                            staticClass: "is-hidden-touch",
                            attrs: {
                              src: "/interface/sort-arrow.png",
                              alt: "",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "is-family-tertiary" }, [
                            _vm._v(_vm._s(props.row.secondarySaleCount)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3031757764
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "topCollectors mb-2" }, [
      _c(
        "div",
        {
          staticClass:
            "topCollectors__label is-hidden-mobile title ml-2 has-text-weight-bold is-family-tertiary",
        },
        [_vm._v("\n      Top 50 collectors by total ETH purchased\n    ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }