<template>
  <section>
    Current highest offer
    <eth-with-fiat-price :price-in-wei="weiValue"></eth-with-fiat-price>
    from
    <profile-name-link :eth-account="bidder" />
  </section>
</template>
<script>
import EthWithFiatPrice from '../common/EthWithFiatPrice';
import ProfileNameLink from '../avatar/ProfileNameLink';
export default {
  components: {ProfileNameLink, EthWithFiatPrice},
  props: ['weiValue', 'bidder']
};
</script>
