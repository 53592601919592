var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("hr", { staticClass: "is-marginless" }),
            _vm._v(" "),
            _vm.collective && _vm.collective.recipients
              ? _c(
                  "section",
                  {
                    staticClass: "mb-4 content",
                    staticStyle: { "max-height": "450px", overflow: "scroll" },
                  },
                  [
                    _c("table", { staticClass: "table is-small is-striped" }, [
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.collective.recipients,
                          function (recipient, index) {
                            return _c("tr", { key: index }, [
                              _c(
                                "td",
                                [
                                  _c("user-profile-lookup", {
                                    attrs: {
                                      "artist-account": recipient,
                                      "metadata-artist": recipient,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collective.splits[index] / 100000
                                    ) + "%"
                                  ),
                                ]),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "is-muted-7" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.etherscanTxLink(
                              _vm.collective.transactionHash
                            ),
                            target: "_blank",
                          },
                        },
                        [_vm._v("[view tx]")]
                      ),
                    ]),
                  ]
                )
              : _vm.edition
              ? _c(
                  "section",
                  {
                    staticClass: "mb-4 content",
                    staticStyle: { "max-height": "450px", overflow: "scroll" },
                  },
                  [
                    _c("table", { staticClass: "table is-small is-striped" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c("user-profile-lookup", {
                                attrs: {
                                  "artist-account": _vm.edition.artistAccount,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  (
                                    (parseInt(_vm.edition.artistCommission) /
                                      (parseInt(_vm.edition.artistCommission) +
                                        parseInt(
                                          _vm.edition.optionalCommissionRate
                                        ))) *
                                    100
                                  ).toFixed(0)
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c("user-profile-lookup", {
                                attrs: {
                                  "artist-account":
                                    _vm.edition.optionalCommissionAccount,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  (
                                    (parseInt(
                                      _vm.edition.optionalCommissionRate
                                    ) /
                                      (parseInt(_vm.edition.artistCommission) +
                                        parseInt(
                                          _vm.edition.optionalCommissionRate
                                        ))) *
                                    100
                                  ).toFixed(0)
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.collective
                      ? _c("p", { staticClass: "is-muted-7" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.etherscanTxLink(
                                  _vm.collective.transactionHash
                                ),
                                target: "_blank",
                              },
                            },
                            [_vm._v("[view tx]")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n            Close\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "has-margin-7 has-padding-top-7 has-padding-bottom-7" },
      [
        _c("h2", { staticStyle: { "margin-bottom": "5px" } }, [
          _vm._v("\n            Collaboration\n          "),
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "mb-5" }, [
          _vm._v("\n            Shows splits for this edition\n          "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }