<template>
  <section>
    <offers-combined :address="address"></offers-combined>
  </section>
</template>
<script>
  import OffersCombined from './OffersCombined';

  export default {
    components: {OffersCombined},
    props: ['address']
  };
</script>
