var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-link",
    { attrs: { to: { name: "profile-id", params: { id: _vm.ethAccount } } } },
    [_c("profile-name", { attrs: { "eth-account": _vm.ethAccount } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }