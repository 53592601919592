<template>
    <div class="content">
        <div class="card">
            <div class="card-content has-padding-7">
                <div class="content">

                    <div class="columns has-margin-7">
                        <figure class="mb-4 image is-128x128" v-if="contractImages">
                            <img v-lazy="{ src: contractImages.thumbnail }">
                        </figure>
                        <section class="has-margin-7 py-7">
                            <p class="title is-3 mb-1 is-family-secondary">
                                Burn Creator Contract
                            </p>
                            <p class="title is-6 mt-0 is-family-secondary">
                                {{ contract.name }} by {{ artistName }}
                            </p>
                        </section>
                    </div>

                    <section class="mb-4 content notification" v-if="state === 0">
                        <dl>
                            <li>
                                Burning means you transfer the ownership of this contract to the dead address
                            </li>
                            <span v-if="!hasEditions">
                                <li>
                                    You  won't be able to access or edit this contract anymore and it'll disappear from everywhere on KnownOrigin
                                </li>
                                <li>
                                    You also won't be able to mint new artworks on this contract
                                </li>
                            </span>
                            <span v-else>
                                <li>
                                    You won't be able to mint new artworks on this contract, but your existing artwork can still be accessed
                                </li>
                                <li>
                                    You won't be able to access or edit this contract anymore, but <span class="has-text-weight-semibold">you'll still be paid</span> royalties as normal
                                </li>
                                <br />
                                If you want to burn the artwork on this contract, you'll have to do that seperately.
                                <br />
                            </span>
                            <br />
                            This action <span class="has-text-weight-semibold">cannot</span> be undone.
                        </dl>
                    </section>
                    <section class="mb-4 content notification" v-if="state === 1">
                        <p class="is-6 mb-1">Your transaction is processing</p>
                    </section>
                </div>

                <div class="card-footer">
                    <div class="card-footer-item">
                        <b-button expanded @click="close" :disabled="state === 1">
                            Never mind
                        </b-button>
                    </div>
                    <div class="card-footer-item">
                        <b-button class="button is-danger" expanded @click="performAction" :disabled="state === 1">
                            Burn Contract
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: ['contract', 'contractImages'],
    data() {
        return {
            artistName: '',
            hasEditions: false,
            state: 0
        }
    },
    async mounted() {
        if (parseInt(this.contract.totalNumOfEditions) > 0) {
            this.hasEditions = true;
        }
        await this.loadUser()
    },
    methods: {
        async performAction() {
            const tx = await this.$store.dispatch('web3ActionsStore/burnCreatorContract', {
                contract: this.contract
            });
            if (tx) {
                this.state = 1
                await tx.wait();
            }
            this.$emit('close')
        },
        close() {
            this.$emit('close');
        },
        async loadUser() {
        let name = this.name;
        try {
          const res = await this.$store.dispatch(
            'userStore/getUser',
            this.contract.creator
          );
          if (res.username) {
            name = res.username;
          } else {
            name = this.contract.creator;
          }
        } catch (err) {
          name = this.contract.creator;
        }

      this.artistName = name;
    }
    }
};
</script>

<style>
dl li {
    list-style-position: inside;
    padding: 10px 0 10px 20px;
    text-indent: -1.3em;
}
</style>
