var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "journalHeader" },
    [
      _c(
        "NuxtLink",
        {
          staticClass:
            "journalHeader__back mb-3 is-size-6 has-text-weight-bold is-family-tertiary",
          attrs: { to: "/journal" },
        },
        [_vm._v("Back to Journals")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c(
            "b-tag",
            {
              staticClass:
                "is-uppercase is-family-tertiary has-text-weight-medium mr-3",
              attrs: { type: "is-secondary" },
            },
            [_vm._v("\n      " + _vm._s(_vm.content.journalType) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "time",
            { staticClass: "is-vcentered" },
            [
              _c("b-icon", { attrs: { icon: "calendar", size: "is-small" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "is-size-7 is-family-tertiary has-text-weight-bold",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("moment")(new Date(_vm.content.date), "from")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h1",
        {
          staticClass:
            "journalHeader__header is-family-secondary has-text-weight-black is-size-4 is-size-3-tablet is-size-2-desktop",
        },
        [_vm._v("\n    " + _vm._s(_vm.content.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "journalHeader__image",
        attrs: { src: _vm.content.leadimage },
      }),
      _vm._v(" "),
      _vm.content.description
        ? _c(
            "p",
            {
              staticClass:
                "journalHeader__description is-family-secondary has-text-weight-light is-size-6 is-size-4-tablet pb-6",
            },
            [_vm._v("\n    " + _vm._s(_vm.content.description) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }