var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mb-4" }, [
    _c("article", { staticClass: "tile is-child content" }, [
      _vm.isGatedSale
        ? _c(
            "section",
            [
              _c("marketplace-purchase-gated", {
                attrs: {
                  edition: _vm.edition,
                  phase: _vm.edition.gatedSale.phases[0],
                },
              }),
            ],
            1
          )
        : _vm.isNotSoldOutPrimary
        ? _c("section", [
            _vm.revokedApproval
              ? _c("section", [
                  _c("p", { staticClass: "has-text-danger" }, [
                    _vm._v(
                      "\n          Not for sale - seller has revoked approval\n        "
                    ),
                  ]),
                ])
              : _vm.isBuyNowTypes(_vm.edition.salesType)
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "title is-family-tertiary has-text-weight-bold",
                        },
                        [_vm._v("Buy now")]
                      ),
                      _vm._v(" "),
                      _c("eth-with-fiat-price", {
                        attrs: {
                          size: "is-large",
                          strong: true,
                          "show-fiat": true,
                          "price-in-wei": _vm.edition.priceInWei,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm.isSteppedSalesType(_vm.edition.salesType)
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "title is-family-tertiary has-text-weight-bold",
                        },
                        [_vm._v("Buy now")]
                      ),
                      _vm._v(" "),
                      _c("eth-with-fiat-price", {
                        attrs: {
                          size: "is-large",
                          strong: true,
                          "show-fiat": true,
                          "price-in-wei": _vm.edition.priceInWei,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  parseInt(_vm.edition.remainingSupply) > 1
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "title is-muted is-family-tertiary",
                            },
                            [_vm._v("Next price")]
                          ),
                          _vm._v(" "),
                          _c("eth-with-fiat-price", {
                            attrs: {
                              size: "is-large",
                              type: "is-danger",
                              strong: true,
                              "price-in-wei": _vm.getNextStep(
                                _vm.edition.priceInWei,
                                _vm.edition.stepSaleStepPrice
                              ),
                              "show-fiat": true,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm.isReserveAuctionSalesType(_vm.edition.salesType)
              ? _c("div", [
                  _vm.canResultReserveAuction
                    ? _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "column",
                                attrs: {
                                  type: "is-primary",
                                  size: "is-medium",
                                  expanded: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resultReserveAuction(_vm.edition)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Complete auction\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _vm.hasValidEditionOffer &&
                    _vm.reserveEditionAuctionCountdownInProgress
                      ? _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c("current-highest-offer", {
                              attrs: {
                                "wei-value": _vm.editionOffers[0].weiValue,
                                "bidder-address":
                                  _vm.editionOffers[0].bidder.address,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.hasValidEditionOffer &&
                        _vm.reserveEditionAuctionCountdownComplete
                      ? _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c("winning-offer", {
                              attrs: {
                                "wei-value": _vm.editionOffers[0].weiValue,
                                "bidder-address":
                                  _vm.editionOffers[0].bidder.address,
                              },
                            }),
                          ],
                          1
                        )
                      : !_vm.hasValidEditionOffer
                      ? _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "title is-family-tertiary has-text-weight-bold",
                              },
                              [_vm._v("Reserve price")]
                            ),
                            _vm._v(" "),
                            _c("eth-with-fiat-price", {
                              attrs: {
                                size: "is-large",
                                strong: true,
                                "show-fiat": true,
                                "price-in-wei": _vm.edition.reservePrice,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm.isOffersOnlySalesType(_vm.edition.salesType)
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _vm.editionOffers && _vm.editionOffers.length > 0
                    ? _c(
                        "div",
                        { staticClass: "column is-mobile" },
                        [
                          _c("current-highest-offer", {
                            attrs: {
                              "wei-value": _vm.editionOffers[0].weiValue,
                              "bidder-address":
                                _vm.editionOffers[0].bidder.address,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.offerValidAndNotReached
                    ? _c(
                        "div",
                        { staticClass: "column is-mobile" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "title is-family-tertiary has-text-weight-bold",
                            },
                            [_vm._v("Minimum bid")]
                          ),
                          _vm._v(" "),
                          _vm.minBid && _vm.minBid.eth_reserve_in_wei
                            ? _c("eth-with-fiat-price", {
                                attrs: {
                                  size: "is-large",
                                  strong: true,
                                  "show-fiat": true,
                                  "price-in-wei": _vm.minBid.eth_reserve_in_wei,
                                },
                              })
                            : _c("p", { staticClass: "is-family-tertiary" }, [
                                _vm._v("No reserve"),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.editionStartDatePassed && !_vm.isGatedSale
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _c("edition-sale-count-down", {
                        attrs: { edition: _vm.edition },
                        on: { "start-sale": _vm.onSaleStarted },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isReserveAuctionSalesType(_vm.edition.salesType) &&
            _vm.reserveEditionAuctionCountdownInProgress
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      parseInt(_vm.edition.reserveAuctionEndTimestamp) > 0
                        ? _c("auction-count-down", {
                            attrs: { listing: _vm.edition },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.revokedApproval &&
            _vm.editionStartDatePassed &&
            _vm.isWeb3Connected
              ? _c("section", { staticClass: "columns is-mobile" }, [
                  _vm.isBuyableSalesType(_vm.edition.salesType)
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c("buy-now-buttons", {
                            attrs: {
                              edition: _vm.edition,
                              "algolia-info": _vm.algoliaInfo,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.canPlaceEditionOffer
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "has-margin-bottom-7",
                              attrs: {
                                type: "is-primary",
                                size: "is-medium",
                                expanded: "",
                              },
                              on: { click: _vm.launchMakeOfferEditionModal },
                            },
                            [_vm._v("\n            Place a bid\n          ")]
                          ),
                          _vm._v(" "),
                          _c("wert-topup-link"),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm.workingToken && !_vm.isGatedSale
        ? _c("section", [
            _vm.isReserveAuctionSalesType(_vm.workingToken.salesType) &&
            _vm.workingToken.isListed
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _vm.tokenOffers &&
                  _vm.tokenOffers.length > 0 &&
                  _vm.reserveTokenAuctionCountdownInProgress
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c("current-highest-offer", {
                            attrs: {
                              "wei-value": _vm.tokenOffers[0].weiValue,
                              "bidder-address":
                                _vm.tokenOffers[0].bidder.address,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.tokenOffers &&
                      _vm.tokenOffers.length > 0 &&
                      _vm.reserveTokenAuctionCountdownComplete
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c("winning-offer", {
                            attrs: {
                              "wei-value": _vm.tokenOffers[0].weiValue,
                              "bidder-address":
                                _vm.tokenOffers[0].bidder.address,
                            },
                          }),
                        ],
                        1
                      )
                    : !_vm.tokenOffers || _vm.tokenOffers.length === 0
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "title is-family-tertiary has-text-weight-bold",
                            },
                            [_vm._v("Reserve price")]
                          ),
                          _vm._v(" "),
                          _c("eth-with-fiat-price", {
                            attrs: {
                              size: "is-large",
                              strong: true,
                              "show-fiat": true,
                              "price-in-wei":
                                _vm.workingToken.listing.reservePrice,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reserveTokenAuctionCountdownInProgress
                    ? _c(
                        "div",
                        { staticClass: "column content" },
                        [
                          _vm.workingToken.listing.reserveAuctionEndTimestamp &&
                          parseInt(
                            _vm.workingToken.listing.reserveAuctionEndTimestamp
                          ) > 0
                            ? _c("auction-count-down", {
                                attrs: { listing: _vm.workingToken.listing },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.reserveTokenAuctionCountdownComplete
                    ? _c("div", { staticClass: "column content" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "title is-family-tertiary has-text-weight-bold",
                          },
                          [_vm._v("\n            Auction complete\n          ")]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isReserveAuctionSalesType(_vm.workingToken.salesType) &&
            _vm.workingToken.isListed &&
            !_vm.reserveTokenAuctionCountdownComplete &&
            _vm.isWeb3Connected
              ? _c("div", { staticClass: "columns is-mobile" }, [
                  _c(
                    "div",
                    { staticClass: "column content" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "has-margin-bottom-7",
                          attrs: {
                            type: "is-primary",
                            size: "is-medium",
                            expanded: "",
                          },
                          on: { click: _vm.launchMakeTokenOfferModel },
                        },
                        [_vm._v("\n            Place a bid\n          ")]
                      ),
                      _vm._v(" "),
                      _c("wert-topup-link"),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isBuyNowSalesType(_vm.workingToken.salesType) &&
            _vm.workingToken.isListed
              ? _c("div", [
                  _c("div", { staticClass: "columns is-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "column is-full content" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "title is-family-tertiary has-text-weight-bold",
                          },
                          [_vm._v("Buy now")]
                        ),
                        _vm._v(" "),
                        _c("eth-with-fiat-price", {
                          attrs: {
                            size: "is-large",
                            strong: true,
                            "show-fiat": true,
                            "price-in-eth": _vm.workingToken.listPrice,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isWeb3Connected
                    ? _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "has-margin-bottom-7",
                                attrs: {
                                  type: "is-primary",
                                  size: "is-medium",
                                  expanded: "",
                                },
                                on: { click: _vm.launchBuyNowTokenModal },
                              },
                              [
                                _vm._v(
                                  "\n              Buy with ETH\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.workingToken.version === "3"
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "has-margin-bottom-7",
                                    attrs: {
                                      type: "is-secondary",
                                      size: "is-medium",
                                      expanded: "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: _vm.launchBuyNowTokenWertModal,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Buy with card\n            "
                                    ),
                                  ]
                                )
                              : _c("wert-topup-link"),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isOffersOnlySalesType(_vm.workingToken.salesType) &&
            !_vm.workingToken.isListed
              ? _c("div", [
                  _vm.tokenOffers && _vm.tokenOffers.length > 0
                    ? _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c("current-highest-offer", {
                              attrs: {
                                "wei-value": _vm.tokenOffers[0].weiValue,
                                "bidder-address":
                                  _vm.tokenOffers[0].bidder.address,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.minBid && _vm.minBid.eth_reserve_in_wei
                    ? _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c("p", { staticClass: "title" }, [
                              _vm._v("Minimum bid"),
                            ]),
                            _vm._v(" "),
                            _c("eth-with-fiat-price", {
                              attrs: {
                                size: "is-large",
                                "show-fiat": true,
                                "price-in-wei": _vm.minBid.eth_reserve_in_wei,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canPlaceTokenOffer && _vm.isWeb3Connected
                    ? _c("div", { staticClass: "columns is-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "column content" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "has-margin-bottom-7",
                                attrs: {
                                  type: "is-primary",
                                  size: "is-medium",
                                  expanded: "",
                                },
                                on: { click: _vm.launchMakeTokenOfferModel },
                              },
                              [
                                _vm._v(
                                  "\n              Place a bid\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("wert-topup-link"),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm.moveToOffersTable
        ? _c("div", [
            _c("div", { staticClass: "columns is-mobile" }, [
              _c("div", { staticClass: "column content" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "smooth-scroll",
                        rawName: "v-smooth-scroll",
                        value: {
                          duration: 500,
                          updateHistory: false,
                          offset: -150,
                        },
                        expression:
                          "{ duration: 500, updateHistory: false, offset: -150 }",
                      },
                    ],
                    staticClass:
                      "has-margin-bottom-7 button is-medium is-fullwidth is-secondary",
                    attrs: { href: "#salesTable" },
                  },
                  [_vm._v("\n            View listings\n          ")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isWeb3Connected
        ? _c(
            "section",
            [
              _c(
                "b-button",
                {
                  staticClass: "has-margin-bottom-7 has-margin-top-7",
                  attrs: {
                    type: "is-primary",
                    size: "is-medium",
                    expanded: "",
                    outlined: "",
                    "data-testid": "connectWalletnBtn",
                  },
                  on: { click: _vm.loginToWeb3 },
                },
                [_c("span", [_vm._v("Connect wallet")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSoldOutPrimary &&
      _vm.workingToken &&
      _vm.edition.totalSupply === "1"
        ? _c("div", { staticClass: "columns is-mobile" }, [
            _c(
              "div",
              { staticClass: "column content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "has-text-weight-bold title is-family-tertiary",
                  },
                  [_vm._v("\n          Owned by\n        ")]
                ),
                _vm._v(" "),
                _c("user-profile-lookup", {
                  attrs: {
                    "artist-account": _vm.workingToken.currentOwner.address,
                    "metadata-artist": _vm.workingToken.currentOwner.address,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }