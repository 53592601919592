var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.profileCollection.private ||
    (_vm.profileCollection.private &&
      _vm.isLoggedInAccountViewingPage &&
      _vm.hasAuthToken)
    ? _c("div", [
        _c("div", { staticClass: "level mb-0 level-is-shrinkable" }, [
          _c("div", { staticClass: "level-left" }, [
            _c("div", { staticClass: "level-item" }, [
              _c(
                "h1",
                {
                  staticClass:
                    "is-family-secondary mb-0 is-block is-size-3-mobile",
                },
                [_vm._v(_vm._s(_vm.profileCollection.name))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "level-right" }, [
            _c(
              "div",
              { staticClass: "level-item" },
              [
                _vm.profileCollection.private
                  ? _c(
                      "b-tag",
                      {
                        staticClass: "mr-4 is-uppercase",
                        attrs: { type: "is-dark" },
                      },
                      [_vm._v("PRIVATE")]
                    )
                  : !_vm.profileCollection.private &&
                    _vm.isLoggedInAccountViewingPage &&
                    _vm.hasAuthToken
                  ? _c(
                      "b-tag",
                      {
                        staticClass: "mr-4 is-uppercase",
                        attrs: { type: "is-light" },
                      },
                      [_vm._v("PUBLIC")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.profileCollection.private
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          "aria-role": "list",
                          position: "is-bottom-left",
                        },
                      },
                      [
                        _c(
                          "b-icon",
                          {
                            attrs: { slot: "trigger", icon: "share-variant" },
                            slot: "trigger",
                          },
                          [_vm._v("Share")]
                        ),
                        _vm._v(" "),
                        !_vm.profileCollection.private
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "urlLink",
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyLinkWithToast(
                                      _vm.profileCollection.slug
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Copy share link\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.profileCollection.private
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "instaLink",
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyInstaWithToast(
                                      _vm.profileCollection.slug,
                                      _vm.profileCollection.name
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Copy for Instagram share\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.profileCollection.private
                          ? _c(
                              "b-dropdown-item",
                              {
                                ref: "twitterLink",
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyTwitterWithToast(
                                      _vm.profileCollection.slug,
                                      _vm.profileCollection.name
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Copy for Twitter share\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-dropdown",
                  {
                    attrs: { "aria-role": "list", position: "is-bottom-left" },
                  },
                  [
                    _c(
                      "b-icon",
                      {
                        attrs: { slot: "trigger", icon: "dots-horizontal" },
                        slot: "trigger",
                      },
                      [_vm._v("Share")]
                    ),
                    _vm._v(" "),
                    !_vm.profileCollection.private
                      ? _c(
                          "b-dropdown-item",
                          {
                            ref: "viewCollection",
                            attrs: { "aria-role": "listitem" },
                            on: { click: _vm.viewCollection },
                          },
                          [
                            _vm._v(
                              "\n            View full collection\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLoggedInAccountViewingPage
                      ? _c(
                          "b-dropdown-item",
                          {
                            ref: "edit",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.editCollection(
                                  _vm.profileCollection.id
                                )
                              },
                            },
                          },
                          [_vm._v("\n            Edit collection\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLoggedInAccountViewingPage &&
                    !_vm.profileCollection.private
                      ? _c(
                          "b-dropdown-item",
                          {
                            ref: "edit",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.pinCollection(
                                  _vm.profileCollection.id,
                                  !_vm.isCollectionPinned
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.isCollectionPinned
                                    ? "Unpin collection from the top"
                                    : "Pin collection to top"
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLoggedInAccountViewingPage
                      ? _c(
                          "b-dropdown-item",
                          {
                            ref: "delete",
                            staticClass: "has-text-danger",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCollection(
                                  _vm.profileCollection.id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            Delete collection\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.isLoggedInAccountViewingPage && _vm.hasAuthToken
          ? _c("p", { staticClass: "is-family-primary mb-6" }, [
              _vm._v(
                "Last updated " +
                  _vm._s(
                    _vm._f("moment")(
                      new Date(_vm.profileCollection.lastUpdated),
                      "from"
                    )
                  )
              ),
            ])
          : _c(
              "p",
              { staticClass: "is-family-primary mb-6 mt-2" },
              [
                _vm._l(
                  (_vm.profileCollection.description || "")
                    .slice(0, _vm.descLength)
                    .split("\n"),
                  function (line, index) {
                    return _c("span", { key: index }, [_vm._v(_vm._s(line))])
                  }
                ),
                _vm._v(" "),
                _vm.descLength === 150 &&
                (_vm.profileCollection.description || "").length > 150
                  ? _c(
                      "span",
                      {
                        staticClass: "is-clickable heading",
                        on: { click: _vm.moreDesc },
                      },
                      [
                        _vm._v("\n      Expand Description "),
                        _c("b-icon", {
                          attrs: {
                            icon: "arrow-expand-down",
                            size: "is-small",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
        _vm._v(" "),
        _vm.editions && _vm.editions.length > 0
          ? _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(
                _vm.editionsInCollectionsOrderSlice,
                function (edition, $index) {
                  return _c(
                    "div",
                    {
                      key: $index,
                      staticClass:
                        "column is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd asset-card",
                    },
                    [
                      _c("edition-card", {
                        staticClass: "has-margin-bottom-4",
                        attrs: {
                          edition: edition,
                          cover: true,
                          address: _vm.address,
                          resolution: "card",
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.profile
          ? _c(
              "div",
              { staticClass: "has-text-centered" },
              [
                _c(
                  "n-link",
                  {
                    staticClass: "button is-primary is-outlined",
                    attrs: {
                      to: {
                        name: "collections-slug-id",
                        params: {
                          slug: _vm.profileSlug,
                          id: _vm.profileCollection.slug,
                        },
                      },
                    },
                  },
                  [_vm._v("\n      View full collection\n    ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("hr", { staticClass: "mt-6 mb-5" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }