var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", { staticClass: "has-text-centered" }, [
    _c(
      "a",
      {
        staticClass: "is-link is-linkable is-muted",
        on: { click: _vm.buyEth },
      },
      [_vm._v("Need more ETH?")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }