<template>
  <div class="content" style="margin-left: 40px; margin-right: 40px;">
    <div class="card">

      <div class="card-content has-padding-7">

        <div class="columns is-mobile">
          <div class="column is-one-third">
            <slot name="main-content">
              <modal-image-asset :edition-number="getEditionId" class="has-margin-top-7" v-if="getEditionId">
              </modal-image-asset>
            </slot>
          </div>
          <div class="column">
            <section class="has-padding-top-7 has-padding-bottom-7">
              <h2 style="margin-bottom: 5px">
                <slot name="content-title"></slot>
              </h2>
              <h5 class="mb-5">
                <slot name="content-sub-title"></slot>
              </h5>
              <slot name="total-picker"></slot>
            </section>
          </div>
        </div>

        <div class="content">
          <section class="has-padding-top-7 has-padding-bottom-7">
            <slot name="content-body"></slot>
            <slot name="content-balance">
              <p class="has-text-weight-bold">
                <b-icon size="is-small" icon="wallet-outline" class="ml-1" /> Your balance is <account-balance></account-balance>
              </p>
            </slot>

            <slot name="secondary-content"></slot>

            <section v-if="transactionHash" class="level is-mobile pt-2">
              <div class="level-left">
                <div class="level-item">
                  <b-icon icon="check-circle-outline" size="is-small"></b-icon>
                  &nbsp;
                  <span class="is-uppercase is-size-7">
                    <slot name="action-feedback-label"></slot>
                  </span>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <a class="is-muted-7 is-family-tertiary" :href="etherscanTxLink(transactionHash)" target="_blank">
                    [view tx]
                  </a>
                </div>
              </div>
            </section>
          </section>

          <slot name="primary-action-extras"></slot>
        </div>

      </div>

      <div class="card-footer">
        <div class="card-footer-item">
          <slot name="action-button-nevermind">
            <b-button expanded @click="close">
              Nevermind
            </b-button>
          </slot>
        </div>

        <!-- Primary CTA -->
        <div class="card-footer-item">
          <slot name="action-button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AccountBalance from '../common/AccountBalance';
import ModalImageAsset from '../asset-renders/ModalImageAsset';

export default {
  components: {ModalImageAsset, AccountBalance},
  props: ['edition', 'token', 'transactionHash'],
  methods: {
    close() {
      this.$parent.$emit('close', false);
    }
  },
  computed: {
    ...mapGetters('web3Store', [
      'etherscanTxLink'
    ]),
    getEditionId() {
      if (this.token && this.token.edition.id) {
          return this.token.edition.id;
      }

      return this.edition.id;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
