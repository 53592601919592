var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-template",
    {
      attrs: { edition: _vm.edition, "transaction-hash": _vm.transactionHash },
    },
    [
      _c("template", { slot: "content-title" }, [
        _c("p", [_vm._v("\n      Change listing\n    ")]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content-sub-title" },
        [
          _c("artwork-name-and-artist", {
            attrs: {
              metadata: _vm.edition.metadata,
              "edition-number": _vm.edition.id,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "content-balance" }, [_vm._v("\n     \n  ")]),
      _vm._v(" "),
      _c("template", { slot: "secondary-content" }, [
        _c(
          "section",
          [
            _vm.availableChanges && _vm.availableChanges.length > 0
              ? _c(
                  "b-field",
                  { attrs: { label: "Listing type" } },
                  [
                    _c(
                      "b-select",
                      {
                        attrs: {
                          placeholder: "Select a new sales type",
                          expanded: "",
                          disabled: _vm.transactionHash,
                        },
                        model: {
                          value: _vm.form.selectedSaleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "selectedSaleType", $$v)
                          },
                          expression: "form.selectedSaleType",
                        },
                      },
                      _vm._l(_vm.availableChanges, function (option) {
                        return _c(
                          "option",
                          { key: option.id, domProps: { value: option.id } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.name) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isBuyableSalesType(_vm.form.selectedSaleType)
              ? _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Enter new buy now price",
                      message: `Min. sale price is ${_vm.minAmount}`,
                    },
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        placeholder: "1.23",
                        expanded: "",
                        autofocus: "",
                        disabled: _vm.transactionHash,
                        type: "number",
                        step: _vm.minAmount,
                      },
                      model: {
                        value: _vm.form.priceInEth,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priceInEth", $$v)
                        },
                        expression: "form.priceInEth",
                      },
                    }),
                  ],
                  1
                )
              : _vm.isOffersOnlySalesType(_vm.form.selectedSaleType)
              ? _c(
                  "div",
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Enter minimum bid price",
                          message: `Min. sale price is ${_vm.minAmount}`,
                        },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            placeholder: "1.23",
                            expanded: "",
                            autofocus: "",
                            type: "number",
                            min: 0,
                            max: 100000,
                            disabled: _vm.transactionHash,
                            step: _vm.minAmount,
                          },
                          model: {
                            value: _vm.form.priceInEth,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "priceInEth", $$v)
                            },
                            expression: "form.priceInEth",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-message",
                      {
                        staticClass: "is-size-7",
                        attrs: {
                          type: "is-info",
                          closable: false,
                          duration: 0,
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("\n            Setting an minimum bid "),
                          _c(
                            "span",
                            { staticClass: "has-text-weight-semibold" },
                            [_vm._v("for free")]
                          ),
                          _vm._v(
                            "\n            is a way to signal to buyers that there is a minimum price which you will\n            consider before accepting.\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "has-text-weight-semibold" }, [
                          _vm._v(
                            "\n            You are still responsible for resulting any auction.\n          "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isReserveAuctionSalesType(_vm.edition.salesType) ||
            _vm.isSteppedSalesType(_vm.edition.salesType)
              ? _c(
                  "b-message",
                  {
                    staticClass: "is-size-7",
                    attrs: { type: "is-warning", closable: false, duration: 0 },
                  },
                  [
                    _vm._v(
                      "\n          Converting from a reserve auction or step sale is a one way process, you cannot move back into these sales types.\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.availableChanges && _vm.availableChanges.length === 0
              ? _c(
                  "b-message",
                  {
                    staticClass: "is-size-7",
                    attrs: { type: "is-info", closable: false, duration: 0 },
                  },
                  [
                    _vm._v(
                      "\n          Unfortunately we cannot change this artwork as it was originally uploaded as an unchangeable listing type.\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("template", { slot: "action-feedback-label" }, [
        _vm._v("\n    Listing updated\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "action-button" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                expanded: "",
                disabled:
                  !_vm.availableChanges ||
                  _vm.availableChanges.length === 0 ||
                  _vm.form.priceInEth >= 100000,
              },
              on: { click: _vm.changeSaleType },
            },
            [_vm._v("\n      Change listing\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }