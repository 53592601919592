<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container content has-text-centered">
        <h1 data-testid="pageTitle" class="title mt-1 mb-3 pl-2">
          {{ title }}
        </h1>
        <slot data-testid="pageSubtitle" name="subtitle"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.hero-body {
  padding: 2rem 0rem;
}
</style>
