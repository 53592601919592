import _get from 'lodash/get';
import {ArtworkReserveRequest, domain} from './712sigs';

export const strict = false;

export const state = () => ({
  editionMinimumOffers: {},
  tokenMinimumOffers: {}
});

export const mutations = {

  setEditionMinimumBid(state, {artwork_id, current_owner, minBid}) {
    state.editionMinimumOffers = {
      ...state.editionMinimumOffers,
      [artwork_id]: {
        [current_owner]: minBid
      }
    };
  },

  setTokenMinimumBid(state, {artwork_id, current_owner, minBid}) {
    state.tokenMinimumOffers = {
      ...state.tokenMinimumOffers,
      [artwork_id]: {
        [current_owner]: minBid
      }
    };
  },

  clearEditionMinimumOffer(state, {artwork_id}) {
    state.editionMinimumOffers[artwork_id] = {};
  },

  clearTokenMinimumOffer(state, {artwork_id}) {
    state.tokenMinimumOffers[artwork_id] = {};
  }
};

export const getters = {
  getEditionMinimumOffer: state => (edition) => {
    if (!edition) {
      return null;
    }
    return _get(state.editionMinimumOffers, `[${edition.id}][${edition.artistAccount}]`);
  },
  getTokenMinimumOffer: state => (token) => {
    if (!token || !token.currentOwner) {
      return null;
    }
    return _get(state.tokenMinimumOffers, `[${token.id}][${token.currentOwner.id}]`);
  }
};

export const actions = {

  /// ////////////////////////
  // off-chain minimum bid //
  /// ////////////////////////

  async setOffchainMinimumBid({state, rootState, dispatch}, data) {
    try {
      const {web3Store} = rootState;
      const {chainId, web3} = web3Store;

      // Confirm they are auth'd
      await dispatch('web3Store/createJwtSession', {}, {root: true});
      const authToken = web3Store.authToken;

      console.log('setting off-chain reserve', data);
      /* eslint-disable-next-line no-async-promise-executor */
      const signature = await new Promise(async (resolve, reject) => {
        const signer = await web3.getSigner();
        return signer._signTypedData(domain(chainId), ArtworkReserveRequest, data)
          .then(resolve)
          .catch(reject);
      });
      console.log('Generated signature', signature);

      const postToApi = async () => this.$api({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `bearer ${authToken}`
        },
        data: {
          data,
          signature
        },
        url: `/network/${chainId}/reserve/${data.entity_type}/${data.artwork_id}`
      });

      const results = await postToApi();
      await dispatch('getOffchainMinimumBid', {
        entity_type: data.entity_type,
        artwork_id: data.artwork_id,
        current_owner: data.current_owner,
        refresh: true
      });
      return results.data;
    } catch (e) {
      console.error('Unable to send/sign off-chain reserve', e);
      return null;
    }
  },

  async getOffchainMinimumBid({state, rootState, commit, getters}, {entity_type, artwork_id, current_owner, refresh}) {
    try {
      if (entity_type === 'edition' && !refresh) {
        const minimumOffer = getters.getEditionMinimumOffer({id: artwork_id, artistAccount: current_owner});
        if (minimumOffer) {
          return minimumOffer;
        }
      }

      if (entity_type === 'token' && !refresh) {
        const minimumOffer = getters.getTokenMinimumOffer({id: artwork_id, artistAccount: current_owner});
        if (minimumOffer) {
          return minimumOffer;
        }
      }

      if (!entity_type || !artwork_id) {
        console.debug('Skipping reserve check until we have all data');
        return null;
      }

      const {web3Store} = rootState;
      const {chainId} = web3Store;

      const request = entity_type === 'edition'
        ? `/network/${chainId}/reserve/${entity_type}/${artwork_id}`
        : `/network/${chainId}/reserve/${entity_type}/${artwork_id}/owner/${current_owner}`;

      const results = await this.$api.get(request);
      if (results.data && results.data.eth_reserve_in_wei) {
        if (entity_type === 'edition') {
          commit('setEditionMinimumBid', {artwork_id, current_owner, minBid: results.data});
        }
        if (entity_type === 'token') {
          commit('setTokenMinimumBid', {artwork_id, current_owner, minBid: results.data});
        }
      }
      return results.data;
    } catch (e) {
      console.warn('Unable to retrieve off-chain reserve', e);
      return null;
    }
  }
};
