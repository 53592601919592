<template>
  <div>
    <aside class="content scrollable-content is-family-tertiary" v-if="tokens">
      <ul>
        <li v-for="(token,idx) in tokens" :key="idx">
          <div class="columns is-mobile is-multiline">
            <div class="column is-half">
              <n-link :to="rerouteToken(token)">
                                        <span
                                          class="heading has-text-weight-semibold">#{{
                                            calculateIterativeEditionId(edition, token.id)
                                          }}</span>
              </n-link>
              <span class="heading">
                                        <profile-name-link v-if="token.currentOwner"
                                                           :eth-account="token.currentOwner.id"></profile-name-link>
                                    </span>
            </div>
            <div class="column is-half">
              <span class="heading"><date-from :timestamp-in-secs="token.lastTransferTimestamp" /></span>
              <span class="heading"><eth-with-fiat-price :price-in-eth="token.lastSalePriceInEth || 0" /></span>
            </div>
          </div>
        </li>
      </ul>
    </aside>
    <aside class="content" v-if="tokens && tokens.length > 0">
      <ul>
        <li>
          <div class="columns is-mobile is-multiline">
            <div class="column is-half">
           <span class="heading is-clickable" @click="launchOwnershipGraph">
              Ownership graph <b-icon icon="graphql" size="is-small"></b-icon>
            </span>
            </div>
          </div>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {TOKEN_CURRENT_OWNERS_FOR_EDITION} from '../../queries/tokenQueries';
import ProfileNameLink from '../avatar/ProfileNameLink';
import DateFrom from '../common/DateFrom';
import EditionOwnersGraph from '../modal/data/EditionOwnersGraph';
import {calculateIterativeEditionId} from '../../services/editionUtils'
import {isCreatorContract, splitCreatorEditionId} from '../../services/CreatorContractUtils';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {DateFrom, EthWithFiatPrice, ProfileNameLink},
  props: ['edition'],
  data() {
    return {
      tokens: null
    };
  },
  methods: {
    rerouteToken(token) {
      if (isCreatorContract(token)) {
        const ccDetails = splitCreatorEditionId(token.id);
        return {
          name: 'contract-contract-id',
          params: {id: token.editionNumber, contract: ccDetails.contract}
        }
      }
      return {name: 'tokens-id', params: {id: token.id}};
    },
    calculateIterativeEditionId,
    // called from external components to refresh
    refresh() {
      this.$apollo.queries.tokens.refetch();
      this.$apollo.queries.tokens.setOptions({
        fetchPolicy: 'network-only',
        pollInterval: 15000 // ms
      });
    },
    launchOwnershipGraph() {
      this.$buefy.modal.open({
        parent: this,
        component: EditionOwnersGraph,
        width: 600,
        height: 600,
        props: {editionId: this.edition.id}
      });
    }
  },
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  mounted() {
    if (this.edition) {
      this.$apollo.addSmartQuery('tokens', {
        client: this.gqlClient,
        query: TOKEN_CURRENT_OWNERS_FOR_EDITION,
        pollInterval: 15000, // ms
        variables() {
          return {
            editionId: this.edition.id
          };
        },
        result({data}) {
          if (data && data.tokens) {
            this.tokens = data.tokens[0].tokens
          }
        },
        error(error) {
          console.log('error', error);
        }
      });
    }
  }
};
</script>
