<template>
  <modal-template :edition="token" :token="token" :transaction-hash="transactionHash" v-if="!loading">

    <template slot="content-title">
      <span v-if="isApproved">
        Sell this item
      </span>
      <span v-else>
        Enable marketplace
      </span>
    </template>

    <template slot="content-sub-title">
      <artwork-name-and-artist :metadata="token.metadata" :edition-number="token.editionNumber" v-if="isApproved">
      </artwork-name-and-artist>
    </template>

    <template slot="content-balance">
      &nbsp;
    </template>

    <div slot="secondary-content">
      <section v-if="isApproved">
        <b-field label="Enter ETH sale price" :message="`Min. sale price is ${minBidAmount}`">
          <b-input placeholder="1.23"
                   expanded
                   autofocus
                   min="0.001"
                   v-model="form.listAmount"
                   :disabled="transactionHash"
                   type="number"
                   step="any">
          </b-input>
        </b-field>
        <p>
          <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
            You will receive
            <eth-with-fiat-price :price-in-eth="listAmountToSeller" v-if="listAmountToSeller"
                                 :override-dp="3">
            </eth-with-fiat-price>
            <eth-with-fiat-price :override-dp="3" price-in-eth="0" v-else>
            </eth-with-fiat-price>
            after artist royalties + fees.
            <a href="https://knownorigin.io/journal/platformupdate/making-a-secondary-marketplace"
               target="_blank" class="is-linkable">See fee breakdown</a>
          </b-message>
        </p>
      </section>
      <section v-else>
        <b-message type="is-info" class="is-size-7" :closable="false" :duration="0">
          <p class="title">
            In order to set a sell price you need to enable our new marketplace
            <a :href="etherscanAddressLink(marketplaceAddress)"
               target="_blank" class="is-linkable">smart contract</a>
          </p>
          <section>
            You only need to do this once. The
            <a :href="etherscanAddressLink(marketplaceAddress)"
               target="_blank" class="is-linkable">smart contract</a>
            allows you to resell your artworks, handle payments &amp;
            royalties and to move tokens on your behalf
          </section>
        </b-message>
      </section>
    </div>

    <template slot="action-feedback-label">
      <span v-if="isApproved">
        Listing token
      </span>
      <span v-else>
        Approving marketplace
      </span>
    </template>

    <template slot="action-button">
      <b-button class="button is-primary"
                expanded
                :disabled="disableButton"
                @click="listToken"
                v-if="isApproved">
        Confirm sale price
      </b-button>
      <b-button class="button is-primary"
                expanded
                :disabled="transactionHash"
                @click="approveMarketplace"
                v-else>
        Enable marketplace
      </b-button>
    </template>

  </modal-template>
</template>
<script>
import {ethers} from 'ethers';
import {mapState, mapGetters} from 'vuex';
import ModalTemplate from '../ModalTemplate';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';
import {listOrOfferAmountFloorInEth} from '../../../services/utils';
import EthWithFiatPrice from '@/components/common/EthWithFiatPrice';

export default {
  components: {EthWithFiatPrice, ArtworkNameAndArtist, ModalTemplate},
  props: ['token'],
  data() {
    return {
      transactionHash: null,
      loading: true,
      isApproved: false,
      minBidAmount: listOrOfferAmountFloorInEth,
      form: {
        listAmount: null
      }
    };
  },
  computed: {
    ...mapState('web3ActionsStore', [
      'marketplaceV2',
      'kodaV3',
      'kodaV3SecondaryMarketplace'
    ]),
    ...mapState('web3Store', [
      'account',
      'chainId'
    ]),
    ...mapGetters('web3Store', [
      'etherscanAddressLink'
    ]),
    disableButton() {
      if (this.transactionHash) {
        return true;
      }
      return (Number(this.form.listAmount || 0) < this.minBidAmount);
    },
    listAmountToSeller() {
      if (this.form.listAmount) {
        return Number(this.form.listAmount) * 0.85;
      }
      return false;
    },
    marketplaceAddress() {
      return parseInt(this.token.version) === 2
        ? this.marketplaceV2.address
        : this.kodaV3SecondaryMarketplace.address;
    }
  },
  async beforeMount() {
    try {
      this.loading = true;
      const {isApproved} = await this.$cacheApi.$get(`/network/${this.chainId}/approval/owner/${this.account}/operator/${this.marketplaceAddress}`);
      this.isApproved = isApproved;
    } catch (e) {
      console.log('Problem with API', e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    close(success = false) {
      this.$emit('close', success);
    },
    async listToken() {
      try {
        if (parseInt(this.token.version) === 3) {
          let tx;

          if (this.token.isListed) {
            tx = await this.$store.dispatch('web3ActionsStore/setTokenBuyNowPriceListing', {
              tokenId: this.token.id,
              listPrice: ethers.utils.parseEther(this.form.listAmount).toString()
            });
            this.transactionHash = tx.hash;
          } else {
            tx = await this.$store.dispatch('web3ActionsStore/listTokenForBuyNow', {
              tokenId: this.token.id,
              listPrice: ethers.utils.parseEther(this.form.listAmount).toString(),
              startDate: '0'
            });
            this.transactionHash = tx.hash;
          }

          // wait for the transaction to be mined
          await tx.wait(1);
        } else {
          const tx = await this.$store.dispatch('web3ActionsStore/listToken', {
            token: this.token.id,
            listAmount: ethers.utils.parseEther(this.form.listAmount).toString()
          });
          this.transactionHash = tx.hash;

          // wait for the transaction to be mined
          await tx.wait(1);
        }

        this.close(true);
      } catch (e) {
        console.error(e);
      }
    },
    async approveMarketplace() {
      try {
        const tx = await this.$store.dispatch('web3ActionsStore/approveTokenMarketplace', {
          version: this.token.version
        });
        this.transactionHash = tx.hash;
        // wait for the transaction to be mined
        await tx.wait(1);
        this.close();
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
