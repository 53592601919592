var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "animBanner py-6 px-5 px-6" }, [
    _c(
      "div",
      {
        staticClass:
          "animBanner__container container columns is-centered mx-auto is-flex pl-5 is-align-items-center",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "animBanner__left column is-half-desktop is-full-tablet p-6 has-text-white",
          },
          [
            _vm.content.pretitle
              ? _c(
                  "h3",
                  {
                    staticClass:
                      "animBanner__pretitle is-family-secondary has-text-grey-lighter is-size-7 is-uppercase is-flex is-align-items-center mb-4",
                  },
                  [
                    _c("img", {
                      staticClass: "animBanner__pretitleLogo mr-2",
                      attrs: { src: "/anim/logo.png" },
                    }),
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.content.pretitle) + "\n\t\t\t"
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.title
              ? _c(
                  "h2",
                  {
                    staticClass:
                      "animBanner__title is-family-secondary has-text-weight-black is-size-3 is-size-2-widescreen",
                  },
                  [_vm._v(_vm._s(_vm.content.title) + "\n\t\t\t\t")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.largeText
              ? _c(
                  "h2",
                  {
                    staticClass:
                      "animBanner__largeText is-size-6 is-size-5-widescreen mb-4",
                  },
                  [_vm._v(_vm._s(_vm.content.largeText))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.mainText
              ? _c("div", {
                  staticClass: "animBanner__mainText has-text-grey-lighter",
                  domProps: { innerHTML: _vm._s(_vm.content.mainText) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "animBanner__right column is-half-desktop is-full-tablet p-0",
          },
          [
            _vm.content.image === "creator-contracts"
              ? _c("div", { staticClass: "animBanner__cc" }, [
                  _c(
                    "div",
                    { staticClass: "animBanner__ccCards" },
                    _vm._l(4, function (el, i) {
                      return _c(
                        "div",
                        {
                          key: el + i,
                          staticClass: "animBanner__ccCard",
                          class: "animBanner__ccCard--" + (i + 1),
                        },
                        [
                          _c("img", {
                            staticClass: "animBanner__ccCardImg",
                            attrs: { src: _vm.ccAssets["card" + (i + 1)] },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "animBanner__ccCardBg",
                            attrs: {
                              src: "/anim/banner/creator-contracts/card.svg",
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "animBanner__ccBg is-flex is-align-items-center is-justify-content-space-between",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "animBanner__ccContractHolder" },
                        [
                          _c("img", {
                            staticClass: "animBanner__ccContractUser",
                            attrs: { src: _vm.ccAssets.profile },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "animBanner__ccContract",
                            attrs: {
                              src: "/anim/banner/creator-contracts/contract.svg",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "animBanner__ccArrows",
                        attrs: {
                          src: "/anim/banner/creator-contracts/arrows.svg",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "animBanner__ccMarketplaces",
                        attrs: {
                          src: "/anim/banner/creator-contracts/marketplaces.svg",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "mb-5",
        attrs: {
          href: "https://portal.knownorigin.io/",
          target: "_blank",
          rel: "noreferrer",
        },
      },
      [
        _c(
          "button",
          {
            staticClass:
              "ctaButton mt-3 py-4 px-5 has-background-white is-size-6",
          },
          [_vm._v("Create a contract")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }