var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "artworksCard" }, [
    _c(
      "div",
      { staticClass: "asset-card-wrapped-link hand-pointer" },
      [
        _c(
          "n-link",
          {
            attrs: { to: { name: "tokens-id", params: { id: _vm.token.id } } },
          },
          [
            _c("image-asset", {
              attrs: {
                "edition-number": _vm.token.editionNumber,
                metadata: _vm.token.metadata,
                resolution: _vm.resolution,
                cover: _vm.cover,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "px-5" },
          [
            _c(
              "p",
              {
                staticClass:
                  "artworksCard__title has-text-weight-bold is-family-tertiary py-3",
              },
              [_vm._v(_vm._s(_vm.token.metadata.name))]
            ),
            _vm._v(" "),
            _c("user-profile-lookup", {
              staticClass: "artworksCard__account is-align-items-center",
              attrs: {
                "artist-account": _vm.token.artistAccount,
                size: _vm.size,
                "no-subtitle": true,
                "is-table": true,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "artworksCard__dataHolder mt-4 py-4" },
          _vm._l(_vm.tokenData, function (token, i) {
            return _c("div", { key: token + i }, [
              _c(
                "div",
                {
                  staticClass:
                    "is-flex is-align-items-center is-justify-content-space-between pb-1 px-5",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "artworksCard__label is-size-7 has-text-weight-medium is-family-tertiary",
                    },
                    [_vm._v(_vm._s(token.label))]
                  ),
                  _vm._v(" "),
                  token.valueETH
                    ? _c("eth-with-fiat-price", {
                        attrs: { "price-in-eth": token.valueETH },
                      })
                    : _c("p", [_vm._v(_vm._s(token.value))]),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }