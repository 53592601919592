import { render, staticRenderFns } from "./Editorial2.vue?vue&type=template&id=b4ea41ee&scoped=true&"
import script from "./Editorial2.vue?vue&type=script&lang=js&"
export * from "./Editorial2.vue?vue&type=script&lang=js&"
import style0 from "./Editorial2.vue?vue&type=style&index=0&id=b4ea41ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4ea41ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4ea41ee')) {
      api.createRecord('b4ea41ee', component.options)
    } else {
      api.reload('b4ea41ee', component.options)
    }
    module.hot.accept("./Editorial2.vue?vue&type=template&id=b4ea41ee&scoped=true&", function () {
      api.rerender('b4ea41ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/homepage/editorial/Editorial2.vue"
export default component.exports