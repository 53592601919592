var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "media" }, [
            _c(
              "div",
              { staticClass: "media-content" },
              [
                _vm._l(_vm.charities, function (charity, i) {
                  return _c(
                    "section",
                    {
                      key: i,
                      staticClass:
                        "level item-container is-mobile hand-pointer",
                      class: {
                        "selected-item":
                          _vm.form.selectedCharity &&
                          _vm.form.selectedCharity.name === charity.name,
                      },
                      staticStyle: { height: "80px" },
                      on: {
                        click: function ($event) {
                          return _vm.selectCharity(charity)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "level-left" }, [
                        _c("div", { staticClass: "level-item" }, [
                          _c("img", {
                            staticClass: "image is-hidden-mobile",
                            staticStyle: {
                              "max-width": "75px",
                              "max-height": "75px",
                            },
                            attrs: { src: charity.image, alt: charity.name },
                          }),
                          _vm._v(" "),
                          _vm.$device.isMobile
                            ? _c("div", [
                                _c("div", [
                                  _c("img", {
                                    staticClass: "image",
                                    staticStyle: {
                                      "max-width": "50px",
                                      "max-height": "50px",
                                    },
                                    attrs: {
                                      src: charity.image,
                                      alt: charity.name,
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "is-link has-text-primary",
                                    attrs: {
                                      href: charity.website,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Learn more\n                    "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "level-right" }, [
                        _c(
                          "div",
                          { staticClass: "level-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "is-hidden-mobile is-link has-text-primary pr-3",
                                attrs: {
                                  href: charity.website,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Learn more\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-icon", {
                              class: {
                                "has-text-success":
                                  _vm.form.selectedCharity &&
                                  _vm.form.selectedCharity.name ===
                                    charity.name,
                                "has-text-grey":
                                  !_vm.form.selectedCharity ||
                                  _vm.form.selectedCharity.name !==
                                    charity.name,
                              },
                              attrs: {
                                icon: "check-circle-outline",
                                size: "is-medium",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._m(0),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c(
              "section",
              { staticClass: "has-padding-top-7 has-padding-bottom-7" },
              [
                _c("h2", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(
                    "\n              Donate to sustainable causes\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("h5", { staticClass: "mb-5" }, [
                  _vm._v(
                    "\n              Select the charity and amount you wish to donate\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "mt-2" },
                  [
                    _vm._l(_vm.donationsAmount, function (amounts, i) {
                      return _c(
                        "b-field",
                        { key: i },
                        [
                          _c(
                            "b-radio",
                            {
                              attrs: {
                                value: amounts.amount,
                                "native-value": amounts.amount,
                              },
                              model: {
                                value: _vm.form.selectedAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "selectedAmount", $$v)
                                },
                                expression: "form.selectedAmount",
                              },
                            },
                            [
                              amounts.amount !== "other"
                                ? _c("eth-with-fiat-price", {
                                    attrs: {
                                      "price-in-eth": amounts.amount,
                                      "show-fiat": true,
                                    },
                                  })
                                : _c("span", [_vm._v("Other")]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.form.selectedAmount === "other"
                      ? _c(
                          "b-field",
                          { attrs: { label: "Enter ETH amount" } },
                          [
                            _c("b-input", {
                              attrs: {
                                type: "number",
                                placeholder: "0.25",
                                min: "0.01",
                                step: "0.01",
                                expanded: "",
                                autofocus: "",
                              },
                              model: {
                                value: _vm.form.otherAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "otherAmount", $$v)
                                },
                                expression: "form.otherAmount",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.transactionHash
                  ? _c("section", { staticClass: "level is-mobile pt-2" }, [
                      _c("div", { staticClass: "level-left" }, [
                        _c(
                          "div",
                          { staticClass: "level-item" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-circle-outline",
                                size: "is-small",
                              },
                            }),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "span",
                              { staticClass: "is-uppercase is-size-7" },
                              [
                                _vm._v(
                                  "\n                    Donation sent\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "level-right" }, [
                        _c("div", { staticClass: "level-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "is-muted-7 is-family-tertiary",
                              attrs: {
                                href: _vm.etherscanTxLink(_vm.transactionHash),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    [view tx]\n                  "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              [
                _vm._v("\n            Get your\n            "),
                _c("DonationBadge"),
                _vm._v("\n            badge. "),
                _c(
                  "a",
                  {
                    staticClass: "is-linkable",
                    on: { click: _vm.openDonationClaimModal },
                  },
                  [_vm._v("Claim now")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n              Nevermind\n            ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c(
        "a",
        {
          staticClass: "is-linkable is-primary",
          attrs: {
            href: "https://knownorigin.io/journal/platformupdate/a-step-toward-sustainability",
            target: "_blank",
          },
        },
        [
          _vm._v(
            "\n                More information about these charities\n              "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }