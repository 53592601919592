<template>
  <span v-if="isCreatorContract">
    <span style="position: absolute; bottom: 1rem; right: 1rem; z-index: 10;" class="hand-pointer">
      <b-tooltip position="is-top" class-name="ml-1" type="is-dark" label="This NFT is from a Creator Contract">
        <div class="ccIcon">
           <b-icon icon="dots-circle" type="is-light" size="is-small"></b-icon>
        </div>
      </b-tooltip>
    </span>
  </span>
</template>

<script>

export default {
  components: {},
  props: ['isCreatorContract']
};
</script>

<style lang="scss" scoped>
@import "./assets/colours";
.ccIcon {
  background-color: $dark;
  padding: 0px 4px;
  border-radius: 25%;
  aspect-ratio: 1/1;
}
</style>
