<template>
    <div class="content">
        <div class="card">
            <div class="card-content has-padding-7">
                <div class="content">
                    <section class="has-margin-7 py-7">
                        <p class="title is-3 is-family-secondary">
                            {{ title }}
                        </p>
                    </section>
                    <section class="mb-4 content notification">
                        <dl>
                            <li>
                                This action <span class="has-text-weight-semibold">cannot</span> be undone
                            </li>
                            <li>
                                {{ description }}
                            </li>
                        </dl>
                    </section>
                </div>

                <div class="card-footer">
                    <div class="card-footer-item">
                        <b-button expanded @click="close" :disabled="saving">
                            Nevermind
                        </b-button>
                    </div>
                    <div class="card-footer-item">
                        <b-button class="button is-danger" expanded @click="performAction" :disabled="saving">
                            {{ actionButton }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: ['title', 'description', 'actionButton'],
    data() {
        return {
            saving: false
        }
    },
    methods: {
        performAction() {
            this.$emit('performAction')
            this.$emit('close')
        },
        close() {
            this.$emit('close');
        }
    }
};
</script>
