<template>
  <section class="content is-hidden-mobile mt-6" v-if="moreForTheArtist && moreForTheArtist.length > 0">
    <h2>More by this artist</h2>
    <div class="columns is-multiline">
      <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen-widescreen is-half-third-fullhd" v-for="edition in moreForTheArtist" :key="edition.id">
        <search-edition-wrapper :card-size="2" :id="edition.id" :cover="true" resolution="card" size="is-small" class="has-text-white has-margin-bottom-4">
        </search-edition-wrapper>
      </div>
    </div>
  </section>
</template>

<script>
import _shuffle from 'lodash/shuffle';
import _take from 'lodash/take';
import {mapState} from 'vuex';
import SearchEditionWrapper from './SearchEditionWrapper';
import {MORE_FROM_THIS_ARTIST} from '@/queries/editionQueries';

export default {
    components: {
      SearchEditionWrapper
    },
    props: ['artistAccount', 'currentEdition'],
    data() {
      return {
        moreForTheArtist: null,
        editions: null,
        allHiddenCollectionItems: null
      };
    },
    computed: {
      ...mapState('web3Store', ['gqlClient'])
    },
    mounted() {
      if (this.artistAccount && this.currentEdition) {
        this.$apollo.addSmartQuery('editions', {
          client: this.gqlClient,
          query: MORE_FROM_THIS_ARTIST,
          variables() {
            return {
              first: 6,
              skip: 0,
              search: this.artistAccount.toLowerCase(),
              edition: this.currentEdition.toString(),
              orderBy: 'createdTimestamp',
              orderDirection: 'desc'
            };
          },
          async update({editions}) {
            await this.getPrivateEditions();
            const filteredList = editions.filter(({id}) => !this.allHiddenCollectionItems.includes(id));
            this.moreForTheArtist = _take(_shuffle(filteredList), 2);
          },
          error(error, vm, key, type, options) {
            console.log('error', error);
          }
        });
      }
    },
    methods: {
      async getPrivateEditions() {
        const {allItems} = await this.$store.dispatch('profileCollectionsStore/getPrivateEditions', {
          address: this.artistAccount
        });
        this.allHiddenCollectionItems = allItems;
      }
    }
  };
</script>

<style>
</style>
