var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      staticStyle: { "margin-left": "40px", "margin-right": "40px" },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content has-padding-7" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "section",
              {
                staticClass:
                  "has-margin-7 has-padding-top-7 has-padding-bottom-7",
              },
              [
                _c("h2", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n            Add a donation badge to your profile\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "mt-2" },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "text",
                        placeholder: "http://etherscan.io/tx/0x0123....",
                        expanded: "",
                        autofocus: "",
                      },
                      model: {
                        value: _vm.form.transactionLink,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "transactionLink", $$v)
                        },
                        expression: "form.transactionLink",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n            Donations must come from your registered Ethereum address, sent to a listed KO charity, and be\n            at least 0.01 ETH.\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {},
                  [
                    _vm._v(
                      "\n            Once confirmed you will see a new\n            "
                    ),
                    _c("DonationBadge"),
                    _vm._v("\n            badge on your profile.\n          "),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.response && !_vm.response.success && _vm.response.message
                  ? _c("p", { staticClass: "has-text-danger mt-2" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.response.message) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.response && _vm.response.success
                  ? _c(
                      "p",
                      { staticClass: "mt-2 has-text-success" },
                      [
                        _vm._v(
                          "\n            Donation Registered\n            "
                        ),
                        _c("b-icon", {
                          attrs: {
                            icon: "check-circle-outline",
                            size: "is-small",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  { attrs: { expanded: "" }, on: { click: _vm.close } },
                  [_vm._v("\n            Nevermind\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-primary",
                    attrs: {
                      expanded: "",
                      loading: _vm.loading,
                      disabled: !_vm.form.transactionLink,
                    },
                    on: { click: _vm.claimBadge },
                  },
                  [_vm._v("\n            Claim\n          ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "title" }, [
      _vm._v("\n            Paste in the "),
      _c("strong", [_vm._v("etherscan link")]),
      _vm._v(" from a donation you have made.\n          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }