<template>
  <div class="content" style="margin-left: 10px; margin-right: 10px;">
    <div class="card">
      <div class="card-content has-padding-7">
        <div class="content">
          <section class="has-margin-7 has-padding-top-7 has-padding-bottom-7">
            <h2 class="mb-0">
              <artwork-name-and-artist :edition-number="editionId">
              </artwork-name-and-artist>
            </h2>
            <h5 class="mb-5">
              Edition #{{ editionId }} ownership network.
            </h5>
            <p>
              An experiment in modelling edition ownership.
            </p>
            <section>
              <network class="wrapper"
                       ref="network"
                       :nodes="nodes"
                       :edges="edges"
                       :options="options"
                       @double-click="onDoubleClick">
              </network>
            </section>
          </section>
        </div>

        <div class="card-footer">
          <div class="card-footer-item">
            <b-button expanded @click="close">
              Close
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import _flatMap from 'lodash/flatMap';
import {mapState} from 'vuex';
import {EDITION_TRANSFER_HISTORY} from '../../../queries/tokenQueries';
import ArtworkNameAndArtist from '../../ArtworkNameAndArtist';

export default {
  components: {ArtworkNameAndArtist},
  props: ['editionId'],
  data() {
    return {
      nodes: [],
      edges: [],
      allTransferEvents: [],
      options: {
        nodes: {
          borderWidth: 2
        },
        edges: {
          color: 'lightgray'
        }
      }
    };
  },
  computed: {
    ...mapState('web3Store', ['gqlClient'])
  },
  mounted() {
    this.$apollo.addSmartQuery('editionTransferHistory', {
      client: this.gqlClient,
      query: EDITION_TRANSFER_HISTORY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          editionId: this.editionId
        };
      },
      update({tokens}) {
        this.allTransferEvents = _flatMap(tokens, 'transfers');
        this.buildGraph();
      },
      error(error, vm, key, type, options) {
        console.log('error', error);
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onDoubleClick(node) {
      if (node.nodes && node.nodes.length === 1) {
        this.$nuxt.$router.push({name: 'profile-id', params: {id: node.nodes[0]}});
        this.close();
      }
      if (node.edges && node.edges.length === 1) {
        const edge = this.$refs.network.getEdge(node.edges[0]);
        this.$nuxt.$router.push({name: 'tokens-id', params: {id: edge.label.replace('#', '')}});
        this.close();
      }
    },
    async buildGraph() {
      const shortEth = value => `${value.substr(0, 4)}...${value.substr(value.length - 4, value.length)}`;

      const nameResolver = async (address) => {
        if (address === '0x0000000000000000000000000000000000000000') {
          return 'Primary Sale';
        }
        const user = await this.$store.dispatch('userStore/getUser', address);
        return user.username ? user.username : shortEth(address);
      }

      const shapeBuilder = (address) => {
        if (address === '0x0000000000000000000000000000000000000000') {
          return 'diamond';
        }
        return 'circle';
      }

      const edgeLabel = transfer => `#${transfer.tokenId}`;

      const nodeColour = (address) => {
        if (address === '0x0000000000000000000000000000000000000000') {
          return '#a4adf8';
        }
        return '#a2a0a0';
      }

      const results = this.allTransferEvents.map(async (transfer) => {
        this.edges.push({
          to: transfer.to,
          from: transfer.from,
          label: edgeLabel(transfer)
        });
        return Promise.all([
          {
            id: transfer.from,
            label: await nameResolver(transfer.from),
            shape: shapeBuilder(transfer.from),
            color: nodeColour(transfer.from)
          },
          {
            id: transfer.to,
            label: await nameResolver(transfer.to),
            shape: shapeBuilder(transfer.to),
            color: nodeColour(transfer.from)
          }
        ]);
      });

      const nodeLookUps = await Promise.all(results);
      this.nodes = _uniqBy(_flatten(nodeLookUps), 'id');
    }
  }
};

</script>
<style scoped lang="scss">
.wrapper {
  max-height: 400px;
  padding: 10px;
  height: 100vh;
}
</style>
