var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user && _vm.user.id
    ? _c(
        "user-profile-lookup",
        {
          attrs: {
            "artist-account": _vm.user.id,
            "metadata-artist": _vm.user.id,
          },
        },
        [
          _c(
            "template",
            { slot: "artist-profile-subtitle" },
            [
              _vm.user && _vm.user.score && _vm.user.id
                ? _c("trending-score", {
                    attrs: {
                      score: _vm.user.score,
                      address: _vm.user.id,
                      "no-padding": true,
                      strong: _vm.strong,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
    : _c("div", { staticClass: "media is-align-items-center" }, [
        _c(
          "div",
          { staticClass: "media-left" },
          [
            _c("b-skeleton", {
              attrs: { circle: "", active: "", width: "64px", height: "64px" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "media-content" }, [
          _c(
            "div",
            { staticClass: "content is-flex is-flex-wrap-wrap" },
            [
              _c("b-skeleton", {
                attrs: { active: "", width: "232px", height: "24px" },
              }),
              _vm._v(" "),
              _c("b-skeleton", {
                attrs: { active: "", width: "75px", height: "24px" },
              }),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }