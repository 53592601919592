var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("nav", { staticClass: "columns mb-4 mt-4" }, [
        _c(
          "aside",
          { staticClass: "column is-half-desktop is-full-mobile" },
          [
            _vm.isLoggedInAccountViewingPage
              ? _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary", outlined: "" },
                    on: { click: _vm.createCollection },
                  },
                  [_vm._v("\n        Create a collection\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLoggedInAccountViewingPage && !_vm.hasAuthToken
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: { type: "is-primary", outlined: "" },
                    on: { click: _vm.signInAndGetAuthToken },
                  },
                  [_vm._v("\n        View private collections\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "columns has-margin-bottom-6 has-margin-top-6" },
        [
          _c("aside", { staticClass: "column is-full-mobile" }, [
            _c("div", { staticClass: "level is-mobile" }, [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "level-item is-family-tertiary has-text-weight-bold",
                  },
                  [
                    _c(
                      "b-field",
                      [
                        _c(
                          "b-switch",
                          {
                            attrs: {
                              "false-value": _vm.viewCollections,
                              "true-value": _vm.viewOnSale,
                            },
                            model: {
                              value: _vm.selectedView,
                              callback: function ($$v) {
                                _vm.selectedView = $$v
                              },
                              expression: "selectedView",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.viewOnSale) +
                                "\n                "
                            ),
                            _c(
                              "b-tooltip",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  position: "is-top",
                                  label: "Items with a list or reserve price",
                                  type: "is-muted",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "information-outline",
                                    size: "is-small",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.selectedView !== _vm.viewCollections
                ? _c("div", { staticClass: "level-right" }, [
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c(
                          "b-select",
                          {
                            staticClass: "has-margin-left-7",
                            attrs: { placeholder: "Sort by", size: "is-small" },
                            model: {
                              value: _vm.selectedSortBy,
                              callback: function ($$v) {
                                _vm.selectedSortBy = $$v
                              },
                              expression: "selectedSortBy",
                            },
                          },
                          _vm._l(_vm.sortByOptions, function (option) {
                            return _c(
                              "option",
                              { key: option.key, domProps: { value: option } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(option.label) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.selectedView === _vm.viewCollections
        ? _c(
            "div",
            [
              _c("collections-view", {
                attrs: {
                  profile: _vm.profile,
                  "is-logged-in-account-viewing-page":
                    _vm.isLoggedInAccountViewingPage,
                  "profile-collections": _vm.profileCollections,
                  "extra-editions": _vm.combinedEditions,
                  "all-collections-items": _vm.allCollectionsItems,
                  "cc-editions": _vm.ccCollabEditions,
                },
                on: {
                  "edit-collection": _vm.editCollection,
                  "refresh-collection": _vm.loadUserCollections,
                  "editions-refresh": _vm.refreshEditions,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedView === _vm.viewOnSale
        ? _c(
            "div",
            [
              _c("on-sale-view", {
                attrs: {
                  profile: _vm.profile,
                  "is-logged-in-account-viewing-page":
                    _vm.isLoggedInAccountViewingPage,
                  editions: _vm.editions,
                },
                on: { "editions-refresh": _vm.refreshEditions },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c(
            "infinite-loading",
            {
              attrs: { identifier: _vm.infiniteId },
              on: { infinite: _vm.showMore },
            },
            [
              _c("template", { slot: "error" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("template", { slot: "spinner" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("template", { slot: "no-results" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "no-more" },
                [_c("empty-state", { attrs: { message: "No more tokens" } })],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }