<template>
  <figure v-lazy-container="{ selector: 'img' }" v-if="address">
    <img
      :data-src="imageUrl"
      :data-error="createAccountIcon">
  </figure>
</template>
<script>
import {toDataUrl} from 'myetherwallet-blockies';
import {mapState} from 'vuex';

export default {
  props: ['address'],
  data() {
    return {
      imageUrl: null
    }
  },
  computed: {
    ...mapState('web3Store', [
      'chainId'
    ]),
    createAccountIcon() {
      return toDataUrl(this.address);
    }
  },
  async mounted() {
    this.imageUrl = await this.$store.dispatch('creatorContractStore/fetchContractImage', {
      address: this.address,
      imageType: 'thumbnail'
    })
  }
};
</script>

<style lang="scss" scoped>
@import "node_modules/bulma/sass/utilities/initial-variables";

img {
  padding: 5px;
  background: #fff;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 15%;
  -webkit-box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  box-shadow: 0 0 0 1px $grey-lighter, 0 0 0 1px $grey-lighter;
  width: 128px;
  height: 128px;
}
</style>
