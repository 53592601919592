<template>
  <div class="card-image" data-testid="artworkImg">
    <span v-if="isVideoImageType">
      <b-icon icon="video" type="is-light" style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
      </b-icon>
    </span>

    <span v-if="is3DImageType">
      <b-icon icon="video-3d-variant" type="is-light" style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
      </b-icon>
    </span>

    <span v-if="is360Image">
      <b-icon icon="panorama" type="is-light" style="position: absolute; top: 1rem; right: 1rem; z-index: 10;">
      </b-icon>
    </span>

    <span v-if="(!startDatePassed || startDateJustPassed) && edition.startDate > 0" class="has-text-light">
      <span style="position: absolute; top: 1rem; left: 1rem; z-index: 10;">
        <b-icon icon="alarm" type="is-light"></b-icon>
      </span>
    </span>

    <unlockable-edition-icon v-if="edition && !disableIcons" :edition-number="edition.id">
    </unlockable-edition-icon>

    <enhanced-icon v-if="edition && !disableIcons" :edition="edition">
    </enhanced-icon>
    <creator-contract-icon :is-creator-contract="isCc" />

    <div class="asset-card-wrapped-link hand-pointer">
      <n-link :to="handleMarketPlaceRouting">
        <image-asset :edition-number="edition.id"
                     :metadata="workingMetadata"
                     :resolution="resolution"
                     :cover="cover">
        </image-asset>
      </n-link>

    </div>
  </div>
</template>

<script>

import {mimeTypeUtils} from '@knownorigin/ko-token-uri-tools';
import ImageAsset from '../asset-renders/ImageAsset';
import UnlockableEditionIcon from '../badges/UnlockableEditionIcon';
import EnhancedIcon from '../badges/EnhancedIcon';
import {editionStartDatePassed, editionStartDateJustPassed} from '../../services/utils';
import {handleGalleryRouting} from '../../services/routing';
import CreatorContractIcon from '../badges/CreatorContractIcon';

export default {
  components: {
    CreatorContractIcon,
    ImageAsset,
    UnlockableEditionIcon,
    EnhancedIcon

  },
  props: [
    'edition',
    'cover',
    'resolution',
    'size',
    'algoliaInfo',
    'clickHandler',
    'disableIcons',
    'isCc'
  ],
  data() {
    return {
      metadataFromKoCache: null,
      like: false,
      editionOffers: null,
      tokens: null
    };
  },
  computed: {
    workingMetadata() {
      if (this.edition.metadata) {
        return this.edition.metadata;
      }
      return this.metadataFromKoCache;
    },
    isVideoImageType() {
      return this.edition.metadata && this.edition.metadata.image_type && mimeTypeUtils.isVideoType(this.edition.metadata.image_type);
    },
    is3DImageType() {
      return this.edition.metadata && this.edition.metadata.image_type && mimeTypeUtils.is3D(this.edition.metadata.image_type);
    },
    is360Image() {
      return this.edition.metadata && this.edition.metadata.image_sphere === true;
    },
    startDatePassed() {
      return editionStartDatePassed(this.edition.startDate);
    },
    startDateJustPassed() {
      return editionStartDateJustPassed(this.edition.startDate);
    },
    handleMarketPlaceRouting() {
      return handleGalleryRouting(this.edition, this.clickHandler)
    }
  }
};
</script>
