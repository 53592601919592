var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.free
    ? _c(
        "div",
        { staticClass: "column content" },
        [
          _c(
            "b-button",
            {
              staticClass: "has-margin-bottom-7",
              attrs: {
                type: "is-primary",
                size: "is-medium",
                expanded: "",
                "data-testid": "buyWithETHBtn",
              },
              on: { click: _vm.launchBuyNowEditionModal },
            },
            [_vm._v("\n    Buy with ETH\n  ")]
          ),
          _vm._v(" "),
          _vm.showWert
            ? _c(
                "b-button",
                {
                  staticClass: "has-margin-bottom-7",
                  attrs: {
                    type: "is-secondary",
                    size: "is-medium",
                    expanded: "",
                  },
                  on: { click: _vm.launchBuyNowEditionWertModal },
                },
                [_vm._v("\n    Buy with card\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("wert-topup-link"),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "b-button",
            {
              staticClass: "has-margin-bottom-7",
              attrs: { type: "is-primary", size: "is-medium", expanded: "" },
              on: { click: _vm.launchBuyNowEditionModal },
            },
            [_vm._v("\n    Free claim\n  ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }