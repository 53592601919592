export const ArtworkReserveRequest = {
  ArtworkReserveRequest: [
    {
      name: 'koda_version',
      type: 'string'
    },
    {
      name: 'sale_type',
      type: 'string'
    },
    {
      name: 'current_owner',
      type: 'address'
    },
    {
      name: 'eth_reserve_in_wei',
      type: 'string'
    },
    {
      name: 'entity_type',
      type: 'string'
    },
    {
      name: 'artwork_id',
      type: 'string'
    },
    {
      name: 'date_signed',
      type: 'string'
    }
  ]
};

export const UnlockableContentRequest = {
  UnlockableContentRequest: [
    {
      name: 'koda_version',
      type: 'string'
    },
    {
      name: 'content_message',
      type: 'string'
    },
    {
      name: 'content_link',
      type: 'string'
    },
    {
      name: 'artwork_id',
      type: 'string'
    },
    {
      name: 'date_signed',
      type: 'string'
    }
  ]
};

export const UnlockableContentClaimRequest = {
  UnlockableContentClaimRequest: [
    {
      name: 'koda_version',
      type: 'string'
    },
    {
      name: 'artwork_id',
      type: 'string'
    },
    {
      name: 'token_id',
      type: 'string'
    },
    {
      name: 'current_owner',
      type: 'string'
    },
    {
      name: 'date_signed',
      type: 'string'
    }
  ]
};

export const domain = (chainId) => {
  return {
    name: 'KnownOrigin.io',
    version: '1',
    chainId,
    verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC'
  };
};
