<template>
  <div>
    <section class="container section push-up-homepage-mobile">
      <div class="tile is-child">
        <div class="columns content is-flex is-vcentered">
          <h2
            class="column title is-size-3-desktop is-size-5-mobile is-quarter-tablet is-half-mobile">
            {{ cmsComponent.title }}
          </h2>
          <div class="columns is-mobile is-centered">
            <div v-if="componentData" class="column is-half is-justify-content-center is-flex">
              <b-button
                :disabled="disableDecrement"
                class="header-style arrow-buttons is-hidden-mobile is-hidden-tablet-only"
                icon-left="arrow-left-circle-outline "
                @click="decrementCarousel" />
              <b-button
                :disabled="disableIncrement"
                class="header-style arrow-buttons is-hidden-mobile is-hidden-tablet-only"
                icon-left="arrow-right-circle-outline"
                @click="incrementCarousel" />
            </div>
          </div>
          <div
            class="column is-quarter-tablet is-half-mobile has-text-right mb-5"
            @click="handleViewMoreClick(cmsComponent.viewMoreLink)">
            <n-link
              :to="cmsComponent.viewMoreLink"
              class="is-outlined is-size-6-mobile component-view-more-link">
              <b-button type="is-secondary">
                {{ cmsComponent.viewMoreLabel }}
              </b-button>
            </n-link>
          </div>
        </div>
      </div>

      <b-carousel-list
        v-if="componentData"
        v-model="carouselValue"
        :data="componentData"
        :items-to-show="perSlide"
        :items-to-list="perSlide"
        :arrow="false"
        class="carousel">
        <template #item="edition">
          <div v-if="cmsComponent.cardHeader" class="level is-mobile tighten-heading">
            <p class="level-left has-text-weight-semibold ml-5">
              {{ mapEvent(edition.eventType) }}
            </p>
            <p class="level-right has-text-weight-light is-size-6 mr-5">
              <span class="uppercase">
                <date-from :timestamp-in-secs="edition.timestamp" />
              </span>
            </p>
          </div>
          <div class="carousel-card">
            <token-card
              v-if="checkIsToken(edition)"
              :token="edition.token ? edition.token : edition"
              :cover="true"
              resolution="card"
              @click.native="handleCardClickedEvent(edition)">
              <template slot="asset-card-footer-left-title">
                <span class="has-text-left">
                  {{ mapActivityEventTitle(edition.eventType) }}
                </span>
              </template>
              <template slot="asset-card-footer-left">
                <eth-with-fiat-price
                  v-if="edition.eventValueInWei"
                  :price-in-wei="edition.eventValueInWei"
                  class="has-text-weight-semibold has-text-left" />
              </template>
            </token-card>
            <edition-card
              v-else
              :edition="edition.edition ? edition.edition : edition"
              :disable-icons="true"
              :cover="true"
              resolution="card"
              class="mb-0"
              @click.native="handleCardClickedEvent(edition)" />
          </div>
        </template>
      </b-carousel-list>

      <div v-else :style="{ gridTemplateColumns: `repeat(${perSlide}, 1fr)` }" class="carouselSkeleton">
        <div v-for="el in perSlide" :key="el" class="carouselSkeleton__item">
          <EditionCardSkeleton />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import get from 'lodash/get';

import EditionCard from '../../EditionCard';
import TokenCard from '../../TokenCard';
import DateFrom from '../../common/DateFrom';
import EthWithFiatPrice from '../../common/EthWithFiatPrice';
import EditionCardSkeleton from '../../EditionCardSkeleton';

import {
  AMPLITUDE_EVENT_PROPERTIES,
  EVENT_NAMES
} from '../../../services/analyticsEvents';
import { mapEvent, mapActivityEventTitle } from '../../../services/utils';

export default {
  components: {
    EditionCard,
    DateFrom,
    TokenCard,
    EthWithFiatPrice,
    EditionCardSkeleton
  },
  props: ['cmsComponent'],
  data() {
    return {
      perSlide: 3,
      componentData: null,
      carouselValue: 0,
      scrollExhaustedEventSent: false
    };
  },
  computed: {
    disableDecrement() {
      return this.carouselValue < 1;
    },
    disableIncrement() {
      if (!this.componentData) { return false }
      return this.carouselValue > this.componentData?.length - 4;
    }
  },
  watch: {
    carouselValue(newVal, oldVal) {
      const offset = this.$device.isDesktop ? 3 : 1;
      if (newVal + offset === this.componentData?.length) {
        // If you are at the end then trigger scroll exhausted event, but only send it once
        if (!this.scrollExhaustedEventSent) {
          this.$store.dispatch('analytics/amplitudeStore/logEvent', {
            name: EVENT_NAMES.componentScrollExhausted,
            properties: {
              [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
              [AMPLITUDE_EVENT_PROPERTIES.depth]: this.componentData?.length,
              [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artwork1',
              [AMPLITUDE_EVENT_PROPERTIES.panelPosition]:
                this.cmsComponent.position,
              [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query
            }
          });
          this.scrollExhaustedEventSent = true;
        }
      }
      this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.componentScroll,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.depth]: newVal + offset,
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artwork1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]:
            this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.scrollDirection]:
            newVal > oldVal ? 'right' : 'left'
        }
      });
    }
  },
  mounted() {
    // set per slide
    this.updatePerSlide()

    // watch for resize to update per slide
    this.handleUpdatePerSlide = this.updatePerSlide.bind(this)
    window.addEventListener('resize', this.handleUpdatePerSlide)

    // when element is in view, load data once
    this.observer = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        const data = await this.$store.dispatch('componentDataStore/fetchComponentData', this.cmsComponent)
        this.componentData = data
        this.observer.unobserve(this.$el)
      }
    })
    this.observer.observe(this.$el)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleUpdatePerSlide)
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    mapActivityEventTitle,
    checkIsToken(val) {
      // TODO Change implementation to take into account individual tokens being returned from a query as well as activityEvents and editions
      if (val.tokenIds === undefined) {
        return true;
        // is token
      } else {
        return false;
        // is not token
      }
    },
    incrementCarousel() {
      this.carouselValue = this.carouselValue + 1 * this.perSlide;
    },
    decrementCarousel() {
      this.carouselValue = this.carouselValue - 1 * this.perSlide;
    },
    updatePerSlide() {
      const win = window.innerWidth
      if (win >= 1024) {
        this.perSlide = 3
      } else if (win >= 769 && win < 1024) {
        this.perSlide = 2
      } else {
        this.perSlide = 1
      }
    },
    mapEvent,
    async handleViewMoreClick(destination) {
      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.viewAllClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artwork1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]:
            this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: destination.name
        }
      });
    },
    async handleCardClickedEvent(edition) {
      const subject = edition.edition
        ? get(edition, 'edition.metadata.artist')
        : get(edition, 'metadata.artist');

      await this.$store.dispatch('analytics/amplitudeStore/logEvent', {
        name: EVENT_NAMES.cardClicked,
        properties: {
          [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage',
          [AMPLITUDE_EVENT_PROPERTIES.panelType]: 'artwork1',
          [AMPLITUDE_EVENT_PROPERTIES.panelPosition]:
            this.cmsComponent.position,
          [AMPLITUDE_EVENT_PROPERTIES.depth]: edition.index + 1,
          [AMPLITUDE_EVENT_PROPERTIES.query]: this.cmsComponent.query,
          [AMPLITUDE_EVENT_PROPERTIES.destination]: 'gallery',
          [AMPLITUDE_EVENT_PROPERTIES.subject]: subject,
          [AMPLITUDE_EVENT_PROPERTIES.subjectType]: 'artist',
          [AMPLITUDE_EVENT_PROPERTIES.contractVersion]: edition.version
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "~bulma/sass/utilities/initial-variables";

.carousel {
  overflow: visible;
  margin: 0 -20px;
  width: calc(100% + 40px);
}

.carousel-card {
  margin-left: 20px;
  margin-right: 20px;
  color: transparent;
}

.has-shadow {
  // do not change, this class is auto applied by buefy on the carousel and must be overwritten!
  box-shadow: 0 0 0 0 transparent;
  background-color: transparent;
}

.header-style {
  border-width: 0px;
}

.header-style:focus,
.header-style:active {
  outline: none !important;
  box-shadow: none;
}

.arrow-buttons {
  font-size: 30px;
}

.uppercase {
  text-transform: uppercase;
  font-size: 11px;
}

.carouselSkeleton {
  display: grid;
  gap: 40px;
  padding: 0 20px;
  position: relative;
  margin: 0 -20px;

  &::after {
    content: '';
    position: absolute;
    left: calc(100% + 20px);
    width: 33.333%;
    display: block;
    top: 2px;
    bottom: 2px;
    background: #fff;
    box-shadow: 0 0em 2.5em 0.75em rgba(10, 10, 10, 0.1);
  }

  &__item {
    border: 2px solid transparent;
  }
}
</style>
